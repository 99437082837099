import React, { useEffect, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import { logger } from '../../../logger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconContainer, PastedAddressMessagePopper } from './PlacesAutocomplete.styled'
import { AddressType } from '@lazr/openapi-client'

export type AutocompletePrediction = google.maps.places.AutocompletePrediction
type AutocompletionRequest = google.maps.places.AutocompletionRequest
type PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest
type PlaceSearchRequest = google.maps.places.PlaceSearchRequest
type PlaceResult = google.maps.places.PlaceResult
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus
type PredictionSubstring = google.maps.places.PredictionSubstring
type GeocoderRequest = google.maps.GeocoderRequest
type GeocoderResult = google.maps.GeocoderResult
type LatLng = google.maps.LatLng

let autocompleteService: google.maps.places.AutocompleteService
let placesService: google.maps.places.PlacesService
let geocoderService: google.maps.Geocoder

const PlacesAutocomplete: React.FunctionComponent<Props> = ({
    id,
    label,
    helperText,
    variant,
    required,
    disabled,
    autoFocus,
    onSelect,
    value,
    onChange,
    className,
}) => {
    const [ inputValue, setInputValue ] = React.useState<string>('')
    const [ options, setOptions ] = React.useState<AutocompletePrediction[]>([])
    const [ isPasted, setIsPasted ] = React.useState<boolean>(false)

    const updatePlaceDetails = async (request: PlaceDetailsRequest): Promise<void> => {
        try {
            const placeResult: PlaceResult = await new Promise((resolve, reject) => {
                try {
                    placesService.getDetails(request, (result: PlaceResult) => {
                        resolve(result)
                    })
                } catch (err: any) {
                    logger.error(err)
                    reject(err)
                }
            })
            let postalCodeFound = false

            const autocompleteResult: PlacesAutocompleteResult = {
                name: placeResult.name,
                formattedAddress: placeResult.formatted_address,
                geometry: {
                    latitude: placeResult.geometry ? placeResult.geometry.location.lat() : 0,
                    longitude: placeResult.geometry ? placeResult.geometry.location.lng() : 0,
                },
            }

            logger.debug('Selected place before reducing', placeResult.address_components)
            if (placeResult.address_components) {
                placeResult.address_components.forEach((item) => {
                    const names: PlacesAutocompleteAddressComponent = {
                        longName: item.long_name,
                        shortName: item.short_name,
                    }

                    if (item.types.includes('street_number')) {
                        autocompleteResult.streetNumber = names
                    } else if (item.types.includes('route')) {
                        autocompleteResult.street = names
                    } else if (item.types.includes('neighborhood')) {
                        autocompleteResult.region = names
                    } else if (item.types.includes('locality')) {
                        autocompleteResult.city = names
                    } else if (item.types.includes('administrative_area_level_1')) {
                        if (names.shortName.length < 3) {
                            autocompleteResult.stateOrProvince = names
                        }
                    } else if (item.types.includes('country')) {
                        autocompleteResult.country = names
                    } else if (item.types.includes('postal_code')) {
                        postalCodeFound = true
                        autocompleteResult.zipOrPostalCode = names
                    }
                })
            }
            autocompleteResult.addressType = placeResult.types?.includes('establishment') ? AddressType.BUSINESS :
                AddressType.RESIDENTIAL

            if ((!postalCodeFound || autocompleteResult.addressType !== AddressType.BUSINESS) && placeResult.geometry) {
                // First fallback
                const addressInfo = await getInformationFromGeolocation(placeResult.geometry.location)
                if (autocompleteResult.addressType !== AddressType.BUSINESS) {
                    autocompleteResult.addressType = addressInfo?.isBusiness ? AddressType.BUSINESS : AddressType.RESIDENTIAL
                }

                if (!postalCodeFound) {
                    let postalCode = addressInfo?.zipOrPostalCode ?? null
                    let results: PlaceResult[] = []

                    // Second fallback
                    if (!postalCode) {
                        results = await getPlaceResultsFromPostOfficeNearbySearch(placeResult.geometry.location)
                        postalCode = getPostalCodeFromPlaceResults(results)
                    }
                    // Third fallback
                    if (!postalCode) {
                        postalCode = await getPostalCodeFromPostOfficePlaceResultsPlaceIds(results)
                    }
                    if (postalCode) {
                        autocompleteResult.zipOrPostalCode = postalCode
                    }
                }
            }

            logger.debug('Selected place after reducing', autocompleteResult)

            onSelect(autocompleteResult)
        } catch (err: any) {
            logger.error(err)
        }
    }

    const getInformationFromGeolocation = (location: LatLng):
    Promise<PlacesAutocompleteInformation | null> =>
        new Promise((resolve, reject) => {
            try {
                const request: GeocoderRequest = {
                    location,
                }

                geocoderService.geocode(request, (results: GeocoderResult[]): void => {
                    if (!results) {
                        resolve(null)

                        return
                    }
                    let names
                    let isBusiness = false
                    for (const result of results) {
                        if (result.geometry.location_type === google.maps.GeocoderLocationType.ROOFTOP &&
                                result.types.includes('establishment')) {
                            isBusiness = true
                        }

                        const foundResult = result.address_components.find((item) =>
                            item.types.includes('postal_code')
                            && !item.types.includes('postal_code_prefix')
                            && !item.types.includes('postal_code_suffix'),
                        )
                        if (foundResult && !names) {
                            names = {
                                longName: foundResult.long_name,
                                shortName: foundResult.short_name,
                            }
                        }
                    }
                    resolve({ zipOrPostalCode: names,  isBusiness })
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })

    const getPlaceResultsFromPostOfficeNearbySearch = async (location: LatLng): Promise<PlaceResult[]> =>
        new Promise((resolve, reject) => {
            try {
                const request: PlaceSearchRequest = {
                    location: location,
                    radius: 5000,
                    type: 'post_office',
                }

                placesService.nearbySearch(request, (results: PlaceResult[]): void => {
                    resolve(results)
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })
    const getPostalCodeFromPlaceResults = (results: PlaceResult[]): PlacesAutocompleteAddressComponent | null => {
        for (const result of results) {
            const foundResult = result?.address_components?.find((item) => item.types.includes('postal_code'))
            if (foundResult) {
                return {
                    longName: foundResult.long_name,
                    shortName: foundResult.short_name,
                }
            }
        }

        return null
    }

    const getPostalCodeFromPostOfficePlaceResultsPlaceIds = async (
        results: PlaceResult[],
    ): Promise<PlacesAutocompleteAddressComponent | null> => {
        for (const result of results) {
            if (result.place_id) {
                const postalCode = await getPostalCodeFromPostOfficePlaceId(result.place_id)
                if (postalCode) {
                    return postalCode
                }
            }
        }

        return null
    }

    const getPostalCodeFromPostOfficePlaceId = async (placeId: string): Promise<PlacesAutocompleteAddressComponent | null> =>
        new Promise((resolve, reject) => {
            try {
                const request: GeocoderRequest = {
                    placeId,
                }

                geocoderService.geocode(request, (results: GeocoderResult[]): void => {
                    if (!results) {
                        resolve(null)

                        return
                    }
                    for (const result of results) {
                        const foundResult = result.address_components.find((item) =>
                            item.types.includes('postal_code')
                            && !item.types.includes('postal_code_prefix')
                            && !item.types.includes('postal_code_suffix'),
                        )
                        if (foundResult) {
                            const names: PlacesAutocompleteAddressComponent = {
                                longName: foundResult.long_name,
                                shortName: foundResult.short_name,
                            }
                            resolve(names)

                            return
                        }
                    }
                    resolve(null)
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setIsPasted((event.nativeEvent as any).inputType.startsWith('insertFromPaste'))
        setInputValue(event.target.value)
    }

    const handlePlaceChanged = async (placeId: string): Promise<void> => {
        if (!placeId) {
            return
        }

        const request: PlaceDetailsRequest = {
            placeId: placeId,
            fields: [ 'address_components', 'name', 'geometry', 'formatted_address', 'types' ],
        }

        await updatePlaceDetails(request)
    }

    const fetch = useMemo(
        () =>
            throttle((
                input: AutocompletionRequest,
                callback: (result: AutocompletePrediction[], status: PlacesServiceStatus) => void,
            ): void => {
                const request = { ...input }
                autocompleteService.getPlacePredictions(request, callback)
            }, 200),
        [],
    )

    useEffect(() => {
        let active = true

        if ((window as any).google) {
            if (!autocompleteService) {
                autocompleteService = new google.maps.places.AutocompleteService()
            }

            if (!placesService) {
                placesService = new google.maps.places.PlacesService(document.querySelector('#places-service-div') as HTMLDivElement)
            }

            if (!geocoderService) {
                geocoderService = new google.maps.Geocoder()
            }
        }

        if (!autocompleteService || !placesService) {
            return
        }

        if (inputValue === '') {
            setOptions(value ? [ value ] : [])

            return
        }

        fetch({ input: inputValue }, (results?: AutocompletePrediction[]) => {
            if (active) {
                let newOptions = [] as AutocompletePrediction[]

                if (value) {
                    newOptions = [ value ]
                }

                if (results) {
                    newOptions = [ ...newOptions, ...results ]
                }

                setOptions(newOptions)
            }
        })

        return (): void => {
            active = false
        }
    }, [ value, inputValue, fetch ])

    return (
        <React.Fragment>
            <div id="places-service-div" style={{ display: 'none' }}/>
            <Autocomplete
                className={className}
                disabled={disabled}
                id={id}
                PopperComponent={isPasted ? PastedAddressMessagePopper : undefined}
                value={value}
                fullWidth
                onChange={
                    (_event, newValue) => {
                        void (async (): Promise<void> => {
                            setOptions(newValue ? [ newValue, ...options ] : options)
                            onChange(newValue)

                            // eslint-disable-next-line camelcase
                            if (newValue?.place_id) {
                                await handlePlaceChanged(newValue.place_id)
                            }
                        })()
                    }
                }
                getOptionLabel={
                    (option: AutocompletePrediction): string => option.description
                }
                options={options}
                filterOptions={(predictionOptions) => predictionOptions}
                autoComplete
                noOptionsText={isPasted ? 'The address you pasted may be too specific in its entirety for the autocomplete. ' +
                    'Please type it manually or enter your address in the fields below.' : undefined}
                includeInputInList
                renderInput={(params): React.ReactNode => (
                    <TextField
                        {...params}
                        required={required}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                        autoFocus={autoFocus}
                        label={label}
                        helperText={helperText}
                        variant={variant}
                        onChange={handleChange}
                        fullWidth
                    />
                )}
                renderOption={(option: AutocompletePrediction): React.ReactNode => {
                    const matches = option.structured_formatting.main_text_matched_substrings || []
                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: PredictionSubstring) => [ match.offset, match.offset + match.length ]),
                    ) || []

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                <IconContainer>
                                    <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} />
                                </IconContainer>
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }}
            />
        </React.Fragment>
    )
}

export interface Props {
    id: string
    label: string
    helperText?: string
    variant?: 'outlined'
    required?: boolean
    disabled?: boolean
    autoFocus?: boolean
    onSelect: (result: PlacesAutocompleteResult) => void
    value: AutocompletePrediction | null
    onChange: (newValue: AutocompletePrediction | null) => void
    className?: string
}

interface PlacesAutocompleteAddressComponent {
    longName: string
    shortName: string
}

interface PlacesAutocompleteInformation {
    zipOrPostalCode?: PlacesAutocompleteAddressComponent
    isBusiness?: boolean
}

interface PlacesAutocompleteResult {
    name: string
    formattedAddress: string | undefined
    streetNumber?: PlacesAutocompleteAddressComponent
    street?: PlacesAutocompleteAddressComponent
    region?: PlacesAutocompleteAddressComponent
    city?: PlacesAutocompleteAddressComponent
    stateOrProvince?: PlacesAutocompleteAddressComponent
    country?: PlacesAutocompleteAddressComponent
    zipOrPostalCode?: PlacesAutocompleteAddressComponent
    geometry?: {
        latitude: number
        longitude: number
    }
    addressType?: AddressType
}

export type { PlacesAutocompleteResult }
export default PlacesAutocomplete
