import React from 'react'
import { Card as MuiCard } from '@material-ui/core'
import styled, { ThemedStyledProps } from 'styled-components'
import { CardProps } from '@material-ui/core'
import { Paper as MuiPaper, PaperProps, AccordionProps, Accordion as MuiAccordion } from '@material-ui/core'
import QuestionCardHeader, { Props } from '@/app/ui-new/components/SectionHeader/QuestionSectionHeader/QuestionCardHeader'

const getBorder = (props: ThemedStyledProps<any, any>, hover = false, expanded = false): string => {
    if (hover && !props.disabled && !expanded) {
        return `box-shadow:inset 0px 0px 0px 1px ${props.theme.palette.neutral700.main};`
    }

    if (props.error){
        return `box-shadow:inset 0px 0px 0px 2px ${props.theme.palette.error900.main};`
    }

    return ''
}

export const StyledPaper: React.FC<PaperProps> = styled(MuiPaper)<PaperProps>`
    background-color: ${(props): number => props.theme.palette.neutral100.main};
    padding-top: ${(props): string => props.theme.spacing(12)}px;
    padding-right: ${(props): string => props.theme.spacing(25)}px;
    padding-bottom: ${(props): string => props.theme.spacing(25)}px;
    padding-left: ${(props): string => props.theme.spacing(25)}px;
`

export const StyledCard: React.FC<CardProps> = styled(MuiCard)<CardProps>`
    border: none;
    background-color: unset;
    border-radius: 0px;

    & [class*="MuiCardContent-root"]{
        padding: 0;
    }
`

export const StyledAccordion: React.FC<AccordionProps> = styled(MuiAccordion)<AccordionProps>`
    width: 100%;
    box-shadow: none;
    border: 1px solid ${(props): number => props.theme.palette.neutral100.main};
    .Mui-disabled{
        background-color: unset;
    }

    & [class*="MuiAccordionSummary-root"]{
        &.Mui-disabled {
            opacity: 1;
        }

        & [class*="MuiCardHeader-root"]{
            &:hover{
                ${(props): string => getBorder(props, true, props.expanded)}
            }
        }
        border-bottom: 1px solid ${(props): number => props.theme.palette.neutral100.main};
        padding: 0;
    }

    & [class*="MuiAccordionSummary-content"]{
        margin: 0;
    }

    & [class*="MuiAccordionDetails-root"]{
        padding: ${(props): string => props.theme.spacing(6)}px;
    }
`

export const StyledQuestionCardHeader: React.FC<Props> = styled(QuestionCardHeader)<Props>`
    & .lzr-cardHeader-title-accent{
        font-weight: 700;
    }
`
