/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStatus {
    PENDING = 'PENDING',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    PAID = 'PAID',
    REVISION_REQUESTED = 'REVISION_REQUESTED',
    DELETED = 'DELETED',
    VOIDED = 'VOIDED',
}