import { Invoice, InvoiceProps } from '../../model/Invoice'
import { InvoiceConsolidation, InvoiceConsolidationProps } from '../../model/InvoiceConsolidation'
import { ApplyPaymentInvoice, ApplyPaymentInvoiceProps } from '../../model/ApplyPaymentInvoice'
import {
    ApiError,
    BatchPaymentResponse,
    BillingService,
    ErrorResponse,
    GenerateConsolidationResponse,
    GenerateInvoiceResponse,
    GenerateStatementOfAccountResponse,
    InvoiceService,
    PaymentAccountResponse,
    PaymentMethodResponse,
    InvoiceStatus,
    CarrierInvoiceResponse,
    CarrierInvoiceTransportTypesResponse,
    ConsolidationFileType,
} from '@lazr/openapi-client'
import { logger } from '../../logger'
import { InvoiceFilter, NewConsolidationInvoiceFilter } from '../../ui/definitions/InvoiceList'
import { UuidV4 } from '@lazr/types'
import { EditInvoiceAttributes, EditInvoiceCsvBatchAttributes, InvoiceAttributes } from '../../model/InvoiceAttributes'
import { handleUnauthorizedException } from './index'
import { Address, Carrier } from '../../model'
import { QuickbooksBatchPayments } from '../../ui/pages/consolidation-payment/components/ConsolidationPayment'
import { Currency } from '@lazr/enums'
import { AddressProps } from '../../model/Address'
import { StatementOfAccountInvoice } from '../../model'
import { StatementOfAccountConsolidation } from '../../model'
import { StatementOfAccountInvoiceProps } from '../../model/StatementOfAccountInvoice'
import { StatementOfAccountConsolidationProps } from '@/app/model/StatementOfAccountConsolidation'
import { CarrierInvoice } from '../../ui/pages/invoice-csv-batch/List/components/carrier-invoice/CarrierInvoiceModal'

export interface InvoicesPaging {
    page?: number
    resultPerPage?: number
}

export interface ConsolidationsPaging {
    page?: number
    resultPerPage?: number
}

export type InvoiceOrderByField = 'external_number' | 'order_number' | 'status' | 'invoice_due_date' |
'quote_price' | 'invoice_price' | 'invoice_date' | 'billing_po' | 'currency' |
'client_organization_name' | 'three_pl_organization_name'
export type InvoiceOrder = 'asc' | 'desc'

export const InvoiceApiService = Object.freeze({
    getById: async (id: UuidV4): Promise<Invoice> => {
        let getInvoiceByIdResponse
        try {
            getInvoiceByIdResponse = await BillingService.getInvoiceById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get order by id')
            }
            throw new Error('Unable to get order by id')
        }

        if (!getInvoiceByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getInvoiceByIdResponse, null, 4))
            throw new Error('Unable to get order by id')
        }

        return new Invoice(getInvoiceByIdResponse.data.invoice)
    },
    invoices: async (paging: InvoicesPaging, filters?: InvoiceFilter) => {
        let getInvoicesResponse
        try {
            getInvoicesResponse = await BillingService.getInvoices(
                paging.page,
                paging.resultPerPage,
                filters?.consolidationId ?? undefined,
                filters?.number ?? undefined,
                filters?.orderNumber ?? undefined,
                filters?.status ?? undefined,
                filters?.billingPo ?? undefined,
                filters?.clientOrganization?.id ?? undefined,
                filters?.types,
                filters?.minDifference ? parseInt(filters?.minDifference, 10) : undefined,
                filters?.maxDifference ? parseInt(filters?.maxDifference, 10) : undefined,
                filters?.pickUpDateFrom ?? undefined,
                filters?.pickUpDateTo ?? undefined,
                filters?.withPOD ?? undefined,
                filters?.orderId,
                filters?.order,
                filters?.orderBy,
                filters?.dueDateFrom ?? undefined,
                filters?.dueDateTo ?? undefined,
                filters?.transportType ?? undefined,
                filters?.billToAddressId ?? undefined,
                filters?.currency ?? undefined,
                filters?.excludeCreditNotes ?? undefined,
                filters?.excludeXeroInvoices ?? undefined,
                filters?.pastDueInvoices ?? undefined,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('invoices', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve invoice list')
            }
            throw new Error('Unable to retrieve invoice list')
        }

        if (!getInvoicesResponse.data) {
            logger.debug('list', JSON.stringify(getInvoicesResponse, null, 4))
            throw new Error('Unable to retrieve invoice list')
        }

        return {
            invoices: getInvoicesResponse.data.invoices.map((invoice: InvoiceProps) => new Invoice(invoice)),
            total: getInvoicesResponse.data.paging.items,
        }
    },
    consolidations: async (paging: ConsolidationsPaging, filters?: InvoiceFilter) => {
        let getConsolidationsResponse
        try {
            getConsolidationsResponse = await BillingService.getConsolidations(
                paging.page,
                paging.resultPerPage,
                filters?.number ?? undefined,
                filters?.orderNumber ?? undefined,
                filters?.status ?? undefined,
                filters?.billingPo ?? undefined,
                filters?.clientOrganization?.id ?? undefined,
                filters?.types,
                filters?.minDifference ? parseInt(filters?.minDifference, 10) : undefined,
                filters?.maxDifference ? parseInt(filters?.maxDifference, 10) : undefined,
                filters?.pickUpDateFrom ?? undefined,
                filters?.pickUpDateTo ?? undefined,
                filters?.withPOD ?? undefined,
                filters?.orderId,
                filters?.dueDateFrom ?? undefined,
                filters?.dueDateTo ?? undefined,
                filters?.transportType ?? undefined,
                filters?.billToAddressId ?? undefined,
                filters?.currency ?? undefined,
                filters?.excludeCreditNotes ?? undefined,
                filters?.excludeXeroInvoices ?? undefined,
                filters?.pastDueInvoices ?? undefined,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('consolidations', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve consolidation list')
            }
            throw new Error('Unable to retrieve consolidation list')
        }
        if (!getConsolidationsResponse.data) {
            logger.debug('consolidations', JSON.stringify(getConsolidationsResponse, null, 4))
            throw new Error('Unable to retrieve consolidation list')
        }

        return {
            consolidations: getConsolidationsResponse.data.consolidations.map(
                // @ts-ignore FIXME: Leftover error after fixing the frontend build on 2022-05-04 by mbiard
                (consolidation: InvoiceConsolidationProps) => new InvoiceConsolidation(consolidation)),
            total: getConsolidationsResponse.data.paging.items,
        }
    },
    send: async (email: string, invoiceId: UuidV4) => {
        let sendOrderInvoiceResponse
        try {
            sendOrderInvoiceResponse = await BillingService.sendOrderInvoice({ invoiceId, contactEmail: email })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('send', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to send invoice')
            }
            throw new Error('Unable to send invoice')
        }
        if (!sendOrderInvoiceResponse.data) {
            logger.debug('send', JSON.stringify(sendOrderInvoiceResponse, null, 4))
            throw new Error('Unable to send invoice')
        }
    },
    sendInvoiceConsolidation: async (email: string, consolidationId: UuidV4) => {
        let sendInvoiceConsolidationResponse
        try {
            sendInvoiceConsolidationResponse = await BillingService.sendInvoiceConsolidation({ consolidationId, contactEmail: email })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('sendInvoiceConsolidation', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to send consolidation')
            }
            throw new Error('Unable to send consolidation')
        }
        if (!sendInvoiceConsolidationResponse.data) {
            logger.debug('send', JSON.stringify(sendInvoiceConsolidationResponse, null, 4))
            throw new Error('Unable to send consolidation')
        }
    },
    resendInvoiceConsolidation: async (emails: string[], consolidationId: UuidV4) => {
        let resendInvoiceConsolidationResponse
        try {
            resendInvoiceConsolidationResponse =
                await BillingService.resendInvoiceConsolidation({ consolidationId, contactEmails: emails })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('resendInvoiceConsolidation', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to resend consolidation')
            }
            throw new Error('Unable to resend consolidation')
        }
        if (!resendInvoiceConsolidationResponse.data) {
            logger.debug('send', JSON.stringify(resendInvoiceConsolidationResponse, null, 4))
            throw new Error('Unable to resend consolidation')
        }
    },
    resendEdiInvoice: async (invoiceId: UuidV4): Promise<boolean> => {
        let resendEdiInvoiceResponse
        try {
            resendEdiInvoiceResponse =
                await BillingService.resendEdiInvoice({ invoiceId })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('resendEdiInvoice', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                return false
            }
            throw new Error('Unable to resend edi invoice')
        }

        return resendEdiInvoiceResponse.success
    },
    resendOrderInvoice: async (emails: string[], invoiceId: UuidV4) => {
        let resendOrderInvoiceResponse
        try {
            resendOrderInvoiceResponse =
                await BillingService.resendOrderInvoice({ invoiceId, contactEmails: emails })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('resendOrderInvoice', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to resend invoice')
            }
            throw new Error('Unable to resend invoice')
        }
        if (!resendOrderInvoiceResponse.data) {
            logger.debug('send', JSON.stringify(resendOrderInvoiceResponse, null, 4))
            throw new Error('Unable to resend invoice')
        }
    },
    invoiceOrder: async (orderInvoiceAttributes: InvoiceAttributes): Promise<void> => {
        let orderInvoiceResponse
        try {
            orderInvoiceResponse = await BillingService.invoiceOrder({
                orderId: orderInvoiceAttributes.orderId,
                totalCad: orderInvoiceAttributes.totalCad,
                totalUsd: orderInvoiceAttributes.totalUsd,
                clientCurrency: orderInvoiceAttributes.clientCurrency,
                transportCurrency: orderInvoiceAttributes.transportCurrency,
                clientOrderInvoiceChargeItems: orderInvoiceAttributes.clientOrderInvoiceChargeItems.map((orderInvoiceChargeItem) =>
                    ({
                        sequenceNumber: orderInvoiceChargeItem.sequenceNumber,
                        totalCad: orderInvoiceChargeItem.totalCad,
                        totalUsd: orderInvoiceChargeItem.totalUsd,
                        code: orderInvoiceChargeItem.code,
                        description: orderInvoiceChargeItem.description,
                        taxes: orderInvoiceChargeItem.taxes,
                    })),
                transportOrderInvoiceChargeItems: orderInvoiceAttributes.transportOrderInvoiceChargeItems.map((orderInvoiceChargeItem) =>
                    ({
                        sequenceNumber: orderInvoiceChargeItem.sequenceNumber,
                        totalCad: orderInvoiceChargeItem.totalCad,
                        totalUsd: orderInvoiceChargeItem.totalUsd,
                        code: orderInvoiceChargeItem.code,
                        description: orderInvoiceChargeItem.description,
                        taxes: orderInvoiceChargeItem.taxes,
                    })),
                pickupDate: orderInvoiceAttributes.pickupDate,
                transportInvoiceNumber: orderInvoiceAttributes.transportInvoiceNumber,
                quoteCarrierInfoId: orderInvoiceAttributes.quoteCarrierInfoId,
                lazrShareTaxes: orderInvoiceAttributes.lazrShareTaxes,
                invoiceDate: orderInvoiceAttributes.invoiceDate,
                addressId: orderInvoiceAttributes.addressId,
                billingPo: orderInvoiceAttributes.billingPo,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('invoiceOrder', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to invoice order')
            }
            throw new Error('Unable to invoice order')
        }

        if (!orderInvoiceResponse.success) {
            logger.debug('invoiceOrder', JSON.stringify(orderInvoiceResponse, null, 4))
            throw new Error('Unable to invoice order')
        }
    },
    editInvoice: async (editInvoiceAttributes: EditInvoiceAttributes): Promise<void> => {
        let editInvoiceResponse
        try {
            editInvoiceResponse = await BillingService.editInvoiceDetails({
                invoiceId: editInvoiceAttributes.invoiceId,
                billingAddressId: editInvoiceAttributes.billingAddressId,
                billingPo: editInvoiceAttributes.billingPo,
                originPo: editInvoiceAttributes.originPo,
                destinationPo: editInvoiceAttributes.destinationPo,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('editInvoice', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit invoice')
            }
            throw new Error('Unable to invoice order')
        }

        if (!editInvoiceResponse.success) {
            logger.debug('editInvoice', JSON.stringify(editInvoiceResponse, null, 4))
            throw new Error('Unable to edit invoice')
        }
    },
    generateInvoice: async (
        invoiceId: UuidV4,
    ): Promise<GenerateInvoiceResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the Invoice'

        try {
            const res = await InvoiceService.generateInvoice({
                invoiceId: invoiceId,
            })

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    generateConsolidation: async (
        consolidationId: UuidV4,
        fileType: ConsolidationFileType
    ): Promise<GenerateConsolidationResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the Consolidation'

        try {
            const res = await InvoiceService.generateConsolidation({
                consolidationId: consolidationId,
                fileType,
            })

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    generateStatementOfAccount: async (): Promise<GenerateStatementOfAccountResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the Statement of Account'

        try {
            const res = await InvoiceService.generateStatementOfAccount()

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    applyInvoiceBatchPayments: async (
        batchPayments: QuickbooksBatchPayments,
        currency: Currency,
    ): Promise<BatchPaymentResponse | string> => {
        const genericError = 'An unexpected error occurred while applying the payments'

        try {
            return await BillingService.batchPayments(currency, {
                batchPayments: batchPayments,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },

    getInvoicePaymentAccounts: async (
        currency: Currency, enablePaymentsToAccount: boolean,
    ): Promise<PaymentAccountResponse | string> => {
        const genericError = 'An unexpected error occurred while fetching the accounts'

        try {
            const res = await BillingService.getAccounts(currency, enablePaymentsToAccount)

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    getInvoicePaymentMethods: async (): Promise<PaymentMethodResponse | string> => {
        const genericError = 'An unexpected error occurred while fetching the payment methods'

        try {
            const res = await BillingService.getPaymentMethods()

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    getStatementOfAccount: async () => {
        let getStatementOfAccountResponse
        try {
            getStatementOfAccountResponse = await BillingService.getStatementOfAccount()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getStatementOfAccount', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get statement of account')
            }
            throw new Error('Unable to get statement of account')
        }

        if (!getStatementOfAccountResponse.data) {
            logger.debug('getStatementOfAccount', JSON.stringify(getStatementOfAccountResponse, null, 4))
            throw new Error('Unable to get statement of account')
        }


        return { consolidations:
        // @ts-ignore
                getStatementOfAccountResponse.data.consolidations.map((consolidation : StatementOfAccountConsolidationProps) =>
                    new StatementOfAccountConsolidation(consolidation)),
        invoices:
                getStatementOfAccountResponse.data.invoices.map((invoice : StatementOfAccountInvoiceProps) =>
                    new StatementOfAccountInvoice(invoice)),
        clientName: getStatementOfAccountResponse.data.clientName,
        defaultBillingAddressCompanyName: getStatementOfAccountResponse.data.defaultBillingAddressCompanyName,
        type: getStatementOfAccountResponse.data.type,
        address: getStatementOfAccountResponse.data.address,
        invoiceTotalCad: getStatementOfAccountResponse.data.invoiceTotalCad,
        currentTotalCad: getStatementOfAccountResponse.data.currentTotalCad,
        thirtyDaysTotalCad: getStatementOfAccountResponse.data.thirtyDaysTotalCad,
        sixtyDaysTotalCad: getStatementOfAccountResponse.data.sixtyDaysTotalCad,
        ninetyPlusDaysTotalCad: getStatementOfAccountResponse.data.ninetyPlusDaysTotalCad,
        invoiceTotalUsd: getStatementOfAccountResponse.data.invoiceTotalUsd,
        currentTotalUsd: getStatementOfAccountResponse.data.currentTotalUsd,
        thirtyDaysTotalUsd: getStatementOfAccountResponse.data.thirtyDaysTotalUsd,
        sixtyDaysTotalUsd: getStatementOfAccountResponse.data.sixtyDaysTotalUsd,
        ninetyPlusDaysTotalUsd: getStatementOfAccountResponse.data.ninetyPlusDaysTotalUsd,
        paymentTerm: getStatementOfAccountResponse.data.paymentTerm,
        }
    },
    getNewConsolidationInvoices: async (filters: NewConsolidationInvoiceFilter) => {
        let getNewConsolidationInvoicesResponse

        try {
            if (!filters.clientOrganization || !filters.transportType || !filters.billToAddress ||
                !filters.consolidationType || !filters.currency) {
                return { invoices: [] }
            }
            getNewConsolidationInvoicesResponse = await BillingService.getNewConsolidationInvoices(
                filters.clientOrganization.id,
                filters.transportType,
                filters.billToAddress.id,
                filters.consolidationType,
                filters.currency,
                filters.invoiceDueDateFrom,
                filters.invoiceDueDateTo,
                filters.pickUpDateFrom,
                filters.pickUpDateTo,
                filters?.order,
                filters?.invoiceEmailSentStatus,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getNewConsolidationInvoices', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve new consolidation invoice list')
            }
            throw new Error('Unable to retrieve new consolidation invoice list')
        }
        if (!getNewConsolidationInvoicesResponse.data) {
            logger.debug('consolidations', JSON.stringify(getNewConsolidationInvoicesResponse, null, 4))
            throw new Error('Unable to retrieve new consolidation invoice list')
        }

        return {
            invoices: getNewConsolidationInvoicesResponse.data.invoices.map((invoice: InvoiceProps) => new Invoice(invoice)),
        }
    },
    getBillingAddressesByOrganizationId: async (organizationId: UuidV4) => {
        let getBillingAddressesResponse

        try {
            getBillingAddressesResponse = await BillingService.getBillingAddresses(organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getBillingAddressesByOrganizationId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve billing addresslist')
            }
            throw new Error('Unable to retrieve billing address list')
        }
        if (!getBillingAddressesResponse.data) {
            logger.debug('getBillingAddressesByOrganizationId', JSON.stringify(getBillingAddressesResponse, null, 4))
            throw new Error('Unable to retrieve billing address list')
        }

        return {
            billingAdresses: getBillingAddressesResponse.data.addresses.map((address: AddressProps) => new Address(address)),
        }
    },

    getShippingAddressesByOrganizationId: async (organizationId: UuidV4) => {
        let getShippingAddressesResponse

        try {
            getShippingAddressesResponse = await BillingService.getShippingAddresses(organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getShippingAddressesByOrganizationId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve shipping address list')
            }
            throw new Error('Unable to retrieve shipping address list')
        }
        if (!getShippingAddressesResponse.data) {
            logger.debug('getShippingAddressesByOrganizationId', JSON.stringify(getShippingAddressesResponse, null, 4))
            throw new Error('Unable to retrieve shipping address list')
        }

        return {
            shippingAdresses: getShippingAddressesResponse.data.addresses.map((address: AddressProps) => new Address(address)),
        }
    },
    createConsolidation: async (address: Address | null, invoiceIds: UuidV4[]) => {
        let createInvoiceConsolidationResponse
        if (!address) {
            throw new Error('Unable to create consolidation due to missing address')
        }
        if (invoiceIds.length === 0) {
            throw new Error('Unable to create consolidation due to missing invoices')
        }
        try {
            createInvoiceConsolidationResponse = await BillingService.createInvoiceConsolidation({
                addressId: address.id,
                invoiceIds: invoiceIds,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('createConsolidation', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to create invoice consolidation')
            }
            throw new Error('Unable to create invoice consolidation')
        }

        if (!createInvoiceConsolidationResponse.success) {
            logger.debug('createConsolidation', JSON.stringify(createInvoiceConsolidationResponse, null, 4))
            throw new Error('Unable to create invoice consolidation')
        }
    },
    getCarrierInvoiceTransportTypes: async (carrier: Carrier): Promise<CarrierInvoiceTransportTypesResponse> => {
        let getCarrierInvoiceTransportResponse
        try {
            getCarrierInvoiceTransportResponse = await BillingService.getCarrierInvoiceTransportTypes(carrier.name)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getCarrierInvoiceTransportTypes', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve carrier invoice transport types')
            }
            throw new Error('Unable to retrieve carrier invoice transport types')
        }

        if (!getCarrierInvoiceTransportResponse.data) {
            logger.debug('list', JSON.stringify(getCarrierInvoiceTransportResponse, null, 4))
            throw new Error('Unable to retrieve carrier invoice transport types')
        }

        return  getCarrierInvoiceTransportResponse.data
    },
    processCarrierInvoice: async (
        carrierInvoice: CarrierInvoice,
    ): Promise<CarrierInvoiceResponse | string> => {
        const genericError = 'An unexpected error occurred while processing the file'

        try {
            return await BillingService.processCarrierInvoice(carrierInvoice)
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    voidInvoice: async (
        params: {
            invoiceId: UuidV4
            status: InvoiceStatus.DELETED | InvoiceStatus.VOIDED
        },
    ): Promise<boolean | string> => {
        const genericError = 'An unexpected error occurred while voiding the invoice'

        try {
            const res = await BillingService.voidInvoice(params)

            if (res.data?.success) {
                return res.data?.success
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
    getApplyPaymentInvoices: async (paging: InvoicesPaging, filters?: InvoiceFilter) => {
        let getInvoicesResponse
        try {
            getInvoicesResponse = await BillingService.getApplyPaymentInvoices(
                paging.page,
                paging.resultPerPage,
                filters?.status ?? undefined,
                filters?.clientOrganization?.id ?? undefined,
                filters?.pickUpDateFrom ?? undefined,
                filters?.pickUpDateTo ?? undefined,
                filters?.order,
                filters?.orderBy,
                filters?.dueDateFrom ?? undefined,
                filters?.dueDateTo ?? undefined,
                filters?.billToAddressId ?? undefined,
                filters?.currency ?? undefined,
                filters?.excludeCreditNotes ?? undefined,
                filters?.excludeXeroInvoices ?? undefined,
                filters?.types,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('invoices', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve invoice list')
            }
            throw new Error('Unable to retrieve invoice list')
        }

        if (!getInvoicesResponse.data) {
            logger.debug('list', JSON.stringify(getInvoicesResponse, null, 4))
            throw new Error('Unable to retrieve invoice list')
        }

        return {
            invoices: getInvoicesResponse.data.invoices.map((invoice: ApplyPaymentInvoiceProps) => new ApplyPaymentInvoice(invoice)),
            total: getInvoicesResponse.data.paging.items,
        }
    },
    getDefaultBillingUserAddressById: async (addressId: UuidV4, organizationId?: UuidV4):
    Promise<Address> => {
        let getDefaultBillingUserAddressByIdResponse
        try {
            getDefaultBillingUserAddressByIdResponse = await BillingService.getDefaultBillingUserAddressById(addressId, organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getDefaultBillingUserAddressById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get default billing user address by id')
            }
            throw new Error('Unable to get default billing user address by id')
        }

        if (!getDefaultBillingUserAddressByIdResponse.data) {
            logger.debug('getDefaultBillingUserAddressById', JSON.stringify(getDefaultBillingUserAddressByIdResponse, null, 4))
            throw new Error('Unable to get default billing user addresss by id')
        }

        return new Address(getDefaultBillingUserAddressByIdResponse.data)
    },
    getDefaultBillingUserAddressByIds: async (addressIds: string[], organizationId: UuidV4) => {
        let getDefaultBillingUserAddressByIdsResponse
        try {
            getDefaultBillingUserAddressByIdsResponse = await BillingService.getDefaultBillingUserAddress(
                addressIds,
                organizationId,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getDefaultBillingUserAddressByIds', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get default billing user address by ids')
            }
            throw new Error('Unable to get default billing user address by ids')
        }

        if (!getDefaultBillingUserAddressByIdsResponse.success && !getDefaultBillingUserAddressByIdsResponse.data) {
            logger.debug('getDefaultBillingUserAddressByIds', JSON.stringify(getDefaultBillingUserAddressByIdsResponse, null, 4))
            throw new Error('Unable to get default billing user addresss by ids')
        }

        return getDefaultBillingUserAddressByIdsResponse.data
    },
    removeDefaultBillingUserAddresses: async (addressId: string): Promise<string | null> => {
        let removeDefaultBillingUserAddressesResponse
        try {
            removeDefaultBillingUserAddressesResponse = await BillingService.removeDefaultBillingUserAddresses(addressId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('removeDefaultBillingUserAddresses', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to remove default billing user addresses')
            }
            throw new Error('Unable to remove default billing user addresses')
        }

        if (!removeDefaultBillingUserAddressesResponse.data) {
            logger.debug('removeDefaultBillingUserAddresseses', JSON.stringify(removeDefaultBillingUserAddressesResponse, null, 4))
            throw new Error('Unable to remove default billing user addresses')
        }

        return removeDefaultBillingUserAddressesResponse.data.id
    },
    editInvoiceCsvBatch: async (editInvoiceCsvBatchAttributes: EditInvoiceCsvBatchAttributes): Promise<string | null> => {
        let editInvoiceDateResponse
        try {
            editInvoiceDateResponse = await BillingService.editOrderInvoiceCsvBatch(
                {
                    id: editInvoiceCsvBatchAttributes.invoiceCsvBatchId,
                    invoiceDate: editInvoiceCsvBatchAttributes.invoiceDate,
                })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('editInvoiceDate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit invoice date')
            }
            throw new Error('Unable to edit invoice date')
        }

        if (!editInvoiceDateResponse.data) {
            logger.debug('editInvoiceDate', JSON.stringify(editInvoiceDateResponse, null, 4))
            throw new Error('Unable to edit invoice date')
        }

        return editInvoiceDateResponse.data.id
    },
    deletePriceReview: async (params: {
        id: UuidV4
        orderId: UuidV4
    }): Promise<boolean | string> => {
        const genericError = 'An unexpected error occurred while deleting the price review'

        try {
            const res = await BillingService.deletePriceReview(params)

            if (res.data?.success) {
                return res.data?.success
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
})
