import { v4 as uuidv4 } from 'uuid'

// REDUX =========================================================================
export const SET_THEME = 'SET_THEME'
export const SET_APP_STATE = 'SET_APP_STATE'
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST'
export const SET_USER = 'SET_USER'
export const SET_USER_IS_SIGNED_IN = 'SET_USER_IS_SIGNED_IN'
export const SET_USER_ORGANIZATION = 'SET_USER_ORGANIZATION'
export const SET_USER_FILTER = 'SET_USER_FILTER'
export const SET_USER_LIST_ORGANIZATION_LIST = 'SET_USER_LIST_ORGANIZATION_LIST'
export const SET_SNACKBAR = 'SET_SNACKBAR'
export const SET_NOTISTACK = 'SET_NOTISTACK'
export const SET_ORDER_LIST = 'SET_ORDER_LIST'
export const SET_ORDER_EVENTS = 'SET_ORDER_EVENTS'
export const SET_VERSION = 'SET_VERSION'
export const SET_VERSION_SNACKBACK_OPEN = 'SET_VERSION_SNACKBACK_OPEN'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_LOCALE_LIST = 'SET_LOCALE_LIST'
export const SET_TIMEZONE_LIST = 'SET_TIMEZONE_LIST'
export const SET_USER_TITLE_LIST = 'SET_USER_TITLE_LIST'
export const SET_CURRENCY_LIST = 'SET_CURRENCY_LIST'
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST'
export const SET_DEFAULT_USER_CURRENCY = 'SET_DEFAULT_USER_CURRENCY'
export const SET_LANES = 'SET_LANES'
export const SET_USER_BILL_TO_ADDRESSES = 'SET_USER_BILL_TO_ADDRESSES'
export const SET_REQUEST_DEMO_DIALOG_OPEN = 'SET_REQUEST_DEMO_DIALOG_OPEN'
export const SET_REGISTER_DIALOG_OPEN = 'SET_REGISTER_DIALOG_OPEN'


// New Marketplace Actions names
export const SET_MARKET_PLACE_ADDRESS_INFORMATION = 'SET_MARKET_PLACE_ADDRESS_INFORMATION'
export const SET_MARKET_PLACE_RFQ_INFORMATION = 'SET_MARKET_PLACE_RFQ_INFORMATION'
export const SET_MARKET_PLACE_ADDRESS_ACCESSORIALS = 'SET_MARKET_PLACE_ADDRESS_ACCESSORIALS'
export const SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS = 'SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS'
export const SET_MARKET_PLACE_CARGO_ACCESSORIALS = 'SET_MARKET_PLACE_CARGO_ACCESSORIALS'
export const SET_MARKET_PLACE_ORDER_INFORMATION = 'SET_MARKET_PLACE_ORDER_INFORMATION'
export const SET_MARKET_PLACE_TRANSPORT_INFORMATION = 'SET_MARKET_PLACE_TRANSPORT_INFORMATION'
export const SET_MARKET_PLACE = 'SET_MARKET_PLACE'
export const PRELOAD_MARKET_PLACE = 'PRELOAD_MARKET_PLACE'
export const SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE = 'SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE'
export const SET_MARKET_PLACE_COMMODITY_TYPE = 'SET_MARKET_PLACE_COMMODITY_TYPE'
export const SET_MARKET_PLACE_BILLING_CURRENCY = 'SET_MARKET_PLACE_BILLING_CURRENCY'
export const SET_MARKET_PLACE_SELECTED_QUOTE = 'SET_MARKET_PLACE_SELECTED_QUOTE'
export const SET_MARKET_PLACE_QUOTES = 'SET_MARKET_PLACE_QUOTES'
export const SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE = 'SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE'
export const ADD_MARKET_PLACE_HANDLING_UNIT = 'ADD_MARKET_PLACE_HANDLING_UNIT'
export const SET_MARKET_PLACE_HANDLING_UNIT = 'SET_MARKET_PLACE_HANDLING_UNIT'
export const DELETE_MARKET_PLACE_HANDLING_UNIT = 'DELETE_MARKET_PLACE_HANDLING_UNIT'
export const RESET_MARKET_PLACE_CARGO = 'RESET_MARKET_PLACE_CARGO'
export const RESET_MARKET_PLACE_DUTY_BILL_ADDRESS = 'RESET_MARKET_PLACE_DUTY_BILL_ADDRESS'
export const RESET_MARKET_PLACE_CUSTOMS_DETAILS = 'RESET_MARKET_PLACE_CUSTOMS_DETAILS'
export const RESET_MARKET_PLACE_HAZMAT_DETAIL = 'RESET_MARKET_PLACE_HAZMAT_DETAIL'
export const RESET_MARKET_PLACE_CUSTOMS_INFO = 'RESET_MARKET_PLACE_CUSTOMS_INFO'
export const RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS = 'RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS'
export const ADD_MARKET_PLACE_CUSTOMS_DETAIL = 'ADD_MARKET_PLACE_CUSTOMS_DETAIL'
export const DELETE_MARKET_PLACE_CUSTOMS_DETAIL = 'DELETE_MARKET_PLACE_CUSTOMS_DETAIL'
export const MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL = 'MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL'
export const SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL = 'SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL'
export const SET_MARKET_PLACE_PLACES_AUTOCOMPLETE = 'SET_MARKET_PLACE_PLACES_AUTOCOMPLETE'
export const SET_MARKET_PLACE_MARKET_PLACE_STEP = 'SET_MARKET_PLACE_MARKET_PLACE_STEP'
export const SET_MARKET_PLACE_COMPLETED_STEP = 'SET_MARKET_PLACE_COMPLETED_STEP'
export const SET_MARKET_PLACE_ACCESSORIAL_LISTS = 'SET_MARKET_PLACE_ACCESSORIAL_LISTS'
export const SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST = 'SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST'
export const SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST = 'SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST'
export const SET_MARKET_PLACE_PACKAGE_TYPE_LIST = 'SET_MARKET_PLACE_PACKAGE_TYPE_LIST'
export const SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST = 'SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST'
export const SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE = 'SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE'
export const SET_MARKET_PLACE_SCHEDULE_PICKUP = 'SET_MARKET_PLACE_SCHEDULE_PICKUP'
export const SET_MARKET_PLACE_ERRORS = 'SET_MARKET_PLACE_ERRORS'
export const SET_MARKET_PLACE_ERRORS_IS_SUBMITTED = 'SET_MARKET_PLACE_ERRORS_IS_SUBMITTED'
export const SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN = 'SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN'
export const SET_MARKET_PLACE_INVALIDATION_DIALOG = 'SET_MARKET_PLACE_INVALIDATION_DIALOG'

export const ADD_ORDER_DETAILS_CUSTOMS_DETAIL = 'ADD_ORDER_DETAILS_CUSTOMS_DETAIL'
export const DELETE_ORDER_DETAILS_CUSTOMS_DETAIL = 'DELETE_ORDER_DETAILS_CUSTOMS_DETAIL'
export const SET_SYSTEM_STATS = 'SET_SYSTEM_STATS'
export const SET_3PL_ADDITIONAL_INFO = 'SET_3PL_ADDITIONAL_INFO'
export const SET_3PL_ESTIMATED_TRANSIT_MIN = 'SET_3PL_ESTIMATED_TRANSIT_MIN'
export const SET_3PL_ESTIMATED_TRANSIT_MAX = 'SET_3PL_ESTIMATED_TRANSIT_MAX'
export const SET_3PL_ETA = 'SET_3PL_ETA'
export const SET_3PL_VALID_UNTIL = 'SET_3PL_VALID_UNTIL'
export const SET_LAST_INVOICE_PICKUP_DATE = 'SET_LAST_INVOICE_PICKUP_DATE'
export const SET_TRANSPORT_SELECTED_INVOICE_DOCUMENTS = 'SET_TRANSPORT_SELECTED_INVOICE_DOCUMENTS'
export const SET_SELECTED_TRANSPORT_INVOICE_NUMBER = 'SET_SELECTED_TRANSPORT_INVOICE_NUMBER'
export const SET_SELECTED_CLIENT_TAXES = 'SET_SELECTED_CLIENT_TAXES'
export const SET_SELECTED_LAZR_SHARE_TAXES = 'SET_SELECTED_LAZR_SHARE_TAXES'
export const SET_SELECTED_TRANSPORT_TAXES = 'SET_SELECTED_TRANSPORT_TAXES'
export const SET_INVOICE_DATE = 'SET_INVOICE_DATE'
export const SET_CALCULATED_DUE_DATE = 'SET_CALCULATED_DUE_DATE'
export const SET_CARRIER_PAYMENT_TERMS_DAYS = 'SET_CARRIER_PAYMENT_TERMS_DAYS'
export const SET_ORDER_DETAILS_MARKETPLACE_ERRORS_IS_SUBMITTED = 'SET_ORDER_DETAILS_MARKETPLACE_ERRORS_IS_SUBMITTED'
export const SET_ORDER_DETAILS_MARKETPLACE_ERRORS = 'SET_ORDER_DETAILS_MARKETPLACE_ERRORS'
export const SET_ORDER_DETAILS_PENDING_PRICE_REVIEW_ERRORS = 'SET_ORDER_DETAILS_PENDING_PRICE_REVIEW_ERRORS'
export const SET_ORDER_DETAILS_MARKETPLACE_INITIAL_STATE = 'SET_ORDER_DETAILS_MARKETPLACE_INITIAL_STATE'
export const SET_ORDER_DETAILS_ORDER = 'SET_ORDER_DETAILS_ORDER'
export const SET_ORDER_DETAILS_EDITABLE_ORDER = 'SET_ORDER_DETAILS_EDITABLE_ORDER'
export const SET_ORDER_DETAILS_EDITABLE_RFQ = 'SET_ORDER_DETAILS_EDITABLE_RFQ'
export const SET_ORDER_DETAILS_EDITABLE_CUSTOMS_DETAIL = 'SET_ORDER_DETAILS_EDITABLE_CUSTOMS_DETAIL'
export const SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS = 'SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS'
export const SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS_IS_SUBMITTED = 'SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS_IS_SUBMITTED'
export const SET_ORDER_DETAILS_ORDER_BILLING_ERRORS = 'SET_ORDER_DETAILS_ORDER_BILLING_ERRORS'
export const SET_ORDER_DETAILS_ORDER_BILLING_ERRORS_IS_SUBMITTED = 'SET_ORDER_DETAILS_ORDER_BILLING_ERRORS_IS_SUBMITTED'
export const RESET_ORDER_DETAIL_DUTY_BILL_ADDRESS = 'RESET_ORDER_DETAIL_DUTY_BILL_ADDRESS,'
export const RESET_ORDER_DETAIL_CUSTOM_QUOTE_STATE = 'RESET_ORDER_DETAIL_CUSTOM_QUOTE_STATE'
export const SET_CURRENCY_RATE = 'SET_CURRENCY_RATE'
export const SET_QUOTE_COST_CHARGES = 'SET_QUOTE_COST_CHARGES'
export const SET_PENDING_PRICE_REVIEW_COST_CHARGES = 'SET_PENDING_PRICE_REVIEW_COST_CHARGES'
export const SET_3PL_CURRENCY = 'SET_3PL_CURRENCY'
export const SET_PENDING_PRICE_REVIEW_CURRENCY = 'SET_PENDING_PRICE_REVIEW_CURRENCY'
export const SET_ORDER_FILTER = 'SET_ORDER_FILTER'
export const SET_INVOICE_FILTER = 'SET_INVOICE_FILTER'
export const SET_NEW_CONSOLIDATION_INVOICE_FILTER = 'SET_NEW_CONSOLIDATION_INVOICE_FILTER'
export const SET_INVOICE_LIST_ORGANIZATION_LIST = 'SET_INVOICE_LIST_ORGANIZATION_LIST'
export const SET_INVOICE_LIST_OTHER_USER_INFO_LIST = 'SET_INVOICE_LIST_OTHER_USER_INFO_LIST'
export const SET_ORDER_CLOSE_TIMER = 'SET_ORDER_CLOSE_TIMER'
export const ADD_ORDER_CLOSE_INTERVAL = 'ADD_ORDER_CLOSE_INTERVAL'
export const ADD_ORDER_DOCUMENTS = 'ADD_ORDER_DOCUMENTS'
export const DELETE_ORDER_INTERVAL = 'DELETE_ORDER_INTERVAL'
export const SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED = 'SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED'
export const SET_ORDER_LIST_OTHER_USER_INFO_LIST = 'SET_ORDER_LIST_OTHER_USER_INFO_LIST'
export const SET_ORDER_LIST_THREE_PL_AGENT_LIST = 'SET_ORDER_LIST_THREE_PL_AGENT_LIST'
export const SET_ORDER_LIST_ORGANIZATION_LIST = 'SET_ORDER_LIST_ORGANIZATION_LIST'
export const SET_ORDER_LIST_CARRIER_LIST = 'SET_ORDER_LIST_CARRIER_LIST'
export const SET_QUOTE_COST_CHARGE = 'SET_QUOTE_COST_CHARGE'
export const SET_PENDING_PRICE_REVIEW_COST_CHARGE = 'SET_PENDING_PRICE_REVIEW_COST_CHARGE'
export const ADD_QUOTE_COST_CHARGE = 'ADD_QUOTE_COST_CHARGE'
export const ADD_PENDING_PRICE_REVIEW_COST_CHARGE = 'ADD_PENDING_PRICE_REVIEW_COST_CHARGE'
export const DELETE_QUOTE_COST_CHARGE = 'DELETE_QUOTE_COST_CHARGE'
export const DELETE_PENDING_PRICE_REVIEW_COST_CHARGE = 'DELETE_PENDING_PRICE_REVIEW_COST_CHARGE'
export const SET_ORDER_DETAIL_CHARGES = 'SET_ORDER_DETAIL_CHARGES'
export const SET_DEFAULT_BILLING_ADDRESSES = 'SET_DEFAULT_BILLING_ADDRESSES'
export const SET_DEFAULT_SHIPPING_ADDRESSES = 'SET_DEFAULT_SHIPPING_ADDRESSES'

export const SET_IS_DEFAULT_BILLING_ADDRESSES_CALL_EXECUTED = 'SET_IS_DEFAULT_BILLING_ADDRESSES_CALL_EXECUTED'
export const SET_PROMPT_MODAL_LEAVE = 'SET_PROMPT_MODAL_LEAVE'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK
    = 'SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK
    = 'SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS
    = 'SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS'
export const SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS
    = 'SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS'
export const SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED
    = 'SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED'
export const SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED
    = 'SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED'
export const SET_NEW_PICKUP_DATE = 'SET_NEW_PICKUP_DATE'
export const SET_CONSOLIDATION_PAYMENT_FILTER = 'SET_CONSOLIDATION_PAYMENT_FILTER'
export const SET_CONSOLIDATION_PAYMENT_LIST_ORGANIZATION_LIST = 'SET_CONSOLIDATION_PAYMENT_LIST_ORGANIZATION_LIST'
export const SET_APPLY_PAYMENT_INVOICES = 'SET_APPLY_PAYMENT_INVOICES'
export const SET_APPLY_PAYMENT_CONSOLIDATIONS = 'SET_APPLY_PAYMENT_CONSOLIDATIONS'
export const ADD_APPLY_PAYMENT_INVOICE = 'ADD_APPLY_PAYMENT_INVOICE'
export const ADD_APPLY_PAYMENT_CONSOLIDATION = 'ADD_APPLY_PAYMENT_CONSOLIDATION'
export const DELETE_APPLY_PAYMENT_INVOICE = 'DELETE_APPLY_PAYMENT_INVOICE'
export const DELETE_APPLY_PAYMENT_CONSOLIDATION = 'DELETE_APPLY_PAYMENT_CONSOLIDATION'
export const UPDATE_APPLY_PAYMENT_CONSOLIDATION = 'UPDATE_APPLY_PAYMENT_CONSOLIDATION'
export const UPDATE_APPLY_PAYMENT_INVOICE = 'UPDATE_APPLY_PAYMENT_INVOICE'
export const RESET_APPLY_PAYMENT = 'RESET_APPLY_PAYMENT'
export const SET_ADDRESS_FILTER = 'SET_ADDRESS_FILTER'
export const SET_EDI_FILTER = 'SET_EDI_FILTER'

export const SET_LANE_FILTER = 'SET_LANE_FILTER'
export const TOGGLE_REASONS_MODAL = 'TOGGLE_REASONS_MODAL'
export const TOGGLE_FEDEX_GROUND_MODAL = 'TOGGLE_FEDEX_GROUND_MODAL'
export const SET_ORDER_DETAILS_CUSTOMS_DETAIL = 'SET_ORDER_DETAILS_CUSTOMS_DETAIL'
export const SET_ORDER_LIST_SALES_REP_LIST = 'SET_ORDER_LIST_SALES_REP_LIST'
export const SET_ORGANIZATION_FILTER = 'SET_ORGANIZATION_FILTER'
export const SET_ORGANIZATION_LIST_SALES_REP_LIST = 'SET_ORGANIZATION_LIST_SALES_REP_LIST'
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export const SET_COLLECT_INFORMATION = 'SET_COLLECT_INFORMATION'
export const SET_USER_LANES = 'SET_USER_LANES'
export const SET_USER_SELECTED_LANE = 'SET_USER_SELECTED_LANE'
export const SET_ETD_ENABLED = 'SET_ETD_ENABLED'
export interface Action {
    type: string
    payload: any
}

// ROLES =========================================================================

export const CLIENT = 'Client'
export const AGENT = 'Agent'
export const OPERATOR = 'Operator'

// KEYS =========================================================================

export const GOOGLE_API_KEY = 'AIzaSyCC-YRcUTboPCafM067DOIed6bIyVsNGQk'

// SCRIPTS =========================================================================

export const GOOGLE_API_URL = `//maps.googleapis.com/maps/api/js?language=en&key=${GOOGLE_API_KEY}&libraries=places`
export const SALESFORCE_CRM_SCRIPT_URL = '/scripts/salesforceCrm.js'

// CREDENTIALS =========================================================================

export const MIN_PASSWORD_LENGTH = 8

// QUOTE STATUSES =========================================================================

// 0%: Draft
// 25%: Sent to agent, Custom quote requested
// 50%: In revision, Revised, Custom quote completed
// 75%: Accepted
// 100%: Approved, Completed, Expired, Cancelled, Deleted

// Client actions required
export const DRAFT = 'Draft' // Need to send to agent
export const REVISED = 'Revised' // Need to accept
export const CUSTOM_QUOTE_COMPLETED = 'Custom Quote Completed' // Need to accept

// Agent actions required
export const SENT_TO_AGENT = 'Sent to Agent' // Need to revise or approve
export const CUSTOM_QUOTE_REQUESTED = 'Custom Quote Requested' // Need to complete custom quote
export const IN_REVISION = 'In Revision' // Need to end revision
export const ACCEPTED = 'Accepted' // Need to approve (could change this to "carrier booked" or something)
export const APPROVED = 'Approved' // Need to complete

// End of life for quote
export const COMPLETED = 'Completed' // Set by agent once delivered
export const EXPIRED = 'Expired' // Set automatically using quote information at creation
export const CANCELLED = 'Cancelled' // Set by client before approval or by agent at any time
export const DELETED = 'Deleted' // Set by agent admin or operator at any time

// SHIPMENT TRACKING STATUSES =========================================================================

// 0%: Not available
// 25%: Scheduled for pickup
// 50%: In transit, Delayed (shown as red)
// 75%: Out for delivery
// 100%: Delivered

export const ORDERED = 'Ordered'
export const SCHEDULED_FOR_PICKUP = 'Scheduled for pickup'
export const IN_TRANSIT = 'In transit'
export const DELAYED_ON_HOLD = 'Delayed (On hold)'
export const DELAYED_CUSTOMS = 'Delayed (Customs)'
export const DELAYED_OTHER = 'Delayed (Other)'
export const OUT_FOR_DELIVERY = 'Out for delivery'
export const DELIVERED = 'Delivered'

// TYPES OF TRANSPORT =========================================================================

export const LTL_DRY = 'LTL Dry'
export const LTL_REEFER = 'LTL Reefer'
export const LTL_SPECIALIZED = 'LTL Specialized'
export const FTL_DRY = 'FTL Dry'
export const FTL_REEFER = 'FTL Reefer'
export const FTL_SPECIALIZED = 'FTL Specialized'
export const OTHER = 'OTHER'

// TIME RANGES =========================================================================

export const TODAY = 'Today'
export const YESTERDAY = 'Yesterday'
export const LAST7DAYS = 'Last 7 Days'
export const LAST30DAYS = 'Last 30 Days'
export const THISMONTH = 'This Month'
export const LASTMONTH = 'Last Month'
export const THISYEAR = 'This Year'
export const LASTYEAR = 'Last Year'

// TESTING AREA (TEMPORARY) =========================================================================

export const USER_ID = uuidv4()
