/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import React from 'react'

export default ((): Translations<typeof en> => ({
    ['Cookie Policy']: 'Politique relative aux cookies',
    [`This site uses cookies to remember your preferences across different visits and otherwise provide you with a great user experience.`]: () => (
        <>
            Ce site utilise des cookies pour mémoriser vos préférences lors de différentes visites et vous offrir une expérience utilisateur exceptionnelle.<br/>
            Les cookies nous aident également à comprendre comment l'application est utilisée pour des améliorations continues d'une manière qui compte pour nos utilisateurs.<br/>
            En utilisant Lazr, vous acceptez notre utilisation des cookies.
        </>
    ),
    [`Decline`]: 'Refuser',
    [`Accept`]: 'Accepter',
}))()
