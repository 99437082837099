/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './BannerDesktop.i18n.en'
import { Translations } from '@lazr/i18n'
import React from 'react'

export default ((): Translations<typeof en> => ({
    [`One shipping software to do it all`]: () => (<>Un seul logiciel<br/>d&apos;expédition pour<br/>tout faire</>),
    [`Get access to the largest freight network in North America for less than truckload (LTL) & parcel shipping. Discover new shipping services, cut shipping costs, and manage everything with one simple solution.`]: `Accédez au plus grand réseau de transporteurs de marchandises en Amérique du Nord pour l'expédition de palettes et de colis. Découvrez de nouveaux services, réduisez les coûts d'expédition et gérez tout avec une solution simple.`,
    [`Get shipping rates`]: `Consultez nos tarifs`,
    [`Get Lazr for free`]: `Obtenez Lazr gratuitement`,
}))()
