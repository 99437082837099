/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['Sign Up']: 'Sign Up',
    ['Sign In']: 'Sign In',
    ['Register']: 'Register',
    ['Email']: 'Email',
    ['Password']: 'Password',
    ['Welcome back,']: 'Welcome back,',
    ['Welcome to Lazr']: 'Welcome to Lazr',
    ['Create your Organization']: 'Create your Organization',
    ['Create your Account']: 'Create your Account',
    ['Register Your Account']: 'Register Your Account',
}
