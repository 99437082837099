/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import type en from './AddressForm.i18n.en'
import type { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'select between': 'sélectionnez entre',
    'must be at least qtd characters long':'doit contenir au moins ≤≤qtd≥≥ caractères',
    or: 'ou',
    'field is required': 'champ requis',
    'Address type': "Type d'adresse",
    Commercial: "Commercial",
    Residential: "Résidentiel",
    Street: "Rue",
    Apt: "Apt",
    City: "Ville",
    'Postal code': "Code postal",
    'Prov/St': "Prov/État",
    Country: "Pays",
    'Company name': "Nom de l'entreprise",
    // 'Shipping hours': "Heures d'expédition",
    // to: 'à',
    // Opening: "Ouverture",
    // Closing: "Fermeture",
    'Contact name':'Nom du contact',
    'Phone number':'Numéro de téléphone',
    'Ext.':'Ext.',
    'Contact emails':'Courriels de contact',
    'Enter an email address':'Entrez une adresse courriel',
    'must be at least one email':'doit être au moins un e-mail'
}))()
