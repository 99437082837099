import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService, TransportType } from '@lazr/openapi-client'
import { Carrier } from '../../model'
import { handleUnauthorizedException } from './index'
import { CollectCarrier } from '@/app/model/CollectCarrier'
export interface CarrierParams {
    processInvoiceSupport?: boolean
    processTrackingUpdateSupport?: boolean
    supportPickup?: boolean
    supportHoldForPickup?: boolean
}
export const CarrierApiService = Object.freeze({
    list: async (params: CarrierParams = {}): Promise<Carrier[]> => {
        let getCarriersResponse
        try {
            getCarriersResponse = await EnumTypesService.getCarriers(params.processInvoiceSupport, params.processTrackingUpdateSupport, params.supportPickup,params.supportHoldForPickup)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve carrier list')
            }
            throw new Error('Unable to retrieve carrier list')
        }

        if (!getCarriersResponse.data) {
            logger.debug('list', JSON.stringify(getCarriersResponse, null, 4))
            throw new Error('Unable to retrieve carrier list')
        }

        return getCarriersResponse.data
    },
    getCollectCarriers: async (transportTypes?: TransportType[]): Promise<CollectCarrier[]> => {
        let getCarriersResponse
        try {
            getCarriersResponse = await EnumTypesService.getCollectCarriers(transportTypes)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getCollectCarriers', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve carrier list')
            }
            throw new Error('Unable to retrieve carrier list')
        }

        if (!getCarriersResponse.data) {
            logger.debug('getCollectCarriers', JSON.stringify(getCarriersResponse, null, 4))
            throw new Error('Unable to retrieve carrier list')
        }

        return getCarriersResponse.data
    },
})
