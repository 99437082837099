/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrackingUpdateStatus {
    CARRIER_DISPATCHED = 'CARRIER_DISPATCHED',
    SCHEDULED_FOR_PICKUP = 'SCHEDULED_FOR_PICKUP',
    PICKUP_ARRIVAL = 'PICKUP_ARRIVAL',
    PICKUP_DEPARTURE = 'PICKUP_DEPARTURE',
    INFO = 'INFO',
    IN_TRANSIT = 'IN_TRANSIT',
    AT_STOP = 'AT_STOP',
    DELAYED_CUSTOMS_HOLD = 'DELAYED_CUSTOMS_HOLD',
    DELAYED_HOLD = 'DELAYED_HOLD',
    DELAYED_OTHER = 'DELAYED_OTHER',
    OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
    DELIVERY_ARRIVAL = 'DELIVERY_ARRIVAL',
    DELIVERED = 'DELIVERED',
    NOT_DELIVERED = 'NOT_DELIVERED',
    CUSTOMER_PICKUP = 'CUSTOMER_PICKUP',
    RETURN_TO_SENDER = 'RETURN_TO_SENDER',
}