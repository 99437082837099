import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import {
    Box as MuiBox,
    Grid as MuiGrid,
    Typography as MuiTypography,
} from '@material-ui/core'
import {
    spacing as MuiSpacing,
} from '@material-ui/system'

import RawLogo from '@/app/ui/components/Logo'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import TextField from '@/app/ui-new/components/TextField/TextField'

export const StyledTextField = styled(TextField)`
    max-width: 328px;
    [class^="MuiInputBase-root"] > input {
        color: ${(props): string => props.theme.palette.black.contrastText};
    }
    fieldset {
        border: 1px solid #B8B8B8;
        border-radius: 4px;
    }
`

export const Grid = styled(MuiGrid)`
    .lzr-mobile-padding {
        padding-bottom: ${(props): number => props.theme.spacing(11)}px;
    }
`

export const Link = styled(RouterLink)`
   text-decoration: none;
   color: ${(props): string => props.theme.palette.black.contrastText};
 `

export const StyledAnchor = styled.a`
   text-decoration: none;
   color: ${(props): string => props.theme.palette.black.contrastText};
 `

export const Logo = styled(RawLogo)`
    width: 164px;
    fill: ${(props): string => props.theme.palette.black.contrastText};
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing))`
    font-weight: ${(props): number => props.theme.typography.fontWeightLight};
`

export const Wrapper = styled(MuiBox)`
    position: relative;
    width: 100%;
    min-height: 485px;
    background-color: ${(props): string => props.theme.palette.black.main};
    color: ${(props): string => props.theme.palette.black.contrastText};
    padding: ${(props): number => props.theme.spacing(8)}px ${(props): number => props.theme.spacing(4)}px;

    @media screen and (min-width: 450px) and (max-width: 900px) {
        padding: ${(props): number => props.theme.spacing(8)}px ${(props): number => props.theme.spacing(20)}px;
    }

    [class*='MuiGrid-spacing-xs-10']{
        width: 100%;
    }
`

export const StyledPrimaryButton = styled(PrimaryButton)`
    height: 32px;
    min-width: 182px;
    max-width: 328px;
`
export const TypographySubscribeText = styled(MuiTypography)`
    font-weight: 700;
    letter-spacing: 0.02rem;
`
