/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import PackageTypeTranslations from '@/app/ui-new/i18n/PackageTypeAndDescription.i18n.en'
import TransportType from '@/app/ui-new/i18n/TransportType.i18n.en'
import WeightUnit from '@/app/ui-new/i18n/WeightUnit.i18n.en'
import BillDutiesToParty from '@/app/ui-new/i18n/BillDutiesToParty.i18n.en'
import FedexLocation from '@/app/ui-new/i18n/FedexLocation.i18n.en'

export default {
    ...PackageTypeTranslations,
    ...TransportType,
    ...WeightUnit,
    ...BillDutiesToParty,
    ...FedexLocation
}
