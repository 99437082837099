import React from 'react'
import {
    CharacterImg,
    ListItemImg1, ListItemImg2, ListItemImg3,
    ListItemImg4, ListItemImg5, ListItemImg6,
    StyledPrimaryButton, StyledSecondaryButton,
    StyledSubTitleTypography,
    StyledTitleTypography,
    Wrapper,
} from './BannerDesktop.styled'
import { Box, Grid } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './BannerDesktop.i18n'

const BannerDesktop: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    return (
        <Wrapper>
            <Box width={1224}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <div>
                            <StyledTitleTypography variant={'h1'}>{t('One shipping software to do it all')}</StyledTitleTypography>
                        </div>
                        <Box mt={8} width={500}>
                            <StyledSubTitleTypography variant={'h6'}>
                                {t('Get access to the largest freight network in North America for less than truckload (LTL) & parcel shipping. Discover new shipping services, cut shipping costs, and manage everything with one simple solution.')}
                            </StyledSubTitleTypography>
                        </Box>
                        <Box mt={13} display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                            <Box mr={6}>
                                <StyledPrimaryButton size={'large'} href='/register'>
                                    {t('Get shipping rates')}
                                </StyledPrimaryButton>
                            </Box>
                            <StyledSecondaryButton  size={'large'} href='/register'>
                                {t('Get Lazr for free')}
                            </StyledSecondaryButton>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box position={'relative'} width={612} height={331}>
                            <ListItemImg1 src={`/images/website/banner/BannerListItem1.svg?v=${window.lazrVersion}`} alt="Item1" />
                            <ListItemImg2 src={`/images/website/banner/BannerListItem2.svg?v=${window.lazrVersion}`} alt="Item2" />
                            <ListItemImg3 src={`/images/website/banner/BannerListItem3.svg?v=${window.lazrVersion}`} alt="Item3" />
                            <ListItemImg4 src={`/images/website/banner/BannerListItem4.svg?v=${window.lazrVersion}`} alt="Item4" />
                            <ListItemImg5 src={`/images/website/banner/BannerListItem5.svg?v=${window.lazrVersion}`} alt="Item5" />
                            <ListItemImg6 src={`/images/website/banner/BannerListItem6.svg?v=${window.lazrVersion}`} alt="Item6" />
                            <CharacterImg src={`/images/website/banner/BannerCharacter.svg?v=${window.lazrVersion}`} alt="Character" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Wrapper>
    )
}

export interface Props {
}

export default BannerDesktop
