/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './DefaultShippingAddressPicker.i18n.en'
import { Translations } from '@lazr/i18n'
import { AddressBookType } from '@/app/ui/lib/constants'

export default ((): Translations<typeof en> => ({
    [`Default shipping address`]: `Adresse de livraison par défaut`,
    [`Default shipping address is already selected`]: (data: { addressBookType: AddressBookType}) =>
        <>
            <div>L&apos;adresse de livraison par défaut est déjà sélectionnée</div>
            <div> comme adresse {data.addressBookType === AddressBookType.ORIGIN ?
                `de déstination` : `d'origine`}!
            </div>
        </>,
    [`No default shipping address found!`]: `Aucune adresse de livraison par défaut trouvée`,
    [`Info`]: `Information`,
}))()
