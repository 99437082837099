/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * AddressComplete Interactive Find (v2.10) Response.
 */
export type FindAddressesResponse = {
    /**
     * The next step of the search process.
     */
    Next: FindAddressesResponse.Next;
    /**
     * Descriptive information about the found item, typically if it's a container.
     */
    Description: string;
    /**
     * A zero-based position in the Text response indicating the suggested position of the cursor if this item is selected. A -1 response indicates no suggestion is available.
     */
    Cursor: number;
    /**
     * A list of number ranges identifying the characters to highlight in the Text response (zero-based start position and end).
     */
    Highlight: string;
    /**
     * The found item.
     */
    Text: string;
    /**
     * The Id to be used as the LastId with the Find method.
     */
    Id: string;
}

export namespace FindAddressesResponse {

    /**
     * The next step of the search process.
     */
    export enum Next {
        FIND = 'Find',
        RETRIEVE = 'Retrieve',
    }


}
