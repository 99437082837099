/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import React from 'react'

export default {
    [`One shipping software to do it all`]: () => (<>One shipping<br/>software to do it all</>),
    [`Get access to the largest freight network in North America for less than truckload (LTL) & parcel shipping. Discover new shipping services, cut shipping costs, and manage everything with one simple solution.`]: `Get access to the largest freight network in North America for less than truckload (LTL) & parcel shipping. Discover new shipping services, cut shipping costs, and manage everything with one simple solution.`,
    [`Get shipping rates`]: `Get shipping rates`,
    [`Get Lazr for free`]: `Get Lazr for free`,
}
