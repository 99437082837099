/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Quote`]: `Quote`,
    [`faster`]: `faster`,
    [`Ship`]: `Ship`,
    [`smarter`]: `smarter`,
    [`Start saving today with the most intelligent shipping platform for businesses.`]: `Start saving today with the most intelligent shipping platform for businesses.`,
    [`Get Lazr for free`]: `Get Lazr for free`,
    [`Talk to our team`]: `Talk to our team`,
}
