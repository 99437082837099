import type {
    Country,
    Currency,
    DocumentType,
    InvoiceCategory,
    InvoiceEdiSentStatus,
    InvoiceEmailSendingStatus,
    InvoiceStatus,
    InvoiceType,
    PackageType,
    UuidV4,
    WeightUnit
} from '@lazr/openapi-client'

export interface ApplyPaymentInvoice {
    id: UuidV4
    xeroId: UuidV4 | null
    quickbooksId: string | null
    consolidationId: UuidV4 | null
    consolidationNumber?: number
    externalNumber: string
    orderNumber: string
    quotePrice: number
    status: InvoiceStatus
    dueDate: string
    currency: Currency
    pickUpDate: string
    paid: number
    amountDue: number
    total: number
    appliedPaymentAmount: number
    type: InvoiceType
    invoicePrice: number
    date: string
    subtotal: number
    amountPaid: number
    taxesTotal: number
    category: InvoiceCategory
}
export interface InvoicePayment {
    id: UuidV4
    xeroPaymentId: string
    currency: Currency
    amount: number
    paymentDate: string
}
export interface InvoiceRecipient {
    id: UuidV4
    recipientEmail: string
}
export interface InvoiceDocument {
    id: UuidV4
    name: string
    type: DocumentType
    transportInvoiceNumber: string | null
}

interface InvoicePackageType {
    code: PackageType
    singularName: string
    pluralName: string
}

interface InvoiceChargeItem {
    id: UuidV4
    qty?: number
    units?: InvoicePackageType
    description: string
    weight?: number
    weightUnit?: WeightUnit
    totalWeight?: number
    totalWeightUnit?: WeightUnit
    class?: string
    total: number
    code: string
}

interface InvoiceTaxes {
    id: UuidV4
    type: string
    total: number
}

export interface InvoiceProps {
    id: UuidV4
    xeroId: UuidV4 | null
    quickbooksId: string | null
    orderId: UuidV4
    orderNumber: string
    organizationId: UuidV4
    consolidationId: UuidV4 | null
    externalNumber: string
    status: InvoiceStatus
    type: InvoiceType
    category: InvoiceCategory
    currency: Currency
    invoiceDate: string
    invoiceDueDate: string
    quotePrice: number
    invoicePrice: number
    clientName: string
    billingName: string
    billingAddress: string
    billingAddress2: string
    billingAddress3: string
    billingCity: string
    billingState: string
    billingPostalCode: string
    billingCountry: Country
    billingEmails: string[]
    pickUpDate: string
    carrierId: UuidV4
    carrierName: string
    invoiceRevision: number
    clientNumber: string
    billingPo: string
    bolNumber: string
    originPo: string
    destinationPo: string
    shipperName: string
    shipperAddress: string
    shipperAddress2: string
    shipperCity: string
    shipperState: string
    shipperPostalCode: string
    shipperCountry: Country
    consigneName: string
    consigneAddress: string
    consigneAddress2: string
    consigneCity: string
    consigneState: string
    consignePostalCode: string
    consigneCountry: Country
    recipients: InvoiceRecipient[]
    payments: InvoicePayment[]
    documents: InvoiceDocument[]
    chargeItems?: InvoiceChargeItem []
    taxes?: InvoiceTaxes []
    createdAt: string
    clientOrganizationName: string
    threePlOrganizationName: string
    emailSentStatus?: InvoiceEmailSendingStatus
    invoiceEdiSentStatus?: InvoiceEdiSentStatus
    paymentTerm: number
}
export class Invoice {
    public id: UuidV4
    public xeroId: UuidV4 | null
    public quickbooksId: string | null
    public orderId: UuidV4
    public orderNumber: string
    public organizationId: UuidV4
    public consolidationId: UuidV4 | null
    public externalNumber: string
    public status: InvoiceStatus
    public type: InvoiceType
    public category: InvoiceCategory
    public currency: Currency
    public invoiceDate: string
    public invoiceDueDate: string
    public quotePrice: number
    public invoicePrice: number
    public clientName: string
    public billingName: string
    public billingAddress: string
    public billingAddress2: string
    public billingAddress3: string
    public billingCity: string
    public billingState: string
    public billingPostalCode: string
    public billingCountry: Country
    public billingEmails: string[]
    public pickUpDate: string
    public carrierId: UuidV4
    public carrierName: string
    public invoiceRevision: number
    public clientNumber: string
    public billingPo: string
    public bolNumber: string
    public originPo: string
    public destinationPo: string
    public shipperName: string
    public shipperAddress: string
    public shipperAddress2: string
    public shipperCity: string
    public shipperState: string
    public shipperPostalCode: string
    public shipperCountry: Country
    public consigneName: string
    public consigneAddress: string
    public consigneAddress2: string
    public consigneCity: string
    public consigneState: string
    public consignePostalCode: string
    public consigneCountry: Country
    public recipients: InvoiceRecipient[]
    public payments: InvoicePayment[]
    public documents: InvoiceDocument[]
    public chargeItems?: InvoiceChargeItem[]
    public taxes?: InvoiceTaxes[]
    public createdAt: string
    public amountPaid: number
    public total: number
    public clientOrganizationName: string
    public threePlOrganizationName: string
    public emailSentStatus?: InvoiceEmailSendingStatus
    public invoiceEdiSentStatus?: InvoiceEdiSentStatus
    public paymentTerm: number

    constructor (props: InvoiceProps) {
        this.id = props.id
        this.xeroId = props.xeroId
        this.quickbooksId = props.quickbooksId
        this.orderId = props.orderId
        this.externalNumber = props.externalNumber
        this.orderNumber = props.orderNumber
        this.organizationId = props.organizationId
        this.consolidationId = props.consolidationId
        this.status = props.status
        this.type = props.type
        this.category = props.category
        this.currency = props.currency
        this.invoiceDate = props.invoiceDate
        this.invoiceDueDate = props.invoiceDueDate
        this.quotePrice = props.quotePrice
        this.invoicePrice = props.invoicePrice
        this.clientName = props.clientName
        this.billingName = props.billingName
        this.billingAddress = props.billingAddress
        this.billingAddress2 = props.billingAddress2
        this.billingAddress3 = props.billingAddress3
        this.billingCity = props.billingCity
        this.billingState = props.billingState
        this.billingPostalCode = props.billingPostalCode
        this.billingCountry = props.billingCountry
        this.billingEmails = props.billingEmails
        this.pickUpDate = props.pickUpDate
        this.carrierId  = props.carrierId
        this.carrierName = props.carrierName
        this.invoiceRevision = props.invoiceRevision
        this.clientNumber = props.clientNumber
        this.billingPo = props.billingPo
        this.bolNumber = props.bolNumber
        this.originPo = props.originPo
        this.destinationPo = props.destinationPo
        this.shipperName = props.shipperName
        this.shipperAddress = props.shipperAddress
        this.shipperAddress2 = props.shipperAddress2
        this.shipperCity = props.shipperCity
        this.shipperState = props.shipperState
        this.shipperPostalCode = props.shipperPostalCode
        this.shipperCountry = props.shipperCountry
        this.consigneName = props.consigneName
        this.consigneAddress = props.consigneAddress
        this.consigneAddress2 = props.consigneAddress2
        this.consigneCity = props.consigneCity
        this.consigneState = props.consigneState
        this.consignePostalCode = props.consignePostalCode
        this.consigneCountry = props.consigneCountry
        this.recipients = props.recipients
        this.payments = props.payments
        this.documents = props.documents
        this.chargeItems = props.chargeItems
        this.taxes = props.taxes
        this.createdAt = props.createdAt
        this.clientOrganizationName = props.clientOrganizationName
        this.threePlOrganizationName = props.threePlOrganizationName
        this.emailSentStatus = props.emailSentStatus
        this.invoiceEdiSentStatus = props.invoiceEdiSentStatus
        this.paymentTerm = props.paymentTerm
    }
}
