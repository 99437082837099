import React, { useRef } from 'react'
import i18n from './SupportWidget.i18n'
import {
    Divider,
    EmailLink,
    EmailTypography,
    Grid,
    GridIconItem,
    Link,
    LinkTypography,
    MenuIcon,
    Ninja,
    PhoneTypography,
    SupportMenu, TitleTypography,
    Wrapper,
} from './SupportWidget.styled'
import { privacyPolicyRoute, termsOfServiceRoute, helpCenterRoute } from '@/app/ui/routes'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import { Typography } from '@material-ui/core'

const SupportWidget: React.FunctionComponent<Props> = ({ id }) => {
    const { t } = useI18n(i18n)

    if (id === undefined) {
        id = 'support-widget'
    }

    const [ isOpen, setIsIsOpen ] = React.useState(false)

    const ninjaRef = useRef<HTMLDivElement>(null)

    const handleMenuToggle = () => {
        setIsIsOpen(!isOpen)
    }

    const handleMenuClose = () => {
        setIsIsOpen(false)
    }

    return <React.Fragment>
        <Wrapper id={id}>
            <Ninja
                ref={ninjaRef}
                aria-owns={isOpen ? 'menu-support' : ''}
                aria-haspopup="true"
                aria-label='Support Menu'
                onClick={handleMenuToggle}
                className={isOpen ? 'is-open' : ''}
            >
                <div>
                    <img alt='Support Ninja' src={`/images/support-widget/SupportNinja.svg?v=${window.lazrVersion}`}/>
                </div>
            </Ninja>
        </Wrapper>
        <SupportMenu
            id='menu-support'
            anchorEl={ninjaRef.current}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isOpen}
            onClose={handleMenuClose}
        >
            <Grid container alignItems='center'>
                <Grid item xs={12} pt={6} pb={3} px={6}>
                    <TitleTypography variant='h6'>
                        {t('Support')}
                    </TitleTypography>
                </Grid>
                <Grid item xs={12} py={3} px={6}>
                    <Typography variant='body2'>
                        {t('Should you require assistance, our user support team is at your service.')}
                    </Typography>
                </Grid>
                <Grid item xs={12} pt={0} pb={6} px={6}>
                    <Typography variant='body2'>
                        {/* eslint-disable-next-line max-len */}
                        {t('Don\'t hesitate to contact us for prompt and professional assistance with any inquiries or concerns you may have.')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container item alignItems='center' xs={12} pt={6} pb={3} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'phone-alt' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <PhoneTypography variant='body1'>
                            (514) 418-6552 ext. 906
                        </PhoneTypography>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' xs={12} py={3} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'phone-office' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <PhoneTypography variant='body1'>
                            (877) 493-5297 ext. 906
                        </PhoneTypography>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' xs={12} pt={3} pb={6} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'envelope' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <EmailLink href={t('mailto:support@lazr.io?subject=Support Request')}>
                            <EmailTypography variant='body1'>
                                {t('support@lazr.io')}
                            </EmailTypography>
                        </EmailLink>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container item alignItems='center' xs={12} pt={6} pb={3} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'question-circle' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <LinkTypography variant='body1'>
                            <Link to={helpCenterRoute.path}>{t('Help Center')}</Link>
                        </LinkTypography>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' xs={12} py={3} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'user-secret' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <LinkTypography variant='body1'>
                            <Link to={privacyPolicyRoute.path}>{t('Privacy')}</Link>
                        </LinkTypography>
                    </Grid>
                </Grid>
                <Grid container item alignItems='center' xs={12} pt={3} pb={3} px={6}>
                    <GridIconItem item xs='auto'>
                        <MenuIcon icon={[ 'far', 'file-alt' ]}/>
                    </GridIconItem>
                    <Grid item xs>
                        <LinkTypography variant='body1'>
                            <Link to={termsOfServiceRoute.path}>{t('Terms of Service')}</Link>
                        </LinkTypography>
                    </Grid>
                </Grid>
            </Grid>
        </SupportMenu>
    </React.Fragment>
}

export interface Props {
    id?: string
}

export { SupportWidget }

export default SupportWidget
