import { AnyAction, Reducer } from 'redux'
import { initialInvoiceListFiltering } from '../store/InitialState'
import * as types from '../constants'
import { InvoiceFilter, InvoiceListFiltering, NewConsolidationInvoiceFilter } from '../../definitions/InvoiceList'
import { OtherOrganization } from '../../../model'

const reducer: Reducer = (state: InvoiceListFiltering = initialInvoiceListFiltering, action: AnyAction): InvoiceListFiltering => ({
    filters: filters(state.filters, action),
    newConsolidationFilters: newConsolidationFilters(state.newConsolidationFilters, action),
    organizationList: organizationList(state.organizationList, action),
    filteredByOrganizationId: filteredByOrganizationId(state.filteredByOrganizationId, action),
})

const filters = (state: InvoiceFilter, action: AnyAction): InvoiceFilter =>
    action.type === types.SET_INVOICE_FILTER
        ? action.payload
        : state

const newConsolidationFilters = (state: NewConsolidationInvoiceFilter, action: AnyAction): NewConsolidationInvoiceFilter =>
    action.type === types.SET_NEW_CONSOLIDATION_INVOICE_FILTER
        ? action.payload
        : state

const organizationList = (state: OtherOrganization[], action: AnyAction): OtherOrganization[] => {
    switch (action.type) {
        case types.SET_INVOICE_LIST_ORGANIZATION_LIST:
            return action.payload.organizationList
        default:
            return state
    }
}
const filteredByOrganizationId = (state: string, action: AnyAction): string => {
    switch (action.type) {
        case types.SET_INVOICE_LIST_OTHER_USER_INFO_LIST:
            return action.payload.filteredByOrganizationId
        default:
            return state
    }
}
export default reducer
