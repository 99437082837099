import React from 'react'
import { AsYouType, CountryCode as PhoneCountryCode } from 'libphonenumber-js'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Country, Provider, TransportType } from '@lazr/openapi-client'
import { getUser } from '../../../redux/selectors'
import * as EmailValidator from 'email-validator'
import { getMarketPlace as reduxGetMarketPlace } from '../../../redux/selectors/MarketPlaceSelectors'
import { MarketPlace, MarketPlaceErrors } from '../../../definitions/MarketPlace'
import {
    setMarketPlaceErrors as reduxSetMarketPlaceErrors,
    setMarketPlaceErrorsIsSubmitted as reduxSetMarketPlaceErrorsIsSubmitted,
} from '../../../redux/actions/MarketPlaceActions'
import { initialMarketPlace } from '../../../redux/store/InitialState'
import reduxSetSnackbar from '../../../redux/actions/SnackbarActions'
import { isAccessorialUsed, validatePostalCode } from '../../../lib/helpers'
import { OrderInfoHandlingUnit, OrderInfoRfq } from '../../../../model'
import { calculateFreightClassFromHandlingUnit, deepCopy, isCaUsPrViLane, isFreightService, isInternationalOrder, isMailingService } from '@lazr/utilities'

const HANDLING_UNIT_LING_ERR_MSG = 'All handling units need to be linked to at least one customs detail.'
const checkMarketPlaceErrors = (marketPlaceErrors: MarketPlaceErrors): boolean => {
    let hasErrors = Object.keys(marketPlaceErrors.rfqErrors).length !== 0

    hasErrors = hasErrors || Object.keys(marketPlaceErrors.rfqCustomsErrors).length !== 0

    marketPlaceErrors.cargoErrors.forEach((handlingUnitError) => {
        hasErrors = hasErrors || Object.keys(handlingUnitError).length !== 0
    })

    marketPlaceErrors.customsDetailErrors.forEach((customsDetailError) => {
        hasErrors = hasErrors || Object.keys(customsDetailError).length !== 0
    })

    marketPlaceErrors.handlingUnitLinkErrors.forEach((handlingUnitLinkError) => {
        hasErrors = hasErrors || Object.keys(handlingUnitLinkError).length !== 0
    })

    return hasErrors
}
export const useFormValidation = (
    validator: (marketPlace: MarketPlace, isSubmitting: boolean, countryCode: Country) => MarketPlaceErrors,
): [(submitting?: boolean) => boolean, boolean, () => void] => {
    const user = useSelector(getUser)
    const userCountry = user?.locale.country ?? Country.CA

    const marketPlace = useSelector(reduxGetMarketPlace)

    const { isSubmitted } = marketPlace.marketPlaceErrors

    const [ isValidating, setValidating ] = React.useState(false)

    const dispatch = useDispatch()

    const validate = (isSubmitting = false): boolean => {
        if (!isSubmitting && !isSubmitted) {
            return false
        }
        setValidating(true)
        const marketPlaceErrors = validator(marketPlace, isSubmitting, userCountry)

        dispatch(reduxSetMarketPlaceErrors(marketPlaceErrors))
        const hasErrors = checkMarketPlaceErrors(marketPlaceErrors)
        const isHandlingUnitLinkError = marketPlaceErrors.handlingUnitLinkErrors.some((handlingUnitLinkError) =>
            Object.keys(handlingUnitLinkError).length !== 0,
        )
        setValidating(false)

        if (hasErrors && isSubmitting) {
            dispatch(reduxSetSnackbar({
                autoHideDuration: 5000,
                message: isHandlingUnitLinkError ? HANDLING_UNIT_LING_ERR_MSG : 'Required fields are missing.',
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: 'error',
            }))
        }

        return hasErrors
    }

    const submit = (): void => {
        dispatch(reduxSetMarketPlaceErrorsIsSubmitted(false))
    }

    return [ validate, isValidating, submit ]
}

export default useFormValidation

export const shipmentInformationStepValidator = (
    marketPlace: MarketPlace,
    isSubmitting: boolean,
): MarketPlaceErrors => {
    const addressInformation = marketPlace.order.rfq
    const cargo = marketPlace.order.rfq?.handlingUnits
    let rfqIsDirty = marketPlace.marketPlaceErrors.rfqIsDirty
    const cargoIsDirty =  marketPlace.marketPlaceErrors.cargoIsDirty

    const rfqErrors: Partial<Record<keyof OrderInfoRfq, string>> = {}
    const cargoErrors: Partial<Record<keyof OrderInfoHandlingUnit, string>>[] = [ {} ]

    const requiredText = 'Required'

    if (isSubmitting) {
        rfqIsDirty = {
            originState: true,
            originCountry: true,
            originCity: true,
            originPostalCode: true,
            originPickupDate: true,
            destinationState: true,
            destinationCountry: true,
            destinationCity: true,
            destinationPostalCode: true,
        }
    }

    const countriesInCaUsPrViLane = isCaUsPrViLane(addressInformation?.originCountry?.code, addressInformation?.destinationCountry?.code)
    if (countriesInCaUsPrViLane) {
        if (!addressInformation?.originState?.trim() && rfqIsDirty.originState) {
            rfqErrors.originState = requiredText
        } else if (addressInformation?.originState && rfqIsDirty.originState) {
            if (addressInformation.originState.length > 2) {
                rfqErrors.originState = 'Invalid state code. State must use the two letter code.'
            }
        }
    }
    if (addressInformation?.originState?.trim()){
        rfqIsDirty.originState = true
    }

    if (!addressInformation?.originCountry && rfqIsDirty.originCountry) {
        rfqErrors.originCountry = requiredText
    }
    if (addressInformation?.originCountry){
        rfqIsDirty.originCountry = true
    }

    if (!addressInformation?.originCity?.trim() && rfqIsDirty.originCity) {
        rfqErrors.originCity = requiredText
    }
    if (addressInformation?.originCity?.trim()){
        rfqIsDirty.originCity = true
    }

    if (!addressInformation?.originPostalCode?.trim() && rfqIsDirty.originPostalCode) {
        rfqErrors.originPostalCode = requiredText
    } else if (addressInformation?.originPostalCode && addressInformation.originCountry && rfqIsDirty.originPostalCode) {
        if (!validatePostalCode(addressInformation.originCountry.code, addressInformation.originPostalCode)) {
            rfqErrors.originPostalCode = 'Invalid postal/zip code'
        }
    }
    if (addressInformation?.originPostalCode?.trim()){
        rfqIsDirty.originPostalCode = true
    }

    if (!addressInformation?.originPickupDate?.trim() && rfqIsDirty.originPickupDate) {
        rfqErrors.originPickupDate = requiredText
    } else if (addressInformation?.originPickupDate?.trim() && rfqIsDirty.originPickupDate) {
        if (moment().isAfter(addressInformation.originPickupDate, 'date')){
            rfqErrors.originPickupDate = 'Pickup date is in the past'
        }
    }
    if (addressInformation?.originPickupDate?.trim()){
        rfqIsDirty.originPickupDate = true
    }

    if (countriesInCaUsPrViLane) {
        if (!addressInformation?.destinationState?.trim() && rfqIsDirty.destinationState) {
            rfqErrors.destinationState = requiredText
        } else if (addressInformation?.destinationState && rfqIsDirty.destinationState) {
            if (addressInformation.destinationState.length > 2) {
                rfqErrors.destinationState = 'Invalid state code. State must use the two letter code.'
            }
        }
    }
    if (addressInformation?.destinationState?.trim()) {
        rfqIsDirty.destinationState = true
    }

    if (!addressInformation?.destinationCountry && rfqIsDirty.destinationCountry) {
        rfqErrors.destinationCountry = requiredText
    }
    if (addressInformation?.destinationCountry){
        rfqIsDirty.destinationCountry = true
    }

    if (!addressInformation?.destinationCity?.trim() && rfqIsDirty.destinationCity) {
        rfqErrors.destinationCity = requiredText
    }
    if (addressInformation?.destinationCity?.trim()){
        rfqIsDirty.destinationCity = true
    }

    if (!addressInformation?.destinationPostalCode?.trim() && rfqIsDirty.destinationPostalCode) {
        rfqErrors.destinationPostalCode = requiredText
    } else if (addressInformation?.destinationPostalCode && addressInformation.destinationCountry && rfqIsDirty.destinationPostalCode) {
        if (!validatePostalCode(addressInformation.destinationCountry.code, addressInformation.destinationPostalCode)) {
            rfqErrors.destinationPostalCode = 'Invalid postal/zip code'
        }
    }
    if (addressInformation?.destinationPostalCode?.trim()){
        rfqIsDirty.destinationPostalCode = true
    }


    cargo?.forEach((handlingUnit, index) => {
        if (!marketPlace.order.rfq?.transportType) {
            return
        }

        if (isSubmitting) {
            if (marketPlace.order.rfq.transportType === TransportType.PAK) {
                cargoIsDirty[index] = {
                    quantity: true,
                    weight: true,
                    description: true,
                }
            }
            if (marketPlace.order.rfq.transportType === TransportType.PARCEL  || marketPlace.order.rfq.transportType === TransportType.ENVELOPE) {
                cargoIsDirty[index] = {
                    quantity: true,
                    length: true,
                    width: true,
                    height: true,
                    weight: true,
                    description: true,
                }
            }
            if (!isMailingService(marketPlace.order.rfq.transportType)) {
                cargoIsDirty[index] = {
                    quantity: true,
                    packageType: true,
                    pieces: true,
                    length: true,
                    width: true,
                    height: true,
                    weight: true,
                    class: true,
                    nmfc: true,
                    description: true,
                }
            }
        }

        if (!cargoIsDirty[index]) {
            cargoIsDirty[index] = {}
        }
        cargoErrors[index] = {}


        if (handlingUnit?.quantity && handlingUnit.quantity <= 0) {
            cargoErrors[index].quantity = 'Quantity must be greater than 0.'
        }
        if (handlingUnit?.weight && handlingUnit.weight <= 0) {
            cargoErrors[index].weight = 'Each must be greater than 0.'
        }


        if (marketPlace.order.rfq.transportType === TransportType.PAK) {
            if (!handlingUnit.quantity && cargoIsDirty[index].quantity) {
                cargoErrors[index].quantity = requiredText
            }

            if (handlingUnit.quantity){
                cargoIsDirty[index].quantity = true
            }
            if (!handlingUnit.weight && cargoIsDirty[index].weight) {
                cargoErrors[index].weight = requiredText
            }
            if (handlingUnit.weight){
                cargoIsDirty[index].weight = true
            }
            if (!handlingUnit.description?.trim() && cargoIsDirty[index].description) {
                cargoErrors[index].description = requiredText
            }
            if (handlingUnit.description?.trim()){
                cargoIsDirty[index].description = true
            }
        }
        if (marketPlace.order.rfq.transportType === TransportType.LTL) {
            if (handlingUnit?.length && handlingUnit.length <= 0) {
                cargoErrors[index].length = 'Length must be greater than 0.'
            }
            if (handlingUnit?.width && handlingUnit.width <= 0) {
                cargoErrors[index].width = 'Width must be greater than 0.'
            }

            if (handlingUnit?.height && handlingUnit.height <= 0) {
                cargoErrors[index].height = 'Height must be greater than 0.'
            }
        }

        if (marketPlace.order.rfq.transportType === TransportType.FTL) {
            if (handlingUnit?.length && handlingUnit.length <= 0) {
                cargoErrors[index].length = 'Length must be greater than 0.'
            }
            if (handlingUnit?.width && handlingUnit.width <= 0) {
                cargoErrors[index].width = 'Width must be greater than 0.'
            }

            if (handlingUnit?.height && handlingUnit.height <= 0) {
                cargoErrors[index].height = 'Height must be greater than 0.'
            }
        }

        if (marketPlace.order.rfq.transportType === TransportType.PARCEL || marketPlace.order.rfq.transportType === TransportType.ENVELOPE) {
            if (!handlingUnit.quantity && cargoIsDirty[index].quantity) {
                cargoErrors[index].quantity = requiredText
            }
            if (handlingUnit.quantity){
                cargoIsDirty[index].quantity = true
            }

            if (handlingUnit?.length && handlingUnit.length <= 0) {
                cargoErrors[index].length = 'Length must be greater than 0.'
            }
            if (handlingUnit?.width && handlingUnit.width <= 0) {
                cargoErrors[index].width = 'Width must be greater than 0.'
            }

            if (handlingUnit?.height && handlingUnit.height <= 0) {
                cargoErrors[index].height = 'Width must be greater than 0.'
            }

            if (!handlingUnit.length && cargoIsDirty[index].length) {
                cargoErrors[index].length = requiredText
            }
            if (handlingUnit.length){
                cargoIsDirty[index].length = true
            }

            if (!handlingUnit.width && cargoIsDirty[index].width) {
                cargoErrors[index].width = requiredText
            }
            if (handlingUnit.width){
                cargoIsDirty[index].width = true
            }

            if (!handlingUnit.height && cargoIsDirty[index].height) {
                cargoErrors[index].height = requiredText
            }
            if (handlingUnit.height){
                cargoIsDirty[index].height = true
            }

            if (!handlingUnit.weight && cargoIsDirty[index].weight) {
                cargoErrors[index].weight = requiredText
            }
            if (handlingUnit.weight){
                cargoIsDirty[index].weight = true
            }

            if (!handlingUnit.description?.trim() && cargoIsDirty[index].description) {
                cargoErrors[index].description = requiredText
            }
            if (handlingUnit.description?.trim()){
                cargoIsDirty[index].description = true
            }
        }

        if (!isMailingService(marketPlace.order.rfq.transportType)) {
            if (!handlingUnit.quantity && cargoIsDirty[index].quantity) {
                cargoErrors[index].quantity = requiredText
            }
            if (handlingUnit.quantity){
                cargoIsDirty[index].quantity = true
            }

            if (!handlingUnit.packageType?.trim() && cargoIsDirty[index].packageType) {
                cargoErrors[index].packageType = requiredText
            }
            if (handlingUnit.packageType?.trim()){
                cargoIsDirty[index].packageType = true
            }

            if (handlingUnit?.pieces && handlingUnit.pieces <= 0) {
                cargoErrors[index].pieces = 'Pieces must be greater than 0.'
            }
            if (handlingUnit?.width && handlingUnit.width <= 0) {
                cargoErrors[index].width = 'Width must be greater than 0.'
            }

            if (handlingUnit?.height && handlingUnit.height <= 0) {
                cargoErrors[index].height = 'Height must be greater than 0.'
            }

            if (!handlingUnit.pieces && cargoIsDirty[index].pieces) {
                cargoErrors[index].pieces = requiredText
            }
            if (handlingUnit.pieces){
                cargoIsDirty[index].pieces = true
            }

            if (!handlingUnit.length && cargoIsDirty[index].length) {
                cargoErrors[index].length = requiredText
            }
            if (handlingUnit.length){
                cargoIsDirty[index].length = true
            }

            if (!handlingUnit.width && cargoIsDirty[index].width) {
                cargoErrors[index].width = requiredText
            }
            if (handlingUnit.width){
                cargoIsDirty[index].width = true
            }

            if (!handlingUnit.height && cargoIsDirty[index].height) {
                cargoErrors[index].height = requiredText
            }
            if (handlingUnit.height){
                cargoIsDirty[index].height = true
            }

            if (!handlingUnit.weight && cargoIsDirty[index].weight) {
                cargoErrors[index].weight = requiredText
            }
            if (handlingUnit.weight){
                cargoIsDirty[index].weight = true
            }

            if (!handlingUnit.class?.trim() && cargoIsDirty[index].class) {
                cargoErrors[index].class = requiredText
            }
            if (handlingUnit.class?.trim()){
                cargoIsDirty[index].class = true
            }

            if (calculateFreightClassFromHandlingUnit(handlingUnit) !== handlingUnit.class &&
                !handlingUnit.nmfc?.trim() && cargoIsDirty[index].nmfc) {
                cargoErrors[index].nmfc = requiredText
            }
            if (handlingUnit.nmfc?.trim()){
                cargoIsDirty[index].nmfc = true
            }

            if (!handlingUnit.description?.trim() && cargoIsDirty[index].description) {
                cargoErrors[index].description = requiredText
            }
            if (handlingUnit.description?.trim()){
                cargoIsDirty[index].description = true
            }
        }
    })

    return {
        rfqIsDirty,
        cargoIsDirty,
        rfqErrors,
        cargoErrors,
        customsDetailErrors: deepCopy(initialMarketPlace.marketPlaceErrors.customsDetailErrors),
        customsDetailIsDirty: [ {} ],
        handlingUnitLinkIsDirty: [],
        handlingUnitLinkErrors: [],
        rfqCustomsErrors: {},
        rfqCustomsIsDirty: {},
        isSubmitted: true,
    }
}

export const completeOrderStepValidator = (
    marketPlace: MarketPlace,
    isSubmitting: boolean,
    country: Country,
): MarketPlaceErrors => {
    const rfq = marketPlace.order.rfq
    const rfqErrors: Partial<Record<keyof OrderInfoRfq, string>> = {}
    const hasNumber = /\d/
    const requiredText = 'Required'
    const isInternational = isInternationalOrder(rfq?.originCountry?.code, rfq?.destinationCountry?.code)

    if (!rfq?.originCompanyName?.trim()) {
        rfqErrors.originCompanyName = requiredText
    }

    if (!rfq?.originPickupOpenTime?.trim()) {
        rfqErrors.originPickupOpenTime = requiredText
    } else if (!moment(rfq.originPickupOpenTime, 'HH:mm', true).isValid() &&
        !moment(rfq.originPickupOpenTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.originPickupOpenTime = 'Invalid time'
    }
    if (!rfq?.originPickupCloseTime?.trim()) {
        rfqErrors.originPickupCloseTime = requiredText
    } else if (!moment(rfq.originPickupCloseTime, 'HH:mm', true).isValid() &&
        !moment(rfq.originPickupCloseTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.originPickupCloseTime = 'Invalid time'
    }

    if (rfq?.originContactPhone?.trim()) {
        const asYouType = new AsYouType(country as PhoneCountryCode)
        asYouType.input(rfq.originContactPhone)
        if (!asYouType.getNumber()?.isPossible()) {
            rfqErrors.originContactPhone = 'Invalid phone number'
        }
    }

    if (rfq?.originContactEmails && rfq?.originContactEmails.length > 0 &&
        (!rfq?.originContactEmails.every((originContactEmail) =>  EmailValidator.validate(originContactEmail)))){
        //TODO: handle the case where if the order is goint to be served by Puro to not accept + signs in email
        rfqErrors.originContactEmails = 'Invalid email address'
    }

    if (!rfq?.destinationCompanyName?.trim()) {
        rfqErrors.destinationCompanyName = requiredText
    }

    if (!rfq?.destinationDeliveryOpenTime?.trim()) {
        rfqErrors.destinationDeliveryOpenTime = requiredText
    } else if (!moment(rfq.destinationDeliveryOpenTime, 'HH:mm', true).isValid() &&
        !moment(rfq.destinationDeliveryOpenTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.destinationDeliveryOpenTime = 'Invalid time'
    }
    if (!rfq?.destinationDeliveryCloseTime?.trim()) {
        rfqErrors.destinationDeliveryCloseTime = requiredText
    } else if (!moment(rfq.destinationDeliveryCloseTime, 'HH:mm', true).isValid() &&
        !moment(rfq.destinationDeliveryCloseTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.destinationDeliveryCloseTime = 'Invalid time'
    }

    if (rfq?.destinationContactPhone?.trim()) {
        const asYouType = new AsYouType(Country[country] as PhoneCountryCode)
        asYouType.input(rfq.destinationContactPhone)
        if (!asYouType.getNumber()?.isPossible()) {
            rfqErrors.destinationContactPhone = 'Invalid phone number'
        }
    }

    if (rfq?.destinationContactEmails && rfq?.destinationContactEmails.length > 0 &&
        (!rfq?.destinationContactEmails.every((destinationContactEmail) =>  EmailValidator.validate(destinationContactEmail)))) {
        rfqErrors.destinationContactEmails = 'Invalid email address'
    }

    //validation for GLS Canada
    if (marketPlace.order.rfq?.selectedQuote?.provider === Provider.GLS_CANADA) {
        if (rfq?.originCompanyName?.trim() && (rfq?.originCompanyName.length < 2 || rfq?.originCompanyName.length > 40)) {
            rfqErrors.originCompanyName = 'Must be between 2 and 40 characters'
        }
        if (rfq?.destinationCompanyName?.trim() && (rfq?.destinationCompanyName.length < 2 || rfq?.destinationCompanyName.length > 40)) {
            rfqErrors.destinationCompanyName = 'Must be between 2 and 40 characters'
        }
        if (hasNumber.test(rfq?.originContactName ?? '')) {
            rfqErrors.originContactName = 'Cannot contain digits'
        }
        if (rfq?.originContactName?.trim() && (rfq?.originContactName.length < 2 || rfq?.originContactName.length > 80)) {
            rfqErrors.originContactName = 'Must be between 2 and 80 characters'
        }
        if (hasNumber.test(rfq?.destinationContactName ?? '')) {
            rfqErrors.destinationContactName = 'Cannot contain digits'
        }
        if (rfq?.destinationContactName?.trim() && (rfq?.destinationContactName.length < 2 || rfq?.destinationContactName.length > 80)) {
            rfqErrors.destinationContactName = 'Must be between 2 and 80 characters'
        }
        if (rfq?.originPickupOpenTime && !rfqErrors.originPickupOpenTime &&
            moment(rfq?.originPickupOpenTime, 'HH:mm:ss').isBefore(moment('07:00:00', 'HH:mm:ss'))) {
            rfqErrors.originPickupOpenTime = 'Must be after 07:00 am'
        }
    }

    if (isMailingService(rfq?.transportType)) {
        if (!rfq?.originContactName?.trim()) {
            rfqErrors.originContactName = requiredText
        }

        if (!rfq?.originContactPhone?.trim()) {
            rfqErrors.originContactPhone = requiredText
        }

        if (rfq?.originContactEmails && rfq?.originContactEmails.length === 0) {
            //TODO: handle the case where if the order is goint to be served by Puro to not accept + signs in email
            rfqErrors.originContactEmails = 'required email address'
        }

        if (isAccessorialUsed(rfq?.accessorials, 'HAZM')) {
            if (!rfq?.hazmatMode) {
                rfqErrors.hazmatMode = requiredText
            }
            if (!rfq?.hazmatClass) {
                rfqErrors.hazmatClass = requiredText
            }
        }
    }

    if (isInternational &&
        isFreightService(rfq?.transportType) && marketPlace.order.rfq?.selectedQuote?.provider !== Provider.FEDEX) {
        if (!rfq?.customsBroker) {
            rfqErrors.customsBroker = requiredText
        }
    }

    if (!isInternational && marketPlace.order.rfq?.selectedQuote?.provider === Provider.DHL){
        if (!rfq?.incotermsType){
            rfqErrors.incotermsType = requiredText
        }
    }

    return {
        rfqIsDirty: {},
        cargoIsDirty: [ {} ],
        rfqErrors,
        cargoErrors: deepCopy(initialMarketPlace.marketPlaceErrors.cargoErrors),
        customsDetailErrors: marketPlace.marketPlaceErrors.customsDetailErrors,
        customsDetailIsDirty: marketPlace.marketPlaceErrors.customsDetailIsDirty,
        handlingUnitLinkIsDirty: marketPlace.marketPlaceErrors.handlingUnitLinkIsDirty,
        handlingUnitLinkErrors: marketPlace.marketPlaceErrors.handlingUnitLinkErrors,
        rfqCustomsErrors: marketPlace.marketPlaceErrors.rfqCustomsErrors,
        rfqCustomsIsDirty: marketPlace.marketPlaceErrors.rfqCustomsIsDirty,
        isSubmitted: true,
    }
}

export const completeOrderStepRFQFieldsValidator = (
    marketPlace: MarketPlace,
    isSubmitting: boolean,
    country: Country,
): Partial<Record<keyof OrderInfoRfq, string>> => {
    const rfq = marketPlace.order.rfq
    const order = marketPlace.order
    const rfqErrors: Partial<Record<keyof OrderInfoRfq, string>> = {}
    const requiredText = 'Required'

    if (!rfq?.originCompanyName?.trim()) {
        rfqErrors.originCompanyName = requiredText
    }

    if (isMailingService(rfq?.transportType)) {
        if (rfq?.originContactEmails && rfq?.originContactEmails.length === 0) {
            //TODO: handle the case where if the order is goint to be served by Puro to not accept + signs in email
            rfqErrors.originContactEmails = 'required email address'
        }
        if (!rfq?.originContactName?.trim()) {
            rfqErrors.originContactName = requiredText
        }

        if (!rfq?.originContactPhone?.trim()) {
            rfqErrors.originContactPhone = requiredText
        }
        if(!!rfq?.documentsOnlyIndicator && !rfq?.shipmentDocumentType) {
            rfqErrors.shipmentDocumentType = requiredText
        }

    }


    if (!rfq?.originPickupOpenTime?.trim()) {
        rfqErrors.originPickupOpenTime = requiredText
    } else if (!moment(rfq.originPickupOpenTime, 'HH:mm', true).isValid() &&
        !moment(rfq.originPickupOpenTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.originPickupOpenTime = 'Invalid time'
    }
    if (!rfq?.originPickupCloseTime?.trim()) {
        rfqErrors.originPickupCloseTime = requiredText
    } else if (!moment(rfq.originPickupCloseTime, 'HH:mm', true).isValid() &&
        !moment(rfq.originPickupCloseTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.originPickupCloseTime = 'Invalid time'
    }

    if (rfq?.originContactPhone?.trim()) {
        const asYouType = new AsYouType(country as PhoneCountryCode)
        asYouType.input(rfq.originContactPhone)
        if (!asYouType.getNumber()?.isPossible()) {
            rfqErrors.originContactPhone = 'Invalid phone number'
        }
    }

    if (rfq?.originContactEmails && rfq?.originContactEmails.length > 0 &&
        !rfq?.originContactEmails.every((originContactEmail) =>  EmailValidator.validate(originContactEmail))) {
        //TODO: handle the case where if the order is goint to be served by Puro to not accept + signs in email
        rfqErrors.originContactEmails = 'Invalid email address'
    }
    if (!rfq?.destinationCompanyName?.trim()) {
        rfqErrors.destinationCompanyName = requiredText
    }

    if (!rfq?.destinationDeliveryOpenTime?.trim()) {
        rfqErrors.destinationDeliveryOpenTime = requiredText
    } else if (!moment(rfq.destinationDeliveryOpenTime, 'HH:mm', true).isValid() &&
        !moment(rfq.destinationDeliveryOpenTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.destinationDeliveryOpenTime = 'Invalid time'
    }
    if (!rfq?.destinationDeliveryCloseTime?.trim()) {
        rfqErrors.destinationDeliveryCloseTime = requiredText
    } else if (!moment(rfq.destinationDeliveryCloseTime, 'HH:mm', true).isValid() &&
        !moment(rfq.destinationDeliveryCloseTime, 'HH:mm:ss', true).isValid()){
        rfqErrors.destinationDeliveryCloseTime = 'Invalid time'
    }

    if (rfq?.destinationContactPhone?.trim()) {
        const asYouType = new AsYouType(Country[country] as PhoneCountryCode)
        asYouType.input(rfq.destinationContactPhone)
        if (!asYouType.getNumber()?.isPossible()) {
            rfqErrors.destinationContactPhone = 'Invalid phone number'
        }
    }
    if (rfq?.destinationContactEmails && rfq?.destinationContactEmails.length > 0 &&
        !rfq?.destinationContactEmails.every((destinationContactEmail) =>  EmailValidator.validate(destinationContactEmail))) {
        rfqErrors.destinationContactEmails = 'Invalid email address'
    }

    if (rfq?.commercialInvoiceUserProvided) {
        if (order.documents?.length === 0) {
            rfqErrors.commercialInvoiceUserProvided = 'Missing commercial invoice document upload'
        }
    }

    return rfqErrors
}
