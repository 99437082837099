import React from 'react'
import { FormHelperText, SelectProps } from '@material-ui/core'
import { Label } from './FormControl.styled'
import { StyledSelect } from '@/app/ui-new/components/Select/Select.styled'
import FormControl from '@/app/ui-new/components/Select/FormControl'
import { Dialog, DialogButtonContainer, DialogText, DialogTitle, CustomDialogProps } from '../Dialog/Dialog'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import { DialogTypography } from '../Dialog/Dialog.styled'

export interface Props extends SelectProps {
    label?: string
    helperText?: any
    size?: 'small' | 'medium'
    dialog?: CustomDialogProps
}

const Select: React.FunctionComponent<Props> = (props: Props) => {
    return <>
        {props.label ? <Label $disabled={!!props.disabled}>
            {props.required ? `${props.label} *` : `${props.label ?? ''}`}
            {props.dialog?.triggerIcon ?? null}
        </Label> : null}
        <FormControl fullWidth variant="outlined" size={props.size ?? 'medium'} error={props.error}>
            <StyledSelect
                {...props}
                label={undefined}
            >
                {props.children}
            </StyledSelect>
            {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null}
        </FormControl>
        {props.dialog && <Dialog
                size='normal'
                open={props.dialog.showModal}
                onClose={props.dialog.btnOnClick}
            >
                <DialogTitle centered>
                    <strong>{ props.dialog.title }</strong>
                </DialogTitle>
                <DialogText mt={4} mb={2}>
                    <DialogTypography>
                        {
                            props.dialog.content
                        }
                    </DialogTypography>
                </DialogText>
                <DialogButtonContainer centered>
                    <PrimaryButton
                        size='medium'
                        onClick={props.dialog.btnOnClick}
                    >
                        { props.dialog.btnText }
                    </PrimaryButton>
                </DialogButtonContainer>
            </Dialog>}
    </>
}

export default Select
