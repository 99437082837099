import {
    ApiError,
    LoadTenderService,
    LoadTenderOverrides,
    ErrorResponse,
    GenerateLoadTenderResponse,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const LoadTenderGeneratorApiService = Object.freeze({
    generateLoadTender: async (
        orderId: string,
        loadTenderOverrides?: LoadTenderOverrides | undefined
    ): Promise<GenerateLoadTenderResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the Load Tender'

        try {
            const res = await LoadTenderService.generateLoadTender({
                orderId: orderId,
                loadTenderOverrides: loadTenderOverrides,
            })

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message === 'No shipments attached to the order provided.') {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
})
