import * as types from '../constants'
import { Action } from '../constants'
import { Currency } from '@lazr/openapi-client'

export const setCurrencyRate = (source: Currency, target: Currency, lazrRate: number): Action => ({
    type: types.SET_CURRENCY_RATE,
    payload: {
        source,
        target,
        lazrRate,
    },
})
