/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './FedexLocation.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    FRONT: 'Avant',
    NONE: 'Aucun',
    REAR: 'Arrière',
    SIDE: 'Côté',
}))()
