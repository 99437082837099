import React from 'react'
import { TooltipProps, Tooltip as MuiTooltip } from './Tooltip.styled'

const Tooltip = (props: TooltipProps) => (
    <MuiTooltip
        PopperProps={{
            disablePortal: true,
            keepMounted: false /* Useful in devtool it leaves the code in the DOM for inspection */,
        }}
        arrow
        enterDelay={300}
        interactive={true}
        classes={{ popper: 'lzr-popper', tooltip: 'lzr-tooltip' }}
        {...props}
    >
        {props.children}
    </MuiTooltip>
)

export interface CustomTooltipProps {
    $bgColor: string
    $width: string
    icon?: [any, any]
    placement:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
        | undefined
    title: string
}

export default Tooltip
