import React from 'react'
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'

export const Container = styled.div`
    width: 100%;
    max-width: 375px;
    background-color: ${(props): string => props.theme.palette.accentPrimary900.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props): string => props.theme.spacing(12)}px ${(props): string => props.theme.spacing(4)}px;
`

export const Video: React.FC<VideoProps> = styled.div<VideoProps>`
    position: relative;
    min-height: 192px;
    min-width: 343px;
    opacity: 0;
    transition: opacity 0.3s;
    overflow: hidden;

    & iframe{
        height: 192px;
        width: 343px;
        min-height: 192px;
        min-width: 343px;
        border: 0;
        border-radius: 8px;
    }
`

export const VideoScreenShot: React.FC<VideoScreenShotProps> = styled.img<VideoScreenShotProps>`
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.3s;
    transition-timing-function: ease-in;
    border-radius: 8px;

    &.hidden{
        opacity: 0;
        pointer-events: none;
    }
`

export const VideoOverlay: React.FC<VideoOverlayProps> = styled.div<VideoOverlayProps>`
    position: absolute;
    inset: 2px;
    background-color: transparent;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    transition-timing-function: ease-in;
    border-radius: 8px;
    cursor: pointer;

    & img:hover{
        box-shadow: 2px 2px 10px black;
    }

    &.hidden{
        opacity: 0;
        pointer-events: none;
    }
`

interface VideoProps{
    ref: React.RefObject<HTMLDivElement>
}

interface VideoScreenShotProps{
    ref: React.RefObject<HTMLDivElement>
    src: string
}
interface VideoOverlayProps{
    ref: React.RefObject<HTMLDivElement>
    onClick: (event: MouseEvent) => void
}

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Header = styled(styled(MuiTypography)(spacing))`
    font-weight: 700;
    font-size: 1.7142rem;
    line-height: 2.2285rem;
    text-align: center;
    color: ${(props): string => props.theme.palette.white.main};
`

export const Text = styled(styled(MuiTypography)(spacing))`
    font-weight: 400;
    font-size: 1.1428rem;
    line-height: 1.6rem;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${(props): string => props.theme.palette.white.main};
`
