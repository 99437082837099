import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { CustomsBroker } from '../../model'
import { handleUnauthorizedException } from './index'

export const CustomsBrokerApiService = Object.freeze({
    list: async (): Promise<CustomsBroker[]> => {
        let getCustomsBrokersResponse
        try {
            getCustomsBrokersResponse = await EnumTypesService.getCustomsBrokers()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve customs broker list')
            }
            throw new Error('Unable to retrieve customs broker list')
        }

        if (!getCustomsBrokersResponse.data) {
            logger.debug('list',  JSON.stringify(getCustomsBrokersResponse, null, 4))
            throw new Error('Unable to retrieve customs broker list')
        }

        return getCustomsBrokersResponse.data
    },
})
