import { Breakpoints } from '@material-ui/core/styles/createBreakpoints'
interface CustomBreakpointValues extends Breakpoints {
    xxl?: number;
  }
const breakpoints: any = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440,
        xxl: 1920,
    },
}

export default breakpoints
