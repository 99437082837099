import React, { useCallback, useRef, useState } from 'react'
import { Box, BoxProps } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToggleButtonGroup } from '@material-ui/lab'
import {
    StyledContainer,
    StyledTypographyH1,
    StyledTypographyLabel,
    StyledToggleButton,
    StyledTypographySwitcher,
    StyledInnerBox,
    StyledListItem,
    StyledOuterBox,
} from '@/app/ui-new/pages/website/components/CariersCarousel/Mobile/CarriersCarouselMobile.styled'
import CarrierLogo from '@/app/ui-new/components/CarrierLogo/CarrierLogo'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './CarriersCarouselMobile.i18n'

const CarriersCarouselMobile: React.FunctionComponent<Props> = (props: Props) => {
    const innerBoxRef = React.useRef<HTMLDivElement>()
    const [ carrierType, setCarrierType ] = React.useState<'freight' | 'parcel'>('freight')
    const [ hasAnimationStopped, setHasAnimationStopped ] = React.useState<boolean>(false)
    const [ isMouseDown, setIsMouseDown ] = useState(false)
    const intervalIdRef = useRef<number>()
    const carrierTypeRef = useRef(carrierType)
    carrierTypeRef.current = carrierType
    const { t } = useI18n(i18n)

    const pageWidth = 375
    const mouseCoords = useRef({
        startX: 0,
        scrollLeft: 0,
    })

    const stopAnimation = useCallback(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }
        setHasAnimationStopped(true)
    }, [ setHasAnimationStopped, intervalIdRef, window.clearInterval ])

    const handleCarrierTypeChange = useCallback((event: React.MouseEvent<HTMLElement>, selectedType: 'freight' | 'parcel') => {
        stopAnimation()
        if (selectedType !== null) {
            setCarrierType(selectedType)
        }
    }, [ setCarrierType, stopAnimation ])

    const scroll = React.useCallback((element: HTMLDivElement) => {
        element.scrollLeft = element.scrollLeft + 1
        const hasScrollingEnded = (element.scrollLeft + pageWidth - 15) >= element.scrollWidth
        if (hasScrollingEnded) {
            window.clearInterval(intervalIdRef.current)
            window.setTimeout(() => {
                setCarrierType(() => carrierTypeRef.current === 'parcel' ? 'freight' : 'parcel')
            }, 2000)
        }
    }, [ innerBoxRef, carrierType, intervalIdRef, pageWidth, window.clearInterval, window.setTimeout, setCarrierType, carrierTypeRef ])

    const startScrolling = React.useCallback(() => {
        if (!hasAnimationStopped) {
            const element = innerBoxRef.current

            if (element) {
                intervalIdRef.current = window.setInterval(() => {
                    scroll(element)
                }, 10)
            }
        }
    }, [ hasAnimationStopped, innerBoxRef, intervalIdRef, window.setInterval, scroll ])

    React.useEffect(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }

        if (innerBoxRef.current) {
            innerBoxRef.current.scrollLeft = 0
        }

        const isListOverflowed = !!innerBoxRef.current && (innerBoxRef.current.scrollWidth > pageWidth)
        const timeout = isListOverflowed ? 2000 : 5000

        window.setTimeout(() => {
            startScrolling()
        }, timeout)

        return (() => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        })
    }, [ carrierType, intervalIdRef, innerBoxRef ])

    const renderItemsList = useCallback ((carriersType: 'freight' | 'parcel'): React.ReactNode => {
        if (carriersType === 'freight') {
            return props.freightCarriers.map((carrier, index) => (
                <StyledListItem key={index}>
                    <CarrierLogo variant={carrier} size='wide-large'/>
                </StyledListItem>
            ))
        }

        return props.parcelCarriers.map((carrier, index) => (
            <StyledListItem key={index}>
                <CarrierLogo variant={carrier} size='wide-large'/>
            </StyledListItem>
        ))
    }, [
        props.freightCarriers,
        props.parcelCarriers,
    ])

    const handleDragStart = useCallback((e) => {
        if (!innerBoxRef.current) {
            return
        }
        stopAnimation()
        if (!innerBoxRef.current) {return}
        const slider = innerBoxRef.current
        const startX = e.pageX - slider.offsetLeft
        const scrollLeft = slider.scrollLeft
        mouseCoords.current = { startX, scrollLeft }
        setIsMouseDown(true)
    }, [ innerBoxRef, setIsMouseDown, stopAnimation, mouseCoords ])

    const handleDragEnd = useCallback(() => {
        if (!innerBoxRef.current) {
            return
        }
        setIsMouseDown(false)
    }, [ innerBoxRef, setIsMouseDown ])

    const handleDrag = useCallback ((e) => {
        if (!isMouseDown || !innerBoxRef.current) {
            return
        }
        e.preventDefault()
        const slider = innerBoxRef.current
        const x = e.pageX - slider.offsetLeft
        const walkX = (x - mouseCoords.current.startX) * 1.5
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
    }, [ innerBoxRef, isMouseDown ])

    return (
        <StyledContainer width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <StyledContainer display='flex' flexDirection={'column'} width={pageWidth}>
                <Box display='flex' flexDirection='row' justifyContent={'space-between'} mx={4} mt={12}>
                    <Box>
                        <StyledTypographyH1 variant='h1'>{props.primaryLabel}</StyledTypographyH1>
                        <StyledTypographyLabel variant='body2'>{props.secondaryLabel}</StyledTypographyLabel>
                    </Box>
                    <Box>
                        <ToggleButtonGroup orientation="vertical" value={carrierType} exclusive onChange={handleCarrierTypeChange}>
                            <StyledToggleButton value="freight" aria-label="freight">
                                {carrierType === 'freight' && <FontAwesomeIcon  icon={[ 'far', 'pallet' ]}/>}
                                <StyledTypographySwitcher variant={'body2'}>{t('Freight')}</StyledTypographySwitcher>
                            </StyledToggleButton>
                            <StyledToggleButton value="parcel" aria-label="parcel">
                                {carrierType === 'parcel' && <FontAwesomeIcon  icon={[ 'far', 'box-open' ]}/>}
                                <StyledTypographySwitcher variant={'body2'}>{t('Parcel')}</StyledTypographySwitcher>
                            </StyledToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Box>
                <Box mt={9} mb={12}>
                    <StyledOuterBox>
                        <StyledInnerBox
                            // @ts-ignore
                            ref={innerBoxRef}
                            onClick={stopAnimation}
                            onMouseDown={handleDragStart}
                            onMouseUp={handleDragEnd}
                            onMouseMove={handleDrag}
                            isOverflowed = {!!innerBoxRef.current && (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth)}
                        >
                            {renderItemsList(carrierType)}
                        </StyledInnerBox>
                    </StyledOuterBox>
                </Box>
            </StyledContainer>
        </StyledContainer>
    )
}

export interface Props {
    freightCarriers: string[]
    parcelCarriers: string[]
    primaryLabel?: string
    secondaryLabel?: string
}

export interface InnerBoxProps extends BoxProps {
    isOverflowed?: boolean
}

export default CarriersCarouselMobile
