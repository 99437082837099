import { Currency, Provider } from '@lazr/enums'
import { RoseRocketSpecifics } from '@/app/model/ProviderSpecifics'
import { OrderInfo } from '@/app/model'

export const createRoseRocketSpecificObject = (provider: Provider | undefined, order: Partial<OrderInfo>): RoseRocketSpecifics => {
    if (!!order.rfq?.commercialInvoiceDocumentIndicator && (!order.rfq?.importExport || !order.rfq?.billDutiesToParty)) {
        throw new Error(('Required fields missing.'))
    }

    return {
        roseRocketSpecifics: {
            LTL: {
                importExportType: order.rfq?.importExport?.code ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyCurrency: order.rfq?.dutyCurrency ?? Currency.CAD,
            },
        },
    }
}
