/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Onboarding {
    API = 'API',
    GMX = 'GMX',
    GMX_IMPORT = 'GMX_IMPORT',
    WEBSITE = 'WEBSITE',
    GOOGLE_ADS = 'GOOGLE_ADS',
    TRACKING_PAGE = 'TRACKING_PAGE',
    CANADA_CHAMBER_OF_COMMERCE = 'CANADA_CHAMBER_OF_COMMERCE',
}