import styled from 'styled-components'
import { Typography as MuiTypography } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import { AlertBanner, AlertBannerContent } from '@/app/ui-new/components/AlertBanner/AlertBanner'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import { Snackbar } from '@/app/ui-new/components/Snackbar/Snackbar'
export const StyledSnackbar = styled(Snackbar)`
    position: unset
`
export const StyledTypography = styled(styled(MuiTypography)(MuiSpacing))``

export const StyledAlertBanner = styled(AlertBanner)`
`

export const StyledAlertBannerContent = styled(AlertBannerContent)`
`

export const StyledButton = styled(PrimaryButton)`
    white-space: nowrap;
`
