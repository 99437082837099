import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { IncotermsType } from '../../model'
import { handleUnauthorizedException } from './index'

export const IncotermsTypeApiService = Object.freeze({
    list: async (): Promise<IncotermsType[]> => {
        let getIncotermsTypeResponse
        try {
            getIncotermsTypeResponse = await EnumTypesService.getIncotermsTypes()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve incoterms list')
            }
            throw new Error('Unable to retrieve incoterms type list')
        }

        if (!getIncotermsTypeResponse.data) {
            logger.debug('list',  JSON.stringify(getIncotermsTypeResponse, null, 4))
            throw new Error('Unable to retrieve incoterms list')
        }

        return getIncotermsTypeResponse.data
    },
})
