import React from 'react'
import { Box, Grid } from '@material-ui/core'
import {
    StyledH1Typography, StyledListItem,
    StyledListItemTypography,
    StyledParagraphTypography, StyledStatBox, StyledStatContainer, StyledStatTypography,
    StyledTitleTypography,
} from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/OldVsNewWayMobile.styled'
import OldWayHeader from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/OldWayHeader'
import NewWayHeader from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/NewWayHeader'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './OldVsNewWayMobile.i18n'

const OldVsNewWayMobile: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return (
        <>
            <Box width={'100%'} display={'flex'} justifyContent={'center'} bgcolor={'#FFF'}>
                <Box width={375} p={4} py={12}>
                    <StyledTitleTypography variant={'h1'}>
                        {t('Ship')} <span className={'lzr-color-purple'}>{t('better')}</span>  {t('in every way')}
                    </StyledTitleTypography>
                    <Box mt={8}>
                        <StyledParagraphTypography variant={'h6'}>
                            {t('Traditional shipping takes too long and costs too much')}.
                        </StyledParagraphTypography>
                        <Box mt={4}>
                            <StyledParagraphTypography variant={'h6'}>
                                {t('Get Lazr to take care of all your cross-border shipping needs and more')}.
                            </StyledParagraphTypography>
                        </Box>
                    </Box>

                    <Box mt={18}>
                        <OldWayHeader/>
                        <Box mt={10}>
                            <Box display={'flex'} alignItems={'center'}>
                                <StyledListItem>
                                    <img src={`/images/website/OldVsNewWay/IconOldway1.svg?v=${window.lazrVersion}`}
                                        alt="IconOldWay1"/>
                                </StyledListItem>
                                <StyledListItemTypography variant={'body1'}>
                                    52+ {t('minutes to complete booking, documentation and billing')}
                                </StyledListItemTypography>
                            </Box>
                            <Box mt={4} display={'flex'} alignItems={'center'}>
                                <StyledListItem>
                                    <img src={`/images/website/OldVsNewWay/IconOldway2.svg?v=${window.lazrVersion}`}
                                        alt="IconOldWay2"/>
                                </StyledListItem>
                                <StyledListItemTypography variant={'body1'}>
                                    {t('Limited options from one or two carriers')}
                                </StyledListItemTypography>
                            </Box>
                            <Box mt={4} display={'flex'} alignItems={'center'}>
                                <StyledListItem>
                                    <img src={`/images/website/OldVsNewWay/IconOldway3.svg?v=${window.lazrVersion}`}
                                        alt="IconOldWay3"/>
                                </StyledListItem>
                                <StyledListItemTypography variant={'body1'}>
                                    {t('Scattered shipment tracking')}
                                </StyledListItemTypography>
                            </Box>
                            <Box mt={4} display={'flex'} alignItems={'center'}>
                                <StyledListItem>
                                    <img src={`/images/website/OldVsNewWay/IconOldway4.svg?v=${window.lazrVersion}`}
                                        alt="IconOldWay4"/>
                                </StyledListItem>
                                <StyledListItemTypography variant={'body1'}>
                                    {t('Multiple accounts with different logins')}
                                </StyledListItemTypography>
                            </Box>
                        </Box>

                        <Box mt={11}>
                            <NewWayHeader/>
                            <Box mt={10}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <StyledListItem>
                                        <img src={`/images/website/OldVsNewWay/IconLazrway1.svg?v=${window.lazrVersion}`}
                                            alt="IconLazrWay1"/>
                                    </StyledListItem>
                                    <StyledListItemTypography variant={'body1'}>
                                        {t('Less than 3 minutes to complete booking, documentation and billing')}
                                    </StyledListItemTypography>
                                </Box>
                                <Box mt={4} display={'flex'} alignItems={'center'}>
                                    <StyledListItem>
                                        <img src={`/images/website/OldVsNewWay/IconLazrway2.svg?v=${window.lazrVersion}`}
                                            alt="IconLazrWay2"/>
                                    </StyledListItem>
                                    <StyledListItemTypography variant={'body1'}>
                                        {t('Instant shipping rates from dozens of carriers')}
                                    </StyledListItemTypography>
                                </Box>
                                <Box mt={4} display={'flex'} alignItems={'center'}>
                                    <StyledListItem>
                                        <img src={`/images/website/OldVsNewWay/IconLazrway3.svg?v=${window.lazrVersion}`}
                                            alt="IconLazrWay3"/>
                                    </StyledListItem>
                                    <StyledListItemTypography variant={'body1'}>
                                        {t('A single tracking view of all shipments, everywhere')}
                                    </StyledListItemTypography>
                                </Box>
                                <Box mt={4} display={'flex'} alignItems={'center'}>
                                    <StyledListItem>
                                        <img src={`/images/website/OldVsNewWay/IconLazrway4.svg?v=${window.lazrVersion}`}
                                            alt="IconLazrWay4"/>
                                    </StyledListItem>
                                    <StyledListItemTypography variant={'body1'}>
                                        {t('All freight & parcel carriers in one place')}
                                    </StyledListItemTypography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <StyledStatContainer flexDirection={'column'} display={'flex'} justifyContent={'center'} mt={11}>
                        <Grid container xs={12} spacing={0}>
                            <Grid item xs={6}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>50+</StyledH1Typography>
                                    <StyledStatTypography>{t('Connected freight carriers')}</StyledStatTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>8</StyledH1Typography>
                                    <StyledStatTypography>{t('Connected parcel carriers')}</StyledStatTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>21%</StyledH1Typography>
                                    <StyledStatTypography>{t('Average savings in shipping costs')}</StyledStatTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>95%</StyledH1Typography>
                                    <StyledStatTypography>{t('Faster than traditional quoting')}</StyledStatTypography>
                                </StyledStatBox>
                            </Grid>
                        </Grid>
                    </StyledStatContainer>
                </Box>
            </Box>
        </>
    )
}

export default OldVsNewWayMobile
