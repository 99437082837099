import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logger } from '../../../logger'
import { AddressApiService } from '../../../service/ApiService'
import {
    setDefaultBillingAddresses as reduxSetDefaultBillingAddresses,
    setDefaultShippingAddresses as reduxSetDefaultShippingAddresses,
} from '../../redux/actions/AddressListActions'
import { getDefaultAddressList as reduxGetDefaultAddressList } from '../../redux/selectors'
import { FilteredAddresses } from '@/app/model/Address'
import {
    setIsDefaultBillingAddressesCallExecuted as reduxSetIsDefaultBillingAddressesCallExecuted,
} from '@/app/ui/redux/actions/AddressListApiCallExecutedActions'

export const useDefaultAddressList = (isUserSignedIn: boolean) => {
    const dispatch = useDispatch()

    const defaultAddressList = useSelector(reduxGetDefaultAddressList)

    React.useEffect(() => {
        let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
        let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
        void (async (): Promise<void> => {
            try {
                if (!isUserSignedIn) {
                    return
                }
                ([ fetchedDefaultBillingAddresses,
                    fetchedDefaultShippingAddresses ] = await Promise.all([
                    await AddressApiService.list(
                        {
                            page: 1,
                            resultPerPage: 100,
                        },
                        {
                            isBilling: true,
                            isBillingDefault: true,
                        },
                    ),
                    await AddressApiService.list(
                        {
                            page: 1,
                            resultPerPage: 100,
                        },
                        {
                            isShippingDefault: true,
                            isBillingDefault: true,
                        },
                    ),
                ]))
                if (fetchedDefaultBillingAddresses) {
                    dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
                }
                if (fetchedDefaultShippingAddresses) {
                    dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
                }
                dispatch(reduxSetIsDefaultBillingAddressesCallExecuted(true))
            } catch (error: any) {
                logger.error('could not fetch defaultBillingAddresses', error)
            }
        })()
    }, [ isUserSignedIn ])

    return [ defaultAddressList ]
}
