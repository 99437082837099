/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BillingStatus {
    PENDING_PRICE_REVIEW = 'PENDING_PRICE_REVIEW',
    PENDING_INVOICE = 'PENDING_INVOICE',
    AWAITING_PAYMENT = 'AWAITING_PAYMENT',
    PENDING_PAYMENT = 'PENDING_PAYMENT',
    PAYMENT_PAST_DUE = 'PAYMENT_PAST_DUE',
}