import styled from 'styled-components'
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Checkbox as MuiCheckbox,
    Grid as MuiGrid,
    Typography as MuiTypography,
    Chip as MuiChip,
    CardHeader as MuiCardHeader,
} from '@material-ui/core'
import { spacing, spacing as MuiSpacing } from '@material-ui/system'

import { FontAwesomeIcon as MuiFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StyledNumberField from '@/app/ui-new/components/NumberField/NumberField'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``


export const Typography = styled(styled(MuiTypography)(MuiSpacing))`

&[class*="MuiTypography-colorError"] {
  color: ${(props): number => props.theme.palette.error900.main};
}
`

export const Load = styled(styled(MuiGrid)(MuiSpacing))`
height: 100vh;
`

export const Card = styled(styled(MuiCard)(spacing))`
    box-shadow: ${(props): string => props.theme.shadows[4]};
    height: 100%;
    width: 240px;
`

export const CardContent = styled(styled(MuiCardContent)(spacing))`
    &:last-child {
        padding-bottom: ${(props): number => props.theme.spacing(2)}px;
    }
`



export const Checkbox = styled(MuiCheckbox)`
  &.MuiButtonBase-root  {
      padding-left: ${(props): number => props.theme.spacing(0)}px;
      padding-bottom: ${(props): number => props.theme.spacing(1)}px;
      padding-top: ${(props): number => props.theme.spacing(1)}px;
  }
  &.Mui-checked {
      color: ${(props): number => props.theme.palette.accent1.main};
  }
`


export const FontAwesomeIcon = styled(styled(MuiFontAwesomeIcon)(MuiSpacing))`
    &.calendarAndClock {
      width: 16px;
      height: 16px;
      color: ${(props): string => props.theme.palette.accent1.main};
    }
`

export const CardHeader = styled(styled(MuiCardHeader)(MuiSpacing))`
    & .MuiCardHeader-action {
        position: relative;
        top: ${(props): number => props.theme.spacing(0.5)}px;
    }
`

export const GridLabel = styled(Grid) `
label{
    color:black;
}
`
interface NumberFieldProps {
    width?: string
}

export const NumberField: React.FC<NumberFieldProps> = styled(StyledNumberField)<NumberFieldProps>`
width: ${(props): string => props.width ? props.width : '100px' } ;
    & > [class^="MuiInputBase-root"]{
        width: ${(props): string => props.width ? props.width : '100px' } ;
        font-size: 1.5rem;
        &.Mui-disabled {
            background:rgba(16,210,194,0.12);
            input {
                color: black;
                padding-top: ${(props): number => props.theme.spacing(2.5)}px;
            }

            fieldset {
                border-color: ${(props): number => props.theme.palette.neutral300.main};
            }



        }

    }

    & > [class^="MuiFormLabel-root"] {

        &.Mui-disabled {
            color: ${(props): number => props.theme.palette.neutral500.main};
        }
    }
`

