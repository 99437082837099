import React, { useCallback, useEffect } from 'react'
import { logger } from '@/app/logger'
import { type AccessorialLocationType, AddressType } from '@lazr/openapi-client'
import GoogleAddressSearchBar, { type GoogleAddressSearchOption } from '@/app/ui-new/components/GoogleAddressSearchBar/GoogleAddressSearchBar'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './PlacesAutocomplete.i18n'
import { fetchAddressResults, isAddressBook } from './helpers'
import { useDebouncedCallback } from '@/app/ui-new/pages/hooks/useDebouncedCallback'

export type AutocompletePredictionAddress = google.maps.places.AutocompletePrediction
type PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest
type PlaceSearchRequest = google.maps.places.PlaceSearchRequest
type PlaceResult = google.maps.places.PlaceResult
type GeocoderRequest = google.maps.GeocoderRequest
type GeocoderResult = google.maps.GeocoderResult
type LatLng = google.maps.LatLng

export let autocompleteService: google.maps.places.AutocompleteService

let placesService: google.maps.places.PlacesService
let geocoderService: google.maps.Geocoder


export interface Props {
    id: string
    helperText?: string
    variant?: 'outlined'
    required?: boolean
    disabled?: boolean
    autoFocus?: boolean
    onSelect: (result: PlacesAutocompleteResult) => void
    value: GoogleAddressSearchOption | null
    onChange: (newValue: GoogleAddressSearchOption | null) => void
    className?: string
    size: 'large'| 'medium' | 'small'
    locationType: AccessorialLocationType
    onEditClick: () => void
    onEnterAddressManuallyClick: () => void
    showAddressFields: boolean
    error?: boolean
    fixedInputValue?: string
}

interface PlacesAutocompleteAddressComponent {
    longName: string
    shortName: string
}

interface PlacesAutocompleteInformation {
    zipOrPostalCode?: PlacesAutocompleteAddressComponent
    isBusiness?: boolean
}

export interface PlacesAutocompleteResult {
    id?: string
    name?: string
    formattedAddress: string | undefined
    streetNumber?: PlacesAutocompleteAddressComponent
    streetAddress2?: string
    street?: PlacesAutocompleteAddressComponent
    region?: PlacesAutocompleteAddressComponent
    city?: PlacesAutocompleteAddressComponent
    stateOrProvince?: PlacesAutocompleteAddressComponent
    country?: PlacesAutocompleteAddressComponent
    zipOrPostalCode?: PlacesAutocompleteAddressComponent
    geometry?: {
        latitude: number
        longitude: number
    }
    addressType?: AddressType
    isAddressBook?: boolean
}

const PlacesAutocomplete: React.FunctionComponent<Props> = ({
    id,
    helperText,
    variant,
    required,
    disabled,
    autoFocus,
    onSelect,
    value,
    onChange,
    className,
    size,
    onEditClick,
    onEnterAddressManuallyClick,
    showAddressFields,
    error,
    fixedInputValue,
}) => {
    const { t } = useI18n(i18n)
    const [ inputValue, setInputValue ] = React.useState<string>('')
    const [ options, setOptions ] = React.useState<GoogleAddressSearchOption[]>([])
    const [ addressWasSelected, setAddressWasSelected ] = React.useState<boolean>(false)
    const [ isPasted, setIsPasted ] = React.useState<boolean>(false)

    const updatePlaceDetails = async (request: PlaceDetailsRequest): Promise<void> => {
        try {
            const placeResult: PlaceResult = await new Promise((resolve, reject) => {
                try {
                    placesService.getDetails(request, (result: PlaceResult) => {
                        resolve(result)
                    })
                } catch (err: any) {
                    logger.error(err)
                    reject(err)
                }
            })
            let postalCodeFound = false

            const autocompleteResult: PlacesAutocompleteResult = {
                name: placeResult.name,
                formattedAddress: placeResult.formatted_address,
                geometry: {
                    latitude: placeResult.geometry ? placeResult.geometry.location.lat() : 0,
                    longitude: placeResult.geometry ? placeResult.geometry.location.lng() : 0,
                },
            }

            if (placeResult.address_components) {
                // biome-ignore lint/complexity/noForEach: <explanation>
                placeResult.address_components.forEach((item) => {
                    const names: PlacesAutocompleteAddressComponent = {
                        longName: item.long_name,
                        shortName: item.short_name,
                    }

                    if (item.types.includes('street_number')) {
                        autocompleteResult.streetNumber = names
                    } else if (item.types.includes('route')) {
                        autocompleteResult.street = names
                    } else if (item.types.includes('neighborhood')) {
                        autocompleteResult.region = names
                    } else if (item.types.includes('locality')) {
                        autocompleteResult.city = names
                    } else if (item.types.includes('administrative_area_level_1')) {
                        if (names.shortName.length < 3) {
                            autocompleteResult.stateOrProvince = names
                        }
                    } else if (item.types.includes('country')) {
                        autocompleteResult.country = names
                    } else if (item.types.includes('postal_code')) {
                        postalCodeFound = true
                        autocompleteResult.zipOrPostalCode = names
                    }
                })
            }
            autocompleteResult.addressType = placeResult.types?.includes('establishment') ? AddressType.BUSINESS :
                AddressType.RESIDENTIAL

            if ((!postalCodeFound || autocompleteResult.addressType !== AddressType.BUSINESS) && placeResult.geometry) {
                // First fallback
                const addressInfo = await getInformationFromGeolocation(placeResult.geometry.location)
                if (autocompleteResult.addressType !== AddressType.BUSINESS) {
                    autocompleteResult.addressType = addressInfo?.isBusiness ? AddressType.BUSINESS : AddressType.RESIDENTIAL
                }

                if (!postalCodeFound) {
                    let zipOrPostalCode = addressInfo?.zipOrPostalCode ?? null
                    let results: PlaceResult[] = []

                    // Second fallback
                    if (!zipOrPostalCode) {
                        results = await getPlaceResultsFromPostOfficeNearbySearch(placeResult.geometry.location)
                        zipOrPostalCode = getPostalCodeFromPlaceResults(results)
                    }
                    // Third fallback
                    if (!zipOrPostalCode) {
                        zipOrPostalCode = await getPostalCodeFromPostOfficePlaceResultsPlaceIds(results)
                    }
                    if (zipOrPostalCode) {
                        autocompleteResult.zipOrPostalCode = zipOrPostalCode
                    }
                }
            }

            onSelect(autocompleteResult)
        } catch (err: any) {
            logger.error(err)
        }
    }

    const getInformationFromGeolocation = (location: LatLng):
    Promise<PlacesAutocompleteInformation | null> =>
        new Promise((resolve, reject) => {
            try {
                const request: GeocoderRequest = {
                    location,
                }

                geocoderService.geocode(request, (results: GeocoderResult[]): void => {
                    if (!results) {
                        resolve(null)

                        return
                    }
                    let names: any
                    let isBusiness = false
                    for (const result of results) {
                        if (result.geometry.location_type === google.maps.GeocoderLocationType.ROOFTOP &&
                            result.types.includes('establishment')) {
                            isBusiness = true
                        }

                        const foundResult = result.address_components.find((item) =>
                            item.types.includes('postal_code')
                            && !item.types.includes('postal_code_prefix')
                            && !item.types.includes('postal_code_suffix'),
                        )
                        if (foundResult && !names) {
                            names = {
                                longName: foundResult.long_name,
                                shortName: foundResult.short_name,
                            }
                        }
                    }
                    resolve({ zipOrPostalCode: names,  isBusiness })
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })

    const getPlaceResultsFromPostOfficeNearbySearch = async (location: LatLng): Promise<PlaceResult[]> =>
        new Promise((resolve, reject) => {
            try {
                const request: PlaceSearchRequest = {
                    location: location,
                    radius: 5000,
                    type: 'post_office',
                }

                placesService.nearbySearch(request, (results: PlaceResult[]): void => {
                    resolve(results)
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })
    const getPostalCodeFromPlaceResults = (results: PlaceResult[]): PlacesAutocompleteAddressComponent | null => {
        for (const result of results) {
            const foundResult = result?.address_components?.find((item) => item.types.includes('postal_code'))
            if (foundResult) {
                return {
                    longName: foundResult.long_name,
                    shortName: foundResult.short_name,
                }
            }
        }

        return null
    }

    const getPostalCodeFromPostOfficePlaceResultsPlaceIds = async (
        results: PlaceResult[],
    ): Promise<PlacesAutocompleteAddressComponent | null> => {
        for (const result of results) {
            if (result.place_id) {
                const postOfficePostalCode = await getPostalCodeFromPostOfficePlaceId(result.place_id)
                if (postOfficePostalCode) {
                    return postOfficePostalCode
                }
            }
        }

        return null
    }

    const getPostalCodeFromPostOfficePlaceId = async (placeId: string): Promise<PlacesAutocompleteAddressComponent | null> =>
        new Promise((resolve, reject) => {
            try {
                const request: GeocoderRequest = {
                    placeId,
                }

                geocoderService.geocode(request, (results: GeocoderResult[]): void => {
                    if (!results) {
                        resolve(null)

                        return
                    }
                    for (const result of results) {
                        const foundResult = result.address_components.find((item) =>
                            item.types.includes('postal_code')
                            && !item.types.includes('postal_code_prefix')
                            && !item.types.includes('postal_code_suffix'),
                        )
                        if (foundResult) {
                            const names: PlacesAutocompleteAddressComponent = {
                                longName: foundResult.long_name,
                                shortName: foundResult.short_name,
                            }
                            resolve(names)

                            return
                        }
                    }
                    resolve(null)
                })
            } catch (err: any) {
                logger.error(err)
                reject(err)
            }
        })

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setIsPasted((event.nativeEvent as any).inputType.startsWith('insertFromPaste'))
        setAddressWasSelected(false)
        setInputValue(event.target.value)
    }, [ setAddressWasSelected, setInputValue, setIsPasted ])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const handlePlaceChanged = useCallback(async (placeId: string): Promise<void> => {
        if (!placeId) {
            return
        }

        const request: PlaceDetailsRequest = {
            placeId: placeId,
            fields: [ 'address_components', 'name', 'geometry', 'formatted_address', 'types' ],
        }
        setAddressWasSelected(true)
        await updatePlaceDetails(request)
    }, [ setAddressWasSelected, updatePlaceDetails ])

    const wait = 1
   // Calculate milliseconds
   const milliseconds = wait ? wait * 1000 : 0

    const fetchData = async (active: boolean): Promise<void> => {
        try {
            await fetchAddressResults(inputValue, (results?: GoogleAddressSearchOption[]) => {
                if (active) {
                    let newOptions = results || []

                    if (value) {
                        newOptions = newOptions.filter((option) => !(option.id === value.id && option.addressId === value.addressId &&
                            option.description === value.description))
                        newOptions = [ { ...value }, ...newOptions ]
                    }

                    setOptions(newOptions)
                }
            })
        }
        catch (e: any) {
            console.error(e.message)
            console.error(e.stack)
        }
    }


    const debounceData = useDebouncedCallback(async (active:boolean) => {
        try {
            await fetchData(active)
        } catch (error) {
            console.error('Error in fetchData:', error)
        }
    }, milliseconds)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        let active = true

        if ((window as any).google) {
            if (!autocompleteService) {
                autocompleteService = new google.maps.places.AutocompleteService()
            }

            if (!placesService) {
                placesService = new google.maps.places.PlacesService(document.querySelector('#places-service-div') as HTMLDivElement)
            }

            if (!geocoderService) {
                geocoderService = new google.maps.Geocoder()
            }
        }

        if (!autocompleteService || !placesService) {
            return
        }

        if (inputValue === '') {
            setOptions(value ? [ value ] : [])

            return
        }


        debounceData(active)

        return (): void => {
            active = false
        }
    }, [ value, inputValue, fetchAddressResults ])

    const appendToList = [
        {
            show: !addressWasSelected,
            description: t('Enter address manually...'),
            onClick: onEnterAddressManuallyClick,
        },
    ]

    return (
        <React.Fragment>
            <GoogleAddressSearchBar
                showLegend
                id={id}
                className={className}
                disabled={disabled}
                helperText={helperText}
                required={required}
                variant={variant}
                autoFocus={autoFocus}
                value={value}
                options={options}
                fixedInputValue={fixedInputValue}
                filterOptions={(predictionOptions) => predictionOptions}
                getOptionLabel={
                    (option: GoogleAddressSearchOption): string => option.description
                }
                size={size}
                onChange={
                    (_event, newValue) => {
                        void (async (): Promise<void> => {
                            onChange(newValue)
                            if (newValue && isAddressBook(newValue)) {
                                onSelect({
                                    id: newValue.id,
                                    isAddressBook: newValue.isAddressBook,
                                    formattedAddress: newValue.mainText,
                                    name: newValue.data.name,
                                    street: { shortName: newValue.data.streetAddressLine1, longName: newValue.data.streetAddressLine1 },
                                    streetAddress2: newValue.data.streetAddressLine2 || undefined,
                                    city: { shortName: newValue.data.city, longName: newValue.data.city },
                                    stateOrProvince: { shortName: newValue.data.state, longName: newValue.data.state },
                                    country: { shortName: newValue.data.country.code, longName: newValue.data.country.code },
                                    zipOrPostalCode: { shortName: newValue.data.postalCode, longName: newValue.data.postalCode },
                                    addressType: newValue.data.addressType,
                                })
                            }
                            else if (newValue?.addressId) {
                                await handlePlaceChanged(newValue.addressId)
                            }
                        })()
                    }
                }
                textFieldOnchange={handleChange}
                appendToList={appendToList}
                error={error}
            />
            {/* Fix this on paste before checking result */}
            {/*
            <PasteAddressWarningDialog
                open={isPasted}
                onClose={() => setIsPasted(false)}
            />
            */}
        </React.Fragment>
    )
}


export default PlacesAutocomplete
