import React, { useState } from 'react'
import {
    BoxPanel,
    StyledDialog,
    StyledFormContainer, Welcome, Wrapper,
} from './RegisterOrganizationDesktop.styled'
import { Box } from '@material-ui/core'
import { DialogCloseIcon } from '@/app/ui-new/components/Dialog/Dialog'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import RegisterOrganization from '@/app/ui-new/pages/auth/RegisterOrganization'
import i18n from '@/app/ui-new/pages/auth/AuthMain.i18n'
import { BannerDesktop } from '@/app/ui-new/pages/website/components/Register Organization/Desktop/Banner/BannerDesktop'
import { RegistrationState } from '@lazr/openapi-client'

const RegisterOrganizationDesktop: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useI18n(i18n)
    const welcomeMessageRegister = <>{t('Create your Organization')}</>
    const [ successfulSignup, setSuccessfulSignup ] = useState<boolean>(false)
    const [ registrationState, setRegistrationState ] = useState<RegistrationState | null>(null)

    return (
        <StyledDialog
            fullScreen
            alignment='right'
            open={props.isRegisterOrganizationOpen}
            onClose={() => {props.closeDialog()}}
        >
            <DialogCloseIcon
                onClick={() => {
                    props.closeDialog()
                }}
            />
            { successfulSignup && <Wrapper>
                <div>
                    <Welcome>
                        { welcomeMessageRegister }
                    </Welcome>
                </div>
                <RegisterOrganization
                    successfulSignup={successfulSignup}
                    setSuccessfulSignup={setSuccessfulSignup}
                    registrationState={registrationState}
                    setRegistrationState={setRegistrationState}
                />
            </Wrapper>
            }
            { !successfulSignup &&
            <Box display={'flex'} flexDirection={'row'}>
                <Box flexGrow={1} height={'100%'}>
                    <BannerDesktop/>
                </Box>
                <StyledFormContainer>
                    <Wrapper>
                        <div>
                            <Welcome>
                                { welcomeMessageRegister }
                            </Welcome>
                        </div>
                        <BoxPanel>
                            <RegisterOrganization
                                successfulSignup={successfulSignup}
                                setSuccessfulSignup={setSuccessfulSignup}
                                registrationState={registrationState}
                                setRegistrationState={setRegistrationState}
                            />
                        </BoxPanel>
                    </Wrapper>
                </StyledFormContainer>
            </Box>}
        </StyledDialog>
    )
}

export interface Props {
    isRegisterOrganizationOpen: boolean
    closeDialog: () => void
    openSnackbar: (type: 'error' | 'success') => Promise<void>
}

export default RegisterOrganizationDesktop
