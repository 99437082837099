/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './VideoHolderDesktop.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'Lazr Demo - Step by Step Intro': `Démo Lazr - Introduction pas à pas`,
    'Watch a 1-minute demo': `Regardez la démo d'une minute`,
    'Meet your intelligent shipping assistant': `Rencontrez votre assistant d'expédition intelligent`,
    'Lazr is a leading shipping software that helps you save on pallet and parcel shipping, complete orders efficiently, and make deliveries on time.': `Lazr est un logiciel d'expédition de premier plan qui vous aide à économiser sur les envois de palettes et de colis, à compléter les commandes de manière efficace et à effectuer les livraisons dans les délais.`,
}))()
