import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { Language } from '../../model'
import { handleUnauthorizedException } from './index'

export const LanguageApiService = Object.freeze({
    list: async (): Promise<Language[]> => {
        let getLanguagesResponse
        try {
            getLanguagesResponse = await EnumTypesService.getLanguages()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve language list')
            }
            throw new Error('Unable to retrieve language list')
        }

        if (!getLanguagesResponse.data) {
            logger.debug('list', JSON.stringify(getLanguagesResponse, null, 4))
            throw new Error('Unable to retrieve language list')
        }

        return getLanguagesResponse.data
    },
})
