import { UuidV4 } from '@lazr/openapi-client'
import {
    Country,
    Provider,
    ShipmentIdentifierType,
    TrackingCsvStatus,
    TrackingUpdateStatus,
    DocumentType,
} from '@lazr/enums'
import { Carrier } from '@/app/model/Carrier'

export interface CarrierTrackingUpdate {
    carrier: Carrier
    fileString: string
    fileName: string
}

export interface CarrierTrackingUpdateDocument {
    type: DocumentType
    fileName: string
    file: File
}

export interface TrackingUpdate {
    id: UuidV4
    orderId: UuidV4
    orderNumber: string
    trackingUpdateTime: string
    status: TrackingUpdateStatus
    line: string
}
export interface TrackingUpdateCsvBatchCarrierInfo {
    id: UuidV4
    name: string
    carrierId: UuidV4
    companyName: string | null
    streetAddressLine1: string
    streetAddressLine2: string
    city: string
    state: string
    country: TrackingUpdateCsvBatchCountry
    postalCode: string
}

interface TrackingUpdateCsvBatchCountry {
    code: Country
    name: string
}
export interface TrackingUpdateCsvBatchError {
    line: number
    reason: string
    orderId?: UuidV4
    orderNumber?: string
    shipmentIdentifierType: ShipmentIdentifierType | null
    shipmentIdentifierValue: string | null
}
interface User {
    id: UuidV4
    shortFullName: string
}

export interface TrackingUpdateCsvBatchProps {
    id: string
    number: string
    fileName: string
    provider: Provider
    status: TrackingCsvStatus
    trackingUpdates?: TrackingUpdate[]
    errors?: TrackingUpdateCsvBatchError[]
    createdByUser: User
    createdAt: string
    csvBatchDate?: string
    carrier: InvoiceCsvBatchCarrier
    documentId?: UuidV4
}
export interface InvoiceCsvBatchCarrier {
    id: UuidV4
    name: string
    carrierInfoList?: TrackingUpdateCsvBatchCarrierInfo[]
}

export class TrackingUpdateCsvBatch {
    public id: string
    public number: string
    public fileName: string
    public provider: Provider
    public status: TrackingCsvStatus
    public trackingUpdates?: TrackingUpdate[]
    public errors?: TrackingUpdateCsvBatchError[]
    public createdByUser?: User
    public createdAt: string
    public csvBatchDate?: string
    public carrier: InvoiceCsvBatchCarrier
    public documentId?: UuidV4
    constructor (props: TrackingUpdateCsvBatchProps) {
        this.id = props.id
        this.number = props.number
        this.fileName = props.fileName
        this.provider = props.provider
        this.status = props.status
        this.trackingUpdates = props.trackingUpdates
        this.errors = props.errors
        this.createdByUser = props.createdByUser
        this.createdAt = props.createdAt
        this.csvBatchDate = props.csvBatchDate
        this.carrier = props.carrier
        this.documentId = props.documentId
    }
}
