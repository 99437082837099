import React from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './index.i18n'
import { StyledAlertBanner,
    StyledAlertBannerContent,
    StyledTypography,
    StyledButton,
    StyledSnackbar } from './index.styled'

export const VersionNotistackAlert: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)
    const [ isVisible, setIsVisible ] = React.useState<boolean>(props.open)
    if (!isVisible) {
        return null
    }

    return (<StyledSnackbar
        open={isVisible}
        onClose={() => {setIsVisible(false)}}
        autoHideDuration={null}>
        <StyledAlertBanner
            severity='info'>
            <StyledAlertBannerContent
                title={t('New Version Available')}
                /* eslint-disable-next-line max-len */
                message={<StyledTypography variant='body1'>{t('Reload Lazr at your convenience for the latest version.')}</StyledTypography>}
                buttons={
                    <StyledButton size='medium' onClick={() => {window.location.reload()}}>
                        {t('Reload')}
                    </StyledButton>
                }
            />
        </StyledAlertBanner>
    </StyledSnackbar>)
}
interface Props {
    open: boolean
}
