/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingStatus } from '../models/BillingStatus';
import type { DocumentType } from '../models/DocumentType';
import type { EquipmentType } from '../models/EquipmentType';
import type { Onboarding } from '../models/Onboarding';
import type { OrderStatus } from '../models/OrderStatus';
import type { RequestType } from '../models/RequestType';
import type { RfqStatus } from '../models/RfqStatus';
import type { ServiceLevelType } from '../models/ServiceLevelType';
import type { SuccessResponse_GetDocumentByIdResponse_ } from '../models/SuccessResponse_GetDocumentByIdResponse_';
import type { SuccessResponse_GetDocumentsResponse_ } from '../models/SuccessResponse_GetDocumentsResponse_';
import type { SuccessResponse_GetJoinedDocumentsResponse_ } from '../models/SuccessResponse_GetJoinedDocumentsResponse_';
import type { SuccessResponse_GetOrderByIdResponse_ } from '../models/SuccessResponse_GetOrderByIdResponse_';
import type { SuccessResponse_GetOrderOpenTimeLeftResponse_ } from '../models/SuccessResponse_GetOrderOpenTimeLeftResponse_';
import type { SuccessResponse_GetOrdersResponse_ } from '../models/SuccessResponse_GetOrdersResponse_';
import type { SuccessResponse_GetOrdersStatsResponse_ } from '../models/SuccessResponse_GetOrdersStatsResponse_';
import type { SuccessResponse_GetRfqByIdResponse_ } from '../models/SuccessResponse_GetRfqByIdResponse_';
import type { SuccessResponse_GetRfqsResponse_ } from '../models/SuccessResponse_GetRfqsResponse_';
import type { SuccessResponse_GetShipmentByIdResponse_ } from '../models/SuccessResponse_GetShipmentByIdResponse_';
import type { SuccessResponse_GetShipmentsResponse_ } from '../models/SuccessResponse_GetShipmentsResponse_';
import type { TrackingFilterStatus } from '../models/TrackingFilterStatus';
import type { TransportType } from '../models/TransportType';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class OrderInfoService {

    /**
     * Get a document by its ID
     * @param documentId The document ID
     * @returns SuccessResponse_GetDocumentByIdResponse_ OK
     * @throws ApiError
     */
    public static async getDocumentById(
        documentId: UuidV4,
    ): Promise<SuccessResponse_GetDocumentByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/document-by-id/${documentId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a document by its file name
     * @param fileName The file name
     * @param documentType The type of the document
     * @param isClientDocument The flag indicating if document belongs to client
     * @returns SuccessResponse_GetDocumentByIdResponse_ OK
     * @throws ApiError
     */
    public static async getDocumentByFileName(
        fileName: string,
        documentType: DocumentType,
        isClientDocument: boolean,
    ): Promise<SuccessResponse_GetDocumentByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/document-by-file-name`,
            query: {
                'fileName': fileName,
                'documentType': documentType,
                'isClientDocument': isClientDocument,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of the documents on an order
     * @param orderId The order ID that the documents belongs to
     * @returns SuccessResponse_GetDocumentsResponse_ OK
     * @throws ApiError
     */
    public static async getDocuments(
        orderId: UuidV4,
    ): Promise<SuccessResponse_GetDocumentsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/documents`,
            query: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get all documents as one on an order
     * @param orderId The order ID that the documents belongs to
     * @returns SuccessResponse_GetJoinedDocumentsResponse_ OK
     * @throws ApiError
     */
    public static async getJoinedDocuments(
        orderId: UuidV4,
    ): Promise<SuccessResponse_GetJoinedDocumentsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/joined-documents`,
            query: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an order by its ID
     * @param orderId The order ID
     * @returns SuccessResponse_GetOrderByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrderById(
        orderId: string,
    ): Promise<SuccessResponse_GetOrderByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-by-id/${orderId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Gets either the open time of an order or the last time a meaninful event occured on that order + 30 minutes,
     * and the time left in seconds
     * @param orderId The order ID
     * @returns SuccessResponse_GetOrderOpenTimeLeftResponse_ OK
     * @throws ApiError
     */
    public static async getOrderOpenTimeLeft(
        orderId: string,
    ): Promise<SuccessResponse_GetOrderOpenTimeLeftResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-open-time-left/${orderId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of orders for your organization
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param isActionable The actionability of an order to filter by
     * @param orderNumbers The list of order numbers to filter by
     * @param clientRefNumbers The list of client reference numbers to filter by
     * @param threePlRefNumbers The list of 3pl reference numbers to filter by
     * @param openUserIds The list of open user ids to filter by
     * @param createdByUserIds The list of created by user ids to filter by
     * @param isOpen The state of the order to filter by
     * @param orderStatuses The list of order statuses to filter by
     * @param rfqStatuses The list of rfq statuses to filter by
     * @param clientOrganizationIds The list of client organization ids to filter by
     * @param threePlOrganizationIds The list of 3pl organization ids to filter by
     * @param createdDateFrom The start of the created date range to filter by
     * @param createdDateTo The end of the created date range to filter by
     * @param originPoNumbers The list of origin PO numbers to filter by
     * @param destinationPoNumbers The list of destination PO numbers to filter by
     * @param handlingUnitPoNumbers The list of handling unit PO numbers to filter by
     * @param readyDateFrom The start of the ready date range to filter by
     * @param readyDateTo The end of the ready date range to filter by
     * @param locationOrigins The list of origin locations to filter by
     * @param locationDestinations The list of destination locations to filter by
     * @param transportTypes The list of transport types to filter by
     * @param equipmentTypes The list of equipment types to filter by
     * @param requestTypes The list of request types to filter by
     * @param carrierNames The list of carrier names to filter by
     * @param billingStatuses The list of billing statuses to filter by
     * @param shipmentIdentifierValues The list of shipment identifier values to filter by
     * @param salesRepId The sales representative id to filter by
     * @param threePlAgentId The assigned 3PL agent id to filter by
     * @param ediTenderNumber The edi tender number to filter by
     * @param serviceLevelTypes The list of service level types to filter by
     * @param trackingFilterStatuses The list of tracking statuses to filter by
     * @param onboardingStatuses The list of onboarding statuses to filter by
     * @returns SuccessResponse_GetOrdersResponse_ OK
     * @throws ApiError
     */
    public static async getOrders(
        page?: number,
        resultsPerPage?: number,
        isActionable?: boolean,
        orderNumbers?: Array<string>,
        clientRefNumbers?: Array<string>,
        threePlRefNumbers?: Array<string>,
        openUserIds?: Array<UuidV4>,
        createdByUserIds?: Array<UuidV4>,
        isOpen?: boolean,
        orderStatuses?: Array<OrderStatus>,
        rfqStatuses?: Array<RfqStatus>,
        clientOrganizationIds?: Array<UuidV4>,
        threePlOrganizationIds?: Array<UuidV4>,
        createdDateFrom?: string,
        createdDateTo?: string,
        originPoNumbers?: Array<string>,
        destinationPoNumbers?: Array<string>,
        handlingUnitPoNumbers?: Array<string>,
        readyDateFrom?: string,
        readyDateTo?: string,
        locationOrigins?: Array<string>,
        locationDestinations?: Array<string>,
        transportTypes?: Array<TransportType>,
        equipmentTypes?: Array<EquipmentType>,
        requestTypes?: Array<RequestType>,
        carrierNames?: Array<string>,
        billingStatuses?: Array<BillingStatus>,
        shipmentIdentifierValues?: Array<string>,
        salesRepId?: UuidV4,
        threePlAgentId?: UuidV4,
        ediTenderNumber?: string,
        serviceLevelTypes?: Array<ServiceLevelType>,
        trackingFilterStatuses?: Array<TrackingFilterStatus>,
        onboardingStatuses?: Array<Onboarding>,
    ): Promise<SuccessResponse_GetOrdersResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/orders`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'isActionable': isActionable,
                'orderNumbers': orderNumbers,
                'clientRefNumbers': clientRefNumbers,
                'threePlRefNumbers': threePlRefNumbers,
                'openUserIds': openUserIds,
                'createdByUserIds': createdByUserIds,
                'isOpen': isOpen,
                'orderStatuses': orderStatuses,
                'rfqStatuses': rfqStatuses,
                'clientOrganizationIds': clientOrganizationIds,
                'threePlOrganizationIds': threePlOrganizationIds,
                'createdDateFrom': createdDateFrom,
                'createdDateTo': createdDateTo,
                'originPoNumbers': originPoNumbers,
                'destinationPoNumbers': destinationPoNumbers,
                'handlingUnitPoNumbers': handlingUnitPoNumbers,
                'readyDateFrom': readyDateFrom,
                'readyDateTo': readyDateTo,
                'locationOrigins': locationOrigins,
                'locationDestinations': locationDestinations,
                'transportTypes': transportTypes,
                'equipmentTypes': equipmentTypes,
                'requestTypes': requestTypes,
                'carrierNames': carrierNames,
                'billingStatuses': billingStatuses,
                'shipmentIdentifierValues': shipmentIdentifierValues,
                'salesRepId': salesRepId,
                'threePlAgentId': threePlAgentId,
                'ediTenderNumber': ediTenderNumber,
                'serviceLevelTypes': serviceLevelTypes,
                'trackingFilterStatuses': trackingFilterStatuses,
                'onboardingStatuses': onboardingStatuses,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of orders stats for your organization
     * @param orderNumbers The list of order numbers to filter by
     * @param clientRefNumbers The list of client reference numbers to filter by
     * @param threePlRefNumbers The list of 3pl reference numbers to filter by
     * @param openUserIds The list of open user ids to filter by
     * @param createdByUserIds The list of created by user ids to filter by
     * @param isOpen The state of the order to filter by
     * @param orderStatuses The list of order statuses to filter by
     * @param rfqStatuses The list of rfq statuses to filter by
     * @param clientOrganizationIds The list of client organization ids to filter by
     * @param threePlOrganizationIds The list of 3pl organization ids to filter by
     * @param createdDateFrom The start of the created date range to filter by
     * @param createdDateTo The end of the created date range to filter by
     * @param originPoNumbers The list of origin PO numbers to filter by
     * @param destinationPoNumbers The list of destination PO numbers to filter by
     * @param readyDateFrom The start of the ready date range to filter by
     * @param readyDateTo The end of the ready date range to filter by
     * @param locationOrigins The list of origin locations to filter by
     * @param locationDestinations The list of destination locations to filter by
     * @param transportTypes The list of transport types to filter by
     * @param equipmentTypes The list of equipment types to filter by
     * @param requestTypes The list of request types to filter by
     * @param carrierNames The list of carrier names to filter by
     * @param billingStatuses The list of billing statuses to filter by
     * @param shipmentIdentifierValues The list of shipment identifier values to filter by
     * @param salesRepId The sales representative id to filter by
     * @param threePlAgentId The assigned 3PL agent id to filter by
     * @param trackingFilterStatuses The list of tracking statuses to filter by
     * @param onboardingStatuses The list of onboarding statuses to filter by
     * @returns SuccessResponse_GetOrdersStatsResponse_ OK
     * @throws ApiError
     */
    public static async getOrdersStats(
        orderNumbers?: Array<string>,
        clientRefNumbers?: Array<string>,
        threePlRefNumbers?: Array<string>,
        openUserIds?: Array<UuidV4>,
        createdByUserIds?: Array<UuidV4>,
        isOpen?: boolean,
        orderStatuses?: Array<OrderStatus>,
        rfqStatuses?: Array<RfqStatus>,
        clientOrganizationIds?: Array<UuidV4>,
        threePlOrganizationIds?: Array<UuidV4>,
        createdDateFrom?: string,
        createdDateTo?: string,
        originPoNumbers?: Array<string>,
        destinationPoNumbers?: Array<string>,
        readyDateFrom?: string,
        readyDateTo?: string,
        locationOrigins?: Array<string>,
        locationDestinations?: Array<string>,
        transportTypes?: Array<TransportType>,
        equipmentTypes?: Array<EquipmentType>,
        requestTypes?: Array<RequestType>,
        carrierNames?: Array<string>,
        billingStatuses?: Array<BillingStatus>,
        shipmentIdentifierValues?: Array<string>,
        salesRepId?: UuidV4,
        threePlAgentId?: UuidV4,
        trackingFilterStatuses?: Array<TrackingFilterStatus>,
        onboardingStatuses?: Array<Onboarding>,
    ): Promise<SuccessResponse_GetOrdersStatsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/orders-stats`,
            query: {
                'orderNumbers': orderNumbers,
                'clientRefNumbers': clientRefNumbers,
                'threePlRefNumbers': threePlRefNumbers,
                'openUserIds': openUserIds,
                'createdByUserIds': createdByUserIds,
                'isOpen': isOpen,
                'orderStatuses': orderStatuses,
                'rfqStatuses': rfqStatuses,
                'clientOrganizationIds': clientOrganizationIds,
                'threePlOrganizationIds': threePlOrganizationIds,
                'createdDateFrom': createdDateFrom,
                'createdDateTo': createdDateTo,
                'originPoNumbers': originPoNumbers,
                'destinationPoNumbers': destinationPoNumbers,
                'readyDateFrom': readyDateFrom,
                'readyDateTo': readyDateTo,
                'locationOrigins': locationOrigins,
                'locationDestinations': locationDestinations,
                'transportTypes': transportTypes,
                'equipmentTypes': equipmentTypes,
                'requestTypes': requestTypes,
                'carrierNames': carrierNames,
                'billingStatuses': billingStatuses,
                'shipmentIdentifierValues': shipmentIdentifierValues,
                'salesRepId': salesRepId,
                'threePlAgentId': threePlAgentId,
                'trackingFilterStatuses': trackingFilterStatuses,
                'onboardingStatuses': onboardingStatuses,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a request for quote by its ID
     * @param rfqId The RFQ ID
     * @returns SuccessResponse_GetRfqByIdResponse_ OK
     * @throws ApiError
     */
    public static async getRfqById(
        rfqId: UuidV4,
    ): Promise<SuccessResponse_GetRfqByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/rfq-by-id/${rfqId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a request for quote by the order ID
     * @param orderId The order ID that the RFQ belongs to
     * @returns SuccessResponse_GetRfqsResponse_ OK
     * @throws ApiError
     */
    public static async getRfqs(
        orderId?: UuidV4,
    ): Promise<SuccessResponse_GetRfqsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/rfqs`,
            query: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a shipment by its ID
     * @param shipmentId The shipment ID
     * @returns SuccessResponse_GetShipmentByIdResponse_ OK
     * @throws ApiError
     */
    public static async getShipmentById(
        shipmentId: UuidV4,
    ): Promise<SuccessResponse_GetShipmentByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/shipment-by-id/${shipmentId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of shipments on an order
     * @returns SuccessResponse_GetShipmentsResponse_ OK
     * @throws ApiError
     */
    public static async getShipments(): Promise<SuccessResponse_GetShipmentsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/shipments`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}