import { AnyAction, Reducer } from 'redux'
import { initialLaneListFiltering } from '../store/InitialState'
import * as types from '../constants'
import { LaneFilter, LaneListFiltering } from '@/app/ui/definitions/LaneList'

const reducer: Reducer = (state: LaneListFiltering = initialLaneListFiltering, action: AnyAction): LaneListFiltering => ({
    filters: filters(state.filters, action),
})

const filters = (state: LaneFilter, action: AnyAction): LaneFilter =>
    action.type === types.SET_LANE_FILTER
        ? action.payload
        : state

export default reducer
