import React, { useRef, useState } from 'react'
import { IconButton, Menu, MenuBackgroundOverlay } from './CurrencyMenu.styled'
import { Currency, User } from '@/app/model'
import { Typography, MenuItem } from '@material-ui/core'
import { UserApiService } from '@/app/service/ApiService'
import { logger } from '@/app/logger'
import { setUser as reduxSetUser } from '@/app/ui/redux/actions/UserActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDefaultUserCurrency as reduxGetDefaultUserCurrency } from '@/app/ui/redux/selectors'
import { getCurrencyList as reduxGetCurrencyList } from '../../../ui/redux/selectors/AppStateSelectors'
import { Currency as CurrencyCode } from '@lazr/enums'

const CurrencyMenu: React.FunctionComponent<CurrencyMenuProps> = ({ user }) => {
    const { currencyList } = useSelector(reduxGetCurrencyList)
    const dispatch = useDispatch()

    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const defaultUserCurrency: CurrencyCode = useSelector(reduxGetDefaultUserCurrency)

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const handleSelectedCurrency = async (currency: Currency): Promise<void> => {
        if (!user) {
            logger.error('Cannot update the display currency preference while user is logged out')

            return
        }

        if (user.currency?.code === currency.code) {
            setIsMenuOpen(false)

            return
        }
        try {
            const updateUserProfile = await UserApiService.editProfile({
                id: user.id,
                organizationId: user.organizationId,
                currency: currency.code,
            })

            logger.debug(updateUserProfile)
            const currentUser =  await UserApiService.getCurrent()
            if (user.getImpersonatingInfo().active) {
                currentUser.impersonate(user.getImpersonatingInfo())
            }
            dispatch(reduxSetUser(currentUser))
        } catch (error: any) {
            logger.error(error)
        }
        setIsMenuOpen(false)
    }

    return (
        <>
            <IconButton
                ref={buttonRef}
                aria-owns={isMenuOpen ? 'menu-appbar' : ''}
                aria-haspopup="true"
                onClick={toggleMenu}
                color="inherit"
            >
                <Typography variant="body1" align="center">
                    { user?.currency?.code ?? defaultUserCurrency }
                </Typography>
            </IconButton>
            <MenuBackgroundOverlay
                display={isMenuOpen ? 'block' : 'none'}
                onClick={(): void => setIsMenuOpen(false)}
            >
                <Menu
                    id="menu-appbar"
                    anchorEl={buttonRef.current}
                    open={isMenuOpen}
                    onClick={(): void => setIsMenuOpen(false)}
                >
                    {currencyList?.map((item) =>
                        <MenuItem
                            key={item.code}
                            value={item.code}
                            onClick={(event) => {
                                void (async (): Promise<void> => {
                                    await handleSelectedCurrency(item)
                                })()
                            }}>
                            {item.code}
                        </MenuItem>,
                    )}
                </Menu>
            </MenuBackgroundOverlay>
        </>
    )
}

interface CurrencyMenuProps {
    user?: User | null
}

export default CurrencyMenu
