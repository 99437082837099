import React from 'react'
import {
    StyledContentTypography,
    StyledTitleTypography1, StyledTitleTypography2, StyledTitleTypography3,
    Wrapper,
} from './DevelopedByDesktop.styled'
import { Box, Grid } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './DevelopedByDesktop.i18n'

const DevelopedByDesktop: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    return (
        <Wrapper>
            <Box width={1224}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Box width={600}>
                            <StyledTitleTypography1 variant={'h1'}>
                                {t('Developed by a')}
                            </StyledTitleTypography1>
                            <Box>
                                <StyledTitleTypography2 display={'inline'} variant={'h1'}>
                                    {t('Leading')}
                                </StyledTitleTypography2>
                                <StyledTitleTypography3 display={'inline'} variant={'h1'}>
                                    {t('logistics company')}
                                </StyledTitleTypography3>
                            </Box>
                        </Box>
                        <Box pt={12}>
                            <StyledContentTypography variant={'h5'}>
                                {t('Easily manage your business’ freight and parcel shipping. Lazr’s proprietary platform helps improve the efficiency and effectiveness of your entire shipping operations—saving time and reducing costs for everyone involved.')}
                            </StyledContentTypography>
                        </Box>
                        <Box pt={12}>
                            <StyledContentTypography variant={'h5'}>
                                {t('Our cutting-edge algorithm gives you access in seconds to a complete and comprehensive view of shipping rates in the USA and Canada, so you know which carriers and at what price your shipment can be handled.')}
                            </StyledContentTypography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box height={'100%'} display={'flex'} alignItems={'center'}>
                            <Box ml={10}>
                                <img src={`/images/website/developedBy/LeadingLogisticsCompany.png?v=${window.lazrVersion}`}
                                    alt="Developed by" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Wrapper>
    )
}

export interface Props {
}

export default DevelopedByDesktop
