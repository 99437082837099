import { CurrencyExchangeRateChanged } from '@lazr/events'
import { Currency, EventType } from '@lazr/openapi-client'
import React from 'react'
import { useDispatch } from 'react-redux'
import { logger } from '../../../logger'
import { ExchangeRateApiService } from '../../../service/ApiService'
import SubscriptionService from '../../../service/SubscriptionService'
import { getCurrencyRatePairFromList } from '../../lib/helpers'
import { setCurrencyRate as reduxSetCurrencyRate } from '../../redux/actions/CurrencyRatesActions'

export const useCurrencyExchangeRate = (isUserSignedIn: boolean) => {
    const dispatch = useDispatch()

    React.useEffect(() => {
        void (async (): Promise<void> => {
            try {
                if (!isUserSignedIn) {
                    return
                }
                const currencyRateList = await ExchangeRateApiService.list()
                const fetchedCadToUsdRate = parseFloat(getCurrencyRatePairFromList(currencyRateList, Currency.CAD, Currency.USD).lazrRate)
                if (isNaN(fetchedCadToUsdRate)) {
                    throw new Error('lazrCadToUsdRate fetched is not a number')
                }
                const fetchedUsdToCadRate = parseFloat(getCurrencyRatePairFromList(currencyRateList, Currency.USD, Currency.CAD).lazrRate)
                if (isNaN(fetchedUsdToCadRate)) {
                    throw new Error('lazrUsdToCadRate fetched is not a number')
                }
                dispatch(reduxSetCurrencyRate(
                    Currency.USD,
                    Currency.CAD,
                    fetchedUsdToCadRate,
                ))
                dispatch(reduxSetCurrencyRate(
                    Currency.CAD,
                    Currency.USD,
                    fetchedCadToUsdRate,
                ))
            } catch (error: any) {
                logger.error('could not fetch currency rate list', error)
            }
        })()
    }, [ isUserSignedIn ])

    React.useEffect((): () => void => {
        const subscription = SubscriptionService.subscribe(
            [ EventType.CURRENCY_EXCHANGE_RATE_CHANGED ],
            (event: CurrencyExchangeRateChanged) => {
                dispatch(reduxSetCurrencyRate(event.data.source, event.data.target, event.data.lazrRate))
            },
        )

        return subscription.destroy
    }, [])
}
