import React from 'react'
import i18n from './AppFooter.i18n'
import moment from 'moment'
import { Grid } from '@material-ui/core'
import { A, Copyright, Link, WrapperGrid } from './AppFooter.styled'
import { privacyPolicyRoute, termsOfServiceRoute, helpCenterRoute } from '@/app/ui/routes'
import { useI18n } from '@/app/ui/components/hooks/I18n'

const AppFooter: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return <WrapperGrid container alignItems="center" id={'app-footer'}>
        <Grid item container spacing={0} justifyContent="space-between">
            <Grid item xs='auto'>
                <A href="mailto:info@lazr.io?subject=Support Request">{t('Support')}</A>
                <Link to={helpCenterRoute.path}>{t('Help Center')}</Link>
                <Link to={privacyPolicyRoute.path}>{t('Privacy')}</Link>
                <Link to={termsOfServiceRoute.path}>{t('Terms of Service')}</Link>
            </Grid>
            <Grid item xs='auto'>
                <Copyright variant="body2">
                    {`Lazr Freight Inc. © 2019-${moment().year()}`}
                </Copyright>
            </Grid>
        </Grid>
    </WrapperGrid>
}

export { AppFooter }

export default AppFooter
