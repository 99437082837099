import type { Pickup, SchedulePickupAttributes, Organization } from '@/app/model'
import { PickupApiService } from '@/app/service/ApiService'
import { ApiServiceAction } from '@lazr/enums'
import { useEffect, useState } from 'react'
import { EventType } from '@lazr/openapi-client'
import SubscriptionService from '@/app/service/SubscriptionService'
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback'
import type { PickupIdentifier } from '@lazr/openapi-client'
import { PickupListFilter } from '../../../../service/ApiService/PickupApiService'

export interface SubscribeProps {
    wait?: number
}
export interface SchedulePickupProps {
    list?: {
        filters: PickupListFilter
        page: number
        limit: number
        wait?: number
        userOrganization: Organization | null
        subscribe?: SubscribeProps
    },
    newData?: SchedulePickupAttributes
}

export interface ReturnSchedulePickupDataProps {
    newPickupDate?: string | null | undefined
    pickupIdentifiers: PickupIdentifier[]
    error?: any,
    loaded?: boolean,
}
export interface ReturnSchedulePickupProps {
    list?: {
        total: number
        pickups: Pickup[]
        loaded: boolean
    }
    new?: ReturnSchedulePickupDataProps
}



const useSchedulePickup = (
    { list, newData }: SchedulePickupProps,
    action: ApiServiceAction,
): ReturnSchedulePickupProps => {
    // Define initial results state
    const [result, setResult] = useState<ReturnSchedulePickupProps['list']>({ total: 0, pickups: [], loaded: false })
    const [newResult, setNewResult] = useState<ReturnSchedulePickupDataProps| undefined>()

    // Calculate milliseconds
    const milliseconds = list?.wait ? list.wait * 1000 : 0

    // Function to fetch data
    const listSchedulePickup = async (): Promise<void> => {
        try {
            const result = await PickupApiService.list(
            {
                page: list?.page ? list?.page : 1,
                    resultPerPage: list?.limit ? list?.limit : 10,
            },
            list?.filters,
        )
            const resultSchedulePickup: ReturnSchedulePickupProps['list'] = {
                ...result,
                loaded: true,
            }
            setResult(resultSchedulePickup)
        } catch (error) {
            console.error('Error fetching order data:', error)
            setResult({
                total: 0,
                pickups: [],
                loaded: false,
            })
        }
    }

    // Function to schedule new pickup
    const newSchedulePickup = async (): Promise<void> => {
        try {
            if (newData) {
                const result: ReturnSchedulePickupDataProps | undefined = await PickupApiService.schedulePickup(newData)
                if (result) {
                    setNewResult({ ...result, loaded: true })
                } else {
                    setNewResult({
                        newPickupDate: undefined,
                        pickupIdentifiers: [],
                        error: 'Internal Error',
                        loaded: false,
                    })
                }
            }
        } catch (error) {
            console.error('Error scheduling new pickup:', error)
            setNewResult({
                newPickupDate: undefined,
                pickupIdentifiers: [],
                error: error,
                loaded: false,
            })
        }
    }

    const debounceSchedulePickup = useDebouncedCallback(async (action) => {
        switch (action) {
            case ApiServiceAction.LIST:
                await listSchedulePickup()
                break
            case ApiServiceAction.NEW:
                await newSchedulePickup()
                break
            default:
                break
        }
    }, milliseconds)

    // Effect to fetch data on limit and page change
    useEffect(() => {
        debounceSchedulePickup(action)
    }, [debounceSchedulePickup, action,
        list?.limit,
        list?.page,
        list?.userOrganization,
        list?.filters.carrierNames,
        list?.filters.pickupNumbers,
        list?.filters.createdByUserIds,
        list?.filters.contactNames,
        list?.filters.pickupStatuses,
        list?.filters.createdDateFrom,
        list?.filters.createdDateTo,
        list?.filters.readyDateFrom,
        list?.filters.readyDateTo,
        list?.filters.locationPickups,
        list?.filters.locationShippers,
        list?.filters.transportTypes,
        // Add a new filter
        newData])

    // Calculate milliseconds for subscription
    const subscriptionMilliseconds = list?.subscribe?.wait ? list.subscribe.wait * 1000 : 0

    const debounceSchedulePickupForSubscription = useDebouncedCallback(async (action) => {
        try {
            if (action === ApiServiceAction.LIST) {
                await listSchedulePickup()
            }
        } catch (error) {
            console.error('Error in fetchData:', error)
        }
    }, subscriptionMilliseconds)

    // Effect to subscribe to events and fetch data with debounce
    useEffect(() => {
        if (!list?.subscribe) {
            return
        }

        // Subscribe to events
        const subscription = SubscriptionService.subscribe(
            [EventType.PICKUP_SCHEDULED, EventType.PICKUP_COULD_NOT_BE_SCHEDULED],
            async () => {
                debounceSchedulePickupForSubscription(action)
            },
        )

        return () => {
            subscription.destroy()
        }
    }, [debounceSchedulePickupForSubscription, list?.subscribe, action])

    return { list: result, new: newResult }
}

export default useSchedulePickup
