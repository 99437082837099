import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'
import PrimaryButtonStyled from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import SecondaryButtonStyled from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { Link as RouterLink } from 'react-router-dom'

interface AuthFormProps{
    ref: React.RefObject<HTMLDivElement>
    children: JSX.Element | JSX.Element[]
}

export const Typography = styled(MuiTypography)(MuiSpacing)

export const Welcome = styled(Typography)`
    font-family: 'Termina';
    color: ${(props): string => props.theme.palette.black.main};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: center;
    padding: ${(props) => props.theme.spacing(6)}px 0 0;

    & span{
        color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }
`

export const AuthForm: React.FC<AuthFormProps> = styled.div<AuthFormProps>`
    width: 100%;
    max-width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;

    &.opacity1{
        opacity: 1;
    }

    & > div{
        margin: ${(props) => props.theme.spacing(4)}px 0;
    }

    & div:not([class^="MuiInputAdornment-root"]){
        width: 100%;
    }
`

export const PrimaryButton = styled(styled(PrimaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const SecondaryButton = styled(styled(SecondaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const Link = styled(RouterLink)`
   text-decoration: none;
   text-align: left;
   color: ${(props): string => props.theme.palette.neutral600.main};
   margin-top: ${(props) => props.theme.spacing(6)}px;
`

export const Message = styled.div`
    width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 12px;
`
