import * as types from '../constants'
import { Action } from '../constants'
import { Address } from '../../../model'

export const setDefaultBillingAddresses = (value: Address[] | null): Action => ({
    type: types.SET_DEFAULT_BILLING_ADDRESSES,
    payload: value,
})

export const setDefaultShippingAddresses = (value: Address[] | null): Action => ({
    type: types.SET_DEFAULT_SHIPPING_ADDRESSES,
    payload: value,
})
