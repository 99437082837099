import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Grid as MuiGrid, Menu as MuiMenu, Typography as MuiTypography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TitleTypography = styled(MuiTypography)`
    font-weight: 500;
    font-size: 1.5rem;
    color: ${(props): number => props.theme.palette.black.main};
`

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Wrapper = styled.div`
    position: sticky;
    bottom: 0;
    display:flex;
    justify-content: flex-end;
    height: 0;
`

export const Ninja = styled.div`
    height: 48px;
    width: 48px;
    margin-right: ${(props): number => props.theme.spacing(3)}px;
    margin-bottom: ${(props): number => props.theme.spacing(3)}px;
    border-radius: 50%;
    background-color: ${(props): number => props.theme.palette.black.main};
    border: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s ease-out;
    z-index: 99999;
    transform: translateY(-60px);

    div {
        height: 32px;
        overflow: hidden;
        transition: height 0.3s ease-out;

        img {
            position: relative;
            top: ${(props): number => props.theme.spacing(3)}px;
            left: 0;
            transition: top 0.3s ease-out;
        }
    }

    &:hover, &.is-open {
        background-color: ${(props): number => props.theme.palette.accentSecondary200.main};

        div {
            height: 48px;

            img {
                top: ${(props): number => props.theme.spacing(1)}px;
            }
        }
    }
`

export const SupportMenu = styled(MuiMenu)`
    z-index: 2147483645;
    transform: translateY(-10px);

    & [class^='MuiPaper-root'] {
        width: 290px;
    }
`

export const Divider = styled.div`
    height: 1px;
    background-color: ${(props): number => props.theme.palette.divider};
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: ${(props): number => props.theme.palette.black.main};

    &:hover {
        color: ${(props): number => props.theme.palette.primary.main};
    }
`

export const PhoneTypography = styled(MuiTypography)`
    text-decoration: none;
    font-weight: 500;
    color: ${(props): number => props.theme.palette.black.main};
`

export const EmailLink = styled.a`
    text-decoration: none;
`

export const EmailTypography = styled(MuiTypography)`
    text-decoration: none;
    font-weight: 500;
    color: ${(props): number => props.theme.palette.black.main};
`

export const LinkTypography = styled(MuiTypography)`
    text-decoration: none;
    font-weight: 500;
    color: ${(props): number => props.theme.palette.black.main};
`

export const GridIconItem = styled(Grid)`
    width: 40px;
`

export const MenuIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.palette.black.main};
    margin-right: ${(props): number => props.theme.spacing(4)}px;
    font-size: 1.25rem;
`
