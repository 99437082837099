import {
    AccessorialLocationType,
    AccessorialType,
    AddressType,
    DensityUnit,
    DimensionUnit,
    EquipmentType,
    PackageType,
    TransportType,
    USMCA,
    VolumeUnit,
    WeightUnit,
    UuidV4,
    Currency as CurrencyCode,
    ShipmentDocumentType,
    PaymentTypeOption,
    Country as CountryCode
} from '@lazr/openapi-client'
import {
    BillDutiesToPartyType,
    Country,
    Currency,
    CoverageCommodityType,
    HazmatClassType,
    HazmatModeType,
    ImportExportType,
    B13aFilingOption,
    ExportComplianceStatement,
    IncotermsType,
} from './index'
import { CurrencyProps } from './Currency'
import { Collect, Search } from './Search'
import { isMailingService, isInternationalOrder } from '@lazr/utilities'
import { BatteryMaterial, BatteryPacking, HazmatAccessibiltyType, LabelSizeType } from '@lazr/enums'
import { OrderStatus } from '@lazr/openapi-client'
import { RfqStatus } from '@lazr/openapi-client'

export interface PreparedSearchProps {
    billingCurrency: CurrencyProps
    clientNumber: string | null
    threePlNumber: string | null
    id: UuidV4
    rfq: PreparedSearchRfq
    collectData: Collect | null
    status: OrderStatus | null
}

export interface PreparedSearchRfq {
    rfqAccessorials: PreparedSearchRfqAccessorial[]
    handlingUnits: PreparedSearchRfqHandlingUnit[]
    createdAt: string
    updatedAt: string
    shipmentCoverageValue: number | null
    coverageCommodityType: CoverageCommodityType | null
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean
    customsBroker: string | null
    destinationPoNumber: string | null
    destinationDeliveryInstructions: string | null
    destinationDeliveryCloseTime: string | null
    destinationDeliveryOpenTime: string | null
    destinationNotifyReceiver: boolean | null
    destinationCompanyName: string | null
    destinationContactPhoneExtension: string | null
    destinationContactPhone: string | null
    destinationContactEmails: string[]
    destinationContactName: string | null
    destinationName: string | null
    destinationDeliveryDate: string | null
    destinationPostalCode: string | null
    destinationAddressType: AddressType | null
    destinationCountry: Country | null
    destinationState: string | null
    destinationCity: string | null
    destinationStreetAddress2: string | null
    destinationStreetAddress3: string | null
    destinationStreetAddress: string | null
    originPoNumber: string | null
    originPickupInstructions: string | null
    originPickupCloseTime: string | null
    originPickupOpenTime: string | null
    originNotifyShipper: boolean | null
    originCompanyName: string | null
    originContactPhoneExtension: string | null
    originContactPhone: string | null
    originContactEmails: string[]
    originContactName: string | null
    originName: string | null
    originPickupDate: string | null
    originPostalCode: string | null
    originAddressType: AddressType | null
    originCountry: Country | null
    originState: string | null
    originCity: string | null
    originStreetAddress2: string | null
    originStreetAddress3: string | null
    originStreetAddress: string | null
    equipmentType: EquipmentType | null
    transportType: TransportType
    customQuoteRequested: boolean
    billDutiesToParty: BillDutiesToPartyType | null
    commercialInvoiceDocumentIndicator: boolean | null
    commercialInvoiceUserProvided: boolean | null
    documentsOnlyIndicator: boolean | null
    customsTotalValue: number | null
    dutyBillToCity: string | null
    dutyBillToContactEmail: string | null
    dutyBillToCompanyName: string | null
    dutyBillToContactName: string | null
    dutyBillToContactPhone: string | null
    dutyBillToContactPhoneExt: string | null
    dutyBillToCountry: Country | null
    dutyBillToName: string | null
    dutyBillToPostalCode: string | null
    dutyBillToState: string | null
    dutyBillToStreetAddress: string | null
    dutyBillToStreetAddress2: string | null
    dutyBillToStreetAddress3: string | null
    dutyBillToAccountNumber: string | null
    dutyBusinessRelated: boolean | null
    dutyCurrency: Currency | null
    hazmatClass: HazmatClassType | null
    hazmatDeclarationDocumentIndicator: boolean | null
    hazmatMode: HazmatModeType | null
    importExport: ImportExportType | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
    b13aFilingOption: B13aFilingOption | null
    exportComplianceStatement: ExportComplianceStatement | null
    customsBrokerCity: string | null
    customsBrokerEmail: string | null
    customsBrokerContactName: string | null
    customsBrokerContactPhone: string | null
    customsBrokerContactPhoneExt: string | null
    customsBrokerCountry: Country | null
    customsBrokerPostalCode: string | null
    customsBrokerState: string | null
    customsBrokerStreetAddress: string | null
    customsBrokerStreetAddress2: string | null
    customsBrokerStreetAddress3: string | null
    customsBrokerAccountNumber: string | null
    taxIdentificationNumber: string | null
    incotermsType: IncotermsType | null
    etdEnabled: boolean | null
    id: UuidV4
    customsDetails: PreparedSearchRfqCustomsDetail[]
    shipmentDocumentType: ShipmentDocumentType | null
    paymentType: PaymentTypeOption | null
    holdForPickup: PreparedSearchHoldForPickup | null
    status: RfqStatus | null
}

interface PreparedSearchHoldForPickup {
    carrierId: UuidV4 | null
    address: PreparedSearchHoldForPickupAddress | null
}

interface PreparedSearchHoldForPickupAddress {
    companyName: string
    phone: string
    phoneExtension: string | null
    streetAddress: string
    streetAddress2: string | null
    streetAddress3: string | null
    city: string
    state: string
    country: CountryCode
    postalCode: string
}
export interface PreparedSearchRfqAccessorial {
    isRemovable: boolean | null
    accessorial: PreparedSearchAccessorial
    id: UuidV4
}

export interface PreparedSearchRfqHandlingUnitAccessorial {
    isRemovable: boolean | null
    accessorial: PreparedSearchAccessorial
    id: UuidV4
}

export interface PreparedSearchRfqHandlingUnit {
    handlingUnitAccessorials: PreparedSearchRfqHandlingUnitAccessorial[]
    description: string | null
    poNumber: string | null
    packageType: PackageType | null
    nmfcSub: string | null
    nmfc: string | null
    class: string | null
    densityUnit: DensityUnit | null
    density: number | null
    totalVolumeUnit: VolumeUnit | null
    totalVolume: number | null
    totalWeightUnit: WeightUnit | null
    totalWeight: number | null
    weightUnit: WeightUnit | null
    weight: number | null
    heightUnit: DimensionUnit | null
    height: number | null
    widthUnit: DimensionUnit | null
    width: number | null
    lengthUnit: DimensionUnit | null
    length: number | null
    pieces: number | null
    quantity: number | null
    rfqId: UuidV4
    id: UuidV4
    hazmatAccessibiltyType: HazmatAccessibiltyType | null
    dryIce: {
        unit: WeightUnit
        value: number
    } | null
    battery: {
        material: BatteryMaterial
        packing: BatteryPacking
    } | null
    freightHazmatUn: string | null,
    freightHazmatScientificName: string | null,
    freightHazmatPackagingGroup: string | null,
    freightHazmatEmergencyContactNumber: string | null,
    freightHazmatClass: string | null,
}

export interface PreparedSearchRfqCustomsDetail {
    id: UuidV4
    description: string | null
    harmonizedCode: string | null
    squareMeters: number | null
    countryOfManufacture: Country | null
    productCode: string | null
    unitValue: number | null
    unitCurrency: CurrencyCode | null
    quantity: number | null
    productIndicatorIsUsmca: boolean | null
    productIndicatorFda: boolean | null
    productIndicatorTextile: boolean | null
    documentIndicatorUsmca: boolean | null
    documentIndicatorFda: boolean | null
    documentIndicatorFcc: boolean | null
    senderIsProducerIndicator: boolean | null
    textileManufacturer: string | null
    unitWeight: number | null
    weightUnit: WeightUnit | null
    pieces: number | null
    productIndicatorUsmcaType: USMCA | null
    handlingUnitIds: UuidV4[]
}

export interface PreparedSearchAccessorial {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
}

export class PreparedSearch {
    status: OrderStatus | null
    billingCurrency: Currency | null
    clientNumber: string | null
    id: UuidV4
    rfq: PreparedSearchRfq
    threePlNumber: string | null
    collectData: Collect | null
  

    constructor (props: PreparedSearchProps) {
        this.clientNumber = props.clientNumber
        this.id = props.id
        this.rfq = props.rfq
        this.threePlNumber = props.threePlNumber
        this.collectData = props.collectData
        this.status = props.status

        // Rich objects
        this.billingCurrency =  new Currency(props.billingCurrency)
        if (this.rfq.originCountry) {
            this.rfq.originCountry = props.rfq.originCountry && new Country(props.rfq.originCountry)
        }
        if (this.rfq.destinationCountry) {
            this.rfq.destinationCountry = props.rfq.destinationCountry && new Country(props.rfq.destinationCountry)
        }
    }

    toSearch (): Partial<Search> {
        return {
            id: this.id,
            billingCurrency: this.billingCurrency,
            clientNumber: this.clientNumber || undefined,
            threePlNumber: this.threePlNumber || undefined,
            status: this.status || undefined,
            rfq: {
                status: this.rfq.status || undefined,
                accessorials: this.rfq.rfqAccessorials.map((accessorial) => ({
                    accessorial: accessorial.accessorial,
                    id: accessorial.id,
                    isRemovable: accessorial.isRemovable ?? true,
                })),
                handlingUnits: this.rfq.handlingUnits.map((handlingUnit) => ({
                    accessorials: handlingUnit.handlingUnitAccessorials.map((accessorial) => ({
                        accessorial: accessorial.accessorial,
                        id: accessorial.id,
                        isRemovable: accessorial.isRemovable ?? true,
                    })),
                    description: handlingUnit.description,
                    poNumber: handlingUnit.poNumber,
                    packageType: handlingUnit.packageType,
                    nmfcSub: handlingUnit.nmfcSub,
                    nmfc: handlingUnit.nmfc,
                    class: handlingUnit.class,
                    densityUnit: handlingUnit.densityUnit || DensityUnit.LB_FT3,
                    density: handlingUnit.density,
                    totalVolumeUnit: handlingUnit.totalVolumeUnit || VolumeUnit.FT3,
                    totalVolume: handlingUnit.totalVolume,
                    totalWeightUnit: handlingUnit.totalWeightUnit || (handlingUnit.weightUnit || WeightUnit.LB),
                    totalWeight: handlingUnit.totalWeight,
                    weightUnit: handlingUnit.weightUnit || WeightUnit.LB,
                    weight: handlingUnit.weight,
                    heightUnit: handlingUnit.heightUnit || DimensionUnit.IN,
                    height: handlingUnit.height,
                    widthUnit: handlingUnit.widthUnit || DimensionUnit.IN,
                    width: handlingUnit.width,
                    lengthUnit: handlingUnit.lengthUnit || DimensionUnit.IN,
                    length: handlingUnit.length,
                    pieces: handlingUnit.pieces,
                    quantity: handlingUnit.quantity,
                    rfqId: handlingUnit.rfqId,
                    id: handlingUnit.id,
                    hazmatAccessibiltyType: handlingUnit.hazmatAccessibiltyType,
                    dryIce: handlingUnit.dryIce,
                    battery: handlingUnit.battery,
                    freightHazmatUn: handlingUnit.freightHazmatUn,
                    freightHazmatScientificName: handlingUnit.freightHazmatScientificName,
                    freightHazmatPackagingGroup: handlingUnit.freightHazmatPackagingGroup,
                    freightHazmatEmergencyContactNumber: handlingUnit.freightHazmatEmergencyContactNumber,
                    freightHazmatClass: handlingUnit.freightHazmatClass,
                })),
                quotes: [],
                shipmentCoverageValue: this.rfq.shipmentCoverageValue,
                coverageCommodityType: this.rfq.coverageCommodityType,
                isBookedWithCoverage: this.rfq.isBookedWithCoverage,
                isCoverageExclusionsAccepted: this.rfq.isCoverageExclusionsAccepted,
                customsBroker: this.rfq.customsBroker,
                destinationPoNumber: this.rfq.destinationPoNumber,
                destinationDeliveryInstructions: this.rfq.destinationDeliveryInstructions,
                destinationDeliveryCloseTime: this.rfq.destinationDeliveryCloseTime,
                destinationDeliveryOpenTime: this.rfq.destinationDeliveryOpenTime,
                destinationNotifyReceiver: this.rfq.destinationNotifyReceiver,
                destinationCompanyName: this.rfq.destinationCompanyName,
                destinationContactPhoneExtension: this.rfq.destinationContactPhoneExtension,
                destinationContactPhone: this.rfq.destinationContactPhone,
                destinationContactEmails: this.rfq.destinationContactEmails,
                destinationContactName: this.rfq.destinationContactName,
                destinationName: this.rfq.destinationName,
                destinationDeliveryDate: this.rfq.destinationDeliveryDate,
                destinationPostalCode: this.rfq.destinationPostalCode,
                destinationAddressType: this.rfq.destinationAddressType,
                destinationCountry: this.rfq.destinationCountry,
                destinationState: this.rfq.destinationState,
                destinationCity: this.rfq.destinationCity,
                destinationStreetAddress3: this.rfq.destinationStreetAddress3,
                destinationStreetAddress2: this.rfq.destinationStreetAddress2,
                destinationStreetAddress: this.rfq.destinationStreetAddress,
                originPoNumber: this.rfq.originPoNumber,
                originPickupInstructions: this.rfq.originPickupInstructions,
                originPickupCloseTime: this.rfq.originPickupCloseTime,
                originPickupOpenTime: this.rfq.originPickupOpenTime,
                originNotifyShipper: this.rfq.originNotifyShipper,
                originCompanyName: this.rfq.originCompanyName,
                originContactPhoneExtension: this.rfq.originContactPhoneExtension,
                originContactPhone: this.rfq.originContactPhone,
                originContactEmails: this.rfq.originContactEmails,
                originContactName: this.rfq.originContactName,
                originName: this.rfq.originName,
                originPickupDate: this.rfq.originPickupDate,
                originPostalCode: this.rfq.originPostalCode,
                originAddressType: this.rfq.originAddressType,
                originCountry: this.rfq.originCountry,
                originState: this.rfq.originState,
                originCity: this.rfq.originCity,
                originStreetAddress3: this.rfq.originStreetAddress3,
                originStreetAddress2: this.rfq.originStreetAddress2,
                originStreetAddress: this.rfq.originStreetAddress,
                equipmentType: this.rfq.equipmentType,
                transportType: this.rfq.transportType,
                customQuoteRequested: this.rfq.customQuoteRequested,
                billDutiesToParty: this.rfq.billDutiesToParty,
                commercialInvoiceDocumentIndicator: this.rfq.commercialInvoiceDocumentIndicator,
                commercialInvoiceUserProvided: this.rfq.commercialInvoiceUserProvided,
                documentsOnlyIndicator: (isMailingService(this.rfq.transportType) &&
                    isInternationalOrder(this.rfq.originCountry?.code, this.rfq.destinationCountry?.code)) ?
                    this.rfq.documentsOnlyIndicator : false,
                customsTotalValue: this.rfq.customsTotalValue,
                dutyBillToCity: this.rfq.dutyBillToCity,
                dutyBillToContactEmail: this.rfq.dutyBillToContactEmail,
                dutyBillToCompanyName: this.rfq.dutyBillToCompanyName,
                dutyBillToContactName: this.rfq.dutyBillToContactName,
                dutyBillToContactPhone: this.rfq.dutyBillToContactPhone,
                dutyBillToContactPhoneExt: this.rfq.dutyBillToContactPhoneExt,
                dutyBillToCountry: this.rfq.dutyBillToCountry,
                dutyBillToName: this.rfq.dutyBillToName,
                dutyBillToPostalCode: this.rfq.dutyBillToPostalCode,
                dutyBillToState: this.rfq.dutyBillToState,
                dutyBillToStreetAddress: this.rfq.dutyBillToStreetAddress,
                dutyBillToStreetAddress2: this.rfq.dutyBillToStreetAddress2,
                dutyBillToAccountNumber: this.rfq.dutyBillToAccountNumber,
                dutyBusinessRelated: this.rfq.dutyBusinessRelated,
                dutyCurrency: this.rfq.dutyCurrency,
                hazmatClass: this.rfq.hazmatClass,
                hazmatDeclarationDocumentIndicator: this.rfq.hazmatDeclarationDocumentIndicator,
                hazmatMode: this.rfq.hazmatMode,
                importExport: this.rfq.importExport,
                useThermalPrinter: this.rfq.useThermalPrinter,
                labelSizeType: this.rfq.labelSizeType,
                b13aFilingOption: this.rfq.b13aFilingOption,
                exportComplianceStatement: this.rfq.exportComplianceStatement,
                customsBrokerCity: this.rfq.customsBrokerCity,
                customsBrokerEmail: this.rfq.customsBrokerEmail,
                customsBrokerContactName: this.rfq.customsBrokerContactName,
                customsBrokerContactPhone: this.rfq.customsBrokerContactPhone,
                customsBrokerContactPhoneExt: this.rfq.customsBrokerContactPhoneExt,
                customsBrokerCountry: this.rfq.customsBrokerCountry,
                customsBrokerPostalCode: this.rfq.customsBrokerPostalCode,
                customsBrokerState: this.rfq.customsBrokerState,
                customsBrokerStreetAddress: this.rfq.customsBrokerStreetAddress,
                customsBrokerStreetAddress2: this.rfq.customsBrokerStreetAddress2,
                customsBrokerStreetAddress3: this.rfq.customsBrokerStreetAddress3,
                customsBrokerAccountNumber: this.rfq.customsBrokerAccountNumber,
                taxIdentificationNumber: this.rfq.taxIdentificationNumber,
                incotermsType: this.rfq.incotermsType,
                createdAt: this.rfq.createdAt,
                updatedAt: this.rfq.updatedAt,
                id: this.rfq.id,
                etdEnabled: this.rfq.etdEnabled,
                customsDetails: this.rfq.customsDetails,
                paymentType: this.rfq.paymentType || undefined,
                holdForPickup: {
                    carrierId: this.rfq.holdForPickup?.carrierId ?? null,
                    address: this.rfq.holdForPickup?.address ?? null,
                }
            },
            collectData: this.collectData,
        }
    }
}
