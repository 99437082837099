import {
    UuidV4,
    EventType,
    EventStatus,
    EntityType,
} from '@lazr/openapi-client'

export interface EventProps {
    id: UuidV4
    type: EventType
    status: EventStatus
    entityId: UuidV4 | null
    entityType: EntityType
    byUserId: UuidV4 | null
    createdAt: string
    data: Record<string, any> & {
        refs?: Record<string, UuidV4 | UuidV4[]>
    }
}

export class Event {
    public id: UuidV4
    public type: EventType
    public status: EventStatus
    public entityId: UuidV4 | null
    public entityType: EntityType
    public byUserId: UuidV4 | null
    public createdAt: string
    data: Record<string, any> & {
        refs?: Record<string, UuidV4 | UuidV4[]>
    }

    constructor (props: EventProps) {
        this.id = props.id
        this.type = props.type
        this.status = props.status
        this.entityId = props.entityId
        this.entityType = props.entityType
        this.byUserId = props.byUserId
        this.createdAt = props.createdAt
        this.data = props.data
    }
}
