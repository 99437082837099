import React from 'react'
import MuiSlide, { SlideProps } from '@material-ui/core/Slide'

export const SlideFromTop = React.forwardRef(function Transition(
    props: SlideProps,   //{ children?: React.ReactElement<any, any>, direction: any },
    ref: React.Ref<unknown>,
) {
    return <MuiSlide
    {...props}
    ref={ref}
    direction='down'
/>
})

export const SlideFromRight = React.forwardRef(function Transition(
    props: SlideProps,   //{ children?: React.ReactElement<any, any>, direction: any },
    ref: React.Ref<unknown>,
) {
    return <MuiSlide
    {...props}
    ref={ref}
    direction='left'
/>
})

export const SlideFromBottom = React.forwardRef(function Transition(
    props: SlideProps,   //{ children?: React.ReactElement<any, any>, direction: any },
    ref: React.Ref<unknown>,
) {
    return <MuiSlide
    {...props}
    ref={ref}
    direction='up'
/>
})

export const SlideFromLeft = React.forwardRef(function Transition(
    props: SlideProps,   //{ children?: React.ReactElement<any, any>, direction: any },
    ref: React.Ref<unknown>,
) {
    return <MuiSlide
    {...props}
    ref={ref}
    direction='right'
/>
})
