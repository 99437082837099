/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import PackageTypeTranslations from '@/app/ui-new/i18n/PackageTypeAndDescription.i18n.fr'
import TransportType from '@/app/ui-new/i18n/TransportType.i18n.fr'
import WeightUnit from '@/app/ui-new/i18n/WeightUnit.i18n.fr'
import BillDutiesToParty from '@/app/ui-new/i18n/BillDutiesToParty.i18n.fr'
import FedexLocation from '@/app/ui-new/i18n/FedexLocation.i18n.fr'

import type en from './useEnumSelectData.i18n.en'
import type { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ...PackageTypeTranslations,
    ...TransportType,
    ...WeightUnit,
    ...BillDutiesToParty,
    ...FedexLocation
}))()
