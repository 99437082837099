import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Paper, PaperProps, Typography, TypographyProps } from '@material-ui/core'
import { ImageContainerProps } from '@/app/ui-new/pages/website/components/Carousel/Mobile/StepCardMobile'

export const StyledPaper: React.FC<PaperProps> = styled(Paper)<PaperProps>`
    height: 570px;
    width: 100%;
    border-radius: 8px;
    padding: ${(props): number => props.theme.spacing(4)}px;
`
export const StyledStepCard: React.FC<BoxProps> = styled(Box)<BoxProps>`
    overflow: hidden;
    height: 100%;
    width: 100%;

`
export const StyledDisclaimerTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    color: ${(props): number => props.theme.palette.text.disabled};
`

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    font-size: 1.75rem;
`

export const StyledImageContainer: React.FC<ImageContainerProps> = styled(Box)<ImageContainerProps>`
    width: 100%;
    height: 262px;
    background-image: url(${(props): string => props.imgSrc});
    background-size: cover;
    border-radius: 8px;

`

