import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logger } from '../../../logger'
import { CurrencyApiService } from '../../../service/ApiService'
import { setCurrencyList as reduxSetCurrencyList } from '../../redux/actions/CurrencyListActions'
import { getCurrencyList as reduxGetCurrencyList } from '../../redux/selectors/AppStateSelectors'

export const useCurrencyList = () => {
    const dispatch = useDispatch()

    const { currencyList, isCurrencyListFetched } = useSelector(reduxGetCurrencyList)

    React.useEffect(() => {
        void (async (): Promise<void> => {
            try {
                if (isCurrencyListFetched) {
                    return
                }
                const currencyListResponse = await CurrencyApiService.list()
                dispatch(reduxSetCurrencyList(currencyListResponse))
            } catch (error: any) {
                logger.error('could not fetch currency list', error)
            }
        })()
    }, [])

    return [ currencyList ]
}
