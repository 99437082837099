/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './FlipCardsDesktop.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`A freight management system that works for everyone`]: `Un système de gestion qui fonctionne pour tous`,
    [`Lazr advantages card`]: `Lazr advantages card`,
    [`Procurement manager`]: `Responsable des achats`,
    [`Access the largest logistic network for LTL & parcel shipping`]: `Accédez au plus grand réseau logistique pour le transport de lots et de colis`,
    [`Icon`]: `Icon`,
    [`Get instant pricing from 50+ trusted carriers`]: `Obtenir des prix instantanés auprès de plus de 50 transporteurs de confiance`,
    [`Add your own carrier contracts to compare shipping rates`]: `Ajoutez vos propres contrats de transport pour comparer les tarifs d'expédition`,
    [`Autofill forms & automate billing`]: `Remplir automatiquement les formulaires et automatiser la facturation`,
    [`Get Lazr for free`]: `Obtenez Lazr gratuitement`,
    [`I'm a`]: `Je suis`,
    [`Warehouse Manager`]: `Directeur d'entrepôt`,
    [`Easily track all shipments in one dashboard`]: `Suivre facilement tous les envois dans un seul tableau de bord`,
    [`Unified dashboard with precise tracking & visibility`]: `Tableau de bord unifié avec un suivi et une visibilité précis`,
    [`EDI capabilities for efficient supply chain communications`]: `Capacités EDI pour une communication efficace de la chaîne d'approvisionnement`,
    [`Enhance existing workflows with our API integrations`]: `Améliorer les flux de travail existants grâce à nos intégrations API`,
    [`Head of Logistics`]: `Responsable de la logistique`,
    [`Diversify carriers & cut shipping costs`]: `Diversifier les transporteurs et réduire les coûts d'expédition`,
    [`Cut shipping costs by up to 21%`]: `Réduire les frais d'expédition jusqu'à 21%`,
    [`Choose from 50+ trusted carriers on the largest LTL & parcel shipping network`]: `Choisissez parmi plus de 50 transporteurs de confiance sur le plus grand réseau d'expédition de colis et de LTL`,
    [`I'm an`]: `Je suis`,
    [`Executive`]: `Cadre`,
    [`Modernize your operations & grow faster`]: `Modernisez vos opérations et accélérez votre croissance`,
    [`Easy-to-use, collaborative platform for both growing & established companies`]: `Plateforme collaborative facile à utiliser pour les entreprises en croissance et les entreprises établies`,
    [`Enhance legacy systems with our API integrations`]: `Améliorez vos systèmes existants grâce à nos intégrations API`,
    [`Improve shipping operations from start to finish`]: `Améliorer les opérations d'expédition du début à la fin`,
}))()
