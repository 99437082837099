import styled from 'styled-components'

import {
    spacing as MuiSpacing,
} from '@material-ui/system'
import {
    Typography as MuiTypography,
    Button as MuiButton,
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'

import OriginalGenericAlert from '../components/GenericAlert'
import { darken } from 'polished'
import { BUTTON_HOVER_DARKEN_AMOUNT } from '../lib/constants'
import { Link as MuiLink } from 'react-router-dom'

export const drawerWidth = 60

export const Drawer = styled.div`
  ${(props): string => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`

export const Link = styled(MuiLink)(MuiSpacing)

export const StyledLink = styled(Link)`
    text-decoration: none;
`

export const Button = styled(MuiButton)(MuiSpacing)

export const LinkButton = styled(Button)`
    border-radius: 6px;
    padding: ${(props): string => props.theme.spacing(2)}px ${(props): string => props.theme.spacing(4)}px;

    background-color: ${(props): number => props.theme.palette.background.paper};
    color: ${(props): number => props.theme.palette.text.primary};
    white-space: nowrap;

    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.background.paper)};
    }
`

export const VersionSnackbarButton = styled(Button)`
    border-color: ${(props): number => props.theme.palette.info.contrastText};
    color: ${(props): number => props.theme.palette.info.contrastText};

    margin-left: ${(props): string => props.theme.spacing(4)}px;
`

export const VersionSnackbarTypography = styled(MuiTypography)`
    position: relative;
    top: 2px;
`

export const Typography = styled(MuiTypography)(MuiSpacing)

export const CookieSnackbarButton = styled(styled(MuiButton)(MuiSpacing))`
    color: ${(props): number => props.theme.palette.background.paper};
`

export const StyledAlert = styled(styled(MuiAlert)(MuiSpacing))`
    &.MuiAlert-filledSuccess {
        color: ${(props): number => props.theme.palette.success.contrastText};
    }
    & .MuiAlert-icon {
        align-items: center;
    }
    &.top {
        margin-bottom: ${(props): number => props.theme.spacing(4)}px;
    }
    &.top:first-child {
        margin-top: ${(props): number => props.theme.spacing(-6)}px;
    }
`

export const Strong = styled.strong`
    text-decoration: underline;
`

export const GenericAlert = styled(OriginalGenericAlert)`
    margin-top: ${(props): number => props.theme.spacing(-6)}px;
    margin-bottom: ${(props): number => props.theme.spacing(4)}px;
`
