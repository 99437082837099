/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaxType {
    GST = 'GST',
    HST_NB = 'HST_NB',
    HST_NL = 'HST_NL',
    HST_NS = 'HST_NS',
    HST_ON = 'HST_ON',
    HST_PE = 'HST_PE',
    NO_TAX = 'NO_TAX',
    QST_GST = 'QST_GST',
}