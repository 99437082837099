import React from 'react'
import {
    TextFieldIconGreenCheck,
    TextFieldIconRedX,
} from './TextFieldIcons'


export const PasswordMatchIndicator: React.FC<PasswordMatchIndicatorProps> = (props) => {
    if (props.showPasswordMatch) {
        return props.passwordMatch ?
            <TextFieldIconGreenCheck /> : <TextFieldIconRedX />
    }

    return <></>
}
export interface PasswordMatchIndicatorProps {
    passwordMatch: boolean
    showPasswordMatch: boolean
}
