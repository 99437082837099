import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialCurrencyRates } from '../store/InitialState'
import { CurrencyRates } from '../../definitions/CurrencyRates'
import { Currency } from '@lazr/openapi-client'

const reducer: Reducer = (previousState: CurrencyRates = initialCurrencyRates, action: AnyAction) => {
    switch (action.type) {
        case types.SET_CURRENCY_RATE: {
            const source = action.payload.source as Currency
            const target = action.payload.target as Currency
            previousState[source][target] = action.payload.lazrRate

            return previousState
        }
        default:
            return previousState
    }
}

export default reducer
