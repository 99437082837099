import { Country, Currency as CurrencyCode } from '@lazr/openapi-client'

export interface CurrencyProps {
    code: CurrencyCode
    name: string
    country: Country
}

export class Currency {
    public code: CurrencyCode
    public name: string
    public country: Country

    constructor (props: CurrencyProps) {
        this.code = props.code
        this.name = props.name
        this.country = props.country
    }
}
