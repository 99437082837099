import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core'

import { FontAwesomeIcon as MuiFontAwesomeIcon } from '@fortawesome/react-fontawesome'

const handleSize = (className: string | undefined) => {
    const xsmall = className?.split(' ').includes('xsmall')
    const small = className?.split(' ').includes('small')
    const large = className?.split(' ').includes('large')

    return xsmall ? '18px' : small ? '32px' :
        large ? '56px' : '40px'
}

const handleIconSize = (className: string | undefined) => {
    const xsmall = className?.split(' ').includes('xsmall')
    const small = className?.split(' ').includes('small')
    const large = className?.split(' ').includes('large')
    const size = xsmall ? '10px' : small ? '32px' : large ? '56px' : '40px'
    return `width: ${size}; height: ${size};`
}

export const IconButton = styled(styled(MuiIconButton)(MuiSpacing))`

    height: ${(props): string => handleSize(props.className)};
    width: ${(props): string => handleSize(props.className)};
    border-radius: 50%;
    padding: 0 ${(props): string => props.theme.spacing(2)}px;
    background: ${(props): number => props.theme.palette.white.main};
    border: 1px solid ${(props): number => props.theme.palette.neutral400.main};
    transition: border-color 0.1s ease-out, background 0.1s ease-out;

    &:not(.preserveiconcolors):hover {
        background: ${(props): number => props.theme.palette.accentPrimary50.main};
        border-color: ${(props): number => props.theme.palette.accentPrimary800.main};

        svg path {
            fill: ${(props): number => props.theme.palette.accentPrimary800.main};
        }
    }

    &.preserveiconcolors:hover {
        background: ${(props): number => props.theme.palette.accentPrimary50.main};
        border-color: ${(props): number => props.theme.palette.accentPrimary800.main};
    }

    &:focus {
        outline: solid 2px ${(props): number => props.theme.palette.accentPrimary400.main};
        outline-offset: 3px;
        border-color: ${(props): number => props.theme.palette.accentPrimary800.main};
    }
`

export type IconButtonProps = MuiIconButtonProps

export const FontAwesomeIcon = styled(styled(MuiFontAwesomeIcon)(MuiSpacing))`
    ${(props):string => handleIconSize(props.className)}
`
