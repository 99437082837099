/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`The ultimate logistics solution`]: `The ultimate logistics solution`,
    [`Streamline your entire shipping process with one elegant platform. You’ll get help from Lazr at every step.`]: `Streamline your entire shipping process with one elegant platform. You’ll get help from Lazr at every step.`,
    [`Get Lazr for free`]: `Get Lazr for free`,
    [`See your best shipping options`]: `See your best shipping options`,
    [`Get instant prices and discover new shipping services & partners to save an average of 21% in costs*. Lazr has volume-based contracts with over 50 trusted carriers in less than truckload (LTL), parcel shipping and courier services. Users can also add their own contracts to instantly see and compare their best options.`]: `Get instant prices and discover new shipping services & partners to save an average of 21% in costs*. Lazr has volume-based contracts with over 50 trusted carriers in less than truckload (LTL), parcel shipping and courier services. Users can also add their own contracts to instantly see and compare their best options.`,
    [`Average savings for Lazr users`]: `Average savings for Lazr users`,
    [`Breeze through pickups`]: `Breeze through pickups`,
    [`Save time with autofilled BOL forms, parcel labels and ready-to-go shipping documents. We’ll automatically generate the necessary documents for each unique shipment, facilitate document sharing, and securely archive any customs files in one convenient place.`]: `Save time with autofilled BOL forms, parcel labels and ready-to-go shipping documents. We’ll automatically generate the necessary documents for each unique shipment, facilitate document sharing, and securely archive any customs files in one convenient place.`,
    [`Real-time tracking`]: `Real-time tracking`,
    [`Easily track your shipments across multiple services and carriers simultaneously. Lazr’s built-in dashboard gives you enhanced visibility with a unified tracking view so you can find your shipments at a glance.`]: `Easily track your shipments across multiple services and carriers simultaneously. Lazr’s built-in dashboard gives you enhanced visibility with a unified tracking view so you can find your shipments at a glance.`,
    [`Seamless integrations & communications`]: `Seamless integrations & communications`,
    [`Our enterprise-grade platform is equipped with a full range of API & EDI capabilities so you can effortlessly optimize your workflow and stay connected to all your shipping partners.`]: `Our enterprise-grade platform is equipped with a full range of API & EDI capabilities so you can effortlessly optimize your workflow and stay connected to all your shipping partners.`,
    [`Move to your next shipment faster`]: `Move to your next shipment faster`,
    [`Instantly cross off your post-delivery checklist. After delivery is confirmed, Lazr automates the necessary billing steps, including auditing, reporting, payment and consolidated invoicing.`]: `Instantly cross off your post-delivery checklist. After delivery is confirmed, Lazr automates the necessary billing steps, including auditing, reporting, payment and consolidated invoicing.`,
    [`Keep improving with data`]: `Keep improving with data`,
    [`Make smarter decisions with every new shipment. The Lazr dashboard includes KPI analytics and deep operations data so you can make faster, stronger decisions, reduce costs, and grow your business.`]: `Make smarter decisions with every new shipment. The Lazr dashboard includes KPI analytics and deep operations data so you can make faster, stronger decisions, reduce costs, and grow your business.`,
    ['']: '',
}
