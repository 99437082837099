import { Currency,
    InvoiceStatus,
    UuidV4,
    InvoiceType }
    from '@lazr/openapi-client'
import { TransportType } from '@lazr/enums'


export interface StatementOfAccountInvoiceProps {
    id: UuidV4
    xeroId: UuidV4 | null
    quickbooksId: string | null
    orderId: UuidV4
    orderNumber: string
    organizationId: UuidV4
    consolidationId: UuidV4 | null
    externalNumber: string
    status: InvoiceStatus
    type: InvoiceType
    currency: Currency
    invoiceDate: string
    invoiceDueDate: string
    clientOrganizationName: string
    threePlOrganizationName: string
    invoiceTotalCad: string
    currentTotalCad: string
    transportType: TransportType
    thirtyDaysTotalCad: string | null
    sixtyDaysTotalCad: string
    ninetyPlusDaysTotalCad: string
    invoiceTotalUsd: string
    currentTotalUsd: string
    thirtyDaysTotalUsd: string | null
    sixtyDaysTotalUsd: string
    ninetyPlusDaysTotalUsd: string
}
export class StatementOfAccountInvoice {
    public id: UuidV4
    public xeroId: UuidV4 | null
    public quickbooksId: string | null
    public orderId: UuidV4
    public orderNumber: string
    public organizationId: UuidV4
    public consolidationId: UuidV4 | null
    public externalNumber: string
    public status: InvoiceStatus
    public type: InvoiceType
    public currency: Currency
    public invoiceDate: string
    public invoiceDueDate: string
    public clientOrganizationName: string
    public threePlOrganizationName: string
    public invoiceTotalCad: string
    public currentTotalCad: string
    public transportType: TransportType
    public thirtyDaysTotalCad: string | null
    public sixtyDaysTotalCad: string
    public ninetyPlusDaysTotalCad: string
    public invoiceTotalUsd: string
    public currentTotalUsd: string
    public thirtyDaysTotalUsd: string | null
    public sixtyDaysTotalUsd: string
    public ninetyPlusDaysTotalUsd: string

    constructor (props: StatementOfAccountInvoiceProps) {
        this.id = props.id
        this.xeroId = props.xeroId
        this.quickbooksId = props.quickbooksId
        this.orderId = props.orderId
        this.externalNumber = props.externalNumber
        this.orderNumber = props.orderNumber
        this.organizationId = props.organizationId
        this.consolidationId = props.consolidationId
        this.status = props.status
        this.type = props.type
        this.currency = props.currency
        this.invoiceDate = props.invoiceDate
        this.invoiceDueDate = props.invoiceDueDate
        this.clientOrganizationName = props.clientOrganizationName
        this.threePlOrganizationName = props.threePlOrganizationName
        this.invoiceTotalCad = props.invoiceTotalCad
        this.currentTotalCad = props.currentTotalCad
        this.transportType = props.transportType
        this.thirtyDaysTotalCad = props.thirtyDaysTotalCad
        this.sixtyDaysTotalCad = props.sixtyDaysTotalCad
        this.ninetyPlusDaysTotalCad = props.ninetyPlusDaysTotalCad
        this.invoiceTotalUsd = props.invoiceTotalUsd
        this.currentTotalUsd = props.currentTotalUsd
        this.thirtyDaysTotalUsd = props.thirtyDaysTotalUsd
        this.sixtyDaysTotalUsd = props.sixtyDaysTotalUsd
        this.ninetyPlusDaysTotalUsd = props.ninetyPlusDaysTotalUsd
    }
}
