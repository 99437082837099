import {
    ApiError,
    BillOfLadingService,
    BolOverrides,
    ErrorResponse,
    GenerateBolResponse,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const BolGeneratorApiService = Object.freeze({
    generateBOL: async (
        orderId: string,
        bolOverrides?: BolOverrides | undefined
    ): Promise<GenerateBolResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the BOL'

        try {
            const res = await BillOfLadingService.generateBol({
                orderId: orderId,
                bolOverrides: bolOverrides,
            })

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message === 'No shipments attached to the order provided.') {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
})
