import { UuidV4 } from '@lazr/openapi-client'
import { Lane } from '@/app/model/Lane'
import { UserRole } from '@lazr/enums'

export interface OrganizationPermissionList {
    organizationPermissions: OrganizationPermission[]
}

export interface OrganizationRoleList {
    organizationRoles: OrganizationRole[]
}

export interface OrganizationPermissionProps {
    id: UuidV4
    organizationRole: OrganizationRole
    performSearch: boolean
    viewCompanyTariffQuotes: boolean
    viewCompanyTariffQuotesPrice: boolean
    allowPrepaidPaymentTerms: boolean
    allowCollectPaymentTerms: boolean
    submitOrder: boolean
    viewAllCompanyOrders: boolean
    viewCompanyInvoices: boolean
    viewCompanyAddressBook: boolean
}
export interface OrganizationRoleProps {
    id: UuidV4
    organizationId: UuidV4
    role: UserRole
}
export class OrganizationRole {
    public id: UuidV4
    public organizationId: UuidV4
    public role: UserRole
    constructor (props: OrganizationRoleProps) {
        this.id = props.id
        this.organizationId = props.organizationId
        this.role = props.role
    }
}

export class OrganizationPermission {
    public id: UuidV4
    public organizationRole: OrganizationRole
    public performSearch: boolean
    public viewCompanyTariffQuotes: boolean
    public viewCompanyTariffQuotesPrice: boolean
    public allowPrepaidPaymentTerms: boolean
    public allowCollectPaymentTerms: boolean
    public submitOrder: boolean
    public viewAllCompanyOrders: boolean
    public viewCompanyInvoices: boolean
    public viewCompanyAddressBook: boolean

    constructor (props: OrganizationPermissionProps) {
        this.id = props.id
        this.organizationRole = props.organizationRole
        this.performSearch = props.performSearch
        this.viewCompanyTariffQuotes = props.viewCompanyTariffQuotes
        this.viewCompanyTariffQuotesPrice = props.viewCompanyTariffQuotesPrice
        this.allowPrepaidPaymentTerms = props.allowPrepaidPaymentTerms
        this.allowCollectPaymentTerms = props.allowCollectPaymentTerms
        this.submitOrder = props.submitOrder
        this.viewAllCompanyOrders = props.viewAllCompanyOrders
        this.viewCompanyInvoices = props.viewCompanyInvoices
        this.viewCompanyAddressBook = props.viewCompanyAddressBook
    }
}
