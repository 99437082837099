import React from 'react'
import {
    StyledMuiCheckbox,
    StyledMuiRadio,
    StyledLabel,
    StyledCheckIcon,
    StyledCheckIconContainer,
    StyledContainer,
    StyledCloseIcon,
    StyledCloseIconContainer,
    ExpandedContent,
    StyledTypographyH6,
    StyledTypographySubtitle1,
    StyledTypographyBody2,
    AbsoluteGrid, StyledUncheckedIcon,
    StyledTypographyButtonText,
} from './SelectionCard.styled'
import {
    CheckboxProps,
    RadioProps,
    Grid,
    useRadioGroup,
    createChainedFunction,
} from '@material-ui/core'
import TextButton from '../TextButtons/TextButton'

const getSelectionCardContainerClassName = (
    checked?: boolean,
    expanded?: boolean,
    error?: boolean,
    otherClassNames?: string,
) => {
    const classNames = [ 'lzr-selection-card-container' ]
    if (checked) {
        classNames.push('lzr-selection-card-container-checked')
    }
    if (expanded) {
        classNames.push('lzr-selection-card-container-expanded')
    }
    if (error) {
        classNames.push('lzr-selection-card-container-error')
    }
    if (otherClassNames) {
        classNames.push(otherClassNames)
    }

    return classNames.join(' ')
}

const getLabelClassName = (
    checked?: boolean,
    expanded?: boolean,
    error?: boolean,
) => {
    const classNames = [ 'lzr-selection-card' ]
    if (checked) {
        classNames.push('lzr-selection-card-checked')
    }
    if (expanded) {
        classNames.push('lzr-selection-card-expanded')
    }
    if (error) {
        classNames.push('lzr-selection-card-error')
    }

    return classNames.join(' ')
}

const getIconClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-icon' ]
    if (checked) {
        classNames.push('lzr-icon-checked')
    }
    if (error) {
        classNames.push('lzr-icon-error')
    }

    return classNames.join(' ')
}

const getTitleClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-title' ]
    if (checked) {
        classNames.push('lzr-title-checked')
    }
    if (error) {
        classNames.push('lzr-title-error')
    }

    return classNames.join(' ')
}

const getDescriptionClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-description' ]
    if (checked) {
        classNames.push('lzr-description-checked')
    }
    if (error) {
        classNames.push('lzr-description-error')
    }

    return classNames.join(' ')
}

const getHelperTextClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-helper-text' ]
    if (checked) {
        classNames.push('lzr-helper-text-checked')
    }
    if (error) {
        classNames.push('lzr-helper-text-error')
    }

    return classNames.join(' ')
}

const getHelperTextIconClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-helper-text-icon' ]
    if (checked) {
        classNames.push('lzr-helper-text-icon-checked')
    }
    if (error) {
        classNames.push('lzr-helper-text-icon-error')
    }

    return classNames.join(' ')
}

const getCheckIconClassName = (checked?: boolean, error?: boolean) => {
    const classNames = [ 'lzr-check-icon' ]
    if (checked) {
        classNames.push('lzr-check-icon-checked')
    }
    if (error) {
        classNames.push('lzr-check-icon-error')
    }

    return classNames.join(' ')
}

const getExpandedContentClassName = (expanded?: boolean) => {
    const classNames = [ 'lzr-expanded-content' ]
    if (expanded) {
        classNames.push('lzr-expanded-content-expanded')
    }

    return classNames.join(' ')
}

const SelectionCard: React.FunctionComponent<SelectionCardProps> = ({
    icon,
    title,
    description,
    helperText,
    helperTextIcon,
    exclusive = false,
    unclickable = false,
    style,
    className,
    value,
    error,
    expandedContent,
    showMore,
    showButton,
    hideCheckIcon = false,
    expandOnClick = false,
    checkIcon: checkIconProp,
    uncheckedIcon: uncheckedIconProp,
    closeIcon = <StyledCloseIcon icon={[ 'fal', 'times' ]} />,
    onClick: onClickProp,
    checked: checkedProp,
    onChange: onChangeProp,
    onClose: onCloseProp,
    name: nameProp,
    unexpandedWidth,
    unexpandedHeight,
    ...rest
}) => {
    const [ expanded, setExpanded ] = React.useState(false)
    const [ shouldShowMore, setShouldShowMore ] = React.useState(false);
    let checkIcon = checkIconProp ?? <StyledCheckIcon icon={[ 'far', 'check-circle' ]} />
    if (error) {
        checkIcon = <StyledCheckIcon icon={[ 'far', 'exclamation-circle' ]} />
    }

    const radioGroup = useRadioGroup()

    let checked = checkedProp
    const onChange = unclickable ? undefined : createChainedFunction(onChangeProp, radioGroup && radioGroup.onChange)
    let name = nameProp

    if (radioGroup) {
        if (typeof checked === 'undefined') {
            checked = radioGroup.value === value
        }
        if (typeof name === 'undefined') {
            name = radioGroup.name
        }
    }

    const canExpand = expandOnClick && !!expandedContent

    const onClick = (unclickable && expanded) ? undefined :
        createChainedFunction(onClickProp, canExpand ? (() => setExpanded(true)) : undefined)

    const onClose = createChainedFunction(onCloseProp)

    const ControlComponent = exclusive ? StyledMuiRadio : StyledMuiCheckbox

    const containerWidth = expanded ? '100%' : unexpandedWidth
    const containerHeight = expanded ? '100%' : unexpandedHeight

    const disabled = unclickable || expanded || rest.disabled

    React.useEffect(() => {
        if (!checked) {
            setExpanded(false)
        }
    }, [ checked ])

    const handleLongDescription = () => {
        const length = 80
        if (typeof description === 'string' && description.length > length && !shouldShowMore) {
            return description.slice(0, length) + '...';
        }
        return description;
    }

    return <div>
        <StyledContainer
            $width={containerWidth}
            $height={containerHeight}
            $unexpandedHeight={unexpandedHeight}
            $canExpand={canExpand}
            className={getSelectionCardContainerClassName(checked, expanded, error, className)}
            style={style}
        >
            {expanded &&
                <StyledCloseIconContainer
                    className={'lzr-close-icon'}
                    onClick={onClose}
                >
                    {closeIcon}
                </StyledCloseIconContainer>
            }
            <StyledLabel
                className={getLabelClassName(checked, expanded, error)}
                component='label'
                $unclickable={disabled}
                $expanded={expanded}
                $unexpandedHeight={unexpandedHeight}
                $helpers={!!helperTextIcon || !!helperText}
                disableRipple={disabled}
            >
                <Grid
                    container
                    spacing={3}
                    direction='column'
                    justifyContent={'flex-start'}
                    alignContent='space-between'
                >
                    <Grid item>
                        <Grid container spacing={4} direction='row' alignItems='center' wrap='nowrap'>
                            {icon &&
                                <Grid item>
                                    <div className={getIconClassName(checked, error)}>
                                        {icon}
                                    </div>
                                </Grid>
                            }
                            <Grid item className={getTitleClassName(checked, error)}>
                                <StyledTypographyH6 variant='h6'>
                                    {title}
                                </StyledTypographyH6>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        description &&
                        <Grid item className={getDescriptionClassName(checked, error)}>
                            <StyledTypographySubtitle1 variant='subtitle1'>
                                {showMore ? handleLongDescription() : description}
                            </StyledTypographySubtitle1>
                        </Grid>
                    }
                </Grid>
                {showButton && <Grid
                    container
                    justifyContent={'flex-end'}
                    alignItems={'flex-end'}
                    className={getDescriptionClassName(checked, error)}
                >
                    <Grid item>
                        <StyledTypographyButtonText
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setShouldShowMore(!shouldShowMore)
                            }}
                        >
                            { shouldShowMore ? 'Show Less' : 'Show More'}
                        </StyledTypographyButtonText>
                    </Grid>
                </Grid>}
                {(helperTextIcon || helperText) &&
                    <AbsoluteGrid container spacing={1} direction='row' alignItems='center'>
                        {helperTextIcon &&
                            <Grid item>
                                <div className={getHelperTextIconClassName(checked, error)}>
                                    {helperTextIcon}
                                </div>
                            </Grid>
                        }
                        {helperText &&
                            <Grid item className={getHelperTextClassName(checked, error)}>
                                <StyledTypographyBody2
                                    variant='body2'
                                    component={'span'}
                                >
                                    {helperText}
                                </StyledTypographyBody2>
                            </Grid>
                        }
                    </AbsoluteGrid>
                }
                {
                    ((checked && !hideCheckIcon) || error) &&
                    <StyledCheckIconContainer
                        className={getCheckIconClassName(checked, error)}
                    >
                        {checkIcon}
                    </StyledCheckIconContainer>
                }
                {
                    uncheckedIconProp && !checked && !error &&
                    <StyledUncheckedIcon>
                        {uncheckedIconProp}
                    </StyledUncheckedIcon>
                }
                <ControlComponent
                    {...rest}
                    onChange={onChange}
                    onClick={onClick}
                    name={name}
                    checked={checked}
                    value={value}
                    disabled={disabled}
                />
            </StyledLabel>
            {canExpand && <ExpandedContent className={getExpandedContentClassName(expanded)}>{expandedContent}</ExpandedContent>}
        </StyledContainer>
    </div>
}

type Props = Omit<CheckboxProps & RadioProps, 'title'>

export interface BaseSelectionCardProps extends Props {
    icon?: React.ReactNode
    title?: React.ReactNode
    description?: React.ReactNode | string
    helperText?: React.ReactNode
    helperTextIcon?: React.ReactNode
    checkIcon?: React.ReactNode
    uncheckedIcon?: React.ReactNode
    closeIcon?: React.ReactNode
    hideCheckIcon?: boolean
    exclusive?: boolean
    unclickable?: boolean
    error?: boolean
    showMore?: boolean
    showButton?: boolean
    onClose?: () => void
}

type ConditionalProps =
    {
        expandOnClick: boolean
        expandedContent: React.ReactNode
        unexpandedWidth: string
        unexpandedHeight: string
    }
    | {
        expandOnClick?: never
        expandedContent?: never
        unexpandedWidth?: string
        unexpandedHeight?: string
    }

export type SelectionCardProps = ConditionalProps & BaseSelectionCardProps

export default SelectionCard
