import styled from 'styled-components'
import { transparentize } from 'polished'

export const StyledDot = styled.div`
        width: 12px;
        height: 12px;
        margin: 0 6px;
        border-radius: 50%;
        background-color: ${(props): string => transparentize(0.8, props.theme.palette.black.main)};
        cursor: pointer;

        &.lzr-active-step{
            background-color: ${(props): string => props.theme.palette.neutral800.main};
        }
`
