/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './NewEfficiencyZeroFrictionMobile.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`New efficiencies.`]: () => (<><span className='accentPrimary600'>Nouvelles</span> efficacités.</>),
    [`Zero friction.`]: `Zéro friction.`,
    [`Power-up your operations and be the office hero. Our free shipping software is instantly deployable company-wide and designed for seamless collaboration with your colleagues.`]: `Renforcez vos opérations et devenez le héros de votre bureau. Notre logiciel d'expédition gratuit peut être déployé instantanément à l'échelle de l'entreprise et est conçu pour une collaboration transparente avec vos collègues.`,

    [`Customizable marketplace`]: `Un marketplace personnalisable`,
    [`Already have carrier contracts in place? Add them into your personal search results to instantly compare your own contracts with our quotes.`]: `Vous avez déjà conclu des ententes avec des transporteurs? Ajoutez-les aux résultats de votre marketplace pour comparer instantanément vos propres contrats avec nos prix instantanés négociés.`,

    [`Modernize your operations`]: `Modernisez vos opérations`,
    [`Bring your legacy systems up to speed with easy API integrations and EDI capabilities to make logistics communications more efficient than ever.`]: `Mettez à niveau vos systèmes existants grâce à des intégrations API faciles et des capacités EDI pour rendre les communications logistiques plus efficaces que jamais.`,

    [`Built for the entire team`]: `Conçu pour l'ensemble de l'équipe`,
    [`With enterprise-grade features, Lazr is purpose-built for collaboration, performance, and flexible monitoring. Anyone on your team can track, manage, and analyze shipments with ease.`]: `Avec des fonctionnalités de niveau entreprise, Lazr est conçu pour la collaboration, la performance et un suivi flexible. Tous les membres de votre équipe peuvent suivre, gérer et analyser les expéditions en toute simplicité.`,

    [`Free to use—forever`]: () => (<>Utilisation gratuite—<span className='accentSecondary400'>pour toujours</span></>),
    [`Pay no extra fees. Just create a free account and start shopping for quotes today.`]: `Ne payez pas de frais supplémentaires. Créez simplement un compte gratuit et commencez à chercher des devis dès aujourd'hui.`,

    [`Get Lazr now`]: `Obtenez Lazr maintenant`,
    [`Visit our marketplace`]: `Visitez notre marketplace`,
}))()
