/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './QuoteFasterShipSmarterDesktop.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Quote`]: `Soumission`,
    [`faster`]: `rapide`,
    [`Ship`]: `Expédition`,
    [`smarter`]: `intelligente`,
    [`Start saving today with the most intelligent shipping platform for businesses.`]: `Commencez à économiser dès aujourd'hui avec la plateforme d'expédition la plus intelligente pour les entreprises.`,
    [`Get Lazr for free`]: `Obtenez Lazr gratuitement`,
    [`Talk to our team`]: `Parler à notre équipe`,
}))()
