// import React from 'react'
import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'

export const Footer = styled(styled.div(MuiSpacing))`
    width: 100%;
    height: 72px;
    background-color: ${(props) => props.theme.palette.black.main};
    padding: ${(props): string => props.theme.spacing(4.75)}px 0 ${(props): string => props.theme.spacing(5.25)}px;
    display: flex;
    align-items: center;

    & img{
        height: 32px;
        margin-left: ${(props) => props.theme.spacing(9.25)}px;
    }

    & div{
        margin-left:  ${(props) => props.theme.spacing(15.25)}px;
        height: 24px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.palette.white.main};
        font-size: 1.2857rem;
        font-weight: 400;
        line-height: 1.7357rem;
    }
`
