import { Provider } from '@lazr/enums'
import { Search } from '@/app/model'
import { PurolatorSpecifics } from '@/app/model/ProviderSpecifics'

export const createPurolatorSpecificObject = (provider: Provider | undefined, order: Partial<Search>): PurolatorSpecifics => {

    if ((!order.rfq?.importExport?.code ||
        !order.rfq?.billDutiesToParty?.code ||
        order.rfq?.dutyBusinessRelated === null || order.rfq?.dutyBusinessRelated === undefined ||
        !order.rfq?.dutyCurrency?.code) && !order.rfq?.documentsOnlyIndicator
    ) {
        throw new Error(('Required fields missing.'))
    }

    return {
        purolatorSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                importExportType: order.rfq?.importExport?.code ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyBusinessRelated: order.rfq.dutyBusinessRelated ?? null,
                dutyCurrency: order.rfq?.dutyCurrency?.code ?? null,
                hazmatClass: order.rfq?.hazmatClass?.code ?? null,
                hazmatDeclarationDocumentIndicator: order.rfq?.hazmatDeclarationDocumentIndicator ?? null,
                hazmatMode: order.rfq?.hazmatMode?.code ?? null,
            },
        },
    }
}
