import styled from 'styled-components'
import { Grid as MuiGrid, Link as MuiLink, Typography as MuiTypography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export const WrapperGrid = styled(MuiGrid)`
    background: ${(props): string => props.theme.palette.background.paper};
    height: 72px;
    min-height: 72px;
    width: 100%;
    border-top: 1px solid ${(props): number => props.theme.palette.divider};
`

export const A = styled(MuiLink)`
    margin-left: ${(props): number => props.theme.spacing(6)}px;
    font-weight: 500;

    &,
    &:hover,
    &:active {
        color: ${(props): number => props.theme.palette.text.primary};
        text-decoration: none;
    }
`

export const Link = styled(RouterLink)`
    margin-left: ${(props): number => props.theme.spacing(6)}px;
    font-weight: 500;

    &,
    &:hover,
    &:active {
        color: ${(props): number => props.theme.palette.text.primary};
        text-decoration: none;
    }
`

export const Copyright = styled(MuiTypography)`
    margin-right: ${(props): number => props.theme.spacing(6)}px;
`
