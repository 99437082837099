import styled from 'styled-components'
import {
    Box as MuiBox, Typography, TypographyProps,
} from '@material-ui/core'
import React from 'react'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    color: ${(props): string => props.theme.palette.neutral800.main};
    & .lzr-color-purple{
        color: ${(props): number => props.theme.palette.accentSecondary.main};
    }
`

export const StyledParagraphTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.5rem;
    font-weight: 400;
    color: ${(props): string => props.theme.palette.neutral800.main};
    line-height: 125%;
`

export const StyledListItem: React.FC = styled.div`
    margin-left: ${(props): number => props.theme.spacing(9)}px;
    margin-right: ${(props): number => props.theme.spacing(6)}px;
`

export const StyledListItemTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.125rem;
`

export const StyledNewWayBackground: React.FC = styled.div`
    width: 575px;
    height: 375px;
    background: linear-gradient(180deg, rgba(0, 124, 122, 0.08), rgba(0, 124, 122, 0));
    padding-right: ${(props): string => props.theme.spacing(9)}px;
`

export const StyledStatContainer = styled(MuiBox)`
     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]{
        padding: ${(props): string => props.theme.spacing(6)}px;
        border: 1px solid ${(props): number => props.theme.palette.neutral200.main};
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:first-child{
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:last-child{
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:not(:last-child) {
        border-right: none;
     }
`

export const StyledStatBox = styled(MuiBox)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const StyledH1Typography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    color: ${(props): number => props.theme.palette.primary.main};
`
