import styled from 'styled-components'
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
import {
    Dialog, DialogProps,
} from '@/app/ui-new/components/Dialog/Dialog'
import { TabPanel as TabPanelStyled } from '@/app/ui-new/components/Tabs/Tabs'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    color: ${(props): string => props.theme.palette.black.main};
`

export const StyledDialog: React.FC<DialogProps> = styled(Dialog)<DialogProps>`
    & [role='dialog']{
        padding: 0;
    }

    & [role='dialog']>svg:first-of-type {
        margin-right: ${(props): string => props.theme.spacing(4)}px;
    }
`

export const StyledFormContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    width: 750px;
    height: 100vh;
    padding-top: ${(props): string => props.theme.spacing(16)}px;
    padding-bottom: ${(props): string => props.theme.spacing(16)}px;
    padding-left: ${(props): string => props.theme.spacing(16)}px;
    padding-right: ${(props): string => props.theme.spacing(12)}px;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.spacing(20)}px 0;

    & [class*='MuiToolbar-root']{
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 960px) {
        padding: ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(6)}px;
        & > div:last-child {
            width: 100%;
            max-width: 360px;
        }
    }
`
export const BoxPanel = styled(Box)`
    width: 360px;

    @media only screen and (max-width: 960px) {
        width: 100%;
    }
`

export const Welcome = styled(Typography)`
    font-family: 'Termina';
    color: ${(props): string => props.theme.palette.black.main};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    padding: ${(props) => props.theme.spacing(6)}px 0;

    & span{
        color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }
`
