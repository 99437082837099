import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {
    TabsToolbar as TabsToolbarStyled,
    Tab as TabStyled,
    Tabs as TabsStyled,
    TabPanel as TabPanelStyled,
} from '@/app/ui-new/components/Tabs/Tabs'
import { TabProps as MuiTabProps } from '@material-ui/core'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.spacing(20)}px 0;

    & [class*='MuiToolbar-root']{
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 960px) {
        padding: ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(6)}px;
        & > div:last-child {
            width: 100%;
            max-width: 360px;
        }
    }
`

export const Welcome = styled(Typography)`
    font-family: 'Termina';
    color: ${(props): string => props.theme.palette.black.main};
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.5rem;
    padding: ${(props) => props.theme.spacing(6)}px 0;

    & span{
        color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }
`

export const TabsToolbar = styled(TabsToolbarStyled)`
    width: 100%;
    max-width: 420px;

    & > div{
        width: 100%;
    }
`

export const Tabs = TabsStyled

export const Tab: React.FC<TabProps> = styled(TabStyled) <TabProps>`
    @media only screen and (max-width: 960px) {
        width: ${(props): number => 100 / props.tabCount}%;
    }
`

export const TabPanel = styled(TabPanelStyled)`
    width: 360px;

    @media only screen and (max-width: 960px) {
        width: 100%;
    }
`

export interface TabProps extends MuiTabProps{
    tabCount: number
}
