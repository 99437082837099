import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import SignIn from '../../auth/SignIn'
import ResetPassword from '../../auth/ResetPassword'


const SignInModal: React.FunctionComponent<Props> = ({ showSignInModal }) => {
    const [ isResetPassword, setIsResetPassword ] = useState<boolean>(false)

    return (
        <Modal
            open={showSignInModal}
            maxWidth={650}
            modalTitle=""
            closeButton={false}
            content={
                <>
                    {isResetPassword ?
                        <ResetPassword isModal={true} setIsResetPassword={setIsResetPassword}/>
                        :
                        <SignIn isModal={true} setIsResetPassword={setIsResetPassword}/>}

                </>
            }
        />
    )
}

export interface Props {
    showSignInModal: boolean
}

export default SignInModal
