import React, { Fragment } from 'react'
import { WhiteLink } from './tooltipTexts.styled'

export const COVERAGE_TOOLTIP_TEXT = (
    <Fragment>
        <p>LAZR COVERAGE</p>
        <ul>
            <li>Faster complaint resolution (often less than 45 days)</li>
            <li>Third party coverage provided by Lazr partner</li>
            <li>
                {'<'}BETA: DEDUCTIBLE INFORMATION{'>'}
            </li>
            <li>
                Provides full coverage of the value of the goods, rules, and
                limitations provided by our coverage partner
            </li>
        </ul>
        <p>
            Lazr offers a commodity coverage service that gives customers peace
            of mind about commodity values. Choosing this option is to purchase
            and accept coverage for goods provided by Lazr partners.
        </p>
        <p>
            The customer understands and agrees that Lazr&apos;s coverage
            applies to all merchandise that is not part of the exclusions.
        </p>
        <p>
            For a list of exclusions, please see{' '}
            <WhiteLink href='/lazrCoverageExclusionDocument.pdf' target='_blank'>
                <u>this link</u>
            </WhiteLink>
            .
        </p>
        <p>BASIC CARRIER COVERAGE</p>
        <ul>
            <li>Resolution of claims is typically 90 days or more</li>
            <li>Cargo coverage provided by transport partners</li>
            <li>
                The eligibility conditions for a claim are determined by the
                terms and conditions of the carrier partner
            </li>
        </ul>

        <p>
            All goods transported via Lazr are covered by the cargo insurance of
            the transport partners. The insured amount varies according to the
            terms and conditions of the carrier. Please note that a verification
            must be done by the customer to confirm the insurable values.
        </p>
        <p>
            If you wish to obtain coverage for the full value of your
            merchandise, please enter the declared value in order to obtain the
            coverage price through our partners.
        </p>
    </Fragment>
)

export const SERVICE_LEVEL_TOOLTIP_TEXT = (
    <Fragment>
        <p>SERVICE LEVEL</p>
        <p>
            The level of service refers to the designation of services by the
            transport partners. E.g.: Expedite, Guaranteed, Standard, etc. This
            name comes directly from our partners and differs from the transport
            category provided by Lazr.
        </p>
    </Fragment>
)

export const ESTIMATED_TOOLTIP_TEXT = `Lazr retransmits the “transit estimates” of the carriers as well as the 3PLs as they are.
 They do not include pick-up day, weekends, and holidays. In addition, transit estimates
 are more of an approximation since transit times may vary depending on availability,
 weather conditions, and other factors. For shipments where transit accuracy is paramount,
 a custom quote is preferred.`

export const LAZR_CATEGORY_TOOLTIP_TEXT = (
    <Fragment>
        <p>LAZR CATEGORY</p>
        <p>
            The objective of the Lazr category is to specify the type of service
            offered by better defining customer expectations.
        </p>
        <ul>
            <li>
                Standard Partner: Private fleets with various offers in terms of
                quantity and price. Often smaller in size, these companies
                provide a personalized level of service.
            </li>
            <li>
                Common Carrier Partner: Larger private or public fleets. These
                transport companies offer their services at all times to all
                customers. The service level of a common carrier is more often
                enterprise level, that is, often less personalized.
            </li>
        </ul>
    </Fragment>
)

export const EST_TRANSIT_TOOLTIP_TEXT = (
    <Fragment>
        <p>EST TRANSIT</p>
        <p>{ESTIMATED_TOOLTIP_TEXT}</p>
    </Fragment>
)

export const EST_DELIVERY_TOOLTIP_TEXT = (
    <Fragment>
        <p>EST DELIVERY DATE</p>
        <p>{ESTIMATED_TOOLTIP_TEXT}</p>
    </Fragment>
)

export const CUSTOMS_DETAILS_BILL_DUTIES_TO_PARTY_TOOLTIP_TEXT = (
    <Fragment>
        <p>Duty</p>
        <p>
            Depending on the goods shipped, the customs authorities in the destination country may apply duties.
            While the customs broker manages this process, you need to indicate who will be responsible for them.<br/><br/>
            Select “Sender”, if you wish to be billed for duties.<br/><br/>
            Select “Receiver”, if your recipient will cover the cost.<br/><br/>
            Selecting “Buyer”, will allow you to enter the address information of the person that will cover this cost.
        </p>

    </Fragment>
)
export const CUSTOMS_DETAILS_INCOTERMS_TOOLTIP_IMAGE = (lang: string) => {
    const url = `/images/tooltips/Synergie-Incoterms2020_${lang}.png?v=${window.lazrVersion}`

    return (
        <Fragment><img src={url}/></Fragment>
    )
}
export const CUSTOMS_DETAILS_USMCA_TOOLTIP_TEXT = (
    <Fragment>
        <p>
            Section 202 of the USMCA Implementation Act specifies the rules of origin used to determine whether a good qualifies as an
            originating good under the Agreement. The HTSUS GN 11 includes both the general and specific rules of origin, definitions, and
            other related provisions.
        </p>

            In general, under the USMCA, a good is originating based on the following five RoO criterion A-E and the good satisfies all
            other applicable requirements:

        <ul>
            <li>
                Criterion A: The good is wholly obtained or produced entirely in the territory of one or more of the USMCA countries, as
                defined in Article 4.3 of theAgreement;
            </li>
            <li>
                Criterion B: The good is produced entirely in the territory of one or more of the USMCA countries using non-originating
                materials, provided the good satisfies all applicable requirements of product-specific rules of origin;
            </li>
            <li>
                Criterion C: The good is produced entirely in the territory of one or more of the USMCA countries exclusively from
                originating materials; or
            </li>
            <li>
                Criterion D: The good is produced entirely in the territory of one or more of the USMCA 7 countries. It is classified with
                its materials, or satisfies the “unassembled goods” requirement, and meets a regional value content threshold of not less
                than 60% if the transaction value method is used, or not less than 50% if the net cost method is used
                (not including RVC for autos); except for goods in Chapter 61-63 of the HTSUS.
            </li>
            <li>
                Criterion E: The goods provided for under the tariff provisions set out in Chapter 2 - Table 2.10.1,
                Table 2.10.2, and Table 2.10.3.
            </li>
        </ul>
            For more information, go to page 6 in this&nbsp;
        <WhiteLink
            // eslint-disable-next-line max-len
            href='https://www.cbp.gov/sites/default/files/assets/documents/2020-Jun/USMCA%20Implementing%20Instructions%20-%202020%20Jun%2030%20%28Finalv1%29.pdf'
            target='_blank'>
            <u>link</u>
        </WhiteLink>
        .
    </Fragment>
)

export const CUSTOMS_DETAILS_FDA_TOOLTIP_TEXT = (
    <Fragment>
        <p>FDA (Food)</p>
        <p>
            Shippers must provide the Prior Notice confirmation with their shipment,
            as required by the U.S. FDA. Please check this box if you are shipping food,
            and include the Prior Notice Confirmation with your shipment.
            Exemptions from Prior Notice: Food sent for non commercial purposes, from an individual to an individual.<br/><br/>

            Please visit the&nbsp;
            <WhiteLink href='https://www.fda.gov/food' target='_blank'>
                <u>FDA website</u>
            </WhiteLink>
            &nbsp;for more details, including the definition of food.
        </p>

    </Fragment>
)

export const CUSTOMS_DETAILS_HARMONIZED_CODE_TOOLTIP_TEXT = (
    <Fragment>
        <p>Harmonized code</p>
        <p>
            Product classification codes for international shipping.<br/><br/>
            Please visit&nbsp;
            <WhiteLink href='https://www.canadapost-postescanada.ca/information/app/wtz/entreprise/trouvercodesh?execution=e1s1'
                target='_blank'>
                <u>Canada Post</u>
            </WhiteLink>
            &nbsp;website for more details.
        </p>

    </Fragment>
)

export const CUSTOM_DETAILS_B13_EXPORT_COMPLIANCE_STATEMENT_TOOLTIP_TEXT = (
    <Fragment>
        <p>Export Compliance Statement</p>
        <p>
            Please click&nbsp;
            <WhiteLink href='https://www.cbsa-asfc.gc.ca/services/export/ndr-adr-eng.html'
                target='_blank'>
                <u>here</u>
            </WhiteLink>
            &nbsp; for more details.
        </p>

    </Fragment>
)

export const CUSTOMS_DETAILS_SQUARE_METERS_TOOLTIP_TEXT = (
    <Fragment>
        <p>Square Meters</p>
        <p>
            The size of the handling unit in square meters.
        </p>
    </Fragment>
)

export const CUSTOMS_DETAILS_IMPORT_EXPORT_TOOLTIP_TEXT = (
    <Fragment>
        <p>Import/Export type</p>
        <p>

            Import / Export types are classified as follows:<br/><br/>
            Permanent – Items intended to stay for use in the destination country on a permanent basis.<br/><br/>
            Temporary – Goods are being shipped for temporary use and will be returned after a period of time
            (note: duties and taxes may still be assessed on the shipment)<br/><br/>
            Repair / Return – Goods are either being sent to the destination to be repaired and then returned to sender,
            or goods that are being returned to their origin.<br/><br/>
            Select the classification that applies to the shipment you are sending.
        </p>

    </Fragment>
)


export const CUSTOMS_DETAILS_TEXTILE_TOOLTIP_TEXT = (
    <Fragment>
        <p>Textile</p>
        <p>
            Please check this box, if you are shipping textiles. Shippers must provide the name and address or
            manufacturer&apos;s identification (MID) number of the company that manufactured the article,
            as required by the U.S. Customs and Border Protection (CBP).<br/><br/>
            Exemptions: Personal shipments that are being presented with an accompanying form 3299,
            gifts valued at up to $100 USD per person and U.S. manufactured goods.<br/><br/>

            Please visit the&nbsp;
            <WhiteLink href='https://www.cbp.gov/' target='_blank'>
                <u>U.S CBP website</u>
            </WhiteLink>
            &nbsp;for more details.
            Please note that manufacturer information will only appear on the laser version of the Commercial Invoice.
        </p>

    </Fragment>
)

export const SHIPPING_INSTRUCTION_TOOLTIP_TEXT = (
    <Fragment>
        <p>Shipping Instruction</p>
        <p>
            Please note that the automated rates provided by Lazr are not valid
            for exceptional shipments such as: Showroom deliveries / pickups,
            Amazon deliveries and pickups, limited access deliveries and
            pickups, etc. (see the support section for more information on
            limited access). In cases where a price would be provided to you
            automatically under these conditions, please note that this price
            may not be respected. Requesting a custom quote would be preferable.
        </p>
    </Fragment>
)

export const CUSTOMS_BROKER_TOOLTIP_TEXT = (
    <Fragment>
        <p>Customs broker</p>
        <p>
            Please note that you will be charged an extra fee of $60.00 CAD / $45.00 USD when
            getting the shipment cleared by an external broker on express and priority services.
        </p>
    </Fragment>
)


export const INFO_TOOLTIP_TEXT = (
    <Fragment>
        {COVERAGE_TOOLTIP_TEXT}
        {SERVICE_LEVEL_TOOLTIP_TEXT}
        {EST_TRANSIT_TOOLTIP_TEXT}
        {EST_DELIVERY_TOOLTIP_TEXT}
        {LAZR_CATEGORY_TOOLTIP_TEXT}
    </Fragment>
)
