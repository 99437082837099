import { Currency, Provider } from '@lazr/enums'
import { isMailingService } from '@lazr/utilities'
import { OrderInfo } from '@/app/model'
import { FedexSpecifics } from '@/app/model/ProviderSpecifics'


export const createFedexSpecificObject = (provider: Provider | undefined, order: Partial<OrderInfo>) : FedexSpecifics => {
    const isMailing = isMailingService(order.rfq?.transportType.code)
    if (!order.rfq?.billDutiesToParty?.code || (!!order.rfq?.commercialInvoiceDocumentIndicator && !order.rfq?.importExport)) {
        throw new Error(('Required fields missing.'))
    }


    return {
        fedexSpecifics: {
            ParcelPakEnvelope: isMailing ? {
                importExportType: order.rfq?.importExport?.code ?? null,
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyBusinessRelated: order.rfq?.dutyBusinessRelated ?? false,
                dutyCurrency: order.rfq?.dutyCurrency ?? Currency.CAD,
                b13aFilingOption: order.rfq?.b13aFilingOption?.code ?? null,
                exportComplianceStatement: order.rfq?.exportComplianceStatement?.code ?? null,
                customsBrokerCity: order.rfq?.customsBrokerCity ?? null,
                customsBrokerEmail: order.rfq?.customsBrokerEmail ?? null,
                customsBrokerContactName: order.rfq?.customsBrokerContactName ?? null,
                customsBrokerContactPhone: order.rfq?.customsBrokerContactPhone ?? null,
                customsBrokerContactPhoneExt: order.rfq?.customsBrokerContactPhoneExt ?? null,
                customsBrokerCountry: order.rfq?.customsBrokerCountry?.code ?? null,
                customsBrokerPostalCode: order.rfq?.customsBrokerPostalCode ?? null,
                customsBrokerState: order.rfq?.customsBrokerState ?? null,
                customsBrokerStreetAddress: order.rfq?.customsBrokerStreetAddress ?? null,
                customsBrokerStreetAddress2: order.rfq?.customsBrokerStreetAddress2 ?? null,
                customsBrokerStreetAddress3: order.rfq?.customsBrokerStreetAddress3 ?? null,
                customsBrokerAccountNumber: order.rfq?.customsBrokerAccountNumber ?? null,
                etdEnabled: order.rfq?.etdEnabled ?? null,
            } : undefined,
            LTL: !isMailing ? {
                importExportType: order.rfq?.importExport?.code ?? null,
                documentsOnlyIndicator: order.rfq?.documentsOnlyIndicator ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyBusinessRelated: order.rfq?.dutyBusinessRelated ?? false,
                dutyCurrency: order.rfq?.dutyCurrency ?? Currency.CAD,
                customsBrokerCity: order.rfq?.customsBrokerCity ?? null,
                customsBrokerEmail: order.rfq?.customsBrokerEmail ?? null,
                customsBrokerContactName: order.rfq?.customsBrokerContactName ?? null,
                customsBrokerContactPhone: order.rfq?.customsBrokerContactPhone ?? null,
                customsBrokerContactPhoneExt: order.rfq?.customsBrokerContactPhoneExt ?? null,
                customsBrokerCountry: order.rfq?.customsBrokerCountry?.code ?? null,
                customsBrokerPostalCode: order.rfq?.customsBrokerPostalCode ?? null,
                customsBrokerState: order.rfq?.customsBrokerState ?? null,
                customsBrokerStreetAddress: order.rfq?.customsBrokerStreetAddress ?? null,
                customsBrokerStreetAddress2: order.rfq?.customsBrokerStreetAddress2 ?? null,
                customsBrokerStreetAddress3: order.rfq?.customsBrokerStreetAddress3 ?? null,
                customsBrokerAccountNumber: order.rfq?.customsBrokerAccountNumber ?? null,
            } : undefined,
        },
    }
}
