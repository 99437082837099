/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoverageCommodityType } from '../models/CoverageCommodityType';
import type { SuccessResponse_GetCoverageResponse_Array_ } from '../models/SuccessResponse_GetCoverageResponse_Array_';
import type { TransportType } from '../models/TransportType';
import { request as __request } from '../core/request';

export class CoverageService {

    /**
     * Get Coverage Calculations
     * @param totalFreightCharges The array of freight charge amounts
     * @param shipmentCoverageValue The shipment coverage value
     * @param commodityType The coverage commodity type
     * @param transportType
     * @returns SuccessResponse_GetCoverageResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCoverage(
        totalFreightCharges: Array<number>,
        shipmentCoverageValue: number,
        commodityType: CoverageCommodityType,
        transportType: TransportType,
    ): Promise<SuccessResponse_GetCoverageResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/coverage`,
            query: {
                'totalFreightCharges': totalFreightCharges,
                'shipmentCoverageValue': shipmentCoverageValue,
                'commodityType': commodityType,
                'transportType': transportType,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}