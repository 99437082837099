import { Country as CountryCode } from '@lazr/openapi-client'

export interface CountryProps {
    code: CountryCode
    name: string
    isSupported: boolean
}

export class Country {
    public code: CountryCode
    public name: string
    public isSupported: boolean

    constructor (props: CountryProps) {
        this.code = props.code
        this.name = props.name
        this.isSupported = props.isSupported
    }
}
