import React, { FC } from 'react'
import { Badge as MuiBadge, BadgeProps } from './Badge.styled'

const Badge: FC<BadgeProps> = (props) => <MuiBadge
    {...{
        ...props,
        overlap: props.overlap || 'rectangular',
    }}
>{props.children}</MuiBadge>

export default Badge
