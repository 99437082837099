import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const PittOhioLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/pitt-ohio-logo.svg?v=${window.lazrVersion}`}
        name={'Pitt Ohio'}
    />

export default PittOhioLogo
