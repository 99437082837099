/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './SignIn.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Email cannot be empty']: 'Le champ de courriel doit être complété',
    ['Email is not a valid format']: `Le format du courriel n'est pas valide`,
    ['Password cannot be empty']: 'Le champ de mot de passe doit être complété',
    [`Password must be at least '{passwordLength}' characters long`]: ({ passwordLength }: { passwordLength: number }): string => (`Le mot de passe doit avoir une longueur d'au moins ${passwordLength} caractères`),
    ['This email has not been verified']: `Cette adresse courriel n'a pas été vérifiée`,
    ['You have not yet been approved by your administrator']: `Vous n'avez pas été approuvé par votre administrateur`,
    ['The specified login token is invalid']: 'Le jeton d\'authentification spécifié est invalide',
    ['This email / password combination is invalid']: 'Cette combinaison de courriel / mot de passe est invalide',
    ['Email']: 'Courriel',
    ['Password']: 'Mot de passe',
    ['Sign In']: 'Connexion',
    ['Forgot password?']: 'Mot de passe oublié?',
}))()
