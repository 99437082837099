import styled from 'styled-components'
import { IconButton } from '@/app/ui-new/components/IconButton/IconButton.styled'
import { transparentize } from 'polished'
import { Box } from '@material-ui/core'
import { InnerBoxProps } from '@/app/ui-new/pages/landing/components/CarriersCarousel/ScrollableList'


export const StyledOuterBox = styled.div`
    position: relative;
    padding: 0px ${(props): number => props.theme.spacing(2)}px;
    max-width: 100%;
    min-width: 310px;
`

export const StyledInnerBox: React.FC<InnerBoxProps> = styled(Box)<InnerBoxProps>`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    min-height: 120px;
    &: hover {
        cursor: ${(props): string => props.isOverflowed ? 'pointer' : 'default'};
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        /* WebKit */ width: 0;
        height: 0;
    }
`

export const StyledLeftBtnBox = styled.div<{ $bgColor: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props): number => props.theme.spacing(2)}px;
    width: 100px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(to left, ${(props) => props.$bgColor}00, ${(props) => props.$bgColor}FF);
`

export const StyledRightBtnBox = styled.div<{ $bgColor: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: end;
    background-image: linear-gradient(to right, ${(props) => props.$bgColor}00, ${(props) => props.$bgColor}FF);
`

export const StyledIconButton = styled(IconButton)`
    background-color: ${(props): string => props.theme.palette.neutral800.main};
    border-color: transparent;
    color: ${(props): number => props.theme.palette.white.main} !important;
    z-index: 1;

    &:focus{
        outline: unset;
        border-color: transparent;
    }

    &:hover{
        outline: unset;
        border-color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }

    &.Mui-disabled {
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.neutral800.main)};
    }
`

export const StyledListItem = styled.div`
    border-color: ${(props): string => transparentize(0.85, props.theme.palette.black.main)};
    margin: 0  ${(props): number => props.theme.spacing(2)}px;
    user-select: none;
    & img {
    -webkit-user-drag: none;
    }
`
