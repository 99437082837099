import React from 'react'
import { TextField as CustomTextField, Label } from './TextField.styled'
import { OutlinedTextFieldProps, StandardTextFieldProps } from '@material-ui/core'

import Tooltip, { CustomTooltipProps } from '../Tooltip/Tooltip'
import { FontAwesomeIcon } from '../IconButton/IconButton.styled'
import { QuestionIcon } from '../Tooltip/Tooltip.styled'
import { CustomDialogProps, Dialog, DialogTitle, DialogText, DialogButtonContainer } from '../Dialog/Dialog'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import { DialogTypography } from '../Dialog/Dialog.styled'


const TextField: React.FunctionComponent<CustomsTextFieldProps> = React.forwardRef((props, ref) => {
    return (<div>
        {props.label && <Label $disabled={!!props.disabled} mb={props.mb}>
            {props.required && props.label ? `${props.label} *` : `${props.label ?? ''}`}
            {props.dialog?.triggerIcon ?? null}
            {props?.tooltip &&
                <Tooltip {...props?.tooltip} >
                    <QuestionIcon>
                        <FontAwesomeIcon icon={props?.tooltip?.icon ? props?.tooltip?.icon : ['far', 'question-circle']} />
                    </QuestionIcon>
                </Tooltip>
            }
        </Label>}
        <CustomTextField
            {...props}
            ref={ref}
            label={undefined}
        />
        {props.dialog && <Dialog
                size='normal'
                open={props.dialog.showModal}
                onClose={props.dialog.btnOnClick}
            >
                <DialogTitle centered>
                    <strong>{ props.dialog.title }</strong>
                </DialogTitle>
                <DialogText mt={4} mb={2}>
                    <DialogTypography>
                        {
                            props.dialog.content
                        }
                    </DialogTypography>
                </DialogText>
                <DialogButtonContainer centered>
                    <PrimaryButton
                        size='medium'
                        onClick={props.dialog.btnOnClick}
                    >
                        { props.dialog.btnText }
                    </PrimaryButton>
                </DialogButtonContainer>
            </Dialog>}
    </div>)
})

TextField.displayName = 'TextField'

export interface CustomsTextFieldProps extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, 'select'> {
    label?: string
    tooltip?: CustomTooltipProps
    mb?:number
    dialog?: CustomDialogProps
}

export default TextField

