import { ApiServiceAction, TransportType } from '@lazr/enums'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from './useDebouncedCallback'
import type { UuidV4 } from '@lazr/openapi-client'
import { ServiceLevel } from '@/app/model'
import { ServiceLevelApiService } from '@/app/service/ApiService/ServiceLevelApiService'

export interface SubscribeProps {
    wait?: number
}

export interface ServiceLevelProps {
    list?: {
        carrierId: UuidV4,
        transportType?: TransportType
        page?: number
        limit?: number
        wait?: number
    }
}

export interface ReturnServiceLevelProps {
    list?: {
        data: ServiceLevel[],
        loaded: boolean
    }
}



const useServiceLevel = (
    { list }: ServiceLevelProps,
    action: ApiServiceAction,
): ReturnServiceLevelProps => {
    // Define initial results state
    const [resultServiceLevel, setResultServiceLevel] = useState<ReturnServiceLevelProps['list']>({ data:[], loaded: false })

    // Calculate milliseconds
    const milliseconds = list?.wait ? list.wait * 1000 : 0

    // Function to fetch data

    // Function to fetch service levels
    const listServiceLevels = async (): Promise<void> => {
        try {
            if (list?.carrierId && list?.transportType) {
                const result = await ServiceLevelApiService.getByCarrierId({
                    carrierId: list?.carrierId,
                    transportType: list.transportType,
                })
                const resultServiceLevels: ReturnServiceLevelProps['list'] = {
                    data: result,
                    loaded: true,
                }
                setResultServiceLevel(resultServiceLevels)
            } else {
                setResultServiceLevel({ data: [], loaded: false })
            }
        } catch (error) {
            console.error('Error fetching service level data:', error)
            setResultServiceLevel({ data: [], loaded: false })
        }
    }

    const debounceSchedulePickup = useDebouncedCallback(async (action) => {
        switch (action) {
            case ApiServiceAction.LIST:
                await listServiceLevels()
                break
            default:
                break
        }
    }, milliseconds)

    // Effect to fetch data on limit and page change
    useEffect(() => {
        debounceSchedulePickup(action)
    }, [debounceSchedulePickup, action, list?.limit, list?.page, list?.carrierId, list?.transportType ])

    // Effect to subscribe to events and fetch data with debounce
    return { list: resultServiceLevel }
}

export default useServiceLevel
