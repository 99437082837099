import * as types from '../constants'

export const setVersion = (value: string | null = null): types.Action => ({
    type: types.SET_VERSION,
    payload: value,
})

export const setVersionSnackbarOpen = (value = false): types.Action => ({
    type: types.SET_VERSION_SNACKBACK_OPEN,
    payload: value,
})
