/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderStatus {
    PRELOAD = 'PRELOAD',
    GHOST = 'GHOST',
    DRAFT = 'DRAFT',
    NEGOTIATION = 'NEGOTIATION',
    RESERVATION = 'RESERVATION',
    TRANSPORT = 'TRANSPORT',
    BILLING = 'BILLING',
    COMPLETED = 'COMPLETED',
}