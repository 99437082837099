import React from 'react'
import {
    ButtonTextTypography,
    Card1,
    Card2,
    Card3,
    Card4, CarouselContainer,
    Logo,
    Page, StyledPrimaryButton, StyledTypographyH1, StyledTypographyLabel,
    SubTitleTypography,
    TitleTypography,
} from '@/app/ui-new/pages/landing/theme/teal/index.styled'
import { Box } from '@material-ui/core'
import StepCard from '@/app/ui-new/pages/landing/theme/teal/components/StepCard'
import CarriersCarousel from '@/app/ui-new/pages/landing/components/CarriersCarousel/CarriersCarousel'
import { Language } from '@lazr/enums'
import { freightCarriers, parcelCarriers } from '@/app/ui-new/pages/landing/components/CarriersCarousel/helper'
import { Footer } from '@/app/ui-new/pages/landing/components/Footer/Footer'
import { useDispatch } from 'react-redux'
import { setRequestDemoDialogOpen as reduxSetRequestDemoOpen } from '@/app/ui/redux/actions/RequestDemoActions'

/* I18N */
import i18n from './index.i18n'
import { setRegisterDialogOpen as reduxSetRegisterDialogOpen } from '@/app/ui/redux/actions/RegisterActions'
import { Helmet } from 'react-helmet'

declare const window: Window & { dataLayer: Record<string, unknown>[] }

export const TealPage: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = i18n.activate(props.language.toLowerCase() as 'en' | 'fr')
    const dispatch = useDispatch()

    const urlParams = new URLSearchParams(window.location.search)

    return (
        <>
            <Helmet>
                <title>{t('Page Title')}</title>
                <meta charSet="utf-8" />
                <meta name="description" content={t('Meta Description')} />
            </Helmet>
            <Page>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Box mt={16}>
                            <Logo/>
                        </Box>

                        <Box mt={11} width={1000} textAlign={'center'}>
                            <TitleTypography variant={'h1'}>{props.title}</TitleTypography>
                        </Box>

                        <Box mt={8} width={700} textAlign={'center'}>
                            <SubTitleTypography variant={'body1'}>{props.subTitle}</SubTitleTypography>
                        </Box>

                        <Box mt={8}>
                            <StyledPrimaryButton
                                onClick={
                                    () => {
                                        if (props.button.type === 'RequestDemo') {
                                            dispatch(reduxSetRequestDemoOpen(true))
                                        } else {
                                            dispatch(reduxSetRegisterDialogOpen(true))
                                        }

                                        window.dataLayer.push({
                                            event: 'landing_main_button',
                                            landingPageTheme: urlParams.get('theme'),
                                            landingPageButton: urlParams.get('button'),
                                            landingPageSubtitle: urlParams.get('subtitle'),
                                            landingPageTitle: urlParams.get('title'),
                                            landingPageTestimonials: urlParams.get('testimonials'),
                                            landingPageLanguage: urlParams.get('language'),
                                        })
                                    }}
                                endIcon={
                                    <img src={`/images/landing/LazrBrandedArrowWhite.svg?v=${window.lazrVersion}`}
                                        alt="LazrIcon"/>
                                }
                                size={'large'}
                            >
                                <ButtonTextTypography variant={'h2'}>{props.button.text}</ButtonTextTypography>
                            </StyledPrimaryButton>
                        </Box>

                        <Box width={'100vw'} display={'flex'} flexDirection={'row'} alignItems={'center'} mt={props.isCommerceChamber ? 275 : 180} position={'absolute'}>
                            <Box width={246} ml={27}>
                                <StyledTypographyH1 variant='h1'>{'50+'}</StyledTypographyH1>
                                <StyledTypographyLabel variant='body2'>
                                    {t('Trusted carriers are on Lazr')}
                                </StyledTypographyLabel>
                            </Box>
                            <CarouselContainer>
                                <CarriersCarousel
                                    freightCarriers={freightCarriers}
                                    parcelCarriers={parcelCarriers}
                                    carousel={urlParams.get('carousel') ?? '1'}
                                    bgColor={'#D8F5F2'}
                                />
                            </CarouselContainer>
                        </Box>
                    </Box>
                    <div>
                        <Footer language={props.language}/>
                    </div>
                </Box>

                <Card1 src={`/images/landing/theme/teal/CardYrc2x.png?v=${window.lazrVersion}`} alt="Card1"/>
                <Card2 src={`/images/landing/theme/teal/CardManitoulin2x.png?v=${window.lazrVersion}`} alt="Card2"/>
                <Card3 src={`/images/landing/theme/teal/CardTForce2x.png?v=${window.lazrVersion}`} alt="Card3"/>
                <Card4 src={`/images/landing/theme/teal/CardDayAndRoss2x.png?v=${window.lazrVersion}`} alt="Card4"/>
            </Page>
        </>
    )
}

export interface Props {
    title: string
    subTitle: JSX.Element | string
    button: {
        text: JSX.Element | string
        type: string
    }
    testimonials: {
        text: string
        name: string
        jobTitle: string
    }[]
    language: Language
    isCommerceChamber?: boolean
}
