/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RfqStatus {
    PRELOAD = 'PRELOAD',
    GHOST = 'GHOST',
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    REVISED = 'REVISED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    ABORTED = 'ABORTED',
    EXPIRED = 'EXPIRED',
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
    BOOKED = 'BOOKED',
    CARRIER_DISPATCHED = 'CARRIER_DISPATCHED',
    AWAITING_ORGANIZATION_APPROVAL = 'AWAITING_ORGANIZATION_APPROVAL',
}