/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import React from 'react'
import { AddressBookType } from '@/app/ui/lib/constants'

export default {
    [`Default shipping address`]: `Default shipping address`,
    [`Default shipping address is already selected`]: (data: { addressBookType: AddressBookType}) =>
        <>
            <div>Default shipping address is already selected</div>
            <div> as {data.addressBookType === AddressBookType.ORIGIN ?
                AddressBookType.DESTINATION.toLowerCase() : AddressBookType.ORIGIN.toLowerCase()} address!
            </div>
        </>,
    [`No default shipping address found!`]: `No default shipping address found!`,
    [`Info`]: `Info`,
}
