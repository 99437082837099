/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateConsolidationRequest } from '../models/GenerateConsolidationRequest';
import type { GenerateInvoiceRequest } from '../models/GenerateInvoiceRequest';
import type { SuccessResponse_GenerateConsolidationResponse_ } from '../models/SuccessResponse_GenerateConsolidationResponse_';
import type { SuccessResponse_GenerateInvoiceResponse_ } from '../models/SuccessResponse_GenerateInvoiceResponse_';
import type { SuccessResponse_GenerateStatementOfAccountResponse_ } from '../models/SuccessResponse_GenerateStatementOfAccountResponse_';
import type { SuccessResponse_LateInvoicePaymentsResponse_Array_ } from '../models/SuccessResponse_LateInvoicePaymentsResponse_Array_';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * Generate a Consolidation document
     * @param requestBody The data
     * @returns SuccessResponse_GenerateConsolidationResponse_ OK
     * @throws ApiError
     */
    public static async generateConsolidation(
        requestBody: GenerateConsolidationRequest,
    ): Promise<SuccessResponse_GenerateConsolidationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/generate-consolidation`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate an Invoice document for an order
     * @param requestBody The data
     * @returns SuccessResponse_GenerateInvoiceResponse_ OK
     * @throws ApiError
     */
    public static async generateInvoice(
        requestBody: GenerateInvoiceRequest,
    ): Promise<SuccessResponse_GenerateInvoiceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/generate-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate statement of account
     * @returns SuccessResponse_GenerateStatementOfAccountResponse_ OK
     * @throws ApiError
     */
    public static async generateStatementOfAccount(): Promise<SuccessResponse_GenerateStatementOfAccountResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/generate-statement-of-account`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of all the late, not fully paid invoices.
     * Packages them in a tiny easy to use format.
     * positive number means the amount of days left
     * negative number means the payment is late by that amount of days.
     * @returns SuccessResponse_LateInvoicePaymentsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLateInvoicePayments(): Promise<SuccessResponse_LateInvoicePaymentsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/late-payments`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}