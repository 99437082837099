import { Currency } from '@lazr/openapi-client'
import { PriceReviewCharge, PriceReviewChargeAttributes } from './PriceReviewCharge'

export interface PriceReviewAttributes {
    orderId: string
    rawCad: number
    rawUsd: number
    totalCad: number
    totalUsd: number
    currency: Currency
    charges: PriceReviewChargeAttributes[]
    markAsCompleted: boolean
    completeReason: string | null
}
export interface PriceReviewProps {
    id: string
    orderId: string
    rawCad?: number
    rawUsd?: number
    totalCad: number
    totalUsd: number
    currency: Currency
    updatedAt: string
    createdAt: string
    createdByUserId: string
    charges: PriceReviewCharge[]
}

export class PriceReview {
    public id: string
    public orderId: string
    public rawCad?: number
    public rawUsd?: number
    public totalCad: number
    public totalUsd: number
    public currency: Currency
    public updatedAt: string
    public createdAt: string
    public createdByUserId: string
    public charges: PriceReviewCharge[]

    constructor (props: PriceReviewProps) {
        this.id = props.id
        this.orderId = props.orderId
        this.rawCad = props.rawCad
        this.rawUsd = props.rawUsd
        this.totalCad = props.totalCad
        this.totalUsd = props.totalUsd
        this.currency = props.currency
        this.updatedAt = props.updatedAt
        this.createdAt = props.createdAt
        this.createdByUserId = props.createdByUserId
        this.charges = props.charges
    }
}
