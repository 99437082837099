import React from 'react'
import {
    Container,
    BigText,
    TealText,
    SmallText,
    PrimaryButton,
    // TextButton,
    // IconButton,
} from './QuoteFasterShipSmarterDesktop.styled'
import { useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'
import { Box } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './QuoteFasterShipSmarterDesktop.i18n'

export const QuoteFasterShipSmarterDesktop: React.FunctionComponent<QuoteFasterShipSmarterProps> = (props) => {
    const { t } = useI18n(i18n)
    const language = useSelector(reduxGetLanguage)

    return <Container>
        {language === 'EN' ?
            <BigText>
                {t('Quote')} <TealText>{t('faster')}</TealText>. {t('Ship')} <TealText>{t('smarter')}</TealText>.
            </BigText>
            :
            <BigText>
                <Box mb={8}>{t('Quote')} <TealText>{t('faster')}</TealText>.</Box>
                <div>{t('Ship')} <TealText>{t('smarter')}</TealText>.</div>
            </BigText>
        }

        <SmallText>{t('Start saving today with the most intelligent shipping platform for businesses.')}</SmallText>
        <div>
            <PrimaryButton size='large' href='/register'>{t('Get Lazr for free')}</PrimaryButton>
            {/* <TextButton variant='outlined' size='large' href='/'>{t('Talk to our team')}</TextButton> */}
        </div>

        {/* <IconButton preserveIconColors href='/'>
            <img alt='Help' src='/images/website/Ninja.svg'/>
        </IconButton> */}

    </Container>
}

export interface QuoteFasterShipSmarterProps{
    isMobile?: boolean
}
