/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './AuthMain.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Sign Up']: `Inscription`,
    ['Sign In']: 'Connexion',
    ['Register']: 'Inscription',
    ['Email']: 'Courriel',
    ['Password']: 'Mot de passe',
    ['Welcome back,']: 'Bon retour,',
    ['Welcome to Lazr']: 'Bienvenue sur Lazr',
    ['Create your Organization']: 'Créez votre Organisation',
    ['Create your Account']: 'Créez votre Compte',
    ['Register Your Account']: 'Créez votre compte',
}))()
