import React from 'react'
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`New efficiencies.`]: () => (<><span className='accentPrimary600'>New</span> efficiencies.</>),
    [`Zero friction.`]: `Zero friction.`,
    [`Power-up your operations and be the office hero. Our free shipping software is instantly deployable company-wide and designed for seamless collaboration with your colleagues.`]: `Power-up your operations and be the office hero. Our free shipping software is instantly deployable company-wide and designed for seamless collaboration with your colleagues.`,

    [`Customizable marketplace`]: `Customizable marketplace`,
    [`Already have carrier contracts in place? Add them into your personal search results to instantly compare your own contracts with our quotes.`]: `Already have carrier contracts in place? Add them into your personal search results to instantly compare your own contracts with our quotes.`,

    [`Modernize your operations`]: `Modernize your operations`,
    [`Bring your legacy systems up to speed with easy API integrations and EDI capabilities to make logistics communications more efficient than ever.`]: `Bring your legacy systems up to speed with easy API integrations and EDI capabilities to make logistics communications more efficient than ever.`,

    [`Built for the entire team`]: `Built for the entire team`,
    [`With enterprise-grade features, Lazr is purpose-built for collaboration, performance, and flexible monitoring. Anyone on your team can track, manage, and analyze shipments with ease.`]: `With enterprise-grade features, Lazr is purpose-built for collaboration, performance, and flexible monitoring. Anyone on your team can track, manage, and analyze shipments with ease.`,

    [`Free to use—forever`]: () => (<>Free to use—<span className='accentSecondary400'>forever</span></>),
    [`Pay no extra fees. Just create a free account and start shopping for quotes today.`]: `Pay no extra fees. Just create a free account and start shopping for quotes today.`,

    [`Get Lazr now`]: `Get Lazr now`,
    [`Visit our marketplace`]: `Visit our marketplace`,
}
