import { logger } from '../../logger'
import { ApiError, ErrorResponse, FindAddressesService } from '@lazr/openapi-client'
import { FindAddresses, FindAddressesRequest } from '../../model'
import { handleUnauthorizedException } from './index'
import { AddressValidatorName } from '@/app/model/FindAddresses'

export const FindAddressesApiService = Object.freeze({
    findAddresses: async (request: FindAddressesRequest, api: AddressValidatorName): Promise<FindAddresses[]> => {
        let FindAddressesResponse
        try {
            FindAddressesResponse = await FindAddressesService.findAddresses({ request, api })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('findAddresses', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve Find addresses responses')
            }
            throw new Error('Unable to retrieve Find addresses responses')
        }

        if (!FindAddressesResponse.data) {
            logger.debug('findAddresses',  JSON.stringify(FindAddressesResponse, null, 4))
            throw new Error('Unable to retrieve Find addresses responses')
        }

        return FindAddressesResponse.data
    },
})
