import React from 'react'
import { FormControlProps } from '@material-ui/core'
import { StyledFormControl } from '@/app/ui-new/components/Select/FormControl.styled'

const FormControl: React.FunctionComponent<FormControlProps> = (props: FormControlProps) => (
    <StyledFormControl
        {...props}
    >
        {props.children}
    </StyledFormControl>
)

export default FormControl
