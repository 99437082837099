import BaseBadge from '@/app/ui-new/components/Badge/Badge'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    Grid as MuiGrid,
    MenuItem as MuiMenuItem,
    Popover as MuiPopover,
    Typography as MuiTypography,
} from '@material-ui/core'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Menu = styled(MuiPopover)`
    z-index: 2147483645;

    & [class^='MuiPaper-root'] {
        position: fixed;
        top: 80px;
        right: 24px;
        width: 360px;
    }
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: ${(props): number => props.theme.palette.black.main};

    &:hover {
        color: ${(props): number => props.theme.palette.primary.main};
    }
`

export const Badge = styled(BaseBadge)`
    & [class^='MuiBadge-badge'] {
        position: absolute;
        top: -6px;
        right: -6px;
        color: ${(props): number => props.theme.palette.error700.contrastText};
        background-color: ${(props): number => props.theme.palette.error700.main};
    }
`

export const Typography = styled.div`
    user-select: none;
`

export const NotificationMenuTitle = styled(MuiTypography)`
    padding: ${(props): number => props.theme.spacing(4)}px;
`

export const NotificationMenuItem = styled(MuiMenuItem)`
    text-decoration: none;
    color: ${(props): string => props.theme.palette.text.primary};
    white-space: normal;
    width: 100%;
`

export const NotificationMessage = styled(MuiTypography)`
    font-size: 0.875rem;
`

export const NotificationTime = styled(MuiTypography)`
    font-size: 0.75rem;
`

export const MarkAllReadLink = styled(MuiTypography)`
    padding: ${(props): number => props.theme.spacing(4)}px;
    font-size: 1rem;
    cursor: pointer;
`

export const NotificationMenuItemViewAll = styled(MuiMenuItem)`
    padding: ${(props): number => props.theme.spacing(2)}px;
    text-decoration: none;
    color: ${(props): string => props.theme.palette.text.primary};
    justify-content: center;
    font-size: 1rem;
    width: 100%;
`
