/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './UserMenu.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Accountant']: 'Comptable',
    ['Admin']: 'Admin',
    ['Agent']: 'Agent',
    ['Billing']: 'Facturation',
    ['Guest Organization']: 'Organisation Invitée',
    ['Guest']: 'Invité',
    ['Imp:']: 'Imp:',
    ['Invite Members']: 'Inviter des membres',
    ['My Account']: 'Mon Compte',
    ['My Organization']: 'Mon Organisation',
    ['Orders']: 'Commandes',
    ['Org:']: 'Org:',
    ['Profile']: 'Profil',
    ['Role:']: 'Rôle:',
    ['Shipper']: 'Expéditeur',
    ['Sign Out']: 'Se Déconnecter',
    ['Supplier']: 'Fournisseur',
    ['User']: 'Utilisateur',
    ['Users']: 'Utilisateurs',
    ['Invoices']: 'Facturation'
}))()
