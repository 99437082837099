import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'

const reducer: Reducer = (state: string | null = null, action: AnyAction) => (
    action.type === types.SET_VERSION
        ? action.payload
        : state
)

export default reducer
