

import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const EchoLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/echo-logo.svg?v=${window.lazrVersion}`}
        name={'Echo Global Logistics'}
    />

export default EchoLogo
