import React from 'react'
import {
    Wrapper,
} from './QuoteFasterShipSmarterMobile.styled'
import { Box } from '@material-ui/core'
import {
    QuoteFasterShipSmarterMobile as QuoteFasterShipSmarter,
} from '../QuoteFasterShipSmarterMobile'


const QuoteFasterShipSmarterMobile: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={375}>
            <QuoteFasterShipSmarter/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default QuoteFasterShipSmarterMobile
