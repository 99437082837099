/* eslint-disable max-len */
import React, { useCallback, useRef } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import StepCardMobile from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Mobile/StepCardMobile'
import Stepper from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Stepper'
import { StyledSwipeableViews } from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Mobile/TestimonialsCarouselMobile.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './TestimonialsCarouselMobile.i18n'

const TestimonialsCarouselMobile: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const theme = useTheme()
    const [ activeStep, setActiveStep ] = React.useState(0)
    const intervalIdRef = useRef<number>()
    const maxSteps = 7
    const windowObject: Window = window
    const elementRef: React.RefObject<HTMLDivElement> = useRef(null)

    windowObject.onscroll = () => {
        if (elementRef.current && checkVisible(elementRef.current)) {
            if (!intervalIdRef.current) {
                intervalIdRef.current = window.setInterval(() => {
                    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps)
                }, 10000)
            }
        }
    }

    const checkVisible = (element: HTMLDivElement): boolean => {
        const rect = element.getBoundingClientRect()
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)

        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
    }

    const stopAnimation = useCallback(() => {
        window.clearInterval(intervalIdRef.current)
    }, [ intervalIdRef, window.clearInterval ])

    const handleStepChange = useCallback ((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    const handleStepperClick = useCallback((step: number) => {
        stopAnimation()
        setActiveStep(step)
    }, [ setActiveStep, stopAnimation ])

    React.useEffect(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }

        return (() => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        })
    }, [ intervalIdRef ])

    return (
        <Box
            width={'100%'}
            bgcolor={'#EBF3F3'}
            display={'flex'}
            justifyContent={'center'}
            // @ts-ignore
            ref={elementRef}
        >
            <Box display='flex' flexDirection='column' width={375} my={13} >
                <StyledSwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    onMouseDown={() => stopAnimation()}
                >
                    <StepCardMobile
                        key={1}
                        name={t('Dominique')}
                        jobTitle={t('Flooring industry')}
                        primaryContent={t('Bravo, excellent platform! It\'s hard to go back, because we are spoiled at LAZR by its efficiency, its practicality, and obviously the prices. You\'re going to make a lot of people happy here, especially in the flooring warehouse.')}
                    />
                    <StepCardMobile
                        key={2}
                        name={t('Tasia')}
                        jobTitle={t('Air filtration industry')}
                        primaryContent={t('Working with Lazr has been a great journey. Not only is their customer service more than top notch, but their user friendly site makes shipping anywhere as easy as a few clicks. Any issues that we\'ve run into have been addressed very promptly, avoiding any shipping hiccups. They\'ve also been great with dealing with third party companies on our behalf to ensure that shipments are picked up and delivered on time. 5-star service.')}
                    />
                    <StepCardMobile
                        key={3}
                        name={t('Amélie')}
                        jobTitle={t('Toy industry')}
                        primaryContent={t('I must say that your team is dedicated and very helpful. It has been a pleasure to work with each and every one of you, and your service is fantastic. Believe me, in 2022, it\'s almost impossible to come by.\nI believe it is important to highlight good work and a good partnership when you come across it.')}
                    />
                    <StepCardMobile
                        key={4}
                        name={t('Johanne')}
                        jobTitle={t('Heavy Machinery Manufacturer')}
                        primaryContent={t('We have used another shipping software for a few years before switching to Lazr.  Their software is much more complete and offers a better visibility all around.  Our customer were very happy with the way Lazr allows us to work.')}
                    />
                    <StepCardMobile
                        key={5}
                        name={t('Charles')}
                        jobTitle={t('Retail Distributor')}
                        primaryContent={t('Using Lazr, we managed to save thousands every month.  We have become aware of some freight expenses we did not know about and were able to take action to stop spending on services we did not need.')}
                    />
                    <StepCardMobile
                        key={6}
                        name={t('Stephan')}
                        jobTitle={t('Pulp and Paper manufacturer')}
                        primaryContent={t('We have 6 locations, Lazr allowed us to bring all the shipments together in one place.  We now have visibility across all our transportation volume for freight and parcel.')}
                    />
                    <StepCardMobile
                        key={7}
                        name={t('Matthew')}
                        jobTitle={t('Metal manufacturing')}
                        primaryContent={t('I appreciate your follow-ups, and the service you provide is always excellent! Thank you for all your support!')}
                    />
                </StyledSwipeableViews>
                <Box mt={12} display='flex' alignItems='center' justifyContent='center'>
                    <Stepper steps={maxSteps} activeStep={activeStep} onClick={(step: number) => handleStepperClick(step)}/>
                </Box>
            </Box>
        </Box>
    )
}

export default TestimonialsCarouselMobile
