import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Paper, PaperProps, Typography, TypographyProps } from '@material-ui/core'

interface CustomTypographyProps extends TypographyProps {
    $color?: string
}

export const StyledStepCard: React.FC<BoxProps> = styled(Box)<BoxProps>`
    overflow-y: hidden;
`

export const StyledPaper: React.FC<PaperProps> = styled(Paper)<PaperProps>`
    padding: ${(props): number => props.theme.spacing(3.5)}px;
    position: relative;
    width: 339px;
    height: 100%;
    border-radius: 8px;
    background: rgba(0,0,0,0.05)
`

export const StyledPrimaryContentTypography: React.FC<CustomTypographyProps> = styled(Typography)<CustomTypographyProps>`
    line-height: 120%;
    color: ${(props) => props.$color};
`

export const StyledJobTitleTypography: React.FC<CustomTypographyProps> = styled(Typography)<CustomTypographyProps>`
    color: ${(props) => props.$color ? props.$color : props.theme.palette.neutral500.main};
`

export const StyledNameTypography: React.FC<CustomTypographyProps> = styled(Typography)<CustomTypographyProps>`
    font-weight: 500;
    color: ${(props) => props.$color ? props.$color : props.theme.palette.neutral600.main};
`
