import { logger } from '../../logger'
import {
    EdiService, ApiError, ErrorResponse, TransportType, EquipmentType, EdiOrderStatus,
} from '@lazr/openapi-client'

import { EdiOrder, FilteredEdiOrders } from '../../model/EdiOrder'
import { OrderData } from '../../model/OrderData'
import { UuidV4 } from '@lazr/types'
import { EdiOrderDetails } from '@lazr/edi'

export type EdiOrderOrderByField = 'status' | 'createdAt' | 'expiresAt'
export type EdiOrderOrder = 'asc' | 'desc'

export declare enum WithOrderCreatedType {
    YES = 'Yes',
    NO = 'No',
}

export interface EdiOrderFilter {
    searchField?: string
    shipmentNumber?: string
    transactionNumber?: string
    poNumber?: string
    bolNumber?: string
    partnerEdiId?: string
    SCAC?: string
    clientOrganizationIds?: UuidV4[]
    threePlOrganizationIds?: UuidV4[]
    receivedDateFrom?: string
    receivedDateTo?: string
    expireDateFrom?: string
    expireDateTo?: string
    locationOrigins?: string[]
    locationDestinations?: string[]
    transportTypes?: TransportType[]
    equipmentTypes?: EquipmentType[]
    ediOrderStatuses?: EdiOrderStatus[]
    withOrderCreatedTypes?: WithOrderCreatedType[]
    orderBy?: EdiOrderOrderByField
    order?: EdiOrderOrder
}

export interface EdiOrderPaging {
    page?: number
    resultPerPage?: number
}

export const EdiApiService = Object.freeze({
    get: async (id: string): Promise<EdiOrder> => {
        let getEdiOrderByIdResponse
        try {
            getEdiOrderByIdResponse = await EdiService.getEdiOrderById(id)
        } catch (error: any) {
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve edi order by id')
            }
            throw new Error('Unable to retrieve edi order by id')
        }

        if (!getEdiOrderByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getEdiOrderByIdResponse, null, 4))
            throw new Error('Unable to retrieve edi order by id')
        }

        // @ts-ignore
        return new EdiOrder(getEdiOrderByIdResponse.data)
    },
    list: async (paging: EdiOrderPaging, filters?: EdiOrderFilter): Promise<FilteredEdiOrders> => {
        let getEdiOrdersResponse
        try {
            getEdiOrdersResponse = await EdiService.getEdiOrders(
                paging.page,
                paging.resultPerPage,
                filters?.searchField,
                filters?.shipmentNumber,
                //filters?.transactionNumber,
                filters?.poNumber,
                filters?.bolNumber,
                //filters?.partnerEdiId,
                filters?.SCAC,
                filters?.clientOrganizationIds,
                filters?.threePlOrganizationIds,
                filters?.receivedDateFrom,
                filters?.receivedDateTo,
                filters?.expireDateFrom,
                filters?.expireDateTo,
                filters?.locationOrigins,
                filters?.locationDestinations,
                filters?.transportTypes,
                //filters?.equipmentTypes,
                filters?.ediOrderStatuses,
                filters?.withOrderCreatedTypes,
                filters?.orderBy,
                filters?.order,
            )
        } catch (error: any) {
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve edi order list')
            }
            throw new Error('Unable to retrieve edi order list')
        }

        if (!getEdiOrdersResponse.data) {
            logger.debug('list', JSON.stringify(getEdiOrdersResponse, null, 4))
            throw new Error('Unable to retrieve edi order list')
        }

        return {
            ediOrders: getEdiOrdersResponse.data.ediOrders as EdiOrder[],
            total: getEdiOrdersResponse.data.paging.items,
        }
    },
    accept: async (id: string): Promise<void> => {
        let acceptEdiOrderResponse
        try {
            acceptEdiOrderResponse = await EdiService.acceptEdiOrder({ ediOrderId: id })
        } catch (error: any) {
            logger.debug('accept', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to accept edi order')
            }
            throw new Error('Unable to accept edi order')
        }

        if (!acceptEdiOrderResponse.data) {
            logger.debug('accept', JSON.stringify(acceptEdiOrderResponse, null, 4))
            throw new Error('Unable to accept edi order')
        }
    },
    decline: async (id: string): Promise<void> => {
        let declineEdiOrderResponse
        try {
            declineEdiOrderResponse = await EdiService.declineEdiOrder({ ediOrderId: id })
        } catch (error: any) {
            logger.debug('decline', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to decline edi order')
            }
            throw new Error('Unable to decline edi order')
        }

        if (!declineEdiOrderResponse.data) {
            logger.debug('decline', JSON.stringify(declineEdiOrderResponse, null, 4))
            throw new Error('Unable to decline edi order')
        }
    },
    transfer: async (id: string, threePlOrganizationId: string): Promise<void> => {
        let transferEdiOrderResponse
        try {
            transferEdiOrderResponse = await EdiService.transferEdiOrder({
                ediOrderId: id,
                threePlOrganizationId,
            })
        } catch (error: any) {
            logger.debug('transfer', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to transfer edi order')
            }
            throw new Error('Unable to transfer edi order')
        }

        if (!transferEdiOrderResponse.data) {
            logger.debug('transfer', JSON.stringify(transferEdiOrderResponse, null, 4))
            throw new Error('Unable to transfer edi order')
        }
    },
    getOrderData: async (id: string): Promise<OrderData> => {
        let getOrderDataResponse

        try {
            getOrderDataResponse = await EdiService.getEdiLazrOrderData(id)
        } catch (error: any) {
            logger.debug('getOrderData', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve edi order data')
            }
            throw new Error('Unable to retrieve edi order data')
        }

        if (!getOrderDataResponse.data) {
            logger.debug('getOrderData', JSON.stringify(getOrderDataResponse, null, 4))
            throw new Error('Unable to retrieve edi order data')
        }

        // @ts-ignore
        return new OrderData(getOrderDataResponse.data)
    },
    getEdiOrderDetails: async (id: string): Promise<EdiOrderDetails> => {
        let getEdiOrderDetailsResponse

        try {
            getEdiOrderDetailsResponse = await EdiService.getEdiOrderDetails(id)
        } catch (error: any) {
            logger.debug('getEdiOrderDetails', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve edi order details')
            }
            throw new Error('Unable to retrieve edi order details')
        }

        if (!getEdiOrderDetailsResponse.data) {
            logger.debug('getEdiOrderDetails', JSON.stringify(getEdiOrderDetailsResponse, null, 4))
            throw new Error('Unable to retrieve edi order details')
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return getEdiOrderDetailsResponse.data as EdiOrderDetails
    },
    getEdiBillingCodes: async (id: string): Promise<string[]> => {
        let getEdiBillingCodesResponse

        try {
            getEdiBillingCodesResponse = await EdiService.getEdiBillingCodes(id)
        } catch (error: any) {
            logger.debug('getEdiBillingCodes', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve edi order data')
            }
            throw new Error('Unable to retrieve edi billing codes')
        }

        if (!getEdiBillingCodesResponse.data) {
            logger.debug('getEdiBillingCodes', JSON.stringify(getEdiBillingCodesResponse, null, 4))
            throw new Error('Unable to retrieve edi billing codes')
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return getEdiBillingCodesResponse.data as string[]
    },
})
