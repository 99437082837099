import { UuidV4, OrganizationType } from '@lazr/openapi-client'
import { OtherOrganization, OtherOrganizationProps } from './OtherOrganization'
import { OtherUser, OtherUserProps } from './OtherUser'

export interface AccountManagerProps {
    id: UuidV4
    user: OtherUserProps
    organization: OtherOrganizationProps
    createdAt: string | null
}

export class AccountManager {
    public id: UuidV4
    public user: OtherUser
    public organization: OtherOrganization
    public createdAt: string | null

    constructor (props: AccountManagerProps) {
        this.id = props.id
        this.user = new OtherUser(props.user)
        this.organization = new OtherOrganization(props.organization)
        this.createdAt = props.createdAt
    }
}
