import styled from 'styled-components'
import {
    Box as MuiBox, Typography, TypographyProps,
} from '@material-ui/core'
import React from 'react'

export const Wrapper = styled(MuiBox)`
    width: 100%;
    background-color: ${(props): string => props.theme.palette.white.main};
    display: flex;
    justify-content: center;
    padding-top: ${(props): string => props.theme.spacing(12)}px;
    padding-bottom: ${(props): string => props.theme.spacing(12)}px;
`

export const StyledTitleTypography1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 140%;
    color: ${(props): string => props.theme.palette.neutral400.main};
`

export const StyledTitleTypography2: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 125%;
    color: ${(props): string => props.theme.palette.accentPrimary700.main};
`

export const StyledTitleTypography3: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 125%;
    color: ${(props): string => props.theme.palette.neutral800.main};
`

export const StyledContentTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 135%;
    color: ${(props): string => props.theme.palette.neutral700.main};
`
