/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`See Lazr in action`]: `See Lazr in action`,
    [`Get a free preview of Lazr’s customized Enterprise platform, totally commitment-free.`]: `Get a free preview of Lazr’s customized Enterprise platform, totally commitment-free.`,
    [`What can I expect?`]: `What can I expect?`,
    [`Shop & instantly compare the best shipping quotes from top carriers in North America, including exclusive deals from Lazr`]: `Shop & instantly compare the best shipping quotes from top carriers in North America, including exclusive deals from Lazr`,
    [`Track and manage your shipments across all carriers and services with our fully integrated shipping platform`]: `Track and manage your shipments across all carriers and services with our fully integrated shipping platform`,
    [`Ship faster and easier than ever—Lazr keeps your info securely saved to autofill forms and automate billing steps`]: `Ship faster and easier than ever—Lazr keeps your info securely saved to autofill forms and automate billing steps`,
}
