import React from 'react'
import {
    Wrapper,
} from './FlipCardsMobile.styled'
import { Box } from '@material-ui/core'
import { FlipCardsMobile as FlipCards } from '../FlipCardsMobile'


const FlipCardsMobile: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={375}>
            <FlipCards/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default FlipCardsMobile
