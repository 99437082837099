/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import { StyledStrong } from './index.styled'

export default {
    [`Missing default billing address`]: `Missing default billing address`,
    [`Please go to the address book and create the default billing address for your organization.`]: () => <>Please go to the address book and create the <StyledStrong>default billing address</StyledStrong> for your organization.</>,
    [`Please ask your administrator to create the default billing address for your organization.`]: () => <>Please ask your administrator to create the <StyledStrong>default billing address</StyledStrong> for your organization.</>,
    [`Go to Address Book`]: `Go to Address Book`,
}
