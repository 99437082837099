import { MarketPlace, MarketPlaceErrors } from '@/app/ui/definitions/MarketPlace'
import { Country, Currency as CurrencyCode } from '@lazr/openapi-client'
import { OrderInfoCustomsDetail, OrderInfoRfq } from '@/app/model'
import { deepCopy, isInternationalOrder } from '@lazr/utilities'
import { isAccessorialUsed } from '@/app/ui/lib/helpers'
import { BillDutiesToParty as BillDutiesToPartyCode } from '@lazr/openapi-client'
import { initialMarketPlace } from '@/app/ui/redux/store/InitialState'
import { completeOrderStepRFQFieldsValidator } from '../../../hooks/useFormValidator'
import { validateTaxIdRequirementPart } from '@/app/ui/pages/marketplace/components/helpers'
import { TransportType } from '@lazr/enums'

export const dhlCompleteOrderStepValidator = (
    marketPlace: MarketPlace,
    isSubmitting: boolean,
    country: Country,
): MarketPlaceErrors => {
    const rfq = marketPlace.order.rfq
    const order = marketPlace.order
    const customsDetails = marketPlace.order.rfq?.customsDetails
    const customsDetailErrors: Partial<Record<keyof OrderInfoCustomsDetail, string>>[] = [ {} ]
    const customsDetailIsDirty =  marketPlace.marketPlaceErrors.customsDetailIsDirty

    const handlingUnits = marketPlace.order.rfq?.handlingUnits
    const handlingUnitLinkErrors: string[] = []
    const handlingUnitLinkIsDirty = marketPlace.marketPlaceErrors.handlingUnitLinkIsDirty

    const rfqCustomsErrors: Partial<Record<keyof OrderInfoRfq, string>> = {}
    let rfqCustomsIsDirty = marketPlace.marketPlaceErrors.rfqCustomsIsDirty
    const requiredText = 'Required'

    const rfqErrors = completeOrderStepRFQFieldsValidator(marketPlace, isSubmitting, country)
    const isInternational = isInternationalOrder(rfq?.originCountry?.code, rfq?.destinationCountry?.code)
    const isTaxIdRequirementPartValidated = marketPlace.order.rfq && validateTaxIdRequirementPart(marketPlace.order.rfq)
    const isEnvelope = rfq?.transportType === TransportType.ENVELOPE

    if (isAccessorialUsed(rfq?.accessorials, 'HAZM')) {
        if (!rfq?.hazmatMode) {
            rfqErrors.hazmatMode = requiredText
        }
        if (!rfq?.hazmatClass) {
            rfqErrors.hazmatClass = requiredText
        }
    }

    if (isSubmitting) {
        rfqCustomsIsDirty = {
            incotermsType: true,
        }
    }

    if (!isInternational && !rfq?.incotermsType && rfqCustomsIsDirty.incotermsType) {
        rfqErrors.incotermsType = requiredText
    }

    if (!rfq?.documentsOnlyIndicator && isInternational) {
        if (isSubmitting) {
            rfqCustomsIsDirty = {
                billDutiesToParty: !isEnvelope,
                importExport: !isEnvelope,
                incotermsType: true,
                taxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.SENDER &&
                    isTaxIdRequirementPartValidated,
                consigneeTaxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code &&
                    [ BillDutiesToPartyCode.SENDER, BillDutiesToPartyCode.RECEIVER ]
                        .includes(marketPlace.order.rfq?.billDutiesToParty?.code) &&
                    isTaxIdRequirementPartValidated,
                thirdPartyTaxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY &&
                    isTaxIdRequirementPartValidated,
            }
        }

        if (rfq?.commercialInvoiceUserProvided) {
            if (order.documents?.length === 0) {
                rfqCustomsErrors.commercialInvoiceUserProvided = 'Missing commercial invoice document upload'
            }
        }

        if (!rfq?.incotermsType && rfqCustomsIsDirty.incotermsType) {
            rfqCustomsErrors.incotermsType = requiredText
        }

        if (rfq?.incotermsType) {
            rfqCustomsIsDirty.incotermsType = true
        }

        if (rfq?.commercialInvoiceDocumentIndicator && !rfq?.commercialInvoiceUserProvided) {
            if (!rfq?.billDutiesToParty && rfqCustomsIsDirty.billDutiesToParty) {
                rfqCustomsErrors.billDutiesToParty = requiredText
            }

            if (rfq?.billDutiesToParty) {
                rfqCustomsIsDirty.billDutiesToParty = true
            }

            if (!rfq?.importExport && rfqCustomsIsDirty.importExport) {
                rfqCustomsErrors.importExport = requiredText
            }

            if (rfq?.importExport) {
                rfqCustomsIsDirty.importExport = true
            }

            if (!rfq?.taxIdentificationNumber && rfqCustomsIsDirty.taxIdentificationNumber) {
                rfqCustomsErrors.taxIdentificationNumber = requiredText
            }

            if (rfq?.taxIdentificationNumber && isTaxIdRequirementPartValidated &&
                marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.SENDER) {
                rfqCustomsIsDirty.taxIdentificationNumber = true
            }

            if (!rfq?.consigneeTaxIdentificationNumber && rfqCustomsIsDirty.consigneeTaxIdentificationNumber) {
                rfqCustomsErrors.consigneeTaxIdentificationNumber = requiredText
            }

            if (rfq?.consigneeTaxIdentificationNumber && marketPlace.order.rfq?.billDutiesToParty?.code &&
                [ BillDutiesToPartyCode.SENDER, BillDutiesToPartyCode.RECEIVER ]
                    .includes(marketPlace.order.rfq?.billDutiesToParty?.code) && isTaxIdRequirementPartValidated) {
                rfqCustomsIsDirty.consigneeTaxIdentificationNumber = true
            }

            if (!rfq?.thirdPartyTaxIdentificationNumber && rfqCustomsIsDirty.thirdPartyTaxIdentificationNumber) {
                rfqCustomsErrors.thirdPartyTaxIdentificationNumber = requiredText
            }

            if (rfq?.thirdPartyTaxIdentificationNumber && isTaxIdRequirementPartValidated &&
                marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY) {
                rfqCustomsIsDirty.thirdPartyTaxIdentificationNumber = true
            }
        }

        if (rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY) {
            if (isSubmitting) {
                rfqCustomsIsDirty.dutyBillToAccountNumber = true
            }

            if (!rfq?.dutyBillToAccountNumber?.trim() && rfqCustomsIsDirty.dutyBillToAccountNumber) {
                rfqCustomsErrors.dutyBillToAccountNumber = requiredText
            }

            if (rfq?.dutyBillToAccountNumber?.trim()) {
                rfqCustomsIsDirty.dutyBillToAccountNumber = true
            }
        }

        let summaryHandlingUnitIds: string[] = []
        customsDetails?.forEach((customsDetail, index) => {
            if (!customsDetail) {
                return
            }

            summaryHandlingUnitIds = summaryHandlingUnitIds.concat(customsDetail.handlingUnitIds)

            if (isSubmitting) {
                customsDetailIsDirty[index] = {
                    description: !isEnvelope,
                    countryOfManufacture: !isEnvelope,
                    quantity: !isEnvelope,
                    unitValue: !isEnvelope,
                    unitWeight: !isEnvelope,
                    handlingUnitIds: !isEnvelope,
                    harmonizedCode: !isEnvelope,
                }
            }
            if (!customsDetailIsDirty[index]) {
                customsDetailIsDirty[index] = {}
            }
            customsDetailErrors[index] = {}

            if (!customsDetail.description?.trim() && customsDetailIsDirty[index].description) {
                customsDetailErrors[index].description = requiredText
            }
            if (customsDetail.description?.trim()) {
                customsDetailIsDirty[index].description = true
            }

            if (!customsDetail.countryOfManufacture &&
                customsDetailIsDirty[index].countryOfManufacture) {
                customsDetailErrors[index].countryOfManufacture = requiredText
            }
            if (customsDetail.countryOfManufacture) {
                customsDetailIsDirty[index].countryOfManufacture = true
            }

            if (!customsDetail.quantity && customsDetailIsDirty[index].quantity) {
                customsDetailErrors[index].quantity = requiredText
            }
            if (customsDetail.quantity) {
                customsDetailIsDirty[index].quantity = true
            }

            if (!customsDetail.unitValue && customsDetailIsDirty[index].unitValue) {
                customsDetailErrors[index].unitValue = requiredText
            }
            if (customsDetail.unitValue) {
                customsDetailIsDirty[index].unitValue = true
            }

            if (!customsDetail.unitWeight && customsDetailIsDirty[index].unitWeight) {
                customsDetailErrors[index].unitWeight = requiredText
            }
            if (customsDetail.unitWeight) {
                customsDetailIsDirty[index].unitWeight = true
            }

            if ((customsDetail.handlingUnitIds.length === 0) &&
                customsDetailIsDirty[index].handlingUnitIds) {
                customsDetailErrors[index].handlingUnitIds = requiredText
            }
            if (customsDetail.handlingUnitIds.length > 0) {
                customsDetailIsDirty[index].handlingUnitIds = true
            }
        })

        handlingUnits?.forEach((handlingUnit, index) => {
            handlingUnitLinkErrors[index] = ''
            if (isSubmitting) {
                handlingUnitLinkIsDirty[index] = !isEnvelope
            }


            if (!summaryHandlingUnitIds.some((id) => id === handlingUnit.id) &&
                handlingUnitLinkIsDirty[index]
            ) {
                handlingUnitLinkErrors[index] = 'Not linked error'
            }
            if (summaryHandlingUnitIds.some((id) => id === handlingUnit.id)) {
                handlingUnitLinkIsDirty[index] = true
            }
        })
    }

    return {
        rfqIsDirty: {},
        cargoIsDirty: [ {} ],
        rfqErrors,
        cargoErrors: deepCopy(initialMarketPlace.marketPlaceErrors.cargoErrors),
        customsDetailErrors: customsDetailErrors,
        customsDetailIsDirty,
        handlingUnitLinkIsDirty,
        handlingUnitLinkErrors,
        rfqCustomsErrors,
        rfqCustomsIsDirty,
        isSubmitted: true,
    }
}
