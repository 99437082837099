/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react'
import RequestDemoMobile from '@/app/ui/pages/components/RequestDemo/Mobile/RequestDemoMobile'
import RequestDemoDesktop from '@/app/ui/pages/components/RequestDemo/Desktop/RequestDemoDesktop'
import { Language } from '@lazr/enums'
import * as EmailValidator from 'email-validator'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'
import { Snackbar } from '@/app/ui-new/components/Snackbar/Snackbar'
import { sleep } from '@lazr/utilities'
import { Alert, AlertContent } from '@/app/ui-new/components/Alert/Alert'
import { getRequestDemoDialogOpen as reduxGetRequestDemoOpen } from '@/app/ui/redux/selectors/RequestDemoSelectors'
import { setRequestDemoDialogOpen as reduxSetRequestDemoOpen } from '@/app/ui/redux/actions/RequestDemoActions'
import { useMediaQuery } from '@material-ui/core'
import { RequestDemoApiService } from '@/app/service/ApiService/RequestDemoApiService'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './RequestDemo.i18n'
import { landingRoute } from '@/app/ui/routes'

declare const window: Window & { dataLayer: Record<string, unknown>[] }

export interface FormFields {
    firstName: string
    lastName: string
    email: string
    phone: string
    companyName: string
    industry: string
    volumeOfShipments: string
    preferredLanguage: string
    ltlLocal: boolean
    ltlNational: boolean
    ltlInternational: boolean
    ftl: boolean
    pak: boolean
    parcel: boolean
    customQuotes: boolean
    other: boolean
    accept: boolean
}

export interface FormFieldErrors {
    firstNameErrorText: string
    lastNameErrorText: string
    emailErrorText: string
    phoneErrorText: string
    companyNameErrorText: string
    industryErrorText: string
    preferredLanguageErrorText: string
    volumeOfShipmentsErrorText: string
    transportationTypeErrorText: string
    acceptErrorText: string
}

const formFieldErrorsInit = {
    firstNameErrorText: '',
    lastNameErrorText: '',
    emailErrorText: '',
    phoneErrorText: '',
    companyNameErrorText: '',
    industryErrorText: '',
    preferredLanguageErrorText: '',
    volumeOfShipmentsErrorText: '',
    transportationTypeErrorText: '',
    acceptErrorText: '',
}

const RequestDemo: React.FunctionComponent = () => {
    const language = useSelector(reduxGetLanguage)
    const { t } = useI18n(i18n)
    const isMobile = useMediaQuery('(max-width: 1230px)')
    const dispatch = useDispatch()

    const formFieldsInit = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        industry: '',
        volumeOfShipments: '',
        preferredLanguage: language === Language.EN ? 'English' : 'Français',
        ltlLocal: false,
        ltlNational: false,
        ltlInternational: false,
        ftl: false,
        pak: false,
        parcel: false,
        customQuotes: false,
        other: false,
        accept: false,
    }
    const [ formFields, setFormFields ] = useState<FormFields>(formFieldsInit)
    const [ formFieldErrors, setFormFieldErrors ] = useState<FormFieldErrors>(formFieldErrorsInit)
    const [ isFormValid, setIsFormValid ] = useState<boolean>(true)
    const [ snackbarOpen, setSnackbarOpen ] = useState(false)
    const [ snackbarOptionalProps, setSnackbarOptionalProps ] = useState({})
    const [ snackbarChild, setSnackbarChild ] = useState<JSX.Element | undefined>(undefined)
    const requestDemoOpen = useSelector(reduxGetRequestDemoOpen)

    const MISSING_FIELD_MESSAGE = t('This field is required')

    const closeDialog = () => {
        dispatch(reduxSetRequestDemoOpen(false))
    }

    const getParams = useCallback (() => {
        const transportationServicesUsed: string[] = []
        if (formFields.ltlLocal) {
            transportationServicesUsed.push('LTL (Local)')
        }

        if (formFields.pak) {
            transportationServicesUsed.push('Pak')
        }

        if (formFields.ltlNational) {
            transportationServicesUsed.push('LTL (National)')
        }

        if (formFields.parcel) {
            transportationServicesUsed.push('Parcel')
        }

        if (formFields.ltlInternational) {
            transportationServicesUsed.push('LTL (International)')
        }

        if (formFields.customQuotes) {
            transportationServicesUsed.push('Custom Quotes')
        }

        if (formFields.ftl) {
            transportationServicesUsed.push('FTL')
        }

        if (formFields.other) {
            transportationServicesUsed.push('Other')
        }

        return {
            email: formFields.email,
            firstName: formFields.firstName,
            lastName: formFields.lastName,
            phone: formFields.phone,
            company: formFields.companyName,
            preferredLanguage: formFields.preferredLanguage,
            industry: formFields.industry,
            volumeOfShipments: formFields.volumeOfShipments,
            manufacturerType: '',
            wholesaleType: '',
            transportationAndWarehousingType: '',
            retailType: '',
            transportationServicesUsed: transportationServicesUsed.join(','),
            consent: formFields.accept ? 'true' : 'false',
        }
    }, [ formFields ])

    const checkIfTransportationTypeFilled = useCallback (():boolean => (formFields.ltlLocal ||
            formFields.ltlNational ||
            formFields.ltlInternational ||
            formFields.ftl ||
            formFields.pak ||
            formFields.parcel ||
            formFields.customQuotes ||
            formFields.other),
    [ formFields ])

    const resetErrors = useCallback(() => {
        setFormFieldErrors(formFieldErrorsInit)
        setIsFormValid(true)
    }, [ setFormFieldErrors ])

    const resetFormFields = useCallback (() => {
        setFormFields(formFieldsInit)
    }, [ setFormFields ])

    const validateEmail = useCallback ((): string => {
        if (formFields.email === '') {
            return MISSING_FIELD_MESSAGE
        }

        if (!EmailValidator.validate(formFields.email)) {
            return t('Email is not a valid format')
        }

        return ''
    }, [ formFields ])

    const checkIfFormIsValid = useCallback ((): boolean => {
        const emailValidationResult = validateEmail()
        const fieldErrors: FormFieldErrors = {
            firstNameErrorText: formFields.firstName ? '' : MISSING_FIELD_MESSAGE,
            lastNameErrorText: formFields.lastName ? '' : MISSING_FIELD_MESSAGE,
            emailErrorText: emailValidationResult,
            phoneErrorText: formFields.phone ? '' : MISSING_FIELD_MESSAGE,
            companyNameErrorText: formFields.companyName ? '' : MISSING_FIELD_MESSAGE,
            industryErrorText: formFields.industry ? '' : MISSING_FIELD_MESSAGE,
            preferredLanguageErrorText: formFields.preferredLanguage ? '' : MISSING_FIELD_MESSAGE,
            volumeOfShipmentsErrorText: formFields.volumeOfShipments ? '' : MISSING_FIELD_MESSAGE,
            transportationTypeErrorText: checkIfTransportationTypeFilled() ? '' : MISSING_FIELD_MESSAGE,
            acceptErrorText: formFields.accept ? '' : MISSING_FIELD_MESSAGE,
        }

        setFormFieldErrors(fieldErrors)
        const isValid = !!(formFields.firstName &&
            formFields.lastName &&
            !emailValidationResult  &&
            formFields.phone &&
            formFields.companyName &&
            formFields.industry &&
            formFields.preferredLanguage  &&
            formFields.volumeOfShipments &&
            checkIfTransportationTypeFilled() &&
            formFields.accept)

        setIsFormValid(isValid)

        return isValid
    }, [
        setFormFieldErrors,
        formFields,
        validateEmail,
        checkIfTransportationTypeFilled,
        setIsFormValid,
    ])

    const submit = useCallback (async () => {
        const params = getParams()
        try {
            await RequestDemoApiService.create(
                params.email,
                params.firstName,
                params.lastName,
                params.phone,
                params.company,
                params.preferredLanguage,
                params.industry,
                params.volumeOfShipments,
                params.manufacturerType,
                params.wholesaleType,
                params.transportationAndWarehousingType,
                params.retailType,
                params.transportationServicesUsed,
                params.consent,
            )
            await openSnackbar('success')
            resetFormFields()
            closeDialog()

            window.dataLayer.push({
                event: window.location.pathname === landingRoute.path
                    ? 'landing_request_demo'
                    : 'website_request_demo',
                requestDemoEmail: params.email,
                requestDemoFirstName: params.firstName,
                requestDemoLastName: params.lastName,
                requestDemoPhone: params.phone,
                requestDemoCompany: params.company,
                requestDemoPreferredLanguage: params.preferredLanguage,
                requestDemoIndustry: params.industry,
                requestDemoVolumeOfShipments: params.volumeOfShipments,
                requestDemoManufacturerType: params.manufacturerType,
                requestDemoWholesaleType: params.wholesaleType,
                requestDemoTransportationAndWarehousingType: params.transportationAndWarehousingType,
                requestDemoRetailType: params.retailType,
                requestDemoTransportationServicesUsed: params.transportationServicesUsed,
                requestDemoConsent: params.consent,
            })
        } catch (error: any) {
            console.error('Request Demo', error.message)
            console.error('Request Demo', error.stack)
            console.error('Request Demo', error)
            await openSnackbar('error')

            window.dataLayer.push({
                event: window.location.pathname === landingRoute.path
                    ? 'landing_request_demo_failed'
                    : 'website_request_demo_failed',
                requestDemoEmail: params.email,
                requestDemoFirstName: params.firstName,
                requestDemoLastName: params.lastName,
                requestDemoPhone: params.phone,
                requestDemoCompany: params.company,
                requestDemoPreferredLanguage: params.preferredLanguage,
                requestDemoIndustry: params.industry,
                requestDemoVolumeOfShipments: params.volumeOfShipments,
                requestDemoManufacturerType: params.manufacturerType,
                requestDemoWholesaleType: params.wholesaleType,
                requestDemoTransportationAndWarehousingType: params.transportationAndWarehousingType,
                requestDemoRetailType: params.retailType,
                requestDemoTransportationServicesUsed: params.transportationServicesUsed,
                requestDemoConsent: params.consent,
                requestDemoErrorMessage: error.message,
            })
        }
    }, [ getParams, resetFormFields, language, closeDialog ])

    const handleSubmit = useCallback (async (event: any) => {
        event.preventDefault()
        resetErrors()

        if (checkIfFormIsValid()) {
            await submit()
        }
    }, [ resetErrors, checkIfFormIsValid, submit ])

    const handleInputChange = (name : keyof FormFields, value: string | boolean | null): void => {
        setFormFields ({ ...formFields, [name]: value })
    }

    const handleCheckboxChecked = (name : keyof FormFields): void => {
        setFormFields ({ ...formFields, [name]: !formFields[name] })
    }

    const closePreviousSnackbar = useCallback(async () => {
        setSnackbarOpen(false)
        await sleep(300)
    }, [ setSnackbarOpen ])

    const snackbarChildForSeverityError = <Alert
        severity='error'
        onClose={() => setSnackbarOpen(false)}
    >
        <AlertContent
            title={t('Error')}
            message={ t('Something went wrong. Please try again later.') }
        />
    </Alert>

    const snackbarChildForSeveritySuccess = <Alert
        severity='success'
        onClose={() => setSnackbarOpen(false)}
    >
        <AlertContent
            title={t('Success')}
            message={t('We will get in touch with you shortly.')}
        />
    </Alert>

    const openSnackbar = useCallback(async (type: 'error' | 'success') => {
        setSnackbarChild(type === 'error' ? snackbarChildForSeverityError : snackbarChildForSeveritySuccess)
        if (snackbarOpen) {
            await closePreviousSnackbar()
        }
        setSnackbarOptionalProps({})
        setSnackbarOpen(true)
    }, [ setSnackbarChild, snackbarOpen, closePreviousSnackbar, setSnackbarOptionalProps, setSnackbarOpen ])

    React.useEffect(() => {
        setFormFields ({ ...formFields, ['preferredLanguage']: language === Language.EN ? 'English' : 'Français' })
    }, [ language ])

    return <>
        {isMobile ?
            <RequestDemoMobile
                isRequestDemoOpen={requestDemoOpen}
                closeDialog={closeDialog}
                openSnackbar={openSnackbar}
                formFields={formFields}
                formFieldErrors={formFieldErrors}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isFormValid={isFormValid}
                resetFormFields={resetFormFields}
                resetErrors={resetErrors}
                handleCheckboxChecked={handleCheckboxChecked}
            /> :
            <RequestDemoDesktop
                isRequestDemoOpen={requestDemoOpen}
                closeDialog={closeDialog}
                openSnackbar={openSnackbar}
                formFields={formFields}
                formFieldErrors={formFieldErrors}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isFormValid={isFormValid}
                resetFormFields={resetFormFields}
                resetErrors={resetErrors}
                handleCheckboxChecked={handleCheckboxChecked}
            />}
        <Snackbar
            open={snackbarOpen}
            onClose={() => {setSnackbarOpen(false)}}
            {...snackbarOptionalProps}
        >
            {snackbarChild}
        </Snackbar>
    </>
}

export default RequestDemo
