import styled from 'styled-components'

import StyledNumberField from '@/app/ui-new/components/NumberField/NumberField'
import { Grid } from '@material-ui/core'

export const GridLabel = styled(Grid)`
& div >  label{
    color:black;
}
`

interface NumberFieldProps {
    width?: string
}
export const NumberField: React.FC<NumberFieldProps> = styled(StyledNumberField)<NumberFieldProps>`
    width: ${(props): string => (props.width ? props.width : '100px')} ;
    & > [class^="MuiInputBase-root"]{
        
      
        height: ${(props): string => (props.size === 'small' ? '40px' : '67px')};
        width: ${(props): string => (props.width ? props.width : '100px')} ;
        font-size: 1.5rem;

        & > input{
            ${(props): string => (props.size === 'small' ? 'font-size:14px ' : '16px')};
        }
        &.Mui-disabled {
            background:rgba(16,210,194,0.12);
            input {
                color: black;
                padding-top: ${(props): number => props.theme.spacing(2.5)}px;
            }

            fieldset {
                border-color: ${(props): number => props.theme.palette.neutral300.main};
            }

            

        }

    }

    
    & > [class^="MuiFormLabel-root"] {

        &.Mui-disabled {
            color: ${(props): number => props.theme.palette.neutral500.main};
        }
    }
`
