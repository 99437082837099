import styled from 'styled-components'
import { Alert as MuiAlert } from '@material-ui/lab'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'

export const Alert = styled(styled(MuiAlert)(MuiSpacing))`
    color: ${(props): number => props.theme.palette.black.main};
    width: 440px;
    border-radius: 0;
    padding: ${(props): string => props.theme.spacing(6)}px;
    justify-content: space-between;

    & > div{
        &:nth-child(1){
            margin-right: 24px;
        }

        &:nth-child(2){
            padding: ${(props): string => props.theme.spacing(2)}px 0 0 0;
            margin-right: ${(props): string => props.theme.spacing(6)}px;
            width: 100%;

            & > h6.alert-title{
                padding-bottom: ${(props): string => props.theme.spacing(2)}px;
            }
        }

        /* Close icon */
        &:nth-child(3){
            width: 24px;
            display: block;
            margin-left: ${(props): string => props.theme.spacing(6)}px;
            margin-top: 3px;
            margin-right: unset;
            padding: 0;

            & svg{
                font-size: 22px;
            }
        }
    }

    /* Colors for icon and border depending on the severity */
    &.alert-info{
        border: 1px solid ${(props): number => props.theme.palette.info300.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.info600.main};
        }
    }
    &.alert-success{
        border: 1px solid ${(props): number => props.theme.palette.success300.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.success600.main};
        }
    }
    &.alert-warning{
        border: 1px solid ${(props): number => props.theme.palette.warning600.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.warning900.main};
        }
    }
    &.alert-error{
        border: 1px solid ${(props): number => props.theme.palette.error200.main};
        & div > svg{
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing))``
