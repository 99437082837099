/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './MostReusableTranslation.i18n.en'
import type { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'Handling Unit': 'Unités de manutention',
    Filters: 'Filtres',
    'Start Tour': 'Tour initial',
    'Transport Type': 'Type de transport',
    'Select the carrier': 'Sélectionnez le transporteur',
    Currency: 'Monnaie',
    Quantity: 'Quantité',
    'Quantity required': 'Quantité requise',
    'Quantity must be at least qtd characters long': 'La quantité doit comporter au moins quantité caractères',
    'Carrier Code': 'Code du transporteur',
    Location: 'Emplacement',
    'Service Level Code': 'Code de niveau de service',
    Timezone: 'Fuseau horaire',
    'Open Time': "Heure d'ouverture",
    'Close Time': 'Heure de fermeture',
    Date: 'Date',
    'is required': 'est requise',
    completed: 'completed',
    'Is Residential?': 'Est-ce Résidentiel?',
    Yes:'Oui',
    Carrier: 'Transporteur',
    Packages: 'Forfaits',
    'Total Weight': 'Poids Total',
    'All packages':'Tous les colis',
    'Open Time must be earlier than Close Time': "Le temps d'ouverture doit être antérieur au temps de fermeture",
    'The date must be greater than the current date':"La date doit être ultérieure à la date actuelle"
}))()
