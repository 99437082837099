import { logger } from '../../logger'
import {
    ApiError,
    ErrorResponse,
    MarketplaceStepNavigationService,
    UuidV4,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'
import { MarketplaceStepName } from '@lazr/enums'

interface MarketplaceStepNavigationParams {
    entityId?: UuidV4
    stepName: MarketplaceStepName
    orderId: UuidV4
}

export const MarketPlaceStepNavigationApiService = Object.freeze({
    record: async (marketplaceStepNavigationParams: MarketplaceStepNavigationParams): Promise<void> => {
        try {
            await MarketplaceStepNavigationService.recordMarketplaceStepNavigation({
                entityId: marketplaceStepNavigationParams.entityId,
                stepName: marketplaceStepNavigationParams.stepName,
                orderId: marketplaceStepNavigationParams.orderId,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('record', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to record marketplace step navigation')
            }
            throw new Error('Unable to record marketplace step navigation')
        }
    },
})
