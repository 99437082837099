/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './OldVsNewWayMobile.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Ship`]: `Expédiez`,
    [`better`]: `plus`,
    [`in every way`]: `facilement`,
    [`Traditional shipping takes too long and costs too much`]: `L'expédition traditionnelle prend trop de temps et coûte trop cher`,
    [`Get Lazr to take care of all your cross-border shipping needs and more`]: `Lazr prend en charge tous vos besoins en matière d'expédition transfrontalière et plus encore`,
    [`minutes to complete booking, documentation and billing`]: `minutes pour compléter la réservation, la documentation et la facturation`,
    [`Limited options from one or two carriers`]: `Options limitées à un ou deux fournisseurs`,
    [`Scattered shipment tracking`]: `Suivi des envois dispersés et long à gérer`,
    [`Multiple accounts with different logins`]: `Plusieurs comptes avec des identifiants différents`,
    [`Less than 3 minutes to complete booking, documentation and billing`]: `Moins de 3 minutes pour compléter la réservation, la documentation et la facturation`,
    [`Instant shipping rates from dozens of carriers`]: `Tarifs d'expédition instantanés de dizaines de transporteurs`,
    [`A single tracking view of all shipments, everywhere`]: `Une seule vue pour tous les envois de n'importe où`,
    [`All freight & parcel carriers in one place`]: `Tous les transporteurs de marchandises sur palettes et de colis en un seul endroit`,
    [`Connected freight carriers`]: `Transporteurs de marchandises connectés`,
    [`Connected parcel carriers`]: `Transporteurs de colis connectés`,
    [`Average savings in shipping costs`]: `Économies moyennes sur les frais d'expédition`,
    [`Faster than traditional quoting`]: `Plus rapide que les demandes traditionnelles de transport`,
    [`Lazr Way`]: `La méthode Lazr`,
    [`Old Way`]: `Ancienne méthode`,
}))()
