/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse_GetGmxArchiveResponse_ } from '../models/SuccessResponse_GetGmxArchiveResponse_';
import type { SuccessResponse_GetGmxRegistrationInfoResponse_ } from '../models/SuccessResponse_GetGmxRegistrationInfoResponse_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class GmxService {

    /**
     * Get GMX archived orders
     * @param page
     * @param resultsPerPage
     * @param orderBy
     * @param order
     * @param trackingNumber
     * @param shippingDateFrom
     * @param shippingDateTo
     * @returns SuccessResponse_GetGmxArchiveResponse_ OK
     * @throws ApiError
     */
    public static async getGmxArchive(
        page?: number,
        resultsPerPage?: number,
        orderBy?: 'trackingNumber' | 'senderName' | 'recipientName' | 'weight' | 'shippingDate',
        order?: 'asc' | 'desc',
        trackingNumber?: string,
        shippingDateFrom?: string,
        shippingDateTo?: string,
    ): Promise<SuccessResponse_GetGmxArchiveResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/gmx-archive`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'orderBy': orderBy,
                'order': order,
                'trackingNumber': trackingNumber,
                'shippingDateFrom': shippingDateFrom,
                'shippingDateTo': shippingDateTo,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get GMX organization registration information
     * @param id The id
     * @returns SuccessResponse_GetGmxRegistrationInfoResponse_ OK
     * @throws ApiError
     */
    public static async getGmxRegistrationInfo(
        id: UuidV4,
    ): Promise<SuccessResponse_GetGmxRegistrationInfoResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/gmx-registration-info/${id}`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}