/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './RegisterOrganization.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Missing required fields`]: `Champs obligatoires manquants`,
    [`Error`]: `Erreur`,
    [`Something went wrong. Please try again later.`]: `Veuillez réessayer plus tard.`,
    [`Success`]: `Succès`,
    [`We will get in touch with you shortly.`]: `Attendez-vous à être contacté par l'un de nos représentants très bientôt.`,
}))()
