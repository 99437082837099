import React from 'react'

const IconLogo: React.FunctionComponent<Props> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Lazr Icon Logo"
        viewBox="0 0 360 360"
        className={className}
    >
        {/* eslint-disable-next-line max-len */}
        <path d="M251.22 70v-.05H72.17v60.86h107.37L72.13 243.26h-.01v47.36h45.83v-.01l107.28-110.73v110.77h61.68V70h-35.69z"/>
    </svg>
)

export interface Props {
    className?: string
}

export default IconLogo
