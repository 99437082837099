import { AppTheme } from '../definitions/Theme'
import {lightVariant as newLightVariant} from "./newDesignVariants"
const lightText = {
    primary: '#FFFFFF', // 100% opacity
    secondary: '#FFFFFFB3', // 70% opacity
    disabled: '#FFFFFF80', // 50% opacity
}

const darkText = {
    primary: '#000000DE', // 87% opacity
    secondary: '#0000008A', // 54% opacity
    disabled: '#00000061', // 38% opacity
}

const lightVariant: AppTheme = {
    name: 'Light',
    palette: {
        ...newLightVariant.palette,
        // Material UI overrides
        type: 'light',
        primary: {
            main: '#12151F',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#252b3b',
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
            // contrastText: will be calculated to contrast with palette.secondary.main
        },
        error: {
            main: '#F44336',
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
            // contrastText: will be calculated to contrast with palette.error.main
        },
        warning: {
            main: '#ff9800',
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
            // contrastText: will be calculated to contrast with palette.warning.main
        },
        info: {
            main: '#2196f3',
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
            // contrastText: will be calculated to contrast with palette.info.main
        },
        success: {
            main: '#0DD994',
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
            // contrastText: will be calculated to contrast with palette.success.main
        },
        // Custom stuff below
        white: {
            main: '#FFFFFF',
            contrastText: darkText.primary,
        },
        appbar: {
            main: '#000',
            contrastText: lightText.primary,
        },
        accent1: {
            main: '#12151F',
            contrastText: lightText.primary,
        },
        accent2: {
            main: '#607D8B',
            contrastText: darkText.primary,
        },
        navyBlue: {
            main: '#12151F',
            contrastText: lightText.primary,
        },
        teal: {
            main: '#0DD994',
            contrastText: darkText.primary,
        },
        selectedPaperBackground: {
            main: '#EBEEF8',
            contrastText: darkText.primary,
        },
        darkGreen: {
            main: '#0b850b',
            contrastText: darkText.primary,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
}

const variants: AppTheme[] = [
    lightVariant,
]

export default variants
