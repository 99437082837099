import { StepButton, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Step, Stepper } from './ProgressSummaryStepper.styled'
import { MARKETPLACE_ROUTES } from '../../../lib/constants'
import { MarketPlaceStep } from '../../../definitions/MarketPlace'

const getSteps = (): string[] => [ 'Shipment Info', 'Marketplace', 'Complete order', 'Confirmation' ]

const ProgressSummaryStepper: React.FunctionComponent<Props> = ({
    activeStep,
    lastCompletedStep,
    className,
    orderId,
}) => {
    const steps = getSteps()

    const theme: Theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.only('xs'))

    const history = useHistory()

    const handleStep = (step: MarketPlaceStep) => (): void => {
        if (step === activeStep || step > lastCompletedStep + 1 || !MARKETPLACE_ROUTES[step]) {
            return
        }
        history.push(`${MARKETPLACE_ROUTES[step]}/${orderId ?? ''}`)
    }

    return <Stepper p={0} activeStep={activeStep} alternativeLabel nonLinear className={className}>
        {steps.map((label, step) => (
            <Step key={step} $isDisabled={step > lastCompletedStep + 1}>
                <StepButton
                    completed={step <= lastCompletedStep}
                    onClick={handleStep(step)}
                    disabled={step > lastCompletedStep + 1}
                >
                    {isXs ?
                        <Typography variant='caption'>{label}</Typography>
                        :
                        label
                    }
                </StepButton>
            </Step>
        ))}
    </Stepper>
}
export interface Props {
    activeStep: number
    lastCompletedStep: number
    className?: string
    orderId: string | null
}

export default ProgressSummaryStepper
