import { Overrides } from '@material-ui/core/styles/overrides'

// TODO #143: Change the "any" type to actual overrides from pickers
const overrides: Overrides & any = {
    MuiTooltip: {
        tooltip: {
            fontSize: '0.8rem',
            fontFamily: 'Nunito',
            backgroundColor: '#12151fe6', // accent1 color at 90% opacity
            padding: '0.5rem',
        },
        arrow: {
            color: '#12151fe6', // accent1 color at 90% opacity
        },
    },
    MuiButton: {
        root: {
            padding: '12px 10px',
        },
        text: {
            padding: '12px 10px',
        },
        sizeSmall: {
            padding: '8px 10px',
        },
        sizeLarge: {
            padding: '16px 10px',
        },
    },
    MuiCardHeader: {
        action: {
            marginTop: '-4px',
            marginRight: '-4px',
        },
    },
    MuiTableRow: {
        root: {
            '&:last-child td': {
                borderBottom: 0,
            },
        },
    },
    MuiPickersModal: {
        dialogRoot: {
            '& > .MuiDialogActions-root': {
                display: 'none',
            },
        },
    },
    MuiPickersDay: {
        day: {
            fontWeight: '300',
        },
    },
    MuiPickersYear: {
        root: {
            height: '64px',
        },
    },
    MuiPickersCalendar: {
        transitionContainer: {
            marginTop: '6px',
        },
    },
    MuiPickersCalendarHeader: {
        iconButton: {
            backgroundColor: 'transparent',
            '& > *': {
                backgroundColor: 'transparent',
            },
        },
        switchHeader: {
            marginTop: '2px',
            marginBottom: '4px',
        },
    },
    MuiPickersClock: {
        container: {
            margin: '32px 0 4px',
        },
    },
    MuiPickersClockNumber: {
        clockNumber: {
            left: 'calc(50% - 16px)',
            width: '32px',
            height: '32px',
        },
    },
    MuiPickerDTHeader: {
        dateHeader: {
            '& h4': {
                fontSize: '2.125rem',
                fontWeight: 400,
            },
        },
        timeHeader: {
            '& h3': {
                fontSize: '3rem',
                fontWeight: 400,
            },
        },
    },
    MuiPickersTimePicker: {
        hourMinuteLabel: {
            '& h2': {
                fontSize: '3.75rem',
                fontWeight: 300,
            },
        },
    },
    MuiPickersToolbar: {
        toolbar: {
            '& h4': {
                fontSize: '2.125rem',
                fontWeight: 400,
            },
        },
    },
}

export default overrides
