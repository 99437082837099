/* eslint-disable @typescript-eslint/no-unsafe-argument */
import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { transparentize } from 'polished'
import { IconButton as IconButtonStyled } from '@/app/ui-new/components/IconButton/IconButton'
import TextButton from '@/app/ui-new/components/TextButtons/TextButton'

export const PlayButtonText = styled(styled(TextButton)(spacing))`
    font-weight: 500;
    font-size: 0.8571rem;
    background-color: ${(props): string => transparentize(0.2, props.theme.palette.white.main)};
    color: ${(props): string => props.theme.palette.black.main};

    &:hover{
        background-color: ${(props): string => transparentize(0.05, props.theme.palette.white.main)};
    }
`

export const IconButton = styled(IconButtonStyled)`
    background-color: transparent;
    border: 0;
    width: 60px;
    height: 60px;
`
