/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Dominique`]: `Dominique`,
    [`Flooring industry`]: `Industrie du couvre-plancher`,
    [`Bravo, excellent platform! It's hard to go back, because we are spoiled at LAZR by its efficiency, its practicality, and obviously the prices. You're going to make a lot of people happy here, especially in the flooring warehouse.`]: `Bravo, excellente plateforme! C'est dur de retourner en arrière, parce qu'on est gâtés chez LAZR par son efficacité, son pratique et évidemment les prix. Vous allez faire des heureux chez nous, surtout à l'entrepôt des couvre-plancher.`,
    [`Tasia`]: `Tasia`,
    [`Air filtration industry`]: `Industrie de la filtration d'air`,
    [`Working with Lazr has been a great journey. Not only is their customer service more than top notch, but their user friendly site makes shipping anywhere as easy as a few clicks. Any issues that we've run into have been addressed very promptly, avoiding any shipping hiccups. They've also been great with dealing with third party companies on our behalf to ensure that shipments are picked up and delivered on time. 5-star service.`]: `Travailler avec Lazr a été une excellente expérience. Non seulement leur service à la clientèle est plus que remarquable, mais leur site facile à utiliser rend l'expédition partout aussi simple que quelques clics. Les problèmes auxquels nous avons été confrontés ont été résolus très rapidement, évitant ainsi tout problème d'expédition. Ils ont également été excellents pour traiter avec des entreprises tierces en notre nom afin de s'assurer que les envois sont récupérés et livrés à temps. Un service cinq étoiles.`,
    [`Amélie`]: `Amélie`,
    [`Toy industry`]: `Industrie du jouet`,
    [`I must say that your team is dedicated and very helpful. It has been a pleasure to work with each and every one of you, and your service is fantastic. Believe me, in 2022, it's almost impossible to come by.\nI believe it is important to highlight good work and a good partnership when you come across it.`]: `Je dois dire que votre équipe est dévouée et très serviable. Ce fut un plaisir de travailler avec chacun d'entre vous et votre service est formidable et croyez-moi, en 2022, c'est presque impossible à obtenir.\nJe crois qu'il est important de souligner le bon travail et le bon partnership quand on le croise.`,
    [`Matthew`]: `Matthew`,
    [`Metal manufacturing`]: `Manufacturier de produits de métal`,
    [`I appreciate your follow-ups, and the service you provide is always excellent! Thank you for all your support!`]: `J'apprécie vos suivis et le service que vous offrez est toujours excellent!  Merci pour tout votre soutien!`,
    ['Johanne']: 'Johanne',
    ['We have used another shipping software for a few years before switching to Lazr.  Their software is much more complete and offers a better visibility all around.  Our customer were very happy with the way Lazr allows us to work.']: `Nous avons utilisé un autre logiciel d'expédition pendant quelques années avant de passer à Lazr. Leur logiciel est beaucoup plus complet et offre une meilleure visibilité. Nos clients étaient très satisfaits de la façon dont Lazr nous permet de travailler.`,
    ['Heavy Machinery Manufacturer']: 'Fabricant de machinerie lourde',
    ['Charles']: 'Charles',
    ['Retail Distributor']: 'Distributeur de détail',
    ['Using Lazr, we managed to save thousands every month.  We have become aware of some freight expenses we did not know about and were able to take action to stop spending on services we did not need.']: `En utilisant Lazr, nous avons réussi à économiser des milliers de dollars chaque mois. Nous avons pris conscience de certaines dépenses de fret dont nous n'avions pas connaissance et avons pu agir pour arrêter de dépenser pour des services dont nous n'avions pas besoin.`,
    ['Stephan']: 'Stephan',
    ['Pulp and Paper manufacturer']: 'Fabricant de pâtes et de papiers',
    ['We have 6 locations, Lazr allowed us to bring all the shipments together in one place.  We now have visibility across all our transportation volume for freight and parcel.']: `Nous avons 6 usines et Lazr nous a permis de regrouper tous les envois en un seul endroit. Nous avons maintenant une visibilité sur l'ensemble de notre volume de transport pour les marchandises et les colis.`,
}))()
