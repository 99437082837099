/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import { StyledStrong } from './index.styled'

export default ((): Translations<typeof en> => ({
    [`Missing default billing address`]: `Adresse de facturation par défaut manquante`,
    [`Please go to the address book and create the default billing address for your organization.`]: () => <>Veuillez créer <StyledStrong>l&lsquo;adresse de facturation par défaut</StyledStrong> pour votre organisation.</>,
    [`Please ask your administrator to create the default billing address for your organization.`]: () => <>Veuillez demander à votre administrateur de créer <StyledStrong>l&lsquo;adresse de facturation par défaut</StyledStrong> pour votre organisation.</>,
    [`Go to Address Book`]: `Accéder au carnet d'adresses`,
}))()
