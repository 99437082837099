import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Paper, Typography, TypographyProps } from '@material-ui/core'
import { StyledPaperProps } from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Mobile/StepCardMobile'

export const StyledPaper: React.FC<StyledPaperProps> = styled(Paper)<StyledPaperProps>`
    height: ${(props): number => props.language === 'EN' ? 561 : 638}px;
    width: 100%;
    border-radius: 8px;
    padding: ${(props): number => props.theme.spacing(4)}px;
`
export const StyledStepCard: React.FC<BoxProps> = styled(Box)<BoxProps>`
    overflow: hidden;
    height: 100%;
    width: 100%;
`

export const StyledPrimaryContentTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 120%;
`

export const StyledJobTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 0.875rem;
    line-height: 140%;
    color: ${(props): number => props.theme.palette.neutral500.main};
`

export const StyledNameTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: ${(props): number => props.theme.palette.neutral600.main};
`
