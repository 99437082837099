/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ShipmentIdentifierType {
    PICKUP_NUMBER = 'PICKUP_NUMBER',
    PRO_NUMBER = 'PRO_NUMBER',
    BILL_OF_LADING_NUMBER = 'BILL_OF_LADING_NUMBER',
    PURCHASE_ORDER_NUMBER = 'PURCHASE_ORDER_NUMBER',
    TRACKING_NUMBER = 'TRACKING_NUMBER',
    PRO_BILL_NUMBER = 'PRO_BILL_NUMBER',
    CUSTOMER_REFERENCE = 'CUSTOMER_REFERENCE',
    EXTERNAL = 'EXTERNAL',
    OTHER = 'OTHER',
    PIN_NUMBER = 'PIN_NUMBER',
    LOCATION_NUMBER = 'LOCATION_NUMBER',
    REFERENCE = 'REFERENCE',
    MANIFEST_NUMBER = 'MANIFEST_NUMBER',
    CONSIGNOR_REFERENCE = 'CONSIGNOR_REFERENCE',
    FREIGHT_FORWARDER_REFERENCE = 'FREIGHT_FORWARDER_REFERENCE',
    FREIGHT_BILL_NUMBER = 'FREIGHT_BILL_NUMBER',
    INBOUND_CENTER_REFERENCE = 'INBOUND_CENTER_REFERENCE',
    LOAD_LIST_REFERENCE = 'LOAD_LIST_REFERENCE',
    OUTBOUND_CENTER_REFERENCE = 'OUTBOUND_CENTER_REFERENCE',
    LOCAL_PAYOR_ACCOUNT_NUMBER = 'LOCAL_PAYOR_ACCOUNT_NUMBER',
    LOCAL_SHIPPER_ACCOUNT_NUMBER = 'LOCAL_SHIPPER_ACCOUNT_NUMBER',
    LOCAL_RECEIVER_ACCOUNT_NUMBER = 'LOCAL_RECEIVER_ACCOUNT_NUMBER',
    CUSTOMS_DECLARATION_NUMBER = 'CUSTOMS_DECLARATION_NUMBER',
    SHIPMENT_ID = 'SHIPMENT_ID',
}