import React from 'react'
import { Box, PaperProps } from '@material-ui/core'
import {
    StyledStepCard,
    StyledPaper,
    StyledPrimaryContentTypography, StyledNameTypography, StyledJobTitleTypography,
} from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Desktop/StepCardDesktop.styled'
import { useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'

const StepCardDesktop: React.FunctionComponent<StepCardDesktopProps> = (props: StepCardDesktopProps) => {
    const language = useSelector(reduxGetLanguage)

    return (
        <StyledPaper elevation={0} language={language}>
            <StyledStepCard display='flex' flexDirection='row' justifyContent='space-between' height={'100%'}>
                <Box display='flex' flexDirection='row'>
                    <img src={`/images/website/carousel/Quotes.svg?v=${window.lazrVersion}`} height={38} alt={'Quotes'}/>
                    <Box ml={12} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                        <Box mb={9}>
                            <StyledPrimaryContentTypography variant='h4'>{props.primaryContent}</StyledPrimaryContentTypography>
                        </Box>
                        <Box>
                            <Box mb={1}>
                                <StyledNameTypography variant='body1'>
                                    {props.name}
                                </StyledNameTypography>
                            </Box>
                            <StyledJobTitleTypography variant='body2'>
                                {props.jobTitle}
                            </StyledJobTitleTypography>
                        </Box>
                    </Box>
                </Box>
            </StyledStepCard>
        </StyledPaper>
    )
}

export interface StepCardDesktopProps {
    primaryContent: string
    name: string
    jobTitle: string
}

export interface StyledPaperProps extends PaperProps {
    language: string
}

export default StepCardDesktop
