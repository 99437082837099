import { AppTheme } from '../definitions/NewDesignTheme'

const lightText = {
    primary: '#FFFFFF', // 100% opacity
    secondary: '#FFFFFFB3', // 70% opacity
    disabled: '#FFFFFF80', // 50% opacity
}

const darkText = {
    primary: '#000000DE', // 87% opacity
    secondary: '#0000008A', // 54% opacity
    disabled: '#00000061', // 38% opacity
}

const TEAL = {
    900: '#005457',
    800: '#006664',
    700: '#007C7A',
    600: '#019592',
    500: '#01A299',
    400: '#00B3A6',
    300: '#10D2C2',
    200: '#74E0D4',
    100: '#AFEBE5',
    50:  '#DFF7F5',
}

const PURPLE = {
    900: '#2E1065',
    800: '#440D96',
    700: '#5811C4',
    600: '#6413DE',
    500: '#7019E4',
    400: '#8847EA',
    300: '#9F6BEE',
    200: '#BC98F2',
    100: '#D7C1F7',
    50:  '#EAE8FD',
}

const GREEN = {
    900: '#1B5E20',
    800: '#2E7D32',
    700: '#388E3C',
    600: '#43A047',
    500: '#4CAF50',
    400: '#66BB6A',
    300: '#81C784',
    200: '#A5D6A7',
    100: '#C8E6C9',
    50:  '#E8F5E9',
}

const YELLOW = {
    900: '#F57F17',
    800: '#F9A825',
    700: '#FBC02D',
    600: '#FDD835',
    500: '#FFEB3B',
    400: '#FFEE58',
    300: '#FFF176',
    200: '#FFF59D',
    100: '#FFF9C4',
    50:  '#FFFDE7',
}

const RED = {
    900: '#B71C1C',
    800: '#C62828',
    700: '#D32F2F',
    600: '#E53935',
    500: '#F44336',
    400: '#EF5350',
    300: '#E57373',
    200: '#EF9A9A',
    100: '#FFCDD2',
    50:  '#FFEBEE',
}

const LIGHT_BLUE = {
    900: '#01579B',
    800: '#0277BD',
    700: '#0288D1',
    600: '#039BE5',
    500: '#03A9F4',
    400: '#29B6F6',
    300: '#4FC3F7',
    200: '#81D4FA',
    100: '#B3E5FC',
    50:  '#E1F5FE',
}

const NEUTRAL = {
    black: '#000000',
    800: '#262626',
    700: '#434343',
    600: '#555555',
    500: '#7B7B7B',
    400: '#9D9D9D',
    300: '#C4C4C4',
    200: '#D9D9D9',
    100: '#E9E9E9',
    gray: '#F7F7F7',
    white: '#FFFFFF',
    white50: 'rgba(255, 255, 255, 0.5)',
}

export const lightVariant: AppTheme = {
    name: 'Light',
    palette: {
        // Material UI overrides
        type: 'light',
        primary: {
            main: TEAL['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
        },
        secondary: {
            main: PURPLE['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
        },
        error: {
            main: RED['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        warning: {
            main: YELLOW['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        info: {
            main: LIGHT_BLUE['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        success: {
            main: GREEN['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        // Custom stuff below

        error50: {
            main: RED['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error100: {
            main: RED['100'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error200: {
            main: RED['200'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error300: {
            main: RED['300'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error400: {
            main: RED['400'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error500: {
            main: RED['500'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error600: {
            main: RED['600'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error700: {
            main: RED['700'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error800: {
            main: RED['800'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        error900: {
            main: RED['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        warning50: {
            main: YELLOW['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning100: {
            main: YELLOW['100'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning200: {
            main: YELLOW['200'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning300: {
            main: YELLOW['300'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning400: {
            main: YELLOW['400'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning500: {
            main: YELLOW['500'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning600: {
            main: YELLOW['600'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning700: {
            main: YELLOW['700'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning800: {
            main: YELLOW['800'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        warning900: {
            main: YELLOW['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info50: {
            main: LIGHT_BLUE['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info100: {
            main: LIGHT_BLUE['100'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info200: {
            main: LIGHT_BLUE['200'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info300: {
            main: LIGHT_BLUE['300'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info400: {
            main: LIGHT_BLUE['400'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        info500: {
            main: LIGHT_BLUE['500'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info600: {
            main: LIGHT_BLUE['600'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info700: {
            main: LIGHT_BLUE['700'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info800: {
            main: LIGHT_BLUE['800'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        info900: {
            main: LIGHT_BLUE['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        success50: {
            main: GREEN['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success100: {
            main: GREEN['100'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success200: {
            main: GREEN['200'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success300: {
            main: GREEN['300'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success400: {
            main: GREEN['400'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success500: {
            main: GREEN['500'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success600: {
            main: GREEN['600'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success700: {
            main: GREEN['700'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        success800: {
            main: GREEN['800'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        success900: {
            main: GREEN['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },

        white: {
            main: NEUTRAL.white,
            contrastText: darkText.primary,
        },
        black: {
            main: NEUTRAL.black,
            contrastText: lightText.primary,
        },
        accentPrimary: {
            main: TEAL['300'],
            contrastText: darkText.primary,
        },
        accentPrimary50: {
            main: TEAL['50'],
            contrastText: darkText.primary,
        },
        accentPrimary100: {
            main: TEAL['100'],
            contrastText: darkText.primary,
        },
        accentPrimary200: {
            main: TEAL['200'],
            contrastText: darkText.primary,
        },
        accentPrimary300: {
            main: TEAL['300'],
            contrastText: darkText.primary,
        },
        accentPrimary400: {
            main: TEAL['400'],
            contrastText: lightText.primary,
        },
        accentPrimary500: {
            main: TEAL['500'],
            contrastText: lightText.primary,
        },
        accentPrimary600: {
            main: TEAL['600'],
            contrastText: lightText.primary,
        },
        accentPrimary700: {
            main: TEAL['700'],
            contrastText: lightText.primary,
        },
        accentPrimary800: {
            main: TEAL['800'],
            contrastText: lightText.primary,
        },
        accentPrimary900: {
            main: TEAL['900'],
            contrastText: lightText.primary,
        },
        accentSecondary: {
            main: PURPLE['600'],
            contrastText: lightText.primary,
        },
        accentSecondary50: {
            main: PURPLE['50'],
            contrastText: darkText.primary,
        },
        accentSecondary100: {
            main: PURPLE['100'],
            contrastText: darkText.primary,
        },
        accentSecondary200: {
            main: PURPLE['200'],
            contrastText: darkText.primary,
        },
        accentSecondary300: {
            main: PURPLE['300'],
            contrastText: lightText.primary,
        },
        accentSecondary400: {
            main: PURPLE['400'],
            contrastText: lightText.primary,
        },
        accentSecondary500: {
            main: PURPLE['500'],
            contrastText: lightText.primary,
        },
        accentSecondary600: {
            main: PURPLE['600'],
            contrastText: lightText.primary,
        },
        accentSecondary700: {
            main: PURPLE['700'],
            contrastText: lightText.primary,
        },
        accentSecondary800: {
            main: PURPLE['800'],
            contrastText: lightText.primary,
        },
        accentSecondary900: {
            main: PURPLE['900'],
            contrastText: lightText.primary,
        },
        neutral: {
            main: NEUTRAL.gray,
            contrastText: darkText.primary,
        },
        neutral50: {
            main: NEUTRAL.gray,
            contrastText: darkText.primary,
        },
        neutral100: {
            main: NEUTRAL['100'],
            contrastText: darkText.primary,
        },
        neutral200: {
            main: NEUTRAL['200'],
            contrastText: darkText.primary,
        },
        neutral300: {
            main: NEUTRAL['300'],
            contrastText: darkText.primary,
        },
        neutral400: {
            main: NEUTRAL['400'],
            contrastText: darkText.primary,
        },
        neutral500: {
            main: NEUTRAL['500'],
            contrastText: lightText.primary,
        },
        neutral600: {
            main: NEUTRAL['600'],
            contrastText: lightText.primary,
        },
        neutral700: {
            main: NEUTRAL['700'],
            contrastText: lightText.primary,
        },
        neutral800: {
            main: NEUTRAL['800'],
            contrastText: lightText.primary,
        },
        // This theme will be used to replace the component when it is been used on the old UI and new UI
        newPrimary: {
            main: TEAL['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
        },
        newSecondary: {
            main: PURPLE['900'],
            contrastText: lightText.primary,
            // light: will be calculated from palette.secondary.main,
            // dark: will be calculated from palette.secondary.main,
        },
        newError: {
            main: RED['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.error.main,
            // dark: will be calculated from palette.error.main,
        },
        newWarning: {
            main: YELLOW['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.warning.main,
            // dark: will be calculated from palette.warning.main,
        },
        newInfo: {
            main: LIGHT_BLUE['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.info.main,
            // dark: will be calculated from palette.info.main,
        },
        newSuccess: {
            main: GREEN['50'],
            contrastText: darkText.primary,
            // light: will be calculated from palette.success.main,
            // dark: will be calculated from palette.success.main,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
}

const newDesignVariants: AppTheme[] = [
    lightVariant,
]

export default newDesignVariants
