/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateLabelResponse = {
    content: string;
    contentType: GenerateLabelResponse.contentType;
    name: string;
    isGenerated: boolean;
    hasBarcode: boolean;
}

export namespace GenerateLabelResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}
