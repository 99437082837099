import { Currency, UuidV4, ConsolidationType }
    from '@lazr/openapi-client'
import { StatementOfAccountInvoice } from '@/app/model/StatementOfAccountInvoice'

export interface StatementOfAccountConsolidationProps {
    id: UuidV4
    type: ConsolidationType
    number: number
    organizationId: UuidV4
    invoiceDate: string
    dueDate: string
    currency: Currency
    subtotal: number
    taxesTotal: number
    invoices: StatementOfAccountInvoice[]
    clientOrganizationName: string
    billingName: string
    billingAddress: string
    billingAddress2: string
    billingAddress3: string
    billingCity: string
    billingState: string
    billingPostalCode: string
    billingCountry: string
    invoiceTotalCad: string
    currentTotalCad: string
    thirtyDaysTotalCad: string | null
    sixtyDaysTotalCad: string
    ninetyPlusDaysTotalCad: string
    invoiceTotalUsd: string
    currentTotalUsd: string
    thirtyDaysTotalUsd: string | null
    sixtyDaysTotalUsd: string
    ninetyPlusDaysTotalUsd: string
}
export class StatementOfAccountConsolidation {
    public id: UuidV4
    public type: ConsolidationType
    public number: number
    public organizationId: UuidV4
    public currency: Currency
    public invoiceDate: string
    public dueDate: string
    public subtotal: number
    public taxesTotal: number
    public invoices: StatementOfAccountInvoice[]
    public clientOrganizationName: string
    public billingName: string
    public billingAddress: string
    public billingAddress2: string
    public billingAddress3: string
    public billingCity: string
    public billingState: string
    public billingPostalCode: string
    public billingCountry: string
    public invoiceTotalCad: string
    public currentTotalCad: string
    public thirtyDaysTotalCad: string | null
    public sixtyDaysTotalCad: string
    public ninetyPlusDaysTotalCad: string
    public invoiceTotalUsd: string
    public currentTotalUsd: string
    public thirtyDaysTotalUsd: string | null
    public sixtyDaysTotalUsd: string
    public ninetyPlusDaysTotalUsd: string

    constructor (props: StatementOfAccountConsolidationProps) {
        this.id = props.id
        this.type = props.type
        this.number = props.number
        this.organizationId = props.organizationId
        this.invoiceDate = props.invoiceDate
        this.currency = props.currency
        this.dueDate = props.dueDate
        this.subtotal = props.subtotal
        this.taxesTotal = props.taxesTotal
        this.invoices = props.invoices
        this.clientOrganizationName = props.clientOrganizationName
        this.billingName = props.billingName
        this.billingAddress = props.billingAddress
        this.billingAddress2 = props.billingAddress2
        this.billingAddress3 = props.billingAddress3
        this.billingCity = props.billingCity
        this.billingState = props.billingState
        this.billingPostalCode = props.billingPostalCode
        this.billingCountry = props.billingCountry
        this.invoiceTotalCad = props.invoiceTotalCad
        this.currentTotalCad = props.currentTotalCad
        this.thirtyDaysTotalCad = props.thirtyDaysTotalCad
        this.sixtyDaysTotalCad = props.sixtyDaysTotalCad
        this.ninetyPlusDaysTotalCad = props.ninetyPlusDaysTotalCad
        this.invoiceTotalUsd = props.invoiceTotalUsd
        this.currentTotalUsd = props.currentTotalUsd
        this.thirtyDaysTotalUsd = props.thirtyDaysTotalUsd
        this.sixtyDaysTotalUsd = props.sixtyDaysTotalUsd
        this.ninetyPlusDaysTotalUsd = props.ninetyPlusDaysTotalUsd
    }
}
