/* eslint-disable @typescript-eslint/no-unsafe-argument */
import styled from 'styled-components'
import {
    Checkbox as MuiCheckbox,
} from '@material-ui/core'
import { transparentize } from 'polished'

export const CheckboxInner = styled(MuiCheckbox)`
    top: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
    position: absolute;
    color: ${(props): number => props.theme.palette.accentPrimary700.main};

    & span {
        overflow: visible;
        position: absolute;
        & span{
            top: -12px;
            left: -12px;
            width: 48px;
            height: 48px;
            background-color: ${(props): string => transparentize(0.5, props.theme.palette.accentPrimary900.main)};
        }
    }

    & svg{
        transform: scale(1.2);
    }

    &:not(.disabled){
        &.Mui-checked{
            color: ${(props): number => props.theme.palette.accentPrimary700.main};
        }
    
        &.unselected {
            color: ${(props): number => props.theme.palette.neutral700.main};
            & span {
                & span{
                    background-color: ${(props): string => transparentize(0.5, props.theme.palette.accentPrimary700.main)};
                }
            }
        }
    
        &.error{
            color: ${(props): number => props.theme.palette.error900.main};
            & span {
                & span{
                    background-color: ${(props): number => props.theme.palette.error900.main}
                }
            }
        }
    }
`

export const CheckboxOuter = styled.div`
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    &:not(.disabled){
        &:hover{
            background-color: ${(props): string => transparentize(0.92, props.theme.palette.accentPrimary300.main)};
        }
        &:focus{
            background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main)};
        }
        &:active{
            background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main)};
        }

        &.unselected{
            &:hover{
                background-color: ${(props): string => transparentize(0.92, props.theme.palette.neutral700.main)};
            }
            &:focus{
                background-color: ${(props): string => transparentize(0.88, props.theme.palette.neutral700.main)};
            }
            &:active{
                background-color: ${(props): string => transparentize(0.88, props.theme.palette.neutral700.main)};
            }
        }

        &.error{
            &:hover{
                background-color: ${(props): string => transparentize(0.92, props.theme.palette.error300.main)};
            }
            &:focus{
                background-color: ${(props): string => transparentize(0.88, props.theme.palette.error300.main)};
            }
            &:active{
                background-color: ${(props): string => transparentize(0.88, props.theme.palette.error300.main)};
            }
        }
    }
`
