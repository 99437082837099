import React from 'react'
import {
    IconButton as IconButtonStyled,
    FontAwesomeIcon as FontAwesomeIconStyled,
} from './IconButton.styled'
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core'

const IconButton: React.FunctionComponent<IconButtonProps> = React.forwardRef((props, ref) => {
    const classes = props.className
    const size = props.size ?? 'medium'
    const preserveIconColors = props.preserveIconColors ? 'preserveIconColors' : ''
    const className: string = classes ? classes.split(' ').concat(size).concat(preserveIconColors).join(' ') : size
    const newProps = { ...props, size: undefined, preserveiconcolors: undefined, className }

    return <IconButtonStyled {...newProps} ref={ref}>
        {props.children}
    </IconButtonStyled>
})
IconButton.displayName = 'IconButton'
export { IconButton }

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'size'> {
    size?: 'xsmall' | 'small' | 'medium' | 'large'
    preserveIconColors?: boolean
    href?: string
}

export const FontAwesomeIcon = FontAwesomeIconStyled
