import styled from 'styled-components'
import {
    Typography as MuiTypography,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const StyledPageTitleTypography = styled(styled(MuiTypography)(MuiSpacing))`
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
`
