/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './DevelopedByDesktop.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Developed by a`]: `Développé par `,
    [`Leading`]: `une entreprise `,
    [`logistics company`]: `de logistique de pointe`,
    ['Easily manage your business’ freight and parcel shipping. Lazr’s proprietary platform helps improve the efficiency and effectiveness of your entire shipping operations—saving time and reducing costs for everyone involved.']: 'Gérez facilement les expéditions de marchandises sur palette et de colis de votre entreprise. La plateforme unique de Lazr permet d\'améliorer l\'efficacité et l\'efficience de l\'ensemble de vos opérations d\'expédition, ce qui permet de gagner du temps et de réduire les coûts pour toutes les parties concernées.',
    ['Our cutting-edge algorithm gives you access in seconds to a complete and comprehensive view of shipping rates in the USA and Canada, so you know which carriers and at what price your shipment can be handled.']: 'Notre algorithme de pointe vous permet d\'accéder en quelques secondes à une vue complète et détaillée des tarifs d\'expédition aux États-Unis et au Canada, afin que vous sachiez quels transporteurs et à quel prix votre envoi peut être pris en charge.',
}))()
