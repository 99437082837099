import styled from 'styled-components'
import { Box as MuiBox, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props): string => props.theme.palette.white.main};
`

export const ImageWrapper = styled(MuiBox)`
    position: relative;
    height: 80px;
    width: 600px;
    overflow: hidden;
`

export const NewWayArrowShadowImg = styled.img`
    position: absolute;
    top: ${(props): number => props.theme.spacing(-5)}px;
    left: ${(props): number => props.theme.spacing(-7.5)}px;
`

export const NewWayArrowImg = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
`

export const NewWayArrowSubtractImg = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
`

export const AnimatedGlow = styled.img`
    position: absolute;
    top: 0;
    left: -410px;
    height: 80px;
    width: 410px;

    animation: lazr-way-glow 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @keyframes lazr-way-glow {
        from { left: -410px; }
        to { left: 600px; }
    }
`
