import React from 'react'
import styled from 'styled-components'

import {
    Grid as MuiGrid,
    Typography as MuiTypography,
} from '@material-ui/core'

import {
    spacing as MuiSpacing,
    spacing,
} from '@material-ui/system'
import { darken } from 'polished'
import {
    BG_GRAY_DARK_AMOUNT,
    BG_LIGHTER_GRAY_DARKEN_AMOUNT,
} from '../../lib/constants'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../../vendor/perfect-scrollbar.scss'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))`
.addressRow {
    padding: ${(props): number => props.theme.spacing(0.75)}px; // 3px
    line-height: 1rem;
}
.addressRow:hover {
    background-color: ${(props): string => darken(BG_GRAY_DARK_AMOUNT, props.theme.palette.background.paper)};
    cursor: pointer;
}
.addressRow.selected {
     background-color: #0DD994;
}
#addressScrollbar {
    background-color: ${(props): string => darken(BG_LIGHTER_GRAY_DARKEN_AMOUNT, props.theme.palette.background.paper)};
    padding: ${(props): number => props.theme.spacing(2.5)}px; // 10px;
    width: 300px;
    height: 150px;
}
#listContainer {
    height: 200px;
}
`

export const Typography = styled(styled(MuiTypography)(spacing)) <{ component?: React.ElementType<React.HTMLAttributes<HTMLElement>> }>``

export const TitleTypography = styled(Typography)`
    color: ${(props): string => props.theme.palette.accent2.main};
`
export const Scrollbar = styled(PerfectScrollbar)`
`
