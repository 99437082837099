import { z } from 'zod'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const isMoment = (value: any): boolean => {
    return moment.isMoment(value)
}

export const isValidDate = (value: any): boolean => {
    return value ? moment(value, dateFormat, true).isValid() : false
}

const isDateInPast = (value: any): boolean => {
    return moment(value).isBefore(moment(), 'day')
}

// Custom refinement function to check if 'from' is equal and  smaller than 'to'
export const isValidDateRange = (from: string | undefined | null, to: string | undefined | null) => {
    if (from && to) {
        const fromDate = moment(from).format('YYYY-MM-DD')
        const toDate = moment(to).format('YYYY-MM-DD')

        if (fromDate === 'Invalid date') {
            return true
        }
        if (toDate === 'Invalid date') {
            return true
        }

        return moment(fromDate).isSameOrBefore(toDate)
    }

    return false // Invalid date range if 'from' is equal and greater than 'to'
}
