import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import PrimaryButtonStyled from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import SecondaryButtonStyled from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { Link as RouterLink } from 'react-router-dom'
import { Box as MuiBox, Typography as MuiTypography } from '@material-ui/core'

export const Typography = styled(MuiTypography)(MuiSpacing)

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.spacing(20)}px 0;

    & [class*='MuiToolbar-root']{
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 960px) {
        padding: ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(6)}px;
        & > div:last-child {
            width: 100%;
            max-width: 360px;
        }
    }
`

export const Welcome = styled(Typography)`
    font-family: 'Termina';
    color: ${(props): string => props.theme.palette.black.main};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-align: center;
    padding: ${(props) => props.theme.spacing(6)}px 0 0;

    & span{
        color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }
`

export const PrimaryButton = styled(styled(PrimaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const SecondaryButton = styled(styled(SecondaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const Link = styled(RouterLink)`
   text-decoration: none;
   text-align: center;
   color: ${(props): string => props.theme.palette.neutral600.main};
   margin-top: ${(props) => props.theme.spacing(6)}px;
`

export const Message = styled.div`
    width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 12px;
`

export const IconContainer = styled(MuiBox)`
    font-size: 4rem;
    text-align: center;
    color: ${(props): string => props.theme.palette.error900.main};
`
