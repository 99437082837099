/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`A freight management system that works for everyone`]: `A freight management system that works for everyone`,
    [`Lazr advantages card`]: `Lazr advantages card`,
    [`Procurement manager`]: `Procurement manager`,
    [`Access the largest logistic network for LTL & parcel shipping`]: `Access the largest logistic network for LTL & parcel shipping`,
    [`Icon`]: `Icon`,
    [`Get instant pricing from 50+ trusted carriers`]: `Get instant pricing from 50+ trusted carriers`,
    [`Add your own carrier contracts to compare shipping rates`]: `Add your own carrier contracts to compare shipping rates`,
    [`Autofill forms & automate billing`]: `Autofill forms & automate billing`,
    [`Get Lazr for free`]: `Get Lazr for free`,
    [`I'm a`]: `I'm a`,
    [`Warehouse Manager`]: `Warehouse Manager`,
    [`Easily track all shipments in one dashboard`]: `Easily track all shipments in one dashboard`,
    [`Unified dashboard with precise tracking & visibility`]: `Unified dashboard with precise tracking & visibility`,
    [`EDI capabilities for efficient supply chain communications`]: `EDI capabilities for efficient supply chain communications`,
    [`Enhance existing workflows with our API integrations`]: `Enhance existing workflows with our API integrations`,
    [`Head of Logistics`]: `Head of Logistics`,
    [`Diversify carriers & cut shipping costs`]: `Diversify carriers & cut shipping costs`,
    [`Cut shipping costs by up to 21%`]: `Cut shipping costs by up to 21%`,
    [`Choose from 50+ trusted carriers on the largest LTL & parcel shipping network`]: `Choose from 50+ trusted carriers on the largest LTL & parcel shipping network`,
    [`I'm an`]: `I'm an`,
    [`Executive`]: `Executive`,
    [`Modernize your operations & grow faster`]: `Modernize your operations & grow faster`,
    [`Easy-to-use, collaborative platform for both growing & established companies`]: `Easy-to-use, collaborative platform for both growing & established companies`,
    [`Enhance legacy systems with our API integrations`]: `Enhance legacy systems with our API integrations`,
    [`Improve shipping operations from start to finish`]: `Improve shipping operations from start to finish`,
}
