/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './TransportType.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['LTL']: 'LTL',
    ['FTL']: 'FTL',
    ['PARCEL']: 'Colis',
    ['ENVELOPE']: 'Enveloppe',
    ['PAK']: 'Pak',
    ['OTHER']: 'Autre',
}))()
