import React from 'react'
import { Box } from '@material-ui/core'
import {
    StyledStepCard,
    StyledPaper,
    StyledPrimaryContentTypography, StyledNameTypography, StyledJobTitleTypography,
} from '@/app/ui-new/pages/landing/components/TestimonialsCarousel/StepCard.styled'

const StepCard: React.FunctionComponent<StepCardDesktopProps> = (props: StepCardDesktopProps) => (
    <StyledPaper elevation={0}>
        <StyledStepCard display='flex' flexDirection='row' justifyContent='space-between' height={'100%'}>
            <Box display='flex' flexDirection='row' alignItems={'start'}>
                <img src={`/images/landing/Quotes.svg?v=${window.lazrVersion}`} width={20} alt={'Quotes'}/>
                <Box ml={2.5} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box mb={1.5} width={270}>
                        <StyledPrimaryContentTypography
                            variant='body2'
                            $color={props.primaryContentColor}>{props.primaryContent}</StyledPrimaryContentTypography>
                    </Box>
                    <Box>
                        <Box>
                            <StyledNameTypography variant='caption' $color={props.secondaryContentColor}>
                                {props.name}
                            </StyledNameTypography>
                        </Box>
                        <StyledJobTitleTypography variant='caption' $color={props.secondaryContentColor}>
                            {props.jobTitle}
                        </StyledJobTitleTypography>
                    </Box>
                </Box>
            </Box>
        </StyledStepCard>
    </StyledPaper>
)

export interface StepCardDesktopProps {
    primaryContent: string
    name: string
    jobTitle: string
    primaryContentColor?: string
    secondaryContentColor?: string
}

export default StepCard
