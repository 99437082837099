import React, { useState, useRef, useEffect, useCallback } from 'react'
import TextField from '@/app/ui-new/components/TextField/TextField'
import { TextFieldAdornment } from '@/app/ui-new/components/TextFieldAdornment/TextFieldAdornment'
import {
    TextFieldIconEnvelope,
    TextFieldIconLockAlt,
} from '@/app/ui-new/components/TextFieldIcons/TextFieldIcons'
import { PasswordVisibilityButton } from '@/app/ui-new/components/TextFieldIcons/PasswordVisibilityButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    AuthForm,
    PrimaryButton,
    SecondaryButton,
    Link,
} from './SignIn.styled'
import { logger } from '@/app/logger'
import * as EmailValidator from 'email-validator'
// import { MIN_PASSWORD_LENGTH } from '@/app/ui/redux/constants'
import { isSignedIn, UserApiService, OrganizationApiService } from '@/app/service/ApiService'
import * as ApiService from '@/app/service/ApiService'
import { User } from '@/app/model'
import { dashboardRoute, marketplaceShipmentInfoRoute, newMarketplaceShipmentInfoRoute, newOrderListRoute } from '@/app/ui/routes'
import { SPINNER_ICON } from '@/app/ui/components/Modal'
import Timer from '@/app/ui/components/Timer'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './SignIn.i18n'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { useDispatch } from 'react-redux'
import { setUserIsSignedIn as reduxSetUserIsSignedIn } from '@/app/ui-new/redux/actions/MarketPlaceActions'

/* SEO */
import SEO from '@/app/ui/seo'
import { signInRoute } from '@/app/ui/routes'

// need to allow login with very short password due to GMX import
const MIN_PASSWORD_LENGTH = 3

interface LocalStorageUserInfo {
    email: string | null
    firstName: string | null
    pictureUrl: string | null
}

interface LocalStorageHasOrder {
    hasOrder: string | null
}

export const localStorageHelper = {
    setUserInfo: (user: User): void => {
        localStorage.setItem('userEmail', user.email)
        localStorage.setItem('userFirstName', user.firstName ?? '')
        localStorage.setItem('userPictureUrl', user.pictureUrl ?? '')
    },
    getUserInfo: (): LocalStorageUserInfo => ({
        email: localStorage.getItem('userEmail'),
        firstName: localStorage.getItem('userFirstName'),
        pictureUrl: localStorage.getItem('userPictureUrl'),

    }),
    setHasOrder: (value: string): void =>{
        localStorage.setItem('hasOrder', value)
    },
    getHasOrder: (): LocalStorageHasOrder => ({
        hasOrder: localStorage.getItem('hasOrder'),
    })

}

const secondsToResendVerificationEmail = 60

const SignIn: React.FunctionComponent<Props> = ({ isModal = false, setSigninDialogOpen }) => {
    const { t } = useI18n(i18n)
    const dispatch = useDispatch()

    const isSigninPage = window.location.pathname === signInRoute.path

    const userInfoEmail = localStorageHelper.getUserInfo().email
    const [ email, setEmail ] = useState<string>(userInfoEmail ?? '')
    const [ password, setPassword ] = useState<string>('')
    const [ showPassword, setShowPassword ] = useState<boolean>(false)
    const [ emailError, setEmailError ] = useState<boolean>(false)
    const [ passwordError, setPasswordError ] = useState<boolean>(false)
    const [ showResendButton, setShowResendButton ] = useState<boolean>(false)
    const [ processingResendButton, setProcessingResendButton ] = useState<boolean>(false)
    const [ emailErrorText, setEmailErrorText ] = useState<string>('')
    const [ passwordErrorText, setPasswordErrorText ] = useState<string>('')
    const [ isSignInInProgress, setIsSignInInProgress ] = useState<boolean>(false)

    const urlParams = new URLSearchParams(window.location.search)
    const tokenParam = urlParams.get('token')
    const authForm: { current: HTMLDivElement | null } = useRef(null)

    const handleClickShowPassword = useCallback((): void => {
        setShowPassword((PreviousShowPassword) => !PreviousShowPassword)
    }, [ setShowPassword ])

    const isFormValid = useCallback((): boolean => {
        let isValid = true

        if (!email) {
            setEmailError(true)
            setEmailErrorText(t('Email cannot be empty'))
            isValid = false
        } else if (!EmailValidator.validate(email)) {
            setEmailError(true)
            setEmailErrorText(t('Email is not a valid format'))
            isValid = false
        } else {
            setEmailError(false)
            setEmailErrorText('')
        }

        if (!password) {
            setPasswordError(true)
            setPasswordErrorText(t('Password cannot be empty'))
            isValid = false
        } else if (password.length < MIN_PASSWORD_LENGTH) {
            setPasswordError(true)
            setPasswordErrorText(t('Password must be at least \'{passwordLength}\' characters long',
                { passwordLength: MIN_PASSWORD_LENGTH }))
            isValid = false
        } else {
            setPasswordError(false)
            setPasswordErrorText('')
        }

        return isValid
    }, [
        setEmailError,
        setEmailErrorText,
        setPasswordError,
        setPasswordErrorText,
        email,
        EmailValidator,
        password,
    ])

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, setter: (val: string) => void): void => {
        setter(event.target.value)
    }, [])

    const handleSignIn = async (urlToken?: string): Promise<void> => {
        const ensureTokenIsValid = (encodedToken: string): void => {
            const decodedToken = jwtDecode<JwtPayload>(encodedToken, { header: true })

            if (typeof decodedToken !== 'object') {
                throw new Error('InvalidTokenError')
            }
        }

        try {
            setIsSignInInProgress(true)

            let token
            if (urlToken) {
                ensureTokenIsValid(urlToken)
                token = urlToken
            } else {
                token = await UserApiService.authenticate(email, password)
            }

            ApiService.signIn(token)

            let user = null
            if (isSignedIn()) {
                user = await UserApiService.getCurrent()

            }
            if (user) {
                localStorageHelper.setUserInfo(user)

                const hasOrder = localStorageHelper.getHasOrder().hasOrder
                if(!hasOrder){
                    const resultList = await ApiService.OrderApiService.list(
                        {
                            page: 1,
                            resultPerPage: 1,
                        },
                        {},
                    )
                    if (resultList.total > 0) {
                        localStorageHelper.setHasOrder(resultList.total.toString())
                    }
                }

            }

            if (isModal) {
                if (setSigninDialogOpen) {
                    dispatch(reduxSetUserIsSignedIn(true))
                    setSigninDialogOpen(false)
                } else {
                    throw new Error('Missing setSigninDialogOpen on on signin modal')
                }
            }
            else {
                const hasOrder = localStorageHelper.getHasOrder().hasOrder
                if(hasOrder){
                    window.location.href = newOrderListRoute.path
                }else{
                    window.location.href = newMarketplaceShipmentInfoRoute.path
                }

            }
        } catch (error: any) {
            logger.error('error signing in:', error)

            if (error.message === 'UserNotConfirmedException') {
                setPasswordErrorText(t('This email has not been verified'))
            }
            else if (error.message === 'UserNotActivatedException') {
                setPasswordErrorText(t('You have not yet been approved by your administrator'))
            }
            else if ([ error.message, error.name ].includes('InvalidTokenError')) {
                setPasswordErrorText(t('The specified login token is invalid'))
            }
            else {
                setPasswordErrorText(t('This email / password combination is invalid'))
            }
            setPasswordError(true)
            setShowResendButton(error.message === 'UserNotConfirmedException')
            setIsSignInInProgress(false)
        }
    }

    const handleSignInFromForm = useCallback((event: React.FormEvent) => {
        event.preventDefault()
        if (!isFormValid()) {
            return
        }

        return void handleSignIn()
    }, [
        isFormValid,
        setIsSignInInProgress,
        UserApiService,
        ApiService,
        localStorageHelper,
        setPasswordErrorText,
        setPasswordError,
        email,
        password,
        isSignedIn,
        dashboardRoute,
        setShowResendButton,
    ])

    const handleResendConfirmationCode = useCallback(async () => {
        setProcessingResendButton(true)
        window.setTimeout(() => setProcessingResendButton(false), secondsToResendVerificationEmail * 1000)
        await UserApiService.resendConfirmationCode(email)
    }, [
        setProcessingResendButton,
        secondsToResendVerificationEmail,
        window.setTimeout,
        UserApiService,
        email,
    ])

    // TODO: (GD) Why this hide behavior?
    /*
    useEffect(() => {
        window.setTimeout(() => {
            if (authForm.current && window.location.pathname === '/signin') {
                authForm.current.classList.add('opacity1')
            }
        }, 300)
    }, [ authForm ])
    */

    useEffect(() => {
        void (async () => {
            if (tokenParam) {
                await handleSignIn(tokenParam)
            }
        })()
    }, [])

    return <>
        {/* SEO (START) */}
        {isSigninPage &&
            <SEO
                pageTitle={t('Sign In')}
                pageCanonical='https://lazr.io/signin'
            />
        }
        {/* SEO (END) */}

        <form onSubmit={handleSignInFromForm}>
            <AuthForm ref={authForm}>
                <TextField
                    label={t('Email')}
                    fullWidth
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconEnvelope />
                            </TextFieldAdornment>
                        ),
                    }}
                    variant='outlined'
                    value={email}
                    error={emailError}
                    helperText={emailErrorText}
                    onChange={
                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setEmail)
                        }
                    }
                    autoFocus
                />
                <TextField
                    label={t('Password')}
                    type={showPassword ? 'text' : 'password'}
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconLockAlt />
                            </TextFieldAdornment>
                        ),
                        endAdornment: (
                            <TextFieldAdornment position='end'>
                                <PasswordVisibilityButton
                                    passwordVisible={showPassword}
                                    onClick={handleClickShowPassword}
                                    size='small'
                                />
                            </TextFieldAdornment>
                        ),
                    }}
                    variant='outlined'
                    value={password}
                    error={passwordError}
                    helperText={passwordErrorText}
                    onChange={
                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setPassword)
                        }
                    }
                />
                <PrimaryButton
                    type='submit'
                    disabled={isSignInInProgress}
                    mt={7.5}
                    endIcon={isSignInInProgress && <FontAwesomeIcon icon={SPINNER_ICON} pulse/>}
                >{t('Sign In')}</PrimaryButton>

                <Link to='/reset-password'>{t('Forgot password?')}</Link>

                {
                    !showResendButton ? <></> :
                        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                        <SecondaryButton onClick={async () => handleResendConfirmationCode()}
                            mt={6}
                            disabled={processingResendButton}
                            endIcon={processingResendButton && <FontAwesomeIcon icon={SPINNER_ICON} pulse />}
                        >
                            {processingResendButton ?
                                <span>
                                    Resend verification email in <Timer initialSeconds={secondsToResendVerificationEmail} />
                                </span>
                                :
                                'Resend verification email'
                            }
                        </SecondaryButton>
                }
            </AuthForm>
        </form>
    </>
}

interface Props{
    isModal?: boolean
    setSigninDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export default SignIn
