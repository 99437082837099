import React from 'react'
import {
    AnimatedGlow,
    ImageWrapper,
    NewWayArrowImg,
    NewWayArrowShadowImg,
    NewWayArrowSubtractImg,
} from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/NewWayHeader.styled'
import { StyledTitleTypography } from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/OldWayHeader.styled'
import { Box } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './OldVsNewWayDesktop.i18n'

const NewWayHeader: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return (
        <ImageWrapper>
            <NewWayArrowShadowImg src={`/images/website/OldVsNewWay/NewWayArrowShadow.svg?v=${window.lazrVersion}`}
                alt="NewWayArrowShadow"/>
            <NewWayArrowImg src={`/images/website/OldVsNewWay/NewWayArrow.svg?v=${window.lazrVersion}`}
                alt="NewWayArrow"/>
            <NewWayArrowSubtractImg src={`/images/website/OldVsNewWay/NewWayArrowSubtract.svg?v=${window.lazrVersion}`}
                alt="NewWayArrowSubtract"/>
            <Box position={'absolute'} top={27} left={35}>
                <StyledTitleTypography variant={'h5'}>{t('Lazr Way')}</StyledTitleTypography>
            </Box>
            <AnimatedGlow src={`/images/website/OldVsNewWay/NewWayArrowAnimatedGlowDesktop.png?v=${window.lazrVersion}`}
                alt="NewWayArrowAnimatedGlow"/>
        </ImageWrapper>
    )
}

export default NewWayHeader
