/* eslint-disable max-len */
import en from './NavigationGuardProvider.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'Are you sure?': 'Êtes-vous sûr?',
    'Leaving this page will result in losing any unsaved information in the form.':
        'Quitter cette page entraînera la perte de toute information non enregistrée dans le formulaire.',
    Cancel: 'Annuler',
    Leave: 'Quitter',
}))()
