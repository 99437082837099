/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Marketplace`]: `Marketplace`,
    [`Cargo`]: `Cargo`,
    [`Transit`]: `Transit`,
    [`Coverage`]: `Coverage`,
    [`Results`]: `Results`,
    [`Details`]: `Details`,
    [`Checkout`]: `Checkout`,
    [`Reset order`]: `Reset order`,
    ['Reset Order']: `Reset Order`,
    [`Create new order`]: `Create new order`,
    ['Are you sure you want to reset the current order?']: `Are you sure you want to reset the current order?`,
    ['Cancel']: `Cancel`,
    ['Reset']: `Reset`,
}
