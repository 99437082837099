import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { BillDutiesToPartyType } from '../../model'
import { handleUnauthorizedException } from './index'

export const BillDutiesToPartyTypeApiService = Object.freeze({
    list: async (): Promise<BillDutiesToPartyType[]> => {
        let getBillDutiesToPartyTypesResponse
        try {
            getBillDutiesToPartyTypesResponse = await EnumTypesService.getBillDutiesToPartyTypes()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve bill duties to party type list')
            }
            throw new Error('Unable to retrieve bill duties to party type list')
        }

        if (!getBillDutiesToPartyTypesResponse.data) {
            logger.debug('list',  JSON.stringify(getBillDutiesToPartyTypesResponse, null, 4))
            throw new Error('Unable to retrieve bill duties to party type list')
        }

        return getBillDutiesToPartyTypesResponse.data
    },
})
