/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './RequestDemo.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Request a Demo`]: `Demander une démo`,
    [`First Name`]: `Prénom`,
    [`Last Name`]: `Nom`,
    [`Email`]: `Courriel`,
    [`Phone`]: `Téléphone`,
    [`Company Name`]: `Compagnie`,
    [`Preferred Language`]: `Langue de préférence`,
    [`Industry`]: `Industrie`,
    [`None`]: `None`,
    [`Agriculture, forestry, fishing and hunting`]: `Agriculture, foresterie, chasse et pêche`,
    [`Mining, quarrying, and oil and gas extraction`]: `Mines, carrières et extraction de pétrole et de gaz`,
    [`Utilities`]: `Services publics`,
    [`Construction`]: `Construction`,
    [`Manufacturing`]: `Manufacturier`,
    [`Wholesale trade`]: `Commerce de gros`,
    [`Retail trade`]: `Commerce de détail`,
    [`Transportation and warehousing`]: `Transport et entreposage`,
    [`Information and cultural industries`]: `Information et industries culturelles`,
    [`Real estate and rental and leasing`]: `Immobilier et location et crédit-bail`,
    [`Professional, scientific and technical services`]: `Services professionnels, scientifiques et techniques`,
    [`Management of companies and enterprises`]: `Gestion de sociétés et d'entreprises`,
    [`Waste management and remediation services`]: `Services de gestion et d'assainissement des déchets`,
    [`Health care and social assistance`]: `Soins de santé et assistance sociale`,
    [`Arts, entertainment and recreation`]: `Arts, spectacles et loisirs`,
    [`Accommodation and food services`]: `Services d'hébergement et de restauration`,
    [`Other services`]: `Autres services`,
    [`Public administration`]: `Administration publique`,
    [`Volume of Shipments per Week`]: `Volume des expéditions par semaine`,
    [`less 10`]: `moins de 10`,
    [`10-50`]: `10-50`,
    [`50-100`]: `50-100`,
    [`100+`]: `+100`,
    [`Transportation services used`]: `Services de transport utilisés`,
    [`LTL (Local)`]: `LTL (Local)`,
    [`LTL (National)`]: `LTL (National)`,
    [`LTL (International)`]: `LTL (International)`,
    [`FTL`]: `FTL`,
    [`Pak`]: `Pak`,
    [`Parcel`]: `Colis`,
    [`Custom Quotes`]: `Transport hors-normes`,
    [`Other`]: `Autre`,
    [`I accept to receive email communications from LAZR.`]: `J'accepte de recevoir des communications par courriel de LAZR.`,
    [`This field is required`]: `Ce champ est requis`,
    [`Email is not a valid format`]: `Un format n'est pas valide`,
    [`Missing required fields`]: `Champs obligatoires manquants`,
    [`Error`]: `Erreur`,
    [`Something went wrong. Please try again later.`]: `Veuillez réessayer plus tard.`,
    [`Success`]: `Succès`,
    [`We will get in touch with you shortly.`]: `Attendez-vous à être contacté par l'un de nos représentants très bientôt.`,
}))()
