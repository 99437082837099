import * as types from '../constants'
import { Snackbar } from '../../definitions/Snackbar'
import { Action } from '../constants'

const set = (value: Snackbar | null): Action => ({
    type: types.SET_SNACKBAR,
    payload: value,
})

export default set
