import React from 'react'
import {
    Wrapper,
} from './QuoteFasterShipSmarterDesktop.styled'
import { Box } from '@material-ui/core'
import {
    QuoteFasterShipSmarterDesktop as QuoteFasterShipSmarter,
} from '../QuoteFasterShipSmarterDesktop'


const QuoteFasterShipSmarterDesktop: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={1224}>
            <QuoteFasterShipSmarter/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default QuoteFasterShipSmarterDesktop
