import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link as RouterLink } from 'react-router-dom'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    Avatar as MuiAvatar,
    Box as MuiBox,
    Grid as MuiGrid,
    MenuItem as MuiMenuItem,
    Popover as MuiPopover,
    Typography as MuiTypography,
} from '@material-ui/core'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Menu = styled(MuiPopover)`
    z-index: 2147483645;

    & [class^='MuiPaper-root'] {
        position: fixed;
        top: 80px;
        right: 24px;
        width: 240px;
    }
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: ${(props): number => props.theme.palette.black.main};

    &:hover {
        color: ${(props): number => props.theme.palette.primary.main};
    }
`

export const LongText = styled(MuiTypography)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
`

export const BoldText = styled(MuiTypography)`
    font-weight: 500;
`

export const SignOutIcon = styled(FontAwesomeIcon)`
    font-size: 1.25rem;
    vertical-align: middle;
    margin-right: 8px;
`

export const SignOutText = styled(MuiTypography)`
    position: relative;
    top: 1px;
`

export const Avatar = styled(MuiAvatar)`
    border-radius: 0 4px 4px 0;
    width: 40px;
    height: 40px;
`

export const OrganizationName = styled(LongText)`
    font-weight: 500;
    width: 132px;
    text-align: end;
`

export const OrganizationSelectBox = styled(MuiBox)`
    position: relative;
    top: 1px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    text-align: end;
`

export const OrganizationSelectMenuBackgroundOverlay: React.FC<OrganizationSelectMenuBackgroundOverlayProps>
    = styled.div<OrganizationSelectMenuBackgroundOverlayProps>`
    position: fixed;
    inset: 0 0 0 0;
    display: ${(props) => props.display};
    z-index: 2147483646;
`

export const OrganizationSelectMenu: React.FC<OrganizationSelectMenuProps> = styled.ul<OrganizationSelectMenuProps>`
    position: fixed;
    top: 195px;
    right: 24px;
    width: 180px;
    display: ${(props) => props.open ? 'block' : 'none'};
    background-color: ${(props): string => props.theme.palette.white.main};
    color: ${(props): string => props.theme.palette.black.main};
    padding: ${(props) => props.theme.spacing(2)}px 0;
    margin: 0;
    border-radius: 4px;
    transition: opacity 233ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 8px 16px 0 rgba(53,64,82,.25); ${/* Same shadow as Mui 4 */''}
    z-index: 2147483647;
`

export const OrganizationSelectMenuItem = styled(MuiMenuItem)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 0.875rem;
`

export interface OrganizationSelectMenuBackgroundOverlayProps {
    display: string
    onClick: () => void
}

export interface OrganizationSelectMenuProps {
    open: boolean
    onClick: () => void
}
