/* eslint-disable camelcase */
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { setRegisterDialogOpen as reduxSetRegisterDialogOpen } from '@/app/ui/redux/actions/RegisterActions'
import { getRequestRegisterDialogOpen as reduxGetRequestRegisterDialogOpen } from '@/app/ui/redux/selectors/RequestRegisterSelectors'
import RegisterOrganizationMobile from '@/app/ui-new/pages/website/components/Register Organization/Mobile/RegisterOrganizationMobile'
import RegisterOrganizationDesktop from '@/app/ui-new/pages/website/components/Register Organization/Desktop/RegisterOrganizationDesktop'
import { sleep } from '@lazr/utilities'
import { Alert, AlertContent } from '@/app/ui-new/components/Alert/Alert'
import { Snackbar } from '@/app/ui-new/components/Snackbar/Snackbar'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './RegisterOrganization.i18n'

const RegisterOrganization: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const isMobile = useMediaQuery('(max-width: 1230px)')
    const dispatch = useDispatch()
    const [ snackbarOpen, setSnackbarOpen ] = useState(false)
    const [ snackbarOptionalProps, setSnackbarOptionalProps ] = useState({})
    const [ snackbarChild, setSnackbarChild ] = useState<JSX.Element | undefined>(undefined)
    const registerOrganizationOpen = useSelector(reduxGetRequestRegisterDialogOpen)

    const closePreviousSnackbar = useCallback(async () => {
        setSnackbarOpen(false)
        await sleep(300)
    }, [ setSnackbarOpen, sleep ])

    const snackbarChildForSeverityError = <Alert
        severity='error'
        onClose={() => setSnackbarOpen(false)}
    >
        <AlertContent
            title={t('Error')}
            message={ t('Something went wrong. Please try again later.') }
        />
    </Alert>

    const snackbarChildForSeveritySuccess = <Alert
        severity='success'
        onClose={() => setSnackbarOpen(false)}
    >
        <AlertContent
            title={t('Success')}
            message={t('We will get in touch with you shortly.')}
        />
    </Alert>

    const openSnackbar = useCallback(async (type: 'error' | 'success') => {
        setSnackbarChild(type === 'error' ? snackbarChildForSeverityError : snackbarChildForSeveritySuccess)
        if (snackbarOpen) {
            await closePreviousSnackbar()
        }
        setSnackbarOptionalProps({})
        setSnackbarOpen(true)
    }, [ setSnackbarChild, snackbarOpen, closePreviousSnackbar, setSnackbarOptionalProps, setSnackbarOpen ])

    const closeDialog = () => {
        dispatch(reduxSetRegisterDialogOpen(false))
    }

    return <>
        {isMobile ?
            <RegisterOrganizationMobile
                isRegisterOrganizationOpen={registerOrganizationOpen}
                closeDialog={closeDialog}
                openSnackbar={openSnackbar}
            /> :
            <RegisterOrganizationDesktop
                isRegisterOrganizationOpen={registerOrganizationOpen}
                closeDialog={closeDialog}
                openSnackbar={openSnackbar}
            />}
        <Snackbar
            open={snackbarOpen}
            onClose={() => {setSnackbarOpen(false)}}
            {...snackbarOptionalProps}
        >
            {snackbarChild}
        </Snackbar>
    </>
}

export default RegisterOrganization
