/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse_GetServiceLevelsResponse_Array_ } from '../models/SuccessResponse_GetServiceLevelsResponse_Array_';
import type { TransportType } from '../models/TransportType';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class ServiceLevelService {

    /**
     * Get a list of service levels
     * @param carrierId The carrier ID
     * @param transportType
     * @returns SuccessResponse_GetServiceLevelsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getServiceLevelsByCarrierId(
        carrierId: UuidV4,
        transportType?: TransportType,
    ): Promise<SuccessResponse_GetServiceLevelsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/service-levels`,
            query: {
                'carrierId': carrierId,
                'transportType': transportType,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}