import RawLogo from '@/app/ui/components/Logo'
import styled from 'styled-components'
import { Grid as MuiGrid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { spacing as MuiSpacing } from '@material-ui/system'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Header = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    background-color: black;
    color: white;
    width: 100%;
    z-index: 1299;
    padding: ${(props): string => props.theme.spacing(4)}px ${(props): string => props.theme.spacing(6)}px;
    min-height: 72px;
    overflow: hidden;
`

export const Logo = styled(RawLogo)`
    width: 70px;
    height: 100%;
    padding-left: 1.7px;
    transform: translateY(2px);
    fill: ${(props): string => props.theme.palette.white.main};
`

export const Link = styled(RouterLink)`
   text-decoration: none;
`
