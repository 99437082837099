import styled from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'
import {
    AppWrapper as BaseAppWrapper,
    AppBody as BaseAppBody,
    AppContent as BaseAppContent,
} from '@/app/ui-new/layouts/AppLayout.styled'

export const AppWrapper = styled(BaseAppWrapper)`
`

export const AppBody = styled(BaseAppBody)`
    height: calc(100vh - 145px);
`

export const AppContent = styled(BaseAppContent)`
`

export const Result = styled.div`
    padding-top: ${(props): string => props.theme.spacing(3)}px;
`

export const Description = styled.div`
    color: ${(props): string => props.theme.palette.neutral400.main};
`

export const Link = styled.a`
    text-decoration: underline;
    color: ${(props): string => props.theme.palette.accentPrimary400.main};
    cursor: pointer;
`

export const Box = styled(MuiBox)`
    border: 1px solid ${(props): string => props.theme.palette.black.main};
    border-radius: 4px;
    padding: ${(props): string => props.theme.spacing(3)}px;
    min-height: 130px;
`

export const Input = styled.input`
    margin-left: ${(props): string => props.theme.spacing(2)}px;
`
