/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRole {
    ADMIN = 'ADMIN',
    AGENT = 'AGENT',
    SHIPPER = 'SHIPPER',
    ACCOUNTANT = 'ACCOUNTANT',
    SUPPLIER = 'SUPPLIER',
}