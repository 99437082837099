/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateInvoiceResponse = {
    content: string;
    contentType: GenerateInvoiceResponse.contentType;
    name: string;
}

export namespace GenerateInvoiceResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}
