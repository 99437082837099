import React from 'react'
import {
    Wrapper,
} from './FlipCardsDesktop.styled'
import { Box } from '@material-ui/core'
import { FlipCardsDesktop as FlipCards } from '../FlipCardsDesktop'


const FlipCardsDesktop: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={1440}>
            <FlipCards/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default FlipCardsDesktop
