import {
    OrderBillingErrors,
    OrderDetailsMarketplaceErrors,
    OrderSummaryErrors,
} from '../../definitions/OrderDetails'
import {
    Action,
    ADD_ORDER_DETAILS_CUSTOMS_DETAIL,
    ADD_ORDER_CLOSE_INTERVAL,
    ADD_QUOTE_COST_CHARGE,
    DELETE_ORDER_DETAILS_CUSTOMS_DETAIL,
    DELETE_ORDER_INTERVAL,
    DELETE_QUOTE_COST_CHARGE,
    SET_3PL_ADDITIONAL_INFO,
    SET_3PL_CURRENCY,
    SET_3PL_ESTIMATED_TRANSIT_MAX,
    SET_3PL_ESTIMATED_TRANSIT_MIN,
    SET_3PL_ETA,
    SET_3PL_VALID_UNTIL,
    SET_ORDER_DETAILS_CUSTOMS_DETAIL,
    SET_LAST_INVOICE_PICKUP_DATE,
    SET_TRANSPORT_SELECTED_INVOICE_DOCUMENTS,
    SET_ORDER_DETAILS_EDITABLE_ORDER,
    SET_ORDER_DETAILS_EDITABLE_RFQ,
    SET_ORDER_DETAILS_MARKETPLACE_ERRORS,
    SET_ORDER_DETAILS_MARKETPLACE_ERRORS_IS_SUBMITTED,
    SET_ORDER_DETAILS_ORDER,
    SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS,
    SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS_IS_SUBMITTED,
    SET_ORDER_DETAILS_ORDER_BILLING_ERRORS,
    SET_ORDER_DETAILS_ORDER_BILLING_ERRORS_IS_SUBMITTED,
    SET_QUOTE_COST_CHARGE,
    SET_QUOTE_COST_CHARGES,
    SET_ORDER_DETAIL_CHARGES,
    SET_SELECTED_TRANSPORT_INVOICE_NUMBER,
    RESET_ORDER_DETAIL_CUSTOM_QUOTE_STATE,
    RESET_ORDER_DETAIL_DUTY_BILL_ADDRESS,
    SET_SELECTED_TRANSPORT_TAXES,
    SET_SELECTED_CLIENT_TAXES,
    SET_SELECTED_LAZR_SHARE_TAXES,
    SET_INVOICE_DATE,
    SET_CARRIER_PAYMENT_TERMS_DAYS,
    SET_CALCULATED_DUE_DATE,
} from '../constants'
import {  OrderInfo, OrderInfoCustomsDetail, OrderInfoRfq, OtherUser, QuoteCostCharge } from '../../../model'
import { Currency } from '@lazr/openapi-client'
import { Charge } from '../../pages/orders/Details/Sections/components/CostCharges'
import { deepCopy, Tax } from '@lazr/utilities'
import { TransportInvoiceDocument } from '../../pages/orders/Details/Sections/Documents/components/hooks/DocumentUpload'
import {
    initialCustomQuote,
    initialDutyBillAddress,
    initialOrderDetailCustomsDetails,
    initialQuoteCostCharge,
} from '@/app/ui/redux/store/InitialState'

export const setOrderSummaryErrorsIsSubmitted = (isSubmitted: boolean): Action => ({
    type: SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS_IS_SUBMITTED,
    payload: isSubmitted,
})

export const setOrderSummaryErrors = (orderSummaryErrors: OrderSummaryErrors): Action => ({
    type: SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS,
    payload: orderSummaryErrors,
})

export const setOrderBillingErrors = (orderBillingErrors: OrderBillingErrors): Action => ({
    type: SET_ORDER_DETAILS_ORDER_BILLING_ERRORS,
    payload: orderBillingErrors,
})
export const setOrderBillingErrorsIsSubmitted = (isSubmitted: boolean): Action => ({
    type: SET_ORDER_DETAILS_ORDER_BILLING_ERRORS_IS_SUBMITTED,
    payload: isSubmitted,
})

export const setEditableOrder = (order: Partial<OrderInfo> | null): Action => ({
    type: SET_ORDER_DETAILS_EDITABLE_ORDER,
    payload: order,
})

export const setEditableRfq = (rfq: Partial<OrderInfoRfq> | null): Action => ({
    type: SET_ORDER_DETAILS_EDITABLE_RFQ,
    payload: rfq,
})

export const resetDutyBillAddress = (): Action => ({
    type: RESET_ORDER_DETAIL_DUTY_BILL_ADDRESS,
    payload: {
        initialDutyBillAddress,
    },
})

export const setOrder = (order: OrderInfo | null): Action => ({
    type: SET_ORDER_DETAILS_ORDER,
    payload: order,
})

export const setQuoteCostCharge = (quoteCostCharge: Partial<QuoteCostCharge>, index: number): Action => ({
    type: SET_QUOTE_COST_CHARGE,
    payload: {
        quoteCostCharge,
        index,
    },
})


export const deleteQuoteCostCharge = (index: number): Action => ({
    type: DELETE_QUOTE_COST_CHARGE,
    payload: index,
})

export const addQuoteCostCharge = (): Action => ({
    type: ADD_QUOTE_COST_CHARGE,
    payload: initialQuoteCostCharge,
})

export const set3plAdditionalInfo = (additionalInfo: string): Action => ({
    type: SET_3PL_ADDITIONAL_INFO,
    payload: {
        additionalInfo,
    },
})

export const set3plEstimatedTransitMin = (threeplEstimatedTransitMin: number): Action => ({
    type: SET_3PL_ESTIMATED_TRANSIT_MIN,
    payload: {
        threeplEstimatedTransitMin,
    },
})

export const set3plEstimatedTransitMax = (threeplEstimatedTransitMax: number): Action => ({
    type: SET_3PL_ESTIMATED_TRANSIT_MAX,
    payload: {
        threeplEstimatedTransitMax,
    },
})

export const set3plEta = (threeplEta: string): Action => ({
    type: SET_3PL_ETA,
    payload: {
        threeplEta,
    },
})

export const set3plValidUntil = (threeplValidUntil: string): Action => ({
    type: SET_3PL_VALID_UNTIL,
    payload: {
        threeplValidUntil,
    },
})

export const setLastInvoicePickupDate = (lastInvoicePickupDate: string | null): Action => ({
    type: SET_LAST_INVOICE_PICKUP_DATE,
    payload: {
        lastInvoicePickupDate,
    },
})

export const setTransportSelectedInvoiceDocuments = (selectedTransportInvoiceDocuments: TransportInvoiceDocument[]): Action => ({
    type: SET_TRANSPORT_SELECTED_INVOICE_DOCUMENTS,
    payload: {
        selectedTransportInvoiceDocuments,
    },
})

export const setSelectedTransportInvoiceNumber = (selectedTransportInvoiceNumber: string | null): Action => ({
    type: SET_SELECTED_TRANSPORT_INVOICE_NUMBER,
    payload: {
        selectedTransportInvoiceNumber,
    },
})


export const setSelectedTransportTaxes = (selectedTransportTaxes: Tax[][] | null): Action => ({
    type: SET_SELECTED_TRANSPORT_TAXES,
    payload: {
        selectedTransportTaxes,
    },
})

export const setSelectedClientTaxes = (selectedClientTaxes: Tax[][] | null): Action => ({
    type: SET_SELECTED_CLIENT_TAXES,
    payload: {
        selectedClientTaxes,
    },
})
export const setInvoiceDate = (invoiceDate: string | null): Action => ({
    type: SET_INVOICE_DATE,
    payload: {
        invoiceDate,
    },
})
export const setCalculatedDueDate = (calcultatedDueDate: string | null): Action => ({
    type: SET_CALCULATED_DUE_DATE,
    payload: {
        calcultatedDueDate,
    },
})
export const setCarierPaymentTermsDays = (paymentTermsDays: number | null): Action => ({
    type: SET_CARRIER_PAYMENT_TERMS_DAYS,
    payload: {
        paymentTermsDays,
    },
})

export const setSelectedLazrShareTaxes = (selectedLazrShareTaxes: Tax[]): Action => ({
    type: SET_SELECTED_LAZR_SHARE_TAXES,
    payload: {
        selectedLazrShareTaxes,
    },
})

export const setOrderDetailsMarketplaceErrorsIsSubmitted = (isSubmitted: boolean): Action => ({
    type: SET_ORDER_DETAILS_MARKETPLACE_ERRORS_IS_SUBMITTED,
    payload: isSubmitted,
})

export const setOrderDetailsMarketplaceErrors = (orderDetailsMarketplaceErrors: OrderDetailsMarketplaceErrors): Action => ({
    type: SET_ORDER_DETAILS_MARKETPLACE_ERRORS,
    payload: orderDetailsMarketplaceErrors,
})

export const resetCustomQuoteState = (): Action => ({
    type: RESET_ORDER_DETAIL_CUSTOM_QUOTE_STATE,
    payload: deepCopy(initialCustomQuote),
})

export const setQuoteCostCharges = (quoteCostCharges: QuoteCostCharge[]): Action => ({
    type: SET_QUOTE_COST_CHARGES,
    payload: {
        quoteCostCharges,
    },
})
export const setCharges = (charges: Charge[]): Action => ({
    type: SET_ORDER_DETAIL_CHARGES,
    payload: {
        charges,
    },
})

export const set3plCurrency = (threeplCurrency: Currency): Action => ({
    type: SET_3PL_CURRENCY,
    payload: {
        threeplCurrency,
    },
})

export const addOrderCloseInterval = (orderCloseInterval: number): Action => ({
    type: ADD_ORDER_CLOSE_INTERVAL,
    payload: {
        orderCloseInterval,
    },
})

export const deleteOrderInterval = (orderCloseInterval: number): Action => ({
    type: DELETE_ORDER_INTERVAL,
    payload: {
        orderCloseInterval,
    },
})

export const setCustomsDetail = (customsDetail: OrderInfoCustomsDetail | null, index: number): Action => ({
    type: SET_ORDER_DETAILS_CUSTOMS_DETAIL,
    payload: {
        customsDetail,
        index,
    },
})

export const addCustomsDetail = (): Action => ({
    type: ADD_ORDER_DETAILS_CUSTOMS_DETAIL,
    payload: {
        initialCustomsDetails: deepCopy(initialOrderDetailCustomsDetails),
    },
})

export const deleteCustomsDetail = (index: number): Action => ({
    type: DELETE_ORDER_DETAILS_CUSTOMS_DETAIL,
    payload: {
        index,
    },
})
