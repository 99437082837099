import { UuidV4 } from '@lazr/openapi-client'
import { Currency } from '@lazr/openapi-client'

export interface CurrencyRateProps {
    id: UuidV4
    sourceCurrency: Currency
    targetCurrency: Currency
    lazrRate: string
    updatedAt: string
}

export class CurrencyRate {
    public id: UuidV4
    public sourceCurrency: Currency
    public targetCurrency: Currency
    public lazrRate: string
    public updatedAt: string

    constructor (props: CurrencyRateProps) {
        this.id = props.id
        this.sourceCurrency = props.sourceCurrency
        this.targetCurrency = props.targetCurrency
        this.lazrRate = props.lazrRate
        this.updatedAt = props.updatedAt
    }
}
