import React from 'react'
import { Footer as FooterStyled } from './Footer.styled'
import { Language } from '@lazr/enums'

/* I18N */
import i18n from './Footer.i18n'

export const Footer: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = i18n.activate(props.language.toLowerCase() as 'en' | 'fr')

    return <FooterStyled>
        <img alt='Lazr logo' src="/lazr-logo_blanc.png"/>
        <div>
            {t('Quote faster. Ship smarter.')}
        </div>
    </FooterStyled>
}

interface Props{
    language: Language
}
