import styled from 'styled-components'



import { Grid } from '@material-ui/core'
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Checkbox as MuiCheckbox,
    Grid as MuiGrid,
    Typography as MuiTypography,
    Chip as MuiChip,
    CardHeader as MuiCardHeader,
} from '@material-ui/core'
import { spacing, spacing as MuiSpacing } from '@material-ui/system'



export const Typography = styled(styled(MuiTypography)(MuiSpacing))`

&[class*="MuiTypography-colorError"] {
  color: ${(props): number => props.theme.palette.error900.main};
}
`