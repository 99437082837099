import styled from 'styled-components'

export const Fieldset = styled.fieldset`
    border-radius: 6px;
    border-color: ${(props): string => props.theme.palette.divider};
    border-width: 1px;
    margin: 0;
    padding-top: 0;
    height: 100%;
`

export const Legend = styled.legend`
    padding-right: ${(props): number => props.theme.spacing(1.5)}px;
`
