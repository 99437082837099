import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'
import styled from 'styled-components'

interface ExtendedKeyboardDatePickerProps extends KeyboardDatePickerProps {
  
  ref: any
}

export const CustomKeyboardDatePicker = styled(
  KeyboardDatePicker,
)<ExtendedKeyboardDatePickerProps>`
/* Chrome, Safari, Edge, Opera */

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */

    input[type=number] {
        -moz-appearance: textfield;
    }
    margin-top:-5px;
    label {
        color: ${(props): number => props.theme.palette.neutral800.main};
        &.Mui-focused {
            color: ${(props): number =>
              props.theme.palette.accentPrimary700.main};
         }
         &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
     }

    & [class^="MuiInputLabel-root"] {
        color: blue; /* Change this to the desired color */
        &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    & [class^="MuiFormHelperText-root"] {
        color: ${(props): number => props.theme.palette.neutral500.main};
        margin-left: 0;
        margin-top: ${(props): number => props.theme.spacing(2)}px;

        &.Mui-disabled {
            color: ${(props): number => props.theme.palette.neutral400.main};
        }

        &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    & [class^="MuiInputBase-root"] {
        font-family: 'Roboto';
        margin-top:21px;
        > input, textarea {
            height: 1.358rem;
            color: ${(props): number => props.theme.palette.black.main};
            caret-color: ${(props): number =>
              props.theme.palette.accentPrimary700.main};

            :not(.Mui-disabled)::placeholder {
                color: ${(props): number => props.theme.palette.neutral600.main};
                opacity: 1;
            }

            :not(.Mui-disabled):focus::placeholder {
                color: transparent;
            }
        }

        > textarea {
            padding-top: ${(props): number => props.theme.spacing(0.5)}px;
        }

        [class^="MuiInputAdornment-root"] {
           

            * {
                font-family: 'Roboto';
                line-height: 1.5rem;
            }

            /* Default alignment for p in TextField Adornment */

          

            /* FireFox */
           
        }

        &:hover {
            fieldset {
                border-color: ${(props): number =>
                  props.theme.palette.accentPrimary700.main};
            }

            :not(.Mui-disabled)::before {
                border-bottom-color: ${(props): number =>
                  props.theme.palette.accentPrimary700.main};
            }
        }

        &.Mui-focused {
            fieldset {
                border-color: ${(props): number =>
                  props.theme.palette.accentPrimary700.main};
            }

            ::after {
                border-bottom-color: ${(props): number =>
                  props.theme.palette.accentPrimary700.main};
            }
        }

        &.Mui-error {
            fieldset {
                border-color: ${(props): number =>
                  props.theme.palette.error900.main};
            }

            input, textarea {
                caret-color: ${(props): number =>
                  props.theme.palette.error900.main};
            }

            ::after {
                border-bottom-color: ${(props): number =>
                  props.theme.palette.error900.main};
            }
        }

      
`
export const StyledDatePicker = styled.div`
    background: ${(props): number => props.theme.palette.white.main};
    & [class*="MuiToolbar-root"] {
        background-color: ${(props): number => props.theme.palette.white.main};
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    & [class*="MuiPickersToolbarText-toolbarTxt"] {
        color: ${(props): number => props.theme.palette.neutral800.main};
    }
    & [class*="MuiPickersDay-current"]:not([class*="MuiPickersDay-daySelected"]) {
        background: ${(props): number =>
          props.theme.palette.accentPrimary50.main};
    }
`
