import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService, TransportType } from '@lazr/openapi-client'
import { BillingChargeType } from '../../model'
import { handleUnauthorizedException } from './index'


export interface BillingChargeTypeParams {
    transportTypes?: TransportType[]
}

export const BillingChargeTypeApiService = Object.freeze({
    list: async (params: BillingChargeTypeParams = {}): Promise<BillingChargeType[]> => {
        let getBillingChargeTypesResponse
        try {
            getBillingChargeTypesResponse = await EnumTypesService.getBillingChargeTypes(params.transportTypes)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve billing charge type list')
            }
            throw new Error('Unable to retrieve billing charge type list')
        }

        if (!getBillingChargeTypesResponse.data) {
            logger.debug('list',  JSON.stringify(getBillingChargeTypesResponse, null, 4))
            throw new Error('Unable to retrieve billing charge type list')
        }

        return getBillingChargeTypesResponse.data
    },
})
