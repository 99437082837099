import styled from 'styled-components'

import {
    Box as MuiBox,
    Button as MuiButton,
    Paper,
} from '@material-ui/core'

import { spacing } from '@material-ui/system'

export const Button = styled(MuiButton)(spacing)
export const Box = styled(MuiBox)(spacing)

export const IconContainer = styled(MuiBox)`
    font-size: 15rem;
    text-align: center;
`

export const Wrapper = styled(Paper)<{$isModal: boolean}>`
    margin-top: ${(props): string => props.$isModal ? '0' : '15vh' };
    padding: ${(props): number => props.theme.spacing(6)}px;
    width: 100%;

    ${(props): string => props.theme.breakpoints.up('md')} {
        padding: ${(props): number => props.theme.spacing(10)}px;
    }
`
