/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './TimePicker.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Enter time`]: `Sélectionner l'heure`,
    [`Hours`]: `Heures`,
    [`Minutes`]: `Minutes`,
    [`Ok`]: `Accepter`,
    [`Cancel`]: 'Annuler',
}))()
