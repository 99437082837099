import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialAppState } from '../store/InitialState'
import { StoreAppState } from '../../definitions/Store'


const reducer: Reducer = (state: StoreAppState = initialAppState, action: AnyAction): StoreAppState => {
    switch (action.type) {
        case types.SET_APP_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_ORGANIZATION_LIST:
            return {
                ...state,
                organizationList: action.payload,
                isOrganizationFetched: !!action.payload.length,
            }
        case types.SET_LOCALE_LIST:
            return {
                ...state,
                localeList: action.payload,
                isLocaleFetched: !!action.payload.length,
            }
        case types.SET_TIMEZONE_LIST:
            return {
                ...state,
                timezoneList: action.payload,
                isTimezoneFetched: !!action.payload.length,
            }
        case types.SET_USER_TITLE_LIST:
            return {
                ...state,
                userTitleList: action.payload,
                isUserTitleFetched: !!action.payload.length,
            }
        case types.SET_CURRENCY_LIST:
            return {
                ...state,
                currencyList: action.payload,
                isCurrencyFetched: !!action.payload.length,
            }
        case types.SET_LANGUAGE_LIST:
            return {
                ...state,
                languageList: action.payload,
                isLanguageFetched: !!action.payload.length,
            }
        default:
            return state
    }
}

export default reducer
