import { logger } from '../../logger'
import {
    ApiError,
    ErrorResponse,
    OrderEditingService,
    UuidV4,
    TrackingService,
    Country,
} from '@lazr/openapi-client'
import { TrackingUpdateStatus, ShipmentIdentifierType } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const TrackingApiService = Object.freeze({
    editEstimateDelivery: async (
        id: UuidV4,
        transitDaysMax: number,
        transitDaysMin: number,
        shipmentId: UuidV4,
        estimatedDeliveryDate: string): Promise<UuidV4 | undefined> => {
        let editEstimatedDeliveryResponse
        try {
            editEstimatedDeliveryResponse = await OrderEditingService.setEstimatedDeliveryDate(
                {
                    orderId: id,
                    transitDaysMax: transitDaysMax,
                    transitDaysMin: transitDaysMin,
                    shipmentId: shipmentId,
                    estimatedDeliveryDate: estimatedDeliveryDate,
                },
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('setEstimatedDeliveryDate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update tracking')
            }
            throw new Error('Unable to update tracking')
        }
        if (!editEstimatedDeliveryResponse?.data?.shipmentId) {
            logger.debug('editEstimateDelivery', JSON.stringify(editEstimatedDeliveryResponse, null, 4))
            throw new Error('Unable to update tracking')
        }

        return editEstimatedDeliveryResponse.data.shipmentId
    },
    setCarrierNumbers: async (
        shipmentId: UuidV4,
        type: ShipmentIdentifierType,
        value: string,
        primaryForType: boolean,
    ): Promise<UuidV4 | undefined> => {
        let editCarrierNumbersResponse
        try {
            editCarrierNumbersResponse = await OrderEditingService.setCarrierNumber(
                {
                    shipmentId: shipmentId,
                    type: type,
                    value: value,
                    primaryForType: primaryForType,
                },
            )
            if (!editCarrierNumbersResponse) {
                return
            }

            return editCarrierNumbersResponse?.data?.shipmentIdentifierId
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('editCarrierNumbers', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to edit carrier number')
            }
            throw new Error('Unable to edit carrier number')
        }
    },
    addTrackingUpdate: async (
        shipmentId: UuidV4,
        status: TrackingUpdateStatus,
        statusMessage: string,
        streetAddress: string,
        city: string,
        state: string,
        country: Country,
        postalCode: string,
        trackingUpdateTime: string,
        trackingUpdateTimeSet: boolean,
    ): Promise<UuidV4 | undefined> => {
        let addTrackingUpdateResponse
        try {
            addTrackingUpdateResponse = await TrackingService.addTrackingUpdate({
                shipmentId: shipmentId,
                status: status,
                statusMessage: statusMessage,
                streetAddress: streetAddress,
                city: city,
                state: state,
                country: country,
                postalCode: postalCode,
                trackingUpdateTime: trackingUpdateTime,
                trackingUpdateTimeSet: trackingUpdateTimeSet,
            })

            if (!addTrackingUpdateResponse) {
                return
            }

            return addTrackingUpdateResponse?.data?.trackingUpdateId
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('addTrackingUpdate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add tracking update')
            }
            throw new Error('Unable to add tracking update')
        }
    },
})
