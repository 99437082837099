import React from 'react'
import styled from 'styled-components'

import NumberField from '@/app/ui-new/components/NumberField/NumberField'

import { Box, BoxProps, Typography } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab'
import { IconButton } from '@/app/ui-new/components/IconButton/IconButton'
import { Dialog } from '@/app/ui-new/components/Dialog/Dialog'

export const StyledDialog = styled(Dialog)`
    & [role='dialog']{
        padding: 0;
        background-color: rgba(0, 0, 0, 0);
    }
`

export const StyledContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 328px;
    height: 100%;
    padding: ${(props): string => props.theme.spacing(6)}px;
    background: ${(props): string => props.theme.palette.white.main};
    border: 1px solid ${(props): string => props.theme.palette.neutral300.main};
    border-radius: 4px;
`

export const StyledNumberField = styled(NumberField)`
    & [class^="MuiInputBase-root"] {
        width: 90px;
        font-size: 3rem;
    }

    & [class*="MuiInputBase-root"] input {
        text-align: center;
    }
`

export const StyledTitleTypography = styled(Typography)`
    font-weight: 500;
`

export const StyledDividerTypography = styled(Typography)`
    font-size: 5rem;
    line-height: 70%;
`

export const StyledTimeFormatTypography = styled(Typography)`
    font-size: 1rem;
    font-weight: 500;
`

export const StyledToggleButtonGroup: React.FC<ToggleButtonGroupProps> = styled(ToggleButtonGroup)<ToggleButtonGroupProps>`
    width: 50px;
    & [class*="MuiButtonBase-root"] {
        padding: 5.6px;
        border-color: ${(props): string => props.theme.palette.neutral400.main};
        color: ${(props): string => props.theme.palette.neutral800.main};
        text-align: center;
    }

    & [class*="MuiToggleButton-root"].Mui-selected {
        background-color: ${(props): string => props.theme.palette.accentSecondary100.main};
    }

    & [class*="MuiToggleButton-root"]:hover {
        background-color: ${(props): string => props.theme.palette.accentSecondary50.main};
    }
`

export const StyledIconButton = styled(IconButton)`
    border: none;
    & [class*="MuiIconButton-label"] {
        margin-left: 1px;
    }
`
