import React from 'react'
import {
    Wrapper,
    StyledContentTypography,
    StyledTitleTypography1,
    StyledTitleTypography2, StyledTitleTypography3,
} from './DevelopedByMobile.styled'
import { Box } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './DevelopedByMobile.i18n'

const DevelopedByMobile: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    return (
        <Wrapper>
            <Box width={375}>
                <Box px={4} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <StyledTitleTypography1 variant={'h1'}>
                            {t('Developed by a')}
                        </StyledTitleTypography1>
                        <div>
                            <StyledTitleTypography2 display={'inline'} variant={'h1'}>
                                {t('Leading')}
                            </StyledTitleTypography2>
                            <StyledTitleTypography3 display={'inline'} variant={'h1'}>
                                {t('logistics company')}
                            </StyledTitleTypography3>
                        </div>
                        <Box mt={8}>
                            <StyledContentTypography variant={'h5'}>
                                {/* eslint-disable-next-line max-len */}
                                {t('Easily manage your business’ freight and parcel shipping. Lazr’s proprietary platform helps improve the efficiency and effectiveness of your entire shipping operations—saving time and reducing costs for everyone involved.')}
                            </StyledContentTypography>
                        </Box>
                        <Box mt={8}>
                            <StyledContentTypography variant={'h5'}>
                                {/* eslint-disable-next-line max-len */}
                                {t('Our cutting-edge algorithm gives you access in seconds to a complete and comprehensive view of shipping rates in the USA and Canada, so you know which carriers and at what price your shipment can be handled.')}
                            </StyledContentTypography>
                        </Box>
                    </Box>
                    <Box mt={12}>
                        <img width={'100%'} src={`/images/website/developedBy/LeadingLogisticsCompany.png?v=${window.lazrVersion}`}
                            alt="Developed by" />
                    </Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export interface Props {
}

export default DevelopedByMobile
