import {
    ApiError,
    ErrorResponse,
    GenerateLabelResponse,
    LabelService,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const LabelGeneratorApiService = Object.freeze({
    generateLabel: async (
        orderId: string
    ): Promise<GenerateLabelResponse | string> => {
        const genericError = 'An unexpected error occurred while generating the Label'

        try {
            const res = await LabelService.generateLabel({
                orderId: orderId,
            })

            if (res.data) {
                return res.data
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message === 'No shipments attached to the order provided.') {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
})
