import { AnyAction, Reducer } from 'redux'
import * as types from '../constants'
import {  initialOrderDetails } from '../store/InitialState'
import { OrderBillingErrors, OrderDetails, OrderDetailsMarketplaceErrors, OrderSummaryErrors } from '../../definitions/OrderDetails'
import {  OrderInfo, QuoteCostCharge } from '@/app/model'
import { deepCopy } from '@lazr/utilities'
import { Charge } from '../../pages/orders/Details/Sections/components/CostCharges'

const reducer: Reducer = (state: OrderDetails = initialOrderDetails, action: AnyAction): OrderDetails  => ({
    ...orderDetailsReducer(state, action),
    order: order(state.order, action),
    editableOrder: editableOrder(state.editableOrder, action),
    orderSummaryErrors: orderSummaryErrors(state.orderSummaryErrors, action),
    orderDetailsMarketplaceErrors: orderDetailsMarketplaceErrors(state.orderDetailsMarketplaceErrors, action),
    charges: charges(state.charges, action),
    orderBillingErrors: orderBillingErrors(state.orderBillingErrors, action),
})
const orderSummaryErrors = (state: OrderSummaryErrors, action: AnyAction): OrderSummaryErrors => {
    switch (action.type) {
        case types.SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS:
            return action.payload
        case types.SET_ORDER_DETAILS_ORDER_SUMMARY_ERRORS_IS_SUBMITTED:
            return {
                ...state,
                isSubmitted: action.payload,
            }
        default:
            return state
    }
}

const orderBillingErrors = (state: OrderBillingErrors, action: AnyAction): OrderBillingErrors => {
    switch (action.type) {
        case types.SET_ORDER_DETAILS_ORDER_BILLING_ERRORS:
            return action.payload
        case types.SET_ORDER_DETAILS_ORDER_BILLING_ERRORS_IS_SUBMITTED:
            return {
                ...state,
                isSubmitted: action.payload,
            }
        default:
            return state
    }
}

const orderDetailsMarketplaceErrors = (state: OrderDetailsMarketplaceErrors, action: AnyAction): OrderDetailsMarketplaceErrors => {
    switch (action.type) {
        case types.SET_ORDER_DETAILS_MARKETPLACE_ERRORS:
            return action.payload
        case types.SET_ORDER_DETAILS_MARKETPLACE_ERRORS_IS_SUBMITTED:
            return {
                ...state,
                isSubmitted: action.payload,
            }
        case types.SET_ORDER_DETAILS_PENDING_PRICE_REVIEW_ERRORS:
            return action.payload
        default:
            return state
    }
}

const editableOrder = (state: Partial<OrderInfo>, action: AnyAction): Partial<OrderInfo> => {
    switch (action.type) {
        case types.SET_ORDER_DETAILS_ORDER:
            return deepCopy(action.payload)
        case types.SET_ORDER_DETAILS_EDITABLE_ORDER:
            return { ...state, ...action.payload }
        case types.SET_ORDER_DETAILS_EDITABLE_RFQ:
            return {
                ...state,
                rfq: {
                    ...state.rfq,
                    ...action.payload,
                },
            }
        case types.SET_ORDER_DETAILS_CUSTOMS_DETAIL: {
            if (state.rfq?.customsDetails && state.rfq.customsDetails[action.payload.index]) {
                state.rfq.customsDetails[action.payload.index] = action.payload.customsDetail
            }

            return {
                ...state,
            }
        }
        case types.ADD_ORDER_DETAILS_CUSTOMS_DETAIL: {
            if (!state.rfq?.customsDetails && state.rfq) {
                state.rfq.customsDetails = []
            }
            state.rfq?.customsDetails?.push(action.payload.initialCustomsDetails)

            return {
                ...state,
            }
        }
        case types.DELETE_ORDER_DETAILS_CUSTOMS_DETAIL: {
            state?.rfq?.customsDetails?.splice(action.payload.index, 1)

            return {
                ...state,
            }
        }
        default:
            return state
    }
}

const order = (state: OrderInfo | null, action: AnyAction): OrderInfo | null => {
    switch (action.type) {
        case types.SET_ORDER_DETAILS_ORDER:
            return action.payload
        default:
            return state
    }
}
const charges = (state: Charge[], action: AnyAction): Charge[] => {
    switch (action.type) {
        case types.SET_ORDER_DETAIL_CHARGES:
            return action.payload.charges
        default:
            return state
    }
}

const orderDetailsReducer: Reducer = (state: OrderDetails = initialOrderDetails, action: AnyAction): OrderDetails => {
    switch (action.type) {
        case types.SET_QUOTE_COST_CHARGE: {
            const newQuoteCostCharges = state.quoteCostCharges
            newQuoteCostCharges[action.payload.index] = action.payload.quoteCostCharge

            return {
                ...state,
                quoteCostCharges: newQuoteCostCharges,
                threeplTotalCadPrice: newQuoteCostCharges.reduce(
                    (total, price) => total + (price.totalCad || 0), 0,
                ),
                threeplTotalUsdPrice: newQuoteCostCharges.reduce(
                    (total, price) => total + (price.totalUsd || 0), 0,
                ),
                threeplTotalCadCost: newQuoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawCad || 0),  0,
                ),
                threeplTotalUsdCost: newQuoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawUsd || 0),  0,
                ),
            }
        }
        case types.ADD_QUOTE_COST_CHARGE:{
            const newOrderDetailsMarketplaceErrors = state.orderDetailsMarketplaceErrors
            newOrderDetailsMarketplaceErrors.chargesErrors.push({})

            const newQuoteCostCharges = state.quoteCostCharges
            newQuoteCostCharges.push(action.payload)

            return {
                ...state,
                quoteCostCharges: newQuoteCostCharges,
                orderDetailsMarketplaceErrors: newOrderDetailsMarketplaceErrors,
            }
        }
        case types.DELETE_QUOTE_COST_CHARGE:{
            const newQuoteCostCharges = state.quoteCostCharges
            newQuoteCostCharges.splice(action.payload, 1)

            return {
                ...state,
                quoteCostCharges: newQuoteCostCharges,
                threeplTotalCadPrice: newQuoteCostCharges.reduce(
                    (total, price) =>
                        total + (price.totalCad || 0),
                    0,
                ),
                threeplTotalUsdPrice: newQuoteCostCharges.reduce(
                    (total, price) =>
                        total + (price.totalUsd || 0),
                    0,
                ),
                threeplTotalCadCost: newQuoteCostCharges.reduce(
                    (total, cost) =>
                        total + (cost.rawCad || 0),
                    0,
                ),
                threeplTotalUsdCost: newQuoteCostCharges.reduce(
                    (total, cost) =>
                        total + (cost.rawUsd || 0),
                    0,
                ),
            }
        }
        case types.SET_3PL_ADDITIONAL_INFO: {
            return {
                ...state,
                additionalInfo: action.payload.additionalInfo,
            }
        }
        case types.SET_3PL_ESTIMATED_TRANSIT_MIN: {
            return {
                ...state,
                threeplEstimatedTransitMin: action.payload.threeplEstimatedTransitMin,
            }
        }
        case types.SET_3PL_ESTIMATED_TRANSIT_MAX: {
            return {
                ...state,
                threeplEstimatedTransitMax: action.payload.threeplEstimatedTransitMax,
            }
        }
        case types.SET_3PL_ETA: {
            return {
                ...state,
                threeplEta: action.payload.threeplEta,
            }
        }
        case types.SET_3PL_VALID_UNTIL: {
            return {
                ...state,
                threeplValidUntil: action.payload.threeplValidUntil,
            }
        }
        case types.SET_LAST_INVOICE_PICKUP_DATE:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_TRANSPORT_SELECTED_INVOICE_DOCUMENTS:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_SELECTED_TRANSPORT_INVOICE_NUMBER:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_SELECTED_CLIENT_TAXES:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_SELECTED_LAZR_SHARE_TAXES:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_SELECTED_TRANSPORT_TAXES:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_INVOICE_DATE:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_CALCULATED_DUE_DATE:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_CARRIER_PAYMENT_TERMS_DAYS:
            return {
                ...state,
                ...action.payload,
            }
        case types.RESET_ORDER_DETAIL_CUSTOM_QUOTE_STATE:
            return {
                ...state,
                ...action.payload,
            }
        case types.SET_QUOTE_COST_CHARGES: {
            const quoteCostCharges: QuoteCostCharge[] = action.payload.quoteCostCharges

            return {
                ...state,
                quoteCostCharges: quoteCostCharges,
                threeplTotalCadPrice: quoteCostCharges.reduce(
                    (total, price) => total + (price.totalCad || 0), 0,
                ),
                threeplTotalUsdPrice: quoteCostCharges.reduce(
                    (total, price) => total + (price.totalUsd || 0), 0,
                ),
                threeplTotalCadCost: quoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawCad || 0),  0,
                ),
                threeplTotalUsdCost: quoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawUsd || 0),  0,
                ),
            }
        }
        case types.SET_3PL_CURRENCY: {
            return {
                ...state,
                threeplCurrency: action.payload.threeplCurrency,
            }
        }
        case types.ADD_ORDER_CLOSE_INTERVAL: {
            const orderCloseIntervals = state.orderCloseIntervals
            orderCloseIntervals.push(action.payload.orderCloseInterval)

            return {
                ...state,
                orderCloseIntervals: orderCloseIntervals,
            }
        }
        case types.DELETE_ORDER_INTERVAL: {
            const orderCloseIntervals = state.orderCloseIntervals
            const index = orderCloseIntervals.indexOf(action.payload.orderCloseInterval, 0)
            if (index > -1) {
                orderCloseIntervals.splice(index, 1)
            }

            return {
                ...state,
                orderCloseIntervals: orderCloseIntervals,
            }
        }
        default:
            return state
    }
}
export default reducer
