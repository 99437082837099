import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import {
    AppRoute,
    authenticatedRoutes,
    publicRoutes,
    ediRoutes,
    systemRoutes,
    unauthenticatedRoutes,
} from './index'
import RootLayout from '@/app/ui-new/layouts/RootLayout'
import Page404 from '@/app/ui-new/pages/error/Page404'
import Page401 from '@/app/ui-new/pages/error/Page401'
import { useSelector } from 'react-redux'
import { getUserOrganization as reduxGetUserOrganization } from '../redux/selectors/UserOrganizationSelectors'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors'

interface UserPermissions {
    'performSearch': boolean
    'viewCompanyTariffQuotes': boolean
    'viewCompanyTariffQuotesPrice': boolean
    'allowPrepaidPaymentTerms': boolean
    'allowCollectPaymentTerms': boolean
    'submitOrder': boolean
    'viewAllCompanyOrders': boolean
    'viewCompanyInvoices': boolean
    'viewCompanyAddressBook': boolean
}

const renderRoutes = (allRoutes: AppRoute[], userPermissions?: UserPermissions): React.ReactNode[] =>
    allRoutes.map(
        (
            {
                children,
                path: pathA,
                component: ComponentA,
                layout: LayoutA,
                contentFooter: ContentFooterA,
                exact: exactA,
                permissionKey: permissionKeyA,
            },
            indexA,
        ) =>
            children ? (
                children.map(
                    (
                        {
                            path: pathB,
                            component: ComponentB,
                            layout: LayoutB,
                            exact: exactB,
                            permissionKey: permissionKeyB,
                        },
                        indexB,
                    ) => (
                        <Route
                            key={indexB}
                            path={pathB}
                            exact={!!exactB}
                            render={(props): React.ReactNode => {
                                if ((!userPermissions || !permissionKeyA) ||
                                    userPermissions[permissionKeyA as unknown as keyof UserPermissions]) {
                                    return (
                                        <LayoutB>
                                            <ComponentB {...props} />
                                        </LayoutB>
                                    )
                                } else {
                                    return (
                                        <RootLayout>
                                            <Page401/>
                                        </RootLayout>
                                    )
                                }
                            }}
                        />
                    ),
                )
            ) : (
                <Route
                    key={indexA}
                    path={pathA}
                    exact={!!exactA}
                    render={(props): React.ReactNode => {
                        if (LayoutA && ComponentA) {
                            if ((!userPermissions || !permissionKeyA) ||
                                userPermissions[permissionKeyA as unknown as keyof UserPermissions]) {
                                return (
                                    <LayoutA contentFooter={ContentFooterA}>
                                        <ComponentA {...props} />
                                    </LayoutA>
                                )
                            } else {
                                return (
                                    <RootLayout>
                                        <Page401/>
                                    </RootLayout>
                                )
                            }
                        }

                        return null
                    }}
                />
            ),
    )

const Routes: React.FunctionComponent<Props> = (): JSX.Element => {
    const currentUser = useSelector(reduxGetUser)
    const organization = useSelector(reduxGetUserOrganization)

    return (
        <Switch>
            {organization?.isSystem() && renderRoutes(systemRoutes)}
            {renderRoutes(publicRoutes)}
            {renderRoutes(unauthenticatedRoutes)}
            {renderRoutes(authenticatedRoutes, currentUser?.permissions)}
            {(!organization?.isClient() || organization?.hasEdiSupport) && renderRoutes(ediRoutes)}
            <Route>
                <RootLayout>
                    <Page404/>
                </RootLayout>
            </Route>
        </Switch>
    )
}

export interface Props {}

export default withRouter(Routes)
