import { Address } from '@/app/model/Address'
import type {
    AccessorialLocationType,
    AccessorialType,
    AddressType,
    CollectAccountType,
    Country,
    TransportType,
    UuidV4,
} from '@lazr/openapi-client'

interface UserBillToAddress {
    id: UuidV4
    organizationId: UuidV4
    name: string
    description: string
    streetAddressLine1: string
    streetAddressLine2: string | null
    streetAddressLine3: string | null
    city: string
    state: string
    postalCode: string
    addressType: AddressType
    detectedAddressType: AddressType | null
    companyLegalName: string
    companyName: string
    shippingContactName: string
    shippingContactEmails?: string[]
    shippingContactPhone?: string
    shippingContactPhoneExtension: string
    billingContactName?: string
    billingContactEmails?: string[]
    billingContactPhone?: string
    billingContactPhoneExtension?: string
    notify: boolean
    instructions: string
    openTime?: string
    closeTime?: string
    updatedAt: string
    createdAt: string
    country: {
        code: Country
        name: string
        isSupported: boolean
    }
    accessorials: {
        id: UuidV4
        isRemovable: boolean
        accessorial: {
            id: UuidV4
            code: string
            name: string
            type: AccessorialType
            transportTypes: TransportType[]
            locationType: AccessorialLocationType
            forceCustomQuote: boolean
        }
    }[]
    isShipping: boolean
    isBilling: boolean
    isBillingDefault: boolean
    isShippingDefault: boolean
    collectAccounts: CollectAccount[] | null
}

export interface CollectAccount {
    id: UuidV4
    carrier: {
        id: UuidV4
        name: string
    }
    accountNumber: string
    accountType: CollectAccountType
}

export interface BillToAddressProps {
    id: UuidV4
    organizationId: UuidV4
    userId: UuidV4
    billTo: UserBillToAddress
}

export class BillToAddress {
    public id: UuidV4
    public organizationId: UuidV4
    public userId: UuidV4
    public billTo: Address

    constructor (props: BillToAddressProps) {
        this.id = props.id
        this.organizationId = props.organizationId
        this.userId = props.userId
        this.billTo = new Address(props.billTo)
    }
}
