import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo, IconLogo } from './Logo.styled'

const LazrLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={<IconLogo />}
        name={'Lazr Freight'}
    />

export default LazrLogo
