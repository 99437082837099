import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import MuiDialog from '@material-ui/core/Dialog'
import { TransitionProps as MuiTransitionProps } from '@material-ui/core/transitions'
import MuiToolbar from '@material-ui/core/Toolbar'
import MUIClose from '@material-ui/icons/Close'
import { transparentize } from 'polished'
import { Typography as MuiTypography } from '@material-ui/core'

export const Dialog = styled(styled(MuiDialog)(spacing))<{ selectable?: boolean}>`
    user-select: ${(props): string => props.selectable ? 'text' : 'none'} ;

    & [role='dialog']{
        padding: ${(props): string => props.theme.spacing(6)}px;
        border-radius: unset;
    }

    &.small{
        & [role='dialog']{
            width: 312px;
            min-width: 312px;
        }
    }

    &.dialog-top{
        & [role='none presentation']{
            align-items: start;
        }

        & [role='dialog']{
            width: 100vw;
            max-width: unset;
            max-height: unset;
            margin: 0;
        }
    }

    &.dialog-right{
        & [role='none presentation']{
            justify-content: end;
        }

        & [role='dialog']{
            height: 100vh;
            margin: 0;
            max-width: unset;
            max-height: unset;
        }
    }

    &.dialog-bottom{
        & [role='none presentation']{
            align-items: end;
        }

        & [role='dialog']{
            width: 100vw;
            margin: 0;
            max-width: unset;
            max-height: unset;
        }
    }

    &.dialog-left{
        & [role='none presentation']{
            justify-content: start;
        }

        & [role='dialog']{
            height: 100vh;
            max-width: unset;
            max-height: unset;
            margin: 0;
        }
    }
`

export type TransitionProps = MuiTransitionProps

export const DialogTitle = styled(styled(MuiTypography)(spacing))`
    &.dialog-text-centered{
        text-align: center;
    }
`

export const DialogText = styled(styled.div(spacing))`
    flex-grow: 1;

    &.dialog-text-centered {
        text-align: center;
    }
`

export const DialogButtonContainer = styled(styled.div(spacing))`
    padding: ${(props): string => props.theme.spacing(6)}px 0 0 0;
    display: flex;
    justify-content: flex-end;

    & button + button{
        margin-left: ${(props): string => props.theme.spacing(4)}px;
    }

    &.dialog-buttons-centered{
        & button{
            width: 100%;
        }
        & button + button{
            margin-left: ${(props): string => props.theme.spacing(4)}px;
        }
    }

    &.dialog-buttons-stacked{
        display: block;

        & button, & a{
            width: 100%;
        }
        & button + button, & a + a, & button + a, & a + button{
            margin-left: unset;
            margin-top: ${(props): string => props.theme.spacing(4)}px;
        }
    }
`

export const DialogImageContainer = styled(styled.div(spacing))`
    width: 100%;
    padding: 0 0 ${(props): string => props.theme.spacing(4)}px 0;
    display: flex;
    justify-content: center;

    & img{
        max-width: 100%;
    }

    & svg{
        font-size: 3rem;
        color: orange;
    }
`

export const DialogToolbar = styled(MuiToolbar)`
    padding: 0;
    border-bottom: 1px solid ${(props): string => props.theme.palette.neutral200.main};
`

export const DialogToolbarTitle = styled(MuiTypography)`
    flex-grow: 1;
`

export const DialogCloseIcon = styled(styled(MUIClose)(spacing))`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    font-size: 28px;
    padding: 3px;
    cursor: pointer;

    &:hover{
        background-color: ${(props): string => transparentize(0.94, props.theme.palette.neutral800.main)};
        border-radius: 50%;
    }
`

export const DialogTypography = styled(MuiTypography)`
`