import { logger } from '../../logger'
import {
    ApiError,
    BillingService,
    ErrorResponse,
    InvoiceService,
    LateInvoicePaymentsResponse,
    Provider,
    TransportType,
    UuidV4,
    QuoteCharge,
} from '@lazr/openapi-client'
import { PriceReview, PriceReviewAttributes, QuoteCostCharge } from '../../model'
import { handleUnauthorizedException } from './index'

interface ApplyMarginParams {
    provider: Provider
    transportType: TransportType
    selectedQuoteId: UuidV4
    newCostCharges: QuoteCostCharge[]
}

export const BillingApiService = Object.freeze({
    reviewOrderPrice: async (priceReviewAttributes: PriceReviewAttributes): Promise<void> => {
        let reviewOrderPriceResponse
        try {
            reviewOrderPriceResponse = await BillingService.reviewOrderPrice({
                orderId: priceReviewAttributes.orderId,
                rawCad: priceReviewAttributes.rawCad,
                rawUsd: priceReviewAttributes.rawUsd,
                totalCad: priceReviewAttributes.totalCad,
                totalUsd: priceReviewAttributes.totalUsd,
                currency: priceReviewAttributes.currency,
                markAsCompleted: priceReviewAttributes.markAsCompleted,
                completeReason: priceReviewAttributes.completeReason,
                charges: priceReviewAttributes.charges.map((charge) =>
                    ({
                        seqNumber: charge.seqNumber,
                        rawCad: charge.rawCad,
                        rawUsd: charge.rawUsd,
                        totalCad: charge.totalCad,
                        totalUsd: charge.totalUsd,
                        code: charge.code,
                        description: charge.description,
                    })),
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('reviewOrderPrice', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to review order price')
            }
            throw new Error('Unable to review order price')
        }

        if (!reviewOrderPriceResponse.data) {
            logger.debug('reviewOrderPrice', JSON.stringify(reviewOrderPriceResponse, null, 4))
            throw new Error('Unable to review order price')
        }
    },
    get: async (id: string): Promise<PriceReview> => {
        let getOrderPriceReviewByIdResponse
        try {
            getOrderPriceReviewByIdResponse = await BillingService.getOrderPriceReviewById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve order price by id')
            }
            throw new Error('Unable to retrieve order price by id')
        }

        if (!getOrderPriceReviewByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getOrderPriceReviewByIdResponse, null, 4))
            throw new Error('Unable to retrieve order price by id')
        }

        return new PriceReview(getOrderPriceReviewByIdResponse.data.priceReview)
    },
    list: async (orderId: string): Promise<PriceReview[]> => {
        let getOrderPriceReviewsResponse
        try {
            getOrderPriceReviewsResponse = await BillingService.getOrderPriceReviews(orderId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve price review list')
            }
            throw new Error('Unable to retrieve price review list')
        }

        if (!getOrderPriceReviewsResponse.data) {
            logger.debug('list', JSON.stringify(getOrderPriceReviewsResponse, null, 4))
            throw new Error('Unable to retrieve price review list')
        }

        return getOrderPriceReviewsResponse.data.orderPriceReviews.map((priceReview) => new PriceReview(priceReview))
    },

    listUnpaidInvoices: async (): Promise<LateInvoicePaymentsResponse[]> => {
        const res = await InvoiceService.getLateInvoicePayments()

        return res.data ?? []
    },

    applyMargin: async (params: ApplyMarginParams): Promise<QuoteCharge[] | undefined> => {
        try {
            const result = await BillingService.applyMargin(
                {
                    applyMarginParams:
                        {
                            ...params,
                            newCostCharges: params.newCostCharges as unknown as QuoteCharge[],
                        },
                })

            return result.data?.quoteCharge
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('Apply Margin', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to apply margin')
            }
            throw new Error('Unable to apply margin')
        }
    },
})
