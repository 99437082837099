import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logger } from '../../../logger'
import { TimezoneApiService } from '../../../service/ApiService'
import { setTimezoneList as reduxSetTimezoneList } from '../../redux/actions/TimezoneListActions'
import { getTimezoneList as reduxGetTimezoneList } from '../../redux/selectors/AppStateSelectors'
import { getUserIsSignedIn as reduxGetUserIsSignedIn } from '../../redux/selectors/UserSelectors'

export const useTimezoneList = () => {
    const dispatch = useDispatch()
    const isUserSignedIn = useSelector(reduxGetUserIsSignedIn)

    const { timezoneList, isTimezoneListFetched } = useSelector(reduxGetTimezoneList)

    React.useEffect(() => {
        void (async (): Promise<void> => {
            try {
                if (!isUserSignedIn || isTimezoneListFetched) {
                    return
                }
                const timezoneListResponse = await TimezoneApiService.list()
                dispatch(reduxSetTimezoneList(timezoneListResponse))
            } catch (error: any) {
                logger.error('could not fetch timezone list', error)
            }
        })()
    }, [])

    return [ timezoneList ]
}
