import React from 'react'
import { Snackbar } from '../Snackbar/Snackbar'
import { SnackbarProviderStyled, NotistackRoot } from './Notistack.styled'
import { SnackbarProviderProps } from 'notistack'

export const Notistack = (): JSX.Element => (
    <Snackbar/>
)

export const NotistackProvider = (props: SnackbarProviderProps): JSX.Element => (
    <NotistackRoot className='NotistackRoot'>
        <SnackbarProviderStyled {...props} className='notistack-provider'/>
    </NotistackRoot>
)
