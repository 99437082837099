import React from 'react'
import {
    Wrapper,
} from './NewEfficiencyZeroFrictionDesktop.styled'
import { Box } from '@material-ui/core'
import {
    NewEfficiencyZeroFrictionDesktop as NewEfficiencyZeroFriction,
} from '../NewEfficiencyZeroFrictionDesktop'


const NewEfficiencyZeroFrictionDesktop: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={1224}>
            <NewEfficiencyZeroFriction/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default NewEfficiencyZeroFrictionDesktop
