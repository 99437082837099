import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    Box as MuiBox,
    Grid as MuiGrid,
    Popover as MuiPopover,
} from '@material-ui/core'

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Menu = styled(MuiPopover)`
    z-index: 2147483645;

    & [class^='MuiPaper-root'] {
        position: fixed;
        top: 80px;
        right: 24px;
        width: 320px;
    }
`

export const AdminPrivilegesBox = styled(MuiBox)`
    display: flex;
    flex-direction: row;
    justify-content: end;

    & [class^='MuiTypography-root'] {
        font-size: 0.875rem;
    }
`
