import React from 'react'
import {
    Wrapper,
} from './VideoHolderMobile.styled'
import { Box } from '@material-ui/core'
import { VideoHolderMobile as VideoHolder } from '../VideoHolderMobile'


const VideoHolderMobile: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={375}>
            <VideoHolder/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default VideoHolderMobile
