import React, { useRef, useState } from 'react'
import reduxSetSnackbar from '@/app/ui/redux/actions/SnackbarActions'
import { Color } from '@material-ui/lab'
import { IconButton, MenuItem } from './Header.styled'
import { Language as LanguageCode, Locale as LocaleCode } from '@lazr/openapi-client'
import { Language, User } from '@/app/model'
import { Menu, Tooltip, Typography } from '@material-ui/core'
import { UserApiService } from '@/app/service/ApiService'
import { logger } from '@/app/logger'
import { setUser as reduxSetUser } from '@/app/ui/redux/actions/UserActions'
import { setLanguage as reduxSetLanguage } from '@/app/ui/redux/actions/LanguageActions'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'
import { getLanguageList as reduxGetLanguageList } from '../redux/selectors/AppStateSelectors'

const LanguageMenu: React.FunctionComponent<LanguageMenuProps> = ({ user }) => {
    const { languageList } = useSelector(reduxGetLanguageList)
    const language = useSelector(reduxGetLanguage)

    const dispatch = useDispatch()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const displayMessage = (message: string, severity: Color): void => {
        dispatch(
            reduxSetSnackbar({
                autoHideDuration: 5000,
                message: message,
                open: true,
                origin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                severity: severity,
            }),
        )
    }

    const handleSelectedLanguage = async (lang: Language): Promise<void> => {
        if (!user) {
            dispatch(reduxSetLanguage(lang.code))

            setIsMenuOpen(false)

            return
        }

        if (user.language.code === lang.code) {
            setIsMenuOpen(false)

            return
        }

        try {
            await UserApiService.editProfile({
                id: user.id,
                organizationId: user.organizationId,
                locale: lang.code === LanguageCode.FR ? LocaleCode.FR_CA : LocaleCode.EN_CA,
                language: lang.code,
            })

            const currentUser = await UserApiService.getCurrent()

            if (user.getImpersonatingInfo().active) {
                currentUser.impersonate(user.getImpersonatingInfo())
            }

            dispatch(reduxSetUser(currentUser))
            dispatch(reduxSetLanguage(currentUser.language.code))
        } catch (error: any) {
            logger.error(error)
            displayMessage('An unexpected error occurred while saving your language preference', 'error')
        }

        setIsMenuOpen(false)
    }
    const impersonatingInfo = user?.getImpersonatingInfo()

    return <React.Fragment>
            <IconButton
                ref={buttonRef}
                aria-owns={isMenuOpen ? 'menu-appbar' : ''}
                aria-haspopup='true'
                onClick={toggleMenu}
                color='inherit'
            >
                <Typography variant='body1' align='center'>
                    {language}
                </Typography>
            </IconButton>
            <Menu id='menu-appbar' anchorEl={buttonRef.current} open={isMenuOpen} onClose={(): void => setIsMenuOpen(false)}>
                {languageList?.map((item) => (
                    <MenuItem
                        key={item.code}
                        value={item.code}
                        onClick={(event) => {
                            void (async (): Promise<void> => {
                                await handleSelectedLanguage(item)
                            })()
                        }}
                    >
                        {item.code}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
}

interface LanguageMenuProps {
    user?: User | null
}

export default LanguageMenu
