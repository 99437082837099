/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrackingFilterStatus {
    DISPATCHED = 'DISPATCHED',
    PICKUP = 'PICKUP',
    IN_TRANSIT = 'IN_TRANSIT',
    DELAYED = 'DELAYED',
    DELIVERY = 'DELIVERY',
    EXCEPTION = 'EXCEPTION',
}