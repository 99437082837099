import React from 'react'
import { Box, Grid } from '@material-ui/core'
import {
    StyledH1Typography, StyledListItem,
    StyledListItemTypography, StyledNewWayBackground,
    StyledParagraphTypography, StyledStatBox, StyledStatContainer,
    StyledTitleTypography,
} from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/OldVsNewWayDesktop.styled'
import OldWayHeader from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/OldWayHeader'
import NewWayHeader from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/NewWayHeader'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './OldVsNewWayDesktop.i18n'

const OldVsNewWayDesktop: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return (
        <>
            <Box width={'100%'} display={'flex'} justifyContent={'center'} bgcolor={'#FFF'}>
                <Box width={1240} py={25}>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <Box width={430}>
                                <StyledTitleTypography variant={'h1'}>
                                    {t('Ship')} <span className={'lzr-color-purple'}>{t('better')}</span>  {t('in every way')}
                                </StyledTitleTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box width={420}>
                                <StyledParagraphTypography variant={'h6'}>
                                    {t('Traditional shipping takes too long and costs too much')}.
                                </StyledParagraphTypography>
                                <Box mt={4}>
                                    <StyledParagraphTypography variant={'h6'}>
                                        {t('Get Lazr to take care of all your cross-border shipping needs and more')}.
                                    </StyledParagraphTypography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={18}>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <OldWayHeader/>
                                <Box mr={9}>
                                    <Box mt={13} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconOldway1.svg?v=${window.lazrVersion}`}
                                                alt="IconOldWay1"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            52+ {t('minutes to complete booking, documentation and billing')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconOldway2.svg?v=${window.lazrVersion}`}
                                                alt="IconOldWay2"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('Limited options from one or two carriers')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconOldway3.svg?v=${window.lazrVersion}`}
                                                alt="IconOldWay3"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('Scattered shipment tracking')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconOldway4.svg?v=${window.lazrVersion}`}
                                                alt="IconOldWay4"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('Multiple accounts with different logins')}
                                        </StyledListItemTypography>
                                    </Box>
                                </Box>

                            </Grid>
                            <Grid item xs={6}>
                                <NewWayHeader/>
                                <StyledNewWayBackground>
                                    <Box pt={13} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconLazrway1.svg?v=${window.lazrVersion}`}
                                                alt="IconLazrWay1"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('Less than 3 minutes to complete booking, documentation and billing')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconLazrway2.svg?v=${window.lazrVersion}`}
                                                alt="IconLazrWay2"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('Instant shipping rates from dozens of carriers')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconLazrway3.svg?v=${window.lazrVersion}`}
                                                alt="IconLazrWay3"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('A single tracking view of all shipments, everywhere')}
                                        </StyledListItemTypography>
                                    </Box>
                                    <Box mt={4} display={'flex'} alignItems={'center'}>
                                        <StyledListItem>
                                            <img src={`/images/website/OldVsNewWay/IconLazrway4.svg?v=${window.lazrVersion}`}
                                                alt="IconLazrWay4"/>
                                        </StyledListItem>
                                        <StyledListItemTypography variant={'body1'}>
                                            {t('All freight & parcel carriers in one place')}
                                        </StyledListItemTypography>
                                    </Box>
                                </StyledNewWayBackground>
                            </Grid>
                        </Grid>
                    </Box>

                    <StyledStatContainer maxWidth={1224} display={'flex'} justifyContent={'center'} mt={11}>
                        <Grid container xs={12} spacing={0}>
                            <Grid item xs={3}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>50+</StyledH1Typography>
                                    <StyledListItemTypography>{t('Connected freight carriers')}</StyledListItemTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={3}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>8</StyledH1Typography>
                                    <StyledListItemTypography>{t('Connected parcel carriers')}</StyledListItemTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={3}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>21%</StyledH1Typography>
                                    <StyledListItemTypography>{t('Average savings in shipping costs')}</StyledListItemTypography>
                                </StyledStatBox>
                            </Grid>
                            <Grid item xs={3}>
                                <StyledStatBox>
                                    <StyledH1Typography variant={'h1'}>95%</StyledH1Typography>
                                    <StyledListItemTypography>{t('Faster than traditional quoting')}</StyledListItemTypography>
                                </StyledStatBox>
                            </Grid>
                        </Grid>
                    </StyledStatContainer>
                </Box>
            </Box>
        </>
    )
}

export default OldVsNewWayDesktop
