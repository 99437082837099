import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const NmTransferLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/nmtransfer-logo.png?v=${window.lazrVersion}`}
        name={'N&M Transfer Co Inc'}
    />

export default NmTransferLogo
