/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    'Handling Unit': 'Handling Unit',
    Filters: 'Filters',
    'Start Tour': 'Start Tour',
    'Transport Type': 'Transport Type',
    'Select the carrier': 'Select the carrier',
    Currency: 'Currency',
    Quantity: 'Quantity',
    'Quantity required': 'Quantity required',
    'Quantity must be at least qtd characters long': 'La quantité doit comporter au moins quantité caractères',
    'Carrier Code': 'Carrier Code',
    Location: 'Location',
    'Service Level Code': 'Service Level Code',
    Timezone: 'Timezone',
    'Open Time': 'Open Time',
    'Close Time': 'CloseTime',
    Date: 'Date',
    'is required': 'is required',
    completed: 'completed',
    'Is Residential?': 'Is Residential?',
    Yes: 'Yes',
    Carrier: 'Carrier',
    Packages: 'Packages',
    'Total Weight': 'Total Weight',
    'All packages': 'All packages',
    'Open Time must be earlier than Close Time': 'Open Time must be earlier than Close Time',
    'The date must be greater than the current date': 'The date must be greater than the current date',
}
