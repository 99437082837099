import styled from 'styled-components'
import { Button, Typography as MuiTypography, Badge as MuiBadge, type BadgeProps as MuiBadgeProps } from '@material-ui/core'
import { FontAwesomeIcon as MuiFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { spacing as MuiSpacing } from '@material-ui/system'

const handleIconSize = (className: string | undefined) => {
    const xsmall = className?.split(' ').includes('xsmall')
    const small = className?.split(' ').includes('small')
    const large = className?.split(' ').includes('large')
    const size = xsmall ? '10px' : small ? '32px' : large ? '56px' : '40px'
    return `width: ${size}; height: ${size};`
}

export interface BadgeProps extends Omit<MuiBadgeProps, 'color'> {
    color?: string
}

export const TextButton = styled(Button)<{error?: string}>`
    border: ${(props): string => props.error ? '1px red solid' : ''}
    width: fit-content;
    white-space: nowrap;
    :hover {
        text-decoration: underline;
    }
`

export const QuestionIcon = styled.div`
    margin-left: 6px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.neutral400.main};
`

export const FontAwesomeIcon = styled(styled(MuiFontAwesomeIcon)(MuiSpacing))`
    ${(props):string => handleIconSize(props.className)}
`

export const Typography = styled(MuiTypography)<{error?: string}>`
    color: ${(props): string => props.error ? 'red' : ''};
    padding: ${(props): string => props.theme.spacing(1)}px;
`

export const Badge: React.FC<BadgeProps> = styled(MuiBadge).withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<BadgeProps>`
    width: 100%;
    & .lzr-label {
        padding: ${(props): string => props.theme.spacing(1)}px ${(props): string => props.theme.spacing(2)}px;
        align-items: center;
        transform: none;
        border-radius: 4px;
        font-size:14px;
        height: 24px;
        flex-direction: row;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 6px;
        max-width: fit-content;
        vertical-align: middle;
        margin: -12px -3px;
    }

    & .lzr-label > p {
        margin: auto;
        font-size: 12px;
        line-height: 17.5px;
    }

    & > span {
        color: ${(props): string => props.theme.palette.white.main};
        background-color: ${(props): string | undefined => (props?.color ? props.color : props.theme.palette.black.main)};
        line-height: unset;
    }
`