import React from 'react'
import { LayoutProps } from '../../ui/routes'
import RootLayout from '@/app/ui-new/layouts/RootLayout'
import { WebsiteHeaderDesktop } from '@/app/ui-new/components/Navigation/WebsiteHeader/WebsiteHeaderDesktop'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors'
import RequestDemo from '@/app/ui/pages/components/RequestDemo/RequestDemo'

const WithoutFooterLayout: React.FunctionComponent<Props> = ({ children }) => {

    const user = useSelector(reduxGetUser)

    return RootLayout({
        children: <>
          <WebsiteHeaderDesktop user={user}/>
            {children}
            <RequestDemo/>
        </>,
    })
}

export interface Props extends LayoutProps {}

export default WithoutFooterLayout
