import styled from 'styled-components'
import {
    Box as MuiBox,
} from '@material-ui/core'

export const Wrapper = styled(MuiBox)`
    width: 100%;
    background-color: ${(props): string => props.theme.palette.white.main};
    display: flex;
    justify-content: center;
`
