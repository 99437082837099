/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import React from 'react'

export default {
    Attention: 'Attention',
    'Hide this message':'Hide this message',
    "For the best experience on our website, we recommend using a desktop computer.":`For the best experience on our website, we recommend using a desktop computer. Some
    features and functionalities may be limited on small screen.`,
    Hide:"Hide",
}
