/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import { Strong } from '../../../AppLayout.styled'

export default {
    ['New Version Available']: 'New Version Available',
    ['Reload Lazr at your convenience for the latest version.']: 'Reload Lazr at your convenience for the latest version.',
    ['Reload']: 'Reload',
    Attention: 'Attention',
    message: () => (
        <>
            <Strong>Classic Lazr</Strong> will no longer be supported on Nov 1st. Try the new Lazr today with more features tools!
        </>
    ),
}
