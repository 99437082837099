import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    StyledContainer, StyledDividerTypography, StyledIconButton,
    StyledNumberField, StyledTimeFormatTypography,
    StyledTitleTypography, StyledToggleButtonGroup,
    StyledDialog,
} from '@/app/ui-new/components/TimePicker/TimePicker.styled'
import { Box } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import TextButton from '@/app/ui-new/components/TextButtons/TextButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment/moment'
import { ClockView } from '@material-ui/pickers'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './TimePicker.i18n'

export interface Props {
    initTime?: string
    dialogOpen: boolean
    onDialogClose: () => void
    onTimeChange: (time: string) => void
}

export interface Time {
    hours: string
    minutes: string
    ampm: 'AM' | 'PM'
}

export const INIT_TIME: Time = {
    hours: '12',
    minutes: '00',
    ampm: 'AM',
}

const TimePicker: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useI18n(i18n)
    const [ ampm, setAmpm ] = React.useState<Time['ampm']>(INIT_TIME.ampm)
    const [ hours, setHours ] = React.useState(INIT_TIME.hours)
    const [ minutes, setMinutes ] = React.useState(INIT_TIME.minutes)
    const [ clockViewOpen, setClockViewOpen ] = React.useState(false)
    const [ clockViewType, setClockViewType ] = React.useState<'hours' | 'minutes' | 'seconds'>('hours')
    const [ date, setDate ] = useState(new Date(new Date().setHours(12, 0)))

    const minutesInputRef = useRef<HTMLDivElement>(null)

    const handleHoursChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = '^(0?[1-9]|1[0-2]|0|\s*)$'
        const h = event.target.value
        const intH = parseInt(h, 10)
        if (h.match(regex)) {
            setHours(h)

            if (!isNaN(intH)){
                setDate(new Date(date.setHours(intH)))
            }
        }
    }, [ setHours, setDate ])

    const handleMinutesChange = useCallback ((event: React.ChangeEvent<HTMLInputElement>) => {
        const m = event.target.value
        const intM = parseInt(m, 10)
        if (m === '' || intM < 60) {
            setMinutes(m)
            if (!isNaN(intM)){
                setDate(new Date(date.setMinutes(intM)))
            }
        }
    }, [ setMinutes, setDate ])

    const handleAMPMChange = useCallback ((event: React.MouseEvent<HTMLElement>, newValue: Time['ampm']) => {
        newValue !== null && setAmpm(newValue)
    }, [ setAmpm ])

    const handleDateChange = useCallback ((newDate: Date | null): void => {
        if (newDate) {
            setHours(moment(newDate).format('hh'))
            setMinutes(moment(newDate).format('mm'))
            setDate(newDate)
        } else {
            setDate(new Date(new Date().setHours(12, 0)))
        }

        if (clockViewType === 'hours' && minutesInputRef.current) {
            minutesInputRef.current.focus()
        }
    }, [ setHours, setMinutes, setDate, clockViewType, minutesInputRef ])

    const handleCancelClick = useCallback (() => {
        setClockViewOpen(false)
        props.onDialogClose()
    }, [ props.onDialogClose, setClockViewOpen ])

    const setInitState = useCallback (() => {
        const initHours = props.initTime ? moment(props.initTime, [ 'h:mm A' ]).format('hh') : INIT_TIME.hours
        const initMinutes = props.initTime ? moment(props.initTime, [ 'h:mm A' ]).format('mm') : INIT_TIME.minutes
        const initAmpm = props.initTime ? moment(props.initTime, [ 'h:mm A' ]).format('A') as Time['ampm'] : INIT_TIME.ampm
        const initDate = new Date(date.setHours(+initHours, +initMinutes))
        setHours(initHours)
        setMinutes(initMinutes)
        setAmpm(initAmpm)
        setDate(initDate)
    }, [ props.initTime, setHours, setMinutes, setAmpm, setDate ])

    const handleHoursOnBlur = useCallback(() => {
        let intHours = parseInt(hours, 10)
        if (intHours > 10) {
            return
        }

        if (intHours === 0 || isNaN(intHours)) {
            intHours = date.getHours()
        }
        setHours(intHours < 10 ? `0${intHours}` : `${intHours}`)
    }, [ hours, setHours, date ])

    const handleMinutesOnBlur = useCallback(() => {
        const intMinutes = parseInt(minutes, 10)
        if (minutes === '') {
            setMinutes(INIT_TIME.minutes)
        }
        if (intMinutes < 10 && minutes.slice(0, 1) !== '0') {
            setMinutes(`0${intMinutes}`)
        }
    }, [ minutes, setMinutes ])

    const handleOkClick = () => {
        props.onTimeChange(`${hours}:${minutes} ${ampm}`)
        setClockViewOpen(false)
        props.onDialogClose()
    }

    const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, clockView: 'hours' | 'minutes') => {
        setClockViewType(clockView)
        event.target.select()
    }, [ setClockViewType ])

    useEffect(() => {
        setInitState()
    }, [])

    return <StyledDialog
        open={props.dialogOpen}
        onClose={props.onDialogClose}
    >
        <StyledContainer>
            <StyledTitleTypography variant={'body1'}>{t('Enter time')}</StyledTitleTypography>
            <Box mt={6} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'row'}>
                    <StyledNumberField
                        onChange={handleHoursChange}
                        onBlur={handleHoursOnBlur}
                        value={hours}
                        onFocus={(e) => handleFocus(e, 'hours')}
                        helperText={t('Hours')}
                    />
                    <Box px={2}>
                        <StyledDividerTypography variant={'h2'}>:</StyledDividerTypography>
                    </Box>
                    <StyledNumberField
                        onChange={handleMinutesChange}
                        onBlur={handleMinutesOnBlur}
                        value={minutes}
                        onFocus={(e) => handleFocus(e, 'minutes')}
                        helperText={t('Minutes')}
                        ref={minutesInputRef}
                    />
                </Box>
                <StyledToggleButtonGroup orientation="vertical" value={ampm} exclusive onChange={handleAMPMChange}>
                    <ToggleButton value="AM" aria-label="am">
                        <StyledTimeFormatTypography variant={'subtitle1'}>AM</StyledTimeFormatTypography>
                    </ToggleButton>
                    <ToggleButton value="PM" aria-label="pm">
                        <StyledTimeFormatTypography variant={'subtitle1'}>PM</StyledTimeFormatTypography>
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Box>
            <Box mt={6} display={clockViewOpen ? 'block' : 'none'}>
                <ClockView
                    type={clockViewType}
                    date={date}
                    minutesStep={1}
                    onMinutesChange={(newDate) => {
                        handleDateChange(newDate)
                        if (newDate) {
                            setDate(newDate)
                        }
                    }}
                    onHourChange={(newDate) => {
                        handleDateChange(newDate)
                        if (newDate) {
                            setDate(newDate)
                        }
                    }}
                    onSecondsChange={() => {}}
                />
            </Box>
            <Box mt={6} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <div>
                    <StyledIconButton size="small" onClick={() => { setClockViewOpen((prevState) => !prevState)}}>
                        <FontAwesomeIcon icon={[ 'far', 'clock' ]}/>
                    </StyledIconButton>
                </div>
                <Box display={'flex'} flexDirection={'row'}>
                    <Box mr={4}>
                        <TextButton onClick={handleCancelClick}>{t('Cancel')}</TextButton>
                    </Box>
                    <PrimaryButton onClick={handleOkClick}>{t('Ok')}</PrimaryButton>
                </Box>
            </Box>
        </StyledContainer>
    </StyledDialog>
}

export default TimePicker
