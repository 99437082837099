import {
    AccessorialLocationType,
    AccessorialType,
    type AddressType,
    type CollectAccountType,
    type Country as CountryCode,
    type Currency,
    type TransportType,
    type UuidV4,
} from '@lazr/openapi-client'
import type { Country } from './Country'

export type SaveableAddress = Omit<Address, 'originAccessorials' | 'destinationAccessorials' | 'anyAccessorials'>

export interface SaveableAddressNewUi{
    name?: string
    organizationId?: UuidV4
    addressId?: string
    userSpecifiedId?: string
    description?: string
    streetAddressLine1?: string
    streetAddressLine2?: string | null
    streetAddressLine3?: string | null
    city?: string
    state?: string
    country?: Country | null
    postalCode?: string
    addressType?: AddressType
    detectedAddressType?: AddressType | null
    companyName?: string
    shippingContactName?: string
    shippingContactEmails?: string[] | null
    shippingContactPhone?: string
    shippingContactPhoneExtension?: string
    billingContactName?: string | null
    billingContactEmails?: string[] | null
    billingContactPhone?: string  | null
    billingContactPhoneExtension?: string | null
    notify?: boolean
    instructions?: string
    openTime?: string | null
    closeTime?: string | null
    accessorials?: AddressAccessorial[]
    isShipping?: boolean
    isBilling?: boolean
    isBillingDefault?: boolean
    isShippingDefault?: boolean
    currency?: Currency
    collectAccounts?: CollectAccount[] | null
}

export interface AddressAttributes {
    name?: string
    addressId?: string
    userSpecifiedId: string
    description: string
    streetAddress: string
    streetAddress2: string | null
    streetAddress3: string | null
    city: string
    state: string
    country: CountryCode | null
    postalCode: string
    addressType: AddressType
    detectedAddressType?: AddressType | null
    companyName: string
    shippingContactName: string
    shippingContactEmails: string[] | null
    shippingContactPhone?: string
    shippingContactPhoneExtension: string
    billingContactName: string | null
    billingContactEmails: string[] | null
    billingContactPhone: string  | null
    billingContactPhoneExtension: string | null
    notify: boolean
    instructions: string
    openTime: string | null
    closeTime: string | null
    accessorials: AddressAccessorial[]
    isShipping: boolean
    isBilling: boolean
    isBillingDefault: boolean
    isShippingDefault: boolean
    currency?: Currency
    collectAccounts: CollectAccount[] | null
}

export interface CollectAccount {
    id?: UuidV4
    carrier: {
        id: UuidV4
        name: string
    }
    accountNumber: string
    accountType: CollectAccountType
}

export interface FilteredAddresses {
    addresses: Address[]
    total: number
}

export interface AddressAccessorialInfo {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
}

export interface AddressAccessorial {
    id: UuidV4
    isRemovable: boolean
    selected?: boolean
    accessorial: AddressAccessorialInfo
}

export interface AddressProps {
    id: string
    name: string
    description: string
    organizationId: UuidV4
    streetAddressLine1: string
    streetAddressLine2: string | null
    streetAddressLine3: string | null
    city: string
    state: string
    postalCode: string
    addressType: AddressType
    detectedAddressType?: AddressType | null
    companyName: string
    companyLegalName: string
    shippingContactName: string
    shippingContactEmails?: string[]
    shippingContactPhone?: string
    shippingContactPhoneExtension: string
    billingContactName?: string
    billingContactEmails?: string[]
    billingContactPhone?: string
    billingContactPhoneExtension?: string
    notify: boolean
    instructions: string
    openTime?: string
    closeTime?: string
    accessorials: AddressAccessorial[]
    country: Country
    isShipping: boolean
    isBilling: boolean
    isBillingDefault: boolean
    isShippingDefault: boolean
    collectAccounts: CollectAccount[] | null
}

export type WhichAddress = 'originAddress' | 'destinationAddress' | 'thirdPartyAddress' | 'customsBrokerAddress'

export class Address {
    public id: string
    public name: string
    public description: string
    public organizationId: UuidV4
    public streetAddressLine1: string
    public streetAddressLine2: string | null
    public streetAddressLine3: string | null
    public city: string
    public state: string
    public postalCode: string
    public addressType: AddressType
    public detectedAddressType?: AddressType | null
    public companyName: string
    public companyLegalName: string
    public shippingContactPhone?: string
    public shippingContactPhoneExtension: string
    public shippingContactName: string
    public shippingContactEmails?: string[]
    public billingContactPhone?: string
    public billingContactPhoneExtension?: string
    public billingContactName?: string
    public billingContactEmails?: string[]
    public notify: boolean
    public instructions: string
    public openTime?: string
    public closeTime?: string
    public accessorials: AddressAccessorial[]
    public country: Country
    public isShipping: boolean
    public isBilling: boolean
    public isBillingDefault: boolean
    public isShippingDefault: boolean
    public collectAccounts: CollectAccount[] | null

    constructor (props: AddressProps) {
        this.id = props.id
        this.name = props.name
        this.description = props.description
        this.organizationId = props.organizationId
        this.streetAddressLine1 = props.streetAddressLine1
        this.streetAddressLine2 = props.streetAddressLine2
        this.streetAddressLine3 = props.streetAddressLine3
        this.city = props.city
        this.state = props.state
        this.postalCode = props.postalCode
        this.addressType = props.addressType
        this.detectedAddressType = props.detectedAddressType
        this.companyName = props.companyName
        this.companyLegalName = props.companyLegalName
        this.shippingContactPhone = props.shippingContactPhone
        this.shippingContactPhoneExtension = props.shippingContactPhoneExtension
        this.shippingContactName = props.shippingContactName
        this.shippingContactEmails = props.shippingContactEmails
        this.billingContactPhone = props.billingContactPhone
        this.billingContactPhoneExtension = props.billingContactPhoneExtension
        this.billingContactName = props.billingContactName
        this.billingContactEmails = props.billingContactEmails
        this.notify = props.notify
        this.instructions = props.instructions
        this.openTime = props.openTime
        this.closeTime = props.closeTime
        this.accessorials = props.accessorials
        this.country = props.country
        this.isShipping = props.isShipping
        this.isBilling = props.isBilling
        this.isBillingDefault = props.isBillingDefault
        this.isShippingDefault = props.isShippingDefault
        this.collectAccounts = props.collectAccounts
    }

    get originAccessorials (): AddressAccessorial[] {
        return this.accessorials.filter((addressAccessorial) =>
            addressAccessorial.accessorial.locationType === AccessorialLocationType.ORIGIN)
    }
    get destinationAccessorials (): AddressAccessorial[] {
        return this.accessorials.filter((addressAccessorial) =>
            addressAccessorial.accessorial.locationType === AccessorialLocationType.DESTINATION)
    }
    get anyAccessorials (): AddressAccessorial[] {
        return this.accessorials.filter((addressAccessorial) =>
            addressAccessorial.accessorial.type === AccessorialType.SERVICE)
    }
}
