/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react'
import styled from 'styled-components'
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, Grid as MuiGrid } from '@material-ui/core'
import { transparentize } from 'polished'

export interface TooltipProps extends MuiTooltipProps {
    $bgColor?: string
    $width?: string
    $position?: string
}

export const Tooltip: React.FC<TooltipProps> = styled(MuiTooltip)<TooltipProps>`
    + .lzr-popper {
        position: ${(props): string | undefined => props.$position || 'absolute'};
    }
    + .lzr-popper > .lzr-tooltip {
        padding: 16px;
        color: ${(props): string | undefined => props.color || '#ffffff'};
        background-color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
        width: ${(props): string | undefined => props.$width};
        border-radius: 4px;
        font-size: ${(props): string => props.theme.typography.body2.fontSize};
        font-weight: ${(props): string => props.theme.typography.body2.fontWeight};
    }
    + .lzr-popper > div > span[class*='MuiTooltip-arrow-'] {
        color: ${(props): string | undefined => props.$bgColor || transparentize(0.05, props.theme.palette.neutral800.main)};
    }
    + .lzr-popper > .lzr-tooltip a {
        color: ${(props): string | undefined => props.color || '#ffffff'};
    }
`
export const QuestionIcon = styled.div`
    margin-left: 6px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.neutral400.main};
`

export const GridTooltipFlex = styled(MuiGrid)`
    display: flex;
    align-items: center;
`
export const GridTooltipIcon = styled(MuiGrid)`
    width: 15px;
`
