import React from 'react'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import { Snackbar as SnackbarStyled } from './Snackbar.styled'

const SnackbarDefaults: SnackbarProps = {
    autoHideDuration: 6000,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    ClickAwayListenerProps: {
        onClickAway: () => {},
    },
}

export const Snackbar: React.FunctionComponent<SnackbarProps> = (props) =>
    <SnackbarStyled {...SnackbarDefaults} {...props}>
        {props.children}
    </SnackbarStyled>
