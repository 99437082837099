import React, { useEffect, useState } from 'react'
import { StyledIcon } from './DefaultShippingAddressPicker.styled'
import Tooltip from '@/app/ui-new/components/Tooltip/Tooltip'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './DefaultShippingAddressPicker.i18n'
import { IconButton } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar.styled'

const DefaultShippingAddressPicker: React.FunctionComponent<Props> = ({
    onClick,
    disabled
}) => {
    const { t } = useI18n(i18n)

    return <>
        <Tooltip title={t('Default shipping address')} arrow placement='top'>
            <IconButton
                onClick={onClick}
                aria-label='default address'
            >
                <StyledIcon
                    $disabled={disabled}
                    icon={[ 'fas', 'home' ]}
                />
            </IconButton>
        </Tooltip>
    </>
}
export interface Props {
    onClick?: () => void
    disabled?: boolean
}

export default DefaultShippingAddressPicker
