import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const WardLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={'/images/logos/carrier/ward-logo.webp'}
        name={'WARD Trucking'}
    />

export default WardLogo
