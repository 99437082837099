/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['Quote faster.']: `Soumission rapide.`,
    ['Ship smarter.']: `Expédition intelligente.`,
    ['Company']: `Compagnie`,
    ['Quick links']: `Liens rapides`,
    ['Home']: `Accueil`,
    ['Careers']: `Carrières`,
    ['https://resources.lazr.io/career']: `https://resources.lazr.io/carriere`,
    ['Blog']: `Blogue`,
    ['https://resources.lazr.io/blog']: `https://resources.lazr.io/blogue`,
    ['Lazr Academy']: `Académie Lazr`,
    ['Help center']: `Centre d'aide`,
    ['FAQ']: `Questions fréquentes`,
    ['Legal']: `Légal`,
    ['Terms and conditions']: `Termes et conditions`,
    ['Privacy policy']: `Politique de confidentialité`,
    ['Follow us']: `Suivez-nous`,
    ['Facebook']: `Facebook`,
    ['LinkedIn']: `LinkedIn`,
    ['Instagram']: `Instagram`,
    ['Join our newsletter']: `Joignez notre infolettre`,
    ['Email']: `Courriel`,
    ['Subscribe']: `Abonnez-vous`,
}
