import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Typography as MuiTypography, Badge as MuiBadge, type BadgeProps as MuiBadgeProps } from '@material-ui/core'

export interface BadgeProps extends Omit<MuiBadgeProps, 'color'> {
    color?: string
}

export interface BadgeTextProps extends BadgeProps {
    'move-top'?: number
    'move-right'?: number
}

export const Typography = styled(MuiTypography)(MuiSpacing)

export const Badge: React.FC<BadgeProps> = styled(MuiBadge).withConfig({
    shouldForwardProp: (prop) => !['color'].includes(prop),
})<BadgeProps>`
    & .lzr-label {
        padding: ${(props): string => props.theme.spacing(1)}px ${(props): string => props.theme.spacing(2)}px;
        align-items: center;
        transform: none;
        border-radius: 4px;
        font-size:14px;
        height: 24px;
        flex-direction: row;
        flex-wrap: nowrap;
        white-space: nowrap;
        gap: 6px;
        max-width: fit-content;
        vertical-align: middle;
        margin: -12px -3px;
    }

    & .lzr-label > p {
        margin: auto;
        font-size: 12px;
        line-height: 17.5px;
    }

    & > span {
        color: #ffffff;
        background-color: ${(props): string | undefined => (props?.color ? props.color : '#000')};
        line-height: unset;
    }

   
`


export const BadgeText: React.FC<BadgeTextProps> = styled(MuiBadge)<BadgeTextProps>`
& [class^='MuiBadge-badge'] {
    right: ${(props) => (props?.['move-right'] ? `${props?.['move-right']}px` : '-16px')};
   ${(props) => (props?.['move-top'] ? ` top: ${props?.['move-top']}px` : '')};
   ${(props): string | undefined => (props?.color==='error' ? 'background-color: #F44336; color:white' : '')};
}
`

