import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const RemcoLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/remco-logo.svg?v=${window.lazrVersion}`}
        name={'Remco'}
    />

export default RemcoLogo
