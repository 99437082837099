import styled from 'styled-components'
import {

    Grid as MuiGrid,
    Typography as MuiTypography,

} from '@material-ui/core'
import {  spacing as MuiSpacing } from '@material-ui/system'


export const Grid = styled(styled(MuiGrid)(MuiSpacing))``


export const Typography = styled(styled(MuiTypography)(MuiSpacing))`

&[class*="MuiTypography-colorError"] {
  color: ${(props): number => props.theme.palette.error900.main};
}


`
