import { logger } from '@/app/logger'
import { UuidV4 } from '@lazr/types'
import { ApiError, BillingService, ErrorResponse } from '@lazr/openapi-client'
import { TrackingUpdateCsvBatch, TrackingUpdateCsvBatchProps } from '@/app/model'
import { handleUnauthorizedException } from '@/app/service/ApiService/index'
import { CarrierTrackingUpdateResponse } from '@lazr/openapi-client'
import { CarrierTrackingUpdate } from '@/app/model/TrackingUpdateCsvBatch'
export type TrackingUpdateCsvBatchOrderByField = 'number' | 'file_name' | 'carrier_name' |
'created_at' | 'created_by_user_name'
export type TrackingUpdateCsvBatchOrder = 'asc' | 'desc'

export interface TrackingUpdateCsvBatchPaging {
    page?: number
    resultPerPage?: number
}

export interface TrackingUpdateCsvBatchFilter {
    order?: TrackingUpdateCsvBatchOrder
    orderBy?: TrackingUpdateCsvBatchOrderByField
}

export const TrackingUpdateCsvBatchApiService = Object.freeze({
    list: async (paging: TrackingUpdateCsvBatchPaging, filters?: TrackingUpdateCsvBatchFilter) => {
        let getOrderTrackingUpdateCsvBatchesResponse
        try {
            getOrderTrackingUpdateCsvBatchesResponse = await BillingService.getOrderTrackingUpdateCsvBatches(paging.page,
                paging.resultPerPage,
                filters?.order,
                filters?.orderBy)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve tracking update csv batch list')
            }
            throw new Error('Unable to retrieve tracking update csv batch list')
        }

        if (!getOrderTrackingUpdateCsvBatchesResponse.data) {
            logger.debug('list', JSON.stringify(getOrderTrackingUpdateCsvBatchesResponse, null, 4))
            throw new Error('Unable to retrieve tracking update csv batch list')
        }

        return {
            orderTrackingUpdateCsvBatches:
                getOrderTrackingUpdateCsvBatchesResponse.data.orderTrackingUpdateCsvBatches
                    .map((trackingUpdateCsvBatch: TrackingUpdateCsvBatchProps) => new TrackingUpdateCsvBatch(trackingUpdateCsvBatch)),

            total: getOrderTrackingUpdateCsvBatchesResponse.data.paging.items,
        }
    },
    getById: async (trackingUpdateCsvBatchId: UuidV4): Promise<TrackingUpdateCsvBatch | null> => {
        let getTrackingUpdateCsvBatchByIdResponse
        try {
            getTrackingUpdateCsvBatchByIdResponse = await BillingService.getOrderTrackingUpdateCsvBatchById(trackingUpdateCsvBatchId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get tracking update csv batch by id')
            }
            throw new Error('Unable to get tracking update csv batch by id')
        }

        if (!getTrackingUpdateCsvBatchByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getTrackingUpdateCsvBatchByIdResponse, null, 4))
            throw new Error('Unable to get tracking update csv batch by id')
        }

        return new TrackingUpdateCsvBatch(getTrackingUpdateCsvBatchByIdResponse.data.orderTrackingUpdateCsvBatch)
    },
    processCarrierTrackingUpdate: async (
        carrierTrackingUpdate: CarrierTrackingUpdate,
    ): Promise<CarrierTrackingUpdateResponse | string> => {
        const genericError = 'An unexpected error occurred while processing the file'

        try {
            return await BillingService.processTrackingUpdate(carrierTrackingUpdate)
        } catch (error: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                if (errorResponse.error?.message) {
                    return errorResponse.error.message
                } else {
                    return genericError
                }
            }
        }

        return genericError
    },
})
