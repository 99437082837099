import { createGlobalStyle } from 'styled-components'
// @ts-ignore
import Termina100WoffTwo from '../../fonts/termina-100.woff2'
// @ts-ignore
import Termina200WoffTwo from '../../fonts/termina-200.woff2'
// @ts-ignore
import Termina300WoffTwo from '../../fonts/termina-300.woff2'
// @ts-ignore
import Termina400WoffTwo from '../../fonts/termina-400.woff2'
// @ts-ignore
import Termina500WoffTwo from '../../fonts/termina-500.woff2'
// @ts-ignore
import Termina600WoffTwo from '../../fonts/termina-600.woff2'
// @ts-ignore
import Termina700WoffTwo from '../../fonts/termina-700.woff2'
// @ts-ignore
import Termina800WoffTwo from '../../fonts/termina-800.woff2'
// @ts-ignore
import Termina900WoffTwo from '../../fonts/termina-900.woff2'
// @ts-ignore
import Graphik100WoffTwo from '../../fonts/graphik-100.woff2'
// @ts-ignore
import Graphik200WoffTwo from '../../fonts/graphik-200.woff2'
// @ts-ignore
import Graphik300WoffTwo from '../../fonts/graphik-300.woff2'
// @ts-ignore
import Graphik400WoffTwo from '../../fonts/graphik-400.woff2'
// @ts-ignore
import Graphik500WoffTwo from '../../fonts/graphik-500.woff2'
// @ts-ignore
import Graphik600WoffTwo from '../../fonts/graphik-600.woff2'
// @ts-ignore
import Graphik700WoffTwo from '../../fonts/graphik-700.woff2'
// @ts-ignore
import Graphik900WoffTwo from '../../fonts/graphik-900.woff2'
// @ts-ignore
import Roboto100WoffTwo from '../../fonts/roboto-100.woff2'
// @ts-ignore
import Roboto300WoffTwo from '../../fonts/roboto-300.woff2'
// @ts-ignore
import Roboto400WoffTwo from '../../fonts/roboto-400.woff2'
// @ts-ignore
import Roboto500WoffTwo from '../../fonts/roboto-500.woff2'
// @ts-ignore
import Roboto700WoffTwo from '../../fonts/roboto-700.woff2'
import { AppTheme } from '../../definitions/NewDesignTheme'
import { Theme } from '@material-ui/core'

export const GlobalStyle = createGlobalStyle`
 /* Termina */
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 100;
    font-stretch: normal;
    src: url('${Termina100WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 200;
    font-stretch: normal;
    src: url('${Termina200WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    src: url('${Termina300WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    src: url('${Termina400WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    src: url('${Termina500WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    src: url('${Termina600WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    src: url('${Termina700WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 800;
    font-stretch: normal;
    src: url('${Termina800WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Termina';
    font-display: auto;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
    src: url('${Termina900WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

  /* Graphik */
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 100;
    font-stretch: normal;
    src: url('${Graphik100WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 200;
    font-stretch: normal;
    src: url('${Graphik200WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    src: url('${Graphik300WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    src: url('${Graphik400WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    src: url('${Graphik500WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
    src: url('${Graphik600WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    src: url('${Graphik700WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 800;
    font-stretch: normal;
    src: url('${Graphik900WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Graphik';
    font-display: auto;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
    src: url('${Graphik900WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  @font-face {
    font-family: 'Roboto';
    font-display: auto;
    font-style: normal;
    font-weight: 100;
    font-stretch: normal;
    src: url('${Roboto100WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
   @font-face {
    font-family: 'Roboto';
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
    src: url('${Roboto300WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
   @font-face {
    font-family: 'Roboto';
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    src: url('${Roboto400WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
   @font-face {
    font-family: 'Roboto';
    font-display: auto;
    font-style: normal;
    font-weight: 500;
    font-stretch: normal;
    src: url('${Roboto500WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
    @font-face {
    font-family: 'Roboto';
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    src: url('${Roboto700WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

  html,
  body,
  #root {
    font-size: 14px;
    height: 100%;
  }

  body {
    background: ${(props: { theme: AppTheme & Theme }): string => props?.theme?.palette?.background?.default};
  }
`
