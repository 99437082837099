/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Request a Demo`]: `Request a Demo`,
    [`First Name`]: `First Name`,
    [`Last Name`]: `Last Name`,
    [`Email`]: `Email`,
    [`Phone`]: `Phone`,
    [`Company Name`]: `Company Name`,
    [`Preferred Language`]: `Preferred Language`,
    [`Industry`]: `Industry`,
    [`None`]: `None`,
    [`Agriculture, forestry, fishing and hunting`]: `Agriculture, forestry, fishing and hunting`,
    [`Mining, quarrying, and oil and gas extraction`]: `Mining, quarrying, and oil and gas extraction`,
    [`Utilities`]: `Utilities`,
    [`Construction`]: `Construction`,
    [`Manufacturing`]: `Manufacturing`,
    [`Wholesale trade`]: `Wholesale trade`,
    [`Retail trade`]: `Retail trade`,
    [`Transportation and warehousing`]: `Transportation and warehousing`,
    [`Information and cultural industries`]: `Information and cultural industries`,
    [`Real estate and rental and leasing`]: `Real estate and rental and leasing`,
    [`Professional, scientific and technical services`]: `Professional, scientific and technical services`,
    [`Management of companies and enterprises`]: `Management of companies and enterprises`,
    [`Waste management and remediation services`]: `Waste management and remediation services`,
    [`Health care and social assistance`]: `Health care and social assistance`,
    [`Arts, entertainment and recreation`]: `Arts, entertainment and recreation`,
    [`Accommodation and food services`]: `Accommodation and food services`,
    [`Other services`]: `Other services`,
    [`Public administration`]: `Public administration`,
    [`Volume of Shipments per Week`]: `Volume of Shipments per Week`,
    [`less 10`]: `less 10`,
    [`10-50`]: `10-50`,
    [`50-100`]: `50-100`,
    [`100+`]: `100+`,
    [`Transportation services used`]: `Transportation services used`,
    [`LTL (Local)`]: `LTL (Local)`,
    [`LTL (National)`]: `LTL (National)`,
    [`LTL (International)`]: `LTL (International)`,
    [`FTL`]: `FTL`,
    [`Pak`]: `Pak`,
    [`Parcel`]: `Parcel`,
    [`Custom Quotes`]: `Custom Quotes`,
    [`Other`]: `Other`,
    [`I accept to receive email communications from LAZR.`]: `I accept to receive email communications from LAZR`,
    [`This field is required`]: `This field is required`,
    [`Email is not a valid format`]: `Email is not a valid format`,
    [`Missing required fields`]: `Missing required fields`,
    [`Error`]: `Error`,
    [`Something went wrong. Please try again later.`]: `Something went wrong. Please try again later.`,
    [`Success`]: `Success`,
    [`We will get in touch with you shortly.`]: `We will get in touch with you shortly.`,
}
