import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
    BackgroundGradientLayer0 as BackgroundGradientLayer0Styled,
    BackgroundGradientLayer1 as BackgroundGradientLayer1Styled,
    BackgroundGradientLayer2 as BackgroundGradientLayer2Styled,
    BackgroundGradientLayer3 as BackgroundGradientLayer3Styled,
    BackgroundGradientLayer4 as BackgroundGradientLayer4Styled,
    FlipCardsTitle as FlipCardsTitleStyled,
    CardsContainer as CardsContainerStyled,
    Grid as GridStyled,
    CardOuter,
    CardInner,
    Side,
    GradientBorder,
    ImageContainer,
    Typography1,
    Typography2,
    Typography3,
    Typography4,
    KeyPointsContainer,
    IconContainer,
    KeyPointItem,
} from './CardDesktop.styled'
import { BoxProps } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'

export const BackgroundGradientLayer0 = BackgroundGradientLayer0Styled
export const BackgroundGradientLayer1 = BackgroundGradientLayer1Styled
export const BackgroundGradientLayer2 = BackgroundGradientLayer2Styled
export const BackgroundGradientLayer3 = BackgroundGradientLayer3Styled
export const BackgroundGradientLayer4 = BackgroundGradientLayer4Styled

export const FlipCardsTitle = FlipCardsTitleStyled
export const CardsContainer = CardsContainerStyled
export const Grid = GridStyled

export const CardDesktop: React.FunctionComponent<CardProps> = (props) => {
    const [ hoverEnabled, setHoverEnabled ] = useState(false)
    const [ cardRotated, setCardRotated ] = useState(false)
    const cardRef: { current: HTMLDivElement | undefined } = useRef()
    const language = useSelector(reduxGetLanguage)

    const handleClick = useCallback(() => {
        setCardRotated((previousState) => !previousState)
    }, [ setCardRotated ])

    useEffect(() => {
        if (!hoverEnabled && cardRef.current) {
            cardRef.current.addEventListener('click', handleClick)

            return () => {
                if (cardRef.current) {
                    cardRef.current.removeEventListener('click', handleClick)
                }
            }
        }

        return
    }, [ hoverEnabled, cardRef.current, handleClick ])

    if (!!props.enableHover !== hoverEnabled) {
        setHoverEnabled(!!props.enableHover)
    }

    return <CardOuter
        className={[ (props.enableHover ? 'enable-hover' : ''), (cardRotated ? 'rotated' : '') ].join(' ')}
        ref={cardRef}
    >
        <CardInner language={language}>
            <Side>
                <Typography1 variant='h5'>
                    {props.front.iAm}
                </Typography1>

                <GradientBorder>
                    <ImageContainer>
                        {props.front.image}
                    </ImageContainer>
                </GradientBorder>

                <Typography2 variant='h1'>
                    {props.front.position}
                </Typography2>
            </Side>

            <Side className='back'>
                <Typography3 variant='h5' mb={6}>
                    {props.back.title}
                </Typography3>

                <KeyPointsContainer>
                    {props.back.keypoints.map((keypoint, index) => <KeyPointItem mb={5} key={`keypoint_${index}`}>
                        <IconContainer>
                            {keypoint.icon}
                        </IconContainer>
                        <Typography4 variant='body1'>
                            {keypoint.text}
                        </Typography4>
                    </KeyPointItem>)}
                </KeyPointsContainer>

                {props.back.callToActionButton}
            </Side>
        </CardInner>
    </CardOuter>
}

interface KeyPoint {
    icon: JSX.Element
    text: string
}

export interface CardProps {
    front: {
        image: JSX.Element
        iAm: string
        position: string
    }
    back: {
        title: string
        keypoints: KeyPoint[]
        callToActionButton: JSX.Element
    }
    enableHover?: boolean
}

export interface StyledBoxProps extends BoxProps {
    language: string
}
