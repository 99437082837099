import { InputProps, TextField } from '@material-ui/core'
import { CountryCode as PhoneCountryCode, formatIncompletePhoneNumber, parseDigits } from 'libphonenumber-js'
import React from 'react'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '../redux/selectors/UserSelectors'
import { Country } from '@lazr/openapi-client'

const PhoneNumberField: React.FunctionComponent<Props> = ({ value, onChange, ...otherProps }) => {
    const user = useSelector(reduxGetUser)
    const userCountry = (user?.locale.country ?? Country.CA) as PhoneCountryCode

    return <TextField
        {...otherProps}
        value={formatIncompletePhoneNumber(value, userCountry)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            let newValue = parseDigits(event.target.value)
            if (newValue === value && formatIncompletePhoneNumber(newValue, userCountry).startsWith(event.target.value)) {
                newValue = newValue.slice(0, -1)
            }
            onChange(newValue)
        }}
    />
}
export interface Props {
    id?: string
    disabled?: boolean
    className?: string
    value: string
    label?: string
    variant?: 'filled' | 'outlined' | 'standard'
    fullWidth?: boolean
    required?: boolean
    error?: boolean
    helperText?: React.ReactNode
    autoComplete?: string
    placeholder?: string
    size?: 'small' | 'medium'
    inputProps?: InputProps['inputProps']
    onBlur?: InputProps['onBlur']
    onChange: (newValue: string) => void
}

export default PhoneNumberField
