/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessorialLocationType } from '../models/AccessorialLocationType';
import type { AccessorialType } from '../models/AccessorialType';
import type { SuccessResponse_GetAccessorialsResponse_Array_ } from '../models/SuccessResponse_GetAccessorialsResponse_Array_';
import type { TransportType } from '../models/TransportType';
import { request as __request } from '../core/request';

export class AccessorialsService {

    /**
     * Get a list of accessorials
     * @param id The id filter
     * @param locationType The locationType filter
     * @param type The type filter
     * @param code The code filter
     * @param transportTypes The transportTypes filter
     * @param ids The ids filter
     * @returns SuccessResponse_GetAccessorialsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getAccessorials(
        id?: string,
        locationType?: AccessorialLocationType,
        type?: AccessorialType,
        code?: string,
        transportTypes?: Array<TransportType>,
        ids?: Array<string>,
    ): Promise<SuccessResponse_GetAccessorialsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/accessorials`,
            query: {
                'id': id,
                'locationType': locationType,
                'type': type,
                'code': code,
                'transportTypes': transportTypes,
                'ids': ids,
            },
            errors: {
                400: `Bad request`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}