import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import styled from 'styled-components'

export const AppWrapper = styled.div`
    height: 100vh;
`

export const AppBody = styled.div`
    height: calc(100vh - 72px);
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

export const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 34px;
`

export const Strong = styled.strong`
    text-decoration: underline;
`

export const AlertButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;

    & a,
    & button {
        transform: translateX(70px);
    }
`

export const AlertButton = styled(PrimaryButton)`
    margin-top: ${(props): number => props.theme.spacing(2.5)}px
`
