import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { StyledDot } from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Stepper.styled'

const Stepper: React.FunctionComponent<Props> = (props: Props) => (
    <Box display='flex' flexDirection='row' alignItems='center'>
        {
            [ ...Array(props.steps) ].map((item, index): ReactNode => {
                let className = ''
                if (props.activeStep === index) {
                    className += 'lzr-active-step'
                }

                return <StyledDot
                    key={index}
                    className={className}
                    onClick={() => props.onClick(index)}
                />
            })
        }
    </Box>
)

export interface Props {
    steps: number
    activeStep:number
    onClick: (step: number) => void
}
export default Stepper
