import React from 'react'
import { PlayButtonText as ButtonTextStyled, IconButton } from './YoutubePlayButton.styled'

export const YoutubePlayButton: React.FunctionComponent = () => <IconButton preserveIconColors>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21.9685" cy="21.9685" r="21.9685" fill="#262626" />
        <path opacity="0.92" d="M30.7875 21.9684L17.6064 14.8286V29.1081L30.7875 21.9684Z" fill="white" />
    </svg>
</IconButton>

export const PlayButtonText = ButtonTextStyled
