import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { SnackbarProvider as MuiSnackbarProvider } from 'notistack'

export const SnackbarProviderStyled = styled(styled(MuiSnackbarProvider)(MuiSpacing))`
    padding: 0;
    margin: 0 0 ${(props): string => props.theme.spacing(2.5)}px ${(props): string => props.theme.spacing(1)}px;
    width: fit-content;

    & > div{
        padding: 0;
    }
`

export const NotistackRoot = styled.div`
    width: 100%;
    & > div:last-child{
        pointer-events: unset;
    }
`
