/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TransportType {
    LTL = 'LTL',
    FTL = 'FTL',
    PARCEL = 'PARCEL',
    ENVELOPE = 'ENVELOPE',
    PAK = 'PAK',
    OTHER = 'OTHER',
}