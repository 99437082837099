/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['Support']: `Support`,
    ['Should you require assistance, our user support team is at your service.']: `Should you require assistance, our user support team is at your service.`,
    ['Don\'t hesitate to contact us for prompt and professional assistance with any inquiries or concerns you may have.']: `Don't hesitate to contact us for prompt and professional assistance with any inquiries or concerns you may have.`,
    ['mailto:support@lazr.io?subject=Support Request']: `mailto:support@lazr.io?subject=Support Request`,
    ['support@lazr.io']: `support@lazr.io`,
    ['Help Center']: `Help Center`,
    ['Privacy']: `Privacy`,
    ['Terms of Service']: `Terms of Service`,
}
