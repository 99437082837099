import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const FrontlineLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/frontline-logo.jpeg?v=${window.lazrVersion}`}
        name={'Frontline Freight'}
    />

export default FrontlineLogo
