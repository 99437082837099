import styled from 'styled-components'

export const StyledContainer = styled.div`
    box-sizing: border-box;

    background: ${(props): number => props.theme.palette.white.main};
    border: 1px solid rgba(0, 0, 0, 0.12);

    display: flex;
    justify-content: center;
    align-items: center;

    div {
        display: flex;
    }
    img {
        width: 100%;
    }

    &.small {
        width: 32px;
        height: 32px;
        border-radius: 2px;
    }

    &.medium {
        width: 52px;
        height: 52px;
        border-radius: 3px;
    }

    &.large {
        width: 72px;
        height: 72px;
        border-radius: 4px;
    }

    &.wide-large {
        width: 115px;
        height: 80px;
        border-radius: 8px;
    }
`
