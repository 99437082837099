import styled from 'styled-components'
import { Button as MuiButton, ButtonProps } from '@material-ui/core'
import React from 'react'

const getButtonHeight = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 56
        case 'small':
            return 28
        case 'medium':
        default:
            return 40
    }
}

const getFontSize = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 1.125
        case 'small':
            return 0.875
        case 'medium':
        default:
            return 1.1
    }
}

const getLetterSpacing = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 0.08
        case 'small':
            return 0.04
        case 'medium':
        default:
            return 0.06
    }
}

const getPadding = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return '20px 32px'
        case 'small':
            return '6px 18px'
        case 'medium':
        default:
            return '12px 24px'
    }
}

export const StyledMuiButton: React.FC<ButtonProps> = styled(MuiButton)<ButtonProps>`
    height: ${(props): number => getButtonHeight(props.size)}px;
    color: ${(props): number => props.color === 'secondary' ? props.theme.palette.white.main :
        props.theme.palette.primary.main};
    border: 1px solid transparent;
    border-radius: 4px;
    text-transform: none;
    font-size: ${(props): number => getFontSize(props.size)}rem;
    letter-spacing: ${(props): number => getLetterSpacing(props.size)}rem;
    padding: ${(props): string => getPadding(props.size)};

    & [class^="MuiButton-startIcon"] {
        margin-right: 14px;
    }

    & [class^="MuiButton-endIcon"] {
        margin-left: 14px;
    }

    & [class^="MuiButton-startIcon"] svg,
    & [class^="MuiButton-endIcon"] svg
    {
        font-size: ${(props): number => getFontSize(props.size)}rem;
    }

    &:hover {
        background: ${(props): number => props.color === 'secondary' ? props.theme.palette.accentPrimary700.main :
        props.theme.palette.accentPrimary50.main};
    }

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentPrimary400.main};
    }
`
