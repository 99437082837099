/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['System Dashboard']: `System Dashboard`,
    ['Orders']: `Orders`,
    ['Marketplace']: `Marketplace`,
    ['Invoices']: `Invoices`,
    ['Invoice CSV Batches']: `Invoice CSV Batches`,
    ['Tracking Updates CSV Batches']: `Tracking Updates CSV Batches`,
    ['Address Book']: `Address Book`,
    ['Organizations']: `Organizations`,
    ['Users']: `Users`,
    ['EDI']: `EDI`,
    ['Coming soon!']: `Coming soon!`,
    'Schedule Pickup':'Schedule Pickup'
}
