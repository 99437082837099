import React, { useRef, useState, useCallback } from 'react'
import { IconButton, Menu, MenuBackgroundOverlay } from './LanguageMenu.styled'
import { Language as LanguageCode, Locale as LocaleCode } from '@lazr/openapi-client'
import { Language, User } from '@/app/model'
import { Typography, MenuItem } from '@material-ui/core'
import { UserApiService } from '@/app/service/ApiService'
import { logger } from '@/app/logger'
import { setUser as reduxSetUser } from '@/app/ui/redux/actions/UserActions'
import { setLanguage as reduxSetLanguage } from '@/app/ui/redux/actions/LanguageActions'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'
import { getLanguageList as reduxGetLanguageList } from '../../../ui/redux/selectors/AppStateSelectors'
import Tooltip from '../Tooltip/Tooltip'

const LanguageMenu: React.FunctionComponent<LanguageMenuProps> = ({ user }) => {
    const { languageList } = useSelector(reduxGetLanguageList)
    const language = useSelector(reduxGetLanguage)

    const dispatch = useDispatch()

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const toggleMenu = useCallback((): void => {
        setIsMenuOpen((prevState) => !prevState)
    }, [setIsMenuOpen])

    const handleSelectedLanguage = useCallback(
        async (lang: Language): Promise<void> => {
            if (!user) {
                dispatch(reduxSetLanguage(lang.code))

                setIsMenuOpen(false)

                return
            }

            if (user.language.code === lang.code) {
                setIsMenuOpen(false)

                return
            }

            try {
                await UserApiService.editProfile({
                    id: user.id,
                    organizationId: user.organizationId,
                    locale: lang.code === LanguageCode.FR ? LocaleCode.FR_CA : LocaleCode.EN_CA,
                    language: lang.code,
                    uiSettings: user.uiSettings,
                })

                const currentUser = await UserApiService.getCurrent()

                if (user.getImpersonatingInfo().active) {
                    currentUser.impersonate(user.getImpersonatingInfo())
                }

                dispatch(reduxSetUser(currentUser))
                dispatch(reduxSetLanguage(currentUser.language.code))
            } catch (error: any) {
                logger.error(error)
            }

            setIsMenuOpen(false)
        },
        [dispatch, reduxSetLanguage, reduxSetUser, setIsMenuOpen, user, UserApiService, logger],
    )

    const impersonatingInfo = user?.getImpersonatingInfo()

    return  <>
        <IconButton
            ref={buttonRef}
            aria-owns={isMenuOpen ? 'menu-appbar' : ''}
            aria-haspopup='true'
            onClick={toggleMenu}
            color='inherit'
        >
            <Typography variant='body1' align='center'>
                {language}
            </Typography>
        </IconButton>

        <MenuBackgroundOverlay display={isMenuOpen ? 'block' : 'none'} onClick={(): void => setIsMenuOpen(false)}>
            <Menu id='menu-appbar' anchorEl={buttonRef.current} open={isMenuOpen} onClick={(): void => setIsMenuOpen(false)}>
                {languageList?.map((item) => (
                    <MenuItem
                        key={item.code}
                        value={item.code}
                        onClick={(event) => {
                            void (async (): Promise<void> => {
                                await handleSelectedLanguage(item)
                            })()
                        }}
                    >
                        {item.code}
                    </MenuItem>
                ))}
            </Menu>
        </MenuBackgroundOverlay>
        </>
}

interface LanguageMenuProps {
    user?: User | null
}

export default LanguageMenu
