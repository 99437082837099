import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logger } from '../../../logger'
import { LanguageApiService } from '../../../service/ApiService'
import { setLanguageList as reduxSetLanguageList } from '../../redux/actions/LanguageListActions'
import { getLanguageList as reduxGetLanguageList } from '../../redux/selectors/AppStateSelectors'

export const useLanguageList = () => {
    const dispatch = useDispatch()

    const { languageList, isLanguageListFetched } = useSelector(reduxGetLanguageList)

    React.useEffect(() => {
        void (async (): Promise<void> => {
            try {
                if (isLanguageListFetched) {
                    return
                }
                const languageListResponse = await LanguageApiService.list()
                dispatch(reduxSetLanguageList(languageListResponse))
            } catch (error: any) {
                logger.error('could not fetch language list', error)
            }
        })()
    }, [])

    return [ languageList ]
}
