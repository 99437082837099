import React, { useState, useEffect } from 'react'
import i18n from './OrganizationHeader.i18n'
import { useHistory, useLocation } from 'react-router-dom'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrganization as reduxGetUserOrganization } from '@/app/ui/redux/selectors'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors'
import moment from 'moment'
import { Header as StyledHeader, HeaderTitle, ModuleName, OrganizationName } from './OrganizationHeader.styled'

import { Grid, Typography } from '@material-ui/core'

{
    /* <Typography variant='h5'>
{organizationName} {organizationName && userFirstName && ' - '} {userFirstName}
</Typography>
<Typography variant='body2' pl={0.25}>
{moment().format('dddd, D MMMM YYYY')}
</Typography> */
}

export const OrganizationHeader: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const user = useSelector(reduxGetUser)
    const userOrganization = useSelector(reduxGetUserOrganization)

    const pathName = () => {
        let pathName = ''
        const pathURL = location?.pathname?.split('/')?.[2]
        let errors = false
        try {
            pathName = t(pathURL)
        } catch (error: any) {
            errors = true
        }

        if (errors === true) {
            errors = false
            const pathURL = location?.pathname?.split('/')?.[1]
            try {
                pathName = t(pathURL)
            } catch (error: any) {
                errors = true
            }
        }
        if (errors) {
            console.debug('Please add translation on the organizationHeader')
        }

        return pathName
    }

    const userName = () => {
        let pathName = ''

        try {
            const userFirstName = `${user?.firstName} ${user?.lastName}`
            if (pathName === '') {
                pathName = userFirstName ? userFirstName : ''
            }
        } catch (error: any) {
            console.debug('User not found on organizationHeader')
        }

        return pathName
    }

    return (
        <StyledHeader>
            <Grid container>
                <Grid item md={4} lg={4}>
                    <HeaderTitle>
                        {pathName() && <ModuleName>{pathName()} </ModuleName>}
                        <OrganizationName>
                            {userOrganization?.name || ''} - {userName()}
                        </OrganizationName>
                    </HeaderTitle>
                </Grid>
                <Grid container item sm={12} md={12} lg={8} justifyContent='flex-end'>
                    &nbsp;
                </Grid>
            </Grid>
        </StyledHeader>
    )
}
