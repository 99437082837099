import { combineReducers, Reducer } from 'redux'

import themeReducer from './ThemeReducer'
import appStateReducer from './AppStateReducer'
import versionReducer from './VersionReducer'
import versionReducerSnackbarOpen from './VersionSnackbarOpenReducer'
import userReducer from './UserReducer'
import userOrganizationReducer from './UserOrganizationReducer'
import snackbarReducer from './SnackbarReducer'
import notistackReducer from './NotistackReducer'
import marketPlaceReducer from './MarketPlaceReducer'
import currencyRatesReducer from './CurrencyRatesReducer'
import systemStatsReducer from './SystemStatsReducer'
import orderDetailsReducer from './OrderDetailsReducer'
import orderFilterReducer from './OrderListFilteringReducer'
import invoiceListFilterReducer from './InvoiceListFilteringReducer'
import orderEventsReducer from './OrderEventsReducer'
import addressListReducer from './AddressListReducer'
import consolidationPaymentListFilterReducer from './ConsolidationPaymentListFilteringReducer'
import newPickupDateReducer from './NewPickupDateReducer'
import addressListFilterReducer from './AddressListFilteringReducer'
import ediListFilterReducer from './EdiListFilteringReducer'
import userListFilterReducer from './UserListFilteringReducer'
import organizationListFilterReducer from './OrganizationListFilteringReducer'
import languageReducer from './LanguageReducer'
import { Store } from '../../definitions/Store'
import pendingPriceReviewReducer from '@/app/ui/redux/reducers/PendingPriceReviewReducer'
import applyPaymentInfoReducer from '@/app/ui/redux/reducers/ApplyPaymentInfoReducer'
import registerReducer from '@/app/ui/redux/reducers/RegisterDialogOpenReducer'
import requestDemoReducer from '@/app/ui/redux/reducers/RequestDemoDialogOpenReducer'
import laneListFilteringReducer from '@/app/ui/redux/reducers/LaneListFilteringReducer'
import newMarketPlaceReducer from '@/app/ui-new/redux/reducers/MarketPlaceReducer'
import addressListApiCallExecutedReducer from '@/app/ui/redux/reducers/AddressListApiCallExecutedReducer'

const rootReducer: Reducer<Store> = combineReducers<Store>({
    theme: themeReducer,
    appState: appStateReducer,
    version: versionReducer,
    versionSnackbarOpen: versionReducerSnackbarOpen,
    userInfo: userReducer,
    userOrganization: userOrganizationReducer,
    snackbar: snackbarReducer,
    notistack: notistackReducer,
    marketPlace: marketPlaceReducer,
    newMarketPlace: newMarketPlaceReducer,
    currencyRates: currencyRatesReducer,
    systemStats: systemStatsReducer,
    orderDetails: orderDetailsReducer,
    orderListFiltering: orderFilterReducer,
    orderEvents: orderEventsReducer,
    invoiceListFiltering: invoiceListFilterReducer,
    defaultAddresses: addressListReducer,
    consolidationPaymentListFiltering: consolidationPaymentListFilterReducer,
    newPickupDate: newPickupDateReducer,
    addressListFiltering: addressListFilterReducer,
    ediListFiltering: ediListFilterReducer,
    userListFiltering: userListFilterReducer,
    organizationListFiltering: organizationListFilterReducer,
    language: languageReducer,
    pendingPriceReview: pendingPriceReviewReducer,
    applyPaymentInfo: applyPaymentInfoReducer,
    requestedDemoDialogOpen: requestDemoReducer,
    requestedRegisterDialogOpen: registerReducer,
    laneListFiltering: laneListFilteringReducer,
    isDefaultBillingAddressesCallExecuted: addressListApiCallExecutedReducer,
})

export default rootReducer
