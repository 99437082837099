import { Typography } from '@material-ui/core/styles/createTypography'

const fontWeight = {
    light: 300,
    regular: 400,
    medium: 600,
    bold: 700,
}

const newDesignTypography: Partial<Typography> & { useNextVariants: boolean } = {
    useNextVariants: true,
    fontFamily: [
        'Graphik',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: fontWeight.light,
    fontWeightRegular: fontWeight.regular,
    fontWeightMedium: fontWeight.medium,
    fontWeightBold: fontWeight.bold,
    // Font properties reference: https://codepen.io/michaelwebster/details/LrmwWd
    h1: {
        fontFamily: 'Termina',
        fontSize: '6rem',
        fontWeight: fontWeight.light,
        lineHeight: '7rem',
        letterSpacing: '-0.09375rem',
    },
    h2: {
        fontSize: '3.75rem',
        fontWeight: fontWeight.light,
        lineHeight: '4.5rem',
        letterSpacing: '-0.03125rem',
    },
    h3: {
        fontSize: '3rem',
        fontWeight: fontWeight.regular,
        lineHeight: '3.5rem',
        letterSpacing: '0',
    },
    h4: {
        fontSize: '2.125rem',
        fontWeight: fontWeight.regular,
        lineHeight: '2.5rem',
        letterSpacing: '0.015625rem',
    },
    h5: {
        fontSize: '1.5rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1.75rem',
        letterSpacing: '0',
    },
    h6: {
        fontSize: '1.25rem',
        fontWeight: fontWeight.medium,
        lineHeight: '1.5rem',
        letterSpacing: '0.009375rem',
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1.5rem',
        letterSpacing: '0.009375rem',
    },
    subtitle2: {
        fontSize: '0.875rem',
        fontWeight: fontWeight.medium,
        lineHeight: '1.5rem',
        letterSpacing: '0.00625rem',
    },
    body1: {
        fontSize: '1rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1.75rem',
        letterSpacing: '0.03125rem',
    },
    body2: {
        fontSize: '0.875rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1.25rem',
        letterSpacing: '0.015625rem',
    },
    button: {
        fontSize: '0.875rem',
        fontWeight: fontWeight.medium,
        lineHeight: '1rem',
        letterSpacing: '0.078125rem',
        textTransform: 'uppercase',
    },
    caption: {
        fontSize: '0.75rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1rem',
        letterSpacing: '0.025rem',
    },
    overline: {
        fontSize: '0.625rem',
        fontWeight: fontWeight.regular,
        lineHeight: '1rem',
        letterSpacing: '0.09375rem',
        textTransform: 'uppercase',
    },
}

export default newDesignTypography
