import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import PrimaryButtonStyled from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import SecondaryButtonStyled from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { Link as RouterLink } from 'react-router-dom'

interface AuthFormProps{
    ref: React.RefObject<HTMLDivElement>
    children: JSX.Element | JSX.Element[]
}

export const AuthForm: React.FC<AuthFormProps> = styled.div<AuthFormProps>`
    width: 100%;
    max-width: 360px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /*opacity: 0;*/
    transition: opacity 0.3s;

    &.opacity1{
        opacity: 1;
    }

    & > div{
        margin: ${(props) => props.theme.spacing(4)}px 0;
    }

    & div:not([class^="MuiInputAdornment-root"]){
        width: 100%;
    }
`

export const PrimaryButton = styled(styled(PrimaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const SecondaryButton = styled(styled(SecondaryButtonStyled)(MuiSpacing))`
    width: 100%;
`

export const Link = styled(RouterLink)`
    width: 100%;
    text-decoration: none;
    color: ${(props): string => props.theme.palette.neutral600.main};
    margin-top: ${(props) => props.theme.spacing(6)}px;
`

export const Center = styled.div`
    width: 100%;
    padding-top: ${(props) => props.theme.spacing(6)}px;
`

export const CenteredAlertWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media only screen and (min-width: 568px) {
        display: block;
        transform: translateX(-54px);
    }
`
