import React from 'react'
import { ListItem as MuiListItem } from '@material-ui/core'
import styled from 'styled-components'
import { Props } from './ListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledListItem: React.FC<Props> = styled(MuiListItem)<Props>`
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
    display: flex;
    justify-content: space-between;
    color: ${(props) => props.disabled ? props.theme.palette.neutral500.main : props.theme.palette.black.main};
    pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};

    padding-left: ${(props): number => props.nested ? props.theme.spacing(8) : undefined}px;

    &:hover,
    &.Mui-selected:hover{
        background-color: ${(props) => props.disabled ? 'unset' : props.theme.palette.accentPrimary50.main};
    }

    &.Mui-selected{
        color: ${(props) => props.disabled ? props.theme.palette.neutral500.main : props.theme.palette.accentPrimary900.main};
        font-weight: 500;
        background-color: transparent;
    }

    & .lzr-subtitle{
        color: ${(props): number => props.theme.palette.neutral500.main};
    }

    &:focus {
        outline: none;
    }
`

export const CheckIcon = styled(FontAwesomeIcon)`
  color: ${(props): number => props.theme.palette.accentPrimary900.main};
  font-size: 1.4rem;
  margin-left: ${(props): number => props.theme.spacing(6)}px;
`
