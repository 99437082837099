import React, { useRef } from 'react'
import { Container, Video, VideoScreenShot, VideoOverlay, TextContainer, Header, Text } from './VideoHolderMobile.styled'
import { YoutubePlayButton, PlayButtonText } from './YoutubePlayButton'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './VideoHolderDesktop.i18n'

export const VideoHolderMobile: React.FunctionComponent<VideoHolderProps> = (props) => {
    const { t } = useI18n(i18n)

    const container: React.RefObject<HTMLDivElement> = useRef(null)
    const video: React.RefObject<HTMLDivElement> = useRef(null)
    const overlay: React.RefObject<HTMLDivElement> = useRef(null)
    const screenshot: React.RefObject<HTMLDivElement> = useRef(null)

    const handleVideoClick = (event: MouseEvent) => {
        window.setTimeout(() => {
            if (overlay.current && screenshot.current) {
                overlay.current.classList.add('hidden')
                screenshot.current.classList.add('hidden')
            }
        }, 100)
        if (video.current) {
            const iframe = video.current.querySelector('iframe')
            if (iframe) {
                iframe.src += '?autoplay=1'
            }
        }
    }

    const handleVideoLoaded = () => {
        if (video.current) {
            video.current.style.opacity = '1'
        }
    }

    return (
        <Container ref={container}>
            <TextContainer>
                <Header variant='h1'>
                    {t('Meet your intelligent shipping assistant')}
                </Header>
                <Text mt={6} mb={8}>
                    {
                        // eslint-disable-next-line max-len
                        t('Lazr is a leading shipping software that helps you save on pallet and parcel shipping, complete orders efficiently, and make deliveries on time.')
                    }
                </Text>
            </TextContainer>
            <Video
                ref={video}
            >
                <iframe
                    src="https://www.youtube.com/embed/Xq1i-TbUIBI"
                    title={t('Lazr Demo - Step by Step Intro')}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
                    // eslint-disable-next-line react/no-unknown-property
                    onLoad={handleVideoLoaded}
                />
                <VideoScreenShot
                    ref={screenshot}
                    src={`/images/website/VideoHolder/video-screenshot_54670-ms.png?v=${window.lazrVersion}`}
                />
                <VideoOverlay
                    ref={overlay}
                    onClick={handleVideoClick}
                >
                    <YoutubePlayButton/>
                    <PlayButtonText mt={4}>
                        {t('Watch a 1-minute demo')}
                    </PlayButtonText>
                </VideoOverlay>
            </Video>
        </Container>
    )
}

export interface VideoHolderProps {
}
