/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './MarketPlaceHeader.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Marketplace`]: `Marketplace`,
    [`Cargo`]: `Cargo`,
    [`Transit`]: `Transit`,
    [`Coverage`]: `Couverture`,
    [`Results`]: `Résultats`,
    [`Details`]: `Détails`,
    [`Checkout`]: `Paiement`,
    [`Reset order`]: `Réinitialiser`,
    ['Reset Order']: `Réinitialiser la commande`,
    [`Create new order`]: `Créer une nouvelle commande`,
    ['Are you sure you want to reset the current order?']: `Êtes-vous certain de vouloir réinitialiser la commande?`,
    ['Cancel']: `Annuler`,
    ['Reset']: `Réinitialiser`,
}))()
