/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DocumentType {
    BILL_OF_LADING = 'BILL_OF_LADING',
    LOAD_TENDER = 'LOAD_TENDER',
    LABEL = 'LABEL',
    PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
    OTHER = 'OTHER',
    TRANSPORT_INVOICE = 'TRANSPORT_INVOICE',
    INVOICE_SUPPORT = 'INVOICE_SUPPORT',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE',
    AUTHORIZATION_FORM = 'AUTHORIZATION_FORM',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    EXPORT_ACCOMPANYING_DOCUMENT = 'EXPORT_ACCOMPANYING_DOCUMENT',
    EXPORT_LICENSE = 'EXPORT_LICENSE',
    IMPORT_PERMIT = 'IMPORT_PERMIT',
    ONE_TIME_NAFTA = 'ONE_TIME_NAFTA',
    OTHER_CUSTOMS = 'OTHER_CUSTOMS',
    POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
    PACKING_LIST = 'PACKING_LIST',
    SED_DOCUMENT = 'SED_DOCUMENT',
    SHIPPERS_LETTER_OF_INSTRUCTION = 'SHIPPERS_LETTER_OF_INSTRUCTION',
    DECLARATION = 'DECLARATION',
    USMCA_CERTIFICATION_OF_ORIGIN = 'USMCA_CERTIFICATION_OF_ORIGIN',
    USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN = 'USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN',
    PRO_FORMA_INVOICE = 'PRO_FORMA_INVOICE',
    WAYBILL_DOCUMENT = 'WAYBILL_DOCUMENT',
    TRACKING_CSV = 'TRACKING_CSV',
}