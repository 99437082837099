/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    'Handling Unit': 'Handling Unit',
    Measurements: 'Measurements',
    Quantity:'Quantity',
    Weight:'Weight',
   'Weight Unit':'Weight Unit',
    Height:'Height',
    Length:'Length',
    Width:'Width',
    'Dimention Unit':'Dimention Unit',
    'in/lb': 'in/lb',
    'cm/kg': 'cm/kg',
    'Total weight':'Total weight',
    Centimeter: 'Centimeter',
    Kilogram: 'Kilogram',
    Inch: 'Inch',
    Pound: 'Pound',
    Parcels:'Parcels',
    'Dimension unit selector': 'Dimension unit selector',
    Inches: 'Inches',
    Pounds: 'Pounds',
    Centimeters: 'Centimeters',
    Kilograms: 'Kilograms',
    'Height needs to be smaller than': 'Height needs to be smaller than',
    'Length needs to be smaller than' : 'Length needs to be smaller than',
    'Average weight': 'Average weight'
}
