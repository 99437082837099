import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@material-ui/core/Collapse'
import {
    BackgroundGradientLayer0 as BackgroundGradientLayer0Styled,
    BackgroundGradientLayer1 as BackgroundGradientLayer1Styled,
    BackgroundGradientLayer2 as BackgroundGradientLayer2Styled,
    BackgroundGradientLayer3 as BackgroundGradientLayer3Styled,
    BackgroundGradientLayer4 as BackgroundGradientLayer4Styled,

    FlipCardsTitle as FlipCardsTitleStyled,
    CardsContainer as CardsContainerStyled,
    Grid as GridStyled,
    CardOuter,
    CardInner,
    Side,
    IconButton,
    ClickZone,
    ImageContainer,
    Typography1,
    Typography2,
    Typography3,
    Typography4,
    KeyPointsContainer,
    IconContainer,
    KeyPointItem,
} from './CardMobile.styled'

export const BackgroundGradientLayer0 = BackgroundGradientLayer0Styled
export const BackgroundGradientLayer1 = BackgroundGradientLayer1Styled
export const BackgroundGradientLayer2 = BackgroundGradientLayer2Styled
export const BackgroundGradientLayer3 = BackgroundGradientLayer3Styled
export const BackgroundGradientLayer4 = BackgroundGradientLayer4Styled

export const FlipCardsTitle = FlipCardsTitleStyled
export const CardsContainer = CardsContainerStyled
export const Grid = GridStyled

export const CardMobile: React.FunctionComponent<CardProps> = (props) => <CardOuter>
    <CardInner>
        <Side>
            <ClickZone
                onClick={props.handleExpand}
                value={props.cardIndex}
                className='clickzone'
            >
                <GridStyled container direction='row' xs={12}>
                    <GridStyled item xs={4}>
                        <ImageContainer>
                            {props.front.image}
                        </ImageContainer>
                    </GridStyled>

                    <GridStyled item xs={7}>
                        <GridStyled container direction='column' xs={12}>
                            <Typography1 variant='h5'>
                                {props.front.iAm}
                            </Typography1>
                            <div>
                                <Typography2 variant='h1' mr={2}>
                                    {props.front.position}
                                </Typography2>
                            </div>
                        </GridStyled>
                    </GridStyled>

                    <GridStyled
                        item
                        xs={1}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <IconButton
                            size='small'
                            className={props.expanded ? 'rotated' : ''}
                        >
                            <FontAwesomeIcon icon={[ 'fas', 'chevron-down' ]}/>
                        </IconButton>
                    </GridStyled>
                </GridStyled>
            </ClickZone>

            <Collapse in={props.expanded}>
                <Typography3 variant='h5' mb={6}>
                    {props.back.title}
                </Typography3>

                <KeyPointsContainer>
                    {props.back.keypoints.map((keypoint, index) => <KeyPointItem mb={5} key={`keypoint_${index}`}>
                        <IconContainer>
                            {keypoint.icon}
                        </IconContainer>
                        <Typography4 variant='body1'>
                            {keypoint.text}
                        </Typography4>
                    </KeyPointItem>,
                    )}
                </KeyPointsContainer>

                {props.back.callToActionButton}
            </Collapse>
        </Side>
    </CardInner>
</CardOuter>

interface KeyPoint {
    icon: JSX.Element
    text: string
}

export interface CardProps {
    front: {
        image: JSX.Element
        iAm: string
        position: string
    }
    back: {
        title: string
        keypoints: KeyPoint[]
        callToActionButton: JSX.Element
    }
    enableHover?: boolean
    cardIndex: number
    expanded: boolean
    handleExpand: React.MouseEventHandler<HTMLButtonElement>
}
