import { Store } from '../../definitions/Store'
import { User, OtherOrganization, BillToAddress, Lane } from '../../../model'
import { UserFilter } from '../../definitions/UserList'
import { Currency } from '@lazr/enums'

export const getUser = (store: Store): User | null => store.userInfo.user
export const getUserIsSignedIn = (store: Store): boolean => store.userInfo.isSignedIn
export const getUserFilters = (store: Store): UserFilter => store.userListFiltering.filters
export const getOrganizationList = (store: Store): OtherOrganization[] => store.userListFiltering.organizationList
export const getDefaultUserCurrency = (store: Store): Currency => store.userInfo.defaultUserCurrency
