import moment from 'moment'
import { MarketPlace, MarketPlaceStep } from '../../definitions/MarketPlace'

import {
    SearchRfq,
    SearchRfqCustomsDetail,
} from '@/app/model'

import {
    OrderStatus,
    PaymentTypeOption,
    RfqStatus,
    WeightUnit,
} from '@lazr/openapi-client'


export const initialCustomsDetails: Partial<SearchRfqCustomsDetail> = {
    id: undefined,
    productIndicatorIsUsmca: false,
    productIndicatorFda: false,
    productIndicatorTextile: false,
    documentIndicatorUsmca: false,
    documentIndicatorFda: false,
    documentIndicatorFcc: false,
    senderIsProducerIndicator: false,
    textileManufacturer: null,
    unitWeight: 0,
    weightUnit: WeightUnit.LB,
    pieces: 0,
    handlingUnitIds: [],
}

export const initialDutyBillAddress : Partial<SearchRfq> = {
    dutyBillToCity: null,
    dutyBillToContactEmail: null,
    dutyBillToCompanyName: null,
    dutyBillToContactName: null,
    dutyBillToContactPhone: null,
    dutyBillToContactPhoneExt: null,
    dutyBillToCountry: null,
    dutyBillToName: null,
    dutyBillToPostalCode: null,
    dutyBillToState: null,
    dutyBillToStreetAddress: null,
    dutyBillToStreetAddress2: null,
    dutyBillToAccountNumber: null,
}

export const initialCustomsBrokerAddress : Partial<SearchRfq> = {
    customsBrokerCity: null,
    customsBrokerEmail: null,
    customsBrokerContactName: null,
    customsBrokerContactPhone: null,
    customsBrokerContactPhoneExt: null,
    customsBrokerCountry: null,
    customsBrokerPostalCode: null,
    customsBrokerState: null,
    customsBrokerStreetAddress: null,
    customsBrokerStreetAddress2: null,
    customsBrokerAccountNumber: null,
}

export const initialCustomsInfo : Partial<SearchRfq> = {
    importExport: null,
    incotermsType: null,
    billDutiesToParty: null,
    commercialInvoiceDocumentIndicator: true,
    commercialInvoiceUserProvided: null,
    dutyBillToCity: null,
    dutyBillToContactEmail: null,
    dutyBillToCompanyName: null,
    dutyBillToContactName: null,
    dutyBillToContactPhone: null,
    dutyBillToContactPhoneExt: null,
    dutyBillToCountry: null,
    dutyBillToName: null,
    dutyBillToPostalCode: null,
    dutyBillToState: null,
    dutyBillToStreetAddress: null,
    dutyBillToStreetAddress2: null,
    dutyBusinessRelated: null,
    dutyCurrency: null,
    reasonForExport: null,
    nonDeliveryHandling: null,
    otherReasonForExport: null,
    customsBroker: null,
    customsBrokerCity: null,
    customsBrokerEmail: null,
    customsBrokerContactName: null,
    customsBrokerContactPhone: null,
    customsBrokerContactPhoneExt: null,
    customsBrokerCountry: null,
    customsBrokerPostalCode: null,
    customsBrokerState: null,
    customsBrokerStreetAddress: null,
    customsBrokerStreetAddress2: null,
    customsBrokerAccountNumber: null,
    taxIdentificationNumber: null,
}

export const initialHazmatDetail : Partial<SearchRfq> = {
    hazmatClass: null,
    hazmatMode: null,
    hazmatDeclarationDocumentIndicator: null,
}

export const resetMarketPlace: Partial<MarketPlace> = {
    order: {
        billingCurrency: null,
        status: OrderStatus.PRELOAD,
        ediOrderId: null,
        rfq: {
            ...initialCustomsInfo,
            accessorials: [],
            handlingUnits: [],
            quotes: [],
            customQuoteRequested: false,
            status: RfqStatus.PRELOAD,
            coverageCommodityType: undefined,
            shipmentCoverageValue: undefined,
            isBookedWithCoverage: undefined,
            originAddressType: null,
            destinationAddressType: null,
        },
    },
    originSaveToAddressBook: false,
    destinationSaveToAddressBook: false,
    originFullAddress: null,
    destinationFullAddress: null,
    dutyBillFullAddress: null,
    customsBrokerFullAddress: null,
    lastCompletedStep: /*Number(localStorage.getItem('newMarketPlaceLastCompletedStep')) ??*/ 0,
    currentStep: /*Number(localStorage.getItem('newMarketPlaceStep')) ??*/ MarketPlaceStep.LOAD_AND_TERMS,
    isQuoteFetchingComplete: false,
    quoteFetchingResultsCompleted: {},
    quoteFetchingSearchProgress: 0,
    marketPlaceErrors: {
        rfqIsDirty: {},
        cargoIsDirty: [ {} ],
        rfqErrors: {},
        cargoErrors: [ {} ],
        customsDetailErrors: [ {} ],
        customsDetailIsDirty: [ {} ],
        rfqCustomsErrors: {},
        rfqCustomsIsDirty: {},
        isSubmitted: false,
        handlingUnitLinkErrors: [],
        handlingUnitLinkIsDirty: [],
    },
    invalidationDialog: {},
    isCoverageExclusionsAccepted: undefined,
    promptModalLeave: false,
    originAddressBookAddress: null,
    destinationAddressBookAddress: null,
    selectedValidatedOriginAddress: null,
    selectedValidatedDestinationAddress: null,
    openReasonsModal: false,
    isPrepared: false,
    paymentType: PaymentTypeOption.PREPAID,
    collect: null,
    selectedLane: null,
    fillMarketPlaceTrigger: null,
    promptUnsavedChangesDialog: false,
    marketplaceVersion: 2,
}

// @ts-ignore FIXME: Leftover error after fixing the frontend build on 2022-05-04 by mbiard
export const initialMarketPlace: MarketPlace = {
    ...resetMarketPlace,
    coverageCommodityTypeList: [],
    packageTypeList: [],
    handlingUnitAccessorialList: [],
    serviceAccessorialList: [],
    originAccessorialList: [],
    destinationAccessorialList: [],
    countryList: [],
}

/*
export const initialState: Store = {
    marketPlace: deepCopy(initialMarketPlace),
}
*/
