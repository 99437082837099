import React from 'react'
import styled from 'styled-components'
import RawLogo from '@/app/ui/components/Logo'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import { Typography } from '@/app/ui/pages/components/AddressValidation.styled'
import { Box, BoxProps, ButtonProps, TypographyProps } from '@material-ui/core'

export const Page: React.FC = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: hidden;
    width: 100vw;
    padding: ${(props): string => props.theme.spacing(16)}px
        0
        ${(props): string => props.theme.spacing(6)}px
        ${(props): string => props.theme.spacing(27)}px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-y: scroll;
`

export const BottomPage = styled.div`
    background-color: #E2E0F2;
    position: fixed;
    bottom: 0;

    @media only screen and (max-height: 910px) {
        position: relative;
    }

`

export const Backgroundcolor = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    background-color: #EBE9F9;
`

export const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    z-index: -1;
    width: 1440px;
    height: 100vh;
    min-height: 876px;

    & img{
        width: 1440px;
        height: 876px;

        &.arrows{
            transform: scale(1.12) translate(471px,-45px);
        }
        &.browser{
            transform: scale(0.693) translate(803px,-136px);
        }
    }

    @media only screen and (max-width: 5000px) {
        right: 400px;
    }

    @media only screen and (max-width: 1700px) {
        right: 300px;
    }

    @media only screen and (max-width: 1600px) {
        right: 200px;
    }

    @media only screen and (max-width: 1500px) {
        right: 150px;
    }

    @media only screen and (max-width: 1400px) {
        right: -14px;
    }

    @media only screen and (max-width: 1300px) {
        right: -113px;
    }

    @media only screen and (max-width: 1200px) {
        right: -208px;
    }

    @media only screen and (max-width: 1100px) {
        right: -349px;
    }

    @media only screen and (max-width: 960px) {
        right: -440px;
    }
`

export const ContentContainer = styled.div`
    width: 600px;
    margin-top: ${(props): string => props.theme.spacing(14.375)}px;

    & > div > div{
        justify-content: unset;
        transform: translateX(-20px);
    }
`

export const Logo = styled(RawLogo)`
    width: 92px;
    height: 36.5px;
    min-height: 36.5px;
    fill: ${(props): string => props.theme.palette.neutral800.main};
`

export const Title = styled.div`
    color: ${(props) => props.theme.palette.neutral800.main};
    font-family: 'Termina';
    font-size: 2.6428rem;
    font-weight: 700;
    line-height: 3.1714rem;
    margin-bottom:  ${(props): string => props.theme.spacing(8)}px;
`

export const Subtitle = styled.div`
    font-size: 1.1428rem;
    font-weight: 400;
    line-height: 1.6rem;
`

export const CtaButton: React.FC<ButtonProps> = styled(PrimaryButton)<ButtonProps>`
    height: 80px;
    min-width: 392px;
    border-radius: 5.5px;
    background-color: ${(props): string => props.theme.palette.accentSecondary.main};
    box-shadow: 0px 8px 40px 0px rgba(11, 115, 108, 0.26);

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary.main};
        outline-offset: 3px;
    }

    &:active {
        background: ${(props): number => props.theme.palette.accentPrimary700.main};
        box-shadow: 0px 8px 40px 0px rgba(53, 11, 115, 0.22);
        outline: 2px solid ${(props): number => props.theme.palette.accentPrimary500.main};
        outline-offset: 3px;
    }

    &:hover {
        background: ${(props): number => props.theme.palette.accentPrimary700.main};
        box-shadow: 0px 8px 40px 0px rgba(53, 11, 115, 0.22);
        outline: 2px solid ${(props): number => props.theme.palette.accentPrimary500.main};
        outline-offset: 3px;
    }
`

export const ButtonTextTypography = styled(Typography)`
    margin-top: ${(props): string => props.theme.spacing(1)}px;
    font-family: Termina;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
`

export const StyledTypographyLabel: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    color: ${(props): number => props.theme.palette.neutral700.main};
`

export const StyledTypographyH1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
`

export const CarouselContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
   width: calc(100vw - 246px - 108px - 10px);
`
