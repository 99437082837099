import React from 'react'
import {
    BackgroundGradientLayer0,
    BackgroundGradientLayer1,
    BackgroundGradientLayer2,
    BackgroundGradientLayer3,
    BackgroundGradientLayer4,
    StyledTitleTypography, StyledPrimaryTextTypography, StyledSubTitleTypography, CheckIcon, StyledSecondaryTextTypography,
} from './BannerDesktop.styled'
import { Box } from '@material-ui/core'
import TestimonialsCarousel from '@/app/ui-new/pages/landing/components/TestimonialsCarousel/TestimonialsCarousel'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './BannerDesktop.i18n'
import { useContent } from '@/app/ui-new/pages/landing/hooks/useContent'

export const BannerDesktop: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const content = useContent()

    return <BackgroundGradientLayer0>
        <BackgroundGradientLayer1/>
        <BackgroundGradientLayer2/>
        <BackgroundGradientLayer3/>
        <BackgroundGradientLayer4>
            <Box width={505} position={'absolute'}>
                <StyledTitleTypography variant='h1'>
                    {t('Create your free Account')}
                </StyledTitleTypography>
                <Box mt={6}>
                    <StyledPrimaryTextTypography variant='body1'>
                        {/* eslint-disable-next-line max-len */}
                        {t('Start using Lazr for free and explore all the potential carriers and pricing for your shipments. No contracts or credit card required.')}
                    </StyledPrimaryTextTypography>
                </Box>
                <Box mt={20}>
                    <StyledSubTitleTypography variant='h1'>
                        {t('Ship better in every way')}
                    </StyledSubTitleTypography>
                </Box>
                <Box mt={6}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Box mr={4}><CheckIcon icon={[ 'far', 'check-circle' ]} /></Box>
                        <StyledSecondaryTextTypography>{t('50+ Connected freight carriers')}</StyledSecondaryTextTypography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} mt={4}>
                        <Box mr={4}><CheckIcon icon={[ 'far', 'check-circle' ]} /></Box>
                        <StyledSecondaryTextTypography>{t('8 Connected parcel carriers')}</StyledSecondaryTextTypography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'} mt={4}>
                        <Box mr={4}><CheckIcon icon={[ 'far', 'check-circle' ]} /></Box>
                        <StyledSecondaryTextTypography>{t('21% Average savings in shipping costs')}</StyledSecondaryTextTypography>
                    </Box>
                    <Box display={'flex'} flexDirection={'row'}  mt={4}>
                        <Box mr={4}><CheckIcon icon={[ 'far', 'check-circle' ]} /></Box>
                        <StyledSecondaryTextTypography>{t('95% Faster than traditional quoting')}</StyledSecondaryTextTypography>
                    </Box>
                </Box>
            </Box>
            <Box pt={190} mr={50}>
                <TestimonialsCarousel testimonials={content.testimonials}/>
            </Box>
        </BackgroundGradientLayer4>
    </BackgroundGradientLayer0>
}
