import React, { useEffect, useState } from 'react'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import SubscriptionService from '@/app/service/SubscriptionService'
import i18n from './NotificationsMenu.i18n'
import { Divider, Typography } from '@material-ui/core'
import { EventType } from '@lazr/openapi-client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserEventNotification } from '@/app/model/UserEventNotification'
import { UserEventNotificationApiService } from '@/app/service/ApiService'
import { getUserEventNotificationMessageAndLink } from '@/app/ui/lib/helpers'
import { logger } from '@/app/logger'
import { useHistory } from 'react-router-dom'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import {
    Badge,
    Grid,
    Menu,
    MarkAllReadLink,
    NotificationMenuItem,
    NotificationMenuItemViewAll,
    NotificationMenuTitle,
    NotificationMessage,
    NotificationTime,
} from './NotificationsMenu.styled'

const NotificationsMenu: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)
    const history = useHistory()

    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const [ numUnviewedNotifications, setNumUnviewedNotifications ] = useState<number>(0)
    const [ notifications, setNotifications ] = useState<UserEventNotification[]>([])

    const sound = new Audio(`/sounds/notification.mp3?v=${window.lazrVersion}`)

    sound.volume = 0.5

    const toggleMenu = async (): Promise<void> => {
        setIsMenuOpen(!isMenuOpen)
        if (!isMenuOpen) {
            setNumUnviewedNotifications(0)
            try {
                await UserEventNotificationApiService.markAllAsViewed()
            } catch (error: any) {
                logger.error('Unable to mark all user event notifications as viewed')
                logger.error(error.message)
                logger.error(error.stack)
            }
        }
    }

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }

    const handleNotificationClick = async (id: string, linkTo: string): Promise<void> => {
        setNotifications(
            notifications.map((n: UserEventNotification) => {
                if (n.notificationId === id) {
                    return {
                        ...n,
                        notificationIsRead: true,
                    }
                }

                return n
            }),
        )

        try {
            await UserEventNotificationApiService.markAsRead(id)
        } catch (error: any) {
            logger.error('Unable to mark user event notification as read')
            logger.error(error.message)
            logger.error(error.stack)
        }

        if (linkTo && linkTo !== history.location.pathname) {
            history.push(linkTo)
        } else {
            closeMenu()
        }
    }

    const handleMarkAllAsRead = async (): Promise<void> => {
        setNotifications(
            notifications.map((n: UserEventNotification) => ({
                ...n,
                notificationIsRead: true,
            })),
        )
        try {
            await UserEventNotificationApiService.markAllAsRead()
        } catch (error: any) {
            logger.error('Unable to mark all user event notifications as read')
            logger.error(error.message)
            logger.error(error.stack)
        }
    }

    const updateNotifications = async (): Promise<void> => {
        try {
            const result = await UserEventNotificationApiService.list({ page: 1, resultPerPage: 10 })
            setNotifications(result.notifications)
            setNumUnviewedNotifications(result.unviewed)
        } catch (error: any) {
            logger.error('Unable to retrieve user event notifications')
            logger.error(error.message)
            logger.error(error.stack)
        }
    }

    useEffect((): (() => void) | undefined => {
        const subscription = SubscriptionService.subscribe(
            [
                EventType.USER_EVENT_NOTIFICATION_ADDED,
                EventType.USER_EVENT_NOTIFICATION_MARKED_AS_READ,
                EventType.USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ,
                EventType.USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED,
            ],
            async (event): Promise<void> => {
                await updateNotifications()
                if (event.type === EventType.USER_EVENT_NOTIFICATION_ADDED) {
                    await sound.play().catch((e) => { logger.info(e) })
                }
            },
        )

        return subscription.destroy
    }, [])

    useEffect(() => {
        void (async (): Promise<void> => {
            await updateNotifications()
        })()
    }, [])

    return <>
        <SecondaryButton
            aria-owns={isMenuOpen ? 'menu-appbar' : ''}
            aria-haspopup='true'
            startIcon={
                <Badge badgeContent={numUnviewedNotifications} max={99}>
                    <FontAwesomeIcon icon={[ 'far', 'bell' ]}/>
                </Badge>
            }
            size={'small'}
            onClick={() => void toggleMenu()}
        />
        <Menu
            id='menu-appbar'
            anchorReference='none'
            open={isMenuOpen}
            onClose={closeMenu}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container direction='column'>
                <Grid container item direction='row'>
                    <Grid item xs={6}>
                        <NotificationMenuTitle variant='h6'>
                            Notifications
                        </NotificationMenuTitle>
                    </Grid>
                    <Grid container item xs={6} alignItems='center' justifyContent='flex-end'>
                        <Grid item>
                            {notifications.length > 0 &&
                                <MarkAllReadLink onClick={() => {
                                    void (async (): Promise<void> => {
                                        await handleMarkAllAsRead()
                                    })()
                                }
                                }>
                                    Mark all as read
                                </MarkAllReadLink>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider/>
                </Grid>
                {
                    notifications.map((n: UserEventNotification) =>
                        <Grid container item key={n.notificationId} xs={12}>
                            {(() => {
                                const { message, linkTo, timeAgo } = getUserEventNotificationMessageAndLink(n)

                                return <Grid container item>
                                    <NotificationMenuItem
                                        onClick={() => {
                                            void (async (): Promise<void> => {
                                                await handleNotificationClick(n.notificationId, linkTo)
                                            })()
                                        }}>
                                        <Grid item xs={11}>
                                            <NotificationMessage>
                                                {message}
                                            </NotificationMessage>
                                            <NotificationTime>
                                                {timeAgo}
                                            </NotificationTime>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography variant='h6'>
                                                {n.notificationIsRead || '•'}
                                            </Typography>
                                        </Grid>
                                    </NotificationMenuItem>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Grid>
                            })()}
                        </Grid>,
                    )
                }
                <Grid item>
                    <NotificationMenuItemViewAll onClick={(): void => history.push('/notifications')}>
                        View all notifications
                    </NotificationMenuItemViewAll>
                </Grid>
            </Grid>
        </Menu>
    </>
}

export { NotificationsMenu }
