/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['Email cannot be empty']: 'Email cannot be empty',
    ['Email is not a valid format']: 'Email is not a valid format',
    ['Password cannot be empty']: 'Password cannot be empty',
    [`Password must be at least '{passwordLength}' characters long`]: ({ passwordLength }: { passwordLength: number }): string => (`Password must be at least ${passwordLength} characters long`),
    ['This email has not been verified']: 'This email has not been verified',
    ['You have not yet been approved by your administrator']: 'You have not yet been approved by your administrator',
    ['The specified login token is invalid']: 'The specified login token is invalid',
    ['This email / password combination is invalid']: 'This email / password combination is invalid',
    ['Email']: 'Email',
    ['Password']: 'Password',
    ['Sign In']: 'Sign In',
    ['Forgot password?']: 'Forgot password?',
}
