// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect } from 'react'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import ToggleButton from '../../ToggleButton/ToggleButton'
import { Label } from '../../TextField/TextField.styled'
import { Typography } from '../FormSimple.styled'
import ToggleButtonGroup from '../../ToggleButton/ToggleButtonGroup'
import { DivGroup } from './toggleForm.styled'

export interface ToggleFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleSubmit: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const ToggleForm: React.FC<ToggleFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleSubmit,
}) => {
    const { type, tooltip, labelName, placeholder, spaceForError, subLabel, disabled, width } = field

    useEffect(() => {
        setValue(field.name, field.values)
        debouncedHandleSubmit?.()
    }, [field.values])

    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field: { onChange, value } }) => {
                const helperTextField = errors?.[field.name]?.message ? (
                    <>
                        <Typography variant='caption' color='error'>
                            {' '}
                            {errors?.[field.name]?.message}
                        </Typography>
                    </>
                ) : (
                    ' '
                )

                return (
                    <DivGroup error={errors?.[field.name]?.message}>
                        <Label $disabled={!field.labelName}>
                            {field.labelName} {field.required ? '*' : ''}
                        </Label>
                        <ToggleButtonGroup
                            value={value}
                            exclusive
                            onChange={(e, newValue: any) => {
                                onChange(newValue)
                                setValue(field.name, newValue, { shouldValidate: false })
                                // Trigger validation on change
                                trigger(field.name).then(() => {
                                    const isFieldValid = !errors[field.name]
                                    if (isFieldValid && onChange) {
                                        onChange(newValue)
                                        setValue(field.name, newValue, {
                                            shouldValidate: true,
                                        })
                                    }
                                    debouncedHandleSubmit?.()
                                })
                            }}
                        >
                            <ToggleButton value={field?.toggles?.first?.value} withSelectedIcon>
                                {field?.toggles?.first?.label}
                            </ToggleButton>
                            <ToggleButton value={field?.toggles?.second?.value} withSelectedIcon>
                                {field?.toggles?.second?.label}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {helperTextField}
                    </DivGroup>
                )
            }}
        />
    )
}

export default ToggleForm
