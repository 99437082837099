import React from 'react'
import styled from 'styled-components'
import RawLogo from '@/app/ui/components/Logo'
import { Typography } from '@/app/ui/pages/components/AddressValidation.styled'
import { Box, BoxProps, ButtonProps, TypographyProps } from '@material-ui/core'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'

export const Page: React.FC = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    background-image: url(\"/images/landing/TealTheme/background/BackgroundClean.png?v=${window.lazrVersion}\");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #D8F5F2;
`

export const Card1 = styled.img`
    position: absolute;
    width: 252px;
    height: 215px;
    top: -47px;
    left: 80px;
    transform: rotate(12deg);
`

export const Card2 = styled.img`
    position: absolute;
    width: 252px;
    height: 215px;
    top: 17px;
    right: 0;
    transform: rotate(4.3deg);
`

export const Card3 = styled.img`
    position: absolute;
    width: 252px;
    height: 215px;
    top: 386px;
    left: -40px;
    transform: rotate(7deg);
`
export const Card4 = styled.img`
    position: absolute;
    width: 252px;
    height: 215px;
    top: 439px;
    right: 0;
    transform: rotate(-9deg);
`
export const Logo = styled(RawLogo)`
    width: 92px;
    fill: ${(props): string => props.theme.palette.neutral800.main};
`

export const TitleTypography = styled(Typography)`
    color: ${(props): string => props.theme.palette.neutral800.main};
    font-size: 4rem;
    font-weight: 700;
    line-height: 120%;
`

export const SubTitleTypography = styled(Typography)`
    color: ${(props): string => props.theme.palette.black.main};
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 135%;
`

export const ButtonTextTypography = styled(Typography)`
    margin-top: 4px;
    font-family: Termina;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
`

export const StyledPrimaryButton: React.FC<ButtonProps> = styled(PrimaryButton)<ButtonProps>`
    height: 80px;
    min-width: 392px;
    border-radius: 5.5px;
    background-color: ${(props): number => props.theme.palette.accentPrimary700.main};
    box-shadow: 0px 8px 40px 0px rgba(11, 115, 108, 0.26);

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentPrimary700.main};
        outline-offset: 3px;
    }

    &:active {
        background: ${(props): number => props.theme.palette.accentSecondary.main};
        box-shadow: 0px 8px 40px 0px rgba(53, 11, 115, 0.22);
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }

    &:hover {
        background: ${(props): number => props.theme.palette.accentSecondary.main};
        box-shadow: 0px 8px 40px 0px rgba(53, 11, 115, 0.22);
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }
`

export const StyledTypographyLabel: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    color: ${(props): number => props.theme.palette.neutral700.main};
`

export const StyledTypographyH1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
`

export const CarouselContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
   width: calc(100vw - 246px - 108px - 10px);
`
