/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

import React from 'react'

export default {
    ['Cookie Policy']: 'Cookie Policy',
    [`This site uses cookies to remember your preferences across different visits and otherwise provide you with a great user experience.`]: () => (
        <>
            This site uses cookies to remember your preferences across different visits and otherwise provide you with a great user experience.<br/>
            Cookies also help us understand how the application is being used for continuous improvements in ways that matter to our users.<br/>
            By using Lazr, you accept our use of cookies.
        </>
    ),
    ['Decline']: 'Decline',
    ['Accept']: 'Accept',
}
