/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EdiOrderStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
}