import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { Country } from '../../model'
import { handleUnauthorizedException } from './index'

export const CountryApiService = Object.freeze({
    list: async (): Promise<Country[]> => {
        let getCountriesResponse
        try {
            getCountriesResponse = await EnumTypesService.getCountries()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve country list')
            }
            throw new Error('Unable to retrieve country list')
        }

        if (!getCountriesResponse.data) {
            logger.debug('list', JSON.stringify(getCountriesResponse, null, 4))
            throw new Error('Unable to retrieve country list')
        }

        return getCountriesResponse.data
    },
})
