import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const BackgroundGradientLayer0 = styled(styled.div(spacing))`
    position: relative;
    opacity: 0.9;
`

export const BackgroundGradientLayer1 = styled.div`
    background: linear-gradient(to right, ${(props): number => props.theme.palette.accentSecondary.main},
        ${(props): number => props.theme.palette.accentPrimary.main});
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    clip-path: polygon(0% 35%, 30% 50%, 70% 10%, 100% 10%, 100% 45%, 90% 55%, 40% 90%, 0 60%);
`

export const BackgroundGradientLayer2 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
`

export const BackgroundGradientLayer3 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.7;
`

export const BackgroundGradientLayer4 = styled.div`
    backdrop-filter: blur(30px);
    padding: ${(props): string => props.theme.spacing(25)}px
        ${(props): string => props.theme.spacing(24)}px
        ${(props): string => props.theme.spacing(36)}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media screen and (max-width: 1712px){
        padding: ${(props): string => props.theme.spacing(25)}px
            ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(36)}px;
    }
`

export const StyledTitleTypography = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 120%;
`

export const StyledSubTitleTypography = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
`

export const StyledPrimaryTextTypography = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    font-size: 1.125rem;
    line-height: 135%;
`

export const StyledSecondaryTextTypography = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    font-size: 1rem;
    line-height: 140%;
    padding-top: 2px;
`

export const CheckIcon = styled(FontAwesomeIcon)`
  color: ${(props): number => props.theme.palette.accentPrimary.main};
  font-size: 1.5rem;
`
