/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet990Schema_parse_ = {
    heading?: {
        remarksK1?: Array<{
            freeFormMessage02?: string,
            freeFormMessage01?: string,
        }>,
        eventDetailV9?: Array<{
            length20?: number,
            weight19?: number,
            totalEquipment18?: number,
            totalEquipment17?: number,
            totalEquipment16?: number,
            standardPointLocationCode15?: string,
            quantity14?: number,
            timeCode13?: string,
            freeFormMessage12?: string,
            trainDelayReasonCode11?: string,
            quantity10?: number,
            standardPointLocationCode09?: string,
            statusReasonCode08?: string,
            countryCode07?: string,
            stateOrProvinceCode06?: string,
            cityName05?: string,
            event02?: string,
            eventCode01?: string,
            date03?: string,
            time04?: string,
        }>,
        chargeDetailL9?: Array<{
            monetaryAmount02?: number,
            specialChargeOrAllowanceCode01?: string,
        }>,
        referenceIdentificationN9?: {
            referenceIdentifierC040?: {
                referenceIdentificationQualifier05?: string,
                referenceIdentificationQualifier03?: string,
                referenceIdentification02?: string,
                referenceIdentificationQualifier01?: string,
                referenceIdentification04?: string,
                referenceIdentification06?: string,
            },
            timeCode06?: string,
            freeFormDescription03?: string,
            referenceIdentification02?: string,
            referenceIdentificationQualifier01?: string,
            time05?: string,
            date04?: string,
        },
        beginningSegmentForBookingOrPickUpDeliveryB1?: {
            reservationActionCode04?: string,
            date03?: string,
            shipmentIdentificationNumber02?: string,
            standardCarrierAlphaCode01?: string,
        },
        transactionSetTrailerSE?: {
            numberOfIncludedSegments01?: number,
            transactionSetControlNumber02?: string,
        },
        stopOffDetailsS5Loop?: Array<{
            remarksK1?: Array<{
                freeFormMessage02?: string,
                freeFormMessage01?: string,
            }>,
            referenceIdentificationN9?: Array<{
                referenceIdentifier07?: {
                    referenceIdentificationQualifier05?: string,
                    referenceIdentificationQualifier03?: string,
                    referenceIdentification02?: string,
                    referenceIdentificationQualifier01?: string,
                    referenceIdentification04?: string,
                    referenceIdentification06?: string,
                },
                timeCode06?: string,
                freeFormDescription03?: string,
                referenceIdentification02?: string,
                referenceIdentificationQualifier01?: string,
                time05?: string,
                date04?: string,
            }>,
            stopOffDetailsS5?: {
                accomplishCode11?: string,
                standardPointLocationCode10?: string,
                description09?: string,
                volumeUnitQualifier08?: string,
                volume07?: number,
                unitOrBasisForMeasurementCode06?: string,
                numberOfUnitsShipped05?: number,
                weightUnitCode04?: string,
                stopReasonCode02?: string,
                stopSequenceNumber01?: number,
                weight03?: number,
            },
            dateTimeG62?: Array<{
                timeCode05?: string,
                time04?: string,
                timeQualifier03?: string,
                date02?: string,
                dateQualifier01?: string,
            }>,
        }>,
        equipmentDetailsN7?: {
            carTypeCode24?: string,
            standardCarrierAlphaCode23?: string,
            equipmentType22?: string,
            width21?: number,
            height20?: number,
            typeOfServiceCode19?: string,
            equipmentNumberCheckDigit18?: number,
            weightUnitCode17?: string,
            tareQualifierCode16?: string,
            equipmentLength15?: number,
            position14?: string,
            temperatureControl13?: string,
            standardCarrierAlphaCode12?: string,
            equipmentDescriptionCode11?: string,
            ownershipCode10?: string,
            volumeUnitQualifier09?: string,
            volume08?: number,
            dunnage07?: number,
            weightAllowance06?: number,
            tareWeight05?: number,
            weightQualifier04?: string,
            weight03?: number,
            equipmentNumber02?: string,
            equipmentInitial01?: string,
        },
        dateTimeG62?: Array<{
            timeCode05?: string,
            time04?: string,
            timeQualifier03?: string,
            date02?: string,
            dateQualifier01?: string,
        }>,
        transactionSetHeaderST?: {
            transactionSetControlNumber02?: string,
            transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet990Schema_parse_.transactionSetIdentifierCode01,
        },
    };
}

export namespace ReturnType_typeofEdiTransactionSet990Schema_parse_ {

    export enum transactionSetIdentifierCode01 {
        _990 = '990',
    }


}
