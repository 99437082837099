import React from 'react'
import { TextField, Label } from './NumberField.styled'
import { OutlinedTextFieldProps  } from '@material-ui/core'
import { omit } from 'lodash'

const NumberField: React.FunctionComponent<CustomsTextFieldProps> = React.forwardRef (({negative = false,  ...props}, ref) => <React.Fragment>
    <Label $disabled={!!props.disabled}>
        {props.required && props.label ? `${props.label} *` : `${props.label ?? ''}`}
    </Label>
    <TextField
        {...omit(props, 'subLabel')}
        label={props.subLabel}
        variant='outlined'
        type='number'
        InputLabelProps={{ shrink: true, required: false }}
        InputProps={{ notched: false, ...props.InputProps }}
        inputRef={ref}
        onKeyDown={(event) => {
            if (!negative && event.key === '-') {
                event. preventDefault()
            }
        }}
    />
</React.Fragment>)

NumberField.displayName = 'NumberField'

type OmittedProps = 'variant' | 'select' | 'InputLabelProps' | 'multiline' | 'type'
export interface CustomsTextFieldProps extends Omit<OutlinedTextFieldProps, OmittedProps> {
    label?: string
    subLabel?: string
    negative?: boolean
    size?: 'small' | 'medium'
}

export default NumberField
