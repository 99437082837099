import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CarrierLogo from '@/app/ui-new/components/CarrierLogo/CarrierLogo'
import { Box, BoxProps } from '@material-ui/core'
import {
    StyledIconButton,
    StyledInnerBox, StyledLeftBtnBox,
    StyledListItem, StyledOuterBox, StyledRightBtnBox,
} from '@/app/ui-new/pages/landing/components/CarriersCarousel/ScrollableList.styled'

const ScrollableList: React.FunctionComponent<Props> = (props: Props) => {
    const [ isNeedLeftArrow, setNeedLeftArrow ] = React.useState<boolean>(false)
    const [ isNeedRightArrow, setNeedRightArrow ] = React.useState<boolean>(true)
    const innerBoxRef = React.useRef<HTMLDivElement>()
    const scrollableListRef = React.useRef<HTMLDivElement>()
    const [ carrierType, setCarrierType ] = React.useState<'freight' | 'parcel'>(props.carousel === '2' ? 'parcel' : 'freight')
    const [ hasAnimationStopped, setHasAnimationStopped ] = React.useState<boolean>(false)
    const [ isMouseDown, setIsMouseDown ] = useState(false)
    const [ scrollingStartTimeout, setScrollingStartTimeout ] = useState(2000)
    const intervalIdRef = useRef<number>()
    const carrierTypeRef = useRef(carrierType)
    carrierTypeRef.current = carrierType

    const stopAnimation = useCallback(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }
        setHasAnimationStopped(true)
    }, [ window.clearInterval, setHasAnimationStopped, intervalIdRef ])

    const mouseCoords = useRef({
        startX: 0,
        scrollLeft: 0,
    })

    const handleLeftArrowIconClickEvent: React.EventHandler<React.MouseEvent<HTMLElement>> = React.useCallback(
        () => {
            stopAnimation()
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: 'smooth',
                    left: -800,
                })
            }
        },
        [ innerBoxRef, stopAnimation ],
    )

    const handleRightArrowIconClickEvent: React.EventHandler<React.MouseEvent<HTMLElement>> = React.useCallback(
        () => {
            stopAnimation()
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: 'smooth',
                    left: 800,
                })
            }
        },
        [ innerBoxRef, stopAnimation ],
    )

    const startScrolling = React.useCallback(() => {
        if (!hasAnimationStopped) {
            const element = innerBoxRef.current

            if (element) {
                intervalIdRef.current = window.setInterval(() => {
                    scroll(element)
                }, 10)
            }
        }
    }, [ hasAnimationStopped, innerBoxRef, intervalIdRef, window.setInterval ])

    const scroll = React.useCallback((element: HTMLDivElement) => {
        element.scrollLeft = element.scrollLeft + 1
        const scrollableListWidth = scrollableListRef.current?.clientWidth ?? 0
        const hasScrollingEnded = (element.scrollLeft + scrollableListWidth - 10) >= element.scrollWidth
        if (hasScrollingEnded) {
            window.clearInterval(intervalIdRef.current)
            setNeedRightArrow(false)
            window.setTimeout(() => {
                element.scrollLeft = 0
                setNeedRightArrow(true)
                startScrolling()
            }, 2000)
        }
    }, [
        innerBoxRef,
        carrierType,
        intervalIdRef,
        scrollableListRef,
        setNeedRightArrow,
        window.clearInterval,
        window.setTimeout,
        startScrolling,
    ])

    const checkIfButtonsNeeded = React.useCallback(() => {
        const element = innerBoxRef.current
        if (element){
            const scrollableListWidth = scrollableListRef.current?.clientWidth ?? 0
            setNeedRightArrow((element.scrollLeft + scrollableListWidth - 10) < element.scrollWidth)
            setNeedLeftArrow(element.scrollLeft !== 0)
        }
    }, [ innerBoxRef, setNeedRightArrow, setNeedLeftArrow, scrollableListRef ])

    const handleScrollChangeEvent: React.EventHandler<React.UIEvent<HTMLDivElement>> = useCallback((e) => {
        const curScrollPos = e.currentTarget.scrollLeft
        const maxScrollPos = e.currentTarget.scrollWidth - e.currentTarget.clientWidth
        if (curScrollPos === 0) {
            setNeedLeftArrow(false)
        } else if (Math.ceil(curScrollPos) >= maxScrollPos) {
            setNeedRightArrow(false)
        } else {
            setNeedLeftArrow(true)
            setNeedRightArrow(true)
        }
    }, [ setNeedLeftArrow, setNeedRightArrow ])

    const renderItemsList = useCallback ((carriersType: 'freight' | 'parcel'): React.ReactNode => {
        if (carriersType === 'freight') {
            return props.freightCarriers.map((carrier, index) => (
                <StyledListItem key={index}>
                    <CarrierLogo variant={carrier} size='wide-large'/>
                </StyledListItem>
            ))
        }

        return props.parcelCarriers.map((carrier, index) => (
            <StyledListItem key={index}>
                <CarrierLogo variant={carrier} size='wide-large'/>
            </StyledListItem>
        ))
    }, [
        props.freightCarriers,
        props.parcelCarriers,
    ])

    const handleDragStart = useCallback((e) => {
        if (!innerBoxRef.current) {
            return
        }
        stopAnimation()
        if (!innerBoxRef.current) {return}
        const slider = innerBoxRef.current
        const startX = e.pageX - slider.offsetLeft
        const scrollLeft = slider.scrollLeft
        mouseCoords.current = { startX, scrollLeft }
        setIsMouseDown(true)
    }, [ innerBoxRef, setIsMouseDown, stopAnimation, mouseCoords ])

    const handleDragEnd = useCallback(() => {
        if (!innerBoxRef.current) {
            return
        }
        setIsMouseDown(false)
        checkIfButtonsNeeded()
    }, [ innerBoxRef, setIsMouseDown, stopAnimation, checkIfButtonsNeeded ])

    const handleDrag = useCallback ((e) => {
        if (!isMouseDown || !innerBoxRef.current) {
            return
        }
        e.preventDefault()
        const slider = innerBoxRef.current
        const x = e.pageX - slider.offsetLeft
        const walkX = (x - mouseCoords.current.startX) * 1.5
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
    }, [ innerBoxRef, isMouseDown ])

    React.useEffect(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }

        if (innerBoxRef.current) {
            innerBoxRef.current.scrollLeft = 0
        }

        checkIfButtonsNeeded()
        const isListOverflowed = !!innerBoxRef.current && (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth)
        setScrollingStartTimeout(2000)
        window.setTimeout(() => {
            setNeedLeftArrow(!hasAnimationStopped && isListOverflowed)
            startScrolling()
        }, scrollingStartTimeout)

        return (() => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        })
    }, [ carrierType, intervalIdRef, innerBoxRef ])

    return (
        <Box width={'100%'}
            // @ts-ignore
            ref={scrollableListRef}
        >
            <StyledOuterBox>
                {isNeedLeftArrow && (
                    <StyledLeftBtnBox $bgColor={props.bgColor}>
                        <StyledIconButton size="small" onClick={handleLeftArrowIconClickEvent}>
                            {<FontAwesomeIcon  icon={[ 'far', 'arrow-left' ]}/>}
                        </StyledIconButton>
                    </StyledLeftBtnBox>)
                }
                <StyledInnerBox
                    onScroll={hasAnimationStopped ? handleScrollChangeEvent : undefined}
                    onMouseDown={handleDragStart}
                    onMouseUp={handleDragEnd}
                    onMouseMove={handleDrag}
                    isOverflowed = {!!innerBoxRef.current && (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth)}
                    // @ts-ignore
                    ref={innerBoxRef}
                >
                    {renderItemsList(carrierType)}
                </StyledInnerBox>
                {isNeedRightArrow && (
                    <StyledRightBtnBox $bgColor={props.bgColor}>
                        <StyledIconButton size="small" onClick={handleRightArrowIconClickEvent}>
                            {<FontAwesomeIcon  icon={[ 'far', 'arrow-right' ]}/>}
                        </StyledIconButton>
                    </StyledRightBtnBox>
                )}
            </StyledOuterBox>
        </Box>
    )
}

export interface InnerBoxProps extends BoxProps {
    isOverflowed?: boolean
}

export interface Props {
    freightCarriers: string[]
    parcelCarriers: string[]
    carousel: string
    bgColor: string
}

export default ScrollableList
