import styled from 'styled-components'

import {
    Typography as MuiTypography,
    Grid as MuiGrid,
    TextField as MuiTextField,
    OutlinedTextFieldProps,
    StandardTextFieldProps,
    Box as MuiBox,
    Button as MuiButton,
} from '@material-ui/core'
import { IconButton as IconButtonStyled } from '@/app/ui-new/components/IconButton/IconButton'

import { spacing as MuiSpacing } from '@material-ui/system'

const getInputHeight = (size: string | undefined) => {
    switch (size) {
        case 'medium':
            return '2.188em'
        case 'small':
            return '2.188em'
        default:
            return '3.188em'
    }
}

export const Button = styled(MuiButton)`
    margin-left: ${(props): number => props.theme.spacing(5)}px;
    color: red;
`

export const Typography = styled(MuiTypography)(MuiSpacing)

export const SecondaryTypography = styled(MuiTypography)`
    color: ${(props): string => props.theme.palette.neutral500.main};
    margin-top: ${(props): number => props.theme.spacing(0.5)}px;
    font-size: 0.895rem;
`

export const GroupTypography = styled(MuiTypography)`
    color: ${(props): string => props.theme.palette.neutral600.main};
`

export const BackTypography = styled(MuiTypography)`
    color: ${(props): string => props.theme.palette.neutral800.main};
    font-weight: 500;
`

export const IconContainer = styled(MuiBox)`
    display: flex;
    justify-content: center;
    color: ${(props): string => props.theme.palette.neutral400.main};
    width: 45px;
    margin-right: ${(props): number => props.theme.spacing(0.5)}px;
    svg {
        font-size: 1.4rem;
    }
`

export const CheckContainer = styled(IconContainer)`
    width: auto;
`

export const IconContainerTextField = styled(IconContainer)<{size: string}>`
    width: auto;
    svg {
        font-size:  ${(props): string => props.size === 'small' ? '1.5rem' : '1.6rem' };
    }
`

export const IconContainerAddressMenu = styled.div<{size: string}>`
    display: flex;
    justify-content: flex-end;
    width: auto;
    svg {
        font-size:  ${(props): string => props.size === 'small' ? '1.5rem' : '1.6rem' };
    }
`

export const AutoCompleteContainer = styled.div`
    margin: ${(props): number => props.theme.spacing(2)}px 0 ${(props): number => props.theme.spacing(3.5)}px 0;
`

export const IconButton = styled(IconButtonStyled)`
    border-color: transparent;

    &:hover{
        border-color: transparent !important;
    }

    &:focus{
        border-color: transparent !important;
        outline-width: 0 !important;
    }

    & svg path{
        fill: unset;
    }
`

export const IconContainerArrow = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.neutral400.main};
    margin-right: ${(props): number => props.theme.spacing(4)}px;
    font-size: 1rem;
    svg {
        font-size: 1rem;
    }
`

export const IconContainerLeftArrow = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.neutral400.main};
    margin-right: ${(props): number => props.theme.spacing(4)}px;
    font-size: 1.2rem;
    svg {
        font-size: 1.2rem;
    }
`
export const UlCustom = styled.ul<{$focus: boolean}>`
     padding: 0;
     margin: ${(props): number => props.theme.spacing(2)}px auto;
    & [class*="MuiAutocomplete-option"][aria-selected='true'] {
        background-color: ${(props): number => props.theme.palette.white.main};

        p, span, svg {
        color: ${(props): number => props.theme.palette.accentPrimary900.main};
        }
    }

    & [class*="MuiAutocomplete-option"][data-focus='true'] {
        background-color: ${(props): string => props.$focus ? props.theme.palette.accentPrimary50.main : props.theme.palette.white.main};
    }

    li {
        padding: 0;
    }
`

export const TextField = styled(MuiTextField)<OutlinedTextFieldProps | StandardTextFieldProps>`
  /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

& [class^="MuiFormHelperText-root"] {
    color: ${(props): number => props.theme.palette.neutral500.main};
    margin-left: 0;
    margin-top: ${(props): number => props.theme.spacing(2)}px;
    &.Mui-disabled {
        color: ${(props): number => props.theme.palette.neutral400.main};
    }
    &.Mui-error {
        color: ${(props): number => props.theme.palette.error900.main};
    }
}

& [class*="MuiOutlinedInput-root"] {
    padding-right: ${(props): number => props.theme.spacing(6)}px !important;
}

& [class^="MuiInputBase-root"] {
     font-size: 16px;
    > input, textarea {
        height: ${(props): string => getInputHeight(props.size)};
        color: ${(props): number => props.theme.palette.black.main};
        caret-color: ${(props): number => props.theme.palette.accentPrimary700.main};
        :not(.Mui-disabled)::placeholder {
            color: ${(props): number => props.theme.palette.neutral500.main};
            opacity: 1;
        }
        :not(.Mui-disabled):focus::placeholder {
            color: transparent;
        }
    }

     > textarea {
        padding-top: ${(props): number => props.theme.spacing(0.5)}px;
     }

     [class*="MuiInputAdornment-positionStart"]  * {
        display: flex;
        justify-content: center;
        color: ${(props): number => props.theme.palette.neutral500.main};
        width: ${(props): string => props.size === 'small' ? '45px' : '43px'};
        margin-left: ${(props): number => props.size === 'small' ? props.theme.spacing(1.6) : props.theme.spacing(1.3)}px;
     }

   &:hover {
        fieldset {
            border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
        }
        :not(.Mui-disabled)::before {
            border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
        }
    }
   &.Mui-focused {
        fieldset {
            border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
        }
         ::after {
            border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
        }
    }
    &.Mui-error {
        fieldset {
            border-color: ${(props): number => props.theme.palette.error900.main};
        }
        input, textarea {
            caret-color: ${(props): number => props.theme.palette.error900.main};
        }
        ::after {
            border-bottom-color: ${(props): number => props.theme.palette.error900.main};
        }
    }
    &.Mui-disabled {
        background-color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral.main : 'inherit'};
        input::placeholder, textarea::placeholder {
            color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
            opacity: 1;
        }
        input, textarea {
            color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
        }
        [class^="MuiInputAdornment-root"] * {
            color: ${(props): number => props.theme.palette.neutral300.main};
        }
        fieldset {
            border-color: ${(props): number => props.theme.palette.neutral.main};
        }
    }
}
`

export const Grid = styled(MuiGrid)(MuiSpacing)

export const CustomListTypography = styled(styled(Typography)(MuiSpacing))`
      color: ${(props): number => props.theme.palette.neutral500.main};
`

export const ListBoxGrid = styled(styled(MuiGrid)(MuiSpacing))`
    cursor: pointer;

    &:hover, &:focus &:focus-visible, &.visibleFocus {
        p, svg {
            color: ${(props): number => props.theme.palette.accentPrimary900.main};
        }
     }
    min-width: 150px;
`
export const MenuItemGrid = styled(styled(MuiGrid)(MuiSpacing))`
    color: ${(props): number => props.theme.palette.neutral800.main};
    padding: ${(props): number => props.theme.spacing(2)}px ${(props): number => props.theme.spacing(4)}px;
`
