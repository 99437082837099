/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import type en from './HandlingUnitForm.i18n.en'
import type { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    'Handling Unit': 'Unités de manutention',
    Measurements: 'Mesures',
    Quantity:'Quantité',
    Weight:'Poids',
   'Weight Unit':'Weight Unit',
    Height:'Hauteur',
    Length:'Longueur',
    Width:'Largeur',
    'Dimention Unit':'Dimention Unit',
    'in/lb': 'po/lb',
    'cm/kg': 'cm/kg',
    'Total weight': 'Totale Poids',
    Centimeter: 'Centimètre',
    Kilogram: 'Kilogramme',
    Inch: 'Pouce',
    Pound: 'Livre',
    Parcels:'Colis',
    'Dimension unit selector': "Sélecteur d'unité de dimension",
    Inches: 'Pouces',
    Pounds: 'Livres',
    Centimeters: 'Centimètres',
    Kilograms: 'Kilogrammes',
    'Height needs to be smaller than': 'La hauteur doit être inférieure à',
    'Length needs to be smaller than' : 'La longueur doit être inférieure à',
    'Average weight': 'Poids Moyen'
}))()
