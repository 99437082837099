import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { newDesignThemes } from '@/app/ui/theme'
import { GlobalStyle } from '@/app/ui/layouts/components/NewDesignGlobalStyle.styled'
import { useContent } from './hooks/useContent'
import { TealPage } from '@/app/ui-new/pages/landing/theme/teal'
import { PurplePage } from '@/app/ui-new/pages/landing/theme/purple'
import { setLanguage as reduxSetLanguage } from '@/app/ui/redux/actions'
import { useDispatch } from 'react-redux'
import { Language } from '@lazr/enums'
import RequestDemo from '@/app/ui/pages/components/RequestDemo/RequestDemo'
import RegisterOrganization from '@/app/ui-new/pages/website/components/Register Organization/RegisterOrganization'

const Landing: React.FunctionComponent<Props> = () => {
    const content = useContent()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(reduxSetLanguage(content.language ?? Language.EN))
    }, [])

    return <React.Fragment>
        <CssBaseline/>
        <GlobalStyle/>
        <MuiThemeProvider theme={newDesignThemes[0]}>
            <ThemeProvider theme={newDesignThemes[0]}>
                {
                    window.location.pathname === '/commerce-chamber/landing' ?
                        <TealPage
                            title={content.title}
                            subTitle={content.subtitle}
                            button={content.button}
                            testimonials={content.testimonials}
                            language={content.language}
                            isCommerceChamber={true}
                        />: <>
                            {content.theme === 'Teal' ? <TealPage
                                title={content.title}
                                subTitle={content.subtitle}
                                button={content.button}
                                testimonials={content.testimonials}
                                language={content.language}
                            /> : ''}
                            {content.theme === 'Purple' ? <PurplePage
                                button={content.button}
                                language={content.language}
                                subtitle={content.subtitle}
                                testimonials={content.testimonials}
                                title={content.title}
                            /> : ''}
                        </>
                }
                <RequestDemo/>
                <RegisterOrganization/>
            </ThemeProvider>
        </MuiThemeProvider>
    </React.Fragment>
}

export interface Props {}

export default Landing
