/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './CarouselMobile.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`The ultimate logistics solution`]: `La solution logistique ultime`,
    [`Streamline your entire shipping process with one elegant platform. You’ll get help from Lazr at every step.`]: `Simplifiez l'ensemble de votre processus d'expédition grâce à une plateforme élégante. Lazr vous assistera à chaque étape.`,
    [`Get Lazr for free`]: `Obtenez Lazr gratuitement`,
    [`See your best shipping options`]: `Voir les meilleures options d'expédition`,
    [`Get instant prices and discover new shipping services & partners to save an average of 21% in costs*. Lazr has volume-based contracts with over 50 trusted carriers in less than truckload (LTL), parcel shipping and courier services. Users can also add their own contracts to instantly see and compare their best options.`]: `Obtenez des prix instantanés et découvrez de nouveaux services d'expédition et partenaires pour économiser en moyenne 21 % sur les coûts*. Lazr a négocié des contrats basés sur son volume consolidé avec plus de 50 transporteurs de confiance spécialisés dans les envois sur palettes (LTL), de l'expédition de colis et des services de courrier. Les utilisateurs peuvent également ajouter leurs propres contrats pour analyser instantanément leurs meilleures options.`,
    [`Average savings for Lazr users`]: `Économies moyennes pour les utilisateurs de Lazr`,
    [`Breeze through pickups`]: `Des cueillettes rapides`,
    [`Save time with autofilled BOL forms, parcel labels and ready-to-go shipping documents. We’ll automatically generate the necessary documents for each unique shipment, facilitate document sharing, and securely archive any customs files in one convenient place.`]: `Gagnez du temps grâce aux formulaires BOL remplis automatiquement, aux étiquettes de colis et aux documents d'expédition prêts à l'utilisation. Nous générons automatiquement les documents nécessaires pour chaque envoi, facilitons le partage des documents et archivons en toute sécurité tous les fichiers douaniers en un seul endroit pratique.`,
    [`Real-time tracking`]: `Suivi en temps réel`,
    [`Easily track your shipments across multiple services and carriers simultaneously. Lazr’s built-in dashboard gives you enhanced visibility with a unified tracking view so you can find your shipments at a glance.`]: `Suivez facilement vos envois à travers plusieurs services et transporteurs simultanément. Le tableau de bord intégré de Lazr vous offre une meilleure visibilité grâce à une vue de suivi unifiée qui vous permet de retrouver vos envois d'un seul coup d'œil.`,
    [`Seamless integrations & communications`]: `Intégrations et communications transparentes`,
    [`Our enterprise-grade platform is equipped with a full range of API & EDI capabilities so you can effortlessly optimize your workflow and stay connected to all your shipping partners.`]: `Notre plateforme d'entreprise est équipée d'une gamme complète de capacités API et EDI afin que vous puissiez optimiser sans effort votre flux de travail et rester en contact avec tous vos partenaires d'expédition.`,
    [`Move to your next shipment faster`]: `Accédez à votre prochain envoi  plus rapidement`,
    [`Instantly cross off your post-delivery checklist. After delivery is confirmed, Lazr automates the necessary billing steps, including auditing, reporting, payment and consolidated invoicing.`]: `Vous pouvez instantanément compléter votre liste de vérification post-livraison. Une fois la livraison confirmée, Lazr automatise les étapes de facturation nécessaires, y compris l'audit, le reporting, le paiement et la facturation consolidée.`,
    [`Keep improving with data`]: `Amélioration continue grâce aux données`,
    [`Make smarter decisions with every new shipment. The Lazr dashboard includes KPI analytics and deep operations data so you can make faster, stronger decisions, reduce costs, and grow your business.`]: `Prenez des décisions plus judicieuses à chaque nouvelle expédition. Le tableau de bord de Lazr comprend des analyses KPI et des données d'exploitation approfondies afin que vous puissiez prendre des décisions plus rapides et plus solides, réduire les coûts et développer votre entreprise.`,
    ['']: '',
}))()
