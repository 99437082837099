import React from 'react'
import { LayoutProps } from '@/app/ui/routes'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, AlertContent } from '@/app/ui-new/components/Alert/Alert'
import { getNotistack as reduxGetNotistack } from '@/app/ui/redux/selectors/NotistackSelectors'
import { setNotistack as reduxSetNotistack } from '@/app/ui/redux/actions/NotistackActions'
import { SnackbarKey } from 'notistack'

const NotistackHandler: React.FunctionComponent<Props> = ({ children }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const snackbar = useSelector(reduxGetNotistack)
    const dispatch = useDispatch()

    const openNotistack = (content: string | JSX.Element) => {
        const notistackIdentifier = new Date().getTime().toString()
        const wrapper = <div
            className='notistackIdentifier'
            data-notistack-identifier={notistackIdentifier}
        >{content}</div>

        enqueueSnackbar(wrapper, { key: notistackIdentifier })
    }

    const closeNotistack = (event: any) => {
        const target = event.target.closest('.notistackIdentifier')
        const key = target.dataset.notistackIdentifier as SnackbarKey

        closeSnackbar(key)
    }

    if (snackbar) {
        const message = <Alert severity={snackbar.severity} onClose={snackbar.isClosable ? closeNotistack : undefined}>
            <AlertContent message={snackbar?.message}/>
        </Alert>
        openNotistack(message)
        dispatch(reduxSetNotistack(null))
    }

    return (
        <div> {children} </div>
    )
}

export interface Props extends LayoutProps {}

export default NotistackHandler
