import React, { useEffect } from 'react'
import { Grid, ListItem, MenuItem } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import CarrierLogo from '../../CarrierLogo/CarrierLogo'
import { carrierCodeMapping } from '@/app/ui-new/pages/marketplace/OriginAndDestination/constants' ///TODO: We need to create a a const that shareble to our system
import Select from '../../Select/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from './SelectForm.styled'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleSubmit: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const SelectForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleSubmit,
}) => {
    const { labelName, required } = field

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(field.name, field.values)
        debouncedHandleSubmit?.()
    }, [field.values])
    return (
        <>
            <Controller
                name={field.name}
                control={control}
                defaultValue={field.defaultValue || 0}
                render={({ field: { onChange, value } }) => (
                    <Select
                        name={field.name}
                        value={value ? value : ''}
                        label={labelName}
                        required={required}
                        onChange={(e, newValue: any) => {
                            onChange(newValue?.props?.value)
                            setValue(field.name, newValue?.props?.value, { shouldValidate: false })
                            // Trigger validation on change
                            trigger(field.name).then(() => {
                                const isFieldValid = !errors[field.name]
                                if (isFieldValid && onChange) {
                                    onChange(newValue?.props?.value)
                                    setValue(field.name, newValue?.props?.value, {
                                        shouldValidate: true,
                                    })
                                }
                                debouncedHandleSubmit?.()
                            })
                        }}
                        size={field.select?.size ? field.select?.size : 'small'}
                        error={errors[field.name] ? true : false}
                    >
                       
                        {field.select?.data.length === 0 ? (
                            <FontAwesomeIcon icon='spinner' pulse />
                        ) : (
                            field.select?.data.map((data: any, index: number) => {
                                let selectlist: any
                                switch (field.select?.name) {
                                    case 'currency': {
                                        selectlist = (
                                            <MenuItem value={data.id} key={`carrier-${field.name}-${index}`}>
                                                <Typography variant='body1'>{data.id}</Typography>
                                            </MenuItem>
                                        )
                                        return selectlist
                                    }

                                    case 'carrier': {
                                        selectlist = (
                                            <MenuItem value={data.id} key={`carrier-${field.name}-${index}`}>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <CarrierLogo variant={carrierCodeMapping[data.name]} size='small' mr={3} />
                                                    </Grid>
                                                    <Grid item align-content='center'>
                                                        <Typography variant='body1'>{data.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        )
                                        return selectlist
                                    }
                                    case 'iconList': {
                                        selectlist = (
                                            <MenuItem value={data.id} key={`carrier-${field.name}-${index}`}>
                                                <Grid container spacing={2}>
                                                    <Grid item>
                                                        <FontAwesomeIcon icon={['far', data.icon]} />
                                                        {/* {data.name === 'PARCEL' && <FontAwesomeIcon icon={['far', 'box']} />}
                                                    {data.name === 'ENVELOPE' && <FontAwesomeIcon icon={['far', 'envelope']} />}
                                                    {data.name === 'PAK' && <FontAwesomeIcon icon={['far', 'box']} />}
                                                    {data.name === 'OTHER' && <FontAwesomeIcon icon={['far', 'ellipsis-h']} />}
                                                    {data.name === 'FTL' && <FontAwesomeIcon icon={['far', 'truck']} />}
                                                    {data.name === 'LTL' && <FontAwesomeIcon icon={['far', 'truck-container']} />}
                                                    */}
                                                    </Grid>
                                                    <Grid item align-content='center'>
                                                        <Typography variant='body1'>{data.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        )
                                        return selectlist
                                    }

                                    case 'serviceLevel': {
                                        selectlist = (
                                            <MenuItem value={data.id} key={`service-level-${field.name}-${index}`}>
                                                <Typography variant='body1'>{data.name}</Typography>
                                            </MenuItem>
                                        )
                                        return selectlist
                                    }

                                    default: {
                                        selectlist = (
                                            <MenuItem value={data.name} key={`select-${field.name}-${index}`}>
                                                <Typography variant='body1'>{data.name}</Typography>
                                            </MenuItem>
                                        )

                                        return selectlist
                                    }
                                }
                            })
                        )}
                    </Select>
                )}
            />
            {errors[field.name] && (
                <Typography variant='caption' color='error'>
                    {errors[field.name]?.message}
                </Typography>
            )}
        </>
    )
}

export default SelectForm
