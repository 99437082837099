import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { loadComponent } from '../helpers'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import { TextFieldAdornment } from '../../TextFieldAdornment/TextFieldAdornment.styled'
import { TextFieldIconSearch } from '../../TextFieldIcons/TextFieldIcons'
import TextField from '../../TextField/TextField'
import TimePicker from '../../TimePicker/TimePicker'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface DialogOpened {
    timeOpeningDialogOpened?: boolean
    timeClosingDialogOpened?: boolean
}

export interface TimeInputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleSubmit: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const TimeInputForm: React.FC<TimeInputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleSubmit,
}) => {
    const { type, tooltip, labelName, placeholder, spaceForError, subLabel, disabled, width, required } = field
    const [dialogOpened, setDialogOpened] = useState<DialogOpened>({ timeOpeningDialogOpened: false, timeClosingDialogOpened: false })
    const [focusState, setFocusState] = useState(false)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(field.name, field.values)
        
        debouncedHandleSubmit?.()
    }, [field.values])

    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field }) => {
                const fieldDate = field
                const errorField = error ? error : errors?.[field.name]
                const helperTextField = helperText ? helperText : errors?.[field.name]?.message ?? spaceForError ?? ' '
                const dynamicSubLabel = subLabel ? { subLabel: subLabel } : {}
                return (
                    <>
                        <TextField
                            {...field}
                            {...dynamicSubLabel}
                            disabled={disabled}
                            variant={type === 'timeFieldOutlined' ? 'outlined' : 'standard'}
                            required={required ? true : false}
                            type={type === 'hiddenText' ? 'hidden' : 'text'}
                            label={labelName}
                            fullWidth
                            tooltip={tooltip}
                            placeholder={placeholder}
                            error={!!errorField}
                            helperText={helperTextField}
                            mb={0}
                            size='small'
                            value={fieldDate.value ?? ''}
                            onClick={() =>
                                setDialogOpened((previousDialogOpened) => ({
                                    ...previousDialogOpened,
                                    timeOpeningDialogOpened: true,
                                }))
                            }
                            onFocus={(event) => {
                                if (!focusState) {
                                    setFocusState(true)

                                    setDialogOpened((previousDialogOpened) => ({
                                        ...previousDialogOpened,
                                        timeClosingDialogOpened: true,
                                    }))

                                    event.preventDefault()
                                } else {
                                    setFocusState(false)
                                }
                            }}
                            onKeyUp={(e: any) => {
                                const inputValue = e.target.value
                                if (field.onChange) {
                                    field.onChange(inputValue)
                                    setValue(field.name, inputValue, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleSubmit()
                                }

                                // Trigger validation on change
                                trigger(field.name).then(() => {
                                    const isFieldValid = !errorField
                                    if (isFieldValid && field.onChange) {
                                        field.onChange(inputValue)
                                        setValue(field.name, inputValue, {
                                            shouldValidate: false,
                                        })
                                        debouncedHandleSubmit()
                                    }
                                })
                            }}
                        />
                        <TimePicker
                            onTimeChange={(newTime: string) => {
                                const inputValue = newTime
                                if (field.onChange) {
                                    field.onChange(inputValue)
                                    setValue(field.name, inputValue, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleSubmit()
                                }

                                // Trigger validation on change
                                trigger(field.name).then(() => {
                                    const isFieldValid = !errorField
                                    if (isFieldValid && field.onChange) {
                                        field.onChange(inputValue)
                                        setValue(field.name, inputValue, {
                                            shouldValidate: false,
                                        })
                                        debouncedHandleSubmit()
                                    }
                                })
                            }}
                            initTime={field.value ?? '8:00 AM'}
                            dialogOpen={!!dialogOpened?.timeOpeningDialogOpened}
                            onDialogClose={() =>
                                setDialogOpened((previousDialogOpened) => ({
                                    ...previousDialogOpened,
                                    timeOpeningDialogOpened: false,
                                }))
                            }
                        />
                    </>
                )
            }}
        />
    )
}

export default TimeInputForm
