import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DISABLED_OPACITY } from '@/app/ui/lib/constants'
import { Box as MuiBox } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const StyledIcon = styled(FontAwesomeIcon)<{ $disabled?: boolean }>`
    font-size: ${(props): number => props.theme.spacing(7)}px;
    color: ${(props): string => props.theme.palette.neutral600.main};
    opacity: ${(props): number => props.$disabled ? DISABLED_OPACITY : 1};
    margin: ${(props): number => props.theme.spacing(0)};
`

