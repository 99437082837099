import React from 'react'
import { AlertProps } from '@material-ui/lab'
import { Alert as AlertStyled, Typography } from './Alert.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './Alert.i18n'

export const Alert = (props: AlertProps): JSX.Element => {
    const { t } = useI18n(i18n)

    return < AlertStyled
        elevation = {6}
        variant = 'filled'
        className = {`alert-${props.severity ?? ''}`}
        closeText = {t('Close')}
        {...props}
    />
}

export const AlertContent = (props: AlertContentProps): JSX.Element => {
    const { t } = useI18n(i18n)

    return <>
        {props.title && <Typography variant='h6' className='alert-title'>
            {props.title ?? t('An Alert title...')}
        </Typography>}
        <Typography variant='body1' className='alert-message'>
            {props.message ?? t('An Alert message...')}
        </Typography>
    </>
}

interface AlertContentProps{
    title?: string
    message: React.ReactNode
}
