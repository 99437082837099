import React from 'react'
import { StyledMuiButton } from './SecondaryButton.styled'
import { ButtonProps } from '@material-ui/core'

const SecondaryButton: React.FunctionComponent<ButtonProps> = (props) =>
    <StyledMuiButton
        variant="outlined"
        {...props}
    >{props.children}
    </StyledMuiButton>

export default SecondaryButton
