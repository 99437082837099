import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PageName } from '@lazr/openapi-client'
import { PageNavigationApiService } from '@/app/service/ApiService'
import { Wrapper, IconContainer, Message, PrimaryButton, Typography, Welcome } from './Page404.styled'
import { homeRoute } from '@/app/ui/routes'
import { logger } from '@/app/logger'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './Page404.i18n'

const Page404: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    useEffect(() => {
        void (async (): Promise<void> => {
            try {
                await PageNavigationApiService.record({ pageName: PageName.PAGE_NOT_FOUND })
            } catch (error: any) {
                logger.error('Unable to record page navigation')
                logger.error(error.message)
                logger.error(error.stack)
            }
        })()
    }, [])

    return <Wrapper>
        <IconContainer>
            <FontAwesomeIcon icon={[ 'far', 'times-circle' ]}/>
        </IconContainer>
        <Welcome>
            {t('Page Not Found')}
        </Welcome>
        <Message>
            <Typography variant="body1" align="center">
                {t('The page might have been removed.')}
            </Typography>
            <PrimaryButton href={homeRoute.path} mt={8}>
                {t('Go Back to the Main Page')}
            </PrimaryButton>
        </Message>
    </Wrapper>
}

export interface Props {}

export default Page404
