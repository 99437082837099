import { Store } from '@/app/ui/definitions/Store'
import { ValidatedAddress } from '../../definitions/ValidatedAddress'

import {
    AddressConfirmationInformation,
    AddressInformation,
    AddressInformationErrors,
    Collect,
    CompleteCustomsOrderInformation,
    CompleteCutomsOrderStepErrors,
    CompleteOrderHazmatStepErrors,
    CompleteOrderInformation,
    CompleteOrderStepErrors,
    InvalidationDialog,
    MarketPlace,
    MarketPlaceErrors,
    MarketPlaceStep,
    ProgressSummaryInformation,
    TransportConfirmationInformation,
    TransportInformation,
} from '../../definitions/MarketPlace'

import {
    Address,
    CoverageCommodityType,
    Currency,
    PackageType,
    Search,
    SearchRfq,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqCustomsDetail,
    SearchRfqQuote,
    ResultDocument,
    Lane,
    Country,
} from '@/app/model'

import {
    AccessorialLocationType,
    AccessorialType,
    Currency as CurrencyCode,
    DocumentType,
    PaymentTypeOption,
    UuidV4,
} from '@lazr/openapi-client'

export const getMarketPlaceCommercialInvoice = (store: Store): ResultDocument | null => {
    const order = getOrder(store)
    if (order?.documents) {
        const foundCommercialInvoiceDocument = order.documents.find((d) =>
            (d.type === DocumentType.COMMERCIAL_INVOICE && !d.isGenerated))
        if (foundCommercialInvoiceDocument) {
            return foundCommercialInvoiceDocument
        }
    }

    return null
}

export const getMarketplaceDocuments = (store: Store): ResultDocument[] | null => {
    const order = getOrder(store)
    const customsDocuments: ResultDocument[] = []
    if (order.documents && order.documents.length > 0) {
        order.documents.forEach((document) => {
            if ([
                DocumentType.AUTHORIZATION_FORM,
                DocumentType.CERTIFICATE_OF_ORIGIN,
                DocumentType.EXPORT_ACCOMPANYING_DOCUMENT,
                DocumentType.EXPORT_LICENSE,
                DocumentType.IMPORT_PERMIT,
                DocumentType.ONE_TIME_NAFTA,
                DocumentType.OTHER_CUSTOMS,
                DocumentType.POWER_OF_ATTORNEY,
                DocumentType.PACKING_LIST,
                DocumentType.SED_DOCUMENT,
                DocumentType.SHIPPERS_LETTER_OF_INSTRUCTION,
                DocumentType.DECLARATION,
            ].includes(document.type)) {
                customsDocuments.push(document)
            }
        })

        return customsDocuments
    }

    return null
}

export const getMarketPlace = (store: Store): MarketPlace => store.newMarketPlace

export const getMarketPlaceErrors = (store: Store): MarketPlaceErrors => getMarketPlace(store).marketPlaceErrors

export const getAddressInformation = (store: Store, locationType: AccessorialLocationType): AddressInformation => {
    const marketPlace = getMarketPlace(store)
    const rfq = marketPlace.order.rfq

    switch (locationType) {
        case AccessorialLocationType.DESTINATION:
            return {
                state: rfq?.destinationState,
                country: rfq?.destinationCountry,
                city: rfq?.destinationCity,
                streetAddress: rfq?.destinationStreetAddress,
                streetAddress2: rfq?.destinationStreetAddress2,
                streetAddress3: rfq?.destinationStreetAddress3,
                postalCode: rfq?.destinationPostalCode,
                addressType: rfq?.destinationAddressType,
                accessorials: rfq?.accessorials?.filter(
                    (accessorial) => accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? [],
                pickupDate: rfq?.originPickupDate,
                fullAddress: marketPlace.destinationFullAddress,
                originAddressBookAddress: marketPlace.originAddressBookAddress,
                destinationAddressBookAddress: marketPlace.destinationAddressBookAddress,
            }

        case AccessorialLocationType.ORIGIN:
        default:
            return {
                state: rfq?.originState,
                country: rfq?.originCountry,
                city: rfq?.originCity,
                streetAddress: rfq?.originStreetAddress,
                streetAddress2: rfq?.originStreetAddress2,
                streetAddress3: rfq?.originStreetAddress3,
                postalCode: rfq?.originPostalCode,
                addressType: rfq?.originAddressType,
                accessorials: rfq?.accessorials?.filter(
                    (rfqAccessorial) =>
                        rfqAccessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
                        rfqAccessorial.accessorial.type === AccessorialType.SERVICE,
                ) ?? [],
                pickupDate: rfq?.originPickupDate,
                fullAddress: marketPlace.originFullAddress,
                originAddressBookAddress: marketPlace.originAddressBookAddress,
                destinationAddressBookAddress: marketPlace.destinationAddressBookAddress,
            }
    }
}

export const getOriginSelectedAccessorials = (store: Store): SearchRfqAccessorial[] =>
    getMarketPlace(store).order?.rfq?.accessorials?.filter((rfqAccessorial) =>
        rfqAccessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
        rfqAccessorial.accessorial.type === AccessorialType.SERVICE) ?? []

export const getAccessorialList = (store: Store, locationType: AccessorialLocationType): SearchRfqAccessorial[] =>
    locationType === AccessorialLocationType.DESTINATION
        ? getMarketPlace(store).destinationAccessorialList
        : getMarketPlace(store).originAccessorialList

export const getCountryList = (store: Store): Country[] => getMarketPlace(store).countryList

export const getHandlingUnitAccessorialList = (store: Store): SearchRfqAccessorial[] =>
    getMarketPlace(store).handlingUnitAccessorialList

export const getServiceAccessorialList = (store: Store): SearchRfqAccessorial[] =>
    getMarketPlace(store).serviceAccessorialList

export const getPackageTypeList = (store: Store): PackageType[] =>
    getMarketPlace(store).packageTypeList

export const getCoverageCommodityTypeList = (store: Store): CoverageCommodityType[] =>
    getMarketPlace(store).coverageCommodityTypeList

export const getAddressInformationErrors = (store: Store, locationType: AccessorialLocationType): AddressInformationErrors => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    switch (locationType) {
        case AccessorialLocationType.ORIGIN:
            return {
                state: marketPlaceErrors.rfqErrors.originState,
                country: marketPlaceErrors.rfqErrors.originCountry,
                city: marketPlaceErrors.rfqErrors.originCity,
                streetAddress: marketPlaceErrors.rfqErrors.originStreetAddress,
                postalCode: marketPlaceErrors.rfqErrors.originPostalCode,
                pickupDate: marketPlaceErrors.rfqErrors.originPickupDate,
            }

        case AccessorialLocationType.DESTINATION:
            return {
                state: marketPlaceErrors.rfqErrors.destinationState,
                country: marketPlaceErrors.rfqErrors.destinationCountry,
                city: marketPlaceErrors.rfqErrors.destinationCity,
                streetAddress: marketPlaceErrors.rfqErrors.destinationStreetAddress,
                postalCode: marketPlaceErrors.rfqErrors.destinationPostalCode,
                pickupDate: marketPlaceErrors.rfqErrors.destinationDeliveryDate,
            }

        default:
            return {}
    }
}

export const getCompleteOrderHazmatStepErrors = (store: Store): CompleteOrderHazmatStepErrors  => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    return {
        hazmatClass: marketPlaceErrors.rfqErrors.hazmatClass,
        hazmatMode: marketPlaceErrors.rfqErrors.hazmatMode,
    }
}

export const getCompleteCustomsOrderStepErrors = (store: Store): CompleteCutomsOrderStepErrors => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    return {
        billDutiesToParty: marketPlaceErrors.rfqCustomsErrors.billDutiesToParty,
        importExport: marketPlaceErrors.rfqCustomsErrors.importExport,
        incotermsType: marketPlaceErrors.rfqCustomsErrors.incotermsType,
        dutyBillToCity: marketPlaceErrors.rfqCustomsErrors.dutyBillToCity,
        dutyBillToContactEmail: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactEmail,
        dutyBillToCompanyName: marketPlaceErrors.rfqCustomsErrors.dutyBillToCompanyName,
        dutyBillToContactName: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactName,
        dutyBillToContactPhone: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactPhone,
        dutyBillToCountry: marketPlaceErrors.rfqCustomsErrors.dutyBillToCountry,
        dutyBillToPostalCode: marketPlaceErrors.rfqCustomsErrors.dutyBillToPostalCode,
        dutyBillToState: marketPlaceErrors.rfqCustomsErrors.dutyBillToState,
        dutyBillToStreetAddress: marketPlaceErrors.rfqCustomsErrors.dutyBillToStreetAddress,
        dutyBillToAccountNumber: marketPlaceErrors.rfqCustomsErrors.dutyBillToAccountNumber,
        customsBroker: marketPlaceErrors.rfqErrors.customsBroker,
        customsBrokerCity: marketPlaceErrors.rfqCustomsErrors.customsBrokerCity,
        customsBrokerEmail: marketPlaceErrors.rfqCustomsErrors.customsBrokerEmail,
        customsBrokerContactName: marketPlaceErrors.rfqCustomsErrors.customsBrokerContactName,
        customsBrokerContactPhone: marketPlaceErrors.rfqCustomsErrors.customsBrokerContactPhone,
        customsBrokerContactPhoneExt: marketPlaceErrors.rfqCustomsErrors.customsBrokerContactPhoneExt,
        customsBrokerCountry: marketPlaceErrors.rfqCustomsErrors.customsBrokerCountry,
        customsBrokerPostalCode: marketPlaceErrors.rfqCustomsErrors.customsBrokerPostalCode,
        customsBrokerState: marketPlaceErrors.rfqCustomsErrors.customsBrokerState,
        customsBrokerStreetAddress: marketPlaceErrors.rfqCustomsErrors.customsBrokerStreetAddress,
        customsBrokerStreetAddress2: marketPlaceErrors.rfqCustomsErrors.customsBrokerStreetAddress2,
        customsBrokerAccountNumber: marketPlaceErrors.rfqCustomsErrors.customsBrokerAccountNumber,
        taxIdentificationNumber: marketPlaceErrors.rfqCustomsErrors.taxIdentificationNumber,
        commercialInvoiceUserProvided: marketPlaceErrors.rfqErrors.commercialInvoiceUserProvided,
    }
}

export const getCompleteCanadaPostCutomsOrderStepErrors = (store: Store): CompleteCutomsOrderStepErrors => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    return {
        reasonForExport: marketPlaceErrors.rfqCustomsErrors.reasonForExport,
        nonDeliveryHandling: marketPlaceErrors.rfqCustomsErrors.nonDeliveryHandling,
        otherReasonForExport: marketPlaceErrors.rfqCustomsErrors.otherReasonForExport,
        billDutiesToParty: marketPlaceErrors.rfqCustomsErrors.billDutiesToParty,
        dutyBillToCity: marketPlaceErrors.rfqCustomsErrors.dutyBillToCity,
        dutyBillToContactEmail: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactEmail,
        dutyBillToCompanyName: marketPlaceErrors.rfqCustomsErrors.dutyBillToCompanyName,
        dutyBillToContactName: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactName,
        dutyBillToContactPhone: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactPhone,
        dutyBillToCountry: marketPlaceErrors.rfqCustomsErrors.dutyBillToCountry,
        dutyBillToPostalCode: marketPlaceErrors.rfqCustomsErrors.dutyBillToPostalCode,
        dutyBillToState: marketPlaceErrors.rfqCustomsErrors.dutyBillToState,
        dutyBillToStreetAddress: marketPlaceErrors.rfqCustomsErrors.dutyBillToStreetAddress,
        dutyBillToAccountNumber: marketPlaceErrors.rfqCustomsErrors.dutyBillToAccountNumber,
        incotermsType: marketPlaceErrors.rfqCustomsErrors.incotermsType,
    }
}

export const getCompleteCanparCutomsOrderStepErrors = (store: Store): CompleteCutomsOrderStepErrors => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    return {
        billDutiesToParty: marketPlaceErrors.rfqCustomsErrors.billDutiesToParty,
        dutyBillToCity: marketPlaceErrors.rfqCustomsErrors.dutyBillToCity,
        dutyBillToContactEmail: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactEmail,
        dutyBillToCompanyName: marketPlaceErrors.rfqCustomsErrors.dutyBillToCompanyName,
        dutyBillToContactName: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactName,
        dutyBillToContactPhone: marketPlaceErrors.rfqCustomsErrors.dutyBillToContactPhone,
        dutyBillToCountry: marketPlaceErrors.rfqCustomsErrors.dutyBillToCountry,
        dutyBillToPostalCode: marketPlaceErrors.rfqCustomsErrors.dutyBillToPostalCode,
        dutyBillToState: marketPlaceErrors.rfqCustomsErrors.dutyBillToState,
        dutyBillToStreetAddress: marketPlaceErrors.rfqCustomsErrors.dutyBillToStreetAddress,
        dutyBillToAccountNumber: marketPlaceErrors.rfqCustomsErrors.dutyBillToAccountNumber,
        importExport: marketPlaceErrors.rfqCustomsErrors.importExport,
        incotermsType: marketPlaceErrors.rfqCustomsErrors.incotermsType,
    }
}

export const getCompleteOrderStepErrors = (store: Store, locationType: AccessorialLocationType): CompleteOrderStepErrors => {
    const marketPlaceErrors = getMarketPlaceErrors(store)

    switch (locationType) {
        case AccessorialLocationType.ORIGIN:
            return {
                companyName: marketPlaceErrors.rfqErrors.originCompanyName,
                contactName: marketPlaceErrors.rfqErrors.originContactName,
                contactPhone: marketPlaceErrors.rfqErrors.originContactPhone,
                contactEmails: marketPlaceErrors.rfqErrors.originContactEmails,
                openTime: marketPlaceErrors.rfqErrors.originPickupOpenTime,
                closeTime: marketPlaceErrors.rfqErrors.originPickupCloseTime,
            }

        case AccessorialLocationType.DESTINATION:
            return {
                companyName: marketPlaceErrors.rfqErrors.destinationCompanyName,
                contactName: marketPlaceErrors.rfqErrors.destinationContactName,
                contactPhone: marketPlaceErrors.rfqErrors.destinationContactPhone,
                contactEmails: marketPlaceErrors.rfqErrors.destinationContactEmails,
                openTime: marketPlaceErrors.rfqErrors.destinationDeliveryOpenTime,
                closeTime: marketPlaceErrors.rfqErrors.destinationDeliveryCloseTime,
            }

        default:
            return {}
    }
}

export const getTransportInformation = (store: Store): TransportInformation => {
    const marketPlace = getMarketPlace(store)

    return {
        rfq: marketPlace.order.rfq,
    }
}

export const getCargoErrors = (store: Store): Partial<Record<keyof SearchRfqHandlingUnit, string>>[] =>
    getMarketPlaceErrors(store).cargoErrors

export const getHandlingUnitCustomsDetailErrors = (store: Store): Partial<Record<keyof SearchRfqCustomsDetail, string>>[] =>
    getMarketPlaceErrors(store).customsDetailErrors

export const getProgressSummary = (store: Store): ProgressSummaryInformation => {
    const marketPlace = getMarketPlace(store)

    return {
        lastCompletedStep: marketPlace.lastCompletedStep,
        currentStep: marketPlace.currentStep,
        isQuoteFetchingComplete: marketPlace.isQuoteFetchingComplete,
        originAccessorialList: marketPlace.originAccessorialList,
        destinationAccessorialList: marketPlace.destinationAccessorialList,
        handlingUnitAccessorialList: marketPlace.handlingUnitAccessorialList,
        serviceAccessorialList: marketPlace.serviceAccessorialList,
        packageTypeList: marketPlace.packageTypeList,
        coverageCommodityTypeList: marketPlace.coverageCommodityTypeList,
    }
}

export const getShipmentCoverageValue = (store: Store): number | undefined => {
    const rfq = getMarketPlace(store).order?.rfq

    if (!rfq || rfq.shipmentCoverageValue === null || rfq.shipmentCoverageValue === undefined) {
        return undefined
    }

    return +rfq.shipmentCoverageValue
}

export const getOriginalShipmentCoverageValue = (store: Store): number =>
    getMarketPlace(store).order?.rfq?.originalShipmentCoverageValue ?? 0

export const getOriginalShipmentCoverageValueCurrency = (store: Store): CurrencyCode =>
    getMarketPlace(store).order?.rfq?.originalShipmentCoverageValueCurrency ?? CurrencyCode.CAD

export const getCommodityType = (store: Store): CoverageCommodityType | null =>
    getMarketPlace(store).order?.rfq?.coverageCommodityType ?? null

export const getIsCoverageExclusionsAccepted = (store: Store): boolean | undefined => getMarketPlace(store).isCoverageExclusionsAccepted

export const getSelectedQuote = (store: Store): SearchRfqQuote | null =>
    getMarketPlace(store).order?.rfq?.selectedQuote ?? null

export const getSelectedQuoteId = (store: Store): UuidV4 | null =>
    getMarketPlace(store).order?.rfq?.selectedQuote?.id ?? null

export const getIsBookedWithCoverage = (store: Store): boolean | undefined => getMarketPlace(store).order?.rfq?.isBookedWithCoverage

export const getOrder = (store: Store): Partial<Search> => getMarketPlace(store).order

export const getRfq = (store: Store): Partial<SearchRfq> | undefined => getMarketPlace(store).order?.rfq

export const getLastCompletedStep = (store: Store): MarketPlaceStep => getMarketPlace(store).lastCompletedStep

export const getInvalidationDialog = (store: Store): InvalidationDialog => getMarketPlace(store).invalidationDialog

export const getAddressConfirmationInformation =
    (store: Store, locationType: AccessorialLocationType): Partial<AddressConfirmationInformation> => {
        const marketPlace = getMarketPlace(store)

        switch (locationType) {
            case AccessorialLocationType.ORIGIN:
                return {
                    streetAddress: marketPlace.order?.rfq?.originStreetAddress,
                    streetAddress2: marketPlace.order?.rfq?.originStreetAddress2,
                    streetAddress3: marketPlace.order?.rfq?.originStreetAddress3,
                    city: marketPlace.order?.rfq?.originCity,
                    state: marketPlace.order?.rfq?.originState,
                    country: marketPlace.order?.rfq?.originCountry,
                    postalCode: marketPlace.order?.rfq?.originPostalCode,
                    pickupDate: marketPlace.order?.rfq?.originPickupDate,
                    companyName: marketPlace.order?.rfq?.originCompanyName,
                    contactName: marketPlace.order?.rfq?.originContactName,
                    contactEmails: marketPlace.order?.rfq?.originContactEmails,
                    notify: marketPlace.order?.rfq?.originNotifyShipper,
                    contactPhone: marketPlace.order?.rfq?.originContactPhone,
                    contactPhoneExtension: marketPlace.order?.rfq?.originContactPhoneExtension,
                    poNumber: marketPlace.order?.rfq?.originPoNumber,
                    openTime: marketPlace.order?.rfq?.originPickupOpenTime,
                    closeTime: marketPlace.order?.rfq?.originPickupCloseTime,
                    accessorials: marketPlace.order?.rfq?.accessorials?.filter(
                        (accessorial) => accessorial.accessorial.locationType === AccessorialLocationType.ORIGIN ||
                            accessorial.accessorial.type === AccessorialType.SERVICE) ?? [],
                    instructions: marketPlace.order?.rfq?.originPickupInstructions,
                    transitDaysMin: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.transitDaysMin,
                    transitDaysMax: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.transitDaysMax,
                }

            case AccessorialLocationType.DESTINATION:

                return {
                    streetAddress: marketPlace.order?.rfq?.destinationStreetAddress,
                    streetAddress2: marketPlace.order?.rfq?.destinationStreetAddress2,
                    streetAddress3: marketPlace.order?.rfq?.destinationStreetAddress3,
                    city: marketPlace.order?.rfq?.destinationCity,
                    state: marketPlace.order?.rfq?.destinationState,
                    country: marketPlace.order?.rfq?.destinationCountry,
                    postalCode: marketPlace.order?.rfq?.destinationPostalCode,
                    pickupDate: marketPlace.order?.rfq?.originPickupDate,
                    companyName: marketPlace.order?.rfq?.destinationCompanyName,
                    contactName: marketPlace.order?.rfq?.destinationContactName,
                    contactEmails: marketPlace.order?.rfq?.destinationContactEmails,
                    notify: marketPlace.order?.rfq?.destinationNotifyReceiver,
                    contactPhone: marketPlace.order?.rfq?.destinationContactPhone,
                    contactPhoneExtension: marketPlace.order?.rfq?.destinationContactPhoneExtension,
                    poNumber: marketPlace.order?.rfq?.destinationPoNumber,
                    openTime: marketPlace.order?.rfq?.destinationDeliveryOpenTime,
                    closeTime: marketPlace.order?.rfq?.destinationDeliveryCloseTime,
                    accessorials: marketPlace.order?.rfq?.accessorials?.filter(
                        (accessorial) => accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? [],
                    instructions: marketPlace.order?.rfq?.destinationDeliveryInstructions,
                    transitDaysMin: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.transitDaysMin,
                    transitDaysMax: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.transitDaysMax,
                    estimatedDeliveryDate: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.estimatedDeliveryDate,
                    estimatedTimeOfArrival: marketPlace.order?.rfq?.quotes?.find(
                        (quote) => quote.id === getMarketPlace(store).order?.rfq?.selectedQuote?.id)?.estimatedTimeOfArrival,
                }

            default:
                return {}
        }
    }

export const  getCompleteCutomsOrderInformation = (store: Store): CompleteCustomsOrderInformation => {
    const marketPlace = getMarketPlace(store)

    return {
        rfq: marketPlace.order.rfq,
        dutyBillFullAddress: marketPlace.dutyBillFullAddress,
        customsBrokerFullAddress: marketPlace.customsBrokerFullAddress,
        documents: marketPlace.order.documents || [],
    }
}

export const getCompleteOrderInformation = (store: Store, locationType: AccessorialLocationType): Partial<CompleteOrderInformation> => {
    const marketPlace = getMarketPlace(store)

    switch (locationType) {
        case AccessorialLocationType.ORIGIN:
            return {
                streetAddress: marketPlace.order?.rfq?.originStreetAddress,
                streetAddress2: marketPlace.order?.rfq?.originStreetAddress2,
                streetAddress3: marketPlace.order?.rfq?.originStreetAddress3,
                city: marketPlace.order?.rfq?.originCity,
                state: marketPlace.order?.rfq?.originState,
                country: marketPlace.order?.rfq?.originCountry,
                postalCode: marketPlace.order?.rfq?.originPostalCode,
                addressType: marketPlace.order?.rfq?.originAddressType,
                detectedAddressType: marketPlace.order?.rfq?.originDetectedAddressType,
                pickupDate: marketPlace.order?.rfq?.originPickupDate,
                companyName: marketPlace.order?.rfq?.originCompanyName,
                contactName: marketPlace.order?.rfq?.originContactName,
                contactEmails: marketPlace.order?.rfq?.originContactEmails,
                poNumber: marketPlace.order?.rfq?.originPoNumber,
                notify: marketPlace.order?.rfq?.originNotifyShipper,
                contactPhone: marketPlace.order?.rfq?.originContactPhone,
                contactPhoneExtension: marketPlace.order?.rfq?.originContactPhoneExtension,
                openTime: marketPlace.order?.rfq?.originPickupOpenTime,
                closeTime: marketPlace.order?.rfq?.originPickupCloseTime,
                accessorials: marketPlace.order?.rfq?.accessorials?.filter(
                    (accessorial) => accessorial.accessorial.locationType === AccessorialLocationType.ORIGIN) ?? [],
                instructions: marketPlace.order?.rfq?.originPickupInstructions,
                customQuoteRequested: marketPlace.order?.rfq?.customQuoteRequested,
                addressBookAddress: marketPlace.originAddressBookAddress,
                instructionsMaxCharacter: marketPlace.order.rfq?.selectedQuote?.quoteCarrier.pickupInstructionsMaxCharacter,
            }

        case AccessorialLocationType.DESTINATION:

            return {
                streetAddress: marketPlace.order?.rfq?.destinationStreetAddress,
                streetAddress2: marketPlace.order?.rfq?.destinationStreetAddress2,
                streetAddress3: marketPlace.order?.rfq?.destinationStreetAddress3,
                city: marketPlace.order?.rfq?.destinationCity,
                state: marketPlace.order?.rfq?.destinationState,
                country: marketPlace.order?.rfq?.destinationCountry,
                postalCode: marketPlace.order?.rfq?.destinationPostalCode,
                addressType: marketPlace.order?.rfq?.destinationAddressType,
                detectedAddressType: marketPlace.order?.rfq?.destinationDetectedAddressType,
                pickupDate: marketPlace.order?.rfq?.originPickupDate,
                companyName: marketPlace.order?.rfq?.destinationCompanyName,
                contactName: marketPlace.order?.rfq?.destinationContactName,
                contactEmails: marketPlace.order?.rfq?.destinationContactEmails,
                poNumber: marketPlace.order?.rfq?.destinationPoNumber,
                notify: marketPlace.order?.rfq?.destinationNotifyReceiver,
                contactPhone: marketPlace.order?.rfq?.destinationContactPhone,
                contactPhoneExtension: marketPlace.order?.rfq?.destinationContactPhoneExtension,
                openTime: marketPlace.order?.rfq?.destinationDeliveryOpenTime,
                closeTime: marketPlace.order?.rfq?.destinationDeliveryCloseTime,
                accessorials: marketPlace.order?.rfq?.accessorials?.filter(
                    (accessorial) => accessorial.accessorial.locationType === AccessorialLocationType.DESTINATION) ?? [],
                instructions: marketPlace.order?.rfq?.destinationDeliveryInstructions,
                customQuoteRequested: marketPlace.order?.rfq?.customQuoteRequested,
                addressBookAddress: marketPlace.destinationAddressBookAddress,
                instructionsMaxCharacter: marketPlace.order.rfq?.selectedQuote?.quoteCarrier.deliveryInstructionsMaxCharacter,
            }

        default:
            return {}
    }
}

export const getTransportConfirmationInformation = (store: Store): Partial<TransportConfirmationInformation> => {
    const marketPlace = getMarketPlace(store)
    const rfq = getRfq(store)
    const order = getOrder(store)
    const selectedResult = getSelectedQuote(store)

    return {
        carrierDisplayName: selectedResult?.carrierDisplayName,
        isBookedWithCoverage: marketPlace.order?.rfq?.isBookedWithCoverage,
        commodityType: marketPlace.order?.rfq?.coverageCommodityType ?? null,
        shipmentCoverageValue: (
            !rfq || rfq.shipmentCoverageValue === null || rfq.shipmentCoverageValue === undefined
                ? undefined
                : +rfq.shipmentCoverageValue
        ),
        customsBroker: rfq?.customsBroker,
        clientNumber: order?.clientNumber,
        threePlNumber: order?.threePlNumber,
        equipmentType: rfq?.equipmentType,
        transportType: rfq?.transportType,
        provider: selectedResult?.provider,
        serviceLevel: selectedResult?.serviceLevel,
        transitDaysMax: selectedResult?.transitDaysMax,
        transitDaysMin: selectedResult?.transitDaysMin,
        tariffType: selectedResult?.tariffType,
        isDocumentOnly: rfq?.documentsOnlyIndicator,
        useThermalPrinter: rfq?.useThermalPrinter,
        labelSizeType: rfq?.labelSizeType,
        handlingUnits: rfq?.handlingUnits,
    }
}

export const getBillingCurrency = (store: Store): Currency | null => getMarketPlace(store).order.billingCurrency ?? null

export const getCurrentStep = (store: Store): MarketPlaceStep => getMarketPlace(store).currentStep

export const getIsQuoteFetchingComplete = (store: Store): boolean => getMarketPlace(store).isQuoteFetchingComplete

export const getQuoteFetchingResultsCompleted = (store: Store): Record<string, boolean> =>
    getMarketPlace(store).quoteFetchingResultsCompleted

export const getQuoteFetchingSearchProgress = (store: Store): number => getMarketPlace(store).quoteFetchingSearchProgress

export const getPromptModalLeave = (store: Store): boolean => getMarketPlace(store).promptModalLeave

export const getOriginAddressBookAddress = (store: Store): Address | null => getMarketPlace(store).originAddressBookAddress

export const getDestinationAddressBookAddress = (store: Store): Address | null => getMarketPlace(store).destinationAddressBookAddress

export const getSelectedValidatedOriginAddress = (store: Store): ValidatedAddress | null =>
    getMarketPlace(store).selectedValidatedOriginAddress

export const getSelectedValidatedDestinationAddress = (store: Store): ValidatedAddress | null =>
    getMarketPlace(store).selectedValidatedDestinationAddress

export const getOpenReasonsModal = (store: Store): boolean => getMarketPlace(store).openReasonsModal

export const getOpenFedexGroundModal = (store: Store): boolean => getMarketPlace(store).openFedexGroundModal

export const getPaymentType = (store: Store): PaymentTypeOption =>
    getMarketPlace(store).paymentType

export const getCollect = (store: Store): Collect | null =>
    getMarketPlace(store).collect as Collect

export const getUserLanes = (store: Store): Lane[] | null =>
    getMarketPlace(store).userLanes

export const getSelectedLane = (store: Store): Lane | null =>
    getMarketPlace(store).selectedLane

export const getRescheduledPickupDate = (store: Store): string  => store.newMarketPlace.rescheduledPickupDate

export const getPromptUnsavedChangesDialog = (store: Store): boolean => getMarketPlace(store).promptUnsavedChangesDialog
