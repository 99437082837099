import {
    Locale as LocaleCode,
    Country as CountryCode,
    Language as LanguageCode,
    Currency as CurrencyCode,
} from '@lazr/openapi-client'

export interface LocaleProps {
    code: LocaleCode
    name: string
    country: CountryCode
    language: LanguageCode
    currency: CurrencyCode
}

export class Locale {
    public code: LocaleCode
    public name: string
    public country: CountryCode
    public language: LanguageCode
    public currency: CurrencyCode

    constructor (props: LocaleProps) {
        this.code = props.code
        this.name = props.name
        this.country = props.country
        this.language = props.language
        this.currency = props.currency
    }
}
