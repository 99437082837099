/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RecordMarketplaceStepNavigationRequest } from '../models/RecordMarketplaceStepNavigationRequest';
import type { SuccessResponse_RecordMarketplaceStepNavigationResponse_ } from '../models/SuccessResponse_RecordMarketplaceStepNavigationResponse_';
import { request as __request } from '../core/request';

export class MarketplaceStepNavigationService {

    /**
     * Record a marketplace step navigation
     * @param requestBody The data
     * @returns SuccessResponse_RecordMarketplaceStepNavigationResponse_ OK
     * @throws ApiError
     */
    public static async recordMarketplaceStepNavigation(
        requestBody: RecordMarketplaceStepNavigationRequest,
    ): Promise<SuccessResponse_RecordMarketplaceStepNavigationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/record-marketplace-step-navigation`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}