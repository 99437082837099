import React from 'react'
import {
    AnimatedGlow, ImageWrapper, NewWayArrowImg,
} from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/NewWayHeader.styled'
import { StyledTitleTypography } from '@/app/ui-new/pages/website/components/OldVsNewWay/Mobile/OldWayHeader.styled'
import { Box } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './OldVsNewWayMobile.i18n'

const NewWayHeader: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return (
        <ImageWrapper>
            <NewWayArrowImg src={`/images/website/OldVsNewWay/NewWayArrowMobile.svg?v=${window.lazrVersion}`} alt="NewWayArrow"/>
            <Box position={'absolute'} top={20} left={28}>
                <StyledTitleTypography variant={'h5'}>{t('Lazr Way')}</StyledTitleTypography>
            </Box>
            <AnimatedGlow src={`/images/website/OldVsNewWay/NewWayArrowAnimatedGlowMobile.png?v=${window.lazrVersion}`}
                alt="NewWayArrowAnimatedGlow"/>
        </ImageWrapper>
    )
}

export default NewWayHeader
