import type { CoverageCommodityType as CoverageCommodityTypeCode } from '@lazr/openapi-client'

export interface CoverageCommodityTypeProps {
    code: CoverageCommodityTypeCode
    name: string
}

export class CoverageCommodityType {
    code: CoverageCommodityTypeCode
    name: string

    constructor (props: CoverageCommodityTypeProps) {
        this.code = props.code
        this.name = props.name
    }
}
