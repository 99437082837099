import React from 'react'
import {
    Wrapper,
} from './VideoHolderDesktop.styled'
import { Box } from '@material-ui/core'
import { VideoHolderDesktop as VideoHolder } from '../VideoHolderDesktop'


const VideoHolderDesktop: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={1224}>
            <VideoHolder/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default VideoHolderDesktop
