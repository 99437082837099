import React from 'react'
import { Grid } from '@material-ui/core'

import { Typography, TitleTypography } from './ProgressSummaryInformation.styled'

const ProgressSummaryInformation: React.FunctionComponent<Props> = ({
    sectionA,
    sectionB,
    className,
}) =>
    <Grid container justifyContent='space-between' spacing={4} className={className}>
        <Grid item xs={12} sm={7}>
            <Grid container justifyContent='space-between' direction='row' spacing={0}>
                <Grid item xs={7} sm={6}>
                    <Grid container direction='column'>
                        {sectionA.map((row, index) =>
                            <Grid key={index} item xs>
                                <TitleTypography
                                    pt={0.75}
                                    variant='body2'
                                >
                                    {row.title}
                                </TitleTypography>
                            </Grid>,
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={6}>
                    <Grid container direction='column'>
                        {sectionA.map((row, index) =>
                            <Grid key={index} item xs>
                                <Typography
                                    pt={0.75}
                                    noWrap
                                    variant='body2'
                                >
                                    {row.value}
                                </Typography>
                            </Grid>,
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
            <Grid container justifyContent='space-between' direction='row' spacing={0}>
                <Grid item xs={7} sm={7}>
                    <Grid container direction='column'>
                        {sectionB.map((row, index) =>
                            <Grid key={index} item xs>
                                <TitleTypography
                                    pt={0.75}
                                    variant='body2'
                                >
                                    {row.title}
                                </TitleTypography>
                            </Grid>,
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={5}>
                    <Grid container direction='column'>
                        {sectionB.map((row, index) =>
                            <Grid key={index} item xs>
                                <Typography
                                    component='div'
                                    pt={0.75}
                                    noWrap
                                    variant='body2'
                                >
                                    {row.value}
                                </Typography>
                            </Grid>,
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>

export interface Props{
    sectionA: ProgressSummaryInformationData[]
    sectionB: ProgressSummaryInformationData[]
    className?: string
}
export interface ProgressSummaryInformationData {
    title: React.ReactNode
    value: React.ReactNode
}

export default ProgressSummaryInformation
