import { PackageType as PackageTypeCode } from '@lazr/openapi-client'

export interface PackageTypeProps {
    code: PackageTypeCode
    singularName: string
    pluralName: string
}

export class PackageType {
    code: PackageTypeCode
    singularName: string
    pluralName: string

    constructor (props: PackageTypeProps) {
        this.code = props.code
        this.singularName = props.singularName
        this.pluralName = props.pluralName
    }
}
