import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors'
import { dashboardRoute, homeRoute, landingRoute, signInRoute } from '@/app/ui/routes'
import { OrganizationApiService } from '@/app/service/ApiService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@/app/ui-new/components/TextField/TextField'
import { TextFieldAdornment } from '@/app/ui-new/components/TextFieldAdornment/TextFieldAdornment'
import {
    TextFieldIconEnvelope,
    TextFieldIconLockAlt,
    TextFieldIconUser,
} from '@/app/ui-new/components/TextFieldIcons/TextFieldIcons'
import { PasswordVisibilityButton } from '@/app/ui-new/components/TextFieldIcons/PasswordVisibilityButton'
import { PasswordMatchIndicator } from '@/app/ui-new/components/TextFieldIcons/PasswordMatchIndicator'
import { AuthForm, Message, PrimaryButton, Typography, Welcome } from './RegisterOrganization.styled'
import { SnackbarKey, useSnackbar } from 'notistack'
import { Alert, AlertContent } from '@/app/ui-new/components/Alert/Alert'
import { SPINNER_ICON } from '@/app/ui/components/Modal'
import { RegistrationState } from '@lazr/openapi-client'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './RegisterOrganization.i18n'
import { sanitizeCompanyName } from '@lazr/utilities'

/* SEO */
import SEO from '@/app/ui/seo'
import { FormControl, Grid } from '@material-ui/core'
import PhoneNumberField from '../../components/TextField/PhoneNumberField'


declare const window: Window & { dataLayer: Record<string, unknown>[] }

const SignIn: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [organizationName, setOrganizationName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false)
    const [phone, setPhone] = useState<string>('')
    const [phoneErrorText, setPhoneErrorTextText] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [firstNameErrorText, setFirstNameErrorText] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')

    const [lastNameErrorText, setLastNameErrorText] = useState<string>('')


    const [organizationNameErrorText, setOrganizationNameErrorText] = useState<string>('')
    const [emailErrorText, setEmailErrorText] = useState<string>('')
    const [passwordErrorText, setPasswordErrorText] = useState<string>('')
    const [passwordMatchErrorText, setPasswordMatchErrorText] = useState<string>('')
    const [isRegistrationInProgress, setIsRegistrationInProgress] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [passwordMatch, setPasswordMatch] = useState<boolean>(false)
    const [showPasswordMatch, setShowPasswordMatch] = useState<boolean>(false)

    const urlParams = new URLSearchParams(window.location.search)
    const affiliateIdParam = urlParams.get('affiliateId')
    const { repName } = useParams<{ repName: string }>()
    const authForm: { current: HTMLDivElement | null } = useRef(null)

    useEffect(() => {
        window.setTimeout(() => {
            if (authForm.current && (window.location.pathname.toLowerCase().includes('/register') ||
                (window.location.pathname.toLowerCase() === '/landing' || window.location.pathname.toLowerCase() === '/commerce-chamber/landing'))) {
                authForm.current.classList.add('opacity1')
            }
        }, 300)
    }, [authForm])

    useEffect(() => {
        if (!affiliateIdParam && !repName) {
            return
        }
        if (affiliateIdParam) {
            localStorage.setItem('affiliateId', affiliateIdParam)
        }
        if (repName) {
            localStorage.setItem('repName', repName)
        }
    }, [affiliateIdParam])

    const handleClickShowPassword = useCallback((): void => {
        setShowPassword((PreviousShowPassword) => !PreviousShowPassword)
    }, [setShowPassword])

    const handleClickShowPasswordConfirm = useCallback((): void => {
        setShowPasswordConfirm((PreviousShowPassword) => !PreviousShowPassword)
    }, [setShowPasswordConfirm])

    const openNotistack = useCallback((content: JSX.Element) => {
        const notistackIdentifier = new Date().getTime().toString()
        const wrapper = <div
            className='notistackIdentifier'
            data-notistack-identifier={notistackIdentifier}
        >{content}</div>

        enqueueSnackbar(wrapper, { key: notistackIdentifier })
    }, [enqueueSnackbar])

    const closeNotistack = useCallback((event: any) => {
        const target = event.target.closest('.notistackIdentifier')
        const key: SnackbarKey | undefined = target.dataset.notistackIdentifier

        closeSnackbar(key)
    }, [closeSnackbar])

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, setter: (val: string) => void): void => {
        setter(event.target.value)
    }, [])

    const resetErrors = useCallback(() => {
        setOrganizationNameErrorText('')
        setEmailErrorText('')
        setPasswordErrorText('')
        setPasswordMatchErrorText('')
        setPhoneErrorTextText('')
        setFirstNameErrorText('')
        setLastNameErrorText('')
    }, [
        setPhoneErrorTextText,
        setFirstNameErrorText,
        setLastNameErrorText,
        setOrganizationNameErrorText,
        setEmailErrorText,
        setPasswordErrorText,
        setPasswordMatchErrorText,
    ])

    const handlePasswordCheckOnBlur = useCallback(() => {
        setPasswordMatch(confirmPassword === password)
        setShowPasswordMatch(!!password && !!confirmPassword)
    }, [
        setPasswordMatch,
        setShowPasswordMatch,
        confirmPassword,
        password,
    ])

    const handlePasswordCheckOnFocus = useCallback(() => {
        setShowPasswordMatch(false)
    }, [setShowPasswordMatch])

    const formIsFilled = useCallback((): boolean => {
        const missingFieldMessage = t('This field is required')

        setPhoneErrorTextText(!phone ? missingFieldMessage : '')
        setFirstNameErrorText(!firstName ? missingFieldMessage : '')
        setLastNameErrorText(!lastName ? missingFieldMessage : '')
        setOrganizationNameErrorText(!organizationName ? missingFieldMessage : '')
        setEmailErrorText(!email ? missingFieldMessage : '')
        setPasswordErrorText(!password ? missingFieldMessage : '')
        setPasswordMatchErrorText(!confirmPassword ? missingFieldMessage :
            confirmPassword === password ? '' : t('Password confirmation does not match'))

        return !!(organizationName && email && password && confirmPassword === password)
    }, [
        setOrganizationNameErrorText,
        setEmailErrorText,
        setPasswordErrorText,
        setPasswordMatchErrorText,
        setPhoneErrorTextText,
        setFirstNameErrorText,
        setLastNameErrorText,
        phone,
        firstName,
        lastName,
        organizationName,
        email,
        password,
        confirmPassword,
    ])

    const isGenericOrganizationApiServiceError = useCallback((err: string): boolean => err === t('Unable to register organization'), [t])

    const applyErrorMessage = useCallback((originalError: string): void => {
        const lowerCasedError = originalError.toLowerCase()

        switch (true) {
            case isGenericOrganizationApiServiceError(originalError):
                setErrorMessage(originalError)
                break
            case lowerCasedError.includes('organization') || lowerCasedError.includes('name'):
                if (!lowerCasedError.includes('type')) {
                    setOrganizationNameErrorText(originalError)
                }
                break
            case lowerCasedError.includes('email'):
                setEmailErrorText(originalError)
                break
            case lowerCasedError.includes('password'):
                setPasswordErrorText(originalError)
                break
            default:
                setErrorMessage(originalError)
                break
        }
    }, [
        isGenericOrganizationApiServiceError,
        setErrorMessage,
        setOrganizationNameErrorText,
        setEmailErrorText,
        setPasswordErrorText,
    ])

    const register = useCallback(async () => {
        const affiliateId = localStorage.getItem('affiliateId') ?? undefined
        const repName = localStorage.getItem('repName') ?? undefined
        const repReference = {
            affiliateId,
            repName,
        }

        setIsRegistrationInProgress(true)

        const result = await OrganizationApiService.register(
            organizationName,
            email,
            password,
            window.location.href,
            firstName,
            lastName,
            phone,
            '',
            '',
            '',
            '',
            repReference,
        )

        if (result.error) {
            console.error(result.error)
            applyErrorMessage(result.error)

            window.dataLayer.push({
                event: window.location.pathname === landingRoute.path
                    ? 'landing_register_organization_failed'
                    : 'website_register_organization_failed',
                registerOrganizationOrganizationName: organizationName,
                registerOrganizationAdminUserEmail: email,
                registerOrganizationSalesRepAffiliateId: affiliateId,
                registerOrganizationSalesRepName: repName,
                registerOrganizationErrorMessage: result.error,
            })
        } else {
            props.setRegistrationState(result.state)
            props.setSuccessfulSignup(true)
            localStorage.removeItem('affiliateId')
            localStorage.removeItem('repName')

            window.dataLayer.push({
                event: window.location.pathname === landingRoute.path
                    ? 'landing_register_organization'
                    : 'website_register_organization',
                registerOrganizationOrganizationName: organizationName,
                registerOrganizationAdminUserEmail: email,
                registerOrganizationSalesRepAffiliateId: affiliateId,
                registerOrganizationSalesRepName: repName,
            })
        }
        setIsRegistrationInProgress(false)
    }, [
        setIsRegistrationInProgress,
        OrganizationApiService,
        applyErrorMessage,
        landingRoute,
        organizationName,
        email,
        password,
        props.setRegistrationState,
        props.setSuccessfulSignup,
        firstName,
        lastName,
        phone,
    ])

    const handleRegister = useCallback((event: React.FormEvent) => {
        event.preventDefault()
        resetErrors()

        return void (async () => {
            if (formIsFilled()) {
                await register()
            }
        })()
    }, [
        resetErrors,
        formIsFilled,
        register,
    ])

    if (useSelector(reduxGetUser)) {
        return <Redirect to={dashboardRoute.path} />
    }

    if (props.successfulSignup) {
        return <>
            <img alt='Success' src='/images/ninja/NinjaSuccess.svg' />
            <Welcome>
                {t('Success!')}
            </Welcome>
            <Message>
                <Typography variant='body1' align='center'>
                    {
                        props.registrationState === RegistrationState.PENDING_EMAIL_CONFIRMATION
                            ? t('In order to sign in to Lazr, you must first verify your email by following the link sent to your inbox.')
                            : t('Your account has been created, sign in now.')
                    }
                </Typography>
                <PrimaryButton href={
                    props.registrationState === RegistrationState.PENDING_EMAIL_CONFIRMATION ? homeRoute.path : signInRoute.path} mt={8}>
                    {props.registrationState === RegistrationState.PENDING_EMAIL_CONFIRMATION ?
                        t('Go Back to Main Page') : t('Go Back to the Sign In Page')}
                </PrimaryButton>
            </Message>
        </>
    }

    if (errorMessage) {
        openNotistack(
            <Alert severity='error' onClose={closeNotistack}>
                <AlertContent message={errorMessage} />
            </Alert>,
        )
        setErrorMessage('')
    }

    return <>
        {/* SEO (START) */}
        <SEO
            pageTitle={t('Create New Organization')}
        />
        {/* SEO (END) */}
        <Typography variant='body2' my={6}>
            {t('This form will create a new organization within Lazr.')}
        </Typography>
        <Typography variant='body2' my={6}>
            {/* eslint-disable-next-line max-len */}
            {t('If you would like to join an existing organization, please contact an administrator of that organization for an invitation.')}
        </Typography>
        <form onSubmit={handleRegister}>
            <Grid container spacing={4}>
                {/* First/Last name */}
                <Grid item xs={6}>
                    <FormControl margin='normal' required fullWidth>
                        <TextField
                            label={t('First Name')}
                            value={firstName}
                            fullWidth
                            size='small'
                            autoFocus
                            variant='outlined'
                            error={firstNameErrorText !== ''}
                            helperText={firstNameErrorText}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {

                                setFirstName(e.target.value)
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl margin='normal' required fullWidth>
                        <TextField
                            label={t('Last Name')}
                            value={lastName}
                            fullWidth
                            size='small'
                            variant='outlined'
                            error={lastNameErrorText !== ''}
                            helperText={lastNameErrorText}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                setLastName(e.target.value)
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControl margin='normal' required fullWidth>
                    <PhoneNumberField
                        fullWidth
                        size='small'
                        label={t('Phone')}
                        variant={'outlined'}
                        value={phone}
                        error={phoneErrorText !== ''}
                        helperText={phoneErrorText}
                        onChange={(newPhoneNumber: string): void => {
                           setPhone(newPhoneNumber)
                        }}
                    />
                </FormControl>
            </Grid>
            <AuthForm ref={authForm}>
                <TextField
                    name='organizationName'
                    label={t('New Organization Name')}
                    value={organizationName}
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconUser />
                            </TextFieldAdornment>
                        ),
                    }}
                    onChange={
                        (event: React.ChangeEvent<HTMLInputElement>): void => {
                            setOrganizationName(
                                event.target.value
                                    ? sanitizeCompanyName(event.target.value)
                                    : '',
                            )
                        }
                    }
                    autoComplete='organizationName'
                    
                    error={organizationNameErrorText !== ''}
                    helperText={organizationNameErrorText}
                    variant='outlined'
                />
                <TextField
                    name='email'
                    label={t('Email')}
                    value={email}
                    size='small'
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconEnvelope />
                            </TextFieldAdornment>
                        ),
                    }}
                    onChange={
                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setEmail)
                        }
                    }
                    autoComplete='email'
                    error={emailErrorText !== ''}
                    helperText={emailErrorText}
                    variant='outlined'
                />
                <TextField
                    name='password'
                    label={t('Password')}
                    value={password}
                    size='small'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconLockAlt />
                            </TextFieldAdornment>
                        ),
                        endAdornment: (
                            <TextFieldAdornment position='end'>
                                <PasswordVisibilityButton
                                    passwordVisible={showPassword}
                                    onClick={handleClickShowPassword}
                                    size='small'
                                />
                            </TextFieldAdornment>
                        ),
                    }}
                    onChange={
                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setPassword)
                        }
                    }
                    onBlur={handlePasswordCheckOnBlur}
                    onFocus={handlePasswordCheckOnFocus}
                    autoComplete='current-password'
                    error={passwordErrorText !== ''}
                    helperText={passwordErrorText}
                    variant='outlined'
                />
                <TextField
                    name='password-confirmation'
                    label={t('Confirm password')}
                    value={confirmPassword}
                    size='small'
                    type={showPasswordConfirm ? 'text' : 'password'}
                    InputProps={{
                        startAdornment: (
                            <TextFieldAdornment position='start'>
                                <TextFieldIconLockAlt />
                            </TextFieldAdornment>
                        ),
                        endAdornment: (
                            <TextFieldAdornment position='end'>
                                <PasswordMatchIndicator
                                    showPasswordMatch={showPasswordMatch}
                                    passwordMatch={passwordMatch}
                                />
                                <PasswordVisibilityButton
                                    passwordVisible={showPasswordConfirm}
                                    onClick={handleClickShowPasswordConfirm}
                                    size='small'
                                />
                            </TextFieldAdornment>
                        ),
                    }}
                    onChange={
                        (e: React.ChangeEvent<HTMLInputElement>): void => {
                            handleInputChange(e, setConfirmPassword)
                        }
                    }
                    onBlur={handlePasswordCheckOnBlur}
                    onFocus={handlePasswordCheckOnFocus}
                    autoComplete='confirm-password'
                    error={passwordMatchErrorText !== ''}
                    helperText={passwordMatchErrorText}
                    variant='outlined'
                />
                <PrimaryButton
                    type='submit'
                    disabled={isRegistrationInProgress}
                    mt={7.5}
                    endIcon={isRegistrationInProgress && <FontAwesomeIcon icon={SPINNER_ICON} pulse />}
                    className='conversion-tracking-website-register-organization'
                >{t('Create New Organization')}</PrimaryButton>
            </AuthForm>
        </form>
    </>
}

interface Props {
    successfulSignup: boolean
    setSuccessfulSignup: (state: boolean) => void
    registrationState: RegistrationState | null
    setRegistrationState: (state: RegistrationState) => void
}

export default SignIn
