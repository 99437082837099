/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['Confirm password']: 'Confirm password',
    ['Create New Organization']: 'Create New Organization',
    ['Email']: 'Email',
    ['Go Back to the Sign In Page']: 'Go Back to the Sign In Page',
    ['Go Back to Main Page']: 'Go back to main page',
    ['If you would like to join an existing organization, please contact an administrator of that organization for an invitation.']: 'If you would like to join an existing organization, please contact an administrator of that organization for an invitation.',
    ['In order to sign in to Lazr, you must first verify your email by following the link sent to your inbox.']: 'In order to sign in to Lazr, you must first verify your email by following the link sent to your inbox.',
    ['New Organization Name']: 'New Organization Name',
    ['Password confirmation does not match']: 'Password confirmation does not match',
    ['Password']: 'Password',
    ['Success!']: 'Success!',
    ['This field is required']: 'This field is required',
    ['This form will create a new organization within Lazr.']: 'This form will create a new organization within Lazr.',
    ['Unable to register organization']: 'Unable to register organization',
    ['Your account has been created, sign in now.']: 'Your account has been created, sign in now.',
    [`First Name`]: `First Name`,
    [`Last Name`]: `Last Name`,
    [`Phone`]: `Phone`,
}
