import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { ButtonProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'


export const StyledSwipeableViews = styled(SwipeableViews)`
    padding: 0 10px;
    cursor: pointer;
    width: 100%;

    .react-swipeable-view-container > div{
        padding: 0 4px;
        user-select: none;
    }
`
export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 120%;
    color: ${(props): string => props.theme.palette.neutral800.main};
`

export const StyledSubTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.25rem;
    font-weight: 400;
    color: ${(props): string => props.theme.palette.neutral700.main};
    line-height: 130%;
`

export const StyledPrimaryButton: React.FC<ButtonProps> = styled(PrimaryButton)<ButtonProps>`
    background-color: ${(props): number => props.theme.palette.accentSecondary.main};
    color: ${(props): number => props.theme.palette.white.main};
    &:hover {
        background: ${(props): number => props.theme.palette.accentSecondary400.main};
    }

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }
`

