/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DraftOrderRequest } from '../models/DraftOrderRequest';
import type { SubmitOrderRequest } from '../models/SubmitOrderRequest';
import type { SuccessResponse_DraftOrderResponse_ } from '../models/SuccessResponse_DraftOrderResponse_';
import type { SuccessResponse_SubmitOrderResponse_ } from '../models/SuccessResponse_SubmitOrderResponse_';
import { request as __request } from '../core/request';

export class CreateOrderService {

    /**
     * Draft an order
     * @param requestBody The data
     * @returns SuccessResponse_DraftOrderResponse_ OK
     * @throws ApiError
     */
    public static async draftOrder(
        requestBody: DraftOrderRequest,
    ): Promise<SuccessResponse_DraftOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/draft-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
                501: `Not implemented`,
            },
        });
        return result.body;
    }

    /**
     * Submit an order to a 3PL
     * @param requestBody The data
     * @returns SuccessResponse_SubmitOrderResponse_ OK
     * @throws ApiError
     */
    public static async submitOrder(
        requestBody: SubmitOrderRequest,
    ): Promise<SuccessResponse_SubmitOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/submit-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
                501: `Not implemented`,
            },
        });
        return result.body;
    }

}