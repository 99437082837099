import React, { SyntheticEvent } from 'react'
import { TypographyProps as MuiTypographyProps } from '@material-ui/core'
import { SpacingProps } from '@material-ui/system'
import { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog'
import {
    Dialog as DialogStyled,
    DialogTitle as DialogTitleStyled,
    DialogText as DialogTextStyled,
    DialogButtonContainer as DialogButtonContainerStyled,
    DialogImageContainer as DialogImageContainerStyled,
    DialogToolbar as DialogToolbarStyled,
    DialogToolbarTitle as DialogToolbarTitleStyled,
    DialogCloseIcon as DialogCloseIconStyled,
    TransitionProps as MuiTransitionProps,
} from './Dialog.styled'
import {
    SlideFromTop,
    SlideFromRight,
    SlideFromBottom,
    SlideFromLeft,
} from './SlideInOut'

export const Dialog: React.FunctionComponent<DialogProps> = (props) => {
    // Setting the possible classNames
    const fullScreen = props.fullScreen
    let alignmentClassName = props.size === 'small' ? 'small' : ''
    let TransitionComponent: React.ComponentType<MuiTransitionProps & { children?: React.ReactElement<any, any> }> | undefined =
        props.TransitionComponent

    switch (props.alignment) {
        case 'top':
            alignmentClassName = fullScreen ? '' : 'dialog-top'
            TransitionComponent = props.TransitionComponent ? props.TransitionComponent : SlideFromTop
            break
        case 'right':
            alignmentClassName = fullScreen ? '' : 'dialog-right'
            TransitionComponent = props.TransitionComponent ? props.TransitionComponent : SlideFromRight
            break
        case 'bottom':
            alignmentClassName = fullScreen ? '' : 'dialog-bottom'
            TransitionComponent = props.TransitionComponent ? props.TransitionComponent : SlideFromBottom
            break
        case 'left':
            alignmentClassName = fullScreen ? '' : 'dialog-left'
            TransitionComponent = props.TransitionComponent ? props.TransitionComponent : SlideFromLeft
            break
    }
    const className = props.className ? props.className.split(' ').concat(alignmentClassName).join(' ') : alignmentClassName

    // Preparing the props for the Mui Dialog instance
    const newProps = { ...props, className, TransitionComponent: TransitionComponent }

    // Adding the backdropClickFunction (if provided) to the onClose callback
    const onClose = props.onClose
    const onBackdropClick = props.onBackdropClick
    newProps.onClose = (event: SyntheticEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onBackdropClick && onBackdropClick()

            return
        }
        onClose && onClose(event, reason)
    }

    // Removing the props unused by Mui Dialog
    delete newProps.alignment
    delete newProps.size
    delete newProps.onBackdropClick

    return <DialogStyled {...newProps}/>
}

export interface DialogProps extends Omit<MuiDialogProps, 'onBackdropClick'> {
    alignment?: 'top' | 'right' | 'bottom' | 'left' | 'centered'
    size?: 'small' | 'normal'
    onBackdropClick?: () => void
}

export const DialogTitle: React.FunctionComponent<TypographyProps> = (props) => {
    const alignmentClass = props.centered ? 'dialog-text-centered' : ''
    const className = props.className ? props.className.split(' ').concat(alignmentClass).join(' ') : alignmentClass
    const variant = props.variant ? props.variant : 'h5'
    const newProps = { ...props, variant, className }

    delete newProps.centered

    return <DialogTitleStyled {...newProps}/>
}
export const DialogText: React.FunctionComponent<{ centered?: boolean } & React.ComponentProps<typeof DialogTextStyled>> = (props) => {
    const alignmentClass = props.centered ? 'dialog-text-centered' : ''
    const className = props.className ? props.className.split(' ').concat(alignmentClass).join(' ') : alignmentClass

    return <DialogTextStyled {...props} className={className}/>
}

interface TypographyProps extends MuiTypographyProps {
    centered?: boolean
}
interface TypographyProps extends SpacingProps {}


export const DialogButtonContainer: React.FunctionComponent<DialogButtonContainerProps> = (props) => {
    const alignmentClasses: string[] = []
    if (props.verticallyStacked) {
        alignmentClasses.push('dialog-buttons-stacked')
    }
    if (props.centered) {
        alignmentClasses.push('dialog-buttons-centered')
    }

    const className = props.className ? props.className.split(' ').concat(alignmentClasses).join(' ') : alignmentClasses.join(' ')
    const newProps = { ...props, className }

    delete newProps.verticallyStacked
    delete newProps.centered

    return <DialogButtonContainerStyled {...newProps}/>
}

interface DialogButtonContainerProps {
    verticallyStacked?: boolean
    centered?: boolean
    className?: string
}

export const DialogImageContainer = DialogImageContainerStyled

export const DialogToolbar = DialogToolbarStyled

export const DialogToolbarTitle: React.FunctionComponent<TypographyProps> = (props) => {
    const variant = props.variant ? props.variant : 'h6'
    const newProps = { ...props, variant }

    return <DialogToolbarTitleStyled {...newProps}/>
}

export const DialogCloseIcon = DialogCloseIconStyled

export interface CustomDialogProps {
    triggerIcon: React.ReactNode
    showModal: boolean
    title: string
    content: React.ReactNode | string
    btnOnClick: () => void
    btnText: string
}
