import { useCallback, useRef } from 'react'
import debounce from 'lodash/debounce'

type CallbackFunction<T extends any[]> = (...args: T) => void

export const useDebouncedCallback = <T extends any[]>(callback: CallbackFunction<T>, delay: number): CallbackFunction<T> => {
    const callbackRef = useRef(callback)

    // Update the ref with the latest callback on each render
    callbackRef.current = callback

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    return useCallback(
        debounce((...args: T) => {
            callbackRef.current(...args)
        }, delay),
        [delay],
    )
}
