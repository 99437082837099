import React from 'react'
import { Box } from '@material-ui/core'
import { StyledBox, StyledTitleTypography } from '@/app/ui-new/pages/website/components/OldVsNewWay/Desktop/OldWayHeader.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './OldVsNewWayDesktop.i18n'

const OldWayHeader: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    return (
        <Box height={80} display={'flex'} flexDirection={'row'} >
            <StyledBox>
                <StyledTitleTypography variant={'h5'}>{t('Old Way')}</StyledTitleTypography>
            </StyledBox>
            <Box bgcolor={'#717171'} ml={2} width={32} height={'100%'}/>
            <Box bgcolor={'#858585'} ml={3} width={32} height={'100%'}/>
            <Box bgcolor={'#999999'} ml={4} width={16} height={'100%'}/>
            <Box bgcolor={'#999999'} ml={5} width={8} height={'100%'}/>
        </Box>
    )
}

export default OldWayHeader
