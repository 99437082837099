import React, { useState } from 'react'
import { Accordion as AccordionSimple, AccordionDetails, AccordionSummary } from './Accordion.styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Grid, Typography } from '@material-ui/core'
import { BadgeText } from '../Badge/Badge.styled'
import { StyledAccordion, StyledQuestionCardHeader } from './AccordionSession.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface AccordionProps {
    children?: React.ReactElement
    title?: string
    component?: React.ReactElement
    isExpanded: boolean
    accordionDisabled?: boolean
    badge?: { total?: number; color?: string }
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
    handleExpansion: () => void
    nameID?: string
    error?: boolean
    completed?: boolean
    disabled?: boolean
    endLabel?: string
    action?: React.ReactNode
}

interface ShowBadgeProps {
    children: React.ReactNode
    badge?: { total?: number; color?: string }
}

const AccordionSession: React.FC<AccordionProps> = ({
    children,
    badge,
    title,
    component,
    isExpanded = true,
    accordionDisabled = false,
    nameID,
    error = false,
    completed = true,
    disabled = false,
    endLabel,
    action,
    setIsExpanded,
    handleExpansion,
}) => {
    return (
        <StyledAccordion square expanded={isExpanded} onChange={handleExpansion} disabled={accordionDisabled}>
            <AccordionSummary aria-controls='panel1a-content' id='panel1a-header3'>
                <StyledQuestionCardHeader
                    titleLabel={
                        // eslint-disable-next-line max-len
                        title
                    }
                    endLabel={endLabel ? endLabel : ''}
                    endLabelInteractable
                    endIcon={<FontAwesomeIcon icon={['far', 'check-circle']} />}
                    error={error}
                    completed={completed}
                    disabled={disabled}
                    action={action}
                />
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </StyledAccordion>
    )
}

export default AccordionSession
