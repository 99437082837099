import React from 'react'
import {
    Container,
    BigText,
    TealText,
    SmallText,
    PrimaryButton,
    TextButton,
    // IconButton,
} from './QuoteFasterShipSmarterMobile.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './QuoteFasterShipSmarterMobile.i18n'

export const QuoteFasterShipSmarterMobile: React.FunctionComponent<QuoteFasterShipSmarterProps> = (props) => {
    const { t } = useI18n(i18n)

    return <Container>
        <BigText>
            {t('Quote')} <TealText>{t('faster')}</TealText>.
        </BigText>
        <BigText>
            {t('Ship')} <TealText>{t('smarter')}</TealText>.
        </BigText>
        <SmallText>{t('Start saving today with the most intelligent shipping platform for businesses.')}</SmallText>
        <PrimaryButton size='large' href='/register'>{t('Get Lazr for free')}</PrimaryButton>
        {/* <TextButton variant='outlined' size='large' href='/'>{t('Talk to our team')}</TextButton> */}

        {/* <IconButton preserveIconColors href='/'>
            <img alt='Help' src='/images/website/Ninja.svg'/>
        </IconButton> */}

    </Container>
}

export interface QuoteFasterShipSmarterProps{
}
