import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'
import { Grid as MuiGrid } from '@material-ui/core'
import { IconButton as StyledIconButton } from '@/app/ui-new/components/IconButton/IconButton'

export const BackgroundGradientLayer0 = styled(styled.div(spacing))`
    width: 375px;
    max-width: 375px;
    position: relative;
    opacity: 0.9;
`

export const BackgroundGradientLayer1 = styled.div`
    background: linear-gradient(to top, ${(props): number => props.theme.palette.accentSecondary.main},
        ${(props): number => props.theme.palette.accentPrimary.main});
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    clip-path: polygon(50% 15%, 100% 30%, 100% 85%, 5% 95%, 40% 60%, 0 30%);
`

export const BackgroundGradientLayer2 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
`

export const BackgroundGradientLayer3 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.7;
`

export const BackgroundGradientLayer4 = styled.div`
    backdrop-filter: blur(20px);
    padding: ${(props): string => props.theme.spacing(12)}px
        ${(props): string => props.theme.spacing(4)}px
        ${(props): string => props.theme.spacing(8)}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const FlipCardsTitle = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    max-width: 1110px;
    padding-bottom: ${(props): string => props.theme.spacing(12.5)}px;
    font-family: 'Termina';
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
`

export const CardsContainer = styled(styled.div(spacing))`
    width: 100%;
    & > div > div{
        display: flex;
        justify-content: center;
        width: 100%;
    }
`

export const Grid = styled(styled(MuiGrid)(spacing))``

export const CardOuter = styled(styled.div(spacing))`
    background-color: transparent;
    width: 100%;
    height: fit-content;
`

export const CardInner = styled(styled.div(spacing))`
    width: 100%;
`

export const Side = styled(styled.div(spacing))`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: ${(props): number => props.theme.palette.white.main};
    border-radius: 16px;
    overflow: hidden;

    & > button{
        padding: ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(4)}px
            ${(props): string => props.theme.spacing(6)}px;

        & > div{
            & div:nth-child(2){
                display: flex;
                align-items: center;
                min-height: 2.6rem
            }

            & div:last-child{
                display: flex;

                & button{
                    padding: 0;
                    border: 0;
                    width: 24px;
                    height: 24px;
                    font-size: 1rem;
                }
            }
        }
    }

    & > div:nth-child(2){
        padding: ${(props): string => props.theme.spacing(4)}px
            ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(6)}px;
        border-top: 1px dotted ${(props): number => props.theme.palette.neutral100.main};
    }

    & > div[class*='MuiCollapse-hidden']{
        padding: 0;
    }
`

export const IconButton = styled(StyledIconButton)`
    transition: all 0.3s;
    &.rotated{
        transform: rotate(-180deg);
    }
`

export const ClickZone = styled.button`
    background-color: transparent;
    width: 100%;
    border: 0;
    cursor: pointer;
    text-align: left;
`

export const ImageContainer = styled(styled.div(spacing))`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 4px solid ${(props): number => props.theme.palette.white.main};
    overflow: hidden;

    & img{
        width: 66px;
        height: 66px;
    }
`

export const Typography1 = styled(styled(MuiTypography)(spacing))`
    font-weight: 500;
    font-size: 1.1428rem;
`

export const Typography2 = styled(styled(MuiTypography)(spacing))`
    font-weight: 700;
    font-size: 1.2857rem;
    line-height: 125%;
`

export const Typography3 = styled(styled(MuiTypography)(spacing))`
    font-weight: 500;
    text-align: left;
    font-size: 1.43rem;
`

export const Typography4 = styled(styled(MuiTypography)(spacing))`
    text-align: left;
    line-height: 1.4rem;
    letter-spacing: -0.01rem;
`

export const KeyPointsContainer = styled(styled.div(spacing))`
    flex-grow: 1;
`

export const IconContainer = styled(styled.div(spacing))`
    width: 50px;
    min-width: 50px;
    text-align: left;
`

export const KeyPointItem = styled(styled.div(spacing))`
    display: flex;
`
