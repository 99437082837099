import { UuidV4, OrganizationType } from '@lazr/openapi-client'

export interface OtherOrganizationProps {
    id: UuidV4
    name: string
    description: string
    type: OrganizationType
    updatedAt: string | null
    createdAt: string | null
    adminUserId: UuidV4 | null
}

export class OtherOrganization {
    public id: UuidV4
    public name: string
    public description: string
    public type: OrganizationType
    public updatedAt: string | null
    public createdAt: string | null
    public adminUserId: UuidV4 | null

    constructor (props: OtherOrganizationProps) {
        this.id = props.id
        this.name = props.name
        this.description = props.description
        this.type = props.type
        this.updatedAt = props.updatedAt
        this.createdAt = props.createdAt
        this.adminUserId = props.adminUserId
    }
}
