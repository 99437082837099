/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EquipmentType {
    DRYBOX = 'DRYBOX',
    REEFER = 'REEFER',
    FLATBED = 'FLATBED',
    FLATBED_ROLL_TITE = 'FLATBED_ROLL_TITE',
    SPECIALIZED = 'SPECIALIZED',
    OTHER = 'OTHER',
}