/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAccountManagerRequest } from '../models/AddAccountManagerRequest';
import type { AssignThreePlAgentRequest } from '../models/AssignThreePlAgentRequest';
import type { ChangeUserAvatarRequest } from '../models/ChangeUserAvatarRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { ConfirmSignUpRequest } from '../models/ConfirmSignUpRequest';
import type { DeleteUserBillingAddressRequest } from '../models/DeleteUserBillingAddressRequest';
import type { EditUserProfileRequest } from '../models/EditUserProfileRequest';
import type { RequestPasswordResetRequest } from '../models/RequestPasswordResetRequest';
import type { ResendConfirmationCodeRequest } from '../models/ResendConfirmationCodeRequest';
import type { SaveBillToAddressRequest } from '../models/SaveBillToAddressRequest';
import type { SaveUserLaneRequest } from '../models/SaveUserLaneRequest';
import type { SuccessResponse_AddAccountManagerResponse_ } from '../models/SuccessResponse_AddAccountManagerResponse_';
import type { SuccessResponse_AssignThreePlAgentResponse_ } from '../models/SuccessResponse_AssignThreePlAgentResponse_';
import type { SuccessResponse_ChangeUserAvatarResponse_ } from '../models/SuccessResponse_ChangeUserAvatarResponse_';
import type { SuccessResponse_ConfirmPasswordResetResponse_ } from '../models/SuccessResponse_ConfirmPasswordResetResponse_';
import type { SuccessResponse_ConfirmSignUpResponse_ } from '../models/SuccessResponse_ConfirmSignUpResponse_';
import type { SuccessResponse_DeleteUserBillingAddressResponse_ } from '../models/SuccessResponse_DeleteUserBillingAddressResponse_';
import type { SuccessResponse_DeleteUserLaneResponse_ } from '../models/SuccessResponse_DeleteUserLaneResponse_';
import type { SuccessResponse_EditUserProfileResponse_ } from '../models/SuccessResponse_EditUserProfileResponse_';
import type { SuccessResponse_GetAccountManagerResponse_ } from '../models/SuccessResponse_GetAccountManagerResponse_';
import type { SuccessResponse_GetBillToByUserIdResponse_ } from '../models/SuccessResponse_GetBillToByUserIdResponse_';
import type { SuccessResponse_GetCurrentUserResponse_ } from '../models/SuccessResponse_GetCurrentUserResponse_';
import type { SuccessResponse_GetLanesByUserIdResponse_ } from '../models/SuccessResponse_GetLanesByUserIdResponse_';
import type { SuccessResponse_GetLinkedUsersResponse_Array_ } from '../models/SuccessResponse_GetLinkedUsersResponse_Array_';
import type { SuccessResponse_GetSalesRepResponse_ } from '../models/SuccessResponse_GetSalesRepResponse_';
import type { SuccessResponse_GetThreePlResponse_ } from '../models/SuccessResponse_GetThreePlResponse_';
import type { SuccessResponse_GetUserByIdResponse_ } from '../models/SuccessResponse_GetUserByIdResponse_';
import type { SuccessResponse_GetUsersResponse_ } from '../models/SuccessResponse_GetUsersResponse_';
import type { SuccessResponse_RequestPasswordResetResponse_ } from '../models/SuccessResponse_RequestPasswordResetResponse_';
import type { SuccessResponse_ResendConfirmationCodeResponse_ } from '../models/SuccessResponse_ResendConfirmationCodeResponse_';
import type { SuccessResponse_SaveBillToAddressResponse_ } from '../models/SuccessResponse_SaveBillToAddressResponse_';
import type { SuccessResponse_SaveUserLaneResponse_ } from '../models/SuccessResponse_SaveUserLaneResponse_';
import type { UserRole } from '../models/UserRole';
import type { UserStatus } from '../models/UserStatus';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Add an account manager for your organization.
     * @param requestBody The data
     * @returns SuccessResponse_AddAccountManagerResponse_ OK
     * @throws ApiError
     */
    public static async addAccountManager(
        requestBody: AddAccountManagerRequest,
    ): Promise<SuccessResponse_AddAccountManagerResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/add-account-manager`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Assign a 3PL agent to an order
     * @param requestBody Order and agent IDs
     * @returns SuccessResponse_AssignThreePlAgentResponse_ OK
     * @throws ApiError
     */
    public static async assignThreePlAgent(
        requestBody: AssignThreePlAgentRequest,
    ): Promise<SuccessResponse_AssignThreePlAgentResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/assign-three-pl-agent-to-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Change user avatar
     * @param requestBody The data
     * @returns SuccessResponse_ChangeUserAvatarResponse_ OK
     * @throws ApiError
     */
    public static async changeUserAvatar(
        requestBody: ChangeUserAvatarRequest,
    ): Promise<SuccessResponse_ChangeUserAvatarResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/change-user-avatar`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Request a password reset for a specific user
     * @param requestBody The data
     * @returns SuccessResponse_ConfirmPasswordResetResponse_ OK
     * @throws ApiError
     */
    public static async confirmPasswordReset(
        requestBody: ConfirmPasswordResetRequest,
    ): Promise<SuccessResponse_ConfirmPasswordResetResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/confirm-password-reset`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Confirms sign up for a specific user
     * @param requestBody The data
     * @returns SuccessResponse_ConfirmSignUpResponse_ OK
     * @throws ApiError
     */
    public static async confirmSignUp(
        requestBody: ConfirmSignUpRequest,
    ): Promise<SuccessResponse_ConfirmSignUpResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/confirm-signup`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete a user billing address by billing address id
     * @param requestBody The data
     * @returns SuccessResponse_DeleteUserBillingAddressResponse_ OK
     * @throws ApiError
     */
    public static async deleteUserBillingAddress(
        requestBody: DeleteUserBillingAddressRequest,
    ): Promise<SuccessResponse_DeleteUserBillingAddressResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-user-billing-address`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete a user lane for a specific organization
     * @param id The lane id to delete
     * @returns SuccessResponse_DeleteUserLaneResponse_ OK
     * @throws ApiError
     */
    public static async deleteUserLane(
        id: UuidV4,
    ): Promise<SuccessResponse_DeleteUserLaneResponse_> {
        const result = await __request({
            method: 'DELETE',
            path: `/delete-user-lane/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit the profile of the user currently logged in
     * @param requestBody The data
     * @returns SuccessResponse_EditUserProfileResponse_ OK
     * @throws ApiError
     */
    public static async editUserProfile(
        requestBody: EditUserProfileRequest,
    ): Promise<SuccessResponse_EditUserProfileResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-user-profile`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Request a password reset for a specific user
     * @param requestBody The data
     * @returns SuccessResponse_RequestPasswordResetResponse_ OK
     * @throws ApiError
     */
    public static async requestPasswordReset(
        requestBody: RequestPasswordResetRequest,
    ): Promise<SuccessResponse_RequestPasswordResetResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/request-password-reset`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Resents confirmation code for a specific user
     * @param requestBody The data
     * @returns SuccessResponse_ResendConfirmationCodeResponse_ OK
     * @throws ApiError
     */
    public static async resendConfirmationCode(
        requestBody: ResendConfirmationCodeRequest,
    ): Promise<SuccessResponse_ResendConfirmationCodeResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/resend-confirmation-code`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Register one or multiple user bill to addresses for a specific organization
     * @param requestBody The data
     * @returns SuccessResponse_SaveBillToAddressResponse_ OK
     * @throws ApiError
     */
    public static async saveBillToAddress(
        requestBody: SaveBillToAddressRequest,
    ): Promise<SuccessResponse_SaveBillToAddressResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/save-bill-to-addresses`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Register a user lane for a specific organization
     * @param requestBody The data
     * @returns SuccessResponse_SaveUserLaneResponse_ OK
     * @throws ApiError
     */
    public static async saveUserLane(
        requestBody: SaveUserLaneRequest,
    ): Promise<SuccessResponse_SaveUserLaneResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/save-user-lane`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get account manager
     * @returns SuccessResponse_GetAccountManagerResponse_ OK
     * @throws ApiError
     */
    public static async getAccountManager(): Promise<SuccessResponse_GetAccountManagerResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/account-manager`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get all user bill to addresses by organization id
     * @param organizationId The ID of the organization
     * @param addressesId
     * @returns SuccessResponse_GetBillToByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getBillToByOrganizationId(
        organizationId: string,
        addressesId: Array<string>,
    ): Promise<SuccessResponse_GetBillToByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/bill-to-by-organization-id`,
            query: {
                'organizationId': organizationId,
                'addressesId': addressesId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get all user bill to addresses by user id
     * @param userId The ID of the user to get
     * @param organizationId The ID of the organization
     * @returns SuccessResponse_GetBillToByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getBillToByUserId(
        userId: string,
        organizationId: string,
    ): Promise<SuccessResponse_GetBillToByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/bill-to-by-user-id/${userId}/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get your own user
     * @returns SuccessResponse_GetCurrentUserResponse_ OK
     * @throws ApiError
     */
    public static async getCurrentUser(): Promise<SuccessResponse_GetCurrentUserResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/current-user`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get all user lanes by organization id
     * @param organizationId The ID of the organization to get
     * @param addressesId
     * @returns SuccessResponse_GetLanesByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getLanesByOrganizationId(
        organizationId: string,
        addressesId: Array<string>,
    ): Promise<SuccessResponse_GetLanesByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/lanes-by-organzation-id`,
            query: {
                'organizationId': organizationId,
                'addressesId': addressesId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get all user lanes by user id
     * @param userId
     * @param organizationId
     * @returns SuccessResponse_GetLanesByUserIdResponse_ OK
     * @throws ApiError
     */
    public static async getLanesByUserId(
        userId: string,
        organizationId: string,
    ): Promise<SuccessResponse_GetLanesByUserIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/lanes-by-user-id/${userId}/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of users linked with the calling user
     * @returns SuccessResponse_GetLinkedUsersResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLinkedUsers(): Promise<SuccessResponse_GetLinkedUsersResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/get-linked-users`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get sales representatives
     * @returns SuccessResponse_GetSalesRepResponse_ OK
     * @throws ApiError
     */
    public static async getSalesRepresentative(): Promise<SuccessResponse_GetSalesRepResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/sales-rep`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get threePL agents
     * @param organizationId The organization ID
     * @returns SuccessResponse_GetThreePlResponse_ OK
     * @throws ApiError
     */
    public static async getThreePlAgents(
        organizationId: UuidV4,
    ): Promise<SuccessResponse_GetThreePlResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/three-pl-agents/${organizationId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a user by its ID
     * @param userId The user ID
     * @returns SuccessResponse_GetUserByIdResponse_ OK
     * @throws ApiError
     */
    public static async getUserById(
        userId: UuidV4,
    ): Promise<SuccessResponse_GetUserByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/user-by-id/${userId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of users for an organization
     * @param page
     * @param resultsPerPage
     * @param orderBy
     * @param order
     * @param name
     * @param role
     * @param clientOrganizationId
     * @param status
     * @returns SuccessResponse_GetUsersResponse_
     * @throws ApiError
     */
    public static async getUsers(
        page?: number,
        resultsPerPage?: number,
        orderBy?: 'name' | 'role' | 'status' | 'email' | 'phoneNumber' | 'organizationName' | 'jobTitle' | 'lastActivity' | 'activityStatus',
        order?: 'asc' | 'desc',
        name?: string,
        role?: UserRole,
        clientOrganizationId?: UuidV4,
        status?: Array<UserStatus>,
    ): Promise<SuccessResponse_GetUsersResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/users`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'orderBy': orderBy,
                'order': order,
                'name': name,
                'role': role,
                'clientOrganizationId': clientOrganizationId,
                'status': status,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}