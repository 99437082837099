import React from 'react'
import { EventType, OrganizationType } from '@lazr/openapi-client'
import { useDispatch, useSelector } from 'react-redux'
import { logger } from '../../../logger'
import { Organization, User } from '../../../model'
import { OrganizationApiService } from '../../../service/ApiService'
import { setOrganizationList as reduxSetOrganizationList } from '../../redux/actions/OrganizationListActions'
import {
    getOrganizationList as reduxGetOrganizationList,
} from '../../redux/selectors/AppStateSelectors'
import SubscriptionService from '../../../service/SubscriptionService'
import { Dispatch } from 'redux'

export const useOrganizationList = (user: User, userOrganization: Organization | null, isImpersonatingRole: boolean) => {
    const dispatch = useDispatch()

    const { organizationList, isOrganizationListFetched } = useSelector(reduxGetOrganizationList)

    React.useEffect(() => {
        void (async (): Promise<void> => {
            try {
                if (!user || isOrganizationListFetched) {
                    return
                }
                await getOrganizationList(userOrganization, isImpersonatingRole, dispatch)
            } catch (error: any) {
                logger.error('could not fetch organization list', error)
            }
        })()
    }, [ isImpersonatingRole, user, userOrganization, dispatch ])


    React.useEffect((): () => void => {
        const subscription = SubscriptionService.subscribe(
            [
                EventType.ORGANIZATION_DELETED,
                EventType.ORGANIZATION_REGISTERED,
            ],
            async () => {
                if (!user) {
                    return
                }

                await getOrganizationList(userOrganization, isImpersonatingRole, dispatch)
            },
        )

        return subscription.destroy
    }, [ isImpersonatingRole, user, userOrganization, dispatch ])

    return [ organizationList ]
}

const getOrganizationList = async (userOrganization: Organization | null, isImpersonatingRole: boolean, dispatch: Dispatch<any>) => {
    if (userOrganization?.isSystem() || (!userOrganization?.isSystem() && isImpersonatingRole)) {
        const fetchedOrganizationList = (await OrganizationApiService.list()).organizations
            .filter((organization) => organization.type !== OrganizationType.SYSTEM)
        dispatch(reduxSetOrganizationList(fetchedOrganizationList))
    } else {
        dispatch(reduxSetOrganizationList([]))
    }
}
