/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './BannerDesktop.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Create your free Account`]: `Créer votre compte gratuitement`,
    [`Start using Lazr for free and explore all the potential carriers and pricing for your shipments. No contracts or credit card required.`]: `Commencez à utiliser Lazr gratuitement et explorez toutes les possibilités de transporteurs potentiels ainsi que les tarifs pour vos expéditions. Aucun contrat ni carte de crédit requis.`,
    [`Ship better in every way`]: `Expédiez plus facilement`,
    [`50+ Connected freight carriers`]: `50+ Transporteurs de marchandises connectés`,
    [`8 Connected parcel carriers`]: `8 Connected parcel carriers`,
    [`21% Average savings in shipping costs`]: `21% Économies moyennes sur les frais d'expédition`,
    [`95% Faster than traditional quoting`]: `95% Plus rapide que les demandes traditionnelles de transport`,
}))()
