import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { Snackbar } from '../../definitions/Snackbar'
import { initialSnackbar } from '../store/InitialState'

const reducer: Reducer = (state: Snackbar = initialSnackbar, action: AnyAction) => (
    action.type === types.SET_SNACKBAR
        ? (action.payload ? action.payload : initialSnackbar)
        : state
)

export default reducer
