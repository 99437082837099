import React from 'react'
import {
    Fieldset,
    Legend,
} from './LabelledOutline.styled'

const LabelledOutline: React.FunctionComponent<Props> = ({ id, label, className, children }) => (
    <Fieldset
        className={className}
        id={id}
    >
        <Legend>
            {label}
        </Legend>
        {children}

    </Fieldset>
)

export interface Props {
    id?: string
    label?: React.ReactNode
    className?: string

}
export default LabelledOutline
