import React, { useEffect, useMemo, useState } from 'react'
import Tooltip, { CustomTooltipProps } from '../../Tooltip/Tooltip'
import LabelledOutline from '../../LabelledOutline/LabelledOutline'
import { GridTooltipFlex, QuestionIcon } from '../../Tooltip/Tooltip.styled'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface LabelledOutlineBoxProps {
    labelName?: string
    tooltip?: CustomTooltipProps
    hideOutBox?: boolean
}

const LabelledOutlineBox: React.FC<LabelledOutlineBoxProps> = ({ labelName, tooltip, hideOutBox, children }) => {
    return (
        <>
            {hideOutBox ? (
                children
            ) : (
                <LabelledOutline
                    label={
                        <GridTooltipFlex>
                            <Typography variant='body1'>{labelName}</Typography>
                            {tooltip && (
                                <Tooltip {...tooltip}>
                                    <QuestionIcon>
                                        <FontAwesomeIcon icon={tooltip?.icon ? tooltip?.icon : ['far', 'question-circle']} />
                                    </QuestionIcon>
                                </Tooltip>
                            )}
                        </GridTooltipFlex>
                    }
                >
                    {children}
                </LabelledOutline>
            )}
        </>
    )
}

export default LabelledOutlineBox
