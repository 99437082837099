import styled from 'styled-components'
import {
    Box as MuiBox, ButtonProps, Typography, TypographyProps,
} from '@material-ui/core'
import React from 'react'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'

export const Wrapper = styled(MuiBox)`
    width: 100%;
    background-color: #ECEEF2;
    display: flex;
    justify-content: center;
    padding-top: ${(props): string => props.theme.spacing(17.5)}px;
    padding-bottom: ${(props): string => props.theme.spacing(17.5)}px;
`

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    color: ${(props): string => props.theme.palette.neutral800.main};
`

export const StyledSubTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.25rem;
    font-weight: 400;
    color: ${(props): string => props.theme.palette.neutral800.main};
    line-height: 130%;
`

export const StyledPrimaryButton: React.FC<ButtonProps> = styled(PrimaryButton)<ButtonProps>`
    background-color: ${(props): number => props.theme.palette.accentSecondary.main};
    color: ${(props): number => props.theme.palette.white.main};
    &:hover {
        background: ${(props): number => props.theme.palette.accentSecondary400.main};
    }

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }
`

export const StyledSecondaryButton: React.FC<ButtonProps> = styled(SecondaryButton)<ButtonProps>`
    background-color: transparent;
    color: ${(props): number => props.theme.palette.neutral800.main};
    &:hover {
        background: ${(props): number => props.theme.palette.accentSecondary50.main};
    }

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }
`

export const CharacterImg = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
`

export const ListItemImg1 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-1 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 6.25s;

    @keyframes list-item-1 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        to {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
    }
`

export const ListItemImg2 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-2 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 5s;

    @keyframes list-item-2 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        50% {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
        to {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
    }
`

export const ListItemImg3 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-3 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 3.75s;

    @keyframes list-item-3 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        50% {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
        75% {
            width: 438px;
            height: 101px;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        to {
            width: 438px;
            height: 101px;

            // position 3
            bottom: 45px;
            right: 137px;
        }
    }
`

export const ListItemImg4 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-4 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;

    @keyframes list-item-4 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        50% {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
        75% {
            width: 438px;
            height: 101px;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        100% {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        to {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
    }
`

export const ListItemImg5 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-5 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1.25s;

    @keyframes list-item-5 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        50% {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
        75% {
            width: 438px;
            height: 101px;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        100% {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        to {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
    }
`

export const ListItemImg6 = styled.img`
    position: absolute;

    width: 0;
    height: 0;

    animation: list-item-6 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes list-item-6 {
        from {
            width: 0;
            height: 0;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        0% {
            width: 0;
            height: 0;
        }
        25% {
            width: 438px;
            height: 101px;

            // position 1
            bottom: 225px;
            right: 154px;
        }
        50% {
            width: 438px;
            height: 101px;

            // position 2
            bottom: 135px;
            right: 202px;
        }
        75% {
            width: 438px;
            height: 101px;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        100% {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
        to {
            width: 0;
            height: 0;

            // position 3
            bottom: 45px;
            right: 137px;
        }
    }
`
