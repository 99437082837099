/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse_GetAppVersionResponse_ } from '../models/SuccessResponse_GetAppVersionResponse_';
import { request as __request } from '../core/request';

export class VersionService {

    /**
     * Get the version
     * @returns SuccessResponse_GetAppVersionResponse_ OK
     * @throws ApiError
     */
    public static async get(): Promise<SuccessResponse_GetAppVersionResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/version`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}