import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Size from Theme
// https://v4.mui.com/customization/breakpoints/#breakpoints
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 960px
// lg, large: 1280px
// xl, extra-large: 1920px


export interface SizeScreenProps {
    sizeScreen:{
        xs: boolean
        sm: boolean
        md: boolean
        lg: boolean
        xl: boolean
    }
}

const useSizeScreen = ():SizeScreenProps  => {
    const theme = useTheme()
    const xs = useMediaQuery(theme.breakpoints.up('xs'))
    const sm = useMediaQuery(theme.breakpoints.up('sm'))
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const xl = useMediaQuery(theme.breakpoints.up('xl'))

    return {
        sizeScreen: { xs, sm, md, lg, xl },
    }
}

export default useSizeScreen
