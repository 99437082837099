import axios from 'axios'
import fileDownload from 'js-file-download'

import { ApiError, DocumentType, ErrorResponse, OrderEditingService, OrderInfoService, UuidV4 } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export interface DocumentAttributes {
    orderId: UuidV4
    name: string
    documentType: DocumentType
    base64data: string
    isGenerated: boolean
    hasBarcode: boolean
    transportInvoiceNumber: string | null
}

const errorBuilder = (error: Error, defaultMessage: string): ErrorResponse['error'] => {
    if (error instanceof ApiError) {
        const errorResponse = error.body as ErrorResponse

        return errorResponse.error
    }

    return { message: defaultMessage }
}

export const DocumentApiService = Object.freeze({
    create: async (documentAttribute: DocumentAttributes): Promise<UuidV4 | ErrorResponse['error']> => {
        try {
            const res =  await OrderEditingService.addDocument({
                orderId: documentAttribute.orderId,
                name: documentAttribute.name,
                type: documentAttribute.documentType,
                file: documentAttribute.base64data,
                hasBarcode: documentAttribute.hasBarcode,
                isGenerated: documentAttribute.isGenerated,
                transportInvoiceNumber: documentAttribute.transportInvoiceNumber,
            })

            return res.data?.documentId
        } catch (error: any) {
            handleUnauthorizedException(error)

            return errorBuilder(error, 'Unable to create the document')
        }
    },
    delete: async (documentID: UuidV4): Promise<void | ErrorResponse['error']> => {
        try {
            await OrderEditingService.removeDocument({ documentId: documentID })
        } catch (error: any) {
            handleUnauthorizedException(error)

            return errorBuilder(error, 'Unable to delete the document')
        }
    },
    downloadByFileName: async (fileName: string, documentType: DocumentType, isClientDocument: boolean):
    Promise<void | ErrorResponse['error']> => {
        try {
            const res = await OrderInfoService.getDocumentByFileName(fileName, documentType, isClientDocument)
            const documentMetaData = res.data?.document
            if (documentMetaData?.signedUrl) {
                void axios.get(documentMetaData?.signedUrl, {
                    responseType: 'blob',
                }).then((blob) => {
                    fileDownload(blob.data, fileName)
                }).catch(() => ({ message: 'Could not download' }))
            } else {
                return { message: 'No URL available to download' }
            }
        } catch (error: any) {
            handleUnauthorizedException(error)

            return errorBuilder(error, 'Unable to download the document')
        }
    },
    download: async (documentID: UuidV4): Promise<void | ErrorResponse['error']> => {
        try {
            const res = await OrderInfoService.getDocumentById(documentID)
            const documentMetaData = res.data?.document
            if (documentMetaData?.signedUrl) {
                void axios.get(documentMetaData?.signedUrl, {
                    responseType: 'blob',
                }).then((blob) => {
                    fileDownload(blob.data, documentMetaData.name)
                }).catch(() => ({ message: 'Could not download' }))
            } else {
                return { message: 'No URL available to download' }
            }
        } catch (error: any) {
            handleUnauthorizedException(error)

            return errorBuilder(error, 'Unable to download the document')
        }
    },
    downloadByUrl: async (signedUrl: string, name: string): Promise<void | ErrorResponse['error']> => {
        try {
            void axios.get(signedUrl, {
                responseType: 'blob',
            }).then((blob) => {
                fileDownload(blob.data, name)
            }).catch(() => ({ message: 'Could not download' }))
        } catch (error: any) {
            handleUnauthorizedException(error)

            return errorBuilder(error, 'Unable to download the document')
        }
    },
})

export default DocumentApiService
