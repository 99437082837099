import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import { Typography as MuiTypography } from '@material-ui/core'
import PrimaryButtonStyled from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import { IconButton as IconButtonStyled } from '@/app/ui-new/components/IconButton/IconButton'
import TextButtonStyled from '@/app/ui-new/components/TextButtons/TextButton'

export const Container = styled(styled.div(MuiSpacing))`
    position: relative;
    width: 360px;
    padding-top: ${(props): string => props.theme.spacing(16.25)}px;
    padding-right:${(props): string => props.theme.spacing(7)}px;
    padding-bottom: ${(props): string => props.theme.spacing(25)}px;
    padding-left:${(props): string => props.theme.spacing(7)}px;
    min-height: 150px;
    background-color: ${(props): number => props.theme.palette.accentPrimary900.main};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & button + button, & a + a, & a + button, button + a{
        margin-top: ${(props): string => props.theme.spacing(4)}px;
    }
`

export const BigText = styled(styled(MuiTypography)(MuiSpacing))`
    font-family: 'Termina';
    font-weight: 700;
    font-size: 2.2857rem;
    line-height: 2.7428rem;
    color: ${(props): number => props.theme.palette.white.main};
`

export const TealText = styled.span`
    color: ${(props): number => props.theme.palette.accentPrimary300.main};
`

export const SmallText = styled(styled(MuiTypography)(MuiSpacing))`
    color: ${(props): number => props.theme.palette.white.main};
    max-width: 652px;
    text-align: center;
    font-size: 1.2142rem;
    line-height: 1.4571rem;
    margin: ${(props): string => props.theme.spacing(12)}px 0;
`

export const PrimaryButton = styled(PrimaryButtonStyled)`
    background-color: ${(props): number => props.theme.palette.black.main};
    color: ${(props): number => props.theme.palette.white.main};
    font-weight: 700;
    width: 100%;
`

export const TextButton = styled(TextButtonStyled)`
    color: ${(props): number => props.theme.palette.white.main};
    border: 1px solid ${(props): number => props.theme.palette.white.main};
    font-weight: 700;
    width: 100%;

    &:hover{
        color: ${(props): number => props.theme.palette.accentPrimary900.main};
    }
`

export const IconButton = styled(IconButtonStyled)`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    bottom: ${(props): number => props.theme.spacing(5.625)}px;
    right: ${(props): number => props.theme.spacing(3)}px;
    border: 2px solid ${(props): number => props.theme.palette.accentSecondary.main};
    width: 56px;
    height: 56px;
`
