import { handleUnauthorizedException } from './index'
import { logger } from '../../logger'
import {
    ApiError,
    ErrorResponse,
    ServiceLevelService,
    UuidV4,
    TransportType,
} from '@lazr/openapi-client'
import { ServiceLevel } from '../../model'

interface Filter {
    carrierId: UuidV4
    transportType?: TransportType
}

export const ServiceLevelApiService = Object.freeze({
    getByCarrierId: async(filters: Filter): Promise<ServiceLevel[]> => {
        let getServiceLevelsResponse
        try {
            getServiceLevelsResponse = await ServiceLevelService.getServiceLevelsByCarrierId(filters.carrierId, filters.transportType)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getServiceLevels', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve Service Levels')
            }
            throw new Error('Unable to retrieve Service Levels')
        }

        if (!getServiceLevelsResponse.data) {
            logger.debug('getServiceLevels', JSON.stringify(getServiceLevelsResponse, null, 4))
            throw new Error('Unable to retrieve Service Levels')
        }

        return getServiceLevelsResponse.data
    },
})
