/* eslint-disable @typescript-eslint/quotes, max-len */
import React from 'react'
import { Language } from '@lazr/enums/src'
import { ValueOf } from '@lazr/types'
import { useQuery } from './useQuery'
import i18n from '../index.i18n'

interface LandingPageQueryString {
    title: keyof typeof MainTitle[Language]
    subtitle: keyof typeof Subtitle[Language]
    button: keyof typeof Button[Language]
    theme: keyof typeof Theme
    testimonials: string
    language: string
    carousel: '1' | '2'
}

interface LandingPageContent {
    title: ValueOf<typeof MainTitle[Language]>
    subtitle: ValueOf<typeof Subtitle[Language]>
    button: ValueOf<typeof Button[Language]>
    theme: 'Teal' | 'Purple'
    testimonials: {
        jobTitle: string
        name: string
        text: string
    }[]
    language: Language
}

export const useContent = (): LandingPageContent => {
    const query = useQuery<LandingPageQueryString>()

    const language = query.language === 'fr' ? Language.FR : Language.EN

    const { t } = i18n.activate(language.toLocaleLowerCase() as 'en' | 'fr')

    const landingPageContent: LandingPageContent = {
        title: MainTitle[language][1],
        subtitle: Subtitle[language][1],
        button: Button[language][1],
        theme: 'Teal',
        testimonials: Array.from({ length: TestimonialText.length }, (_, i) => i + 1).map((num) => ({
            jobTitle: t(Testimonials[num].jobTitle),
            name: t(Testimonials[num].name),
            text: t(Testimonials[num].text),
        })),
        language,
    }

    if (query.title) {
        landingPageContent.title = MainTitle?.[landingPageContent.language]?.[query.title] || landingPageContent.title
    }

    if (query.subtitle) {
        landingPageContent.subtitle = Subtitle?.[landingPageContent.language]?.[query.subtitle] || landingPageContent.subtitle
    }

    if (query.button) {
        landingPageContent.button = Button?.[landingPageContent.language]?.[query.button] || landingPageContent.button
    }

    if (query.theme) {
        landingPageContent.theme = Theme?.[query?.theme] || landingPageContent.theme
    }

    if (query.testimonials) {
        const testimonialsOrder = query.testimonials.split(',')
        let shorterLengthList = testimonialsOrder.length
        const testimonialsLength = Object.keys(Testimonials).length
        if (testimonialsOrder.length > testimonialsLength) {
            shorterLengthList = testimonialsLength
        }

        landingPageContent.testimonials = []
        for (let index = 0; index < shorterLengthList; index++) {
            const testimonialIndex = testimonialsOrder[index]
            const parsedTestimonialIndex = parseInt(testimonialIndex, 10) || index + 1
            const testimonial = Testimonials?.[parsedTestimonialIndex] || Testimonials[index + 1]

            landingPageContent.testimonials.push({
                jobTitle: t(testimonial.jobTitle),
                name: t(testimonial.name),
                text: t(testimonial.text),
            })
        }
    }

    return landingPageContent
}

export const MainTitle = {
    [Language.EN]: {
        1: 'Experience streamlined freight & LTL shipping',
        2: 'Experience streamlined shipping operations',
        3: 'Simplify freight & LTL shipping',
        4: 'Escape the limitations of traditional shipping operations',
        5: 'One simple solution that does it all',
        6: 'Lazr does it all',
        7: 'Shipping costs draining your budgets?',
        8: 'Streamline shipping operations',
        9: 'Diversify carriers & cut costs',
        10: 'Cross-border shipping made easy',
        11: 'Cross-border shipping made simple',
        12: 'Improve shipping operations from start to finish',
        13: 'Get quotes in minutes',
        14: 'Lazr Freight: Ignite Your Shipping Experience!',
        15: 'Lazr Freight: Revolutionizing Shipping with Cutting-Edge Logistics Software',
        16: 'Simplify Your Shipping Operations Today!',
        17: 'Welcome to Lazr Freight - Your Premier Freight Solution',
    },
    [Language.FR]: {
        1: `Simplifiez l'expédition de fret et LTL`,
        2: `Opérations de livraison simplifiées`,
        3: `Expédiez plus facilement`,
        4: `Obtenez des devis en quelques minutes`,
        5: `Une solution simple qui fait tout`,
        6: `Lazr fait tout`,
        7: `Facilitez l'expédition transfrontalière`,
        8: `Simplifiez les opérations d'expédition`,
        9: `Améliorez les opérations d'expédition de A à Z`,
        10: `Lazr Freight - La solution tout-en-un pour l'expedition de marchandise`,
        11: `Simplifiez vos opérations d'expédition dès aujourd'hui!`,
    },
} as const

export const Subtitle = {
    [Language.EN]: {
        1:  <>Elevate your freight shipping game with the LAZR shipping software.<br/><br/>
            As the best B2B shipment service, we blend cost-efficiency with unmatched reliability through our state-of-the-art Online transport tools. Get live quotes and tracking  from all major freight carriers.<br/><br/>
            Whether your LTL freight transport is large or small, Lazr's unique approach ensures precision and timely deliveries for freight transport,  pallet shipping and all other types of shipping.<br/><br/>
            Opt for Lazr's expertise and let us redefine your shipping experience. Discover unmatched efficiency and reliability with us.
        </>,
        2:  <>Searching for unparalleled parcel shipping solutions?<br/><br/>
            You've found your match with Lazr. Our focus is to deliver top-notch, efficient, and budget-friendly parcel B2B shipment services through numerous transport companies.  Get live quotes and tracking  from all major parcel carriers. With our parcel shipping expertise and cutting-edge shipping software, benefit from a smooth, reliable, and on-time delivery of your parcels.<br/><br/>
            Contact us today and discover our exceptional parcel shipping services to and from Canada and the USA that set us apart from the competition!</>,
        3:  <>Book, document, and bill shipments in <strong>under 3 minutes</strong>. Get instant rates from multiple carriers. Track all your shipments in one place.</>,
        4:  <>Experience lightning-fast freight and LTL booking in <strong>under 3 minutes</strong>. Access instant rates from the largest carriers. Enjoy a unified management flow for all your shipments.</>,
        5:  <>Get instant rates from a wide range of carriers and complete booking in <strong>under 3 minutes</strong>. Monitor all your shipments in one place.</>,
        6:  <><strong>Reduce costs</strong> and seamlessly manage it all with our simple solution. Lazr&apos;s is the intelligent shipping assistant that will simplify your cross-border shipping needs and beyond.</>,
        7:  <>Traditional shipping operations drains time & budgets. Lazr&apos;s intelligent shipping assistant cut costs and enables streamlined management. Get quotes from within our <strong>50+ freight and LTL carriers</strong> in minutes.</>,
        8:  <>Lazr&apos;s intelligent shipping assistant enables streamlined management in one simple solution 24/7. Get quotes from within our  <strong>50+ freight and LTL carriers in minutes</strong>.</>,
        9:  <>Lazr&apos;s clients <strong>save an average of 21% in costs</strong> each year. Get instant quotes from our 50+ trusted freight and LTL carriers. Streamline your operations with Lazr&apos;s ready-to-use platform.</>,
        10:  <><strong>Save 21% on costs</strong>. Lazr leverages it&apos;s volume-based contracts with 50+ trusted LTL carriers for your benefit. Within minutes, you&apos;ll find new shipping services and get instant quotes. </>,
        11:  <>Lazr&apos;s intelligent shipping assistant gets quotes within it&apos;s pool of <strong>50+ trusted carriers</strong>. It automatically generates the necessary documents for each unique shipment and handles all the necessary post-delivery checklist. </>,
        12: <>Lazr&apos;s intelligent shipping assistant simplifies the process when managing cross-border freight and LTL shipments. Get quotes from our <strong>50+ trusted carriers</strong> in minutes, and efficiently manage every shipping operation from start to finish in one platform.</>,
        13: <>Lazr&apos;s intelligent shipping assistant gets quotes within its pool of <strong>50+ trusted carriers</strong>. It automatically generates the necessary documents for each unique shipment and handles all the necessary post-delivery checklist. </>,
        14: <>Lazr is the assistant you&apos;ve been hoping for. Simplify every step of the shipping process. Get quotes in <strong>under 3 minutes</strong> from our trust 50+ carriers. Get support from Lazr from start to finish thanks to it&apos;s automated steps.</>,
        15: <>Lazr is the <strong>ultimate logistics solution</strong> at your fingertips. Streamline your entire shipping process within one platform.</>,
        16: <>Welcome to the Future with Lazr Freight - Where Shipping Becomes a Superpower!<br/><br/>
            Embark on a shipping adventure like no other with Lazr Freight! Our revolutionary software isn't just a tool; it's your passport
            to mastering the art of Canada-U.S. freight and LTL shipping. Prepare to be amazed as we turn your shipping challenges into
            triumphs.<br/><br/>
            LTL Shipping: Fast, Furious, and Fantastic<br/><br/>
            Master LTL Shipping Effortlessly with Unmatched Simplicity<br/><br/>
            Say farewell to the hassles of LTL shipping. Lazr Freight is your superhero in the shipping world, delivering cost-effective, ultra-reliable solutions for Canada and U.S. routes. Get ready to ship smarter, not harder!<br/><br/>
            Border? What Border? Effortless LTL Shipping from the U.S. to Canada<br/><br/>
            Lazr Freight makes border crossings a breeze. Our platform transforms your LTL shipments from complex puzzles into smooth, budget-friendly journeys. Experience the magic of hassle-free, cross-border shipping!
        </>,
        17: <>Simplify Your Shipping Operations Today!<br/><br/>
            Lazr freight&apos;s shipping and logistics software leads the new era of streamlined shipping by being your ultimate ally to manage
            freight! Using Lazr, your shipping process becomes a breeze – effortless, efficient, and cost-effective.<br/><br/>
            Instant freight quote online: Shipping, simplified<br/>
            The power of Lazr is its capacity to generate online freight quotes in less than 3 minutes. That empowers you to make informed
            decisions on the spot. Now that&apos;s a straightforward solution that gets you results instantly.<br/><br/>
            A variety of shipping possibilities<br/>
            Gain access to North America&apos;s largest freight network, connecting you to 50+ freight carriers and 8 parcel carriers, all within
            one simple shipping app. Discover new shipping services and cut down on shipping costs - an average savings of 21%!<br/><br/>
            Effortless efficiency in minutes<br/>
            Through Lazr, you&apos;re able to complete your booking, documentation, and billing in less than three minutes. Benefit from instant
            shipping rates from the best carriers and access a unified tracking view for all shipments. The marketplace brings together
            every freight and parcel carrier you need in one centralized platform. No more juggling between systems – everything you need is
            right at your fingertips. With Lazr, efficiency is not just a promise – it&apos;s a reality.<br/><br/>
            Ready to give it a try? Transform the way you do freight management by creating your free account on our shipping TMS software today.
        </>,
        18: <>Lazr freight&apos;s shipping and logistics software leads the new era of streamlined shipping by being your ultimate ally to manage freight! Using Lazr, your shipping process becomes a breeze – effortless, efficient, and cost-effective.<br/><br/>
            Instant freight quote online: Shipping, simplified. The power of Lazr is its capacity to generate online freight quotes in less than 3 minutes. That empowers you to make informed decisions on the spot. Now that&apos;s a straightforward solution that gets you results instantly.<br/><br/>
            A variety of shipping possibilities: Gain access to North America&apos;s largest freight network, connecting you to 50+ freight carriers and 8 parcel carriers, all within one simple shipping app. Discover new shipping services and cut down on shipping costs - an average savings of 21%!<br/><br/>
            Effortless efficiency in minutes: Through Lazr, you&apos;re able to complete your booking, documentation, and billing in less than three minutes. Benefit from instant shipping rates from the best carriers and access a unified tracking view for all shipments. The marketplace brings together every freight and parcel carrier you need in one centralized platform. No more juggling between systems – everything you need is right at your fingertips. With Lazr, efficiency is not just a promise – it&apos;s a reality.<br/><br/>
            Ready to give it a try? Transform the way you do freight management by creating your free account on our shipping TMS software today.
        </>,
        19: <>Searching for a freight shipping service that adapts to your unique needs? Lazr is here to surpass your expectations. We&apos;ve
            been doing this for years, this TMS software does it all.<br/><br/>
            Lazr specializes in the efficient and secure transportation of goods for companies. Whether it&apos;s less-than-truckload (LTL)
            shipments or the need for a centralized LTL ground tracking dashboard, we&apos;ve got you covered. Our commitment to delivering
            streamlined freight transportation services positions us as a leader among LTL trucking companies.<br/><br/>
            Why choose Lazr for your LTL shipping and services?<br/><br/>
            ✓ Simplify logistics with our user-friendly ship-by-freight solutions<br/>
            ✓ Access convenient dashboards, consolidating all LTL tracking in one place<br/>
            ✓ Generate LTL freight quotes instantly through our marketplace<br/>
            ✓ Explore diverse freight LTL options, including overnight deliveries<br/>
            ✓ Rely on our years of shipping experience for confident shipments<br/><br/>
            Experience the ease of our integrated services. Whether it&apos;s LTL, FTL, or parcel shipments, we handle it all. Our advanced reporting ensures transparency and provides peace of mind.<br/><br/>
            Lazr is the shipping assistant you never knew you needed: Efficient, reliable, and personalized parcel and freight shipping options are at your fingertips.<br/><br/>
            Give it a try for free. Access everything in minutes. Discover our marketplace of 50+ carriers now!
        </>,
    },
    [Language.FR]: {
        1: <>Améliorez votre service de livraison LTL et d'expédition de fret avec le logiciel d’expédition Lazr.<br/><br/>
            En tant que meilleur service d'expédition B2B, nous combinons rentabilité et fiabilité inégalée grâce à nos outils de transport en ligne à la pointe de la technologie. Obtenez des soumissions instantanées et suivez vos expéditions en temps réel avec tous les principaux transporteurs de fret.<br/><br/>
            Que votre transport de fret LTL soit grand ou petit, l'approche unique de LAZR assure précision et livraisons ponctuelles pour le transport de fret, y compris le transport de palettes et autres types d'expédition de marchandises.<br/><br/>
            Optez pour l'expertise de LAZR et laissez-nous redéfinir votre expérience avec notre réseau de transporteurs LTL. Découvrez une efficacité et une fiabilité inégalée avec nous.</>,
        2: <>Vous cherchez des solutions d'expédition de colis inégalées ?<br/><br/>
            Vous avez trouvé votre partenaire idéal B2B avec Lazr. Nous offrons des services d'expédition de colis efficaces et économiques via toutes les meilleures compagnies de transport.<br/><br/>
            Avec notre expertise en expédition de colis et palettes (LTL) et notre logiciel d'expédition à la fine pointe de la technologie, bénéficiez d'une livraison fluide, fiable et ponctuelle de vos colis.  Obtenez des soumissions de transport  instantanées et suivez vos envois en temps réel avec tous les grands transporteurs de colis et palettes (LTL).<br/><br/>
            Contactez-nous dès aujourd'hui et découvrez nos services exceptionnels d'expédition de marchandise vers et depuis le Canada et les États-Unis qui nous distinguent de la concurrence !</>,
        3: <>Obtenez des tarifs instantanément auprès de plus de 50 transporteurs de confiance. Effectuez une réservation de <strong>fret et LTL en moins de 3 minutes</strong>. Suivez toutes vos expéditions au même endroit 24/7.</>,
        4: <>Réservez vos transports de <strong>fret et LTL en moins de 3 minutes</strong> auprès de 50+ transporteurs Nord-Américains. Profitez des avantages d&apos;avoir un flux de gestion unifié pour toutes vos expéditions avec Lazr.</>,
        5: <>Économisez grace à Lazr. Notre assistant d&apos;expédition intelligent propose en 3 minutes plusieurs devis auprès de nos <strong>50+ transporteurs Nord-Américains</strong>. </>,
        6: <>Lazr est l&apos;assistant d&apos;expédition intelligent <strong>95% plus rapide que les demandes traditionnelles</strong>. Obtenez des devis instantanés auprès de nos 50+ transporteurs de fret et LTL Nord-Américain. </>,
        7: <>Lazr, c&apos;est l&apos;assistant d&apos;expédition intelligent qui simplifiera vos expéditions transfrontalière. <strong>Réduisez les coûts</strong> et gérez vos opérations de livraisons de A à Z. </>,
        8: <>L&apos;assistant d&apos;expédition intelligent Lazr permet une gestion performante et simple de toutes vos opérations de livraison <strong>au même endroit</strong>. </>,
        9: <>Réservez, documentez et facturez les expéditions en <strong>moins de 3 minutes</strong>. Obtenez des tarifs instantanés auprès de plus de 50 transporteurs. </>,
        10: <>Simplifiez vos opérations avec la plateforme prête à utiliser de Lazr. Suivez toutes vos opérations d&apos;expédition Nord-Américaines <strong>au même endroit</strong>.</>,
        11: <>L&apos;utilisation de Lazr <strong>réduit les coûts</strong> et simplifie toutes les opérations de transport. Obtenez des devis auprès de nos 50+ transporteurs de fret et LTL en quelques minutes.</>,
        12: <>Vous cherchez des services d&apos;expédition de marchandise de haute qualité qui répondent à vos besoins spécifiques? Lazr Freight est là pour dépasser vos attentes!<br/><br/>
            Nous sommes spécialisés dans le transport efficace et sûr de marchandises. Que vous ayez des expédition LTL (moins que le chargement d&apos;un camion complet) ou que vous ayez besoin de transport de palettes, nous avons tout ce qu&apos;il vous faut. Notre dévouement à fournir des services de transport LTL de première classe fait de Lazr le plus grand réseau de transporteurs de marchandises en Amérique du Nord<br/><br/>
            Pourquoi choisir Lazr Freight pour la livraison de marchandise ou pour vos besoins en transport de palettes?<br/><br/>
            ✓ Simplifiez votre logistique avec nos solutions faciles d&apos;expédition par fret<br/>
            ✓ Accédez à notre pratique suivi (tracking) et obtenez rapidement des estimations de prix de livraison (quotes)<br/>
            ✓ Explorez un large éventail d&apos;options en expédition de marchandise, y compris l&apos;expédition de palettes et l&apos;expédition de lots partiels<br/>
            ✓ Profitez de notre expertise reconnue et expédiez en toute confiance avec un transporteur de marchandises fiable<br/><br/>
            Découvrez la commodité de nos services intégrés. Tout est géré dans un seul système, et ce en temps réel. Notre système de suivi avancé garantit la transparence et la tranquillité d&apos;esprit.<br/><br/>
            Choisissez Lazr Freight pour le transport de marchandises qui est efficace, fiable et adapté à vos besoins. Votre marchandise est notre priorité!<br/><br/>
            Prêt à commencer? Créez votre compte gratuitement dès aujourd'hui et obtenez des prix en moins de trois minutes. Simplifiez vos opérations de livraison avec Lazr Freight dès aujourd&apos;hui!
        </>,
        13: <>Le logiciel d&apos;expédition et de logistique de Lazr Freight mène la nouvelle ère de l&apos;expédition rationalisée en étant votre
            allié ultime pour gérer la livraison de marchandise. En utilisant Lazr, votre processus d&apos;expédition devient un jeu d'enfant -
            sans effort, efficace et rentable.<br/><br/>
            Devis instantané en ligne : Expédition, simplifiée<br/>
            La puissance de Lazr réside dans sa capacité à générer des devis en ligne en moins de 3 minutes. Cela vous donne le pouvoir de
            prendre des décisions éclairées sur-le-champ. Voilà une solution simple qui vous donne des résultats instantanément.<br/><br/>
            Une variété de possibilités d&apos;expédition<br/>
            Accédez au plus grand réseau de transporteurs en Amérique du Nord, vous reliant à plus de 50 transporteurs de fret et 8
            transporteurs de colis, le tout dans une seule plateforme d&apos;expédition simple. Découvrez de nouveaux services d&apos;expédition et
            réduisez les coûts d&apos;expédition - une économie moyenne de 21 % !<br/><br/>
            Efficacité sans effort en quelques minutes<br/>
            Avec Lazr, vous pouvez effectuer votre réservation, votre gestion documentaire pour les douanes et votre facturation en moins de 3 minutes. Bénéficiez de tarifs d&apos;expédition instantanés des meilleurs transporteurs et accédez à une vue de suivi unifiée pour tous les envois. Le marketplace Lazr réunit tous les transporteurs de fret, de palettes et de colis dont vous avez besoin sur une plateforme centralisée. Fini les jongleries entre les systèmes - tout ce dont vous avez besoin est à portée de main. Avec Lazr, l'efficacité n&apos;est pas seulement une promesse - c&apos;est une réalité.<br/><br/>
            Prêt à essayer ? Transformez la manière dont vous gérez le fret en créant dès aujourd&apos;hui votre compte gratuit sur Lazr!
        </>,
    },
} as const

export const Button = {
    [Language.EN]: {
        1: {
            text: 'Ready to see it in action? Start Now!',
            type: 'SignUp',
        },
        2: {
            text: 'Explore Lazr Now',
            type: 'SignUp',
        },
        3: {
            text: 'Get A Quote Now',
            type: 'SignUp',
        },
        4: {
            text: 'Get Lazr For Free',
            type: 'SignUp',
        },
        5: {
            text: 'Get Quotes Now',
            type: 'SignUp',
        },
        6: {
            text: 'Register And Get Quotes Now',
            type: 'SignUp',
        },
        7: {
            text: 'Try it now',
            type: 'SignUp',
        },
        8: {
            text: 'Book a 30 minutes demo',
            type: 'RequestDemo',
        },
        9: {
            text: 'Schedule a 30 minutes demo',
            type: 'RequestDemo',
        },
        10: {
            text: 'Secure a demo now',
            type: 'RequestDemo',
        },
        11: {
            text: 'Free 30 minutes demo',
            type: 'RequestDemo',
        },
        12: {
            text: 'Request a demo',
            type: 'RequestDemo',
        },
        13: {
            text: 'Book a custom demo',
            type: 'RequestDemo',
        },
    },
    [Language.FR]: {
        1: {
            text: `Prêt à le voir en action? Commencez maintenant!`,
            type: 'SignUp',
        },
        2: {
            text: `Explorez Lazr dès maintenant`,
            type: 'SignUp',
        },
        3: {
            text: `Obtenez un devis maintenant`,
            type: 'SignUp',
        },
        4: {
            text: `Obtenez Lazr gratuitement`,
            type: 'SignUp',
        },
        5: {
            text: `Obtenez des devis maintenant`,
            type: 'SignUp',
        },
        6: {
            text: `Inscrivez-vous et obtenez des devis maintenant`,
            type: 'SignUp',
        },
        7: {
            text: `Essayez-le maintenant`,
            type: 'SignUp',
        },
        8: {
            text: `Réservez une démo`,
            type: 'RequestDemo',
        },
        9: {
            text: `Planifiez une démo`,
            type: 'RequestDemo',
        },
        10: {
            text: `Planifiez un démo`,
            type: 'RequestDemo',
        },
        11: {
            text: `Démo gratuite de 30 minutes`,
            type: 'RequestDemo',
        },
        12: {
            text: `Demandez une démo`,
            type: 'RequestDemo',
        },
        13: {
            text: `Réservez une démo`,
            type: 'RequestDemo',
        },
    },
} as const

export const Theme = {
    1: 'Teal',
    2: 'Purple',
} as const

const TestimonialText = [
    `Bravo, excellent platform! It's hard to go back, because we are spoiled at LAZR by its efficiency, its practicality, and obviously the prices. You're going to make a lot of people happy here, especially in the flooring warehouse.`,
    `Working with Lazr has been a great journey. Not only is their customer service more than top notch, but their user friendly site makes shipping anywhere as easy as a few clicks. Any issues that we've run into have been addressed very promptly, avoiding any shipping hiccups. They've also been great with dealing with third party companies on our behalf to ensure that shipments are picked up and delivered on time. 5-star service.`,
    `I must say that your team is dedicated and very helpful. It has been a pleasure to work with each and every one of you, and your service is fantastic. Believe me, in 2022, it's almost impossible to come by.\nI believe it is important to highlight good work and a good partnership when you come across it.`,
    `We have used another shipping software for a few years before switching to Lazr.  Their software is much more complete and offers a better visibility all around.  Our customer were very happy with the way Lazr allows us to work.`,
    `Using Lazr, we managed to save thousands every month.  We have become aware of some freight expenses we did not know about and were able to take action to stop spending on services we did not need.`,
    `We have 6 locations, Lazr allowed us to bring all the shipments together in one place.  We now have visibility across all our transportation volume for freight and parcel.`,
    `I appreciate your follow-ups, and the service you provide is always excellent! Thank you for all your support!`,
] as const
const TestimonialJobTitle = [
    'Flooring industry',
    'Air filtration industry',
    'Toy industry',
    'Heavy Machinery Manufacturer',
    'Retail Distributor',
    'Pulp and Paper manufacturer',
    'Metal manufacturing',
] as const
const TestimonialName = [
    'Dominique',
    'Tasia',
    'Amélie',
    'Johanne',
    'Charles',
    'Stephan',
    'Matthew',
] as const

export const Testimonials: Record<number, {
    jobTitle: typeof TestimonialJobTitle[number]
    name: typeof TestimonialName[number]
    text: typeof TestimonialText[number]
}> = {
    1: {
        jobTitle: TestimonialJobTitle[0],
        name: TestimonialName[0],
        text: TestimonialText[0],
    },
    2: {
        jobTitle: TestimonialJobTitle[1],
        name: TestimonialName[1],
        text: TestimonialText[1],
    },
    3: {
        jobTitle: TestimonialJobTitle[2],
        name: TestimonialName[2],
        text: TestimonialText[2],
    },
    4: {
        jobTitle: TestimonialJobTitle[3],
        name: TestimonialName[3],
        text: TestimonialText[3],
    },
    5: {
        jobTitle: TestimonialJobTitle[4],
        name: TestimonialName[4],
        text: TestimonialText[4],
    },
    6: {
        jobTitle: TestimonialJobTitle[5],
        name: TestimonialName[5],
        text: TestimonialText[5],
    },
    7: {
        jobTitle: TestimonialJobTitle[6],
        name: TestimonialName[6],
        text: TestimonialText[6],
    },
}
