import styled from 'styled-components'
import {
    Box,
    BoxProps,
    Typography,
    TypographyProps,
} from '@material-ui/core'
import React from 'react'
import {
    Dialog, DialogProps,
} from '@/app/ui-new/components/Dialog/Dialog'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    color: ${(props): string => props.theme.palette.black.main};
`
export const StyledDialog: React.FC<DialogProps> = styled(Dialog)<DialogProps>`
    & [role='dialog']{
        padding: 0;
        background-color: black;
    }
    & [role='dialog']>svg:first-of-type {
        color: ${(props): string => props.theme.palette.white.main};
    }
`
export const StyledFormContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    padding: ${(props): string => props.theme.spacing(8)}px ${(props): string => props.theme.spacing(4)}px;
    margin: ${(props): string => props.theme.spacing(4)}px;
    border-radius: 12px;
    background-color: ${(props): string => props.theme.palette.white.main};
`
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.spacing(20)}px 0;
    background-color: ${(props): string => props.theme.palette.white.main};

    & [class*='MuiToolbar-root']{
        padding-left: 0;
        padding-right: 0;
    }

    @media only screen and (max-width: 960px) {
        padding: ${(props) => props.theme.spacing(6)}px ${(props) => props.theme.spacing(6)}px;
        & > div:last-child {
            width: 100%;
            max-width: 360px;
        }
    }
`
export const BoxPanel = styled(Box)`
    width: 360px;

    @media only screen and (max-width: 960px) {
        width: 100%;
    }
`
