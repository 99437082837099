import { Currency, Provider } from '@lazr/enums'
import { UpsSpecifics } from '@/app/model/ProviderSpecifics'
import { OrderInfo } from '@/app/model'

export const createUPSSpecificObject = (provider: Provider | undefined, order: Partial<OrderInfo>): UpsSpecifics => {
    if (
        (!!order.rfq?.commercialInvoiceDocumentIndicator && !order.rfq?.commercialInvoiceUserProvided)
        && (!order.rfq?.importExport || !order.rfq?.billDutiesToParty) && !order.rfq?.documentsOnlyIndicator
    ) {
        throw new Error(('Required fields missing.'))
    }

    return {
        upsSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                customsTotalValue: order.rfq?.customsTotalValue ?? null,
                importExportType: order.rfq?.importExport?.code ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyCurrency: order.rfq?.dutyCurrency ?? Currency.CAD,
                dutyBillToAccountNumber: order.rfq?.dutyBillToAccountNumber ?? null,
                etdEnabled: order.rfq?.etdEnabled ?? null,
            },
        },
    }
}
