import WebsiteFooterDesktop from '@/app/ui-new/components/Navigation/WebsiteFooter/Desktop/WebsiteFooterDesktop'
import WebsiteFooterMobile from '@/app/ui-new/components/Navigation/WebsiteFooter/Mobile/WebsiteFooterMobile'
import React from 'react'
import WithoutFooterLayout from '@/app/ui-new/layouts/WithoutFooterLayout'
import { LayoutProps } from '../../ui/routes'
import { useMediaQuery } from '@material-ui/core'

const WithFooterLayout: React.FunctionComponent<Props> = ({ children }) => {
    const isMobile = useMediaQuery('(max-width: 1230px)')

    return WithoutFooterLayout({
        children: <>
            {children}
            {isMobile ? <WebsiteFooterMobile/> : <WebsiteFooterDesktop/>}
        </>,
    })
}

export interface Props extends LayoutProps {}

export default WithFooterLayout
