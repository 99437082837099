import styled from 'styled-components'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import RawIconLogo from '@/app/ui/components/IconLogo'


export const IconLogo = styled(RawIconLogo)`
    width: 100%;
    fill: ${(props): string => props.theme.palette.white.main};
`

export const StyledCarrierLogo = styled(BaseCarrierLogo)`
    background: ${(props): string => props.theme.palette.black.main};
    div {
        width: ${(props): number => props.size === 'wide-large' ? 74 : 100}%;
    }
`

