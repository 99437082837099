import styled from 'styled-components'

import {
    Step as MuiStep,
    Stepper as MuiStepper,
} from '@material-ui/core'

import {
    spacing,
} from '@material-ui/system'

export const Step = styled(MuiStep) <{
    $isDisabled: boolean
}>`
    & .MuiStepIcon-root {
        font-size: 2.143rem;
        color: ${(props): string => (props.$isDisabled ? props.theme.palette.text.disabled : props.theme.palette.accent1.main)};
        // color: red;
    }

    & .MuiStepIcon-active, .MuiStepIcon-active.MuiStepIcon-completed {
        color: ${(props): string => props.theme.palette.teal.main};
    }

    & .MuiStepIcon-completed, & .MuiStepLabel-completed {
        color: ${(props): string => props.theme.palette.accent1.main};
    }

    ${(props): string => props.theme.breakpoints.only('xs')} {
        & .MuiStepLabel-root {
            width: 70px;
        }
    }

    ${(props): string => props.theme.breakpoints.up('sm')} {
        & .MuiStepLabel-root {
            width: 79px;
        }
    }

    & .MuiStepConnector-horizontal {
        top: 16px;
    }

`

export const Stepper = styled(styled(MuiStepper)(spacing))`
    ${(props): string => props.theme.breakpoints.only('xs')} {
        & .MuiStep-alternativeLabel {
            padding-left: 0;
            padding-right: 0;
        }
    }
    margin-right: 9px;
    margin-left: 1px;
`
