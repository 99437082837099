import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'

const reducer: Reducer = (state = false, action: AnyAction) => (
    action.type === types.SET_IS_DEFAULT_BILLING_ADDRESSES_CALL_EXECUTED
        ? action.payload
        : state
)

export default reducer
