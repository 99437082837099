import React, { ReactChild, ReactElement, useEffect, useState } from 'react'
import HandlingUnitForm, { HandlingUnitFormProps } from '../HandlingUnitForm/HandlingUnitForm'
import { Grid } from '@material-ui/core'
import AccordionSession from '../../../Accordion/AccordionSession'
import SecondaryButton from '../../../SecondaryButton/SecondaryButton'
import { IconButton } from '../../../IconButton/IconButton.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './MultipleHandlingUnitForm.i18n'
import { OnError } from '../../FormSimple'

interface HandlingDataProps {
    index?: number
    quantity?: string | boolean
    length?: string | boolean
    width?: string | boolean
    height?: string | boolean
    averageWeight?: number | boolean
    totalWeight?: number | boolean
}

export interface MultipleHandlingUnitFormProps {
    nameID: string
    forceSubmit?: boolean
    isAverage?: boolean
    hasMultiple?: boolean
    initHandlingUnitField: HandlingUnitFormProps['fields']
    getData: (data: any) => void
    getError?: (data: any) => void
}

const MultipleHandlingUnitForm: React.FunctionComponent<MultipleHandlingUnitFormProps> = ({
    nameID,
    hasMultiple = true,
    forceSubmit = false,
    initHandlingUnitField,
    isAverage=false,
    getData,
    getError,
}) => {
    const { t } = useI18n(i18n)
    const [handlingData, setHandlingData] = useState<HandlingDataProps[]>([])
    const [totalHandlingError, setTotalHandlingError] = useState<any>([])

    const [handlingIsValid, setHandlingIsValid] = useState<boolean>(false)
    const [errorHandling, setErrorHandling] = useState<OnError>({
        data: {},
        isValid: false,
        isDirty: false,
    })

    const [handlingUnitField, sethHandlingUnitField] = useState<HandlingUnitFormProps['fields'][]>([])

    useEffect(() => {
        sethHandlingUnitField([initHandlingUnitField])
        setErrorHandling({
            data: {},
            isValid: false,
            isDirty: false,
        })
    }, [
        initHandlingUnitField?.quantity?.hide,
        initHandlingUnitField?.length?.hide,
        initHandlingUnitField?.width?.hide,
        initHandlingUnitField?.height?.hide,
        initHandlingUnitField?.averageWeight?.hide,
        initHandlingUnitField?.totalWeight?.hide,
    ])

    const createDynamicHandlingData = (
        quantity?: string,
        length?: string,
        width?: string,
        height?: string,
        averageWeight?: number,
        totalWeight?: number,
    ) => {
        const data: HandlingDataProps = {}

        if (quantity) {
            data.quantity = quantity
        }
        if (length) {
            data.length = length
        }
        if (width) {
            data.width = width
        }
        if (height) {
            data.height = height
        }
        if (averageWeight?.toString()) {
            if ((averageWeight === 0) === true) {
                data.averageWeight = averageWeight
            }
        }
        if (totalWeight?.toString()) {
            data.totalWeight = totalWeight
        }
        return data
    }

    const addHandling = () => {
        const newArrayHandlingUnitField = [...handlingUnitField, initHandlingUnitField]
        sethHandlingUnitField(newArrayHandlingUnitField)

        const initHandlingData = createDynamicHandlingData(
            !initHandlingUnitField?.quantity?.hide ? '0' : undefined,
            !initHandlingUnitField?.length?.hide ? '0' : undefined,
            !initHandlingUnitField?.width?.hide ? '0' : undefined,
            !initHandlingUnitField?.height?.hide ? '0' : undefined,
            !initHandlingUnitField?.averageWeight?.hide ? 0 : undefined,
            !initHandlingUnitField?.totalWeight?.hide ? 0 : undefined,
        )

        const newArrayHandlingUnitData = [...handlingData, initHandlingData]

        setHandlingData(newArrayHandlingUnitData)
    }

    const removeHandling = (index: number) => {
        const newArrayHandlingUnitField = [...handlingUnitField.slice(0, index), ...handlingUnitField.slice(index + 1)]
        // Update the state with the new array
        sethHandlingUnitField(newArrayHandlingUnitField)

        const newArrayHandlingUnitData = [...handlingData.slice(0, index), ...handlingData.slice(index + 1)]
        setHandlingData(newArrayHandlingUnitData)

        const newArrayHandlingUnitError = [...totalHandlingError.slice(0, index), ...totalHandlingError.slice(index + 1)]
        setTotalHandlingError(newArrayHandlingUnitError)
    }

    const handleUnitChange = (data: any, index: number) => {
        // Create a copy of the current handling data array
        const newArrayHandlingUnitData = [...handlingData]
        // Update the item at the specified index with the new object
        newArrayHandlingUnitData[index] = data

        // Update the state with the new array
        setHandlingData(newArrayHandlingUnitData)
    }

    const onErrorHandlingChange = (data: HandlingDataProps, error: OnError, index: number) => {
        const newArrayHandlingUnitError = [...totalHandlingError]
        setErrorHandling(error)
        let arrayError = newArrayHandlingUnitError
        if (totalHandlingError[index]) {
            if (Object.keys(data).length > 0) {
                arrayError[index] = { ...data, index }
            } else {
                arrayError = [...totalHandlingError.slice(0, index), ...totalHandlingError.slice(index + 1)]
            }
        } else {
            if (Object.keys(data).length > 0) {
                arrayError = [...totalHandlingError, { ...data, index }]
            }
        }

        setTotalHandlingError(arrayError)
    }

    useEffect(() => {
       
        if (errorHandling.isDirty && totalHandlingError.length === 0 && totalHandlingError !== undefined) {
            setHandlingIsValid(true)
            getData?.(handlingData)
        } else {
            getError?.(totalHandlingError)
            setHandlingIsValid(false)
        }
    }, [totalHandlingError])

    return (
        <Grid container spacing={5}>
            {handlingUnitField.map((handlingUnit, index) => (
                <Grid item xs={12} key={`${index + 1} handling`}>
                    <AccordionSession
                        setIsExpanded={() => {}}
                        handleExpansion={() => {}}
                        isExpanded={true}
                        title={`Handling unit ${index + 1}`}
                        error={!errorHandling.isDirty ? false : !handlingIsValid}
                        completed={errorHandling.isDirty && handlingIsValid}
                        endLabel={errorHandling.isDirty && handlingIsValid ? t('completed') : ''}
                        action={
                            hasMultiple &&
                            handlingUnitField.length > 1 && (
                                <IconButton
                                    aria-label='delete'
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        removeHandling(index)
                                    }}
                                >
                                    <FontAwesomeIcon icon={['far', 'trash']} />
                                </IconButton>
                            )
                        }
                    >
                        <HandlingUnitForm
                            nameID={`${nameID}-parcelPakEnvelope-handlingUnitForm`}
                            isAverage={isAverage}
                            fields={handlingUnit}
                            handleOnChange={(data) => handleUnitChange(data, index)}
                            hideForm={true}
                            forceSubmit={forceSubmit}
                            onError={(data) => onErrorHandlingChange(data?.data, data, index)}
                            // headerForm={headerForm}
                        />
                    </AccordionSession>
                </Grid>
            ))}
            {hasMultiple && (
                <Grid item xs={12}>
                    <SecondaryButton onClick={() => addHandling()}>Add more</SecondaryButton>
                </Grid>
            )}
        </Grid>
    )
}

export default MultipleHandlingUnitForm
