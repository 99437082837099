import { EntityType, EventStatus, EventType  } from '@lazr/openapi-client'
import { UuidV4 } from '@lazr/types'
import { EventUser } from './EventUser'


export interface EventInfoProps {
    id: UuidV4
    type: EventType
    status: EventStatus
    entityId: UuidV4
    entityType: EntityType
    byUser: EventUser | null
    createdAt: string
    data: Record<string, any> & {
        refs?: Record<string, UuidV4 | UuidV4[]>
    }

}
export class EventInfo {
    public id: UuidV4
    public type: EventType
    public status: EventStatus
    public  entityId: UuidV4
    public entityType: EntityType
    public byUser: EventUser | null
    public createdAt: string
    public data: Record<string, any> & {
        refs?: Record<string, UuidV4 | UuidV4[]>
    }
    constructor (props: EventInfoProps) {
        this.id = props.id
        this.type = props.type
        this.status = props.status
        this.entityId = props.entityId
        this.entityType = props.entityType
        this.byUser = props.byUser
        this.createdAt = props.createdAt
        this.data = props.data
    }
}
