import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const SynergieCanadaLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/synergie-canada-bleu.png?v=${window.lazrVersion}`}
        name={'Synergie Canada'}
    />

export default SynergieCanadaLogo
