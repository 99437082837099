/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['Quote faster.']: `Quote faster.`,
    ['Ship smarter.']: `Ship smarter.`,
    ['Company']: `Company`,
    ['Quick links']: `Quick links`,
    ['Home']: `Home`,
    ['Careers']: `Careers`,
    ['https://resources.lazr.io/career']: `https://resources.lazr.io/career`,
    ['Blog']: `Blog`,
    ['https://resources.lazr.io/blog']: `https://resources.lazr.io/blog`,
    ['Lazr Academy']: `Lazr Academy`,
    ['Help center']: `Help center`,
    ['FAQ']: `FAQ`,
    ['Legal']: `Legal`,
    ['Terms and conditions']: `Terms and conditions`,
    ['Privacy policy']: `Privacy policy`,
    ['Follow us']: `Follow us`,
    ['Facebook']: `Facebook`,
    ['LinkedIn']: `LinkedIn`,
    ['Instagram']: `Instagram`,
    ['Join our newsletter']: `Join our newsletter`,
    ['Email']: `Email`,
    ['Subscribe']: `Subscribe`,
}
