

import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const GlsCanadaLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/gls-canada-logo.svg?v=${window.lazrVersion}`}
        name={'GLS Canada'}
    />

export default GlsCanadaLogo
