import { logger } from '../../logger'
import {
    AdminUserManagementService,
    ApiError,
    AuthenticationService,
    Currency,
    ErrorResponse,
    Gender,
    Language,
    Locale,
    RegistrationState,
    RegistrationService,
    Timezone,
    UserRole,
    UserService,
    UserStatus,
    UserTitle,
    UuidV4,
    EditUserProfileRequest,
} from '@lazr/openapi-client'
import { BillToAddress, Lane, OtherOrganization, OtherUser, User, AccountManager } from '../../model'
import { UserByOrganization, UserListItem } from '../../model/UserListItem'
import { handleUnauthorizedException } from './index'
import { Buffer } from 'buffer'
import { UserUiSettings } from '@/app/model/User'
import { Onboarding } from '@lazr/enums'

export type UsersOrderByField = 'name' | 'role' | 'status' | 'email' | 'phoneNumber' |
'organizationName' | 'jobTitle' | 'lastActivity' | 'activityStatus' | undefined
export type UsersOrder = 'asc' | 'desc'

export interface UsersFilter {
    searchField?: string
    orderBy?: UsersOrderByField
    order?: UsersOrder
    name?: string
    role?: UserRole
    status?: UserStatus[]
    clientOrganizationId?: UuidV4
}
export interface UsersPaging {
    page?: number
    resultPerPage?: number
}

export interface UserConfirmationResponse{
    email?: string
}

export type UserAttributes = {
    id: UuidV4
    organizationId: UuidV4
    address?: string | null
    birthdate?: string | null
    currency?: Currency | null
    defaultBillingCurrency?: Currency | null
    emailIsVerified?: boolean | null
    firstName?: string | null
    gender?: Gender | null
    userRole?: UserRole | null
    jobTitle?: string | null
    language?: Language | null
    lastName?: string | null
    linkedInProfileUrl?: string | null
    locale?: Locale | null
    longFullName?: string | null
    middleName?: string | null
    nickname?: string | null
    phoneExtension?: string | null
    phoneNumber?: string | null
    phoneNumberIsVerified?: boolean | null
    pictureUrl?: string | null
    preferredUsername?: string | null
    profile?: string | null
    shortFullName?: string | null
    timezone?: Timezone | null
    title?: UserTitle | null
    websiteUrl?: string | null
    defaultBillingAddressId?: string | null
    defaultShippingAddressId?: string | null
    organizationRoleId?: UuidV4 | null
    uiSettings?: UserUiSettings | null
    onboarding?: Onboarding
}

interface SaveUserLaneParams {
    userId: UuidV4
    originAddressId: UuidV4
    destinationAddressId: UuidV4
    organizationId: UuidV4
}

interface SaveBillToAddressParams {
    userId: UuidV4
    addressesId: UuidV4[]
    organizationId: UuidV4
}

interface AddAccountManagerParams {
    usersId: UuidV4[]
    organizationId: UuidV4
}

export const UserApiService = Object.freeze({
    register: async (orgID: string, username: string, password: string, isSupplier = false):
    Promise<{ state:RegistrationState; error?: string}> => {
        const reqBody = { organizationId: orgID, username: username.trim(), password: password, isSupplier }
        try {
            const result = await RegistrationService.registerUser(reqBody)

            return {
                state: result?.data?.registrationState ?? RegistrationState.FAILED,
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse

                return {
                    state: RegistrationState.FAILED,
                    error: errorResponse.error?.message ?? 'Unable to register user',
                }
            }
            throw new Error('Unable to register user')
        }
    },
    editProfile: async (userAttributes: UserAttributes): Promise<void> => {
        try {
            userAttributes.uiSettings = {

                tableSettings: userAttributes.uiSettings?.tableSettings
            }
            await UserService.editUserProfile(userAttributes as EditUserProfileRequest)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('editProfile', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to update profile information')
            }
            throw new Error('Unable to update profile information')
        }
    },
    getCurrent: async (): Promise<User> => {
        let getCurrentUserResponse
        try {
            getCurrentUserResponse = await UserService.getCurrentUser()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getCurrent', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve current user')
            }
            throw new Error('Unable to retrieve current user')
        }

        const currentUser = getCurrentUserResponse.data
        if (!currentUser) {
            logger.debug('getCurrent', JSON.stringify(getCurrentUserResponse, null, 4))
            throw new Error('Unable to retrieve user')
        }

        return new User({
            ...currentUser,
            role: currentUser.permissions.organizationRole.role,
            lanes: currentUser.lanes.map((lane) => new Lane(lane)),
            billToAddresses: currentUser.billToAddresses.map((billToAddress) => new BillToAddress(billToAddress)),
        })
    },
    listLinked: async (): Promise<OtherUser[]> => {
        let getLinkedUsersResponse
        try {
            getLinkedUsersResponse = await UserService.getLinkedUsers()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('listLinkedUsers', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve linked users')
            }
            throw new Error('Unable to retrieve linked users')
        }

        const linkedUsers = getLinkedUsersResponse.data
        if (!linkedUsers) {
            logger.debug('listLinkedUsers', JSON.stringify(getLinkedUsersResponse, null, 4))
            throw new Error('Unable to retrieve linked users')
        }

        return linkedUsers.map((user) => new OtherUser(user))
    },
    authenticate: async (username: string, password: string): Promise<string> => {
        let authenticateResponse
        try {
            authenticateResponse = await AuthenticationService.authenticate({
                username,
                password,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('authenticate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Authentication failed')
            }
            throw new Error('Authentication failed')
        }

        if (!authenticateResponse.data || !authenticateResponse.data.token) {
            logger.error('authenticate: token missing')
            throw new Error('Authentication failed')
        }

        return authenticateResponse.data.token
    },
    logOutUser: async (): Promise<void> => {
        try {
            await AuthenticationService.logOutUser()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('logOutUser', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Log out failed')
            }
            throw new Error('Log out failed')
        }
    },
    requestPasswordReset: async (username: string): Promise<void> => {
        try {
            await UserService.requestPasswordReset({
                username,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('requestPasswordReset', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Request password failed')
            }
            throw new Error('Request password failed')
        }
    },
    confirmPasswordReset: async (username: string, confirmationCode: string, newPassword: string): Promise<void> => {
        try {
            await UserService.confirmPasswordReset({
                username,
                confirmationCode,
                newPassword,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('confirmPasswordReset', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Confirmation failed')
            }
            throw new Error('Confirmation failed')
        }
    },
    getById: async (id: UuidV4): Promise<User> => {
        let getUserByIdResponse
        try {
            getUserByIdResponse = await UserService.getUserById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user by id')
            }
            throw new Error('Unable to retrieve user by id')
        }

        if (!getUserByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getUserByIdResponse, null, 4))
            throw new Error('Unable to retrieve user by id')
        }

        return new User({
            ...getUserByIdResponse.data,
            role: getUserByIdResponse.data.permissions.organizationRole.role,
            lanes: getUserByIdResponse.data.lanes.map((lane) => new Lane(lane)),
            billToAddresses: getUserByIdResponse.data.billToAddresses.map((billToAddress) => new BillToAddress(billToAddress)),
        })
    },
    confirmSignUp: async (confirmationCode:string, username: string): Promise<UserConfirmationResponse> => {
        let response
        try {
            response = await UserService.confirmSignUp({
                confirmationCode,
                username,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('confirmSignUp', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Confirm sign up failed')
            }
            throw new Error('Confirm sign up failed')
        }

        if (!response?.data) {
            logger.debug('getById', JSON.stringify(response, null, 4))
            throw new Error('Confirm sign up failed')
        }

        return { email: response.data.email }
    },
    activate: async (id: string, organizationId: string): Promise<void> => {
        try {
            await AdminUserManagementService.activateUser({ id, organizationId })
        }  catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('activate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Activate failed')
            }
            throw new Error('Activate failed')
        }
    },
    deactivate: async (id: string, organizationId: string): Promise<void> => {
        try {
            await AdminUserManagementService.deactivateUser({ id, organizationId })
        }  catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('deactivate', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Deactivate failed')
            }
            throw new Error('Deactivate failed')
        }
    },
    purge: async (id: string): Promise<void> => {
        try {
            await AdminUserManagementService.purgeUser({ id })
        }  catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('purge', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Purge failed')
            }
            throw new Error('Purge failed')
        }
    },
    delete: async (id: string, organizationId: string): Promise<void> => {
        try {
            await AdminUserManagementService.deleteUser({ id, organizationId })
        }  catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Delete failed')
            }
            throw new Error('Delete failed')
        }
    },
    resendConfirmationCode: async (username: string): Promise<void> => {
        try {
            await UserService.resendConfirmationCode({ username })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('resendConfirmationCode', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Resend confirmation code failed')
            }
            throw new Error('Resend confirmation code failed')
        }
    },
    changeAvatar: async (userId: UuidV4, file: File | string): Promise<UuidV4 | null> => {
        try {
            let bufferedFile
            let type = 'unknown'
            if (typeof file === 'string') {
                bufferedFile = Buffer.from(file, 'base64')
            } else {
                bufferedFile = Buffer.from(await file.arrayBuffer())
                type = file.type
            }

            const res = await UserService.changeUserAvatar({ userId, file: [ ...new Uint8Array(bufferedFile) ], type })

            return res.data?.avatarUrl ?? null
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('changeAvatar', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add the avatar')
            }
            throw new Error('Unable to add the avatar')
        }
    },
    listByOrganization: async (paging: UsersPaging, filters?: UsersFilter): Promise<UserByOrganization> => {
        let getUsersResponse
        try {
            getUsersResponse = await UserService.getUsers(
                paging.page,
                paging.resultPerPage,
                filters?.orderBy,
                filters?.order,
                filters?.name,
                filters?.role,
                filters?.clientOrganizationId,
                filters?.status,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('listByOrganization', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve users')
            }
            throw new Error('Unable to retrieve users')
        }

        const listUsers = getUsersResponse.data
        if (!listUsers) {
            logger.debug('listUsersByOrganization', JSON.stringify(getUsersResponse, null, 4))
            throw new Error('Unable to retrieve users by organization')
        }

        return {
            users:  listUsers.users.map((user) => new UserListItem(user)),
            total: listUsers.paging.items,
        }
    },
    getSalesRepresentative: async (): Promise<OtherUser[]> => {
        let getSalesRepresentativeResponse
        try {
            getSalesRepresentativeResponse = await UserService.getSalesRepresentative()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('listSalesRepresentatives', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve sales representatives')
            }
            throw new Error('Unable to retrieve sales representatives')
        }

        const users = getSalesRepresentativeResponse.data?.salesReps
        if (!users) {
            logger.debug('listSalesRepresentatives', JSON.stringify(getSalesRepresentativeResponse, null, 4))
            throw new Error('Unable to retrieve sales representatives')
        }

        return users.map((user) => new OtherUser(user))
    },
    getThreePlAgents: async (organizationId: UuidV4): Promise<OtherUser[]> => {
        let getThreePlAgentsResponse
        try {
            getThreePlAgentsResponse = await UserService.getThreePlAgents(organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('listThreePlAgents', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve three pl agents')
            }
            throw new Error('Unable to retrieve three pl agents')
        }

        const users = getThreePlAgentsResponse.data?.threePlAgent
        if (!users) {
            logger.debug('listThreePlAgents', JSON.stringify(getThreePlAgentsResponse, null, 4))
            throw new Error('Unable to retrieve three pl agents')
        }

        return users.map((user) => new OtherUser(user))
    },

    getUserLaneByUserId: async (userId: string, organizationId: string): Promise<Lane[]> => {
        let getUserUserLanesByUserIdResponse
        try {
            getUserUserLanesByUserIdResponse = await UserService.getLanesByUserId(userId, organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user lanes')
            }
            throw new Error('Unable to retrieve user lanes')
        }
        const lanes = getUserUserLanesByUserIdResponse.data
        if (!lanes) {
            throw new Error('Unable to retrieve user lanes')
        }

        return lanes.lanes
            .map((lane) => new Lane(lane))
    },
    getUserLaneByOrganizationId: async (addressesId: UuidV4[], organizationId: string): Promise<Lane[]> => {
        let getUserUserLanesByOrganizationIdResponse
        try {
            getUserUserLanesByOrganizationIdResponse = await UserService.getLanesByOrganizationId(organizationId, addressesId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getUserLaneByOrganizationId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user lanes')
            }
            throw new Error('Unable to retrieve user lanes')
        }
        const lanes = getUserUserLanesByOrganizationIdResponse.data
        if (!lanes) {
            throw new Error('Unable to retrieve user lanes')
        }

        return lanes.lanes
            .map((lane) => new Lane(lane))
    },
    saveUserLane: async (params: SaveUserLaneParams): Promise<void> => {
        try {
            await UserService.saveUserLane(params)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('save', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to save user lane')
            }
            throw new Error('Unable to save user lane')
        }
    },

    deleteUserLaneByLaneId: async (id: UuidV4): Promise<void> => {
        try {
            await UserService.deleteUserLane(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('delete', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to delete user lane')
            }
            throw new Error('Unable to delete user lane')
        }
    },

    getUserBillToByUserId: async (userId: string, organizationId: string): Promise<BillToAddress[]> => {
        let getUserBillToAddressByUserIdResponse
        try {
            getUserBillToAddressByUserIdResponse = await UserService.getBillToByUserId(userId, organizationId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user bill to address(es)')
            }
            throw new Error('Unable to retrieve user bill to address(es)')
        }
        const billToAddresses = getUserBillToAddressByUserIdResponse.data
        if (!billToAddresses) {
            throw new Error('Unable to retrieve user bill to address(es)')
        }

        return billToAddresses.billToAddresses
            .map((billToAddress) => new BillToAddress(billToAddress))
    },
    getUserBillToByOrganizationId: async (addressesId: UuidV4[], organizationId: string): Promise<BillToAddress[]> => {
        let getUserBillToAddressByOrganizationIdResponse
        try {
            getUserBillToAddressByOrganizationIdResponse = await UserService.getBillToByOrganizationId(organizationId, addressesId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getUserBillToByOrganizationId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user bill to address(es)')
            }
            throw new Error('Unable to retrieve user bill to address(es)')
        }
        const billToAddresses = getUserBillToAddressByOrganizationIdResponse.data
        if (!billToAddresses) {
            throw new Error('Unable to retrieve user bill to address(es)')
        }

        return billToAddresses.billToAddresses
            .map((billToAddress) => new BillToAddress(billToAddress))
    },
    saveBillToAddress: async (params: SaveBillToAddressParams): Promise<void> => {
        try {
            await UserService.saveBillToAddress(params)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('save', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to save user bill to address')
            }
            throw new Error('Unable to save user bill to address')
        }
    },

    deleteUserBillingAddressById: async (id: UuidV4): Promise<void> => {
        try {
            await UserService.deleteUserBillingAddress({ id })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('delete', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to delete user billing address')
            }
            throw new Error('Unable to delete user billing address')
        }
    },

    getAccountManagers: async (): Promise<AccountManager[]> => {
        let getAccountManagersResponse
        try {
            getAccountManagersResponse = await UserService.getAccountManager()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve account managers!')
            }
            throw new Error('Unable to retrieve account managers!')
        }
        const accountManagers = getAccountManagersResponse.data
        if (!accountManagers) {
            throw new Error('Unable to retrieve account managers!')
        }

        return accountManagers.accountManagers
            .map((accountManager) => new AccountManager(accountManager))
    },

    addAccountManagers: async (params: AddAccountManagerParams): Promise<void> => {
        try {
            await UserService.addAccountManager(params)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('save', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to add account managers')
            }
            throw new Error('Unable to add account managers')
        }
    },
})
