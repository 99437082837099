import React from 'react'
import {
    Wrapper,
} from './NewEfficiencyZeroFrictionMobile.styled'
import { Box } from '@material-ui/core'
import {
    NewEfficiencyZeroFrictionMobile as NewEfficiencyZeroFriction,
} from '../NewEfficiencyZeroFrictionMobile'


const NewEfficiencyZeroFrictionMobile: React.FunctionComponent<Props> = () => (
    <Wrapper>
        <Box width={375}>
            <NewEfficiencyZeroFriction/>
        </Box>
    </Wrapper>
)

export interface Props {
}

export default NewEfficiencyZeroFrictionMobile
