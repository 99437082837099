import TextFieldLabel from '../TextField/TextFieldLabel'
import TextField from '../TextField/TextField'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'
import type { FieldConfig, SchemaField } from './FormSimple'
import moment from 'moment'
import { NumberField as NumberFieldBlue } from './InputForm/InputForm.styled'
import { NumberField } from './InputForm/InputForm.styled'

import type { Country } from '@/app/model'
import { AddressDetails } from './GoogleAddressSearchBarForm/PlacesAutocomplete/helpers'
import { PlacesAutocompleteResult } from './GoogleAddressSearchBarForm/PlacesAutocomplete/PlacesAutocomplete'



export type ColumnName = {
    camelName: string
    displayName: string
    isChecked: boolean
}

/**
 * Removes keys with undefined, null, and empty string values from an object recursively.
 * @param obj - The object from which to remove keys.
 * @returns A new object without keys containing undefined, null, or empty string values.
 */
const removeNullOrUndefinedKeys = (obj: Record<string, any>): Record<string, any> => {
    // Create a new object to store the result

    const result: Record<string, any> = {}

    // Iterate over the keys of the input object
    for (const key in obj) {
        // Check if the value of the current key is an object and not null
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Recursively remove null, undefined, and empty string keys from child objects
            const cleanedChild = removeNullOrUndefinedKeys(obj[key])
            // Check if the cleaned child object is not empty
            if (Object.keys(cleanedChild).length > 0) {
                // Add the cleaned child object to the result
                result[key] = cleanedChild
            }
        } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
            // Check if the value is a Date object
            const value = moment.utc(obj[key], 'YYYY-MM-DD HH:mm:ss', true).format('YYYY-MM-DD')
            if (value !== undefined && value !== '' && value !== 'Invalid date') {
                // Convert Date to date string without the time part

                result[key] = value
            } else {
                // Add the key-value pair to the result object if the value is not undefined, null, or empty string
                result[key] = obj[key]
            }
        }
    }

    // Return the object without keys containing undefined, null, or empty string values
    return result
}

const generateCheckboxSchema = ({
    columns,
    layoutConfig,
}: {
    columns: ColumnName[]
    layoutConfig: Record<string, any>
}): Record<string, SchemaField> =>
    columns.reduce((schema: Record<string, SchemaField>, column) => {
        schema[column.camelName] = {
            type: 'checkbox',
            label: column.displayName,
            layoutConfig: layoutConfig, // Adjust layout configuration as needed
        }
        return schema
    }, {})

const flattenSchema = (schema: Record<string, any>, keyNames: string) => {
    const arrayNames = keyNames.split(',')

    const flattenedSchema: Record<string, any> = {}

    for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
            const entry = schema[key]

            const flattenedEntry: Record<string, any> = {}
            for (const name of arrayNames) {
                const trimName = name.trim()
                if (name === 'zod' && entry[name]) {
                    flattenedSchema[key] = entry[name]
                } else if (entry[name]) {
                    flattenedEntry[name] = entry[name]
                }
            }
            if (Object.keys(flattenedEntry).length > 0) {
                flattenedSchema[key] = flattenedEntry
            }
        }
    }

    return flattenedSchema
}





// Define a function to select component dynamically
const loadComponent = (type: string) => {
    switch (type) {
        case 'text':
        case 'textOutlined':
        case 'textSearchBar':
        case 'hiddenText':
        case 'phoneNumberField':
            return TextField
        case 'textLabel':
            return TextFieldLabel
        case 'primaryButton':
            return PrimaryButton
        case 'secondaryButton':
            return SecondaryButton
        case 'numberFieldBlue':
            return NumberFieldBlue
        case 'numberField':
            return NumberField
        // Add more cases for other input types as needed
        default:
            return null
    }
}
// biome-ignore lint/suspicious/noExplicitAny: I don't know what is the object will be
const sortObjectByOrder: any = (obj: any) => {
    const sortedKeys = Object.keys(obj).sort((a, b) => obj[a].order - obj[b].order)

    // biome-ignore lint/suspicious/noExplicitAny: i dont know the object will be
    const sortedObject: any = {} // Declare as an object

    for (const key of sortedKeys) {
        sortedObject[key] = obj[key]
    }
    return sortedObject
}

const getAddressDetails = (place: PlacesAutocompleteResult, countryList: Country[], lane?: boolean): AddressDetails => {
    let newStreetAddress = ''

    if (place.streetNumber && place.street) {
        newStreetAddress = `${place.streetNumber.longName} ${place.street.longName}`
    } else if (place.street) {
        newStreetAddress = place.street.longName
    } else if (place.name) {
        newStreetAddress = place.name
    }

    const zipOrPostalCode = place.zipOrPostalCode ? place.zipOrPostalCode.longName : ''
    const country = countryList.find((item) => item.code === place.country?.shortName)
    const companyName = place.name === `${place?.streetNumber?.longName} ${place?.street?.shortName}` ? '' : place.name

    const addressDetails: AddressDetails = {
        companyName: companyName,
        streetAddress: newStreetAddress,
        streetAddress2: place.streetAddress2,
        city: place.city?.longName ?? '',
        state: place.stateOrProvince?.shortName ?? '',
        country: country ?? null,
        postalCode: zipOrPostalCode,
        addressType: place.addressType,
        detectedAddressType: place.addressType,
    }

    return addressDetails
}

const reorderFields = (fields: FieldConfig[]): FieldConfig[] => {
    return [...fields].sort((a, b) => {
        const orderA = a.order ?? Number.MAX_SAFE_INTEGER // If order is not defined, set it to the maximum safe integer
        const orderB = b.order ?? Number.MAX_SAFE_INTEGER

        return orderA - orderB
    })
}

export {
    flattenSchema,
    loadComponent,
    generateCheckboxSchema,
    removeNullOrUndefinedKeys,
    sortObjectByOrder,
    reorderFields,
    getAddressDetails,
}
