import React from 'react'
import { CheckboxInner, CheckboxOuter } from './Checkbox.styled'
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core'

export const Checkbox: React.FunctionComponent<Props> = (props) => {
    const classNames = [
        props.checked || props.indeterminate ? '' : 'unselected',
        props.error  ? 'error' : '',
        props.disabled ? 'disabled' : '',
    ].join(' ')

    return (
        <React.Fragment>
            <CheckboxOuter className={classNames}>
                <CheckboxInner {...props}
                    checked={props.checked}
                    className={classNames}
                    disabled={props.disabled ?? false}
                />
            </CheckboxOuter>
        </React.Fragment>
    )
}

export interface Props extends MuiCheckboxProps {
    error?: boolean
}
