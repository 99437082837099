/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import React from 'react'

export default ((): Translations<typeof en> => ({
    Attention: 'Attention',
    'Hide this message':'Masquer ce message',
    "For the best experience on our website, we recommend using a desktop computer.":`Pour une meilleure expérience sur notre site, nous vous recommandons d'utiliser un ordinateur de bureau. Certaines fonctionnalités peuvent être limitées sur un petit écran.`,
    Hide:"Masquer",
}))()
