import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { OrderFilter, OrderListFiltering } from '../../definitions/OrderList'
import { initialOrderListFiltering } from '../store/InitialState'
import { Carrier, OtherOrganization, OtherUser } from '../../../model'

const reducer: Reducer = (state: OrderListFiltering = initialOrderListFiltering, action: AnyAction): OrderListFiltering => ({
    filters: filters(state.filters, action),
    organizationList: organizationList(state.organizationList, action),
    otherUserInfoList: otherUserInfoList(state.otherUserInfoList, action),
    otherThreePlAgentList: otherThreePlAgentList(state.otherThreePlAgentList, action),
    carrierList: carrierList(state.carrierList, action),
    filteredByOrganizationId: filteredByOrganizationId(state.filteredByOrganizationId, action),
    salesRepList: salesRepList(state.salesRepList, action),
})

const filters = (state: OrderFilter, action: AnyAction): OrderFilter =>
    action.type === types.SET_ORDER_FILTER
        ? action.payload
        : state

const organizationList = (state: OtherOrganization[], action: AnyAction): OtherOrganization[] => {
    switch (action.type) {
        case types.SET_ORDER_LIST_ORGANIZATION_LIST:
            return action.payload.organizationList
        default:
            return state
    }
}

const otherUserInfoList = (state: OtherUser[], action: AnyAction): OtherUser[] => {
    switch (action.type) {
        case types.SET_ORDER_LIST_OTHER_USER_INFO_LIST:
            return action.payload.otherUserInfoList
        default:
            return state
    }
}

const otherThreePlAgentList = (state: OtherUser[], action: AnyAction): OtherUser[] => {
    switch (action.type) {
        case types.SET_ORDER_LIST_THREE_PL_AGENT_LIST:
            return action.payload.otherThreePlAgentList
        default:
            return state
    }
}

const carrierList = (state: Carrier[], action: AnyAction): Carrier[] => {
    switch (action.type) {
        case types.SET_ORDER_LIST_CARRIER_LIST:
            return action.payload.carrierList
        default:
            return state
    }
}

const filteredByOrganizationId = (state: string, action: AnyAction): string => {
    switch (action.type) {
        case types.SET_ORDER_LIST_OTHER_USER_INFO_LIST:
            return action.payload.filteredByOrganizationId
        default:
            return state
    }
}

const salesRepList = (state: OtherUser[], action: AnyAction): OtherUser[] => {
    switch (action.type) {
        case types.SET_ORDER_LIST_SALES_REP_LIST:
            return action.payload.salesRepList
        default:
            return state
    }
}

export default reducer
