import React, { useCallback, useRef, useState } from 'react'
import {
    StyledIconButton,
    StyledInnerBox, StyledLeftBtnBox,
    StyledListItem, StyledOuterBox, StyledRightBtnBox,
} from '@/app/ui-new/pages/website/components/CariersCarousel/Desktop/ScrollableListDesktop.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CarrierLogo from '@/app/ui-new/components/CarrierLogo/CarrierLogo'
import { Box, BoxProps } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import {
    StyledToggleButton,
    StyledTypographySwitcher,
} from '@/app/ui-new/pages/website/components/CariersCarousel/Desktop/CarriersCarouselDesktop.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './CarriersCarouselDesktop.i18n'

const ScrollableListDesktop: React.FunctionComponent<Props> = (props: Props) => {
    const [ isNeedLeftArrow, setNeedLeftArrow ] = React.useState<boolean>(false)
    const [ isNeedRightArrow, setNeedRightArrow ] = React.useState<boolean>(true)
    const innerBoxRef = React.useRef<HTMLDivElement>()
    const [ carrierType, setCarrierType ] = React.useState<'freight' | 'parcel'>('freight')
    const [ hasAnimationStopped, setHasAnimationStopped ] = React.useState<boolean>(false)
    const [ isMouseDown, setIsMouseDown ] = useState(false)
    const [ scrollingStartTimeout, setScrollingStartTimeout ] = useState(10000)
    const intervalIdRef = useRef<number>()
    const carrierTypeRef = useRef(carrierType)
    carrierTypeRef.current = carrierType
    const { t } = useI18n(i18n)

    const stopAnimation = useCallback(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }
        setHasAnimationStopped(true)
    }, [ setHasAnimationStopped, intervalIdRef ])

    const mouseCoords = useRef({
        startX: 0,
        scrollLeft: 0,
    })

    const handleLeftArrowIconClickEvent: React.EventHandler<React.MouseEvent<HTMLElement>> = React.useCallback(
        () => {
            stopAnimation()
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: 'smooth',
                    left: -800,
                })
            }
        },
        [ innerBoxRef, stopAnimation ],
    )

    const handleRightArrowIconClickEvent: React.EventHandler<React.MouseEvent<HTMLElement>> = React.useCallback(
        () => {
            stopAnimation()
            if (innerBoxRef.current) {
                innerBoxRef.current.scrollBy({
                    behavior: 'smooth',
                    left: 800,
                })
            }
        },
        [ innerBoxRef, stopAnimation ],
    )

    const scroll = React.useCallback((element: HTMLDivElement) => {
        element.scrollLeft = element.scrollLeft + 1
        const hasScrollingEnded = (element.scrollLeft + props.listWidth - 10) >= element.scrollWidth
        if (hasScrollingEnded) {
            window.clearInterval(intervalIdRef.current)
            setNeedRightArrow(false)
            window.setTimeout(() => {
                setCarrierType(() => carrierTypeRef.current === 'parcel' ? 'freight' : 'parcel')
            }, 2000)
        }
    }, [ innerBoxRef, carrierType, intervalIdRef, props.listWidth, setNeedRightArrow ])

    const startScrolling = React.useCallback(() => {
        if (!hasAnimationStopped) {
            const element = innerBoxRef.current

            if (element) {
                intervalIdRef.current = window.setInterval(() => {
                    scroll(element)
                }, 10)
            }
        }
    }, [ hasAnimationStopped, innerBoxRef, intervalIdRef, window.setInterval, scroll ])

    const checkIfButtonsNeeded = React.useCallback(() => {
        const element = innerBoxRef.current
        if (element){
            setNeedRightArrow((element.scrollLeft + props.listWidth - 10) < element.scrollWidth)
            setNeedLeftArrow(element.scrollLeft !== 0)
        }
    }, [ innerBoxRef, setNeedRightArrow, setNeedLeftArrow, props.listWidth ])

    const handleScrollChangeEvent: React.EventHandler<React.UIEvent<HTMLDivElement>> = useCallback((e) => {
        const curScrollPos = e.currentTarget.scrollLeft
        const maxScrollPos = e.currentTarget.scrollWidth - e.currentTarget.clientWidth
        if (curScrollPos === 0) {
            setNeedLeftArrow(false)
        } else if (Math.ceil(curScrollPos) >= maxScrollPos) {
            setNeedRightArrow(false)
        } else {
            setNeedLeftArrow(true)
            setNeedRightArrow(true)
        }
    }, [ setNeedLeftArrow, setNeedRightArrow ])

    const handleCarrierTypeChange = useCallback((event: React.MouseEvent<HTMLElement>, selectedType: 'freight' | 'parcel') => {
        stopAnimation()
        checkIfButtonsNeeded()
        if (selectedType !== null) {
            setCarrierType(selectedType)
        }
    }, [ setCarrierType, stopAnimation, checkIfButtonsNeeded ])

    const renderItemsList = useCallback ((carriersType: 'freight' | 'parcel'): React.ReactNode => {
        if (carriersType === 'freight') {
            return props.freightCarriers.map((carrier, index) => (
                <StyledListItem key={index}>
                    <CarrierLogo variant={carrier} size='wide-large'/>
                </StyledListItem>
            ))
        }

        return props.parcelCarriers.map((carrier, index) => (
            <StyledListItem key={index}>
                <CarrierLogo variant={carrier} size='wide-large'/>
            </StyledListItem>
        ))
    }, [
        props.freightCarriers,
        props.parcelCarriers,
    ])

    const handleDragStart = useCallback((e) => {
        if (!innerBoxRef.current) {
            return
        }
        stopAnimation()
        if (!innerBoxRef.current) {return}
        const slider = innerBoxRef.current
        const startX = e.pageX - slider.offsetLeft
        const scrollLeft = slider.scrollLeft
        mouseCoords.current = { startX, scrollLeft }
        setIsMouseDown(true)
    }, [ innerBoxRef, setIsMouseDown, stopAnimation, mouseCoords ])

    const handleDragEnd = useCallback(() => {
        if (!innerBoxRef.current) {
            return
        }
        setIsMouseDown(false)
        checkIfButtonsNeeded()
    }, [ innerBoxRef, setIsMouseDown, stopAnimation, checkIfButtonsNeeded ])

    const handleDrag = useCallback ((e) => {
        if (!isMouseDown || !innerBoxRef.current) {
            return
        }
        e.preventDefault()
        const slider = innerBoxRef.current
        const x = e.pageX - slider.offsetLeft
        const walkX = (x - mouseCoords.current.startX) * 1.5
        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX
    }, [ innerBoxRef, isMouseDown ])

    React.useEffect(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }

        if (innerBoxRef.current) {
            innerBoxRef.current.scrollLeft = 0
        }

        checkIfButtonsNeeded()
        const isListOverflowed = !!innerBoxRef.current && (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth)
        const timeout = isListOverflowed ? scrollingStartTimeout : 5000
        setScrollingStartTimeout(2000) // 10s initially to let header animation finish, then 2s for subsequent runs
        window.setTimeout(() => {
            setNeedLeftArrow(!hasAnimationStopped && isListOverflowed)
            startScrolling()
        }, timeout)

        return (() => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        })
    }, [ carrierType, intervalIdRef, innerBoxRef ])

    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Box ml={7} position={'relative'} width={120} height={82}>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={carrierType}
                    exclusive
                    onChange={handleCarrierTypeChange}
                >
                    <StyledToggleButton value="freight" aria-label="freight">
                        {carrierType === 'freight' && <FontAwesomeIcon  icon={[ 'far', 'pallet' ]}/>}
                        <StyledTypographySwitcher variant={'body2'}>{t('Freight')}</StyledTypographySwitcher>
                    </StyledToggleButton>
                    <StyledToggleButton value="parcel" aria-label="parcel">
                        {carrierType === 'parcel' && <FontAwesomeIcon  icon={[ 'far', 'box-open' ]}/>}
                        <StyledTypographySwitcher variant={'body2'}>{t('Parcel')}</StyledTypographySwitcher>
                    </StyledToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box width={props.listWidth}>
                <StyledOuterBox>
                    {isNeedLeftArrow && (
                        <StyledLeftBtnBox>
                            <StyledIconButton size="small" onClick={handleLeftArrowIconClickEvent}>
                                {<FontAwesomeIcon  icon={[ 'far', 'arrow-left' ]}/>}
                            </StyledIconButton>
                        </StyledLeftBtnBox>)
                    }
                    <StyledInnerBox
                        onScroll={hasAnimationStopped ? handleScrollChangeEvent : undefined}
                        onMouseDown={handleDragStart}
                        onMouseUp={handleDragEnd}
                        onMouseMove={handleDrag}
                        isOverflowed = {!!innerBoxRef.current && (innerBoxRef.current.scrollWidth > innerBoxRef.current.clientWidth)}
                        // @ts-ignore
                        ref={innerBoxRef}
                    >
                        {renderItemsList(carrierType)}
                    </StyledInnerBox>
                    {isNeedRightArrow && (
                        <StyledRightBtnBox>
                            <StyledIconButton size="small" onClick={handleRightArrowIconClickEvent}>
                                {<FontAwesomeIcon  icon={[ 'far', 'arrow-right' ]}/>}
                            </StyledIconButton>
                        </StyledRightBtnBox>
                    )}
                </StyledOuterBox>
            </Box>
        </Box>
    )
}

export interface Props {
    freightCarriers: string[]
    parcelCarriers: string[]
    listWidth: number
}

export interface InnerBoxProps extends BoxProps {
    isOverflowed?: boolean
}

export default ScrollableListDesktop
