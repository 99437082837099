/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './NewLayout.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Load']: 'Charge',
    ['Origin & Destination']: 'Origine et Destination',
    ['Coverage']: 'Couverture',
    ['PLT']: 'Palette',
    ['BAG']: 'Sac',
    ['BALE']: 'Balle',
    ['BOX']: 'Boîte',
    ['BUCKET']: 'Seau',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Paquet',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Caisse',
    ['COIL']: 'Bobine',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylindre',
    ['DRUM']: 'Fût',
    ['PIECES']: 'Pièces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Rouleau',
    ['SKID']: 'Skid',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Bac',
    ['OTHER']: 'Autre',
    ['FORKLIFT']: 'Chariot élévateur',
    ['This component is a map.']: `Ce composant est une carte.`,
    ['View Map']: `Voir la carte`,
    ['Truck type']: `Type de camion`,
    ['LTL']: `LTL`,
    ['Reefer']: `Frigorifié`,
    ['Fresh']: `Frais`,
    [`Handling units`]: `Unités de manutention`,
    ['Dashboard']: `Tableau de bord`,
    ['Marketplace']: `Marketplace`,
    ['Invoices']: `Factures`,
    ['Address Book']: `Carnet d'adresses`,
    ['Users']: `Utilisateurs`,
    ['Shipping Details']: `Détails d'expédition`,
    ['Welcome to the marketplace! All you have to do is let us know what you need to ship']: `Bienvenue sur le marketplace! Tout ce que vous avez à faire est de nous faire savoir ce que vous devez expédier`,
    [' and we\'ll provide you with great options.']: ` et nous vous proposerons d'excellentes options.`,
    ['Input that does affect the Results']: `Champ qui affecte les résultats`,
    ['Input that does NOT affect the Results']: `Champ qui n'affecte PAS les résultats`,
    ['Something to fill in:']: `Quelque chose à compléter`,
    ['Reset all shipping informations']: `Réinitialiser toutes les informations d'expédition`,
    ['Go to']: `Aller à`,
    ['Submit shipping information']: `Soumettre les informations d'expédition`,
    ['Complete!']: `Complété!`,
    ['Continue']: `Continuer`,
    ['Set shipping steps bar at fixed position']: `Placer la barre des étapes d'expédition à la position fixe`,
    ['Results for']: `Résultats pour`,
    ['Read more']: `Lire plus`,
    ['Conversations']: `Conversations`,
    ['No conversation']: `Aucune conversation`,
    ['Notifications']: `Avis`,
    ['No notification']: `Aucuns avis`,
    ['User menu']: `Menu utilisateur`,
    ['Role:']: `Rôle:`,
    ['Admin']: `Admin`,
    ['Organization:']: `Organisation:`,
    ['Lazr Freight']: `Lazr Freight`,
    ['My Account']: `Mon compte`,
    ['Orders']: `Commandes`,
    ['Profile']: `Profil`,
    ['Settings']: `Réglages`,
    ['Sign out']: `Déconnexion`,
    ['My Organization']: `Mon Organisation`,
    ['Manage Team']: `Gérer l'équipe`,
    ['Invite members']: `Inviter des membres`,
   
}))()
