import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'

const ADuiePyleLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={`/images/logos/carrier/a-duie-pyle-logo.png?v=${window.lazrVersion}`}
        name={'ADuiePyle'}
    />

export default ADuiePyleLogo
