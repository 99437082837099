import React from 'react'
import { DialogTitle, DialogActions, DialogTitleProps, DialogActionsProps } from '@material-ui/core'
import {
    DialogCloseButton,
    Typography,
    FontAwesomeIcon,
    Dialog,
    Button,
    Note,
    RedStar as StyledRedStar,
} from './Modal.styled'

import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons'

export type IconProp = [IconPrefix, IconName]

export const SPINNER_ICON: IconProp = [ 'fas', 'spinner' ]
export const PREVIEW_ICON: IconProp = [ 'fas', 'eye' ]
export const DOWNLOAD_ICON: IconProp = [ 'fas', 'arrow-alt-to-bottom' ]
export const UPLOAD_ICON: IconProp = [ 'fas', 'cloud-upload' ]
export const RESEND_ICON: IconProp = [ 'fas', 'rss' ]
export const GENERATE_ICON: IconProp = [ 'fas', 'file-plus' ]
export const DELETE_ICON: IconProp = [ 'fas', 'times-circle' ]
export const CANCEL_ICON: IconProp = [ 'fas', 'ban' ]
export const SETTINGS_ICON: IconProp = [ 'fas', 'cog' ]
export const NO_ICON: IconProp = [ 'fas', 'times' ]
export const OK_ICON: IconProp = [ 'fas', 'check' ]
export const DELETE_CONFIRM_ICON: IconProp = [ 'fas', 'trash' ]
export const NEXT_ICON: IconProp = [ 'fas', 'arrow-alt-right' ]
export const BACK_ICON: IconProp = [ 'fas', 'arrow-alt-left' ]
export const FILE_ICON: IconProp = [ 'fas', 'file-alt' ]
export const SEND_ICON: IconProp = [ 'fas', 'paper-plane' ]
export const DOWNLOAD_INVOICE_ICON: IconProp = [ 'fas', 'download' ]
export const INVOICE_ICON: IconProp = [ 'fal', 'file-invoice-dollar' ]
export const COMMENTS_ICON: IconProp = [ 'fas', 'comments' ]
export const EDIT_ICON: IconProp = [ 'fas', 'edit' ]
export const SHARE_ICON: IconProp = [ 'fas', 'share' ]
export const RedStar = StyledRedStar

const Modal: React.FunctionComponent<Props> = (
    {
        open,
        onClose,
        maxWidth,
        maximumWidth,
        modalTitle,
        closeButton,
        content,
        buttons,
        disableBackdropClick,
        disableEscapeKeyDown,
        dialogTitleComponent,
        dialogActionsComponent,
    }) => {
    const DialogTitleComponent = dialogTitleComponent ?? DialogTitle
    const DialogActionsComponent = dialogActionsComponent ?? DialogActions

    return  <Dialog
        onClose={onClose}
        id={modalTitle}
        key={modalTitle}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth={maximumWidth}
        style={{ maxWidth: (maxWidth ?? 500) }}
        open={open}
    >
        <DialogTitleComponent disableTypography>
            <Typography variant="h6">
                {modalTitle}
            </Typography>
            {closeButton &&
                <DialogCloseButton
                    aria-label="closeDialog"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={[ 'far', 'times' ]} className='large gray close' />
                </DialogCloseButton>
            }
        </DialogTitleComponent>
        {content}
        {buttons &&
            <DialogActionsComponent>
                {buttons.map(({ disabled, iconProp, note, className, action, showIcon = true, title }, index) =>
                    <Button
                        key={index}
                        disabled={
                            (disabled ?? false) || (showIcon && iconProp ? iconProp === SPINNER_ICON : false)
                        }
                        color="primary"
                        onClick={() => {
                            void (async (): Promise<void> => {
                                await action()
                            })()
                        }
                        }
                        startIcon={
                            showIcon && iconProp &&
                            <FontAwesomeIcon
                                icon={iconProp}
                                pulse={iconProp === SPINNER_ICON}
                                className="large"
                            />
                        }
                        className={className ?? ''}
                    >
                        <div>
                            {title}
                            {note && <Note>{note}</Note>}
                        </div>
                    </Button>,
                )}
            </DialogActionsComponent>
        }
    </Dialog>
}

export interface Props {
    open: boolean
    onClose?: () => void
    maxWidth?: number
    maximumWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    modalTitle: string
    closeButton: boolean
    content: React.ReactNode
    buttons?: {
        title: React.ReactNode
        disabled?: boolean
        showIcon?: boolean
        iconProp?: IconProp
        note?: React.ReactNode
        className?: string
        action: (() => void) | (() => Promise<void>)
    }[]
    disableBackdropClick?: boolean
    disableEscapeKeyDown?: boolean
    dialogTitleComponent?: (props: DialogTitleProps) => JSX.Element
    dialogActionsComponent?: (props: DialogActionsProps) => JSX.Element
}

export default Modal
