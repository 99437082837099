import { logger } from '../../logger'
import {
    UserEventNotificationService, ApiError, ErrorResponse,
} from '@lazr/openapi-client'
import { UserEventNotifications } from '../../model/UserEventNotification'

export interface UserEventNotificationPaging {
    page?: number
    resultPerPage?: number
}

export const UserEventNotificationApiService = Object.freeze({
    list: async (paging: UserEventNotificationPaging): Promise<UserEventNotifications> => {
        let getUserEventNotificationsResponse
        try {
            getUserEventNotificationsResponse = await UserEventNotificationService.getUserEventNotifications(
                paging.page,
                paging.resultPerPage,
            )
        } catch (error: any) {
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve user event notification list')
            }
            throw new Error('Unable to retrieve user event notification list')
        }

        if (!getUserEventNotificationsResponse.data) {
            logger.debug('list', JSON.stringify(getUserEventNotificationsResponse, null, 4))
            throw new Error('Unable to retrieve user event notification list')
        }

        return {
            notifications: getUserEventNotificationsResponse.data.notifications,
            total: getUserEventNotificationsResponse.data.paging.items,
            unviewed: getUserEventNotificationsResponse.data.unviewed,
        }
    },
    markAllAsViewed: async (): Promise<void> => {
        let markAllUserEventNotificationsAsViewedResponse
        try {
            markAllUserEventNotificationsAsViewedResponse = await UserEventNotificationService.markAllUserEventNotificationsAsViewed()
        } catch (error: any) {
            logger.debug('markAllAsViewed', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to mark all user event notifications as viewed')
            }
            throw new Error('Unable to mark all user event notifications as viewed')
        }

        if (!markAllUserEventNotificationsAsViewedResponse.data) {
            logger.debug('accept', JSON.stringify(markAllUserEventNotificationsAsViewedResponse, null, 4))
            throw new Error('Unable to mark all user event notifications as viewed')
        }
    },
    markAllAsRead: async (): Promise<void> => {
        let markAllUserEventNotificationsAsReadResponse
        try {
            markAllUserEventNotificationsAsReadResponse = await UserEventNotificationService.markAllUserEventNotificationsAsRead()
        } catch (error: any) {
            logger.debug('markAllAsRead', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to mark all user event notifications as read')
            }
            throw new Error('Unable to mark all user event notifications as read')
        }

        if (!markAllUserEventNotificationsAsReadResponse.data) {
            logger.debug('accept', JSON.stringify(markAllUserEventNotificationsAsReadResponse, null, 4))
            throw new Error('Unable to mark all user event notifications as read')
        }
    },
    markAsRead: async (id: string): Promise<void> => {
        let markUserEventNotificationAsReadResponse
        try {
            markUserEventNotificationAsReadResponse = await UserEventNotificationService.markUserEventNotificationAsRead({ id })
        } catch (error: any) {
            logger.debug('markAsRead', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to mark user event notification as read')
            }
            throw new Error('Unable to mark user event notification as read')
        }

        if (!markUserEventNotificationAsReadResponse.data) {
            logger.debug('accept', JSON.stringify(markUserEventNotificationAsReadResponse, null, 4))
            throw new Error('Unable to mark user event notification as read')
        }
    },
})
