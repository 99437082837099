import styled from 'styled-components'
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'
import {
    Dialog, DialogProps,
} from '@/app/ui-new/components/Dialog/Dialog'
import SelectionCard, { SelectionCardProps } from '@/app/ui-new/components/SelectionCard/SelectionCard'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    color: ${(props): string => props.theme.palette.black.main};
`

export const RequiredTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    margin-top: ${(props): string => props.theme.spacing(2)}px;
    color: ${(props): string => props.theme.palette.error900.main};
`

export const StyledDialog: React.FC<DialogProps> = styled(Dialog)<DialogProps>`
    & [role='dialog']{
        padding: 0;
    }

    & [role='dialog']>svg:first-of-type {
        margin-right: ${(props): string => props.theme.spacing(4)}px;
    }
`

export const StyledFormContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    width: 750px;
    height: 100vh;
    padding-top: ${(props): string => props.theme.spacing(16)}px;
    padding-bottom: ${(props): string => props.theme.spacing(16)}px;
    padding-left: ${(props): string => props.theme.spacing(16)}px;
    padding-right: ${(props): string => props.theme.spacing(12)}px;
    overflow-y: auto;
    overflow-x: hidden;
`

export const StyledSelectionCard = styled(SelectionCard)<SelectionCardProps>`
     & [class*="MuiTypography-h6"]{
        font-weight: 500;
     }
`
