import { useEffect, useState } from 'react'
import { CarrierApiService } from '@/app/service/ApiService'
import type { Carrier } from '@lazr/openapi-client'

import { ApiServiceAction } from '@lazr/enums'
import { useDebouncedCallback } from './useDebouncedCallback'

export interface CarriesProps {
    wait?: number
    list?: {
        supportPickup?: boolean
    }
}

interface ReturnCarries {
    list?: Carrier[]
}

const useCarriers = ({ wait, list }: CarriesProps, action: ApiServiceAction): ReturnCarries => {
    const initialResults: Carrier[] = []

    // State for storing result
    const [result, setResult] = useState<Carrier[]>(initialResults)

    // Calculate milliseconds
    const milliseconds = wait ? wait * 1000 : 0

    // Function to fetchCarrierData
    const listCarriers = async (supportPickup?: boolean): Promise<void> => {
        try {
            const carriers = await CarrierApiService.list({ supportPickup } )
            const distinctObjects = carriers.filter((obj, index, self) => index === self.findIndex((t) => t.name === obj.name))
            setResult(distinctObjects)
        } catch (error) {
            console.error('Error fetching carrier data:', error)
            setResult([]) // Handle error by setting result to null
        }
    }

    // Debounced fetchData function
    const debouncedCarriers = useDebouncedCallback(async (supportPickup, action: ApiServiceAction) => {
        try {
            switch (action) {
                case ApiServiceAction.LIST:
                    await listCarriers(supportPickup)
            }
        } catch (error) {
            console.error('Error in fetchData:', error)
        }
    }, milliseconds)

    // Effect to fetch data on limit and page change
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        debouncedCarriers(list?.supportPickup, action)
    }, [setResult, action])

    return { list: result }
}

export default useCarriers
