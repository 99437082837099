import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledCarrierLogo } from './Logo.styled'

const SkeletonLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) =>
    <StyledCarrierLogo
        { ...rest }
        logo={<FontAwesomeIcon icon={[ 'fas', 'shipping-fast' ]}/>}
        name={'Carrier Logo'}
    />

export default SkeletonLogo
