import styled, { css } from 'styled-components'

import {
    Grid as MuiGrid,
    InputLabel as MuiInputLabel,
    TextField as MuiTextField,
    OutlinedTextFieldProps,
    StandardTextFieldProps,
} from '@material-ui/core'

import { spacing as MuiSpacing } from '@material-ui/system'

// export const Label = styled(MuiInputLabel) <{ $disabled: boolean}>`
//     position: unset;
//     transform: unset;
//     margin-bottom: ${(props): number => props.theme.spacing(3.5)}px;
//     color: ${(props): string => props.$disabled ? props.theme.palette.neutral200.main : props.theme.palette.neutral800.main};
    
//     font-weight: 500;
// `

export const TextField = styled(MuiTextField)<OutlinedTextFieldProps | StandardTextFieldProps>`
    /* Chrome, Safari, Edge, Opera */

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */

    input[type=number] {
        -moz-appearance: textfield;
    }
   
    label {
        color: ${(props): number => props.theme.palette.neutral500.main};
        &.Mui-focused {
            color: ${(props): number => props.theme.palette.accentPrimary700.main};
         }
         &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
     }

    & [class^="MuiInputLabel-root"] {
        color: blue; /* Change this to the desired color */
        &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    & [class^="MuiFormHelperText-root"] {
        color: ${(props): number => props.theme.palette.neutral500.main};
        margin-left: 0;
        margin-top: ${(props): number => props.theme.spacing(2)}px;

        &.Mui-disabled {
            color: ${(props): number => props.theme.palette.neutral400.main};
        }

        &.Mui-error {
            color: ${(props): number => props.theme.palette.error900.main};
        }
    }

    & [class^="MuiInputBase-root"] {
        font-family: 'Roboto';

        > input, textarea {
            height: 1.358rem;
            color: ${(props): number => props.theme.palette.black.main};
            caret-color: ${(props): number => props.theme.palette.accentPrimary700.main};

            :not(.Mui-disabled)::placeholder {
                color: ${(props): number => props.theme.palette.neutral600.main};
                opacity: 1;
            }

            :not(.Mui-disabled):focus::placeholder {
                color: transparent;
            }
        }

        > textarea {
            padding-top: ${(props): number => props.theme.spacing(0.5)}px;
        }

        [class^="MuiInputAdornment-root"] {
            padding-bottom: ${(props): string => (props.variant === 'outlined' || props.size !== 'small') ?
        'inherit' : `${props.theme.spacing(1) as string}px`};

            * {
                font-family: 'Roboto';
                line-height: 1.5rem;
            }

            /* Default alignment for p in TextField Adornment */

            p {
                margin-top: ${
    (props): string => {
        if (props.size === 'small' && !props.disabled && (!props.variant || props.variant === 'standard')) {
            return '1px'
        }
        if (props.size !== 'small' && props.disabled && (!props.variant || props.variant === 'standard')) {
            return '-1px'
        }

        return '0'
    }
};
            }

            /* FireFox */
            @-moz-document url-prefix() {
                p {
                    margin-top: ${
    (props): string => {
        if (props.size !== 'small' && (!props.variant || props.variant === 'standard')) {
            return '-1px'
        }

        return '0'
    }
};
                }
            }
        }

        &:hover {
            fieldset {
                border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }

            :not(.Mui-disabled)::before {
                border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }
        }

        &.Mui-focused {
            fieldset {
                border-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }

            ::after {
                border-bottom-color: ${(props): number => props.theme.palette.accentPrimary700.main};
            }
        }

        &.Mui-error {
            fieldset {
                border-color: ${(props): number => props.theme.palette.error900.main};
            }

            input, textarea {
                caret-color: ${(props): number => props.theme.palette.error900.main};
            }

            ::after {
                border-bottom-color: ${(props): number => props.theme.palette.error900.main};
            }
        }

        &.Mui-disabled {
            background-color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral.main : 'inherit'};

            input::placeholder, textarea::placeholder {
                color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
                opacity: 1;
            }

            input, textarea {
                color: ${(props): number => props.variant === 'outlined' ? props.theme.palette.neutral500.main : 'inherit'};
            }

            [class^="MuiInputAdornment-root"] * {
                color: ${(props): number => props.theme.palette.neutral300.main};
            }

            fieldset {
                border-color: ${(props): number => props.theme.palette.neutral.main};
            }
        }
`

export const Grid = styled(MuiGrid)(MuiSpacing)
