import { Currency, UuidV4, ConsolidationType, InvoiceEmailSendingStatus } from '@lazr/openapi-client'
import { Invoice } from './Invoice'

export interface InvoiceConsolidationProps {
    id: UuidV4
    createdAt: string
    type: ConsolidationType
    number: number
    organizationId: UuidV4
    invoiceDate: string
    dueDate: string
    currency: Currency
    subtotal: number
    taxesTotal: number
    invoices: Invoice[]
    clientOrganizationName: string
    billingName: string
    billingAddress: string
    billingAddress2: string
    billingAddress3: string
    billingCity: string
    billingState: string
    billingPostalCode: string
    billingCountry: string
    emailSentStatus?: InvoiceEmailSendingStatus
    billingEmails: string[]
}
export class InvoiceConsolidation {
    public id: UuidV4
    public createdAt: string
    public type: ConsolidationType
    public number: number
    public organizationId: UuidV4
    public currency: Currency
    public invoiceDate: string
    public dueDate: string
    public subtotal: number
    public taxesTotal: number
    public invoices: Invoice[]
    public clientOrganizationName: string
    public billingName: string
    public billingAddress: string
    public billingAddress2: string
    public billingAddress3: string
    public billingCity: string
    public billingState: string
    public billingPostalCode: string
    public billingCountry: string
    public emailSentStatus?: InvoiceEmailSendingStatus
    public billingEmails: string[]

    constructor (props: InvoiceConsolidationProps) {
        this.id = props.id
        this.createdAt = props.createdAt
        this.type = props.type
        this.number = props.number
        this.organizationId = props.organizationId
        this.invoiceDate = props.invoiceDate
        this.currency = props.currency
        this.dueDate = props.dueDate
        this.subtotal = props.subtotal
        this.taxesTotal = props.taxesTotal
        this.invoices = props.invoices
        this.clientOrganizationName = props.clientOrganizationName
        this.billingName = props.billingName
        this.billingAddress = props.billingAddress
        this.billingAddress2 = props.billingAddress2
        this.billingAddress3 = props.billingAddress3
        this.billingCity = props.billingCity
        this.billingState = props.billingState
        this.billingPostalCode = props.billingPostalCode
        this.billingCountry = props.billingCountry
        this.emailSentStatus = props.emailSentStatus
        this.billingEmails = props.billingEmails
    }
}
