import { AutocompletePrediction } from '../pages/components/PlacesAutocomplete'
import {
    Address,
    Country,
    CoverageCommodityType,
    Lane,
    PackageType,
    Search,
    SearchRfq,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqCustomsDetail,
    ResultDocument,
} from '@/app/model'
import { AddressType, EquipmentType, Provider, TariffType, TransportType } from '@lazr/openapi-client'
import { ValidatedAddress } from './ValidatedAddress'
import { LabelSizeType, PaymentTypeOption } from '@lazr/enums'

export type InvalidationDialog = {
    isInvalidationDialogOpen?: boolean
    invalidationFunction?: (...args: any[]) => void
    invalidationArguments?: any
}
export interface MarketPlace {
    order: Partial<Search>
    handlingUnitAccessorialList: SearchRfqAccessorial[]
    serviceAccessorialList: SearchRfqAccessorial[]
    originAccessorialList: SearchRfqAccessorial[]
    destinationAccessorialList: SearchRfqAccessorial[]
    packageTypeList: PackageType[]
    coverageCommodityTypeList: CoverageCommodityType[]
    requiredOriginFieldsEdited: boolean
    requiredDestinationFieldsEdited: boolean
    originFullAddress: AutocompletePrediction | null
    destinationFullAddress: AutocompletePrediction | null
    dutyBillFullAddress: AutocompletePrediction | null
    customsBrokerFullAddress: AutocompletePrediction | null
    lastCompletedStep: MarketPlaceStep
    currentStep: MarketPlaceStep
    isCoverageExclusionsAccepted: boolean
    isQuoteFetchingComplete: boolean
    marketPlaceErrors: MarketPlaceErrors
    invalidationDialog: InvalidationDialog
    promptModalLeave: boolean
    originAddressBookAddress: Address | null
    destinationAddressBookAddress: Address | null
    originSaveToAddressBook: boolean
    destinationSaveToAddressBook: boolean
    selectedValidatedOriginAddress: ValidatedAddress | null
    selectedValidatedDestinationAddress: ValidatedAddress | null
    openReasonsModal: boolean
    isPrepared: boolean
    openFedexGroundModal: boolean
    paymentType: PaymentTypeOption
    collect: Partial<Collect> | null
    userLanes: Lane[] | null
    selectedLane: Lane | null
}

export interface MarketPlaceErrors {
    rfqIsDirty: Partial<Record<keyof SearchRfq, boolean>>
    cargoIsDirty: Partial<Record<keyof SearchRfqHandlingUnit, boolean>>[]
    rfqErrors: Partial<Record<keyof SearchRfq, string>>
    cargoErrors: Partial<Record<keyof SearchRfqHandlingUnit, string>>[]
    customsDetailErrors: Partial<Record<keyof SearchRfqCustomsDetail, string>>[]
    customsDetailIsDirty: Partial<Record<keyof SearchRfqCustomsDetail, boolean>>[]
    rfqCustomsErrors: Partial<Record<keyof SearchRfq, string>>
    rfqCustomsIsDirty: Partial<Record<keyof SearchRfq, boolean>>
    handlingUnitLinkErrors: string[]
    handlingUnitLinkIsDirty: boolean[]
    isSubmitted: boolean
}

export interface AddressInformation {
    state?: string | null
    country?: Country | null
    city?: string | null
    streetAddress?: string | null
    streetAddress2?: string | null
    postalCode?: string | null
    addressType?: AddressType | null
    detectedAddressType?: AddressType | null
    accessorials?: SearchRfqAccessorial[]
    pickupDate?: string | null
    requiredOriginFieldsEdited: boolean
    requiredDestinationFieldsEdited: boolean
    fullAddress: AutocompletePrediction | null
    originAddressBookAddress: Address | null
    destinationAddressBookAddress: Address | null
}

export interface AddressInformationErrors {
    state?: string
    country?: string
    city?: string
    streetAddress?: string
    streetAddress2?: string
    postalCode?: string
    pickupDate?: string
}

export interface CompleteCustomsOrderStepErrors {
    b13aFilingOption?: string
    exportComplianceStatement?: string
    billDutiesToParty?: string
    importExport?: string
    dutyBillToCity?: string
    dutyBillToContactEmail?: string
    dutyBillToCompanyName?: string
    dutyBillToContactName?: string
    dutyBillToContactPhone?: string
    dutyBillToCountry?: string
    dutyBillToPostalCode?: string
    dutyBillToState?: string
    dutyBillToStreetAddress?: string
    dutyBillToAccountNumber?: string
    reasonForExport?: string
    nonDeliveryHandling?: string
    otherReasonForExport?: string
    customsBroker?: string
    customsBrokerCity?: string
    customsBrokerEmail?: string
    customsBrokerContactName?: string
    customsBrokerContactPhone?: string
    customsBrokerContactPhoneExt?: string
    customsBrokerCountry?: string
    customsBrokerPostalCode?: string
    customsBrokerState?: string
    customsBrokerStreetAddress?: string
    customsBrokerStreetAddress2?: string
    customsBrokerAccountNumber?: string
    taxIdentificationNumber?: string
    consigneeTaxIdentificationNumber?: string
    thirdPartyTaxIdentificationNumber?: string
    incotermsType?: string
    commercialInvoiceUserProvided?: string
    shipmentDocumentType?: string
}

export interface CompleteOrderHazmatStepErrors {
    hazmatClass?: string
    hazmatMode?: string
}

export interface CompleteOrderStepErrors {
    companyName?: string
    contactName?: string
    contactPhone?: string
    contactEmails?: string
    openTime?: string
    closeTime?: string
}

export interface TransportInformation {
    rfq: Partial<SearchRfq> | undefined
    requiredDestinationFieldsEdited: boolean
}

export interface ProgressSummaryInformation{
    lastCompletedStep: MarketPlaceStep
    currentStep: MarketPlaceStep
    isQuoteFetchingComplete: boolean
    handlingUnitAccessorialList: SearchRfqAccessorial[]
    serviceAccessorialList: SearchRfqAccessorial[]
    originAccessorialList: SearchRfqAccessorial[]
    destinationAccessorialList: SearchRfqAccessorial[]
    packageTypeList: PackageType[]
    coverageCommodityTypeList: CoverageCommodityType[]
}

export enum MarketPlaceStep {
    SHIPMENT_INFORMATION = 0,
    MARKETPLACE = 1,
    COMPLETE_ORDER = 2,
    CONFIRMATION = 3,
    COMPLETED = 4,
}

export interface AddressConfirmationInformation {
    streetAddress: string | null
    streetAddress2: string | null
    city: string | null
    state: string | null
    country: Country | null
    postalCode: string | null
    pickupDate: string | null
    companyName: string | null
    contactName: string | null
    contactEmails: string[] | null
    notify: boolean | null
    contactPhone: string | null
    contactPhoneExtension: string | null
    poNumber: string | null
    openTime: string | null
    closeTime: string | null
    accessorials: SearchRfqAccessorial[]
    instructions: string | null
    transitDaysMin: number
    transitDaysMax: number
    estimatedTimeOfArrival: string | null
    estimatedDeliveryDate: string | null
}

export interface CompleteCustomsOrderInformation {
    rfq: Partial<SearchRfq> | undefined
    dutyBillFullAddress: AutocompletePrediction | null
    customsBrokerFullAddress: AutocompletePrediction | null
    documents: ResultDocument[] | null
    customsBrokerAddressMaxCharacter: number | null
}

export interface CompleteOrderInformation {
    streetAddress: string | null
    streetAddress2: string | null
    streetAddress3: string | null
    city: string | null
    state: string | null
    country:  Country | null
    postalCode: string | null
    addressType?: AddressType | null
    detectedAddressType?: AddressType | null
    pickupDate: string | null
    accessorials: SearchRfqAccessorial[]
    contactName: string | null
    contactEmails: string[] | null
    contactPhone: string | null
    phoneExtension: string | null
    companyName: string | null
    notify: boolean | null
    openTime: string | null
    closeTime: string | null
    instructions: string | null
    poNumber: string | null
    customQuoteRequested: boolean
    addressBookAddress: Address | null
    saveToAddressBook: boolean
    instructionsMaxCharacter: number | null
    companyNameMaxCharacter: number | null
    contactNameMaxCharacter: number | null
}

export interface TransportConfirmationInformation {
    isBookedWithCoverage: boolean | null
    isCoverageExclusionsAccepted?: boolean | null
    commodityType: CoverageCommodityType | null
    shipmentCoverageValue: number | null
    customsBroker: string | null
    clientNumber: string
    threePlNumber: string
    carrierDisplayName: string
    transportType: TransportType
    equipmentType: EquipmentType | null
    provider: Provider
    serviceLevel: string
    transitDaysMax: number
    transitDaysMin: number
    carrierIdValue: string
    tariffType: TariffType
    isDocumentOnly: boolean | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
}

export interface Collect {
    carrier: {
        id: string
        name: string
        transportType: TransportType
    }
    accountNumber: string
}
