/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Dominique`]: `Dominique`,
    [`Flooring industry`]: `Flooring industry`,
    [`Bravo, excellent platform! It's hard to go back, because we are spoiled at LAZR by its efficiency, its practicality, and obviously the prices. You're going to make a lot of people happy here, especially in the flooring warehouse.`]: `Bravo, excellent platform! It's hard to go back, because we are spoiled at LAZR by its efficiency, its practicality, and obviously the prices. You're going to make a lot of people happy here, especially in the flooring warehouse.`,
    [`Tasia`]: `Tasia`,
    [`Air filtration industry`]: `Air filtration industry`,
    [`Working with Lazr has been a great journey. Not only is their customer service more than top notch, but their user friendly site makes shipping anywhere as easy as a few clicks. Any issues that we've run into have been addressed very promptly, avoiding any shipping hiccups. They've also been great with dealing with third party companies on our behalf to ensure that shipments are picked up and delivered on time. 5-star service.`]: `Working with Lazr has been a great journey. Not only is their customer service more than top notch, but their user friendly site makes shipping anywhere as easy as a few clicks. Any issues that we've run into have been addressed very promptly, avoiding any shipping hiccups. They've also been great with dealing with third party companies on our behalf to ensure that shipments are picked up and delivered on time. 5-star service.`,
    [`Amélie`]: `Amélie`,
    [`Toy industry`]: `Toy industry`,
    [`I must say that your team is dedicated and very helpful. It has been a pleasure to work with each and every one of you, and your service is fantastic. Believe me, in 2022, it's almost impossible to come by.\nI believe it is important to highlight good work and a good partnership when you come across it.`]: `I must say that your team is dedicated and very helpful. It has been a pleasure to work with each and every one of you, and your service is fantastic. Believe me, in 2022, it's almost impossible to come by.\nI believe it is important to highlight good work and a good partnership when you come across it.`,
    [`Matthew`]: `Matthew`,
    [`Metal manufacturing`]: `Metal manufacturing`,
    [`I appreciate your follow-ups, and the service you provide is always excellent! Thank you for all your support!`]: `I appreciate your follow-ups, and the service you provide is always excellent! Thank you for all your support!`,
    ['Johanne']: 'Johanne',
    ['We have used another shipping software for a few years before switching to Lazr.  Their software is much more complete and offers a better visibility all around.  Our customer were very happy with the way Lazr allows us to work.']: 'We have used another shipping software for a few years before switching to Lazr.  Their software is much more complete and offers a better visibility all around.  Our customer were very happy with the way Lazr allows us to work.',
    ['Heavy Machinery Manufacturer']: 'Heavy Machinery Manufacturer',
    ['Charles']: 'Charles',
    ['Retail Distributor']: 'Retail Distributor',
    ['Using Lazr, we managed to save thousands every month.  We have become aware of some freight expenses we did not know about and were able to take action to stop spending on services we did not need.']: 'Using Lazr, we managed to save thousands every month.  We have become aware of some freight expenses we did not know about and were able to take action to stop spending on services we did not need.',
    ['Stephan']: 'Stephan',
    ['Pulp and Paper manufacturer']: 'Pulp and Paper manufacturer',
    ['We have 6 locations, Lazr allowed us to bring all the shipments together in one place.  We now have visibility across all our transportation volume for freight and parcel.']: 'We have 6 locations, Lazr allowed us to bring all the shipments together in one place.  We now have visibility across all our transportation volume for freight and parcel.',
}
