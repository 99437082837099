/* eslint-disable max-len */
import React, { useCallback, useRef } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@/app/ui-new/components/IconButton/IconButton'
import {
    StyledIconButton,
    StyledSwipeableViews,
} from '@/app/ui-new/pages/landing/components/TestimonialsCarousel/TestimonialsCarousel.styled'
import { Box } from '@material-ui/core'
import StepCard from '@/app/ui-new/pages/landing/components/TestimonialsCarousel/StepCard'


const TestimonialsCarousel: React.FunctionComponent<Props> = (props) => {
    const theme = useTheme()
    const [ activeStep, setActiveStep ] = React.useState(0)
    const intervalIdRef = useRef<number>()
    const maxSteps = props.testimonials.length
    const elementRef: React.RefObject<HTMLDivElement> = useRef(null)

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }, [ setActiveStep ])

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }, [ setActiveStep ])

    const handleStepChange = useCallback ((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    React.useEffect(() => {
        if (intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
        }

        return (() => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        })
    }, [ intervalIdRef ])

    return (
        <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            // @ts-ignore
            ref={elementRef}
        >
            <Box width={380}>
                <Box display='flex' flexDirection='column' justifyContent='center'>
                    <Box position='relative'>
                        { activeStep !== 0 &&
                            <Box position='absolute' top={'50%'} left={0}>
                                <StyledIconButton size="small" onClick={handleBack}>
                                    {<FontAwesomeIcon  icon={[ 'far', 'arrow-left' ]}/>}
                                </StyledIconButton>
                            </Box>
                        }
                        <StyledSwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {
                                props.testimonials.map((testimonial, index) => <StepCard
                                    key={index}
                                    name={testimonial.name}
                                    jobTitle={testimonial.jobTitle}
                                    primaryContent={testimonial.text}
                                    primaryContentColor={'#FFFFFF'}
                                    secondaryContentColor={'#C4C4C4'}
                                />)
                            }
                        </StyledSwipeableViews>
                        { activeStep < maxSteps - 1 &&
                            <Box position='absolute' top={'50%'} right={0}>
                                <StyledIconButton size="small" onClick={handleNext}>
                                    {<FontAwesomeIcon  icon={[ 'far', 'arrow-right' ]}/>}
                                </StyledIconButton>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export interface Props {
    testimonials: {
        text: string
        name: string
        jobTitle: string
    }[]
}

export default TestimonialsCarousel

