import { AnyAction, Reducer } from 'redux'
import { initialApplyPaymentInfo } from '../store/InitialState'
import * as types from '../constants'
import { ApplyPaymentInfo } from '@/app/ui/definitions/ApplyPaymentInfo'
const reducer: Reducer = (state: ApplyPaymentInfo = initialApplyPaymentInfo,
    action: AnyAction): ApplyPaymentInfo => {
    switch (action.type) {
        case types.ADD_APPLY_PAYMENT_INVOICE: {
            const newInvoices = state.invoices
            newInvoices.push(action.payload)

            return {
                ...state,
                invoices: newInvoices,
            }
        }
        case types.DELETE_APPLY_PAYMENT_INVOICE: {
            const newInvoices = state.invoices
            newInvoices.splice(action.payload, 1)

            return {
                ...state,
                invoices: newInvoices,
            }
        }
        case types.ADD_APPLY_PAYMENT_CONSOLIDATION: {
            const newConsolidations = state.consolidations
            newConsolidations.push(action.payload)

            return {
                ...state,
                consolidations: newConsolidations,
            }
        }
        case types.DELETE_APPLY_PAYMENT_CONSOLIDATION: {
            const newConsolidations = state.consolidations
            newConsolidations.splice(action.payload, 1)

            return {
                ...state,
                consolidations: newConsolidations,
            }
        }
        case types.SET_APPLY_PAYMENT_INVOICES: {
            return {
                ...state,
                invoices: action.payload.invoices,
            }
        }
        case types.SET_APPLY_PAYMENT_CONSOLIDATIONS: {
            return {
                ...state,
                consolidations: action.payload.consolidations,
            }
        }

        case types.UPDATE_APPLY_PAYMENT_INVOICE: {
            const newInvoices = state.invoices
            newInvoices[action.payload.index] = action.payload.invoice

            return {
                ...state,
                invoices: newInvoices,
            }
        }
        case types.UPDATE_APPLY_PAYMENT_CONSOLIDATION: {
            const newConsolidations = state.consolidations
            newConsolidations[action.payload.index] = action.payload.consolidation

            return {
                ...state,
                consolidations: newConsolidations,
            }
        }
        case types.RESET_APPLY_PAYMENT: {
            state.invoices = []
            state.consolidations = []

            return {
                ...state,
            }
        }
        default:
            return state
    }
}


export default reducer
