import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TextFieldIconUser = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`

export const TextFieldIconUserCircle = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`

export const TextFieldIconEnvelope = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`

export const TextFieldIconLockAlt = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`

export const TextFieldIconEye = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral700.main as string};
`

export const TextFieldIconEyeSlash = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral700.main as string};
`

export const TextFieldIconGreenCheck = styled(FontAwesomeIcon)`
    font-size: 1.8rem;
    color: ${(props) => props.theme.palette.success700.main as string};
`

export const TextFieldIconRedX = styled(FontAwesomeIcon)`
    font-size: 1.8rem;
    color: ${(props) => props.theme.palette.error700.main as string};
`

export const TextFieldIconSearch = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`

export const TextFieldIconDollarSign = styled(FontAwesomeIcon)`
    font-size: 1.3rem;
    color: ${(props) => props.theme.palette.neutral400.main as string};
`
