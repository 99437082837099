import React from 'react'
import {
    useMediaQuery,
    Typography,
} from '@material-ui/core'
import {
    Copyright,
    Grid,
    Link,
    Logo, StyledAnchor,
    Wrapper,
    // JoinOurNewsletter,
    // StyledPrimaryButton,
    // TypographySubscribeText,
    // StyledTextField,
} from './WebsiteFooterDesktop.styled'
import moment from 'moment'

import { privacyPolicyRoute, termsOfServiceRoute, helpCenterRoute } from '@/app/ui/routes'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './WebsiteFooterDesktop.i18n'

const WebsiteFooterDesktop: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    const isBetween1324And900 = useMediaQuery('(min-width: 900px) and (max-width: 1324px)')

    return !isBetween1324And900 ?
        <Wrapper id='footer'>
            <Grid container justifyContent='space-between' alignItems='stretch' spacing={10} direction={'row'}>
                <Grid item>
                    <Grid container spacing={5} direction='column'>
                        <Grid item>
                            <Logo/>
                        </Grid>
                        <Grid item>
                            <Typography variant='body1'>{t('Quote faster.')} {t('Ship smarter.')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {getCompany()}
                </Grid>
                <Grid item>
                    {getQuickLinks()}
                </Grid>
                <Grid item>
                    {getLegal()}
                </Grid>
                <Grid item>
                    {getFollowUs()}
                </Grid>
            </Grid>
            {/* <JoinOurNewsletter>{getJoinOurNewsletter()}</JoinOurNewsletter> */}

            <Copyright>
                <Typography variant='subtitle1'>{`Lazr Freight Inc. © 2019-${moment().year()}`}</Typography>
            </Copyright>
        </Wrapper>
        :
        <Wrapper id='footer'>
            <Grid container justifyContent='space-between' alignItems='stretch' spacing={10} direction={'row'}>
                <Grid item xs={4}>
                    <Grid container spacing={5} direction='column'>
                        <Grid item>
                            <Logo/>
                        </Grid>
                        <Grid item>
                            <Typography variant='body1'>{t('Quote faster.')} {t('Ship smarter.')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    {getCompany()}
                </Grid>
                <Grid item xs={4}>
                    {getQuickLinks()}
                </Grid>
                <Grid item xs={4}>
                    {/* {getJoinOurNewsletter()} */}
                </Grid>
                <Grid item xs={4}>
                    {getLegal()}
                </Grid>
                <Grid item xs={4}>
                    {getFollowUs()}
                </Grid>
            </Grid>
            <Copyright>
                <Typography variant='subtitle1'>{`Lazr Freight Inc. © 2019-${moment().year()}`}</Typography>
            </Copyright>
        </Wrapper>
}

export interface Props {
}

export default WebsiteFooterDesktop

const getCompany = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useI18n(i18n)

    return <Grid container direction='column' alignItems='flex-start' spacing={8}>
        <Grid item >
            <Typography variant='h6'>{t('Company')}</Typography>
        </Grid>
        <Grid item container direction='column' alignItems='flex-start' spacing={6}>
            <Grid item >
                <Typography variant='subtitle1'>200-580 Boul Du Curé-Boivin</Typography>
                <Typography variant='subtitle1'>Boisbriand, Qc, CA, J7G 2A7</Typography>
            </Grid>
            <Grid item >
                <Typography variant='subtitle1'>sales@lazr.io</Typography>
            </Grid>
            <Grid item >
                <Typography variant='subtitle1'>(514) 418-6552 ext. 906</Typography>
            </Grid>
            <Grid item >
                <Typography variant='subtitle1'>(877) 493-5297 ext. 906</Typography>
            </Grid>
        </Grid>
    </Grid>
}

const getQuickLinks = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useI18n(i18n)

    return <Grid container direction='column' alignItems='flex-start' spacing={8}>
        <Grid item >
            <Typography variant='h6'>{t('Quick links')}</Typography>
        </Grid>
        <Grid item container direction='column' alignItems='flex-start' spacing={6}>
            <Grid item >
                <Link to='/'>
                    <Typography variant='body1'>{t('Home')}</Typography>
                </Link>
            </Grid>
            <Grid item >
                <StyledAnchor href={t('https://resources.lazr.io/career')} target="_blank">
                    <Typography variant='body1'>{t('Careers')}</Typography>
                </StyledAnchor>
            </Grid>
            <Grid item >
                <StyledAnchor href={t('https://resources.lazr.io/blog')} target="_blank">
                    <Typography variant='body1'>{t('Blog')}</Typography>
                </StyledAnchor>
            </Grid>
            {/*<Grid item >*/}
            {/*    <Link to='/'>*/}
            {/*        <Typography variant='body1'>{t('Lazr Academy')}</Typography>*/}
            {/*    </Link>*/}
            {/*</Grid>*/}
            <Grid item >
                <Link to={helpCenterRoute.path}>
                    <Typography variant='body1'>{t('Help center')}</Typography>
                </Link>
            </Grid>
            <Grid item >
                <Link to='/'>
                    <Typography variant='body1'>{t('FAQ')}</Typography>
                </Link>
            </Grid>
        </Grid>
    </Grid>
}

const getLegal = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useI18n(i18n)

    return <Grid container direction='column' alignItems='flex-start' spacing={8}>
        <Grid item >
            <Typography variant='h6'>{t('Legal')}</Typography>
        </Grid>
        <Grid item container direction='column' alignItems='flex-start' spacing={6}>
            <Grid item>
                <Link to={termsOfServiceRoute.path}>
                    <Typography variant="body1">{t('Terms and conditions')}</Typography>
                </Link>
            </Grid>
            <Grid item>
                <Link to={privacyPolicyRoute.path}>
                    <Typography variant="body1">{t('Privacy policy')}</Typography>
                </Link>
            </Grid>
        </Grid>
    </Grid>
}

const getFollowUs = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useI18n(i18n)

    return <Grid container direction='column' alignItems='flex-start' spacing={8}>
        <Grid item >
            <Typography variant='h6'>{t('Follow us')}</Typography>
        </Grid>
        <Grid item container direction='column' alignItems='flex-start' spacing={6}>
            <Grid item >
                <StyledAnchor href="https://www.facebook.com/profile.php?id=100093084356478" target="_blank">
                    <Typography variant='body1'>{t('Facebook')}</Typography>
                </StyledAnchor>
            </Grid>
            <Grid item >
                <StyledAnchor href="https://www.linkedin.com/company/lazr-freight/?viewAsMember=true" target="_blank">
                    <Typography variant='body1'>{t('LinkedIn')}</Typography>
                </StyledAnchor>
            </Grid>
            <Grid item >
                <StyledAnchor href="https://www.instagram.com/lazrfreight/?hl=en" target="_blank">
                    <Typography variant='body1'>{t('Instagram')}</Typography>
                </StyledAnchor>
            </Grid>
        </Grid>
    </Grid>
}

// const getJoinOurNewsletter = () => {
//     // eslint-disable-next-line react-hooks/rules-of-hooks
//     const { t } = useI18n(i18n)

//     return <Grid container direction='column' alignItems='flex-start' spacing={6}>
//         <Grid item >
//             <Typography variant='h6'>{t('Join our newsletter')}</Typography>
//         </Grid>
//         <Grid item container direction='column' alignItems='stretch' spacing={4}>
//             <Grid item>
//                 <StyledTextField
//                     variant='outlined'
//                     size='small'
//                     fullWidth
//                     placeholder={t('Email')}
//                 />
//             </Grid>
//             <Grid item >
//                 <StyledPrimaryButton fullWidth href="/register">
//                     <TypographySubscribeText variant='body1'>
//                         {t('Subscribe')}
//                     </TypographySubscribeText>
//                 </StyledPrimaryButton>
//             </Grid>
//         </Grid>
//     </Grid>
// }
