import React from 'react'
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { transparentize } from 'polished'
import { ToggleButtonProps } from '@material-ui/lab/ToggleButton/ToggleButton'

export const StyledContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    background-color: ${(props): string => props.theme.palette.white.main};
`

export const StyledTypographyLabel: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    color: ${(props): number => props.theme.palette.neutral700.main};
`

export const StyledTypographyH1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
`

export const StyledToggleButton: React.FC<ToggleButtonProps> = styled(ToggleButton)<ToggleButtonProps>`
    border-color ${(props): number => props.theme.palette.neutral300.main};
    color: ${(props): number => props.theme.palette.neutral700.main};
    text-transform: none;
    width: 115px;
    border-radius: 8px;

    &:hover,
    &.Mui-selected:hover{
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.accentPrimary300.main)};
    }

    &.Mui-selected{
        background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main)};
        color: ${(props): number => props.theme.palette.primary.main};

        & [class*="MuiToggleButton-label"] svg{
            font-size: 16px;
            margin-right: 0.5rem;
        }
    }
`

export const StyledTypographySwitcher: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
`
