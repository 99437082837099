/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    'select between': 'select between',
    'must be at least qtd characters long':'must be at least ≤≤qtd≥≥ characters long',
    or: 'or',
    'field is required': 'field is required',
    'Address type': 'Address type',
    Commercial: 'Commercial',
    Residential: 'Residential',
    Street: 'Street',
    Apt: 'Apt',
    City: 'City',
    'Postal code': 'Postal Code',
    'Prov/St': 'Prov/St',
    Country: 'Country',
    'Company name': 'Company name',
    // 'Shipping hours': 'Shipping hours',
    // to: 'to',
    // Opening: "Opening",
    // Closing: "Closing",
    'Contact name':'Contact name',
    'Phone number':'Phone number',
    'Ext.':'Ext.',
    'Contact emails':'Contact emails',
    'Enter an email address':'Enter an email address',
    'must be at least one email':'must be at least one email'
}
