import React from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = <T = Record<string, string>>(): T => {
    const { search } = useLocation()
    const query: Record<string, string> = {}
    React.useMemo(() => new URLSearchParams(search), [ search ])
        .forEach((value, key) => {
            query[key] = value
        })

    return query as unknown as T
}
