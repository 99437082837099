import styled from 'styled-components'
import { InputAdornment } from '@material-ui/core'

export const TextFieldAdornment = styled(InputAdornment)`
    & p{
        font-size: 1rem;
        color: ${(props) => props.theme.palette.neutral400.main as string};
    }

    & svg+button{
        margin-left: ${(props) => props.theme.spacing(2.25)}px;
    }
`
