import React, { useState } from 'react'
import { Wrapper, Button, IconContainer, Box } from './ResetPassword.styled'
import { Redirect, useHistory } from 'react-router-dom'
import { logger } from '../../../logger'
import * as EmailValidator from 'email-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MIN_PASSWORD_LENGTH } from '../../redux/constants'
import PasswordField from '../../components/PasswordField'
import {
    FormControl,
    Theme,
    TextField,
    Typography,
    useTheme, makeStyles,
} from '@material-ui/core'
// import { recordUserForgotPasswordRequested } from '../../../event/authentication/UserForgotPasswordRequested'
// import { recordUserForgotPasswordRequestFailed } from '../../../event/authentication/UserForgotPasswordRequestFailed'
// import { recordUserForgotPasswordVerified } from '../../../event/authentication/UserForgotPasswordVerified'
// import { recordUserForgotPasswordVerificationFailed } from '../../../event/authentication/UserForgotPasswordVerificationFailed'
import { AppTheme } from '../../definitions/Theme'
import { UserApiService } from '../../../service/ApiService'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '../../redux/selectors'
import { dashboardRoute } from '../../routes'

const STEP_EMAIL = 'EMAIL'
const STEP_VERIFY_CODE = 'VERIFY_CODE'
const STEP_RESULT_SUCCESS = 'RESULT_SUCCESS'
const STEP_RESULT_FAILURE = 'RESULT_FAILURE'

const secondsToResendVerificationEmail = 60

const ResetPassword: React.FunctionComponent<Props> = ({ isModal = false, setIsResetPassword }) => {
    const history = useHistory()

    const [ email, setEmail ] = useState<string>('')
    const [ emailError, setEmailError ] = useState<boolean>(false)
    const [ emailErrorText, setEmailErrorText ] = useState<string>('')

    const [ verificationCode, setVerificationCode ] = useState<string>('')
    const [ verificationCodeError, setVerificationCodeError ] = useState<boolean>(false)
    const [ verificationCodeErrorText, setVerificationCodeErrorText ] = useState<string>('')

    const [ newPassword, setNewPassword ] = useState<string>('')
    const [ newPasswordError, setNewPasswordError ] = useState<boolean>(false)
    const [ newPasswordErrorText, setNewPasswordErrorText ] = useState<string>('')

    const [ confirmPassword, setConfirmPassword ] = useState<string>('')
    const [ confirmPasswordError, setConfirmPasswordError ] = useState<boolean>(false)
    const [ confirmPasswordErrorText, setConfirmPasswordErrorText ] = useState<string>('')

    const [ currentStep, setCurrentStep ] = useState<string>(STEP_EMAIL)

    const [ isResetPasswordInProgress, setIsResetPasswordInProgress ] = useState<boolean>(false)
    const [ isChangePasswordInProgress, setIsChangePasswordInProgress ] = useState<boolean>(false)

    const [ showResendButton, setShowResendButton ] = useState<boolean>(false)
    const [ processingResendButton, setProcessingResendButton ] = useState<boolean>(false)

    const theme = useTheme<AppTheme & Theme>()
    const useHelperTextStyles = makeStyles(() => ({
        root: {
            fontSize: 'medium',
        },
    }))
    const helperTextStyles = useHelperTextStyles()

    const getButtonLabel = () => isModal ? 'Go back to sign in' : 'Go back to the sign in page'

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setter: (val: string) => void): void => {
        setter(event.target.value)
    }

    const handleSignInClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault()
        if (!isModal) {
            history.push('/signIn')
        }
        else if (setIsResetPassword) {
            setIsResetPassword(false)
        }
    }

    const isResetPasswordFormValid = (): boolean => {
        let isValid = true

        if (!email) {
            setEmailError(true)
            setEmailErrorText('Email cannot be empty')
            isValid = false
        } else if (!EmailValidator.validate(email)) {
            setEmailError(true)
            setEmailErrorText('Email is not a valid format')
            isValid = false
        } else {
            setEmailError(false)
            setEmailErrorText('')
        }

        return isValid
    }

    const isVerifyCodeFormValid = (): boolean => {
        let isValid = true

        if (!verificationCode) {
            setVerificationCodeError(true)
            setVerificationCodeErrorText('Verification code cannot be empty')
            isValid = false
        } else if (!/^\d+$/.test(verificationCode)) {
            setVerificationCodeError(true)
            setVerificationCodeErrorText('Verification code must be a number')
            isValid = false
        } else {
            setVerificationCodeError(false)
            setVerificationCodeErrorText('')
        }

        if (!newPassword) {
            setNewPasswordError(true)
            setNewPasswordErrorText('Password cannot be empty')
            isValid = false
        } else if (newPassword.length < MIN_PASSWORD_LENGTH) {
            setNewPasswordError(true)
            setNewPasswordErrorText(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`)
            isValid = false
        } else {
            setNewPasswordError(false)
            setNewPasswordErrorText('')
        }

        if (confirmPassword !== newPassword) {
            setConfirmPasswordError(true)
            setConfirmPasswordErrorText('Password and confirmation do not match')
            isValid = false
        } else {
            setConfirmPasswordError(false)
            setConfirmPasswordErrorText('')
        }

        return isValid
    }

    const handleResetPassword = async (event?: React.FormEvent): Promise<void> => {
        event?.preventDefault()

        if (isResetPasswordInProgress) {
            return
        }

        if (!isResetPasswordFormValid()) {
            return
        }

        try {
            setIsResetPasswordInProgress(true)

            await UserApiService.requestPasswordReset(email)

            // recordUserForgotPasswordRequested()

            setCurrentStep(STEP_VERIFY_CODE)
        } catch (error: any) {
            logger.error('error resetting password:', error)

            setEmailError(true)

            if (error.message === 'UserNotConfirmedException') {
                setShowResendButton(true)
                setEmailErrorText('This email has not been verified')
            } else {
                setShowResendButton(false)
                setEmailErrorText('This email does not exist in our records')
            }

            // recordUserForgotPasswordRequestFailed(error.message, error.code)
        }

        setIsResetPasswordInProgress(false)
    }

    const handleVerifyCode = async (event: React.FormEvent): Promise<void> => {
        event.preventDefault()

        if (isChangePasswordInProgress) {
            return
        }

        if (!isVerifyCodeFormValid()) {
            return
        }

        try {
            setIsChangePasswordInProgress(true)

            await UserApiService.confirmPasswordReset(email, verificationCode, newPassword)

            // recordUserForgotPasswordVerified()

            setCurrentStep(STEP_RESULT_SUCCESS)
        } catch (error: any) {
            let hasFieldError = false

            logger.error('error resetting password:', error)

            if (error.code === 'CodeMismatchException') {
                setVerificationCodeError(true)
                setVerificationCodeErrorText('Invalid verification code provided. Please try again')
                hasFieldError = true
            }

            if (!hasFieldError) {
                setCurrentStep(STEP_RESULT_FAILURE)
            }

            // recordUserForgotPasswordVerificationFailed(error.message, error.code)
        }

        setIsChangePasswordInProgress(false)
    }

    if (useSelector(reduxGetUser)) {
        return <Redirect to={dashboardRoute.path}/>
    }

    if (currentStep === STEP_VERIFY_CODE) {
        return (
            <Wrapper $isModal={isModal}>
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                    Verification code sent, check your inbox!
                </Typography>
                <Typography component="h2" variant="subtitle2" align="center">
                    Get the verification code from your inbox and choose a new password
                </Typography>
                <form onSubmit={handleVerifyCode}>
                    <FormControl margin="normal" required fullWidth>
                        <Box mt={theme.spacing(1)} />
                        <TextField
                            name="verificationCode"
                            label="Verification code"
                            value={verificationCode}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleInputChange(e, setVerificationCode)
                                }
                            }
                            autoComplete="off"
                            error={verificationCodeError}
                            helperText={verificationCodeErrorText}
                            autoFocus
                        />
                        <Box mt={theme.spacing(1)} />
                        <PasswordField
                            name="newPassword"
                            label="New password"
                            value={newPassword}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleInputChange(e, setNewPassword)
                                }
                            }
                            autoComplete="new-password"
                            error={newPasswordError}
                            helperText={newPasswordErrorText}
                        />
                        <Box mt={theme.spacing(1)} />
                        <PasswordField
                            name="confirmPassword"
                            label="Confirm password"
                            value={confirmPassword}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>): void => {
                                    handleInputChange(e, setConfirmPassword)
                                }
                            }
                            autoComplete="new-password"
                            error={confirmPasswordError}
                            helperText={confirmPasswordErrorText}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        mt={theme.spacing(2)}
                        disabled={isChangePasswordInProgress}
                    >
                        {isResetPasswordInProgress ? 'Changing password...' : 'Save changes'}
                    </Button>
                </form>
                <Button
                    onClick={handleSignInClick}
                    fullWidth
                    color="primary"
                    size="small"
                    mt={theme.spacing(2)}
                >
                    {getButtonLabel()}
                </Button>
            </Wrapper>
        )
    }

    if (currentStep === STEP_RESULT_SUCCESS) {
        return (
            <Wrapper $isModal={isModal}>
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                    Password changed successfully!
                </Typography>
                <Box mt={10} mb={10}>
                    <IconContainer style={{ color: theme.palette.success.main }}>
                        <FontAwesomeIcon icon={[ 'far', 'check-circle' ]}/>
                    </IconContainer>
                </Box>
                <Typography component="h2" variant="body1" align="center">
                    You can now log in with your new password
                </Typography>
                <Button
                    onClick={handleSignInClick}
                    fullWidth
                    color="primary"
                    size="small"
                    mt={theme.spacing(3)}
                >
                    {getButtonLabel()}
                </Button>
            </Wrapper>
        )
    }

    if (currentStep === STEP_RESULT_FAILURE) {
        return (
            <Wrapper $isModal={isModal}>
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                    Password change failed!
                </Typography>
                <Box mt={10} mb={10}>
                    <IconContainer style={{ color: theme.palette.error.main }}>
                        <FontAwesomeIcon icon={[ 'far', 'times-circle' ]}/>
                    </IconContainer>
                </Box>
                <Typography component="h2" variant="body1" align="center">
                    Your password could not be changed
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    mt={theme.spacing(2)}
                    onClick={(): void => setCurrentStep(STEP_EMAIL)}
                >
                    Try again
                </Button>
                <Button
                    onClick={handleSignInClick}
                    fullWidth
                    color="primary"
                    size="small"
                    mt={theme.spacing(2)}
                >
                    {getButtonLabel()}
                </Button>
            </Wrapper>
        )
    }

    return (
        <Wrapper $isModal={isModal}>
            <Typography component="h1" variant="h5" align="center" gutterBottom>
                Reset password
            </Typography>
            <Typography component="h2" variant="subtitle2" align="center">
                Enter your email to reset your password
            </Typography>
            <form onSubmit={handleResetPassword}>
                <FormControl margin="normal" required fullWidth>
                    <TextField
                        FormHelperTextProps={{
                            classes:{
                                root:helperTextStyles.root,
                            },
                        }}
                        name="email"
                        label="Email"
                        value={email}
                        onChange={
                            (e: React.ChangeEvent<HTMLInputElement>): void => {
                                handleInputChange(e, setEmail)
                            }
                        }
                        autoComplete="email"
                        error={emailError}
                        helperText={emailErrorText}
                        autoFocus
                    />
                </FormControl>
                {showResendButton &&
                <Button
                    fullWidth
                    style={{ backgroundColor: theme.palette.success.main }}
                    mt={2}
                    disabled={processingResendButton}
                    onClick={async (): Promise<void> => {
                        setProcessingResendButton(true)
                        setEmailError(false)
                        setEmailErrorText('')
                        window.setTimeout(() => setProcessingResendButton(false), secondsToResendVerificationEmail * 1000)
                        await UserApiService.resendConfirmationCode(email)
                    }}
                >
                    {processingResendButton ?
                        'Email sent, please check you inbox.'
                        :
                        'Resend verification email'
                    }
                </Button>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    mt={theme.spacing(2)}
                    disabled={isResetPasswordInProgress}
                >
                    {isResetPasswordInProgress ? 'Resetting password...' : 'Reset password'}
                </Button>
                <Button
                    onClick={handleSignInClick}
                    fullWidth
                    color="primary"
                    size="small"
                    mt={theme.spacing(2)}
                >
                    {getButtonLabel()}
                </Button>
            </form>
        </Wrapper>
    )
}

export interface Props {
    isModal?: boolean
    setIsResetPassword?: (isResetPassword: boolean) => void
}

export default ResetPassword
