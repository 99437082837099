import React from 'react'
import {
    Tabs as StyledTabs,
    Tab as StyledTab,
    TabPanel as StyledTabPanel,
    TabsToolbar as StyledTabsToolbar,
    TabsToolbarBackButton as StyledTabsToolbarBackButton,
    Typography,
} from './Tabs.styled'
import { TabProps as MuiTabProps } from '@material-ui/core'

export const Tab: React.FunctionComponent<MuiTabProps> = (props) => {
    const label = <Typography variant='h6'>{props.label}</Typography>
    const newProps = { ...props, label }

    return <StyledTab {...newProps}/>
}

export const Tabs = StyledTabs
export const TabPanel = StyledTabPanel
export const TabsToolbar = StyledTabsToolbar
export const TabsToolbarBackButton = StyledTabsToolbarBackButton
