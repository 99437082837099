import React, { useState } from 'react'
import {
    BackgroundGradientLayer0,
    BackgroundGradientLayer1,
    BackgroundGradientLayer2,
    BackgroundGradientLayer3,
    BackgroundGradientLayer4,
    FlipCardsTitle,
    CardsContainer,
    Grid,
    CardMobile,
} from './CardMobile'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './FlipCardsMobile.i18n'

export const FlipCardsMobile: React.FunctionComponent = () => {
    const { t } = useI18n(i18n)

    const [ expanded, setExpanded ] = useState(Array(4).fill(false))

    const closeOtherCard = (event: any) => {
        const target = event.target.tagName === 'BUTTON' && event.target.classList.contains('clickzone') ?
            event.target :
            event.target.closest('button.clickzone')
        const cardIndex = parseInt(target.value as string, 10)
        const currentState = expanded[cardIndex]

        setExpanded(() => {
            const newState = Array(4).fill(false)
            newState[cardIndex] = !currentState

            return newState
        })
    }

    return <BackgroundGradientLayer0>
        <BackgroundGradientLayer1/>
        <BackgroundGradientLayer2/>
        <BackgroundGradientLayer3/>
        <BackgroundGradientLayer4>
            <FlipCardsTitle variant='h2'>
                {t('A freight management system that works for everyone')}
            </FlipCardsTitle>
            <CardsContainer>
                <Grid container direction='column' xs={12}>
                    <Grid item xs={12} mb={4}>
                        <CardMobile
                            front={{
                                image: <img alt={t('Lazr advantages card')} src='/images/website/flip-card-image-1.png'/>,
                                iAm: t('I\'m a'),
                                position: t('Procurement manager'),
                            }}
                            back={{
                                title: t('Access the largest logistic network for LTL & parcel shipping'),
                                keypoints: [
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_08.svg'/>,
                                        text: t('Get instant pricing from 50+ trusted carriers'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_09.svg'/>,
                                        text: t('Add your own carrier contracts to compare shipping rates'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_10.svg'/>,
                                        text: t('Autofill forms & automate billing'),
                                    },
                                ],
                                callToActionButton: <PrimaryButton fullWidth href='/register'>{t('Get Lazr for free')}</PrimaryButton>,
                            }}
                            enableHover
                            cardIndex={0}
                            expanded={expanded[0]}
                            handleExpand={closeOtherCard}
                        />
                    </Grid>

                    <Grid item xs={12} mb={4}>
                        <CardMobile
                            front={{
                                image: <img alt={t('Lazr advantages card')} src='/images/website/flip-card-image-2.png'/>,
                                iAm: t('I\'m a'),
                                position: t('Warehouse Manager'),
                            }}
                            back={{
                                title: t('Easily track all shipments in one dashboard'),
                                keypoints: [
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_07.svg'/>,
                                        text: t('Unified dashboard with precise tracking & visibility'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_06.svg'/>,
                                        text: t('EDI capabilities for efficient supply chain communications'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_10.svg'/>,
                                        text: t('Autofill forms & automate billing'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_03.svg'/>,
                                        text: t('Enhance existing workflows with our API integrations'),
                                    },
                                ],
                                callToActionButton: <PrimaryButton fullWidth href='/register'>{t('Get Lazr for free')}</PrimaryButton>,
                            }}
                            enableHover
                            cardIndex={1}
                            expanded={expanded[1]}
                            handleExpand={closeOtherCard}
                        />
                    </Grid>

                    <Grid item xs={12} mb={4}>
                        <CardMobile
                            front={{
                                image: <img alt={t('Lazr advantages card')} src='/images/website/flip-card-image-3.png'/>,
                                iAm: t('I\'m a'),
                                position: t('Head of Logistics'),
                            }}
                            back={{
                                title: t('Diversify carriers & cut shipping costs'),
                                keypoints: [
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_05.svg'/>,
                                        text: t('Cut shipping costs by up to 21%'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_01.svg'/>,
                                        text: t('Choose from 50+ trusted carriers on the largest LTL & parcel shipping network'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_09.svg'/>,
                                        text: t('Add your own carrier contracts to compare shipping rates'),
                                    },
                                ],
                                callToActionButton: <PrimaryButton fullWidth href='/register'>{t('Get Lazr for free')}</PrimaryButton>,
                            }}
                            enableHover
                            cardIndex={2}
                            expanded={expanded[2]}
                            handleExpand={closeOtherCard}
                        />
                    </Grid>

                    <Grid item xs={12} mb={4}>
                        <CardMobile
                            front={{
                                image: <img alt={t('Lazr advantages card')} src='/images/website/flip-card-image-4.png'/>,
                                iAm: t('I\'m an'),
                                position: t('Executive'),
                            }}
                            back={{
                                title: t('Modernize your operations & grow faster'),
                                keypoints: [
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_04.svg'/>,
                                        text: t('Easy-to-use, collaborative platform for both growing & established companies'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_03.svg'/>,
                                        text: t('Enhance legacy systems with our API integrations'),
                                    },
                                    {
                                        icon: <img alt={t('Icon')} src='/images/icons/Flipcard-Icon_02.svg'/>,
                                        text: t('Improve shipping operations from start to finish'),
                                    },
                                ],
                                callToActionButton: <PrimaryButton fullWidth href='/register'>{t('Get Lazr for free')}</PrimaryButton>,
                            }}
                            enableHover
                            cardIndex={3}
                            expanded={expanded[3]}
                            handleExpand={closeOtherCard}
                        />
                    </Grid>
                </Grid>
            </CardsContainer>
        </BackgroundGradientLayer4>
    </BackgroundGradientLayer0>
}
