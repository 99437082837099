import React from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './index.i18n'
import {
    StyledAlertBanner,
    StyledAlertBannerContent, StyledMobileTypography,
    StyledPrimaryButton,
    StyledSecondaryButton,
    StyledSnackbar,
    StyledTypography,
} from './index.styled'
import { useMediaQuery } from '@material-ui/core'

export const CookiePolicyNotistackAlert: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)
    const isMobile = useMediaQuery('(max-width: 1230px)')
    const [ isVisible, setIsVisible ] = React.useState<boolean>(props.open)

    if (!isVisible) {
        return null
    }

    const message = isMobile ? (<StyledMobileTypography>
        {
            /* eslint-disable-next-line max-len */
            t('This site uses cookies to remember your preferences across different visits and otherwise provide you with a great user experience.')
        }
    </StyledMobileTypography>) :
        (<StyledTypography>
            {
                /* eslint-disable-next-line max-len */
                t('This site uses cookies to remember your preferences across different visits and otherwise provide you with a great user experience.')
            }
        </StyledTypography>)

    return (<StyledSnackbar
        open={isVisible}
        onClose={() => {setIsVisible(false)}}
        autoHideDuration={null}>
        <StyledAlertBanner
            severity='info'>
            <StyledAlertBannerContent
                title={t('Cookie Policy')}
                message={message}
                buttons={<>
                    <StyledSecondaryButton size='medium' onClick={
                        (e: React.MouseEvent<unknown>) => { props.handleDeclineClick(e)
                            setIsVisible(false)}}>
                        {t('Decline')}
                    </StyledSecondaryButton>
                    <StyledPrimaryButton size='medium' onClick={
                        (e: React.MouseEvent<unknown>) => { props.handleAcceptClick(e)
                            setIsVisible(false)}}>
                        {t('Accept')}
                    </StyledPrimaryButton>
                </>}
            />
        </StyledAlertBanner>
    </StyledSnackbar>)
}
interface Props {
    open: boolean
    handleDeclineClick: (e: React.MouseEvent<unknown>) => void
    handleAcceptClick: (e: React.MouseEvent<unknown>) => void
}
