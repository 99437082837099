import React from 'react'
import { Box, BoxProps, Typography } from '@material-ui/core'
import {
    StyledStepCard,
    StyledPaper,
    StyledDisclaimerTypography, StyledTitleTypography, StyledImageContainer,
} from '@/app/ui-new/pages/website/components/Carousel/Mobile/StepCardMobile.styled'

const StepCardMobile: React.FunctionComponent<StepCardMobileProps> = (props: StepCardMobileProps) => (
    <>
        <StyledPaper elevation={0}>
            <StyledStepCard display='flex' flexDirection='column'>
                <StyledImageContainer imgSrc={props.imgSrc}/>
                <Box mt={8}>
                    <Box mb={8}>
                        <StyledTitleTypography variant='h4'>{props.title}</StyledTitleTypography>
                    </Box>
                    <Box mb={8}>
                        <Typography variant='body2'>
                            {props.primaryContent}
                        </Typography>
                    </Box>
                    <StyledDisclaimerTypography variant='caption'>
                        {props.disclaimer ? <span><sup>*</sup>{props.disclaimer}</span> : ''}
                    </StyledDisclaimerTypography>
                </Box>
            </StyledStepCard>
        </StyledPaper>
    </>
)

export interface StepCardMobileProps {
    step?: number
    totalSteps?: number
    title: string
    primaryContent: string
    disclaimer: string
    imgSrc: string
}

export interface ImageContainerProps extends BoxProps {
    imgSrc: string
}

export default StepCardMobile
