import variants from './variants'
import typography from './typography'
import overrides from './overrides'
import breakpoints from './breakpoints'
import props from './props'
import shadows from './shadows'
import newDesignVariants from './newDesignVariants'
import newDesignTypography from './newDesignTypography'
import newDesignOverrides from './newDesignOverrides'
import newDesignProps from './newDesignProps'
import { AppTheme } from '../definitions/Theme'
import { AppTheme as NewDesignTheme } from '../definitions/NewDesignTheme'
import { createTheme, Theme, ThemeOptions } from '@material-ui/core/styles'

const themes: Theme[] = variants.map((variant: AppTheme) => {
    const themeOptions: ThemeOptions & Partial<AppTheme> = {
        spacing: 4,
        breakpoints: breakpoints,
        overrides: overrides,
        props: props,
        typography: typography,
        shadows: shadows,
        palette: variant.palette,
    }

    const mergeOptions = { name: variant.name }

    return createTheme(themeOptions, mergeOptions)
})

export const newDesignThemes: Theme[] = newDesignVariants.map((variant: NewDesignTheme) => {
    const themeOptions: ThemeOptions & Partial<NewDesignTheme> = {
        spacing: 4,
        breakpoints: breakpoints,
        typography: newDesignTypography,
        overrides: newDesignOverrides,
        props: newDesignProps,
        shadows: shadows,
        palette: variant.palette,
    }

    const mergeOptions = { name: variant.name }

    return createTheme(themeOptions, mergeOptions)
})

export default themes
