import styled from 'styled-components'
import { IconButton as IconButtonStyled } from '@/app/ui-new/components/IconButton/IconButton'

export const IconButton = styled(IconButtonStyled)`
    background-color: transparent;
    color: ${(props): number => props.theme.palette.white.main};
    border: 0;
    outline: none;

    &:not(.preserveIconColors):hover {
        background: rgba(223, 247, 245, 0.2);
        border-width: 0;
    }

    &.preserveIconColors:hover {
        background: rgba(223, 247, 245, 0.2);
        border-width: 0;
    }

    &:focus {
        outline: none;
    }
`

export const MenuBackgroundOverlay: React.FC<MenuBackgroundOverlayProps> = styled.div<MenuBackgroundOverlayProps>`
    position: fixed;
    inset: 0 0 0 0;
    display: ${(props) => props.display};
    z-index: 1;
`

export const Menu: React.FC<MenuProps> = styled.ul<MenuProps>`
    position: fixed;
    top: ${(props) => props.anchorEl?.getBoundingClientRect().top}px;
    left: ${(props) => (props.anchorEl?.getBoundingClientRect().left ?? 0) - 6}px;
    display: ${(props) => props.open ? 'block' : 'none'};
    background-color: ${(props): string => props.theme.palette.white.main};
    color: ${(props): string => props.theme.palette.black.main};
    padding: ${(props) => props.theme.spacing(2)}px 0;
    margin: 0;
    border-radius: 4px;
    transition: opacity 233ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0 8px 16px 0 rgba(53,64,82,.25); ${/* Same shadow as Mui 4 */''}
    z-index: 2;
`

export interface MenuBackgroundOverlayProps{
    display: string
    onClick: () => void
}

export interface MenuProps{
    anchorEl: HTMLButtonElement | null
    id: string
    open: boolean
    onClick: () => void
}
