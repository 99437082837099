import React, { useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import StepCardMobile, { StepCardMobileProps } from '@/app/ui-new/pages/website/components/Carousel/Mobile/StepCardMobile'
import Stepper from '@/app/ui-new/pages/website/components/Carousel/Stepper'
import {
    StyledSwipeableViews,
    StyledTitleTypography,
    StyledSubTitleTypography,
    StyledPrimaryButton,
} from '@/app/ui-new/pages/website/components/Carousel/Mobile/CarouselMobile.styled'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './CarouselMobile.i18n'
import en from '@/app/ui-new/pages/website/components/Carousel/Mobile/CarouselMobile.i18n.en'

const CarouselMobile: React.FunctionComponent<CarouselMobileProps> = (props: CarouselMobileProps) => {
    const theme = useTheme()
    const [ activeStep, setActiveStep ] = React.useState(0)
    const maxSteps = props.stepCards.length
    const { t } = useI18n(i18n)

    const handleStepChange = useCallback ((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    const handleStepperClick = useCallback((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    return (
        <Box width={'100%'} bgcolor={'#EBF3F3'} display={'flex'} justifyContent={'center'}>
            <Box display='flex' flexDirection='column' width={375} >
                <Box px={4} pt={12} pb={12}>
                    <Box width={300}>
                        <StyledTitleTypography variant={'h1'}>
                            {t('The ultimate logistics solution')}
                        </StyledTitleTypography>
                    </Box>
                    <Box pt={8}>
                        <StyledSubTitleTypography variant={'h6'}>
                            {t('Streamline your entire shipping process with one elegant platform. You’ll get help from Lazr at every step.')}
                        </StyledSubTitleTypography>
                    </Box>
                </Box>

                <StyledSwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {
                        props.stepCards.map((card, index) => (
                            <StepCardMobile
                                key={index}
                                title={t(card.title as keyof typeof en)}
                                primaryContent={t(card.primaryContent  as keyof typeof en)}
                                disclaimer={t(card.disclaimer  as keyof typeof en)}
                                imgSrc={card.imgSrc}
                                step={index + 1}
                                totalSteps={maxSteps}
                            />
                        ))
                    }
                </StyledSwipeableViews>
                <Box mt={12} display='flex' alignItems='center' justifyContent='center'>
                    <Stepper steps={maxSteps} activeStep={activeStep} onClick={(step: number) => handleStepperClick(step)}/>
                </Box>
                <Box my={12} px={4} display={'flex'} flexDirection={'column'}>
                    <StyledPrimaryButton
                        endIcon={<img src={`/images/website/carousel/LazrBrandedArrowWhite.svg?v=${window.lazrVersion}`} alt="LazrIcon" />}
                        size={'large'} href='/register'>
                        {t('Get Lazr for free')}
                    </StyledPrimaryButton>
                </Box>

            </Box>
        </Box>
    )
}

export interface CarouselMobileProps {
    stepCards: StepCardMobileProps[]
}

export default CarouselMobile
