import styled from 'styled-components'

import {
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader as MuiCardHeader,
    Checkbox as MuiCheckbox,
    Divider as MuiDivider,
    Grid as MuiGrid,
    TextField as MuiTextField,
    Typography as MuiTypography,
} from '@material-ui/core'

import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import { spacing } from '@material-ui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OriginalPlacesAutocomplete from '../../components/PlacesAutocomplete'
import { darken } from 'polished'
import { BUTTON_HOVER_DARKEN_AMOUNT } from '../../../lib/constants'
import OriginalLabelledOutline from '../../../components/LabelledOutline'
import { makeStyles } from '@material-ui/core/styles'
export const LabelledOutline = styled(OriginalLabelledOutline)(spacing)

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiFilledInput-root': {
            background: 'white',
        },
    },
}))

export const Divider = styled(MuiDivider)(spacing)

export const PlacesAutocomplete = styled(OriginalPlacesAutocomplete)(spacing)

export const Autocomplete = styled(MuiAutocomplete)(spacing)

export const GridWithSpace = styled(MuiGrid)(spacing)

export const Card = styled(styled(MuiCard)(spacing))`
  box-shadow: ${(props): string => props.theme.shadows[4]};
  height: 100%;
`
export const CustomCard = styled(styled(MuiCard)(spacing))`
  box-shadow: ${(props): string => props.theme.shadows[4]};
`

export const CardContent = styled(MuiCardContent)`
  padding-top: inherit;
  &:last-child {
    padding-bottom: ${(props): number => props.theme.spacing(4)}px;
  }
`

export const RegistrationCustomCard = styled(styled(MuiCard)(spacing))`
  box-shadow: unset;
`

export const RegistrationCardHeader = styled(MuiCardHeader)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border-bottom: 2px dotted grey;
`

export const RegistrationCardContent = styled(MuiGrid)`
  padding-top: inherit;
  padding-left: 0;
  padding-right: 0;
`

export const Checkbox = styled(MuiCheckbox)`
  margin-right: ${(props): number => props.theme.spacing(2)}px;
  &.Mui-checked {
      color: ${(props): number => props.theme.palette.accent1.main};
  }
`

export const ClockIcon = styled(FontAwesomeIcon)`
  color: ${(props): string => props.theme.palette.accent1.main};
`

export const InstructionsGrid = styled(MuiGrid)`
  margin-top: ${(props): string => props.theme.spacing(2.25)}px;
  position: relative;
`

export const TextLimitTypography = styled(MuiTypography)`
    position: absolute;
    font-weight: ${(props): number => props.theme.typography.fontWeightMedium};
    color: ${(props): string => props.theme.palette.accent2.main};

    ${(props): string => props.theme.breakpoints.up('sm')} {
      right: ${(props): string => props.theme.spacing(6)}px;
      bottom: ${(props): string => props.theme.spacing(5)}px;
    }
    ${(props): string => props.theme.breakpoints.down('xs')} {
      right: ${(props): string => props.theme.spacing(4)}px;
      bottom: ${(props): string => props.theme.spacing(3)}px;
    }
`

export const SaveButton = styled(MuiButton)`
    color: ${(props): string => props.theme.palette.accent1.contrastText};
    background-color: ${(props): string => props.theme.palette.accent1.main};
    & .MuiButton-endIcon {
        margin-left: ${(props): string => props.theme.spacing(3)}px;
    }
    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.accent1.main)};
    }
`

export const TextField = styled(MuiTextField)`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`

export const Button = styled(styled(MuiButton)(spacing))`
    background-color: ${(props): number => props.theme.palette.accent1.main};
    color: ${(props): number => props.theme.palette.accent1.contrastText};
    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.accent1.main)};
    }
`

export const CollectAccountGrid = styled(styled(MuiGrid)(spacing))`
`
