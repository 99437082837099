import React from 'react'
import { CardHeader as MuiCardHeader } from '@material-ui/core'
import styled, { ThemedStyledProps } from 'styled-components'
import { Props, UNFORWARDABLE_KEYS } from './QuestionCardHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getBackgroundColor = (props: ThemedStyledProps<any, any>): string => {
    if (props.error){
        return props.theme.palette.error.main
    }

    return 'unset'
}

const getLabelColor = (props: ThemedStyledProps<any, any>): string => {
    if (props.disabled){
        return props.theme.palette.neutral500.main
    }

    if (props.completed || props.error){
        return props.theme.palette.neutral700.main
    }

    return props.theme.palette.primary.main
}

export const StyledQuestionCardHeader: React.FC<Props> = styled(MuiCardHeader).withConfig({
    shouldForwardProp: (prop) => !UNFORWARDABLE_KEYS.includes(prop),
})<Props>`
    background-color: ${(props): string => getBackgroundColor(props)};
    border: solid 1px ${(props): string => props.theme.palette.neutral100.main};
    padding-top: ${(props): string => props.theme.spacing(4)}px;
    padding-bottom: ${(props): string => props.theme.spacing(4)}px;
    padding-left: ${(props): string => props.theme.spacing(6)}px;
    padding-right: ${(props): string => props.theme.spacing(6)}px;
    width: 100%;
    min-height: 64px;
    box-shadow: ${(props) => props.error ? `inset 0px 0px 0px 1px ${props.theme.palette.error900.main}` : 'none'};

    & [class*="MuiCardHeader-content"] {
        color: ${(props): string => getLabelColor(props)};
    }

    & [class*="MuiCardHeader-content"] svg{
        color: ${(props): number => props.error ? props.theme.palette.error900.main : props.theme.palette.neutral800.main};
        font-size: 1.25rem;
    }

    & [class*="MuiCardHeader-action"]{
        align-self: center;
        margin-top: 1px;
        margin-left: 0;
        color: ${(props): number => props.disabled ? props.theme.palette.neutral500.main : props.theme.palette.neutral600.main};
    }

    & [class*="MuiCardHeader-action"] label [class*="MuiTypography-root"]{
        margin-top: 1px;
    }

    & [class*="MuiCardHeader-action"] label [class*="MuiTypography-root"].interactable{
        cursor: pointer;
    }

    & [class*="MuiCardHeader-action"] [class*="MuiBox-root"].interactable{
        cursor: pointer;
    }

    & [class*="MuiCardHeader-action"] svg{
        font-size: 1.25rem;
    }

    & [class*="MuiInputBase-input"]{
        background-color: ${(props): number => props.theme.palette.white.main};
    }

    & [class*="MuiInputLabel-root"]{
        display: none;
    }
`

export const CheckCircleIcon = styled(FontAwesomeIcon)`
    color: ${(props): string => props.theme.palette.accentPrimary900.main};
`
