import React from 'react'
import {
    TextFieldIconUser as TextFieldIconUserStyled,
    TextFieldIconUserCircle as TextFieldIconUserCircleStyled,
    TextFieldIconEnvelope as TextFieldIconEnvelopeStyled,
    TextFieldIconLockAlt as TextFieldIconLockAltStyled,
    TextFieldIconEye as TextFieldIconEyeStyled,
    TextFieldIconEyeSlash as TextFieldIconEyeSlashStyled,
    TextFieldIconGreenCheck as TextFieldIconGreenCheckStyled,
    TextFieldIconRedX as TextFieldIconRedXStyled,
    TextFieldIconSearch as TextFieldIconSearchStyled,
    TextFieldIconDollarSign as TextFieldIconDollarSignStyled,
} from './TextFieldIcons.styled'

export const TextFieldIconUser: React.FC = () => <TextFieldIconUserStyled icon={[ 'far', 'user' ]} />

export const TextFieldIconUserCircle: React.FC = () => <TextFieldIconUserCircleStyled icon={[ 'far', 'user-circle' ]} />

export const TextFieldIconEnvelope: React.FC = () => <TextFieldIconEnvelopeStyled icon={[ 'far', 'envelope' ]} />

export const TextFieldIconLockAlt: React.FC = () => <TextFieldIconLockAltStyled icon={[ 'far', 'lock-alt' ]} />

export const TextFieldIconEye: React.FC = () => <TextFieldIconEyeStyled icon={[ 'far', 'eye' ]} />

export const TextFieldIconEyeSlash: React.FC = () => <TextFieldIconEyeSlashStyled icon={[ 'far', 'eye-slash' ]} />

export const TextFieldIconGreenCheck: React.FC = () => <TextFieldIconGreenCheckStyled icon={[ 'far', 'check' ]} />

export const TextFieldIconRedX: React.FC = () => <TextFieldIconRedXStyled icon={[ 'far', 'times' ]} />

export const TextFieldIconSearch: React.FC = () => <TextFieldIconSearchStyled icon={[ 'far', 'search' ]} />

export const TextFieldIconHashtag: React.FC = () => <TextFieldIconEyeStyled icon={[ 'far', 'hashtag' ]} />

export const TextFieldIconDollarSign: React.FC = () => <TextFieldIconDollarSignStyled icon={[ 'far', 'dollar-sign' ]} />
