import * as types from '../constants'
import { Language } from '@lazr/openapi-client'

export const setLanguage = (value: Language = Language.EN): types.Action => {
    localStorage.setItem('language', value)

    return {
        type: types.SET_LANGUAGE,
        payload: value,
    }
}
