import { IconName } from '@fortawesome/fontawesome-common-types'

export class FileType {
    static readonly UNKNOWN: FileType = new FileType('', '', 'file', 'default')
    static readonly PDF: FileType = new FileType('pdf', 'application/pdf', 'file-pdf', 'pdf')
    static readonly DOC: FileType = new FileType('doc', 'application/msword', 'file-word', 'word')
    static readonly DOCX: FileType = new FileType('docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'file-word', 'word')
    static readonly XLS: FileType = new FileType('xls', 'application/vnd.ms-excel', 'file-excel', 'excel')
    static readonly XLSX: FileType = new FileType('xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'file-excel', 'excel')
    static readonly TXT: FileType = new FileType('txt', 'text/plain', 'file-alt', 'default')
    static readonly JPG: FileType = new FileType('jpg', 'image/jpeg', 'file-image', 'default')
    static readonly JPEG: FileType = new FileType('jpeg', 'image/jpeg', 'file-image', 'default')
    static readonly PNG: FileType = new FileType('png', 'image/png', 'file-image', 'default')
    static readonly WEBP: FileType = new FileType('webp', 'image/webp', 'file-image', 'default')
    static readonly GIF: FileType = new FileType('gif', 'image/gif', 'file-image', 'default')
    static readonly CSV: FileType = new FileType('csv', 'text/csv', 'file-csv', 'default')

    static readonly DEFAULT: FileType = FileType.UNKNOWN

    static readonly FILENAME_EXTENSION_REGULAR_EXPRESSION = /(?:\.([^.]+))?$/ // '*.[pdf|docx|etc.]'
    static readonly FILENAME_DUPLICATE_REGULAR_EXPRESSION = / \(([^).]+)\)\./ // '* ([1..N]).*'

    static readonly list: FileType[] = [
        FileType.UNKNOWN,
        FileType.PDF,
        FileType.DOC,
        FileType.DOCX,
        FileType.XLS,
        FileType.XLSX,
        FileType.TXT,
        FileType.JPG,
        FileType.JPEG,
        FileType.PNG,
        FileType.GIF,
        FileType.WEBP,
        FileType.CSV,
    ]

    static readonly map: Record<string, FileType> = {
        [FileType.UNKNOWN.key]: FileType.UNKNOWN,
        [FileType.PDF.key]: FileType.PDF,
        [FileType.DOC.key]: FileType.DOC,
        [FileType.DOCX.key]: FileType.DOCX,
        [FileType.XLS.key]: FileType.XLS,
        [FileType.XLSX.key]: FileType.XLSX,
        [FileType.TXT.key]: FileType.TXT,
        [FileType.JPG.key]: FileType.JPG,
        [FileType.JPEG.key]: FileType.JPEG,
        [FileType.PNG.key]: FileType.PNG,
        [FileType.GIF.key]: FileType.GIF,
        [FileType.WEBP.key]: FileType.WEBP,
        [FileType.CSV.key]: FileType.CSV,
    }

    key: string
    contentType: string
    iconName: IconName
    className: string

    private constructor (key: string, contentType: string, iconName: IconName, className: string) {
        this.key = key
        this.contentType = contentType
        this.iconName = iconName
        this.className = className
    }

    static isUnknown (documentType?: FileType | null): boolean {
        return documentType?.key === FileType.UNKNOWN.key
    }

    static getAvailableTypes (isImage?: boolean): string {
        return FileType.list
            .filter((fileType) => !FileType.isUnknown(fileType) &&
                (isImage !== undefined ? (fileType.contentType.includes('image/') === isImage) : true))
            .map((fileType) => fileType.key)
            .join(', ')
    }

    static getFileType (filename?: string, isImage?: boolean): FileType {
        const defaultFileType = FileType.UNKNOWN
        if (!filename) {
            return defaultFileType
        }

        const fileExtension = FileType.FILENAME_EXTENSION_REGULAR_EXPRESSION.exec(filename)

        return FileType.list
            .find((fileType) =>
                !FileType.isUnknown(fileType) &&
                fileExtension &&
                fileType.key === fileExtension[1]?.toLowerCase() &&
                (isImage !== undefined ? (fileType.contentType.includes('image/') === isImage) : true),
            ) || defaultFileType
    }

    static getNextFilename (newFilename: string, originalFilename: string): string {
        let nextFilename = originalFilename.replace('.', ' (1).')
        if (newFilename === originalFilename) {
            return nextFilename
        }

        const duplicateNumber = FileType.FILENAME_DUPLICATE_REGULAR_EXPRESSION.exec(newFilename)
        if (duplicateNumber && duplicateNumber[0] && duplicateNumber[1]) {
            nextFilename = newFilename.replace(duplicateNumber[0], ` (${parseInt(duplicateNumber[1], 10) + 1}).`)
        }

        return nextFilename
    }

    static getFileKeyByContentType (contentType: string): string {
        return (this.list.find((file) => file.contentType === contentType))?.key ?? FileType.UNKNOWN.key
    }
}
