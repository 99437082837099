import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { StyledToggleButtonGroup as MuiToggleButtonGroup } from '../../ToggleButton/ToggleButtonGroup.styled'

interface ToggleButtonGroupProps {
    error?: boolean
  
}

export const DivGroup: React.FC<ToggleButtonGroupProps> = styled.div<ToggleButtonGroupProps>`

${(props) => {
    return props.error && `& > div > div  {border: solid 1px ${props.theme.palette.error900.main}}`
}})

 


`