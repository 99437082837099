import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { HazmatModeType } from '../../model'
import { handleUnauthorizedException } from './index'

export const HazmatModeTypeApiService = Object.freeze({
    list: async (): Promise<HazmatModeType[]> => {
        let getHazmatModeTypesResponse
        try {
            getHazmatModeTypesResponse = await EnumTypesService.getHazmatModeTypes()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve hazmat mode type list')
            }
            throw new Error('Unable to retrieve hazmat mode type list')
        }

        if (!getHazmatModeTypesResponse.data) {
            logger.debug('list',  JSON.stringify(getHazmatModeTypesResponse, null, 4))
            throw new Error('Unable to retrieve hazmat mode type list')
        }

        return getHazmatModeTypesResponse.data
    },
})
