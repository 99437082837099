import styled from 'styled-components'
import {
    height as MuiHeight,
    spacing as MuiSpacing,
} from '@material-ui/system'
import {
    Paper as MuiPaper,
    Grid as MuiGrid,
    Typography as MuiTypography,
    IconButton as MuiIconButton,
    Button as MuiButton,
    Dialog as MuiDialog,
} from '@material-ui/core'
import { FontAwesomeIcon as MuiFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken } from 'polished'
import {
    COLOR_DARKEST_GRAY_DARKEN_AMOUNT,
} from '../lib/constants'

export const Paper = styled(styled(styled(MuiPaper)(MuiSpacing))(MuiHeight))``

export const Grid = styled(styled(MuiGrid)(MuiSpacing))``

export const Typography = styled(styled(MuiTypography)(MuiSpacing))`
`

export const Dialog = styled(MuiDialog)`
  margin: auto;
`

export const FontAwesomeIcon = styled(styled(MuiFontAwesomeIcon)(MuiSpacing))`
    &.large {
        font-size: 1.5rem;
    }
    &.super-huge {
        font-size: 2.5rem;
    }
    &.gray {
        color: ${(props): string => darken(COLOR_DARKEST_GRAY_DARKEN_AMOUNT, props.theme.palette.accent1.contrastText)};
    }
    &.close {
        cursor: pointer;
        color: ${(props): string => props.theme.palette.text.disabled};
    }
`

export const DialogCloseButton = styled(MuiIconButton)`
    height: 48px;
    width: 48px;
    position: absolute;
    right: ${(props): number => props.theme.spacing(2)}px;
    top: ${(props): number => props.theme.spacing(0.5)}px;
`

export const Button = styled(MuiButton)`
    &.red {
        color: ${(props): number => props.theme.palette.error.main};
    }
    &.accent2 {
        color: ${(props): number => props.theme.palette.accent2.main};
    }
    &.noMargin {
        margin-left: 0;
    }
    &.green {
        background-color: #218838;
        color: white;
    }
`

export const Note = styled.div`
  font-style: italic;
  font-size: 0.8em;
  text-transform: unset;
  margin-top: 4px;
  line-height: 0.8em;
`

export const RedStar = styled(Typography)`
  color: red;
  display: inline;
  font-size: 170%;
  margin: 0 4px;
  
  :before{
    content: '*';
    font-style: normal;
  }
`