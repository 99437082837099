import styled from 'styled-components'
import { IconButton } from '@/app/ui-new/components/IconButton/IconButton.styled'
import { transparentize } from 'polished'
import SwipeableViews from 'react-swipeable-views'

export const StyledIconButton = styled(IconButton)`
    background-color: ${(props): string => props.theme.palette.white.main};
    border-color: transparent;
    color: ${(props): number => props.theme.palette.accentPrimary600.main} !important;
    z-index: 1;
    transform: translate(0, -50%);

    &:focus{
        outline: unset;
        border-color: transparent;
    }

    &:hover{
        outline: unset;
        border-color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }

    &.Mui-disabled {
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.neutral800.main as string)};
    }
`

export const StyledSwipeableViews = styled(SwipeableViews)`
    padding: 0 0 0 ${(props): string => props.theme.spacing(2.5)}px;
    cursor: pointer;
    width: 100%;

    .react-swipeable-view-container > div{
        padding: 0 ${(props): string => props.theme.spacing(2.5)}px;
        user-select: none;
    }
`
