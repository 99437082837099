import React, { useEffect, useState } from 'react'
import { BillingApiService } from '../../service/ApiService'
import Modal from '../components/Modal'
import { Grid } from '@material-ui/core'
import { Typography } from './PaymentReminder.styled'
import { useSelector } from 'react-redux'
import { getUserOrganization } from '../redux/selectors'

interface NotificationStatus {
    isNotified: boolean
    dayOfNotif: Date
}

const PaymentReminder: React.FC = () => {
    const organization = useSelector(getUserOrganization)
    const DAYS_BEFORE_WARNING = 10
    const DAYS_BEFORE_ACCOUNT_LOCK = 10

    const CLEAR   = 'CLEAR'
    const WARNING = 'WARNING'
    const URGENT  = 'URGENT'
    const LOCKED  = 'LOCKED'

    const processAlertLevel = (dayLeft: number) => {
        let status = CLEAR
        if (dayLeft >= 0 && dayLeft < DAYS_BEFORE_WARNING) {
            setDaysRemaining(dayLeft)
            status = WARNING
        } else if (dayLeft >= -DAYS_BEFORE_ACCOUNT_LOCK && dayLeft < 0) {
            setDaysRemaining(DAYS_BEFORE_ACCOUNT_LOCK + dayLeft)
            status = URGENT
        } else if (DAYS_BEFORE_ACCOUNT_LOCK + dayLeft < 0) {
            status = LOCKED
        }

        return status
    }

    const [ alertLevel,    setAlertLevel ]      = useState<string>(CLEAR)
    const [ daysRemaining, setDaysRemaining ]   = useState<number>(DAYS_BEFORE_WARNING + 1)

    /**
     * This has warning has been turned off for now. VCollin
    useEffect(() => {
        if (organization?.isClient()) {
            const notificationStatusKey = 'notificationStatus'
            const notificationStoredStatus = window.sessionStorage.getItem(notificationStatusKey) ?? '{}'
            const notificationStatus: NotificationStatus = JSON.parse(notificationStoredStatus)

            const last = new Date(notificationStatus.dayOfNotif)
            const today = new Date()

            const notifiedToday = notificationStatus.isNotified && last.getDay() === today.getDay()

            if (!notifiedToday) {
                void BillingApiService.listUnpaidInvoices().then((lateInvoicePaymentsList) => {
                    let mostUrgent = DAYS_BEFORE_WARNING
                    lateInvoicePaymentsList.forEach((lateInvoicePayment) => {
                        if (lateInvoicePayment.dueInXDays < mostUrgent) {
                            mostUrgent = lateInvoicePayment.dueInXDays
                        }
                    })

                    setAlertLevel(processAlertLevel(mostUrgent))

                    window.sessionStorage.setItem(notificationStatusKey, JSON.stringify({
                        isNotified: true,
                        dayOfNotif: today,
                    }))
                })
            }
        }
    }, []) */

    switch (alertLevel) {
        case WARNING:
            return (
                <Modal
                    open
                    closeButton={false}
                    disableBackdropClick
                    disableEscapeKeyDown
                    modalTitle={'Payment Required Soon'}
                    content={(
                        <Grid item container xs={12}>
                            <Grid item>
                                <Typography px={7} pt={6} pb={2}>
                                    Dear Lazr customer,
                                </Typography>
                                <Typography px={7} pt={3} pb={1}>
                                    We would like to remind you that one of your invoices require attention.
                                    You have <b>{daysRemaining} day{Math.abs(daysRemaining) > 1 ? 's' : ''}</b> left to issue a payment.
                                </Typography>
                                <Typography px={7} pt={6} pb={1}>
                                    Thank you.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    buttons={[ {
                        title: 'Understood',
                        action: () => {
                            setAlertLevel(CLEAR)
                        },
                    } ]}
                />
            )
        case URGENT:
            return (
                <Modal
                    open
                    closeButton={false}
                    disableBackdropClick
                    disableEscapeKeyDown
                    modalTitle={'Late Payment'}
                    content={(
                        <Grid item container xs={12}>
                            <Grid item>
                                <Typography px={7} pt={6} pb={2}>
                                    Dear Lazr customer,
                                </Typography>
                                <Typography px={7} pt={3} pb={1}>
                                    One of your invoices is still unpaid after its due date.
                                    You have <b>{daysRemaining} day{Math.abs(daysRemaining) > 1 ? 's' : ''}</b> to issue a payment.
                                    Otherwise your account will be suspended.
                                </Typography>
                                <Typography px={7} pt={6} pb={1}>
                                    Thank you.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    buttons={[ {
                        title: 'Understood',
                        action: () => {
                            setAlertLevel(CLEAR)
                        },
                    } ]}
                />
            )
        case LOCKED:
            return (
                <Modal
                    open
                    closeButton={false}
                    disableBackdropClick
                    disableEscapeKeyDown
                    modalTitle={'Account Suspended'}
                    content={(
                        <Grid item container xs={12}>
                            <Grid item>
                                <Typography px={7} pt={6} pb={2}>
                                    Dear Lazr customer,
                                </Typography>
                                <Typography px={7} pt={3} pb={1}>
                                    Due to <b>unpaid invoices</b>, your account has been suspended.
                                    Some of your Lazr functionnalities are now locked.
                                </Typography>
                                <Typography px={7} pt={6} pb={1}>
                                    {'Please contact Lazr\'s support to resolve this issue.'}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    buttons={[ {
                        title: 'Understood',
                        action: () => {
                            setAlertLevel(CLEAR)
                        },
                    } ]}
                />
            )
        case CLEAR:
        default:
            return <div />
    }
}

export default PaymentReminder
