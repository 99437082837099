import { createGlobalStyle } from 'styled-components'
// @ts-ignore
import Nunito400WoffTwo from '../../fonts/nunito-v12-latin_latin-ext-400.woff2'
// @ts-ignore
import Nunito300WoffTwo from '../../fonts/nunito-v12-latin_latin-ext-300.woff2'
// @ts-ignore
import Nunito600WoffTwo from '../../fonts/nunito-v12-latin_latin-ext-600.woff2'
// @ts-ignore
import Nunito700WoffTwo from '../../fonts/nunito-v12-latin_latin-ext-700.woff2'
// @ts-ignore
import Modulus400WoffTwo from '../../fonts/modulus-400.woff2'
// @ts-ignore
import Modulus600WoffTwo from '../../fonts/modulus-600.woff2'
import { AppTheme } from '../../definitions/Theme'
import { Theme } from '@material-ui/core'

export const GlobalStyle = createGlobalStyle`
  /* nunito-regular - latin_latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: local('Nunito Regular'), local('Nunito-Regular'),
         url('${Nunito400WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* nunito-300 - latin_latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: local('Nunito Light'), local('Nunito-Light'),
         url('${Nunito300WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* nunito-600 - latin_latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
         url('${Nunito600WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* nunito-700 - latin_latin-ext */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: local('Nunito Bold'), local('Nunito-Bold'),
         url('${Nunito700WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* modulus-400 */
  @font-face {
    font-family: 'Modulus';
    font-style: normal;
    font-weight: 400;
    src: local('Modulus Regular'), local('Modulus-Regular'),
         url('${Modulus400WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }
  /* modulus-600 */
  @font-face {
    font-family: 'Modulus';
    font-style: normal;
    font-weight: 600;
    src: local('Modulus Bold'), local('Modulus-Bold'),
         url('${Modulus600WoffTwo}') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
  }

  html,
  body,
  #root {
    font-size: 14px;
    height: 100%;
  }

  body {
    background: ${(props: { theme: AppTheme & Theme }): string => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`
