import { MarketPlace, MarketPlaceErrors } from '@/app/ui/definitions/MarketPlace'
import { Country, Currency as CurrencyCode } from '@lazr/openapi-client'
import { OrderInfoCustomsDetail, OrderInfoRfq } from '@/app/model'
import { AsYouType } from 'libphonenumber-js'
import { CountryCode as PhoneCountryCode } from 'libphonenumber-js/types'
import * as EmailValidator from 'email-validator'
import { deepCopy, isInternationalOrder, isMailingService } from '@lazr/utilities'
import { isAccessorialUsed, validatePostalCode } from '@/app/ui/lib/helpers'
import { BillDutiesToParty as BillDutiesToPartyCode } from '@lazr/openapi-client'
import { initialMarketPlace } from '@/app/ui/redux/store/InitialState'
import { completeOrderStepRFQFieldsValidator } from '../../../hooks/useFormValidator'
import { Country as CountryCode } from '@lazr/enums'
import { validateTaxIdRequirementPart } from '@/app/ui/pages/marketplace/components/helpers'

export const upsCompleteOrderStepValidator = (
    marketPlace: MarketPlace,
    isSubmitting: boolean,
    country: Country,
): MarketPlaceErrors => {
    const rfq = marketPlace.order.rfq
    const order = marketPlace.order
    const customsDetails = marketPlace.order.rfq?.customsDetails
    const customsDetailErrors: Partial<Record<keyof OrderInfoCustomsDetail, string>>[] = [ {} ]
    const customsDetailIsDirty =  marketPlace.marketPlaceErrors.customsDetailIsDirty

    const handlingUnits = marketPlace.order.rfq?.handlingUnits
    const handlingUnitLinkErrors: string[] = []
    const handlingUnitLinkIsDirty = marketPlace.marketPlaceErrors.handlingUnitLinkIsDirty

    const rfqCustomsErrors: Partial<Record<keyof OrderInfoRfq, string>> = {}
    let rfqCustomsIsDirty = marketPlace.marketPlaceErrors.rfqCustomsIsDirty
    const requiredText = 'Required'

    const rfqErrors = completeOrderStepRFQFieldsValidator(marketPlace, isSubmitting, country)

    if (isMailingService(rfq?.transportType)) {
        if (isAccessorialUsed(rfq?.accessorials, 'HAZM')) {
            if (!rfq?.hazmatMode) {
                rfqErrors.hazmatMode = requiredText
            }
            if (!rfq?.hazmatClass) {
                rfqErrors.hazmatClass = requiredText
            }
        }
    }

    const isTaxIdRequirementPartValidated = marketPlace.order.rfq && validateTaxIdRequirementPart(marketPlace.order.rfq)

    if (!rfq?.documentsOnlyIndicator &&
        isInternationalOrder(rfq?.originCountry?.code, rfq?.destinationCountry?.code)) {
        if (isSubmitting) {
            rfqCustomsIsDirty = {
                billDutiesToParty: true,
                importExport: true,
                incotermsType: true,
                taxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.SENDER &&
                    isTaxIdRequirementPartValidated,
                consigneeTaxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code &&
                    isTaxIdRequirementPartValidated &&
                    [ BillDutiesToPartyCode.SENDER, BillDutiesToPartyCode.RECEIVER ]
                        .includes(marketPlace.order.rfq?.billDutiesToParty?.code),
                thirdPartyTaxIdentificationNumber: marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY &&
                    isTaxIdRequirementPartValidated,
            }
        }

        if (rfq?.commercialInvoiceUserProvided) {
            if (order.documents?.length === 0) {
                rfqCustomsErrors.commercialInvoiceUserProvided = 'Missing commercial invoice document upload'
            }
        }

        if (rfq?.commercialInvoiceDocumentIndicator && !rfq?.commercialInvoiceUserProvided) {

            if (!rfq?.taxIdentificationNumber && rfqCustomsIsDirty.taxIdentificationNumber) {
                rfqCustomsErrors.taxIdentificationNumber = requiredText
            }

            if (rfq?.taxIdentificationNumber && isTaxIdRequirementPartValidated &&
                marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.SENDER) {
                rfqCustomsIsDirty.taxIdentificationNumber = true
            }

            if (!rfq?.consigneeTaxIdentificationNumber && rfqCustomsIsDirty.consigneeTaxIdentificationNumber) {
                rfqCustomsErrors.consigneeTaxIdentificationNumber = requiredText
            }

            if (rfq?.consigneeTaxIdentificationNumber && marketPlace.order.rfq?.billDutiesToParty?.code &&
                [ BillDutiesToPartyCode.SENDER, BillDutiesToPartyCode.RECEIVER ]
                    .includes(marketPlace.order.rfq?.billDutiesToParty?.code) && isTaxIdRequirementPartValidated) {
                rfqCustomsIsDirty.consigneeTaxIdentificationNumber = true
            }

            if (!rfq?.thirdPartyTaxIdentificationNumber && rfqCustomsIsDirty.thirdPartyTaxIdentificationNumber) {
                rfqCustomsErrors.thirdPartyTaxIdentificationNumber = requiredText
            }

            if (rfq?.thirdPartyTaxIdentificationNumber && isTaxIdRequirementPartValidated &&
                marketPlace.order.rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY) {
                rfqCustomsIsDirty.thirdPartyTaxIdentificationNumber = true
            }
        }

        if (!rfq?.billDutiesToParty && rfqCustomsIsDirty.billDutiesToParty) {
            rfqCustomsErrors.billDutiesToParty = requiredText
        }

        if (rfq?.billDutiesToParty) {
            rfqCustomsIsDirty.billDutiesToParty = true
        }

        if (!rfq?.importExport && rfqCustomsIsDirty.importExport) {
            rfqCustomsErrors.importExport = requiredText
        }

        if (rfq?.importExport) {
            rfqCustomsIsDirty.importExport = true
        }

        if (!rfq?.incotermsType && rfqCustomsIsDirty.incotermsType) {
            rfqCustomsErrors.incotermsType = requiredText
        }

        if (rfq?.incotermsType) {
            rfqCustomsIsDirty.incotermsType = true

        }

        if (rfq?.billDutiesToParty?.code === BillDutiesToPartyCode.THIRD_PARTY) {
            if (isSubmitting) {
                rfqCustomsIsDirty.dutyBillToCity = true
                rfqCustomsIsDirty.dutyBillToContactEmail = true
                rfqCustomsIsDirty.dutyBillToCompanyName = true
                rfqCustomsIsDirty.dutyBillToContactName = true
                rfqCustomsIsDirty.dutyBillToContactPhone = true
                rfqCustomsIsDirty.dutyBillToCountry = true
                rfqCustomsIsDirty.dutyBillToPostalCode = true
                rfqCustomsIsDirty.dutyBillToState = true
                rfqCustomsIsDirty.dutyBillToStreetAddress = true
                rfqCustomsIsDirty.dutyBillToAccountNumber = true
            }

            if (!rfq?.dutyBillToCity?.trim() && rfqCustomsIsDirty.dutyBillToCity) {
                rfqCustomsErrors.dutyBillToCity = requiredText
            }

            if (rfq?.dutyBillToCity?.trim()) {
                rfqCustomsIsDirty.dutyBillToCity = true
            }

            if (!rfq?.dutyBillToCompanyName?.trim() && rfqCustomsIsDirty.dutyBillToCompanyName) {
                rfqCustomsErrors.dutyBillToCompanyName = requiredText
            }

            if (rfq?.dutyBillToCompanyName?.trim()) {
                rfqCustomsIsDirty.dutyBillToCompanyName = true
            }

            if (!rfq?.dutyBillToContactEmail?.trim() && rfqCustomsIsDirty.dutyBillToContactEmail) {
                rfqCustomsErrors.dutyBillToContactEmail = requiredText
            } else if (rfq?.dutyBillToContactEmail &&
                rfqCustomsIsDirty.dutyBillToContactEmail &&
                !EmailValidator.validate(rfq?.dutyBillToContactEmail)) {
                rfqCustomsErrors.dutyBillToContactEmail = 'Invalid email address'
            }

            if (rfq?.dutyBillToContactEmail?.trim()) {
                rfqCustomsIsDirty.dutyBillToContactEmail = true
            }

            if (!rfq?.dutyBillToContactName?.trim() && rfqCustomsIsDirty.dutyBillToContactName) {
                rfqCustomsErrors.dutyBillToContactName = requiredText
            }

            if (rfq?.dutyBillToContactName?.trim()) {
                rfqCustomsIsDirty.dutyBillToContactName = true
            }

            if (!rfq?.dutyBillToContactPhone?.trim() && rfqCustomsIsDirty.dutyBillToContactPhone) {
                rfqCustomsErrors.dutyBillToContactPhone = requiredText
            }
            else if (!!rfq?.dutyBillToContactPhone && !!rfq?.dutyBillToCountry && rfqCustomsIsDirty.dutyBillToContactPhone) {
                const asYouTypeDutyBillPhone = new AsYouType(rfq.dutyBillToCountry.code as PhoneCountryCode)
                asYouTypeDutyBillPhone.input(rfq.dutyBillToContactPhone)
                if (!asYouTypeDutyBillPhone.getNumber()?.isPossible()) {
                    rfqCustomsErrors.dutyBillToContactPhone = 'Invalid phone number'
                }
            }

            if (rfq?.dutyBillToContactPhone?.trim()) {
                rfqCustomsIsDirty.dutyBillToContactPhone = true
            }

            if (!rfq?.dutyBillToCountry && rfqCustomsIsDirty.dutyBillToCountry) {
                rfqCustomsErrors.dutyBillToCountry = requiredText
            }

            if (rfq?.dutyBillToCountry) {
                rfqCustomsIsDirty.dutyBillToCountry = true
            }

            if (!rfq?.dutyBillToPostalCode?.trim() && rfqCustomsIsDirty.dutyBillToPostalCode) {
                rfqCustomsErrors.dutyBillToPostalCode = requiredText
            }
            else if (!!rfq?.dutyBillToPostalCode && !!rfq?.dutyBillToCountry && rfqCustomsIsDirty.dutyBillToPostalCode &&
                !validatePostalCode(rfq.dutyBillToCountry.code, rfq.dutyBillToPostalCode)) {
                rfqCustomsErrors.dutyBillToPostalCode = 'Invalid postal/zip code'
            }

            if (rfq?.dutyBillToPostalCode?.trim()) {
                rfqCustomsIsDirty.dutyBillToPostalCode = true
            }

            if (!rfq?.dutyBillToState?.trim() && rfqCustomsIsDirty.dutyBillToState) {
                rfqCustomsErrors.dutyBillToState = requiredText
            }
            else if (!!rfq?.dutyBillToState && rfqCustomsIsDirty.dutyBillToState &&
                rfq.dutyBillToState.length > 2) {
                rfqCustomsErrors.dutyBillToState = 'Invalid state code. State must use the two letter code'
            }

            if (rfq?.dutyBillToState?.trim()) {
                rfqCustomsIsDirty.dutyBillToState = true
            }

            if (!rfq?.dutyBillToStreetAddress?.trim() && rfqCustomsIsDirty.dutyBillToStreetAddress) {
                rfqCustomsErrors.dutyBillToStreetAddress = requiredText
            }

            if (rfq?.dutyBillToStreetAddress?.trim()) {
                rfqCustomsIsDirty.dutyBillToStreetAddress = true
            }

            if (!rfq?.dutyBillToAccountNumber?.trim() && rfqCustomsIsDirty.dutyBillToAccountNumber) {
                rfqCustomsErrors.dutyBillToAccountNumber = requiredText
            }

            if (rfq?.dutyBillToAccountNumber?.trim()) {
                rfqCustomsIsDirty.dutyBillToAccountNumber = true
            }
        }

        let summaryHandlingUnitIds: string[] = []
        const isCommercialInvoiceGenerated = rfq?.commercialInvoiceDocumentIndicator && !rfq?.commercialInvoiceUserProvided
        const isUSOriginAndCADestination = rfq?.originCountry?.code === CountryCode.US && rfq?.destinationCountry?.code === CountryCode.CA
        const showCustomsDetail= isCommercialInvoiceGenerated || isUSOriginAndCADestination;

        customsDetails?.forEach((customsDetail, index) => {
            if (!customsDetail) {
                return
            }

            if (isSubmitting) {
                customsDetailIsDirty[index] = {
                    harmonizedCode: true,
                    description: true,
                    countryOfManufacture: true,
                    quantity: true,
                    unitValue: true,
                    handlingUnitIds: true,
                    unitWeight: true,
                }
            }

            summaryHandlingUnitIds = summaryHandlingUnitIds.concat(customsDetail.handlingUnitIds)

            if (!customsDetailIsDirty[index]) {
                customsDetailIsDirty[index] = {}
            }
            customsDetailErrors[index] = {}

            if (showCustomsDetail) {
                if (!customsDetail.productCode?.trim() &&
                    customsDetailIsDirty[index].productCode) {
                    customsDetailErrors[index].productCode = requiredText
                }

                if (customsDetail.productCode?.trim()) {
                    customsDetailIsDirty[index].productCode = true
                }

                if (customsDetail.productIndicatorTextile &&
                    !customsDetail.textileManufacturer?.trim() &&
                    customsDetailIsDirty[index].textileManufacturer) {
                    customsDetailErrors[index].textileManufacturer = requiredText
                }

                if (customsDetail.textileManufacturer?.trim()) {
                    customsDetailIsDirty[index].textileManufacturer = true
                }

                if (!customsDetail.description?.trim() && customsDetailIsDirty[index].description) {
                    customsDetailErrors[index].description = requiredText
                }

                if (customsDetail.description?.trim()) {
                    customsDetailIsDirty[index].description = true
                }

                if (!customsDetail.countryOfManufacture &&
                    customsDetailIsDirty[index].countryOfManufacture) {
                    customsDetailErrors[index].countryOfManufacture = requiredText
                }

                if (customsDetail.countryOfManufacture) {
                    customsDetailIsDirty[index].countryOfManufacture = true
                }

                if (!customsDetail.quantity && customsDetailIsDirty[index].quantity) {
                    customsDetailErrors[index].quantity = requiredText
                }

                if (customsDetail.quantity) {
                    customsDetailIsDirty[index].quantity = true
                }

                if (!customsDetail.unitValue && customsDetailIsDirty[index].unitValue) {
                    customsDetailErrors[index].unitValue = requiredText
                }

                if (customsDetail.unitValue) {
                    customsDetailIsDirty[index].unitValue = true
                }

                if (!customsDetail.unitWeight && customsDetailIsDirty[index].unitWeight) {
                    customsDetailErrors[index].unitWeight = requiredText
                }
                if (customsDetail.unitWeight) {
                    customsDetailIsDirty[index].unitWeight = true
                }
                if ((customsDetail.handlingUnitIds.length === 0) &&
                    customsDetailIsDirty[index].handlingUnitIds) {
                    customsDetailErrors[index].handlingUnitIds = requiredText
                }
                if (customsDetail.handlingUnitIds.length > 0) {
                    customsDetailIsDirty[index].handlingUnitIds = true
                }
            }
        })

        if (showCustomsDetail) {
            handlingUnits?.forEach((handlingUnit, index) => {
                handlingUnitLinkErrors[index] = ''

                if (isSubmitting) {
                    handlingUnitLinkIsDirty[index] = true
                }

                if (!summaryHandlingUnitIds.some((id) => id === handlingUnit.id) &&
                    handlingUnitLinkIsDirty[index]
                ) {
                    handlingUnitLinkErrors[index] = 'Not linked error'
                }
                if (summaryHandlingUnitIds.some((id) => id === handlingUnit.id)) {
                    handlingUnitLinkIsDirty[index] = true
                }
            })
        }
    }

    return {
        rfqIsDirty: {},
        cargoIsDirty: [ {} ],
        rfqErrors,
        cargoErrors: deepCopy(initialMarketPlace.marketPlaceErrors.cargoErrors),
        customsDetailErrors: customsDetailErrors,
        customsDetailIsDirty,
        handlingUnitLinkIsDirty,
        handlingUnitLinkErrors,
        rfqCustomsErrors,
        rfqCustomsIsDirty,
        isSubmitted: true,
    }
}
