/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptEdiOrderRequest } from '../models/AcceptEdiOrderRequest';
import type { DeclineEdiOrderRequest } from '../models/DeclineEdiOrderRequest';
import type { EdiOrderStatus } from '../models/EdiOrderStatus';
import type { SuccessResponse_AcceptEdiOrderResponse_ } from '../models/SuccessResponse_AcceptEdiOrderResponse_';
import type { SuccessResponse_DeclineEdiOrderResponse_ } from '../models/SuccessResponse_DeclineEdiOrderResponse_';
import type { SuccessResponse_GetEdiBillingCodesResponse_ } from '../models/SuccessResponse_GetEdiBillingCodesResponse_';
import type { SuccessResponse_GetEdiLazrOrderDataResponse_ } from '../models/SuccessResponse_GetEdiLazrOrderDataResponse_';
import type { SuccessResponse_GetEdiOrderByIdResponse_ } from '../models/SuccessResponse_GetEdiOrderByIdResponse_';
import type { SuccessResponse_GetEdiOrderDetailsResponse_ } from '../models/SuccessResponse_GetEdiOrderDetailsResponse_';
import type { SuccessResponse_GetEdiOrdersResponse_ } from '../models/SuccessResponse_GetEdiOrdersResponse_';
import type { SuccessResponse_TransferEdiOrderResponse_ } from '../models/SuccessResponse_TransferEdiOrderResponse_';
import type { TransferEdiOrderRequest } from '../models/TransferEdiOrderRequest';
import type { TransportType } from '../models/TransportType';
import type { UuidV4 } from '../models/UuidV4';
import type { WithOrderCreatedType } from '../models/WithOrderCreatedType';
import { request as __request } from '../core/request';

export class EdiService {

    /**
     * Accept an EDI order revision
     * @param requestBody The data
     * @returns SuccessResponse_AcceptEdiOrderResponse_ OK
     * @throws ApiError
     */
    public static async acceptEdiOrder(
        requestBody: AcceptEdiOrderRequest,
    ): Promise<SuccessResponse_AcceptEdiOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/accept-edi-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Decline an EDI order revision
     * @param requestBody The data
     * @returns SuccessResponse_DeclineEdiOrderResponse_ OK
     * @throws ApiError
     */
    public static async declineEdiOrder(
        requestBody: DeclineEdiOrderRequest,
    ): Promise<SuccessResponse_DeclineEdiOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/decline-edi-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Transfer an EDI order to another 3PL organization
     * @param requestBody The data
     * @returns SuccessResponse_TransferEdiOrderResponse_ OK
     * @throws ApiError
     */
    public static async transferEdiOrder(
        requestBody: TransferEdiOrderRequest,
    ): Promise<SuccessResponse_TransferEdiOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/transfer-edi-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get supported EDI billing codes
     * @param id The ID of the ediOrder to get
     * @returns SuccessResponse_GetEdiBillingCodesResponse_ OK
     * @throws ApiError
     */
    public static async getEdiBillingCodes(
        id: string,
    ): Promise<SuccessResponse_GetEdiBillingCodesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/edi-billing-codes/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an EDI order order data
     * @param id The ID of the ediOrder to get
     * @returns SuccessResponse_GetEdiLazrOrderDataResponse_ OK
     * @throws ApiError
     */
    public static async getEdiLazrOrderData(
        id: string,
    ): Promise<SuccessResponse_GetEdiLazrOrderDataResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/edi-lazr-order-data/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an EDI order by its ID
     * @param id The ID of the ediOrder to get
     * @returns SuccessResponse_GetEdiOrderByIdResponse_ OK
     * @throws ApiError
     */
    public static async getEdiOrderById(
        id: string,
    ): Promise<SuccessResponse_GetEdiOrderByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/edi-order-by-id/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an EDI order order data
     * @param id The ID of the ediOrder to get
     * @returns SuccessResponse_GetEdiOrderDetailsResponse_ OK
     * @throws ApiError
     */
    public static async getEdiOrderDetails(
        id: string,
    ): Promise<SuccessResponse_GetEdiOrderDetailsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/edi-order-details/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of edi orders
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param searchField Text field to search
     * @param shipmentNumber
     * @param poNumber
     * @param bolNumber
     * @param scac
     * @param clientOrganizationIds
     * @param threePlOrganizationIds
     * @param receivedDateFrom
     * @param receivedDateTo
     * @param expireDateFrom
     * @param expireDateTo
     * @param locationOrigins
     * @param locationDestinations
     * @param transportTypes
     * @param ediOrderStatuses
     * @param withOrderCreatedTypes
     * @param orderBy Field to order by
     * @param order Order direction of orderBy
     * @returns SuccessResponse_GetEdiOrdersResponse_ OK
     * @throws ApiError
     */
    public static async getEdiOrders(
        page?: number,
        resultsPerPage?: number,
        searchField?: string,
        shipmentNumber?: string,
        poNumber?: string,
        bolNumber?: string,
        scac?: string,
        clientOrganizationIds?: Array<UuidV4>,
        threePlOrganizationIds?: Array<UuidV4>,
        receivedDateFrom?: string,
        receivedDateTo?: string,
        expireDateFrom?: string,
        expireDateTo?: string,
        locationOrigins?: Array<string>,
        locationDestinations?: Array<string>,
        transportTypes?: Array<TransportType>,
        ediOrderStatuses?: Array<EdiOrderStatus>,
        withOrderCreatedTypes?: Array<WithOrderCreatedType>,
        orderBy?: 'status' | 'createdAt' | 'expiresAt',
        order?: 'asc' | 'desc',
    ): Promise<SuccessResponse_GetEdiOrdersResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/edi-orders`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'searchField': searchField,
                'shipmentNumber': shipmentNumber,
                'poNumber': poNumber,
                'bolNumber': bolNumber,
                'scac': scac,
                'clientOrganizationIds': clientOrganizationIds,
                'threePlOrganizationIds': threePlOrganizationIds,
                'receivedDateFrom': receivedDateFrom,
                'receivedDateTo': receivedDateTo,
                'expireDateFrom': expireDateFrom,
                'expireDateTo': expireDateTo,
                'locationOrigins': locationOrigins,
                'locationDestinations': locationDestinations,
                'transportTypes': transportTypes,
                'ediOrderStatuses': ediOrderStatuses,
                'withOrderCreatedTypes': withOrderCreatedTypes,
                'orderBy': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}