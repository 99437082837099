import styled from 'styled-components'
import { transparentize } from 'polished'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    Tabs as MuiTabs,
    Tab as MuiTab,
} from '@material-ui/core'
import { Typography as MuiTypography } from '@material-ui/core'
import { Toolbar as MuiToolbar } from '@material-ui/core'

export const Tab = styled(styled(MuiTab)(MuiSpacing))`
    padding: ${(props): string => props.theme.spacing(5)}px ${(props): string => props.theme.spacing(6)}px;
    color: ${(props): string => props.theme.palette.neutral700.main};
    
    &:hover{
        background-color: ${(props): string => transparentize(0.50, props.theme.palette.neutral.main)};
    }

    &:focus{
        background-color: ${(props): string => props.theme.palette.neutral100.main};
    }

    & > span[class*='Ripple'] > span > span{
        background-color: ${(props): string => props.theme.palette.neutral600.main};
    }

    &.Mui-selected{
        color: ${(props): string => props.theme.palette.black.main};

        &:hover{
            background-color: ${(props): string => transparentize(0.50, props.theme.palette.accentPrimary50.main)};
        }
    
        &:focus{
            background-color: ${(props): string => props.theme.palette.accentPrimary50.main};
        }
    
        & > span[class*='Ripple'] > span > span{
            background-color: ${(props): string => props.theme.palette.accentPrimary900.main};
        }
    }
`

export const Tabs = styled(MuiTabs)`
    & span[class*='indicator']{
        background-color: ${(props): string => props.theme.palette.accentPrimary900.main};
        height: 4px;
    }

    & button{
        text-transform: unset;
    }
`

export const TabPanel = styled.div`
    padding: ${(props): string => props.theme.spacing(3)}px
`

export const TabsToolbar = styled(MuiToolbar)`
    &.bottom-border-fit{
        border-bottom: 1px solid ${(props): string => props.theme.palette.neutral300.main};
        & > div{
            margin-bottom: -3px;
        }
    }

    & .flexgrow{
        flex-grow: 1;
    }
`

export const TabsToolbarBackButton = styled.div`
    display: flex;
    align-content: center;
    width: 56px;

    & svg{
        font-size: 30px;
        padding: 3px;

        &:hover{
            background-color: ${(props): string => transparentize(0.94, props.theme.palette.neutral800.main)};
            border-radius: 50%;
            cursor: pointer;
        }
    }
`

export const Typography = styled(styled(MuiTypography)(MuiSpacing))``
