import React from 'react'
import BaseCarrierLogo from '../../BaseCarrierLogo'
import { StyledCarrierLogo } from './Logo.styled'
import { Typography } from '@material-ui/core'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './AndMore.i18n'

const AndMoreLogo: React.FunctionComponent<React.ComponentProps<typeof BaseCarrierLogo>> = ({ ...rest }) => {
    const { t } = useI18n(i18n)

    return <StyledCarrierLogo
        { ...rest }
        logo={
            <Typography variant={'h2'}>{t('AND MORE...')}</Typography>
        }
        name={'AndMoreLogo'}
    />
}

export default AndMoreLogo
