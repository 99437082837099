import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Paper, PaperProps, Typography, TypographyProps } from '@material-ui/core'
import { ImageContainerProps } from '@/app/ui-new/pages/website/components/Carousel/Desktop/StepCardDesktop'
import { transparentize } from 'polished'

export const StyledStepCard: React.FC<BoxProps> = styled(Box)<BoxProps>`
    overflow-y: hidden;
`

export const StyledPaper: React.FC<PaperProps> = styled(Paper)<PaperProps>`
    border-radius: 16px;
    padding: ${(props): number => props.theme.spacing(12)}px;
    position: relative;
    height: 100%;
`

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    font-size: 2.25rem;
`

export const StyledStepTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 4rem;
    margin-top: ${(props): number => props.theme.spacing(-5)}px;
    margin-bottom: ${(props): number => props.theme.spacing(-5)}px;
`

export const StyledTotalStepsTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    color: ${(props): number => props.theme.palette.neutral500.main};
`

export const StyledDisclaimerTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    color: ${(props): number => props.theme.palette.text.disabled};
`

export const StyledImageContainer: React.FC<ImageContainerProps> = styled(Box)<ImageContainerProps>`
    width: 360px;
    height: 304px;
    background-image: url(${(props): string => props.imgSrc});
    background-size: cover;
    border-radius: 8px;
`

export const StyledContentTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.125rem;
`

export const StyledOverlay = styled.div`
    border-radius: 16px;
    background: ${(props): string => transparentize(0.2, props.theme.palette.white.main)};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`
