import React from 'react'
import { Select as MuiSelect, SelectProps } from '@material-ui/core'
import styled from 'styled-components'

export const StyledSelect: React.FC<SelectProps> = styled(MuiSelect).withConfig({
    shouldForwardProp: (prop) => ![ 'helperText' ].includes(prop),
})<SelectProps>`
    [class*="MuiSelect-select"]:focus{
        background-color: unset;
    }

    [class*="MuiSelect-icon"]{
        top: calc(50% - 11px);
    }
`
