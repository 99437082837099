import React, { useEffect } from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './index.i18n'
import { StyledAlertBanner, StyledAlertBannerContent, StyledTypography, StyledButton, StyledSnackbar } from './index.styled'

export const MessageNotistackAlert: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)
    const [isVisible, setIsVisible] = React.useState<boolean>(props.open)


    if (!isVisible) {
        return null
    }
    const currentDate = new Date().toISOString().split('T')[0]

    const setCookie = (name: string, value: string, days: number) => {
        const expires = new Date()
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000) // Set expiration in days
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=/'
    }
    // Update the cookie with today's date
    // Cookie expires in 1 day

    return (
        <StyledSnackbar
            open={isVisible}
            onClose={() => {
                setIsVisible(false)
            }}
            autoHideDuration={null}
        >
            <StyledAlertBanner severity='warning'>
                <StyledAlertBannerContent
                    title={t('Attention')}
                    /* eslint-disable-next-line max-len */
                    message={<StyledTypography variant='body1'>{t('message')}</StyledTypography>}
                    buttons={
                        <StyledButton
                            size='medium'
                            onClick={() => {
                                setIsVisible(false)
                                setCookie(props.messageCookieName, currentDate, props.expiresDays)
                            }}
                        >
                            OK
                        </StyledButton>
                    }
                />
            </StyledAlertBanner>
        </StyledSnackbar>
    )
}
interface Props {
    open: boolean
    messageCookieName: string
    expiresDays: number
}
