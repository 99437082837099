import styled from 'styled-components'
import BaseCarrierLogo from '../../BaseCarrierLogo'

export const StyledCarrierLogo = styled(BaseCarrierLogo)`
    div {
        align-items: center;
        justify-content: center;
    }

    h2 {
        font-size: ${(props): number => getFontSize(props.size)}rem;
        color: rgba(0,0,0,0.26);
        font-weight: 500;
        line-height: 120%;
        width: ${(props): string => props.size === 'wide-large' ? '100%' : 'min-content'};
    }
`


const getFontSize = (size: string | undefined) => {
    switch (size) {
        case 'wide-large':
            return 1.125
        case 'large':
            return 1
        case 'small':
            return 0.5
        case 'medium':
        default:
            return 0.8
    }
}
