import React from 'react'
import { StyledMuiButton } from './TextButton.styled'
import { ButtonProps } from '@material-ui/core'

const TextButton: React.FunctionComponent<ButtonProps> = React.forwardRef((props, ref) => (
    <StyledMuiButton
        {...props}
        ref={ref}
    >
        {props.children}
    </StyledMuiButton>
))
TextButton.displayName = 'TextButton'

export default TextButton
