import styled from 'styled-components'
import { Box as MuiBox, Typography, TypographyProps } from '@material-ui/core'
import React from 'react'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${(props): string => props.theme.palette.white.main};
`
export const StyledBox = styled(MuiBox)`
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: ${(props): string => props.theme.spacing(9)}px;
    background-color: #5D5D5D;
    width: 456px;
    height: 100%;
    display: flex;
    align-items: center;
`
