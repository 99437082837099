import React, { useState } from 'react'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './index.i18n'
import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledPrimaryButton,
    StyledSnackbar,
} from './index.styled'


import Cookies from 'js-cookie'
import { Typography } from '@material-ui/core'
import useSizeScreen from '@/app/ui-new/pages/hooks/useSizeScreen'

export const BestExperienceNotistackAlert: React.FunctionComponent<Props> = (props) => {
    const { t } = useI18n(i18n)
    const [snackbarBestExperienceOpen, setSnackbarBestExperienceOpen] = useState<boolean>(
        Cookies.get('cookie-experienceSizeScreen-agreed') !== 'true',
    )
    const monitor = useSizeScreen().sizeScreen
    const handleSnackbarCookieBestExperienceClose = (reason: string): void => {
        if (reason === 'clickaway') {
            return
        }
        const aYearFromNow = new Date()
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
        Cookies.set('cookie-experienceSizeScreen-agreed', 'true', { expires: aYearFromNow })
        setSnackbarBestExperienceOpen(false)
    }

    const bestExperienceMessage = (
        <Typography variant='body1'>
            {t("For the best experience on our website, we recommend using a desktop computer.")}
        </Typography>
    )

    return (
        <>
            {!monitor.lg && (
                <StyledSnackbar
                    open={snackbarBestExperienceOpen}
                    onClose={(event, reason): any => handleSnackbarCookieBestExperienceClose(reason)}
                    autoHideDuration={null}
                >
                    <StyledAlertBanner severity='info'>
                        <StyledAlertBannerContent
                            title={t('Attention')}
                            message={bestExperienceMessage}
                            buttons={
                                <>
                                    <StyledPrimaryButton size='medium' onClick={(): any => handleSnackbarCookieBestExperienceClose('hide')}>
                                        {t('Hide')}
                                    </StyledPrimaryButton>
                                </>
                            }
                        />
                    </StyledAlertBanner>
                </StyledSnackbar>
            )}
        </>
    )
}
interface Props {}
