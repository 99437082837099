import React, { useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@/app/ui-new/components/IconButton/IconButton'
import {
    StyledIconButton, StyledSubTitleTypography,
    StyledSwipeableViews,
    StyledTitleTypography,
    StyledPrimaryButton,
} from '@/app/ui-new/pages/website/components/Carousel/Desktop/CarouselDesktop.styled'
import { Box } from '@material-ui/core'
import StepCardDesktop, { StepCardDesktopProps } from '@/app/ui-new/pages/website/components/Carousel/Desktop/StepCardDesktop'
import Stepper from '@/app/ui-new/pages/website/components/Carousel/Stepper'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './CarouselDesktop.i18n'
import en from '@/app/ui-new/pages/website/components/Carousel/Desktop/CarouselDesktop.i18n.en'

const CarouselDesktop: React.FunctionComponent<CarouselDesktopProps> = (props: CarouselDesktopProps) => {
    const theme = useTheme()
    const [ activeStep, setActiveStep ] = React.useState(0)
    const maxSteps = props.stepCards.length
    const { t } = useI18n(i18n)

    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }, [ setActiveStep ])

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }, [ setActiveStep ])

    const handleStepChange = useCallback ((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    const handleStepperClick = useCallback ((step: number) => {
        setActiveStep(step)
    }, [ setActiveStep ])

    return (
        <Box width={'100%'} bgcolor={'#EBF3F3'} display={'flex'} justifyContent={'center'}>
            <Box width={1440} py={25} bgcolor={'#EBF3F3'}>
                <Box px={27} pb={22} >
                    <Box width={500}>
                        <StyledTitleTypography variant={'h1'}>
                            {t('The ultimate logistics solution')}
                        </StyledTitleTypography>
                    </Box>
                    <Box mt={16} display='flex' justifyContent='space-between'>
                        <Box width={680}>
                            <StyledSubTitleTypography variant='h6'>
                                {t('Streamline your entire shipping process with one elegant platform. You’ll get help from Lazr at every step.')}
                            </StyledSubTitleTypography>
                        </Box>
                        <Box minWidth={300} display='flex' justifyContent='flex-end'>
                            <StyledPrimaryButton
                                endIcon={
                                    <img src={`/images/website/carousel/LazrBrandedArrowWhite.svg?v=${window.lazrVersion}`}
                                        alt="LazrIcon" />
                                }
                                size={'large'} href='/register'>
                                {t('Get Lazr for free')}
                            </StyledPrimaryButton>
                        </Box>
                    </Box>
                </Box>

                <Box display='flex' flexDirection='column' justifyContent='center'>
                    <Box position='relative'>
                        <Box position='absolute' top={'50%'} left={20}>
                            <StyledIconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {<FontAwesomeIcon  icon={[ 'far', 'arrow-left' ]}/>}
                            </StyledIconButton>
                        </Box>
                        <StyledSwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {
                                props.stepCards.map((card, index) => (
                                    <StepCardDesktop
                                        key={index}
                                        title={t(card.title as keyof typeof en)}
                                        primaryContent={t(card.primaryContent  as keyof typeof en)}
                                        disclaimer={t(card.disclaimer  as keyof typeof en)}
                                        imgSrc={card.imgSrc}
                                        step={index + 1}
                                        totalSteps={maxSteps}
                                        isActive={activeStep === index}
                                    />
                                ))
                            }
                        </StyledSwipeableViews>
                        <Box position='absolute' top={'50%'} right={20}>
                            <StyledIconButton size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                {<FontAwesomeIcon  icon={[ 'far', 'arrow-right' ]}/>}
                            </StyledIconButton>
                        </Box>
                    </Box>
                    <Box mt={8} display='flex' alignItems='center' justifyContent='center'>
                        <Stepper steps={maxSteps} activeStep={activeStep} onClick={(step: number) => handleStepperClick(step)}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export interface CarouselDesktopProps {
    stepCards: StepCardDesktopProps[]
}

export default CarouselDesktop
