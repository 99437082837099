/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet214Schema_parse_ = {
    heading?: {
        assignedNumberLXLoop?: Array<{
            electronicFormatIdentificationEFILoop?: Array<{
                binaryDataBIN?: {
                    binaryData02?: string,
                    lengthOfBinaryData01?: number,
                },
                electronicFormatIdentificationEFI?: {
                    filterIdCode16?: string,
                    versionIdentifier15?: string,
                    blockLength14?: number,
                    recordLength13?: number,
                    blockType12?: string,
                    fileName11?: string,
                    drawingSheetSizeCode10?: string,
                    compressionTechnique09?: string,
                    versionIdentifier08?: string,
                    interchangeFormat07?: string,
                    versionIdentifier06?: string,
                    programIdentifier05?: string,
                    versionIdentifier04?: string,
                    securityTechniqueCode03?: string,
                    freeFormMessageText02?: string,
                    securityLevelCode01?: string,
                },
            }>,
            shipmentPurchaseOrderDetailSPOLoop?: Array<{
                destinationQuantitySDQ?: Array<{
                    locationIdentifier23?: string,
                    quantity22?: number,
                    identificationCode21?: string,
                    quantity20?: number,
                    identificationCode19?: string,
                    quantity18?: number,
                    identificationCode17?: string,
                    quantity16?: number,
                    identificationCode15?: string,
                    identificationCode13?: string,
                    quantity12?: number,
                    identificationCode11?: string,
                    quantity08?: number,
                    identificationCode07?: string,
                    identificationCode05?: string,
                    identificationCode03?: string,
                    identificationCodeQualifier02?: string,
                    quantity04?: number,
                    identificationCode09?: string,
                    quantity14?: number,
                    quantity10?: number,
                    quantity06?: number,
                    unitOrBasisForMeasurementCode01?: string,
                }>,
                shipmentPurchaseOrderDetailSPO?: {
                    referenceIdentification08?: string,
                    applicationErrorConditionCode07?: string,
                    quantity04?: number,
                    unitOrBasisForMeasurementCode03?: string,
                    purchaseOrderNumber01?: string,
                    referenceIdentification02?: string,
                    weight06?: number,
                    weightUnitCode05?: string,
                },
            }>,
            purchaseOrderReferencePRFLoop?: Array<{
                purchaseOrderReferencePRF?: {
                    purchaseOrderTypeCode07?: string,
                    contractNumber06?: string,
                    assignedIdentification05?: string,
                    changeOrderSequenceNumber03?: string,
                    releaseNumber02?: string,
                    purchaseOrderNumber01?: string,
                    date04?: string,
                },
                cartonPackageDetailCD3Loop?: Array<{
                    cartonPackageDetailCD3?: {
                        countryCode14?: string,
                        paymentMethodCode13?: string,
                        serviceLevelCode12?: string,
                        serviceLevelCode11?: string,
                        charge10?: number,
                        rateValueQualifier09?: string,
                        charge08?: number,
                        rateValueQualifier07?: string,
                        pickUpOrDeliveryCode06?: string,
                        serviceLevelCode05?: string,
                        serviceStandard04?: number,
                        zone03?: string,
                        weight02?: number,
                        weightQualifier01?: string,
                    },
                    shipmentStatusDetailsAT7Loop?: Array<{
                        equipmentOrContainerOwnerAndTypeMS2?: {
                            equipmentNumberCheckDigit04?: number,
                            equipmentDescriptionCode03?: string,
                            equipmentNumber02?: string,
                            standardCarrierAlphaCode01?: string,
                        },
                        equipmentShipmentOrRealPropertyLocationMS1?: {
                            directionIdentifierCode07?: string,
                            directionIdentifierCode06?: string,
                            latitudeCode05?: string,
                            longitudeCode04?: string,
                            countryCode03?: string,
                            stateOrProvinceCode02?: string,
                            cityName01?: string,
                        },
                        shipmentStatusDetailsAT7?: {
                            timeCode07?: string,
                            time06?: string,
                            date05?: string,
                            shipmentStatusOrAppointmentReasonCode04?: string,
                            shipmentAppointmentStatusCode03?: string,
                            shipmentStatusOrAppointmentReasonCode02?: string,
                            shipmentStatusCode01?: string,
                        },
                    }>,
                    marksAndNumbersMAN?: Array<{
                        marksAndNumbers05?: string,
                        marksAndNumbersQualifier04?: string,
                        marksAndNumbers03?: string,
                        marksAndNumbers02?: string,
                        marksAndNumbersQualifier01?: string,
                        marksAndNumbers06?: string,
                    }>,
                    businessInstructionsAndReferenceNumberL11?: Array<{
                        description03?: string,
                        referenceIdentificationQualifier02?: string,
                        referenceIdentification01?: string,
                    }>,
                }>,
                nameN1Loop?: Array<{
                    geographicLocationN4?: {
                        locationIdentifier06?: string,
                        locationQualifier05?: string,
                        countryCode04?: string,
                        postalCode03?: string,
                        stateOrProvinceCode02?: string,
                        cityName01?: string,
                    },
                    addressInformationN3?: Array<{
                        addressInformation02?: string,
                        addressInformation01?: string,
                    }>,
                    additionalNameInformationN2?: {
                        name02?: string,
                        name01?: string,
                    },
                    nameN1?: {
                        entityIdentifierCode06?: string,
                        entityRelationshipCode05?: string,
                        identificationCode04?: string,
                        identificationCodeQualifier03?: string,
                        name02?: string,
                        entityIdentifierCode01?: string,
                    },
                    businessInstructionsAndReferenceNumberL11?: Array<{
                        description03?: string,
                        referenceIdentificationQualifier02?: string,
                        referenceIdentification01?: string,
                    }>,
                }>,
            }>,
            cartonPackageDetailCD3Loop?: Array<{
                individualOrOrganizationalNameNM1?: {
                    entityIdentifierCode11?: string,
                    entityRelationshipCode10?: string,
                    identificationCode09?: string,
                    identificationCodeQualifier08?: string,
                    nameSuffix07?: string,
                    namePrefix06?: string,
                    nameMiddle05?: string,
                    nameFirst04?: string,
                    nameLastOrOrganizationName03?: string,
                    entityTypeQualifier02?: string,
                    entityIdentifierCode01?: string,
                },
                cartonPackageDetailCD3?: {
                    countryCode14?: string,
                    paymentMethodCode13?: string,
                    serviceLevelCode12?: string,
                    serviceLevelCode11?: string,
                    charge10?: number,
                    rateValueQualifier09?: string,
                    charge08?: number,
                    rateValueQualifier07?: string,
                    pickUpOrDeliveryCode06?: string,
                    serviceLevelCode05?: string,
                    serviceStandard04?: number,
                    zone03?: string,
                    weight02?: number,
                    weightQualifier01?: string,
                },
                ladingExceptionCodeQ7?: Array<{
                    ladingQuantity03?: number,
                    packagingFormCode02?: string,
                    ladingExceptionCode01?: string,
                }>,
                shipmentStatusDetailsAT7Loop?: Array<{
                    equipmentOrContainerOwnerAndTypeMS2?: {
                        equipmentNumberCheckDigit04?: number,
                        equipmentDescriptionCode03?: string,
                        equipmentNumber02?: string,
                        standardCarrierAlphaCode01?: string,
                    },
                    equipmentShipmentOrRealPropertyLocationMS1?: {
                        directionIdentifierCode07?: string,
                        directionIdentifierCode06?: string,
                        latitudeCode05?: string,
                        longitudeCode04?: string,
                        countryCode03?: string,
                        stateOrProvinceCode02?: string,
                        cityName01?: string,
                    },
                    shipmentStatusDetailsAT7?: {
                        timeCode07?: string,
                        time06?: string,
                        date05?: string,
                        shipmentStatusOrAppointmentReasonCode04?: string,
                        shipmentAppointmentStatusCode03?: string,
                        shipmentStatusOrAppointmentReasonCode02?: string,
                        shipmentStatusCode01?: string,
                    },
                }>,
                marksAndNumbersMAN?: Array<{
                    marksAndNumbers05?: string,
                    marksAndNumbersQualifier04?: string,
                    marksAndNumbers03?: string,
                    marksAndNumbers02?: string,
                    marksAndNumbersQualifier01?: string,
                    marksAndNumbers06?: string,
                }>,
                shipmentWeightPackagingAndQuantityDataAT8?: {
                    volumeUnitQualifier06?: string,
                    ladingQuantity05?: number,
                    ladingQuantity04?: number,
                    weightUnitCode02?: string,
                    weightQualifier01?: string,
                    volume07?: number,
                    weight03?: number,
                },
                nameN1Loop?: Array<{
                    geographicLocationN4?: {
                        locationIdentifier06?: string,
                        locationQualifier05?: string,
                        countryCode04?: string,
                        postalCode03?: string,
                        stateOrProvinceCode02?: string,
                        cityName01?: string,
                    },
                    addressInformationN3?: Array<{
                        addressInformation02?: string,
                        addressInformation01?: string,
                    }>,
                    additionalNameInformationN2?: {
                        name02?: string,
                        name01?: string,
                    },
                    nameN1?: {
                        entityIdentifierCode06?: string,
                        entityRelationshipCode05?: string,
                        identificationCode04?: string,
                        identificationCodeQualifier03?: string,
                        name02?: string,
                        entityIdentifierCode01?: string,
                    },
                    businessInstructionsAndReferenceNumberL11?: Array<{
                        description03?: string,
                        referenceIdentificationQualifier02?: string,
                        referenceIdentification01?: string,
                    }>,
                }>,
                businessInstructionsAndReferenceNumberL11?: Array<{
                    description03?: string,
                    referenceIdentificationQualifier02?: string,
                    referenceIdentification01?: string,
                }>,
            }>,
            ladingExceptionCodeQ7?: Array<{
                ladingQuantity03?: number,
                packagingFormCode02?: string,
                ladingExceptionCode01?: string,
            }>,
            shipmentStatusDetailsAT7Loop?: Array<{
                equipmentOrContainerOwnerAndTypeMS2?: {
                    equipmentNumberCheckDigit04?: number,
                    equipmentDescriptionCode03?: string,
                    equipmentNumber02?: string,
                    standardCarrierAlphaCode01?: string,
                },
                equipmentShipmentOrRealPropertyLocationMS1?: {
                    directionIdentifierCode07?: string,
                    directionIdentifierCode06?: string,
                    latitudeCode05?: string,
                    longitudeCode04?: string,
                    countryCode03?: string,
                    stateOrProvinceCode02?: string,
                    cityName01?: string,
                },
                shipmentStatusDetailsAT7?: {
                    timeCode07?: string,
                    time06?: string,
                    date05?: string,
                    shipmentStatusOrAppointmentReasonCode04?: string,
                    shipmentAppointmentStatusCode03?: string,
                    shipmentStatusOrAppointmentReasonCode02?: string,
                    shipmentStatusCode01?: string,
                },
            }>,
            assignedNumberLX?: {
                assignedNumber01?: number,
            },
            marksAndNumbersMAN?: Array<{
                marksAndNumbers05?: string,
                marksAndNumbersQualifier04?: string,
                marksAndNumbers03?: string,
                marksAndNumbers02?: string,
                marksAndNumbersQualifier01?: string,
                marksAndNumbers06?: string,
            }>,
            remarksK1?: Array<{
                freeFormMessage02?: string,
                freeFormMessage01?: string,
            }>,
            shipmentWeightPackagingAndQuantityDataAT8?: Array<{
                volumeUnitQualifier06?: string,
                ladingQuantity05?: number,
                ladingQuantity04?: number,
                weightUnitCode02?: string,
                weightQualifier01?: string,
                volume07?: number,
                weight03?: number,
            }>,
            billOfLadingHandlingRequirementsAT5?: Array<{
                specialHandlingDescription03?: string,
                specialServicesCode02?: string,
                specialHandlingCode01?: string,
            }>,
            businessInstructionsAndReferenceNumberL11?: Array<{
                description03?: string,
                referenceIdentificationQualifier02?: string,
                referenceIdentification01?: string,
            }>,
        }>,
        marksAndNumbersMAN?: Array<{
            marksAndNumbers05?: string,
            marksAndNumbersQualifier04?: string,
            marksAndNumbers03?: string,
            marksAndNumbers02?: string,
            marksAndNumbersQualifier01?: string,
            marksAndNumbers06?: string,
        }>,
        beginningSegmentForTransportationCarrierShipmentStatusMessageB10?: {
            yesNoConditionOrResponseCode07?: string,
            inquiryRequestNumber04?: number,
            standardCarrierAlphaCode03?: string,
            referenceIdentificationQualifier05?: string,
            shipmentIdentificationNumber02?: string,
            referenceIdentification06?: string,
            referenceIdentification01?: string,
        },
        remarksK1?: Array<{
            freeFormMessage02?: string,
            freeFormMessage01?: string,
        }>,
        transactionSetTrailerSE?: {
            numberOfIncludedSegments01?: number,
            transactionSetControlNumber02?: string,
        },
        nameN1Loop?: Array<{
            contactG61?: {
                contactInquiryReference05?: string,
                communicationNumber04?: string,
                communicationNumberQualifier03?: string,
                contactFunctionCode01?: string,
                name02?: string,
            },
            geographicLocationN4?: {
                locationIdentifier06?: string,
                locationQualifier05?: string,
                countryCode04?: string,
                postalCode03?: string,
                stateOrProvinceCode02?: string,
                cityName01?: string,
            },
            addressInformationN3?: Array<{
                addressInformation02?: string,
                addressInformation01?: string,
            }>,
            additionalNameInformationN2?: {
                name02?: string,
                name01?: string,
            },
            nameN1?: {
                entityIdentifierCode06?: string,
                entityRelationshipCode05?: string,
                identificationCode04?: string,
                identificationCodeQualifier03?: string,
                name02?: string,
                entityIdentifierCode01?: string,
            },
            dateTimeG62?: {
                timeCode05?: string,
                time04?: string,
                timeQualifier03?: string,
                date02?: string,
                dateQualifier01?: string,
            },
            businessInstructionsAndReferenceNumberL11?: Array<{
                description03?: string,
                referenceIdentificationQualifier02?: string,
                referenceIdentification01?: string,
            }>,
        }>,
        interlineInformationMS3?: Array<{
            stateOrProvinceCode05?: string,
            transportationMethodTypeCode04?: string,
            cityName03?: string,
            routingSequenceCode02?: string,
            standardCarrierAlphaCode01?: string,
        }>,
        businessInstructionsAndReferenceNumberL11?: Array<{
            description03?: string,
            referenceIdentificationQualifier02?: string,
            referenceIdentification01?: string,
        }>,
        transactionSetHeaderST?: {
            transactionSetControlNumber02?: string,
            transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet214Schema_parse_.transactionSetIdentifierCode01,
        },
    };
}

export namespace ReturnType_typeofEdiTransactionSet214Schema_parse_ {

    export enum transactionSetIdentifierCode01 {
        _214 = '214',
    }


}
