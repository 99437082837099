import { logger } from '../../logger'
import { ApiError, ErrorResponse, GetGmxRegistrationInfoResponse, GmxService } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const GmxRegistrationApiService = Object.freeze({
    get: async (id: string): Promise<GetGmxRegistrationInfoResponse> => {
        let getGmxRegistrationInfoResponse
        try {
            getGmxRegistrationInfoResponse = await GmxService.getGmxRegistrationInfo(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve GMX Registration Info')
            }
            throw new Error('Unable to retrieve GMX Registration Info')
        }

        if (!getGmxRegistrationInfoResponse.data) {
            logger.debug('list', JSON.stringify(getGmxRegistrationInfoResponse, null, 4))
            throw new Error('Unable to retrieve GMX Registration Info')
        }

        return getGmxRegistrationInfoResponse.data
    },
})
