import React from 'react'
import { TextField as CustomTextField } from './TextFieldLabel.styled'
import { OutlinedTextFieldProps, StandardTextFieldProps } from '@material-ui/core'
import Tooltip, { CustomTooltipProps } from '../Tooltip/Tooltip'
import { FontAwesomeIcon } from '../IconButton/IconButton.styled'
import { QuestionIcon } from '../Tooltip/Tooltip.styled'


const TextFieldLabel: React.FunctionComponent<CustomsTextFieldProps> = React.forwardRef((props, ref) =>
    <>
        <CustomTextField
            {...props}
            ref={ref}
        />
        {props?.tooltip &&
            <Tooltip {...props?.tooltip} >
                <QuestionIcon>
                    <FontAwesomeIcon icon={props?.tooltip?.icon ? props?.tooltip?.icon : ['far', 'question-circle']} />
                </QuestionIcon>
            </Tooltip>
        }
    </>

)

TextFieldLabel.displayName = 'TextField'

export interface CustomsTextFieldProps extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, 'select'> {
    label?: string
    tooltip?: CustomTooltipProps
}

export default TextFieldLabel

