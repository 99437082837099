/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Create your free Account`]: `Create your free Account`,
    [`Start using Lazr for free and explore all the potential carriers and pricing for your shipments. No contracts or credit card required.`]: `Start using Lazr for free and explore all the potential carriers and pricing for your shipments. No contracts or credit card required.`,
    [`Ship better in every way`]: `Ship better in every way`,
    [`50+ Connected freight carriers`]: `50+ Connected freight carriers`,
    [`8 Connected parcel carriers`]: `8 Connected parcel carriers`,
    [`21% Average savings in shipping costs`]: `21% Average savings in shipping costs`,
    [`95% Faster than traditional quoting`]: `95% Faster than traditional quoting`,
}
