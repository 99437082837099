/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './BannerMobile.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`See Lazr in action`]: `Voir Lazr en action`,
    [`Get a free preview of Lazr’s customized Enterprise platform, totally commitment-free.`]: `Obtenez un aperçu gratuit de la plateforme d'entreprise personnalisée de Lazr, totalement sans engagement.`,
    [`What can I expect?`]: `À quoi puis-je m'attendre?`,
    [`Shop & instantly compare the best shipping quotes from top carriers in North America, including exclusive deals from Lazr`]: `Magasinez et comparez instantanément les meilleurs prix d'expédition des meilleurs transporteurs en Amérique du Nord, y compris les offres exclusives de Lazr`,
    [`Track and manage your shipments across all carriers and services with our fully integrated shipping platform`]: `Suivez et gérez vos envois avec tous les transporteurs et services grâce à notre plateforme d'expédition entièrement intégrée`,
    [`Ship faster and easier than ever—Lazr keeps your info securely saved to autofill forms and automate billing steps`]: `Expédiez plus rapidement et plus facilement que jamais - Lazr conserve vos informations en toute sécurité pour remplir automatiquement les formulaires et automatiser les étapes de facturation`,
}))()
