/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticateRequest } from '../models/AuthenticateRequest';
import type { RefreshTokenRequest } from '../models/RefreshTokenRequest';
import type { SuccessResponse_AuthenticateResponse_ } from '../models/SuccessResponse_AuthenticateResponse_';
import type { SuccessResponse_LogOutResponse_ } from '../models/SuccessResponse_LogOutResponse_';
import type { SuccessResponse_RefreshTokenResponse_ } from '../models/SuccessResponse_RefreshTokenResponse_';
import { request as __request } from '../core/request';

export class AuthenticationService {

    /**
     * Authenticate to get a token to use for subsequent calls to the API
     * @param requestBody The data
     * @returns SuccessResponse_AuthenticateResponse_ OK
     * @throws ApiError
     */
    public static async authenticate(
        requestBody: AuthenticateRequest,
    ): Promise<SuccessResponse_AuthenticateResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/authenticate`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Logged out the user
     * @returns SuccessResponse_LogOutResponse_ OK
     * @throws ApiError
     */
    public static async logOutUser(): Promise<SuccessResponse_LogOutResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/log-out`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Refresh the token received after authentication to use for subsequent calls to the API
     * @param requestBody The token to refresh
     * @returns SuccessResponse_RefreshTokenResponse_ the new token
     * @throws ApiError
     */
    public static async refresh(
        requestBody: RefreshTokenRequest,
    ): Promise<SuccessResponse_RefreshTokenResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/refresh-token`,
            body: requestBody,
            errors: {
                401: `Unauthorized`,
            },
        });
        return result.body;
    }

}