/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import { StyledStrong } from './index.styled'

export default ((): Translations<typeof en> => ({
    [`You are currently in production`]: `Vous êtes actuellement en production`,
    [`BE CAREFUL AND STAY ALERT! When in doubt, seek guidance. It's easier to ask a question than it is to fix a mistake.`]: () => <><StyledStrong>SOYEZ PRUDENT ET RESTEZ ATTENTIF!</StyledStrong> En cas de doute, demandez des conseils. Il est plus facile de poser une question que de réparer une erreur.</>,
}))()
