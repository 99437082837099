/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PickupStatus {
    SCHEDULED = 'SCHEDULED',
    DISPATCHED = 'DISPATCHED',
    PICKED_UP = 'PICKED_UP',
    CLOSED = 'CLOSED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}