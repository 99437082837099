import React from 'react'

const Logo: React.FunctionComponent<Props> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Lazr Logo"
        viewBox="0 0 181.21 71.21"
        className={className}
    >
        {/* eslint-disable-next-line max-len */}
        <path d="M135.9 33.3V21.5H87.73v13.74h23.05L87.61 59.41V71.2h49.45V57.46h-24.32L135.9 33.3zM68.3 21.46H26.97v13.69h24.79l-24.8 25.32v10.66h10.58v-.01L62.3 46.2v24.93h14.23V21.47H68.3v-.01zM156.37 21.46v.01h-8.24v49.66h14.24V35.15h18.84V21.46h-24.84zM0 0h15.88v71.14H0z"/>
    </svg>
)

export interface Props {
    className?: string
}

export default Logo
