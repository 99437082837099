import styled from 'styled-components'
import SwipeableViews from 'react-swipeable-views'

export const StyledSwipeableViews = styled(SwipeableViews)`
    padding: 0 10px;
    cursor: pointer;
    width: 100%;

    .react-swipeable-view-container > div{
        padding: 0 4px;
        user-select: none;
    }
`
