/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PackageType {
    PLT = 'PLT',
    BAG = 'BAG',
    BALE = 'BALE',
    BOX = 'BOX',
    BUCKET = 'BUCKET',
    PAIL = 'PAIL',
    BUNDLE = 'BUNDLE',
    CAN = 'CAN',
    CARTON = 'CARTON',
    CASE = 'CASE',
    COIL = 'COIL',
    CRATE = 'CRATE',
    CYLINDER = 'CYLINDER',
    DRUM = 'DRUM',
    PIECES = 'PIECES',
    REEL = 'REEL',
    ROLL = 'ROLL',
    SKID = 'SKID',
    TUBE = 'TUBE',
    TOTE = 'TOTE',
    OTHER = 'OTHER',
    FORKLIFT = 'FORKLIFT',
}