/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './RegisterOrganization.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Confirm password']: 'Confirmez le mot de passe',
    ['Create New Organization']: 'Créer la Nouvelle Organisation',
    ['Email']: 'Courriel',
    ['Go Back to the Sign In Page']: 'Retourner à la page de connexion',
    ['Go Back to Main Page']: 'Retourner à la page principale',
    ['If you would like to join an existing organization, please contact an administrator of that organization for an invitation.']: 'Si vous souhaitez rejoindre une organisation existante, veuillez contacter un administrateur de cette organisation pour une invitation.',
    ['In order to sign in to Lazr, you must first verify your email by following the link sent to your inbox.']: `Pour vous connecter à Lazr, vous devez d'abord vérifier votre adresse courriel en suivant le lien envoyé dans votre boîte de réception`,
    ['New Organization Name']: `Nom de la nouvelle organisation`,
    ['Password confirmation does not match']: 'Le mot de passe ne correspond pas',
    ['Password']: 'Mot de passe',
    ['Success!']: 'Succès!',
    ['This field is required']: 'Ce champ est requis',
    ['This form will create a new organization within Lazr.']: 'Ce formulaire créera une nouvelle organisation dans Lazr.',
    ['Unable to register organization']: `Nous n'avons pas pu inscrire l'organisation.`,
    ['Your account has been created, sign in now.']: 'Votre compte a été créé. Connectez-vous maintenant.',
    [`First Name`]: `Prénom`,
    [`Last Name`]: `Nom`,
    [`Phone`]: `Téléphone`,
}))()
