import BaseTooltip from '@/app/ui-new/components/Tooltip/Tooltip'
import RawLogo from '@/app/ui/components/Logo'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
  IconButton as MuiIconButton,
    Box as MuiBox,
    Grid as MuiGrid,
    Typography as MuiTypography,
} from '@material-ui/core'


export const Typography = styled(styled(MuiTypography)(MuiSpacing))``

export const Grid = styled(styled(MuiGrid)(MuiSpacing))`
.truck-calendar-link:hover .cls-1,
.truck-calendar-link:hover .cls-2,
.truck-calendar-link:hover .cls-3,
.truck-calendar-link:hover .cls-4 {
  stroke: #FFFFFF; /* Change stroke color on hover */

}
.truck-calendar-link:hover .cls-4 {
  fill: #FFFFFF;
}

  &#navbar {
    & .navbar-menu-item {
      margin-right: ${(props): string => props.theme.spacing(2)}px;
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        border-radius: 4px;
        min-width: 40px;
        background: rgba(223, 247, 245, 0.1);
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0);
        box-sizing: border-box;
        padding: ${(props): string => props.theme.spacing(2)}px;
        font-weight: 600;
        & svg {
          font-size: 20px;
        }
        &:hover {
          background-color: ${(props): number => props.theme.palette.primary.main};
          color: ${(props): number => props.theme.palette.primary.contrastText};
          border: 1px solid rgba(0, 0, 0, 0);
        }
      }
     
      &.selected {
        & a {
          background: ${(props): number => props.theme.palette.accentPrimary50.main};
          color: ${(props): number => props.theme.palette.primary.main};
          & svg {
            margin-right: 8px;
          }
        }
        &:hover {
          & a {
            background: ${(props): number => props.theme.palette.accentPrimary50.main};
            color: ${( props ): number => props.theme.palette.primary.main};
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }
`

export const GMXButton = styled.a `
border: solid 1px #fff;
display: block;
color: white;
text-decoration: none;
margin-left: auto;
padding: ${(props): string => props.theme.spacing(2)}px;
`
 

export const Header = styled.header`
    min-width: 1280px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: black;
    color: white;
    width: 100%;
    padding-right: ${(props): string => props.theme.spacing(2)}px;
    padding-bottom:${(props): string => props.theme.spacing(3)}px;
    padding-top: ${(props): string => props.theme.spacing(4)}px;
    padding-left: ${(props): string => props.theme.spacing(4)}px;
    // height: 72px;
    z-index: 1299;

 
`

export const Logo = styled(RawLogo)`
    width: 70px;
    /*height: 100%;*/
    padding-left: 1.7px;
    transform: translateY(2px);
    fill: ${(props): string => props.theme.palette.white.main};
    margin-right: 48px;
`

export const Link = styled(RouterLink)`
    text-decoration: none;
    color: ${(props): number => props.theme.palette.white.main};
`

export const NavButton = styled(SecondaryButton)`
    color: ${(props): number => props.theme.palette.white.main};
    background: rgba(223, 247, 245, 0.1);
    border: 1px solid rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    height: 40px;
    min-width: 40px;

    & [class^="MuiButton-startIcon"] {
        margin: 0;
        padding: 0;

        & svg {
            font-size: 20px;
        }
    }

    &:hover {
        background-color: ${(props): number => props.theme.palette.primary.main};
        color: ${(props): number => props.theme.palette.primary.contrastText};
        border: 1px solid rgba(0, 0, 0, 0);
    }

    &:focus {
        outline: 0;
    }
`

export const NavButtonSelected = styled(NavButton)`
    color: ${(props): number => props.theme.palette.primary.main};
    background: ${(props): number => props.theme.palette.accentPrimary50.main};
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 0 10px;

    & [class^="MuiButton-startIcon"] {
        margin-right: 8px;
    }

    &:hover {
        color: ${(props): number => props.theme.palette.primary.main};
        background: ${(props): number => props.theme.palette.accentPrimary50.main};
        border: 1px solid rgba(0, 0, 0, 0);
    }
`

export const Tooltip = styled(BaseTooltip)`
    + .lzr-popper > .lzr-tooltip {
        color: ${(props): number => props.theme.palette.primary.contrastText};
        background-color: ${(props): number => props.theme.palette.primary.main};
        font-weight: 400;
    }
    + .lzr-popper > div > span[class*="MuiTooltip-arrow-"] {
        color: ${(props): number => props.theme.palette.primary.main};
    }
`

export const UtilitiesBox = styled(MuiBox)`
    display: flex;
    flex-direction: row;

    & > :not(div),
    & [class^="MuiButtonBase-root"] {
        color: ${(props): number => props.theme.palette.white.main};
        background: rgba(223, 247, 245, 0.1);
        border: 1px solid rgba(0, 0, 0, 0);
        border-radius: 0;
        margin: 0;
        padding: 0;
        height: 40px;
        min-width: 40px;

        & [class^="MuiButton-startIcon"] {
            margin: 0;
            padding: 0;

            & svg {
                font-size: 20px;
            }
        }

        &:hover {
            color: ${(props): number => props.theme.palette.white.main};
            background: rgba(223, 247, 245, 0.2);
            border: 1px solid rgba(0, 0, 0, 0);
        }

        &:focus {
            outline: 0;
        }
    }

    & > * {
        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
`
export const IconButton = styled(MuiIconButton)`
    width: 48px;
    height: 48px;
    margin: 0 ${(props): number => props.theme.spacing(0.5)}px;

    &:hover {
      background-color: #DFF7F5;
      color: #005457;
    }

    svg {
        width: 24px;
        height: 24px;
    }
`

export const GridMobile = styled(styled(MuiGrid)(MuiSpacing))`
  &#navbar-mobile {
  
    #close-mobile:hover {
        background-color: #005457;
          color: #FFFFFF;   
    }
    & .navbar-mobile-menu-item {
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        border-radius: 4px;
        min-width: 40px;
        background: rgba(223, 247, 245, 0.1);
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0);
        box-sizing: border-box;
        padding: ${(props): string => props.theme.spacing(2)}px;
        font-weight: 600;
        & svg {
          font-size: 20px;
        }
        background:rgba(223, 247, 245, 0.1);
        text-decoration: none;
        color: rgb(18, 21, 31);
        &:hover {
          background-color: #005457;
          color: #FFFFFF;
          border: 1px solid rgba(0, 0, 0, 0);
        }
      }
      &.selected {
        & a {
            background-color: #DFF7F5;
            color: #005457;
          & svg {
           
          }
        }
        &:hover {
          & a {
            background-color: #DFF7F5;
            color: #005457;
          }
        }
      }

      &:focus {
        outline: 0;
      }
    }
  }
`