import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import styled from 'styled-components'
import { AlertBanner, AlertBannerContent } from '@/app/ui-new/components/AlertBanner/AlertBanner'
import { Typography as MuiTypography } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const StyledTypography = styled(styled(MuiTypography)(MuiSpacing))``

export const StyledStrong = styled.strong`
    text-decoration: underline;
`

export const StyledAlertBanner = styled(AlertBanner)`
`

export const StyledAlertBannerContent = styled(AlertBannerContent)`
`

export const StyledButton = styled(PrimaryButton)`
    white-space: nowrap;
`
