/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    [`Ship`]: `Ship`,
    [`better`]: `better`,
    [`in every way`]: `in every way`,
    [`Traditional shipping takes too long and costs too much`]: `Traditional shipping takes too long and costs too much`,
    [`Get Lazr to take care of all your cross-border shipping needs and more`]: `Get Lazr to take care of all your cross-border shipping needs and more`,
    [`minutes to complete booking, documentation and billing`]: `minutes to complete booking, documentation and billing`,
    [`Limited options from one or two carriers`]: `Limited options from one or two carriers`,
    [`Scattered shipment tracking`]: `Scattered shipment tracking`,
    [`Multiple accounts with different logins`]: `Multiple accounts with different logins`,
    [`Less than 3 minutes to complete booking, documentation and billing`]: `Less than 3 minutes to complete booking, documentation and billing`,
    [`Instant shipping rates from dozens of carriers`]: `Instant shipping rates from dozens of carriers`,
    [`A single tracking view of all shipments, everywhere`]: `A single tracking view of all shipments, everywhere`,
    [`All freight & parcel carriers in one place`]: `All freight & parcel carriers in one place`,
    [`Connected freight carriers`]: `Connected freight carriers`,
    [`Connected parcel carriers`]: `Connected parcel carriers`,
    [`Average savings in shipping costs`]: `Average savings in shipping costs`,
    [`Faster than traditional quoting`]: `Faster than traditional quoting`,
    [`Lazr Way`]: `Lazr Way`,
    [`Old Way`]: `Old Way`,
}
