/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PageName {
    HOME = 'HOME',
    ORDER_LIST = 'ORDER_LIST',
    ORDER_DETAILS = 'ORDER_DETAILS',
    PUBLIC_TRACKING = 'PUBLIC_TRACKING',
    PROFILE = 'PROFILE',
    PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    ADDRESS_BOOK = 'ADDRESS_BOOK',
    NEW_ADDRESS = 'NEW_ADDRESS',
    EDIT_ADDRESS = 'EDIT_ADDRESS',
    NEW_ORDER = 'NEW_ORDER',
    NEW_CONSOLIDATION = 'NEW_CONSOLIDATION',
    MARKETPLACE = 'MARKETPLACE',
    ORGANIZATION_LIST = 'ORGANIZATION_LIST',
    USER_LIST = 'USER_LIST',
    INVOICE_LIST = 'INVOICE_LIST',
    INVOICE_PREVIEW = 'INVOICE_PREVIEW',
    EDI_ORDER_LIST = 'EDI_ORDER_LIST',
    USER_EVENT_NOTIFICATION_LIST = 'USER_EVENT_NOTIFICATION_LIST',
    EDI_ORDER_DETAILS = 'EDI_ORDER_DETAILS',
    SYSTEM_DASHBOARD = 'SYSTEM_DASHBOARD',
    STATEMENT_OF_ACCOUNT = 'STATEMENT_OF_ACCOUNT',
    INVOICE_CSV_BATCH = 'INVOICE_CSV_BATCH',
    INVOICE_CSV_BATCH_DETALIS = 'INVOICE_CSV_BATCH_DETALIS',
    LANDING = 'LANDING',
    TRACKING_UPDATE_CSV_BATCH = 'TRACKING_UPDATE_CSV_BATCH',
}