import styled from 'styled-components'
import {
    Box as MuiBox, Typography, TypographyProps,
} from '@material-ui/core'
import React from 'react'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 43.2px;
    color: ${(props): string => props.theme.palette.neutral800.main};
    & .lzr-color-purple{
        color: ${(props): number => props.theme.palette.accentSecondary.main};
    }
`

export const StyledParagraphTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.25rem;
    font-weight: 400;
    color: ${(props): string => props.theme.palette.neutral800.main};
    line-height: 26px;
`

export const StyledListItemTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.125rem;
    color: ${(props): number => props.theme.palette.neutral800.main};
    line-height: 135%;
`

export const StyledListItem: React.FC = styled.div`
    margin-left: ${(props): number => props.theme.spacing(4)}px;
    margin-right: ${(props): number => props.theme.spacing(3)}px;
`

export const StyledStatContainer = styled(MuiBox)`
     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]{
        padding: ${(props): number => props.theme.spacing(4)}px;
        border: 1px solid ${(props): number => props.theme.palette.neutral200.main};
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:nth-child(1){
        border-top-left-radius: 4px;
        border-bottom: none;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:nth-child(2){
        border-top-right-radius: 4px;
        border-bottom: none;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:nth-child(3){
        border-bottom-left-radius: 4px;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:nth-child(4){
        border-bottom-right-radius: 4px;
     }

     & [class*="MuiGrid-container"] [class*="MuiGrid-item"]:nth-child(odd){
        border-right: none;
     }
`

export const StyledStatBox = styled(MuiBox)`
    height: 138px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const StyledStatTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 0.875rem;
    color: ${(props): number => props.theme.palette.neutral800.main};
    line-height: 140%;
`

export const StyledH1Typography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    color: ${(props): number => props.theme.palette.primary.main};
`
