/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TariffType {
    CLIENT = 'CLIENT',
    '3PL' = '3PL',
    LAZR = 'LAZR',
    CLIENT_TARIFF_NO_TRANSPORT_COST = 'CLIENT_TARIFF_NO_TRANSPORT_COST',
    COLLECT = 'COLLECT',
}