import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import styled from 'styled-components'
import { Props } from '@/app/ui-new/components/ToggleButton/ToggleButton'
import { transparentize } from 'polished'

const getLabelPadding = (size: string | undefined) => {
    switch (size) {
        case 'large':
            return 0.94
        case 'small':
            return 0.63
        case 'medium':
        default:
            return 0.75
    }
}

export const StyledToggleButton: React.FC<Props> = styled(ToggleButton).withConfig({
    shouldForwardProp: (prop) => ![ 'withSelectedIcon' ].includes(prop),
})<Props>`
    border-radius: 40px;
    border-color: transparent;
    color: ${(props): number => props.theme.palette.neutral700.main};

    &[class*='MuiButtonBase-root'] + [class*='MuiButtonBase-root']{
        border-left: 1px solid ${(props): number => props.theme.palette.neutral300.main};
    }
    
    &:hover,
    &.Mui-selected:hover{
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.accentPrimary300.main as string)};
    }

    &.Mui-selected{
        background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main as string)};
        color: ${(props): number => props.theme.palette.black.main};

        & [class*="MuiToggleButton-label"] svg{
           margin-right: ${(props): number => (props.withSelectedIcon && props.selected) ? 0.375 : 0}rem;
        }
    }

    & [class*="MuiToggleButton-label"]{
       padding-right: ${(props): number => (props.withSelectedIcon && !props.selected) ? getLabelPadding(props.size) : 0}rem;
       padding-left: ${(props): number => (props.withSelectedIcon && !props.selected) ? getLabelPadding(props.size) : 0}rem;
    }

    &.Mui-selected + &.Mui-selected {
        border-left: 1px solid transparent;
        margin-left: -1px;
    }
`
