import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import {
    Box as MuiBox,
    Button as MuiButton,
    Paper,
} from '@material-ui/core'
import { Alert as MuiAlert } from '@material-ui/lab'

export const Button = styled(MuiButton)(spacing)

export const Box = styled(MuiBox)(spacing)

export const Alert = styled(MuiAlert)(spacing)

export const IconContainer = styled(MuiBox)`
    font-size: 15rem;
    text-align: center;
`

export const Wrapper = styled(Paper)`
    margin-top:  0;
    padding: ${(props): number => props.theme.spacing(6)}px;

    ${(props): string => props.theme.breakpoints.up('md')} {
        padding: ${(props): number => props.theme.spacing(10)}px;
    }
`
export const SuccessWrapper = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:  0;
    padding: ${(props): number => props.theme.spacing(6)}px;

    ${(props): string => props.theme.breakpoints.up('md')} {
        padding: ${(props): number => props.theme.spacing(10)}px;
    }
`
