/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'

import { Strong } from '../../../AppLayout.styled'

export default ((): Translations<typeof en> => ({
    ['New Version Available']: 'Nouvelle version disponible',
    ['Reload Lazr at your convenience for the latest version.']: 'Rechargez Lazr à votre convenance pour la dernière version.',
    ['Reload']: 'Recharger',
    Attention: 'Attention',
    message: () => (
        <>
            <Strong>Lazr version classique</Strong> ne sera plus pris en charge à partir du 1er novembre. Essayez le nouveau Lazr dès
            aujourd'hui avec plus de fonctionnalités et d'outils!
        </>
    ),
}))()
