/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import React from 'react'
import en from './index.i18n.en'
import { Translations } from '@lazr/i18n'
import { StyledStrong } from './index.styled'

export default ((): Translations<typeof en> => ({
    [`Your profile is incomplete`]: `Votre profil est incomplet`,
    [`Please visit and complete your profile to dismiss this message. You must provide your first name, and last name.`]: () => <>Veuillez compléter votre profil pour éliminer ce message. Vous devez fournir votre <StyledStrong>prénom</StyledStrong> et <StyledStrong>nom de famille</StyledStrong>.</>,
    [`Go to Profile`]: `Aller au profil`,
}))()
