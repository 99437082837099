/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrganizationStatus {
    APPROVED = 'APPROVED',
    INACTIVE = 'INACTIVE',
    LIMITED = 'LIMITED',
    EVALUATING = 'EVALUATING',
    BLOCKED = 'BLOCKED',
}