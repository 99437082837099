import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { MarketPlaceStep } from '@/app/ui-new/definitions/MarketPlace'
import { resetMarketPlace as reduxResetMarketPlace } from '@/app/ui-new/redux/actions/MarketPlaceActions'
import { getMarketPlaceStepPath } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'

type marketplaceNavigator = {
    resetMarketplaceRoute: () => void
    resetMarketplace: () => void
    cleanupMarketplace: () => void
}

export const useMarketplaceNavigator = (): marketplaceNavigator => {
    const history = useHistory()
    const dispatch = useDispatch()

    const resetMarketplaceRoute = (): void => {
        history.push(getMarketPlaceStepPath(MarketPlaceStep.LOAD_AND_TERMS))
        localStorage.removeItem('newMarketPlaceOrderId')
        localStorage.removeItem('newMarketPlaceStep')
    }

    const resetMarketplace = (): void => {
        dispatch(reduxResetMarketPlace())
        resetMarketplaceRoute()
    }

    const cleanupMarketplace = () => {
        dispatch(reduxResetMarketPlace())
        history.push(getMarketPlaceStepPath(MarketPlaceStep.COVERAGE))
    }

    return { resetMarketplaceRoute, resetMarketplace, cleanupMarketplace }
}
