import styled from 'styled-components'
import MuiPopper from '@material-ui/core/Popper'
import { Box as MuiBox } from '@material-ui/core'
import { lighten } from 'polished'

export const IconContainer = styled(MuiBox)`
    color: ${(props): string => props.theme.palette.text.disabled};
    font-size: 1.5rem;
    margin-right: ${(props): number => props.theme.spacing(4)}px;
`

export const PastedAddressMessagePopper = styled(MuiPopper)`
    .MuiAutocomplete-noOptions {
        color: ${(props): string => props.theme.palette.warning.contrastText};
        background-color: ${(props): string => lighten(0.25, props.theme.palette.warning.main)};
    }
`
