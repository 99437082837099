import styled from 'styled-components'
import BaseCarrierLogo from '../../BaseCarrierLogo'

export const StyledCarrierLogo = styled(BaseCarrierLogo)`
    div {
        width: ${(props): number => props.size === 'wide-large' ? 65 : 85}%;
    }
    svg {
        width: 100% !important;
        height: 100%;
        color: rgba(0,0,0,0.26);
    }
`
