/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { AnyAction, Reducer } from 'redux'
import * as types from '../constants'
import { initialPendingPriceReview } from '../store/InitialState'
import { InvoiceCsvBatchCharge } from '@/app/model'
import { PendingPriceReview } from '@/app/ui/definitions/PendingPriceReview'

const reducer: Reducer = (state: PendingPriceReview = initialPendingPriceReview, action: AnyAction): PendingPriceReview  => {
    switch (action.type) {
        case types.SET_PENDING_PRICE_REVIEW_COST_CHARGE: {
            const newQuoteCostCharges = state.pendingPriceReviewCostCharges
            newQuoteCostCharges[action.payload.index] = action.payload.quoteCostCharge

            return {
                ...state,
                pendingPriceReviewCostCharges: newQuoteCostCharges,
                pendingPriceReviewTotalCadPrice: newQuoteCostCharges.reduce(
                    (total, price) => total + (price.totalCad || 0), 0,
                ),
                pendingPriceReviewTotalUsdPrice: newQuoteCostCharges.reduce(
                    (total, price) => total + (price.totalUsd || 0), 0,
                ),
                pendingPriceReviewTotalCadCost: newQuoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawCad || 0), 0,
                ),
                pendingPriceReviewTotalUsdCost: newQuoteCostCharges.reduce(
                    (total, cost) => total + (cost.rawUsd || 0), 0,
                ),
            }
        }
        case types.ADD_PENDING_PRICE_REVIEW_COST_CHARGE: {
            const newOrderDetailsPendingPriceReviewErrors = state.pendingPriceReviewErrors
            newOrderDetailsPendingPriceReviewErrors.chargesErrors.push({})

            const newQuoteCostCharges = state.pendingPriceReviewCostCharges
            newQuoteCostCharges.push(action.payload)

            return {
                ...state,
                pendingPriceReviewCostCharges: newQuoteCostCharges,
                pendingPriceReviewErrors: newOrderDetailsPendingPriceReviewErrors,
            }
        }
        case types.DELETE_PENDING_PRICE_REVIEW_COST_CHARGE: {
            const newQuoteCostCharges = state.pendingPriceReviewCostCharges
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            newQuoteCostCharges.splice(action.payload, 1)

            return {
                ...state,
                pendingPriceReviewCostCharges: newQuoteCostCharges,
                pendingPriceReviewTotalCadPrice: newQuoteCostCharges.reduce(
                    (total, price) =>
                        total + (price.totalCad || 0),
                    0,
                ),
                pendingPriceReviewTotalUsdPrice: newQuoteCostCharges.reduce(
                    (total, price) =>
                        total + (price.totalUsd || 0),
                    0,
                ),
                pendingPriceReviewTotalCadCost: newQuoteCostCharges.reduce(
                    (total, cost) =>
                        total + (cost.rawCad || 0),
                    0,
                ),
                pendingPriceReviewTotalUsdCost: newQuoteCostCharges.reduce(
                    (total, cost) =>
                        total + (cost.rawUsd || 0),
                    0,
                ),
            }
        }
        case types.SET_PENDING_PRICE_REVIEW_COST_CHARGES: {
            const pendingPriceReviewCostCharges: InvoiceCsvBatchCharge[] = action.payload.costCharges

            return {
                ...state,
                pendingPriceReviewCostCharges: pendingPriceReviewCostCharges,
                pendingPriceReviewTotalCadPrice: pendingPriceReviewCostCharges.reduce(
                    (total, price) => total + (price.totalCad || 0), 0,
                ),
                pendingPriceReviewTotalUsdPrice: pendingPriceReviewCostCharges.reduce(
                    (total, price) => total + (price.totalUsd || 0), 0,
                ),
                pendingPriceReviewTotalCadCost: pendingPriceReviewCostCharges.reduce(
                    (total, cost) => total + (cost.rawCad || 0), 0,
                ),
                pendingPriceReviewTotalUsdCost: pendingPriceReviewCostCharges.reduce(
                    (total, cost) => total + (cost.rawUsd || 0), 0,
                ),
            }
        }
        case types.SET_PENDING_PRICE_REVIEW_CURRENCY: {
            return {
                ...state,
                pendingPriceReviewCurrency: action.payload.pendingPriceReviewCurrency,
            }
        }
        default:
            return state
    }
}
export default reducer
