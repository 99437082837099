import React from 'react'
import { IconButton, IconButtonProps } from '@material-ui/core'
import {
    TextFieldIconEye,
    TextFieldIconEyeSlash,
} from './TextFieldIcons'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './PasswordVisibilityButton.i18n'

export const PasswordVisibilityButton: React.FC<PasswordVisibilityButtonProps> = (props) => {
    const { t } = useI18n(i18n)

    return <IconButton
        aria-label={t('toggle password visibility')}
        onClick={props.onClick}
        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>): void => event.preventDefault()}
        disabled={props.disabled}
        size={props.size}
    >
        {props.passwordVisible ? <TextFieldIconEye /> : <TextFieldIconEyeSlash />}
    </IconButton>
}

export interface PasswordVisibilityButtonProps extends IconButtonProps{
    passwordVisible: boolean
}
