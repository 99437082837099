import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { ImportExportType } from '../../model'
import { handleUnauthorizedException } from './index'

export const ImportExportTypeApiService = Object.freeze({
    list: async (): Promise<ImportExportType[]> => {
        let getImportExportTypesResponse
        try {
            getImportExportTypesResponse = await EnumTypesService.getImportExportTypes()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve import export type list')
            }
            throw new Error('unable to retrieve import export type list')
        }

        if (!getImportExportTypesResponse.data) {
            logger.debug('list',  JSON.stringify(getImportExportTypesResponse, null, 4))
            throw new Error('unable to retrieve import export type list')
        }

        return getImportExportTypesResponse.data
    },
})
