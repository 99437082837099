/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptOrderRequest } from '../models/AcceptOrderRequest';
import type { CancelOrderRequest } from '../models/CancelOrderRequest';
import type { RejectOrderRequest } from '../models/RejectOrderRequest';
import type { SubmitOrderDraftRequest } from '../models/SubmitOrderDraftRequest';
import type { SuccessResponse_AcceptOrderResponse_ } from '../models/SuccessResponse_AcceptOrderResponse_';
import type { SuccessResponse_CancelOrderResponse_ } from '../models/SuccessResponse_CancelOrderResponse_';
import type { SuccessResponse_RejectOrderResponse_ } from '../models/SuccessResponse_RejectOrderResponse_';
import type { SuccessResponse_SubmitOrderDraftResponse_ } from '../models/SuccessResponse_SubmitOrderDraftResponse_';
import { request as __request } from '../core/request';

export class OrderStatusChangeClientService {

    /**
     * Accept an order's latest revisions (for coordinators / client users)
     * @param requestBody The data
     * @returns SuccessResponse_AcceptOrderResponse_ OK
     * @throws ApiError
     */
    public static async acceptOrder(
        requestBody: AcceptOrderRequest,
    ): Promise<SuccessResponse_AcceptOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/accept-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Cancel a draft order or revised order (for coordinators / client users)
     * @param requestBody The data
     * @returns SuccessResponse_CancelOrderResponse_ OK
     * @throws ApiError
     */
    public static async cancelOrder(
        requestBody: CancelOrderRequest,
    ): Promise<SuccessResponse_CancelOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/cancel-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Reject an order's latest revisions (for coordinators / client users)
     * @param requestBody The data
     * @returns SuccessResponse_RejectOrderResponse_ OK
     * @throws ApiError
     */
    public static async rejectOrder(
        requestBody: RejectOrderRequest,
    ): Promise<SuccessResponse_RejectOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/reject-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Submit an order draft to a 3PL
     * @param requestBody The data
     * @returns SuccessResponse_SubmitOrderDraftResponse_ OK
     * @throws ApiError
     */
    public static async submitOrderDraft(
        requestBody: SubmitOrderDraftRequest,
    ): Promise<SuccessResponse_SubmitOrderDraftResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/submit-order-draft`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}