import { Store } from '../../definitions/Store'
import {
    AddressInformationErrors,
    OrderDetails,
    OrderSummaryErrors,
    OrderBillingErrors,
    QuoteErrors,
} from '../../definitions/OrderDetails'
import { OrderInfo, QuoteCostCharge, SearchRfqCustomsDetail } from '@/app/model'
import { AccessorialLocationType } from '@lazr/openapi-client'
import { Charge } from '../../pages/orders/Details/Sections/components/CostCharges'
import { TransportInvoiceDocument } from '../../pages/orders/Details/Sections/Documents/components/hooks/DocumentUpload'
import { CompleteCustomsOrderStepErrors } from '@/app/ui/definitions/MarketPlace'

export const getOrderDetails = (store: Store): OrderDetails => store.orderDetails

export const getQuoteErrors = (store: Store): QuoteErrors => {
    const marketPlaceErrors = getOrderDetails(store)

    return {
        validUntil: marketPlaceErrors.orderDetailsMarketplaceErrors.quoteErrors.validUntil,
    }
}
export const getChargesErrors = (store: Store): Partial<Record<keyof QuoteCostCharge, string>>[] =>
    getOrderDetails(store).orderDetailsMarketplaceErrors.chargesErrors

export const getOrderDetailsErrors = (store: Store): Partial<Record<keyof OrderDetails, string>> =>
    getOrderDetails(store).orderDetailsMarketplaceErrors.orderDetailsErrors

export const getOrder = (store: Store): OrderInfo | null => getOrderDetails(store).order

export const getEditableOrder = (store: Store): Partial<OrderInfo> => getOrderDetails(store).editableOrder

export const getOrderSummaryErrors = (store: Store): OrderSummaryErrors => getOrderDetails(store).orderSummaryErrors

export const getOrderBillingErrors = (store: Store): OrderBillingErrors => getOrderDetails(store).orderBillingErrors

export const getAddressInformationErrors = (store: Store, locationType: AccessorialLocationType): AddressInformationErrors => {
    const orderSummaryErrors = getOrderSummaryErrors(store)

    switch (locationType) {
        case AccessorialLocationType.ORIGIN:
            return {
                companyName: orderSummaryErrors.errors.originCompanyName,
                streetAddress: orderSummaryErrors.errors.originStreetAddress,
                city: orderSummaryErrors.errors.originCity,
                state: orderSummaryErrors.errors.originState,
                postalCode: orderSummaryErrors.errors.originPostalCode,
                country: orderSummaryErrors.errors.originCountry,
                pickupDate: orderSummaryErrors.errors.originPickupDate,
                contactName: orderSummaryErrors.errors.originContactName,
                contactEmails: orderSummaryErrors.errors.originContactEmails,
                contactPhone: orderSummaryErrors.errors.originContactPhone,
                openTime: orderSummaryErrors.errors.originPickupOpenTime,
                closeTime: orderSummaryErrors.errors.originPickupCloseTime,
            }

        case AccessorialLocationType.DESTINATION:
            return {
                companyName: orderSummaryErrors.errors.destinationCompanyName,
                streetAddress: orderSummaryErrors.errors.destinationStreetAddress,
                city: orderSummaryErrors.errors.destinationCity,
                state: orderSummaryErrors.errors.destinationState,
                postalCode: orderSummaryErrors.errors.destinationPostalCode,
                country: orderSummaryErrors.errors.destinationCountry,
                pickupDate: orderSummaryErrors.errors.destinationPickupDate,
                contactName: orderSummaryErrors.errors.destinationContactName,
                contactEmails: orderSummaryErrors.errors.destinationContactEmails,
                contactPhone: orderSummaryErrors.errors.destinationContactPhone,
                openTime: orderSummaryErrors.errors.destinationPickupOpenTime,
                closeTime: orderSummaryErrors.errors.destinationPickupCloseTime,
            }

        default:
            return {}
    }
}

export const getOrderCloseIntervals = (store: Store): number[] => getOrderDetails(store).orderCloseIntervals

export const getOrderDocuments = (store: Store): any => getOrder(store)?.documents

export const getCharges = (store: Store): Charge[] => getOrderDetails(store).charges

export const getSelectedTransportInvoiceDocuments
    = (store: Store): TransportInvoiceDocument[] => getOrderDetails(store).selectedTransportInvoiceDocuments

export const getCustomsInfoOrderSummaryErrors = (store: Store): Partial<Record<keyof CompleteCustomsOrderStepErrors, string>> =>
    getOrderSummaryErrors(store).customsInfoErrors

export const getCustomsDetailOrderSummaryErrors = (store: Store): Partial<Record<keyof SearchRfqCustomsDetail, string>>[] =>
    getOrderSummaryErrors(store).customsDetailErrors

