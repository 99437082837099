import styled from 'styled-components'
import { Typography as MuiTypography } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import { AlertBanner, AlertBannerContent } from '@/app/ui-new/components/AlertBanner/AlertBanner'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { Snackbar } from '@/app/ui-new/components/Snackbar/Snackbar'
export const StyledSnackbar = styled(Snackbar)`
    position: unset
`
export const StyledTypography = styled(styled(MuiTypography)(MuiSpacing))``
export const StyledMobileTypography = styled(styled(MuiTypography)(MuiSpacing))`
    margin-bottom: ${(props): string => `${props.theme.spacing(2)}px`};
`
export const StyledAlertBanner = styled(AlertBanner)`
`

export const StyledAlertBannerContent = styled(AlertBannerContent)`
`

export const StyledPrimaryButton = styled(PrimaryButton)`
    white-space: nowrap;
`
export const StyledSecondaryButton = styled(SecondaryButton)`
    white-space: nowrap;
`
