import {
    ApiError,
    ErrorResponse, RequestDemoService,
} from '@lazr/openapi-client'

export const RequestDemoApiService = Object.freeze({
    create: async (
        email: string,
        firstName: string,
        lastName: string,
        phone: string,
        company: string,
        preferredLanguage: string,
        industry: string,
        volumeOfShipments: string,
        manufacturerType: string,
        wholesaleType: string,
        transportationAndWarehousingType: string,
        retailType: string,
        transportationServicesUsed: string,
        consent: string,
    ): Promise<void | ErrorResponse['error']> => {
        const reqBody = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            company: company,
            preferredLanguage: preferredLanguage,
            industry: industry,
            volumeOfShipments: volumeOfShipments,
            manufacturerType: manufacturerType,
            wholesaleType: wholesaleType,
            transportationAndWarehousingType: transportationAndWarehousingType,
            retailType: retailType,
            transportationServicesUsed: transportationServicesUsed,
            consent: consent,
        }

        try {
            await RequestDemoService.createRequestDemo(reqBody)
        } catch (error: any) {
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse

                return errorResponse.error
            }

            return { message: 'Unable to request a demo' }
        }
    },
})
