/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateStatementOfAccountResponse = {
    content: string;
    contentType: GenerateStatementOfAccountResponse.contentType;
    name: string;
}

export namespace GenerateStatementOfAccountResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}
