import React from 'react'
import { Grid } from '@material-ui/core'
import { StyledPageTitleTypography } from './PageTitle.styled'

const PageTitle: React.FunctionComponent<Props> = ({
  title,
  size,
  nameID
}) => (
  <Grid container spacing={6}>
    <Grid item xs={12}>
      <StyledPageTitleTypography variant={size ? size : 'h1' } id={`${nameID}`}>
        {title}
      </StyledPageTitleTypography>
    </Grid>
  </Grid>
)

export interface Props {
  title: string | React.ReactElement
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | undefined
  nameID?: string
}
export default PageTitle
