import { UuidV4 } from '@lazr/openapi-client'

export interface OtherUserProps {
    id: UuidV4
    email: string
    firstName: string | null
    middleName: string | null
    lastName: string | null
    organizationId: UuidV4
    organizationName?: string
    shortFullName?: string | null
    longFullName?: string | null
}

export class OtherUser {
    public id: UuidV4
    public firstName: string | null
    public email: string
    public middleName: string | null
    public lastName: string | null
    public organizationId: UuidV4
    public organizationName?: string
    private internalLongFullName?: string
    private internalShortFullName?: string

    constructor (props: OtherUserProps) {
        this.id = props.id
        this.email = props.email
        this.firstName = props.firstName
        this.middleName = props.middleName
        this.lastName = props.lastName
        this.organizationId = props.organizationId
        this.organizationName = props.organizationName

        if (props.longFullName) {
            this.longFullName = props.longFullName
        }
        if (props.shortFullName) {
            this.shortFullName = props.shortFullName
        }
    }

    get longFullName (): string {
        if (!this.internalLongFullName) {
            this.internalLongFullName = this.getLongFullName()
        }

        return this.internalLongFullName
    }

    set longFullName (longFullName: string) {
        this.internalLongFullName = longFullName
    }

    private getLongFullName = (): string => {
        const nameParts = []

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.middleName) {
            nameParts.push(this.middleName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }

    get shortFullName (): string {
        if (!this.internalShortFullName) {
            this.internalShortFullName = this.getShortFullName()
        }

        return this.internalShortFullName
    }

    set shortFullName (shortFullName: string) {
        this.internalShortFullName = shortFullName
    }

    private getShortFullName = (): string => {
        const nameParts = []

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }
}
