import styled from 'styled-components'

import {
    Card as MuiCard,
    CardHeader as MuiCardHeader,
    CardContent as MuiCardContent,
    Button as MuiButton,
    Grid as MuiGrid,
    Divider as MuiDivider,
    Typography as MuiTypography,
    IconButton as MuiIconButton,
    TextField as MuiTextField,
} from '@material-ui/core'

import {
    spacing as MuiSpacing,
} from '@material-ui/system'

import { FontAwesomeIcon as MuiFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { darken, transparentize } from 'polished'
import {
    BG_GRAY_DARK_AMOUNT,
    BUTTON_HOVER_DARKEN_AMOUNT,
    BUTTON_HOVER_TRANSPARENTIZE_AMOUNT,
} from '../../../lib/constants'
import OriginalProgressSummaryInformation from './ProgressSummaryInformation'
import RawIconLogo from '../../../components/IconLogo'
import OriginalAddressValidation from '../../components/AddressValidation'

export const ModalTypography = styled(MuiTypography)(MuiSpacing)

export const CardContent = styled(styled(MuiCardContent)(MuiSpacing))`
    &:last-child {
        padding-bottom: ${(props): number => props.theme.spacing(4)}px;
    }
`

export const AddressValidation = styled(OriginalAddressValidation)(MuiSpacing)

export const CardHeader = styled(MuiCardHeader)(MuiSpacing)

export const Card = styled(styled(MuiCard)(MuiSpacing))`
    border-top: 1px solid ${(props): string => props.theme.palette.divider};
    box-shadow: none;
    border-radius: 0;
`

export const StickyContainer = styled.div`
    position: sticky;
    bottom: 0;
    z-index: 1;
`

export const ProgressSummaryInformation = styled(OriginalProgressSummaryInformation)`
    ${(props): string => props.theme.breakpoints.up('sm')} {
        min-width: 500px;
    }
`

export const Button = styled(MuiButton)`
    border-radius: 6px;
`

export const Grid = styled(styled(MuiGrid)(MuiSpacing))`
`

export const LeftSideGrid = styled(MuiGrid)`
  ${(props): string => props.theme.breakpoints.up('lg')} {
    max-width: 150px;
  }
  ${(props): string => props.theme.breakpoints.only('md')} {
    max-width: 125px;
  }

  ${(props): string => props.theme.breakpoints.only('xs')} {
    max-width: 116px;
  }
`

export const BackButton = styled(Button)`
    background-color: ${(props): number => props.theme.palette.accent1.main};
    color: ${(props): number => props.theme.palette.accent1.contrastText};
    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.accent1.main)};
    }
    ${(props): string => props.theme.breakpoints.up('sm')} {
        width: 116px;
    }
    &.visible {
        visibility: visible;
    }
    &.hidden {
        visibility: hidden;
    }
`

export const RightSideGrid = styled(MuiGrid)`
    ${(props): string => props.theme.breakpoints.up('sm')} {
       max-width: 206px;
    }
    ${(props): string => props.theme.breakpoints.only('xs')} {
       max-width: 202px;
    }
`

export const RightSideButton = styled(Button)`
    ${(props): string => props.theme.breakpoints.up('sm')} {
       width: 198px;
    }
    ${(props): string => props.theme.breakpoints.only('xs')} {
        width: 196px;
        padding-right: ${(props): number => props.theme.spacing(1)}px;
        padding-left: ${(props): number => props.theme.spacing(1)}px;
    }
`

export const DefaultButton = styled(RightSideButton)`
    background-color: ${(props): number => props.theme.palette.accent1.main};
    color: ${(props): number => props.theme.palette.accent1.contrastText};
    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.accent1.main)};
    }
    white-space: nowrap;
`

export const SaveAsDraftButton = styled(RightSideButton)`
    white-space: nowrap;
`

export const WarningButton = styled(RightSideButton)`
    background-color: ${(props): number => props.theme.palette.warning.main};
    &:hover{
        background-color: ${(props): string => darken(BUTTON_HOVER_DARKEN_AMOUNT, props.theme.palette.warning.main)};
    }
    color: ${(props): number => props.theme.palette.warning.contrastText};
`

export const Divider = styled(MuiDivider)(MuiSpacing)

export const ArrowIcon = styled(MuiFontAwesomeIcon)`
    width: 1.715rem !important;
    height: 1.715rem;
    cursor: pointer;
    border-radius: 50%;
    z-index: 2;
    border: 1px solid ${(props): string => props.theme.palette.divider};
    background-color: ${(props): string => props.theme.palette.accent1.contrastText};
    color: ${(props): string => props.theme.palette.text.disabled};
    transition: ${(props): string => props.theme.transitions.create('transform', {
        duration: props.theme.transitions.duration.shortest,
    })};
`

export const ArrowIconContainer = styled(MuiIconButton)<{ $configuration: 'oneButton' | 'twoButton' }>`
    z-index: 1;
    position: absolute;
    left: calc(50% - 24px);
    ${(props): string => props.theme.breakpoints.up('md')} {
        bottom: 121px;
    }
    ${(props): string => props.theme.breakpoints.between('xs', 'sm')} {
        bottom: ${(props): string => props.$configuration === 'oneButton' ? '79' : '121'}px;
    }
`

export const StepperGrid = styled(MuiGrid)`
    padding: 0 !important;
`

export const ScrollBar = styled.div`
    ${(props): string => props.theme.breakpoints.only('xs')} and (max-height: 600px) {
        max-height: calc(100vh - 240px);
        overflow: hidden scroll;
    }
    ${(props): string => props.theme.breakpoints.only('sm')} and (max-height: 600px) {
        max-height: calc(100vh - 256px);
        overflow: hidden scroll;
    }
`

export const StyledIcon = styled(MuiFontAwesomeIcon)`
    color: ${(props): string => props.theme.palette.text.disabled};
`

export const ResetOrderLabel = styled(MuiTypography)`
    padding-top: ${(props): string => props.theme.spacing(0.5)}px;
    color: ${(props): string => props.theme.palette.text.secondary};
`

export const ResetOrderButton = styled(MuiButton)`
    background-color: ${(props): string => darken(BG_GRAY_DARK_AMOUNT, props.theme.palette.background.paper)};
    &:hover .MuiTypography-root, &:hover svg {
        color: ${(props): string => props.theme.palette.error.main};
    }
    &:hover {
        background-color: ${(props): string => transparentize(BUTTON_HOVER_TRANSPARENTIZE_AMOUNT, props.theme.palette.error.main)};
    }
`

export const TransitDaysTooltipIcon = styled(MuiFontAwesomeIcon)`
    color: ${(props): string => props.theme.palette.accent1.main};
`

export const FontAwesomeIcon = styled(styled(MuiFontAwesomeIcon)(MuiSpacing))`
    &.large {
        font-size: 1.5rem;
    }
`

export const IconLogo = styled(RawIconLogo)`
    width: 24px;
    height: 24px;
    fill: ${(props): string => props.theme.palette.accent1.contrastText};
`

export const TextField = styled(MuiTextField)`
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`
