import { Language as LanguageCode } from '@lazr/openapi-client'

export interface LanguageProps {
    code: LanguageCode
    name: string
}

export class Language {
    public code: LanguageCode
    public name: string

    constructor (props: LanguageProps) {
        this.code = props.code
        this.name = props.name
    }
}
