import * as types from '../constants'
import { BillToAddress, Lane, OtherOrganization, User } from '@/app/model'
import { UserFilter } from '../../definitions/UserList'
import { Currency } from '@lazr/enums'

export const setUser = (value: User | null): types.Action => ({
    type: types.SET_USER,
    payload: value,
})

export const setUserIsSignedIn = (value: boolean): types.Action => ({
    type: types.SET_USER_IS_SIGNED_IN,
    payload: value,
})

export const setUserFilters = (value: Partial<UserFilter> | null): types.Action => ({
    type: types.SET_USER_FILTER,
    payload: value,
})

export const setUserListOrganizationFiltering = (organizationList: OtherOrganization[]): types.Action => ({
    type: types.SET_USER_LIST_ORGANIZATION_LIST,
    payload: {
        organizationList,
    },
})
export const setDefaultUserCurrency = (currency: Currency): types.Action => ({
    type: types.SET_DEFAULT_USER_CURRENCY,
    payload: currency,
})

export const setLanes = (lanes: Lane[]): types.Action => ({
    type: types.SET_LANES,
    payload: lanes,
})

export const setUserBillToAddresses = (billToAddresses: BillToAddress[]): types.Action => ({
    type: types.SET_USER_BILL_TO_ADDRESSES,
    payload: billToAddresses,
})

