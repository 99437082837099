/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './Alert.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    [`Alert`]: `Alerte`,
    [`An Alert title...`]: `Titre pour alerte...`,
    [`An Alert message...`]: `Message pour alerte...`,
    [`Close`]: `Fermer`,
}))()
