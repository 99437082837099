import React from 'react'
import { Helmet } from 'react-helmet'

import { GOOGLE_API_URL, SALESFORCE_CRM_SCRIPT_URL } from '../ui/redux/constants'
import { env, Environment } from '../config'
import { homeRoute } from '@/app/ui/routes'

const SEO: React.FC<Props> = (props) => {
    const isProduction = env === Environment.PRODUCTION

    const isHomePage = window.location.pathname === homeRoute.path

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.pageTitle}{!isHomePage ? ' – Lazr' : ''}</title>
            {props.pageDescription && <meta name="description" content={props.pageDescription}/>}
            {props.pageCanonical && <link rel="canonical" href={props.pageCanonical}/>}
            {!isProduction && <meta name="robots" content="noindex,nofollow"/>}
            {props.pageMeta && <meta name={props.pageMeta.name} content={props.pageMeta.content}/>}

            {/* TODO: Remove this load since it's only used by App */}
            {/*<script src={GOOGLE_API_URL}/>*/}
            {/*isProduction && <script src={SALESFORCE_CRM_SCRIPT_URL}/>*/}
        </Helmet>
    )
}

export default SEO

export interface Props {
    pageTitle: string
    pageDescription?: string
    pageCanonical?: string
    pageMeta?: {name: string, content: string}
}