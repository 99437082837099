import React from 'react'
import {
    Container,
    Grid,
    Title,
    SubTitle,
    AdvantagesContainer,
    Advantages,
    AdvantageTitle,
    AdvantageSubTitle,
} from './NewEfficiencyZeroFrictionDesktop.styled'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
// import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './NewEfficiencyZeroFrictionDesktop.i18n'

export const NewEfficiencyZeroFrictionDesktop: React.FunctionComponent<NewEfficiencyZeroFrictionProps> = (props) => {
    const { t } = useI18n(i18n)

    return <Container>

        <Grid container>
            <Grid item xs={9}>
                <Title mb={16}>
                    {t('New efficiencies.')}<br/>{t('Zero friction.')}
                </Title>
                <SubTitle mb={25}>
                    {/*eslint-disable-next-line max-len*/}
                    {t('Power-up your operations and be the office hero. Our free shipping software is instantly deployable company-wide and designed for seamless collaboration with your colleagues.')}
                </SubTitle>
            </Grid>

            <Grid item xs={3}>
                <img alt='Lazr meditation' src='/images/website/Lazr-meditation.png'/>
            </Grid>
        </Grid>

        <AdvantagesContainer>
            <Advantages>
                <AdvantageTitle mb={11}>
                    {t('Customizable marketplace')}
                </AdvantageTitle>
                <AdvantageSubTitle>
                    {/*eslint-disable-next-line max-len*/}
                    {t('Already have carrier contracts in place? Add them into your personal search results to instantly compare your own contracts with our quotes.')}
                </AdvantageSubTitle>
            </Advantages>
            <Advantages>
                <AdvantageTitle mb={11}>
                    {t('Modernize your operations')}
                </AdvantageTitle>
                <AdvantageSubTitle>
                    {/*eslint-disable-next-line max-len*/}
                    {t('Bring your legacy systems up to speed with easy API integrations and EDI capabilities to make logistics communications more efficient than ever.')}
                </AdvantageSubTitle>
            </Advantages>
            <Advantages>
                <AdvantageTitle mb={11}>
                    {t('Built for the entire team')}
                </AdvantageTitle>
                <AdvantageSubTitle>
                    {/*eslint-disable-next-line max-len*/}
                    {t('With enterprise-grade features, Lazr is purpose-built for collaboration, performance, and flexible monitoring. Anyone on your team can track, manage, and analyze shipments with ease.')}
                </AdvantageSubTitle>
            </Advantages>
            <Advantages>
                <AdvantageTitle mb={11}>
                    {t('Free to use—forever')}
                </AdvantageTitle>
                <AdvantageSubTitle>
                    {t('Pay no extra fees. Just create a free account and start shopping for quotes today.')}
                </AdvantageSubTitle>
            </Advantages>
        </AdvantagesContainer>

        <Grid container>
            <Grid item xs={6}>
                <PrimaryButton size='large' href='/register'>{t('Get Lazr now')}</PrimaryButton>
                {/* <SecondaryButton size='large' href='/'>{t('Visit our marketplace')}</SecondaryButton> */}
            </Grid>
        </Grid>
    </Container>
}

interface NewEfficiencyZeroFrictionProps{

}
