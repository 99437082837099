import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { Address } from '../../../model'
import { DefaultAddresses } from '@/app/ui/definitions/DefaultAddresses'
import { initialDefaultAddressListState } from '@/app/ui/redux/store/InitialState'

const reducer: Reducer = (previousAddressListState: DefaultAddresses = initialDefaultAddressListState,
    action: AnyAction) : DefaultAddresses => ({
    billingAddressList: billingAddressList(previousAddressListState, action),
    shippingAddressList: shippingAddressList(previousAddressListState, action),
})

const billingAddressList = (previousAddressListState: DefaultAddresses, action: AnyAction): Address[] => {
    switch (action.type) {
        case types.SET_DEFAULT_BILLING_ADDRESSES:
            return action.payload
        default:
            return previousAddressListState.billingAddressList
    }
}

const shippingAddressList = (previousAddressListState: DefaultAddresses, action: AnyAction): Address[] => {
    switch (action.type) {
        case types.SET_DEFAULT_SHIPPING_ADDRESSES:
            return action.payload
        default:
            return previousAddressListState.shippingAddressList
    }
}

export default reducer
