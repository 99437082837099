import React from 'react'
import { StyledToggleButton } from '@/app/ui-new/components/ToggleButton/ToggleButton.styled'
import DoneIcon from '@material-ui/icons/Done'
import { ToggleButtonProps } from '@material-ui/lab/ToggleButton/ToggleButton'

export interface Props extends ToggleButtonProps {
    withSelectedIcon?: boolean
}

const ToggleButton: React.FunctionComponent<Props> = (props: Props) => (
    <StyledToggleButton {...props}>
        {(props.withSelectedIcon && props.selected) ? <DoneIcon /> : null}
        {props.children}
    </StyledToggleButton>
)

export default ToggleButton
