import React from 'react'
import { Box, BoxProps } from '@material-ui/core'
import {
    StyledStepCard,
    StyledPaper,
    StyledStepTypography,
    StyledTotalStepsTypography,
    StyledDisclaimerTypography,
    StyledTitleTypography,
    StyledImageContainer,
    StyledContentTypography,
    StyledOverlay,
} from '@/app/ui-new/pages/website/components/Carousel/Desktop/StepCardDesktop.styled'

const StepCardDesktop: React.FunctionComponent<StepCardDesktopProps> = (props: StepCardDesktopProps) => (
    <>
        <StyledPaper elevation={0}>
            <StyledOverlay hidden={props.isActive}/>
            <StyledStepCard display='flex' flexDirection='row' justifyContent='space-between'>
                <Box display='flex' flexDirection='row'>
                    <StyledImageContainer imgSrc={props.imgSrc}/>
                    <Box ml={12} width={567}>
                        <Box mb={12}>
                            <StyledTitleTypography variant='h4'>{props.title}</StyledTitleTypography>
                        </Box>
                        <Box mb={12}>
                            <StyledContentTypography variant='body1'>
                                {props.primaryContent}
                            </StyledContentTypography>
                        </Box>
                        <StyledDisclaimerTypography variant='body2'>
                            {props.disclaimer ? <span><sup>*</sup>{props.disclaimer}</span> : ''}
                        </StyledDisclaimerTypography>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='end' justifyContent='start'>
                    <div>
                        <StyledStepTypography variant='h1'>{props.step}</StyledStepTypography>
                    </div>
                    <div>
                        <StyledTotalStepsTypography variant='h6'>/ {props.totalSteps}</StyledTotalStepsTypography>
                    </div>
                </Box>
            </StyledStepCard>
        </StyledPaper>
    </>
)

export interface StepCardDesktopProps {
    step?: number
    totalSteps?: number
    title: string
    primaryContent: string
    disclaimer: string
    imgSrc: string
    isActive?: boolean
}

export interface ImageContainerProps extends BoxProps {
    imgSrc: string
}

export default StepCardDesktop
