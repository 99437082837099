import { BillDutiesToParty, TransportType, FedexLocation, FedexCarrierCode, Currency } from '@lazr/enums'
import { useI18n } from '@/app/ui/components/hooks/I18n' // TODO: Get useI18n from ui-new
import i18n from './useEnumSelectData.i18n'
import { firstUppercaseRestLowercase } from '../helpers'

export interface EnumSelectDataProps {
    transportTypes?: { icon: string; id: TransportType; name: string }[]
    billDutiesToParty?: { id: BillDutiesToParty; name: string }[]
    fedexLocation?: { id: FedexLocation; name: string }[]
    fedexCarrierCode?: { id: FedexCarrierCode; name: string }[]
    currencyCode?: { id: Currency; name: string }[]
}

export interface EnumSelectProps {
    id: keyof EnumSelectDataProps
    filter?: string[]
}

const useEnumSelectData = ({ id, filter }: EnumSelectProps): any => {
    const { t } = useI18n(i18n)

    const transportTypes: EnumSelectDataProps['transportTypes'] = [
        { icon: 'box', id: TransportType.PARCEL, name: t(TransportType.PARCEL) },
        { icon: 'envelope', id: TransportType.ENVELOPE, name: t(TransportType.ENVELOPE) },
        { icon: 'box', id: TransportType.PAK, name: t(TransportType.PAK) },
        { icon: 'truck-container', id: TransportType.LTL, name: t(TransportType.LTL) },
        { icon: 'truck', id: TransportType.FTL, name: t(TransportType.FTL) },
        { icon: 'ellipsis-h', id: TransportType.OTHER, name: t(TransportType.OTHER) },
    ]

    const billDutiesToParty: EnumSelectDataProps['billDutiesToParty'] = [
        { id: BillDutiesToParty.SENDER, name: t(BillDutiesToParty.SENDER) },
        { id: BillDutiesToParty.RECEIVER, name: t(BillDutiesToParty.RECEIVER) },
        { id: BillDutiesToParty.THIRD_PARTY, name: t(BillDutiesToParty.THIRD_PARTY) },
    ]

    const fedexLocation: EnumSelectDataProps['fedexLocation'] = [
        { id: FedexLocation.FRONT, name: t(FedexLocation.FRONT) },
        { id: FedexLocation.NONE, name: t(FedexLocation.NONE) },
        { id: FedexLocation.REAR, name: t(FedexLocation.REAR) },
        { id: FedexLocation.SIDE, name: t(FedexLocation.SIDE) },
    ]

    const fedexCarrierCode: EnumSelectDataProps['fedexCarrierCode'] = [
        { id: FedexCarrierCode.FDXE, name: FedexCarrierCode.FDXE },
        { id: FedexCarrierCode.FDXG, name: FedexCarrierCode.FDXG },
    ]

    const currencyCode: EnumSelectDataProps['currencyCode'] = [
        { id: Currency.CAD, name: firstUppercaseRestLowercase(Currency.CAD) },
        { id: Currency.USD, name: firstUppercaseRestLowercase(Currency.USD) },
    ]

    const allData = {
        transportTypes: transportTypes,
        billDutiesToParty: billDutiesToParty,
        fedexLocation: fedexLocation,
        fedexCarrierCode: fedexCarrierCode,
        currencyCode: currencyCode,
    }

    const filterById = ({ id, filter }: EnumSelectProps) => {
        let filtered: any = []
        if (filter && filter.length > 0) {
            filtered = allData?.[id]?.filter((item) => filter.some((query) => item.id.toLowerCase().includes(query.toLowerCase()))) || []
        } else {
            filtered = allData[id] ? allData[id] : []
        }

        return { [id]: filtered }
    }

    return filterById({ id, filter })[id]
}

export default useEnumSelectData
