import en from 'date-fns/locale/en-CA'
import fr from 'date-fns/locale/fr-CA'
import heredoc from 'tsheredoc'
import { DateTime } from 'luxon'
import { Locale as DateFnsLocale } from 'date-fns'

export type Locale = 'en-CA' | 'fr-CA'
export type Language = 'en' | 'fr'
export type Currency = 'USD' | 'CAD'
export type Translations<T> = {
    [K in keyof T]: T[K] extends (...args: any) => any
        ? (...args: Parameters<T[K]>) => ReturnType<T[K]> | null
        : T[K] | null
}

type StringKeys<T extends Record<keyof T, T[keyof T]>> = Extract<keyof T, string>
type NumberOptions = Omit<Parameters<typeof Intl.NumberFormat>[1], 'style' | 'unit' | 'unitDisplay'>
type Logger = (message: string) => void

/* eslint-disable no-underscore-dangle */
export class I18n<T extends Record<StringKeys<T>, T[keyof T]>> {
    public static readonly DATE_FULL = DateTime.DATE_FULL
    public static readonly DATE_HUGE = DateTime.DATE_HUGE
    public static readonly DATE_MED_WITH_WEEKDAY = DateTime.DATE_MED_WITH_WEEKDAY
    public static readonly DATE_MED = DateTime.DATE_MED
    public static readonly DATE_SHORT = DateTime.DATE_SHORT
    public static readonly DATETIME_FULL_WITH_SECONDS = DateTime.DATETIME_FULL_WITH_SECONDS
    public static readonly DATETIME_FULL = DateTime.DATETIME_FULL
    public static readonly DATETIME_HUGE_WITH_SECONDS = DateTime.DATETIME_HUGE_WITH_SECONDS
    public static readonly DATETIME_HUGE = DateTime.DATETIME_HUGE
    public static readonly DATETIME_MED_WITH_SECONDS = DateTime.DATETIME_MED_WITH_SECONDS
    public static readonly DATETIME_MED_WITH_WEEKDAY = DateTime.DATETIME_MED_WITH_WEEKDAY
    public static readonly DATETIME_MED = DateTime.DATETIME_MED
    public static readonly DATETIME_SHORT_WITH_SECONDS = DateTime.DATETIME_SHORT_WITH_SECONDS
    public static readonly DATETIME_SHORT = DateTime.DATETIME_SHORT
    public static readonly TIME_24_SIMPLE = DateTime.TIME_24_SIMPLE
    public static readonly TIME_24_WITH_LONG_OFFSET = DateTime.TIME_24_WITH_LONG_OFFSET
    public static readonly TIME_24_WITH_SECONDS = DateTime.TIME_24_WITH_SECONDS
    public static readonly TIME_24_WITH_SHORT_OFFSET = DateTime.TIME_24_WITH_SHORT_OFFSET
    public static readonly TIME_SIMPLE = DateTime.TIME_SIMPLE
    public static readonly TIME_WITH_LONG_OFFSET = DateTime.TIME_WITH_LONG_OFFSET
    public static readonly TIME_WITH_SECONDS = DateTime.TIME_WITH_SECONDS
    public static readonly TIME_WITH_SHORT_OFFSET = DateTime.TIME_WITH_SHORT_OFFSET

    private _lang: Language = 'en'
    private _locale: Locale = 'en-CA'
    private _dateFns: DateFnsLocale = en

    public constructor (
        private readonly messages: {
            'en': T
            'fr': Translations<T>
        },
        private readonly logger: Logger = (message: string) => console.warn(message),
    ) {}

    public getLanguage = (): Language => this._lang
    public getLocale = (): Locale => this._locale
    public getDateFnsLocale = (): DateFnsLocale => this._dateFns

    public activate = (locale: Locale | Language | undefined): I18n<T> => {
        if (locale === 'en' || !locale) {
            locale = 'en-CA'
        }

        if (locale === 'fr') {
            locale = 'fr-CA'
        }

        this._lang = locale.split('-')[0] as Language
        this._locale = locale
        this._dateFns = locale === 'en-CA' ? en : fr

        return this
    }

    public t = <K extends StringKeys<T>, V extends T[K]>
    (k: K, ...p: (V extends string ? [ undefined? ]: Parameters<V>)): (V extends string ? string : ReturnType<V>) => {
        let lang = this._lang

        if (!this.messages?.[lang]?.[k]) {
            this.logger(`Missing translation in ${lang}: "${k}"`)
            lang = 'en'
        }

        const v = this.messages[lang][k] as V

        return typeof v === 'string' ? v : v({ ...(p?.[0 as keyof typeof p] || {}) })
    }

    public n = (number: number, options?: NumberOptions): string =>
        number.toLocaleString(this._locale, { style: 'decimal', ...options })

    public c = (number: number, currency: Currency, options?: NumberOptions): string =>
        number.toLocaleString(this._locale, { style: 'currency', currency, ...options })

    public d = (date: Date | string, options?: Intl.DateTimeFormatOptions): string => {
        if (typeof date === 'string') {
            const localDate = new Date(date)

            date = new Date(
                localDate.getUTCFullYear(),
                localDate.getUTCMonth(),
                localDate.getUTCDate(),
                localDate.getUTCHours(),
                localDate.getUTCMinutes(),
                localDate.getUTCSeconds(),
                localDate.getUTCMilliseconds(),
            )
        }

        return date.toLocaleString(this._locale, options)
    }
}

export { heredoc }
