/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './DateForm.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    "Select date": "Sélectionner une date",
    Ok: "Accepter",
    Cancel: 'Annuler',
    From: "Depuis",
    To: "À"
}))()
