import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { OrganizationFilter, OrganizationListFiltering } from '../../definitions/OrganizationList'
import { initialOrganizationListFiltering } from '../store/InitialState'
import { OtherUser } from '@/app/model'

const reducer: Reducer =
    (state: OrganizationListFiltering = initialOrganizationListFiltering, action: AnyAction): OrganizationListFiltering => ({
        filters: filters(state.filters, action),
        salesRepList: salesRepList(state.salesRepList, action),
})

const filters = (state: OrganizationFilter, action: AnyAction): OrganizationFilter =>
    action.type === types.SET_ORGANIZATION_FILTER
        ? action.payload
        : state

const salesRepList = (state: OtherUser[], action: AnyAction): OtherUser[] => {
    switch (action.type) {
        case types.SET_ORGANIZATION_LIST_SALES_REP_LIST:
            return action.payload.salesRepList
        default:
            return state
    }
}

export default reducer
