import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { Locale } from '../../model'
import { Locale as LocaleCode } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export interface LocaleParams {
    code?: LocaleCode
}

export const LocaleApiService = Object.freeze({
    list: async (params: LocaleParams = {}): Promise<Locale[]> => {
        let getLocalesResponse
        try {
            getLocalesResponse = await EnumTypesService.getLocales(params.code)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve locale list')
            }
            throw new Error('Unable to retrieve locale list')
        }

        if (!getLocalesResponse.data) {
            logger.debug('list', JSON.stringify(getLocalesResponse, null, 4))
            throw new Error('Unable to retrieve locale list')
        }

        return getLocalesResponse.data
    },
})
