import { logger } from '../../logger'
import { ApiError, ErrorResponse, VersionService } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const VersionApiService = Object.freeze({
    get: async (): Promise<string> => {
        let getVersion
        try {
            getVersion = await VersionService.get()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('get', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve version')
            }
            throw new Error('Unable to retrieve version')
        }

        if (!getVersion.data) {
            logger.debug('get',  JSON.stringify(getVersion, null, 4))
            throw new Error('Unable to retrieve version')
        }

        return getVersion.data.version
    },
})
