import { logger } from '../../logger'
import { EventsService, ApiError, ErrorResponse, UuidV4 } from '@lazr/openapi-client'
import { Event, EventInfo } from '../../model'
import { EventType } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

export const EventApiService = Object.freeze({
    get: async (id : UuidV4): Promise<Event> => {
        let getEventResponse
        try {
            getEventResponse = await EventsService.getEventById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getEventResponse', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || `Unable to retrieve eventId:${id}`)
            }
            throw new Error(`Unable to retrieve eventId:${id}`)
        }

        if (!getEventResponse.data) {
            logger.debug(getEventResponse)
            throw new Error(`Unable to retrieve eventId:${id}`)
        }

        return new Event(getEventResponse.data.event)
    },
    list: async (orderId: string, eventTypes?: EventType[]): Promise<Event[]> => {
        let getEventsResponse
        try {
            getEventsResponse = await EventsService.getEvents(orderId, eventTypes)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve event list')
            }
            throw new Error('Unable to retrieve event list')
        }

        if (!getEventsResponse.data) {
            logger.debug('list', JSON.stringify(getEventsResponse, null, 4))
            throw new Error('Unable to retrieve event list')
        }

        return getEventsResponse.data.events.map((event) => new Event(event))
    },
    getListByOrderId: async (id: UuidV4): Promise<EventInfo[]> => {
        let getEventsByOrderIdResponse
        try {
            getEventsByOrderIdResponse = await EventsService.getEventByOrderId(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getListByOrderId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve event list by entity id')
            }
            throw new Error('Unable to retrieve event list by entity id')
        }

        if (!getEventsByOrderIdResponse.data) {
            logger.debug('getListByOrderId', JSON.stringify(getEventsByOrderIdResponse, null, 4))
            throw new Error('Unable to retrieve event list by entity id')
        }

        return getEventsByOrderIdResponse.data.events.map((event) => new EventInfo(event))
    },
    getListByEdiOrderId: async (id: UuidV4): Promise<EventInfo[]> => {
        let getEventsByEdiOrderIdResponse
        try {
            getEventsByEdiOrderIdResponse = await EventsService.getEventByEdiOrderId(id)
        } catch (error: any) {
            logger.debug('getListByEdiOrderId', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve event list by entity id')
            }
            throw new Error('Unable to retrieve event list by entity id')
        }

        if (!getEventsByEdiOrderIdResponse.data) {
            logger.debug('getListByEdiOrderId', JSON.stringify(getEventsByEdiOrderIdResponse, null, 4))
            throw new Error('Unable to retrieve event list by entity id')
        }

        return getEventsByEdiOrderIdResponse.data.events.map((event) => new EventInfo(event))
    },
})
