import * as types from '../constants'
import { Action } from '../constants'
import { StoreAppState } from '../../definitions/Store'

const set = (value: Partial<StoreAppState>): Action => ({
    type: types.SET_APP_STATE,
    payload: value,
})

export default set
