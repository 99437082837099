/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateUserRequest } from '../models/ActivateUserRequest';
import type { DeactivateUserRequest } from '../models/DeactivateUserRequest';
import type { DeleteUserRequest } from '../models/DeleteUserRequest';
import type { PurgeUserRequest } from '../models/PurgeUserRequest';
import type { SuccessResponse_ActivateUserResponse_ } from '../models/SuccessResponse_ActivateUserResponse_';
import type { SuccessResponse_DeactivateUserResponse_ } from '../models/SuccessResponse_DeactivateUserResponse_';
import type { SuccessResponse_DeleteUserResponse_ } from '../models/SuccessResponse_DeleteUserResponse_';
import type { SuccessResponse_PurgeUserResponse_ } from '../models/SuccessResponse_PurgeUserResponse_';
import { request as __request } from '../core/request';

export class AdminUserManagementService {

    /**
     * Activate a user in your organization. This will allow this user to
     * perform normal operations for their role.
     * @param requestBody The data
     * @returns SuccessResponse_ActivateUserResponse_ OK
     * @throws ApiError
     */
    public static async activateUser(
        requestBody: ActivateUserRequest,
    ): Promise<SuccessResponse_ActivateUserResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/activate-user`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Deactivate a user in your organization. This will prevent this user from
     * signing in and performing any operation in your organization.
     * @param requestBody The data
     * @returns SuccessResponse_DeactivateUserResponse_ OK
     * @throws ApiError
     */
    public static async deactivateUser(
        requestBody: DeactivateUserRequest,
    ): Promise<SuccessResponse_DeactivateUserResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/deactivate-user`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete a user in your organization.
     * @param requestBody The data
     * @returns SuccessResponse_DeleteUserResponse_ OK
     * @throws ApiError
     */
    public static async deleteUser(
        requestBody: DeleteUserRequest,
    ): Promise<SuccessResponse_DeleteUserResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-user`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Purge a user in your organization.
     * @param requestBody The data
     * @returns SuccessResponse_PurgeUserResponse_ OK
     * @throws ApiError
     */
    public static async purgeUser(
        requestBody: PurgeUserRequest,
    ): Promise<SuccessResponse_PurgeUserResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/purge-user`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}