import styled from 'styled-components'
import BaseCarrierLogo from '../../BaseCarrierLogo'

export const StyledCarrierLogo = styled(BaseCarrierLogo)`
    &.small div img {
        border-radius: 2px;
    }
    &.medium div img {
        border-radius: 3px;
    }
    &.large div img {
        border-radius: 4px;
    }
`
