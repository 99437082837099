import styled from 'styled-components'

import { type GridProps, Grid as MuiGrid } from '@material-ui/core'

import { spacing as MuiSpacing } from '@material-ui/system'

export const GridView = styled(styled(MuiGrid)(MuiSpacing))<GridProps>`
    padding-left:30px;
    &.min-width-800 {
        min-width: 800px;
    }

    &.components {
        align-items: center;
    }
    &.components .MuiGrid-item {
        flow-direction: column;
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &.components.style2 div[class*="MuiGrid-item"] {
        flow-direction: column;
        text-align: center;
        padding-top: ${(props): string => props.theme.spacing(2)}px;
        padding-bottom:  ${(props): string => props.theme.spacing(2)}px;
    }
`
