import { z } from 'zod'
import { isValidDate, isValidDateRange } from '../DatePicker/helpers'

const validationMinQtdSchema = (message: string,message2?: string| null | undefined, quantity?: number | null | undefined) => {
    const qty = quantity? quantity: 2
    return z
        .string({
            required_error: `${message}`,
          })
        .nullable()
        .superRefine((val, ctx) => {

          if (!val) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom ,
                message: `${message}`,
            });
        } else if (quantity && val.length <= qty) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `${message2?.toString().replace('≤≤qtd≥≥', qty.toString())}`,
            });
        }
        })
}

const validationMoreSizeSchema = (message: string, quantity?: number) => {
    const qty = quantity || 2
    return z
        .string()
        .nullable()
        .refine((value) => !value || value.length > qty, {
            message: message,
        })
        .optional()
}

const dateRangeSchema = (message: string, message2: string) =>
    z
        .object({
            from: z
                .string()
                .nullable()
                .refine((value) => !value || isValidDate(value), {
                    message: message,
                })
                .optional(),
            to: z
                .string()
                .nullable()
                .refine((value) => !value || isValidDate(value), {
                    message: message,
                })
                .optional(),
        })
        .refine(({ from, to }) => (!from && !to) || isValidDateRange(from, to), {
            message: message2,
        })
        .optional()

export { validationMoreSizeSchema, dateRangeSchema, validationMinQtdSchema }
