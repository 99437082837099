import styled from 'styled-components'
import { IconButton } from '@/app/ui-new/components/IconButton/IconButton.styled'
import { transparentize } from 'polished'
import SwipeableViews from 'react-swipeable-views'
import React from 'react'
import { ButtonProps, Typography, TypographyProps } from '@material-ui/core'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'

export const StyledIconButton = styled(IconButton)`
    background-color: ${(props): string => props.theme.palette.neutral800.main};
    border-color: transparent;
    color: ${(props): number => props.theme.palette.white.main} !important;
    z-index: 1;
    transform: translate(0, -50%);

    &:focus{
        outline: unset;
        border-color: transparent;
    }

    &:hover{
        outline: unset;
        border-color: ${(props): string => props.theme.palette.accentPrimary800.main};
    }

    &.Mui-disabled {
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.neutral800.main)};
    }
`

export const StyledSwipeableViews = styled(SwipeableViews)`
    padding: 0 98px;
    cursor: pointer;
    width: 100%;

    .react-swipeable-view-container > div{
        padding: 0 10px;
        user-select: none;
    }
`

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 3rem;
    line-height: 120%;
    color: ${(props): string => props.theme.palette.neutral800.main};
`

export const StyledSubTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 1.75rem;
    font-weight: 400;
    color: ${(props): string => props.theme.palette.neutral700.main};
    line-height: 120%;
`

export const StyledPrimaryButton: React.FC<ButtonProps> = styled(PrimaryButton)<ButtonProps>`
    background-color: ${(props): number => props.theme.palette.accentSecondary.main};
    color: ${(props): number => props.theme.palette.white.main};
    &:hover {
        background: ${(props): number => props.theme.palette.accentSecondary400.main};
    }

    &:focus {
        outline: 2px solid ${(props): number => props.theme.palette.accentSecondary400.main};
        outline-offset: 3px;
    }
`
