import React from 'react'
import SchedulePickup, { type SchedulePickupProps } from '../sharedModules/SchedulePickup.module/SchedulePickup'
import Header from '@/app/ui-new/components/Header/Header'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './View.i18n'
import { GridView } from './View.styled'
import { Box } from '@material-ui/core'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface Props {
    nameID?: string
    defaultPickup: SchedulePickupProps['defaultPickup']
}

const View: React.FunctionComponent<Props> = ({ nameID, defaultPickup }: Props) => {
    const { t } = useI18n(i18n)

    return (
        <>
            <GridView id={nameID}>
                <Header
                    title={{
                        value: t('Schedule Pickup'),
                    }}
                    layoutConfig={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                    nameID={`${nameID}-header`}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <SecondaryButton
                            size='small'
                            href='/beta/schedule-pickup/'
                            startIcon={<FontAwesomeIcon icon={['fas', 'arrow-left']} />}
                            style={{ maxWidth: '200px', marginRight: '10px' }}
                            id={'listPickup'}
                        >
                            {t('Schedule Pickup List')}
                        </SecondaryButton>
                    </Box>
                </Header>
                <br />
                <SchedulePickup nameID={nameID ?? ''} defaultPickup={defaultPickup} />
            </GridView>
        </>
    )
}

export default View
