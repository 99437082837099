import styled from 'styled-components'

import {
    Typography as MuiTypography,
} from '@material-ui/core'

import {
    spacing,
} from '@material-ui/system'
import React from 'react'

export const Typography = styled(styled(MuiTypography)(spacing)) <{ component?: React.ElementType<React.HTMLAttributes<HTMLElement>> }>``

export const TitleTypography = styled(Typography)`
    font-weight: ${(props): number => props.theme.typography.fontWeightMedium};
`
