import { AccessorialLocationType, Currency as CurrencyCode, PaymentTypeOption } from '@lazr/openapi-client'
import { PreparedSearch } from '@/app/model/PreparedSearch'
import { ValidatedAddress } from '../../definitions/ValidatedAddress'
import { deepCopy } from '@lazr/utilities'

import {
    Action,
    ADD_MARKET_PLACE_CUSTOMS_DETAIL,
    DELETE_MARKET_PLACE_HANDLING_UNIT,
    DELETE_MARKET_PLACE_CUSTOMS_DETAIL,
    RESET_MARKET_PLACE_CARGO,
    RESET_MARKET_PLACE_DUTY_BILL_ADDRESS,
    RESET_MARKET_PLACE_CUSTOMS_DETAILS,
    RESET_MARKET_PLACE_CUSTOMS_INFO,
    SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED,
    FILL_MARKETPLACE_TRIGGER,
    SET_MARKET_PLACE,
    SET_MARKET_PLACE_ACCESSORIAL_LISTS,
    SET_MARKET_PLACE_ADDRESS_ACCESSORIALS,
    SET_MARKET_PLACE_ADDRESS_INFORMATION,
    SET_MARKET_PLACE_BILLING_CURRENCY,
    SET_MARKET_PLACE_CARGO_ACCESSORIALS,
    SET_MARKET_PLACE_COMMODITY_TYPE,
    SET_MARKET_PLACE_COMPLETED_STEP,
    SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST,
    SET_MARKET_PLACE_ERRORS,
    SET_MARKET_PLACE_ERRORS_IS_SUBMITTED,
    SET_MARKET_PLACE_HANDLING_UNIT,
    SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST,
    SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL,
    RESET_MARKET_PLACE_HAZMAT_DETAIL,
    SET_MARKET_PLACE_INVALIDATION_DIALOG,
    SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN,
    SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE,
    SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE,
    SET_MARKET_PLACE_MARKET_PLACE_STEP,
    SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS,
    SET_MARKET_PLACE_ORDER_INFORMATION,
    SET_MARKET_PLACE_PACKAGE_TYPE_LIST,
    SET_MARKET_PLACE_PLACES_AUTOCOMPLETE,
    SET_MARKET_PLACE_RFQ_INFORMATION,
    SET_MARKET_PLACE_SELECTED_QUOTE,
    SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST,
    SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE,
    SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE,
    SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE_CURRENCY,
    SET_MARKET_PLACE_TRANSPORT_INFORMATION,
    SET_PROMPT_MODAL_LEAVE,
    SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK,
    SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK,
    SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS,
    SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS,
    SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED,
    SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED,
    PRELOAD_MARKET_PLACE,
    TOGGLE_REASONS_MODAL,
    TOGGLE_FEDEX_GROUND_MODAL,
    MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL,
    RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS,
    SET_MARKET_PLACE_SCHEDULE_PICKUP,
    SET_PAYMENT_TYPE,
    SET_COLLECT_INFORMATION,
    SET_USER_LANES,
    SET_USER_SELECTED_LANE,
    SET_NEW_PICKUP_DATE,
    SET_MARKET_PLACE_QUOTE_FETCHING_RESULTS_COMPLETED,
    SET_MARKET_PLACE_QUOTE_FETCHING_SEARCH_PROGRESS,
    SET_COUNTRY_LIST,
    SET_PROMPT_UNSAVED_CHANGES_DIALOG,
} from '../constants'

import {
    initialDutyBillAddress,
    initialCustomsDetails,
    initialHazmatDetail,
    initialCustomsInfo,
    resetMarketPlace as resetMarketPlaceObject,
    initialCustomsBrokerAddress,
} from '../store/InitialState'

import {
    Address,
    CoverageCommodityType,
    Currency,
    PackageType,
    Search,
    SearchRfq,
    SearchRfqAccessorial,
    SearchRfqHandlingUnit,
    SearchRfqHandlingUnitAccessorial,
    SearchRfqCustomsDetail,
    SearchRfqQuote,
    //ResultDocument,
    Lane,
    Country,
} from '@/app/model'

import {
    InvalidationDialog,
    MarketPlace,
    MarketPlaceErrors,
    MarketPlaceStep,
    Collect,
} from '@/app/ui-new/definitions/MarketPlace'
import { AddressSearchOption } from '@/app/ui-new/components/AddressSearchBar/AddressSearchBar'
import * as types from '../../../ui/redux/constants'


export const setAddressInformation = (
    rfq: Partial<SearchRfq>,
): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_INFORMATION,
    payload: {
        rfq,
    },
})

export const setPlacesAutocomplete = (newPlace: AddressSearchOption | null, field: keyof MarketPlace | null): Action => ({
    type: SET_MARKET_PLACE_PLACES_AUTOCOMPLETE,
    payload: {
        newPlace,
        field,
    },
})

export const resetHandlingUnit = (): Action => ({
    type: RESET_MARKET_PLACE_CARGO,
    payload: null,
})

export const deleteHandlingUnit = (index: number): Action => ({
    type: DELETE_MARKET_PLACE_HANDLING_UNIT,
    payload: index,
})

export const setHandlingUnit = (handlingUnit: Partial<SearchRfqHandlingUnit>, index: number): Action => ({
    type: SET_MARKET_PLACE_HANDLING_UNIT,
    payload: {
        handlingUnit,
        index,
    },
})

export const addCustomsDetail = (): Action => ({
    type: ADD_MARKET_PLACE_CUSTOMS_DETAIL,
    payload: {
        initialCustomsDetails: deepCopy(initialCustomsDetails),
    },
})

export const deleteCustomsDetail = (index: number): Action => ({
    type: DELETE_MARKET_PLACE_CUSTOMS_DETAIL,
    payload: {
        index,
    },
})

export const mapHandlingUnitCustomsDetail = (index: number, handlingUnitId: string, isSelected: boolean): Action => ({
    type: MAP_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL,
    payload: {
        index,
        handlingUnitId,
        isSelected,
    },
})

export const resetCustomsDetails = (): Action => ({
    type: RESET_MARKET_PLACE_CUSTOMS_DETAILS,
    payload: null,
})

export const resetCustomsInfo = (): Action => ({
    type: RESET_MARKET_PLACE_CUSTOMS_INFO,
    payload: {
        initialCustomsInfo,
    },
})

export const resetHazmatDetail = (): Action => ({
    type: RESET_MARKET_PLACE_HAZMAT_DETAIL,
    payload: {
        initialHazmatDetail,
    },
})

export const resetDutyBillAddress = (): Action => ({
    type: RESET_MARKET_PLACE_DUTY_BILL_ADDRESS,
    payload: {
        initialDutyBillAddress,
    },
})

export const resetCustomsBrokerAddress = (): Action => ({
    type: RESET_MARKET_PLACE_CUSTOMS_BROKER_ADDRESS,
    payload: {
        initialCustomsBrokerAddress: deepCopy(initialCustomsBrokerAddress),
    },
})

export const setHandlingUnitCustomsDetail = (
    handlingUnitCustomsDetail: Partial<SearchRfqCustomsDetail>,
    index: number,
): Action => ({
    type: SET_MARKET_PLACE_HANDLING_UNIT_CUSTOMS_DETAIL,
    payload: {
        handlingUnitCustomsDetail,
        index,
    },
})

export const setTransportInformation = (rfq: Partial<SearchRfq>): Action => ({
    type: SET_MARKET_PLACE_TRANSPORT_INFORMATION,
    payload: {
        rfq,
    },
})

export const preloadMarketPlace = (preparedSearch: PreparedSearch): Action => ({
    type: PRELOAD_MARKET_PLACE,
    payload: {
        preparedSearch,
    },
})

export const setMarketPlace = (marketPlace: Partial<MarketPlace>): Action => ({
        type: SET_MARKET_PLACE,
        payload: {
            marketPlace,
        },
})

export const setFillMarketPlaceTrigger = (triggered: boolean | null): Action => ({
    type: FILL_MARKETPLACE_TRIGGER,
    payload: triggered,
})

export const setCommodityType = (commodityType: CoverageCommodityType | null): Action => ({
    type: SET_MARKET_PLACE_COMMODITY_TYPE,
    payload: commodityType,
})

export const setIsCoverageExclusionsAccepted = (isCoverageExclusionsAccepted: boolean | undefined): Action => ({
    type: SET_IS_COVERAGE_EXCLUSIONS_ACCEPTED,
    payload: isCoverageExclusionsAccepted,
})

export const setShipmentCoverageValue = (shipmentCoverageValue: number | undefined): Action => ({
    type: SET_MARKET_PLACE_SHIPMENT_COVERAGE_VALUE,
    payload: {
        shipmentCoverageValue,
    },
})

export const setOriginalShipmentCoverageValue = (originalShipmentCoverageValue: number): Action => ({
    type: SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE,
    payload: {
        originalShipmentCoverageValue,
    },
})

export const setOriginalShipmentCoverageValueCurrency = (originalShipmentCoverageValueCurrency: CurrencyCode): Action => ({
    type: SET_MARKET_PLACE_ORIGINAL_SHIPMENT_COVERAGE_VALUE_CURRENCY,
    payload: {
        originalShipmentCoverageValueCurrency,
    },
})

export const setBillingCurrency = (billingCurrency: Currency): Action => ({
    type: SET_MARKET_PLACE_BILLING_CURRENCY,
    payload: billingCurrency,
})

export const setSelectedQuote = (selectedQuote: SearchRfqQuote | null): Action => ({
    type: SET_MARKET_PLACE_SELECTED_QUOTE,
    payload: {
        selectedQuote,
    },
    })

export const setIsBookedWithCoverage = (isBookedWithCoverage: boolean | undefined | null): Action => ({
    type: SET_MARKET_PLACE_IS_BOOKED_WITH_COVERAGE,
    payload: {
        isBookedWithCoverage,
    },
})

export const setMarketPlaceStep = (step: MarketPlaceStep): Action => {
    localStorage.setItem('newMarketPlaceStep', step.toString())

    return {
        type: SET_MARKET_PLACE_MARKET_PLACE_STEP,
        payload: step,
    }
}

export const setMarketPlaceLastCompletedStep = (step: MarketPlaceStep): Action => {
    localStorage.setItem('newMarketPlaceLastCompletedStep', step.toString())

    return {
        type: SET_MARKET_PLACE_COMPLETED_STEP,
        payload: step,
    }
}

export const setAccessorialList =
    (originAccessorialList: SearchRfqAccessorial[], destinationAccessorialList: SearchRfqAccessorial[]): Action => ({
        type: SET_MARKET_PLACE_ACCESSORIAL_LISTS,
        payload: { originAccessorialList, destinationAccessorialList },
    })

export const setCountryList = (country: Country[]): Action => ({
    type: SET_COUNTRY_LIST,
    payload: country,
})

export const setHandlingUnitAccessorialList =
    (handlingUnitAccessorialList: SearchRfqAccessorial[]): Action => ({
        type: SET_MARKET_PLACE_HANDLING_UNIT_ACCESSORIAL_LIST,
        payload: handlingUnitAccessorialList,
    })

export const setServiceAccessorialList =
    (serviceAccessorialList: SearchRfqAccessorial[]): Action => ({
        type: SET_MARKET_PLACE_SERVICE_ACCESSORIAL_LIST,
        payload: serviceAccessorialList,
    })

export const setPackageTypeList =
    (packageTypeList: PackageType[]): Action => ({
        type: SET_MARKET_PLACE_PACKAGE_TYPE_LIST,
        payload: packageTypeList,
    })

export const setCoverageCommodityTypeList =
    (coverageCommodityTypeList: CoverageCommodityType[]): Action => ({
        type: SET_MARKET_PLACE_COVERAGE_COMMODITY_TYPE_LIST_LIST,
        payload: coverageCommodityTypeList,
    })

export const setIsQuoteFetchingComplete = (isQuoteFetchedComplete: boolean): Action => ({
    type: SET_MARKET_PLACE_IS_QUOTE_FETCHING_COMPLETE,
    payload: isQuoteFetchedComplete,
})
export const setQuoteFetchingResultsCompleted = (quoteFetchingResultsCompleted: Record<string, boolean>): Action => ({
    type: SET_MARKET_PLACE_QUOTE_FETCHING_RESULTS_COMPLETED,
    payload: quoteFetchingResultsCompleted,
})
export const setQuoteFetchingSearchProgress = (quoteFetchingSearchProgress: number): Action => ({
    type: SET_MARKET_PLACE_QUOTE_FETCHING_SEARCH_PROGRESS,
    payload: quoteFetchingSearchProgress,
})
export const setSchedulePickup = (schedulePickup: boolean): Action => ({
    type: SET_MARKET_PLACE_SCHEDULE_PICKUP,
    payload: schedulePickup,
})

export const setMarketPlaceErrors = (marketPlaceErrors: MarketPlaceErrors): Action => ({
    type: SET_MARKET_PLACE_ERRORS,
    payload: marketPlaceErrors,
})

export const setMarketPlaceErrorsIsSubmitted = (isSubmitted: boolean): Action => ({
    type: SET_MARKET_PLACE_ERRORS_IS_SUBMITTED,
    payload: isSubmitted,
})

export const resetMarketPlace = (): Action => {
    localStorage.removeItem('newMarketPlaceStep')
    localStorage.removeItem('newMarketPlaceLastCompletedStep')
    localStorage.removeItem('newMarketPlaceOrderId')
    localStorage.removeItem('newMarketPlaceOrderCompletedAt')

    return setMarketPlace(deepCopy(resetMarketPlaceObject) as Partial<MarketPlace>)
}

export const setRfqInformation = (rfq: Partial<SearchRfq>): Action => ({
    type: SET_MARKET_PLACE_RFQ_INFORMATION,
    payload: {
        rfq,
    },
})
export const toggleReasonsModal = (): Action => ({
    type: TOGGLE_REASONS_MODAL,
    payload: null,
})

export const toggleFedexGroundModal = (): Action => ({
    type: TOGGLE_FEDEX_GROUND_MODAL,
    payload: null,
})

export const setAddressAccessorials = (accessorials: SearchRfqAccessorial[], locationType: AccessorialLocationType): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_ACCESSORIALS,
    payload: {
        accessorials,
        locationType,
    },
})

export const setNonRemovableAddressAccessorials = (
    accessorials: SearchRfqAccessorial[],
    locationType: AccessorialLocationType,
): Action => ({
    type: SET_MARKET_PLACE_NON_REMOVABLE_ADDRESS_ACCESSORIALS,
    payload: {
        accessorials,
        locationType,
    },
})

export const setCargoAccessorials = (accessorials: SearchRfqHandlingUnitAccessorial[], index: number): Action => ({
    type: SET_MARKET_PLACE_CARGO_ACCESSORIALS,
    payload: {
        accessorials,
        index,
    },
})

export const setOrderInformation = (order: Partial<Search>): Action => ({
    type: SET_MARKET_PLACE_ORDER_INFORMATION,
    payload: {
        order,
    },
})

export const openInvalidationDialog = (): Action => ({
    type: SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN,
    payload: true,
})

export const closeInvalidationDialog = (): Action => ({
    type: SET_MARKET_PLACE_INVALIDATION_DIALOG_IS_OPEN,
    payload: false,
})

export const setInvalidationDialog = (invalidationDialog: InvalidationDialog): Action => ({
    type: SET_MARKET_PLACE_INVALIDATION_DIALOG,
    payload: {
        ...invalidationDialog,
        isInvalidationDialogOpen: !!invalidationDialog?.invalidationFunction,
    }
})

export const setPromptModalLeave = (promptModalLeave: boolean): Action => ({
    type: SET_PROMPT_MODAL_LEAVE,
    payload: promptModalLeave,
})

export const setOriginAddressBookAddress = (address: Address | null): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_ADDRESS_BOOK_ADDRESS,
    payload: {
        address,
    },
})

export const setDestinationAddressBookAddress = (address: Address | null): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_ADDRESS_BOOK_ADDRESS,
    payload: {
        address,
    },
})

export const setOriginSaveToAddressBook = (originSaveToAddressBook: boolean): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_INFORMATION_ORIGIN_SAVE_TO_ADDRESS_BOOK,
    payload: originSaveToAddressBook,
})

export const setDestinationSaveToAddressBook = (destinationSaveToAddressBook: boolean): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_INFORMATION_DESTINATION_SAVE_TO_ADDRESS_BOOK,
    payload: destinationSaveToAddressBook,
})

export const setOriginAddressValidated = (originValidatedAddress?: ValidatedAddress): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_ORIGIN_VALIDATED,
    payload: originValidatedAddress,
})

export const setDestinationAddressValidated = (destinationValidatedAddress?: ValidatedAddress): Action => ({
    type: SET_MARKET_PLACE_ADDRESS_DESTINATION_VALIDATED,
    payload: destinationValidatedAddress,
})

export const setPaymentType = (paymentType?: PaymentTypeOption): Action => ({
    type: SET_PAYMENT_TYPE,
    payload: paymentType,
})

export const setCollectInformation = (collect: Partial<Collect> | null): Action => ({
    type: SET_COLLECT_INFORMATION,
    payload: collect,
})

export const setUserLanes = (lanes: Lane[]): Action => ({
    type: SET_USER_LANES,
    payload: lanes,
})

export const setSelectedLane = (lane: Lane | null): Action => ({
    type: SET_USER_SELECTED_LANE,
    payload: lane,
})

export const setRescheduledPickupDate = (newPickupDate: string): Action => ({
    type: SET_NEW_PICKUP_DATE,
    payload: newPickupDate,
})

export const setPromptUnsavedChangesDialog = (promptUnsavedChangesDialog: boolean): Action => ({
    type: SET_PROMPT_UNSAVED_CHANGES_DIALOG,
    payload: promptUnsavedChangesDialog,
})

export const setUserIsSignedIn = (value: boolean): types.Action => ({
    type: types.SET_USER_IS_SIGNED_IN,
    payload: value,
})
