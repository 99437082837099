import React, { useEffect, useState } from 'react'
import { CssBaseline } from '@material-ui/core'
import { GlobalStyle } from './RootLayout.styled'
import { LayoutProps } from '../../ui/routes'
import { NotistackProvider } from '@/app/ui-new/components/Notistack/Notistack'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import { newDesignThemes } from '@/app/ui/theme'
import NotistackHandler from '@/app/ui-new/layouts/NotistackHandler'
import NotistackAlerts from '@/app/ui-new/layouts/NotistackAlerts'
import Cookies from 'js-cookie'

const RootLayout: React.FunctionComponent<Props> = ({ children }) => {
    const cookiesSnakbarOpen = Cookies.get('cookie-law-agreed') !== 'true'

   // const [messageSnakbarOpen, setMessageSnakbarOpen] = useState(false)
    //MESSAGE ONCE A DAY:
    const cookieName = 'messageStrike'
    const currentDate = new Date().toISOString().split('T')[0]

    // Function to get a cookie value by name
    const getCookie = (name: string) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
        return match ? match[2] : null
    }

    // Check if the message was already shown today
    const lastShownDate = getCookie(cookieName)
    let messageSnakbarOpen = false

    if (lastShownDate !== currentDate) {
        // Show the message
         messageSnakbarOpen = true
    }

    // useEffect(() => {
       
    //     console.log('lastShownDate',lastShownDate,'currentDate',currentDate )
    //     if (lastShownDate !== currentDate) {
    //         // Show the message
    //         console.log('image here')
    //         setMessageSnakbarOpen(true)
    //     }
    // }, [])

    return (
        <>
            <CssBaseline />
            <GlobalStyle />
            <MuiThemeProvider theme={newDesignThemes[0]}>
                <ThemeProvider theme={newDesignThemes[0]}>
                    <NotistackProvider autoHideDuration={6000}>
                        <NotistackHandler>
                            <NotistackAlerts versionSnackbarOpen={false} cookiesSnakbarOpen={cookiesSnakbarOpen} />
{/*                           Remeve this comment to add message to the client \\ To do: future, lets create a system to add message
                            <NotistackAlerts messageUserSnackbar ={{open: messageSnakbarOpen, cookieName:cookieName, expiresDays: 1}} /> */}

                            {children}
                        </NotistackHandler>
                    </NotistackProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </>
    )
}

export interface Props extends LayoutProps {}

export default RootLayout
