import styled from 'styled-components'
import BaseCarrierLogo from '../../BaseCarrierLogo'

export const StyledCarrierLogo = styled(BaseCarrierLogo)`
    background: #A6A6A6;
    &.small div img {
        border-radius: 2px;
    }
    &.medium div img {
        border-radius: 3px;
    }
    &.large div img {
        border-radius: 4px;
    }
    &.wide-large div img {
        border-radius: 8px;
    }
    &.wide-large div {
        width: 70%;
    }
`
