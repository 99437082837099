import { AccessorialLocationType, AccessorialType, TransportType } from '@lazr/openapi-client'

export interface AccessorialProps {
    id: string
    code: string
    name: string
    locationType: AccessorialLocationType
    type: AccessorialType
    transportTypes: TransportType[]
    forceCustomQuote: boolean
}


export class Accessorial {
    public id: string
    public locationType: AccessorialLocationType
    public type: AccessorialType
    public transportTypes: TransportType[]
    public code: string
    public name: string
    public forceCustomQuote: boolean

    constructor (props: AccessorialProps) {
        this.id = props.id
        this.code = props.code
        this.name = props.name
        this.locationType = props.locationType
        this.type = props.type
        this.transportTypes = props.transportTypes
        this.forceCustomQuote = props.forceCustomQuote
    }
}
