import React, { useState } from 'react'
import {
    BoxPanel,
    StyledDialog,
    StyledFormContainer, Wrapper,
} from './RegisterOrganizationMobile.styled'
import { DialogCloseIcon } from '@/app/ui-new/components/Dialog/Dialog'
import { RegistrationState } from '@lazr/openapi-client'

/* I18N */
import { BannerMobile } from '@/app/ui-new/pages/website/components/Register Organization/Mobile/Banner/BannerMobile'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from '@/app/ui-new/pages/auth/AuthMain.i18n'
import { Welcome } from '@/app/ui-new/pages/auth/AuthMain.styled'
import RegisterOrganization from '@/app/ui-new/pages/auth/RegisterOrganization'
import { Box } from '@material-ui/core'

const RegisterOrganizationMobile: React.FunctionComponent<Props> = (props: Props) => {
    const { t } = useI18n(i18n)
    const welcomeMessageRegister = <>{t('Create your Organization')}</>
    const [ successfulSignup, setSuccessfulSignup ] = useState<boolean>(false)
    const [ registrationState, setRegistrationState ] = useState<RegistrationState | null>(null)

    return (
        <>
            <StyledDialog
                alignment='right'
                open={props.isRegisterOrganizationOpen}
                onClose={() => {props.closeDialog()}}
                fullScreen
            >
                <DialogCloseIcon
                    onClick={() => {
                        props.closeDialog()
                    }}
                />
                { successfulSignup && <Wrapper>
                    <RegisterOrganization
                        successfulSignup={successfulSignup}
                        setSuccessfulSignup={setSuccessfulSignup}
                        registrationState={registrationState}
                        setRegistrationState={setRegistrationState}
                    />
                </Wrapper>}
                { !successfulSignup && <Box>
                    <BannerMobile/>
                    <StyledFormContainer>
                        <Wrapper>
                            <div>
                                <Welcome>
                                    { welcomeMessageRegister }
                                </Welcome>
                            </div>
                            <BoxPanel>
                                <RegisterOrganization
                                    successfulSignup={successfulSignup}
                                    setSuccessfulSignup={setSuccessfulSignup}
                                    registrationState={registrationState}
                                    setRegistrationState={setRegistrationState}
                                />
                            </BoxPanel>
                        </Wrapper>
                    </StyledFormContainer>
                </Box>}
            </StyledDialog>
        </>
    )
}

export interface Props {
    isRegisterOrganizationOpen: boolean
    closeDialog: () => void
    openSnackbar: (type: 'error' | 'success') => Promise<void>
}

export default RegisterOrganizationMobile
