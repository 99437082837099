import { Accessorial } from './Accessorial'
import { Country } from '@/app/model/Country'
import { UuidV5 } from '@lazr/types'
import {
    DensityUnit,
    DimensionUnit,
    EquipmentType,
    PackageType,
    TransportType,
    VolumeUnit,
    WeightUnit,
} from '@lazr/enums'

export interface OrderDataProps {
    addressInformation: {
        originStreetAddress?: string
        originStreetAddress2?: string
        originStreetAddress3?: string
        originCity: string
        originState: string
        originCountry: Country
        originPostalCode: string
        originPickupDate: string
        destinationStreetAddress?: string
        destinationStreetAddress2?: string
        destinationStreetAddress3?: string
        destinationCity: string
        destinationState: string
        destinationCountry: Country
        destinationPostalCode: string
    }
    originAddressAccessorials: {
        id: Accessorial['id']
        accessorial: Accessorial
        isRemovable: true
    }[]
    destinationAddressAccessorials: {
        id: Accessorial['id']
        accessorial: Accessorial
        isRemovable: true
    }[]
    transportationInformation: {
        transportType: TransportType
        equipmentType: EquipmentType
    }
    handlingUnits: {
        accessorials?: {
            id: Accessorial['id']
            accessorial: Accessorial
            isRemovable: true
        }[]
        class: string
        density: number
        densityUnit: DensityUnit
        description: string
        nmfc?: string
        nmfcSub?: string
        packageType: PackageType
        pieces: number
        quantity: number
        totalVolume: number
        totalVolumeUnit: VolumeUnit
        totalWeight: number
        totalWeightUnit: WeightUnit
        weight: number
        weightUnit: WeightUnit
        height: number
        heightUnit: DimensionUnit
        length: number
        lengthUnit: DimensionUnit
        width: number
        widthUnit: DimensionUnit
    }[]
    rfqInformation: {
        originCompanyName: string
        originContactName?: string
        originContactPhone?: string
        originContactPhoneExtension?: string
        originContactEmails?: string[]
        originPoNumber?: string
        originPickupOpenTime: string
        originPickupCloseTime: string
        originPickupInstructions?: string
        destinationCompanyName: string
        destinationContactName?: string
        destinationContactPhone?: string
        destinationContactPhoneExtension?: string
        destinationContactEmails?: string[]
        destinationPoNumber?: string
        destinationDeliveryOpenTime: string
        destinationDeliveryCloseTime: string
        destinationDeliveryInstructions?: string
    }
    orderInformation: {
        clientNumber?: string
        threePlNumber?: string
        ediOrderId: UuidV5
    }
}

export class OrderData {
    addressInformation: {
        originStreetAddress?: string
        originStreetAddress2?: string
        originStreetAddress3?: string
        originCity: string
        originState: string
        originCountry: Country
        originPostalCode: string
        originPickupDate: string
        destinationStreetAddress?: string
        destinationStreetAddress2?: string
        destinationStreetAddress3?: string
        destinationCity: string
        destinationState: string
        destinationCountry: Country
        destinationPostalCode: string
    }
    originAddressAccessorials: {
        id: Accessorial['id']
        accessorial: Accessorial
        isRemovable: true
    }[]
    destinationAddressAccessorials: {
        id: Accessorial['id']
        accessorial: Accessorial
        isRemovable: true
    }[]
    transportationInformation: {
        transportType: TransportType
        equipmentType: EquipmentType
    }
    handlingUnits: {
        accessorials?: {
            id: Accessorial['id']
            accessorial: Accessorial
            isRemovable: true
        }[]
        class: string
        density: number
        densityUnit: DensityUnit
        description: string
        nmfc?: string
        nmfcSub?: string
        packageType: PackageType
        pieces: number
        quantity: number
        totalVolume: number
        totalVolumeUnit: VolumeUnit
        totalWeight: number
        totalWeightUnit: WeightUnit
        weight: number
        weightUnit: WeightUnit
        height: number
        heightUnit: DimensionUnit
        length: number
        lengthUnit: DimensionUnit
        width: number
        widthUnit: DimensionUnit
    }[]
    rfqInformation: {
        originCompanyName: string
        originContactName?: string
        originContactPhone?: string
        originContactPhoneExtension?: string
        originContactEmails?: string[]
        originPoNumber?: string
        originPickupOpenTime: string
        originPickupCloseTime: string
        originPickupInstructions?: string
        destinationCompanyName: string
        destinationContactName?: string
        destinationContactPhone?: string
        destinationContactPhoneExtension?: string
        destinationContactEmails?: string[]
        destinationPoNumber?: string
        destinationDeliveryOpenTime: string
        destinationDeliveryCloseTime: string
        destinationDeliveryInstructions?: string
    }
    orderInformation: {
        clientNumber?: string
        threePlNumber?: string
        ediOrderId: UuidV5
    }

    constructor (props: OrderDataProps) {
        this.addressInformation = props.addressInformation
        this.originAddressAccessorials = props.originAddressAccessorials
        this.destinationAddressAccessorials = props.destinationAddressAccessorials
        this.transportationInformation = props.transportationInformation
        this.handlingUnits = props.handlingUnits
        this.rfqInformation = props.rfqInformation
        this.orderInformation = props.orderInformation
    }
}
