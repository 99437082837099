import React from 'react'
import { Box, PaperProps } from '@material-ui/core'
import {
    StyledStepCard,
    StyledPaper,
    StyledJobTitleTypography,
    StyledNameTypography,
    StyledPrimaryContentTypography,
} from '@/app/ui-new/pages/website/components/TestimonialsCarousel/Mobile/StepCardMobile.styled'
import { useSelector } from 'react-redux'
import { getLanguage as reduxGetLanguage } from '@/app/ui/redux/selectors'

const StepCardMobile: React.FunctionComponent<StepCardMobileProps> = (props: StepCardMobileProps) => {
    const language = useSelector(reduxGetLanguage)

    return (
        <StyledPaper elevation={0} language={language}>
            <StyledStepCard display='flex' flexDirection='column' alignItems={'flex-start'}>
                <img src={`/images/website/carousel/Quotes.svg?v=${window.lazrVersion}`} height={38} alt={'Quotes'} />
                <Box mt={8} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box>
                        <StyledPrimaryContentTypography variant='h4'>{props.primaryContent}</StyledPrimaryContentTypography>
                    </Box>
                    <Box >
                        <Box mb={1} mt={4}>
                            <StyledNameTypography variant='body1'>
                                {props.name}
                            </StyledNameTypography>
                        </Box>
                        <StyledJobTitleTypography variant='body2'>
                            {props.jobTitle}
                        </StyledJobTitleTypography>
                    </Box>
                </Box>
            </StyledStepCard>
        </StyledPaper>
    )
}

export interface StepCardMobileProps {
    primaryContent: string
    name: string
    jobTitle: string
}

export interface StyledPaperProps extends PaperProps {
    language: string
}

export default StepCardMobile
