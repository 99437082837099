import throttle from 'lodash/throttle'
import { AddressApiService } from '@/app/service/ApiService'
import type { Address, Country } from '@/app/model'
import { type GoogleAddressSearchOption, GoogleSearchAddressType } from '@/app/ui-new/components/GoogleAddressSearchBar/GoogleAddressSearchBar'
import { type AutocompletePredictionAddress, autocompleteService } from './PlacesAutocomplete'

import type { AddressType } from '@lazr/openapi-client'

type AutocompletionRequest = google.maps.places.AutocompletionRequest


export interface AddressDetails {
    companyName?: string | null
    state?: string | null
    country?: Country | null
    city?: string | null
    streetAddress?: string | null
    streetAddress2?: string | null
    postalCode?: string | null
    addressType?: AddressType | null
    detectedAddressType?: AddressType | null
}


const getPlacePredictions = async (request: AutocompletionRequest): Promise<AutocompletePredictionAddress[]> =>
    new Promise((resolve) => {
        autocompleteService.getPlacePredictions(request, (results: AutocompletePredictionAddress[]) => {
            resolve(results)
        })
    })

    export const formatAddress = (addressInfo: AddressDetails): string => {
        const addressItems: string[] = []
    
        if (addressInfo.companyName) {
            addressItems.push(addressInfo.companyName)
        }
    
        if (addressInfo.streetAddress2) {
            addressItems.push(addressInfo.streetAddress2)
        }
    
        if (addressInfo.streetAddress) {
            addressItems.push(addressInfo.streetAddress)
        }
    
        if (addressInfo.city) {
            addressItems.push(addressInfo.city)
        }
    
        if (addressInfo.state) {
            addressItems.push(addressInfo.state)
        }
    
        if (addressInfo.country) {
            addressItems.push(addressInfo.country.name)
        }
    
        if (addressInfo.postalCode) {
            addressItems.push(addressInfo.postalCode)
        }
    
        return addressItems.join(', ')
    }

export const fetchAddressResults = throttle(async (
    searchValue: string,
    callback: (result: GoogleAddressSearchOption[]) => void,
): Promise<void> => {
    const request: AutocompletionRequest = {
        input: searchValue,
        // Country restrictions
        // componentRestrictions: {
        //     country: [ Country.CA, Country.MX, Country.US ],
        // },
    }
    const [ googlePlacesResults, addressBookResults ] = await Promise.all([
        getPlacePredictions(request),
        await AddressApiService.list({
            page: 1,
            resultPerPage: 5,
        },
        {
            searchField: searchValue,
            isShipping: true,
        }),
    ])

    const rawOptions: GoogleAddressSearchOption[] = addressBookResults.addresses.map((result) => {
        const formattedAddress = formatAddress(result)

        return {
            id: result.id,
            addressId: result.id,
            description: formattedAddress,
            isAddressBook: true,
            type: GoogleSearchAddressType.ADDRESS,
            mainText: formattedAddress,
            secondaryText: { firstPart: result.shippingContactName, secondPart: result?.shippingContactEmails?.join(', ') },
            data: result,
        }
    })

    const formattedResults: GoogleAddressSearchOption[] = googlePlacesResults.map((result, index) => ({
        id: result.id,
        addressId: result.place_id,
        description: result.description,
        isAddressBook: false,
        type: GoogleSearchAddressType.ADDRESS,
        mainText: result.structured_formatting.secondary_text ?
            `${result.structured_formatting.main_text} ${result.structured_formatting.secondary_text}` :
            result.structured_formatting.main_text,
        mainTextMatchedSubstrings: (result.structured_formatting.main_text_matched_substrings ?? [])
            .concat(result.structured_formatting.secondary_text_matched_substrings ?? []),
        data: result,
    }))
    callback(rawOptions.concat(formattedResults))
}, 200)

export const isAddressBook = (addressSearchOption: GoogleAddressSearchOption): addressSearchOption is GoogleAddressSearchOption<Address> => {
    const address = addressSearchOption as GoogleAddressSearchOption<Address>

    return address.isAddressBook && !!address.data
}
