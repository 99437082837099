/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuccessResponse_GetExchangeRateResponse_Array_ } from '../models/SuccessResponse_GetExchangeRateResponse_Array_';
import { request as __request } from '../core/request';

export class ExchangeRateService {

    /**
     * Get currency exchange rate
     * @returns SuccessResponse_GetExchangeRateResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCurrencyExchangeRate(): Promise<SuccessResponse_GetExchangeRateResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/exchange-rate`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}