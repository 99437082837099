import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AddressForm, { type AddressFormProps } from '../AddressForm/AddressForm'
import AccordionSession from '../../../Accordion/AccordionSession'
import PrimaryButton from '../../../PrimaryButton/PrimaryButton'
import type { FieldConfig, OnError, SchemaField } from '../../FormSimple'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './DoubleAddressForm.i18n'
import useSizeScreen from '@/app/ui-new/pages/hooks/useSizeScreen'
import Tooltip from '../../../Tooltip/Tooltip'
import { processAddress } from '@/app/ui-new/pages/schedule-pickup/helpers'
import FormSimple from '../../FormSimple'
import { flattenSchema } from '../../helpers'
import { z } from 'zod'
import { Country } from '@/app/model'

export interface Address {
    data: AddressFormProps['address']
    title?: string
    endLabel?: string
}
export interface Props {
    nameID?: string
    countries?: Country[]
    hasAddressSameAddress2?: {
        isSame: boolean
        show?: boolean
        label?: string
    }
    title?: string
    address1: Address
    address2?: Address
    forceSubmit?: boolean
    getData: ({ address1, address2 }: { address1: AddressFormProps['address']; address2: AddressFormProps['address'] }) => void
    getError?: (data: any) => void
    disabled?: boolean
}

const DoubleAddressForm: React.FunctionComponent<Props> = ({
    nameID,
    hasAddressSameAddress2 = {
        isSame: false,
        show: true,
    },
    title,
    address1,
    address2,
    forceSubmit,
    getData,
    getError,
    disabled,
    countries,
}: Props) => {
    const { sizeScreen } = useSizeScreen()

    const { t } = useI18n(i18n)

    const [expanded, setIsExpanded] = useState<boolean>(true)
    const [expandedAddress, setIsExpandedAddress] = useState<boolean>(true)

    const initialIsValid: OnError = {
        data: {},
        isValid: false,
        isDirty: false,
    }

    const isValid: OnError = {
        data: {},
        isValid: true,
        isDirty: true,
    }
    const [isValidAddress1, setIsValidAddress1] = useState<OnError>(initialIsValid)
    const [isValidAddress2, setIsValidAddress2] = useState<OnError>(initialIsValid)
    const [addressForm1, setAddressForm1] = useState<AddressFormProps['address'] | undefined>(address1?.data)
    const [addressForm2, setAddressForm2] = useState<AddressFormProps['address'] | undefined>(address2?.data)
    const [isAddressSameAsAddress2, setIsAddressSameAsAddress2] = useState<boolean>(hasAddressSameAddress2?.isSame)

    const makeAddress = (data: any, setAddressForm: React.Dispatch<React.SetStateAction<AddressFormProps['address'] | undefined>>) => {
        const dataForm = processAddress(data)
        setAddressForm(dataForm)
    }

    const getFormAddress1Values = (data: AddressFormProps) => {
        makeAddress(data, setAddressForm1)
        isValidAddress1.isDirty && setIsValidAddress1(isValid)
    }

    const getFormAddress2Values = (data: AddressFormProps) => {
        makeAddress(data, setAddressForm2)
        isValidAddress2.isDirty && setIsValidAddress2(isValid)
    }

    const handleExpansion = () => {
        setIsExpanded((prevExpanded) => !prevExpanded)
    }

    const handleAddressExpansion = () => {
        setIsExpandedAddress((prevExpanded) => !prevExpanded)
    }

    const getFormOnError1 = (data: OnError) => {
        getError?.(data)
        setIsValidAddress1(data)
    }

    const getFormOnError2 = (data: OnError) => {
        getError?.(data)
        setIsValidAddress2(data)
    }

    const movingLeftRight = () => {
        setAddressForm2(addressForm1)
    }

    const switchLeftRight = () => {
        const beforeAddressForm1 = addressForm1
        const beforeAddressForm2 = addressForm2
        setAddressForm1(beforeAddressForm2)
        setAddressForm2(beforeAddressForm1)
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isAddressSameAsAddress2) {
            if (isValidAddress1.isValid && isValidAddress2.isValid && isValidAddress2.isDirty && isValidAddress2.isDirty) {
                getData({ address1: addressForm1, address2: addressForm2 })
            }
        } else {
            if (isValidAddress1.isValid && isValidAddress1.isDirty) {
                getData({ address1: addressForm1, address2: addressForm1 })
            }
        }
    }, [addressForm1, isValidAddress1, isValidAddress2, addressForm2])

    const yesSchema: Record<string, SchemaField> = {
        yes: {
            type: 'checkbox',
            label: t('Yes'),
            layoutConfig: { xs: 12 },
        },
    }

    const fieldsCheckbox: FieldConfig[] = [
        {
            name: 'addressSameAsAddress2',
            schema: z.any(),
            groupInputs: flattenSchema(yesSchema, 'layoutConfig,label,type'),
            type: 'checkbox',
            hasOrder: false,
            values: isAddressSameAsAddress2 ? 'yes' : '',
            layoutConfig: { xs: 12, sm: 12 },
            labelName: hasAddressSameAddress2?.label ? hasAddressSameAddress2?.label : t('Is the shipper the same as the pickup address?'),
        },
    ]

    const handleGeneralInfoChange = (data: any) => {
        if (data?.addressSameAsAddress2) {
            setIsAddressSameAsAddress2(true)
        } else {
            setIsAddressSameAsAddress2(false)
        }
    }
    return (
        <>
            <AccordionSession
                setIsExpanded={setIsExpanded}
                handleExpansion={handleExpansion}
                isExpanded={disabled ? false : expanded}
                title={title}
                disabled={disabled}
                error={
                    isAddressSameAsAddress2
                        ? !isValidAddress1.isDirty
                            ? false
                            : !isValidAddress1.isValid
                        : !isValidAddress1.isDirty || !isValidAddress2.isDirty
                          ? false
                          : !isValidAddress1.isValid || !isValidAddress2.isValid
                }
                completed={
                    isAddressSameAsAddress2
                        ? !isValidAddress1.isDirty
                            ? false
                            : isValidAddress1.isValid
                        : !isValidAddress1.isDirty || !isValidAddress2.isDirty
                          ? false
                          : isValidAddress1.isValid && isValidAddress2.isValid
                }
                endLabel={
                    isAddressSameAsAddress2
                        ? !isValidAddress1.isDirty
                            ? ''
                            : isValidAddress1.isValid
                              ? t('completed')
                              : ''
                        : !isValidAddress1.isDirty || !isValidAddress2.isDirty
                          ? ''
                          : isValidAddress1.isValid && isValidAddress2.isValid
                              ? t('completed')
                              : ''
                }
            >
                <>
                    <Grid container spacing={5}>
                        {hasAddressSameAddress2?.show && (
                            <Grid item xs={12}>
                                <FormSimple
                                    nameID={`${nameID}-form-carrier`}
                                    fields={fieldsCheckbox}
                                    layoutConfig={{ spacing: 3 }}
                                    onChange={handleGeneralInfoChange}
                                    debouncer={{ wait: 1 }}
                                />
                            </Grid>
                        )}

                        <Grid item xs>
                            <AccordionSession
                                setIsExpanded={setIsExpanded}
                                handleExpansion={handleAddressExpansion}
                                isExpanded={expandedAddress}
                                title={address1?.title}
                                error={!isValidAddress1.isDirty ? false : !isValidAddress1.isValid}
                                completed={!isValidAddress1.isDirty ? false : isValidAddress1.isValid}
                                endLabel={
                                    !isValidAddress1.isDirty
                                        ? ''
                                        : isValidAddress1.isValid
                                          ? address1?.endLabel
                                                ? address1?.endLabel
                                                : t('completed')
                                          : ''
                                }
                            >
                                <AddressForm
                                    countries={countries}
                                    nameID={`${nameID}-address1-form`}
                                    address={addressForm1}
                                    getFormValues={getFormAddress1Values}
                                    getFormOnError={getFormOnError1}
                                    isHalfScreen={true}
                                    forceSubmit={forceSubmit}
                                />
                            </AccordionSession>
                        </Grid>
                        {!isAddressSameAsAddress2 && (
                            <>
                                <Grid item sm={12} md={1} style={{ textAlign: 'center' }}>
                                    <Tooltip placement='bottom-end' title={'teste'}>
                                        <PrimaryButton
                                            size='small'
                                            style={{ margin: '5px' }}
                                            onClick={() => movingLeftRight()}
                                            disabled={!(isValidAddress1.isValid && isValidAddress2.isValid)}
                                        >
                                            {' '}
                                            <FontAwesomeIcon icon={['fas', !sizeScreen.md ? 'arrow-down' : 'arrow-right']} />
                                        </PrimaryButton>
                                    </Tooltip>
                                    <Tooltip placement='bottom-end' title={`Switch ${address1?.title}  to  ${address2?.title}`}>
                                        <PrimaryButton
                                            size='small'
                                            style={{ margin: '5px' }}
                                            onClick={() => switchLeftRight()}
                                            disabled={!isValidAddress1.isValid && !isValidAddress2.isValid}
                                        >
                                            {' '}
                                            <FontAwesomeIcon
                                                icon={['fas', 'exchange-alt']}
                                                style={{ transform: !sizeScreen.md ? 'rotate(90deg)' : 'rotate(0deg)' }}
                                            />
                                        </PrimaryButton>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs>
                                    <AccordionSession
                                        setIsExpanded={setIsExpanded}
                                        handleExpansion={handleAddressExpansion}
                                        isExpanded={expandedAddress}
                                        title={address2?.title}
                                        error={!isValidAddress1.isDirty ? false : !isValidAddress1.isValid}
                                        completed={!isValidAddress2.isDirty ? false : isValidAddress2.isValid}
                                        endLabel={
                                            !isValidAddress2.isDirty
                                                ? ''
                                                : isValidAddress2.isValid
                                                  ? address2?.endLabel
                                                        ? address2?.endLabel
                                                        : t('completed')
                                                  : ''
                                        }
                                    >
                                        <AddressForm
                                            nameID={`${nameID}-address2-form`}
                                            countries={countries}
                                            address={addressForm2}
                                            getFormValues={getFormAddress2Values}
                                            getFormOnError={getFormOnError2}
                                            isHalfScreen={true}
                                            forceSubmit={forceSubmit}
                                        />
                                    </AccordionSession>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            </AccordionSession>
        </>
    )
}

export default DoubleAddressForm
