import { Currency, Language, Organization, Timezone, UserTitle, Locale } from '../../../model'
import { Store } from '../../definitions/Store'

export const getOrganizationList = (store: Store): {
    organizationList: Organization[] | undefined
    isOrganizationListFetched: boolean
} => ({ organizationList: store.appState.organizationList, isOrganizationListFetched: store.appState.isOrganizationFetched })
export const getCurrencyList = (store: Store): {
    currencyList: Currency[] | undefined
    isCurrencyListFetched: boolean
} => ({ currencyList: store.appState.currencyList, isCurrencyListFetched: store.appState.isCurrencyFetched })
export const getLanguageList = (store: Store): {
    languageList: Language[] | undefined
    isLanguageListFetched: boolean
} => ({ languageList: store.appState.languageList, isLanguageListFetched: store.appState.isLanguageFetched })
export const getLocaleList = (store: Store): {
    localeList: Locale[] | undefined
    isLocaleListFetched: boolean
} => ({ localeList: store.appState.localeList, isLocaleListFetched: store.appState.isLocaleFetched })
export const getTimezoneList = (store: Store): {
    timezoneList: Timezone[] | undefined
    isTimezoneListFetched: boolean
} => ({ timezoneList: store.appState.timezoneList, isTimezoneListFetched: store.appState.isTimezoneFetched })
export const getUserTitleList = (store: Store): {
    userTitleList: UserTitle[] | undefined
    isUserTitleListFetched: boolean
} => ({ userTitleList: store.appState.userTitleList, isUserTitleListFetched: store.appState.isUserTitleFetched })
