import React, { Ref, forwardRef } from 'react'
import { CustomKeyboardDatePicker } from './KeyboardDatePicker.styled'

const KeyboardDatePicker: React.FunctionComponent<any> = React.forwardRef(
  (props, ref) => {
    return (
      <CustomKeyboardDatePicker
        InputLabelProps={{ shrink: true }}
        //minDate={props.disableToday ? moment().add(1, 'day').toDate() : undefined}
       
        ref={ref}
        {...props}
      />
    )
  },
)

export default KeyboardDatePicker
