import {
    //EdiOrderInvoice,
    //EdiOrderRevision,
    //EdiOrderTrackingUpdate,
    OrderStatus,
    TransportType,
    UuidV4,
    UuidV5,
} from '@lazr/openapi-client'

import {
    EdiOrderInvoice,
    EdiOrderResponse,
    EdiOrderRevision,
    EdiOrderStatus,
    EdiOrderTrackingUpdate,
} from '@lazr/edi'

export interface EdiOrderProps {
    id: UuidV5
    ediAccountId: UuidV4
    status: EdiOrderStatus
    statusName: string
    orderId: UuidV4 | null
    orderNumber: string | null
    orderStatus: OrderStatus | null
    orderStatusName: string | null
    clientOrganizationId: UuidV4
    clientOrganizationName: string
    threePlOrganizationId: UuidV4
    threePlOrganizationName: string
    transportType: TransportType
    expiresAt: string
    updatedAt: string
    createdAt: string
    revisions: EdiOrderRevision[]
    responses: EdiOrderResponse[]
    trackingUpdates: EdiOrderTrackingUpdate[]
    invoices: EdiOrderInvoice[]
}

export interface FilteredEdiOrders {
    ediOrders: EdiOrder[]
    total: number
}

export class EdiOrder {
    id: UuidV5
    ediAccountId: UuidV4
    orderId: UuidV4 | null
    orderNumber: string | null
    orderStatus: OrderStatus | null
    orderStatusName: string | null
    status: EdiOrderStatus | null
    statusName: string | null
    clientOrganizationId: UuidV4
    clientOrganizationName: string
    threePlOrganizationId: UuidV4
    threePlOrganizationName: string
    transportType: TransportType
    expiresAt: string
    updatedAt: string
    createdAt: string
    revisions: EdiOrderRevision[]
    responses: EdiOrderResponse[]
    trackingUpdates: EdiOrderTrackingUpdate[]
    invoices: EdiOrderInvoice[]

    constructor (props: EdiOrderProps) {
        this.id = props.id
        this.ediAccountId = props.ediAccountId
        this.orderId = props.orderId
        this.orderNumber = props.orderNumber
        this.orderStatus = props.orderStatus
        this.orderStatusName = props.orderStatusName
        this.status = props.status
        this.statusName = props.statusName
        this.clientOrganizationId = props.clientOrganizationId
        this.clientOrganizationName = props.clientOrganizationName
        this.threePlOrganizationId = props.threePlOrganizationId
        this.threePlOrganizationName = props.threePlOrganizationName
        this.transportType = props.transportType
        this.updatedAt = props.updatedAt
        this.createdAt = props.createdAt
        this.revisions = props.revisions
        this.responses = props.responses
        this.trackingUpdates = props.trackingUpdates
        this.invoices = props.invoices
    }
}
