import { Country, TaxType } from '@lazr/enums'
import { deepCopy } from './utilities'
// eslint-disable-next-line max-len
type USState = 'AL' | 'AK' | 'AS' | 'AZ' | 'AR' | 'CA' | 'CO' | 'CT' | 'DE' | 'DC' | 'FM' | 'FL' | 'GA' | 'GU' | 'HI' | 'ID' | 'IL' | 'IN' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MH' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'MP' | 'OH' | 'OK' | 'OR' | 'PW' | 'PA' | 'PR' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'UT' | 'VT' | 'VI' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY'
type CAProvinces = 'AB' | 'BC' | 'MB' | 'NB' | 'NL' | 'NT' | 'NS' | 'NU' | 'ON' | 'PE' | 'QC' | 'SK' | 'YT'
export type StateAndProvinces = USState | CAProvinces

export type Tax = {
    type: TaxType
    rate: number
    amount?: number
    applicableAmount?: number
}
export class TaxCalculator {
    public static readonly CA_PROVINCES: CAProvinces[] = [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT' ]
    private static readonly GST_RATE = 0.050
    private static readonly QST_QC_RATE = 0.09975
    private static readonly HST_NB_RATE = 0.15000
    private static readonly HST_NL_RATE = 0.15000
    private static readonly HST_NS_RATE = 0.15000
    private static readonly HST_ON_RATE = 0.13000
    private static readonly HST_PE_RATE = 0.15000

    private static readonly INTERNATIONALTAX: Tax = {
        type: TaxType.NO_TAX,
        rate: 0,
    }

    public static validateStateCountry (stateCode: string, countryCode: string): boolean {
        if (countryCode.toUpperCase() === Country.CA) {
            // For Canada, validate that the state code is in the Province enum
            return Object.values(TaxCalculator.CA_PROVINCES).includes(stateCode.toUpperCase() as CAProvinces)
        } else {
            // For other countries, validate that the country code is in the Country enum
            return Object.values(Country).includes(countryCode.toUpperCase() as Country)
        }
    }

    public static getTaxesWithType (taxType: TaxType, price: number): Tax [] {
        const effectiveTax = this.getEffectiveTaxWithType(taxType)
        effectiveTax.forEach((t, index) => {
            const amount = t.rate * price
            effectiveTax[index].amount = Math.sign(amount) * Math.round(Math.abs(amount) * 100) / 100
        })

        return effectiveTax
    }

    public static reduceTaxes (taxes: Tax []) : Tax [] {
        const reducedTaxes: Tax[] = []
        taxes.forEach((nextTax) => {
            const foundTax = reducedTaxes.find((tax) => tax.type === nextTax.type)
            if (foundTax) {
                foundTax.amount = (foundTax.amount || 0) + (nextTax.amount || 0)
                foundTax.applicableAmount = (foundTax.applicableAmount || 0) + (nextTax.applicableAmount || 0)
            } else {
                reducedTaxes.push(nextTax)
            }
        })

        return reducedTaxes
    }

    public static getEffectiveTax (originState: string | null, originCountry: string,
        destinationState: string | null, destinationCountry: string): Tax [] {
        if (this.isTaxApplicable(originCountry.toUpperCase(), originState?.toUpperCase() ?? null)
            && this.isTaxApplicable(destinationCountry.toUpperCase(), destinationState?.toUpperCase() ?? null)) {
            switch (destinationState?.toUpperCase()) {
                case 'NB':
                    return  [ deepCopy(this.NEW_BRUNSWICK_TAX) ]
                case 'NL':
                    return [ deepCopy(this.NEW_FOUNDLAND_TAX) ]
                case 'NS':
                    return [ deepCopy(this.NOVA_SCOTIA_TAX) ]
                case 'ON':
                    return [ deepCopy(this.ONTARIO_TAX) ]
                case 'QC':
                    return this.applyQuebecInternalRouteRule(originState?.toUpperCase() ?? null)
                case 'PE':
                    return [ deepCopy(this.PRINCE_EDWARD_ISLAND_TAX) ]
                case 'AB':
                case 'BC':
                case 'MB':
                case 'NT':
                case 'NU':
                case 'SK':
                case 'YT':
                default:
                    return [ deepCopy(this.GST_TAX) ]
            }
        } else {
            return [ deepCopy(this.INTERNATIONALTAX) ]
        }
    }
    public static getTaxWithStateProvince (statesProvince: string, country: string): TaxType {
        if (this.isCanadianCountry(country.toUpperCase()) && this.isCanadianProvince(statesProvince.toUpperCase())) {
            switch (statesProvince) {
                case 'NB':
                    return  TaxType.HST_NB
                case 'NL':
                    return TaxType.HST_NL
                case 'NS':
                    return TaxType.HST_NS
                case 'ON':
                    return TaxType.HST_ON
                case 'QC':
                    return TaxType.QST_GST
                case 'PE':
                    return TaxType.HST_PE
                case 'AB':
                case 'BC':
                case 'MB':
                case 'NT':
                case 'NU':
                case 'SK':
                case 'YT':
                default:
                    return TaxType.GST
            }
        } else {
            return TaxType.NO_TAX
        }
    }

    public static getEffectiveTaxWithType (taxType: TaxType): Tax[] {
        switch (taxType){
            case TaxType.GST:
                return [ deepCopy(this.GST_TAX) ]
            case TaxType.HST_NB:
                return [ deepCopy(this.NEW_BRUNSWICK_TAX) ]
            case TaxType.HST_NL:
                return [ deepCopy(this.NEW_FOUNDLAND_TAX) ]
            case TaxType.HST_NS:
                return [ deepCopy(this.NOVA_SCOTIA_TAX) ]
            case TaxType.HST_ON:
                return [ deepCopy(this.ONTARIO_TAX) ]
            case TaxType.NO_TAX:
                return []
            case TaxType.HST_PE:
                return [ deepCopy(this.PRINCE_EDWARD_ISLAND_TAX) ]
            case TaxType.QST_GST:
                return [ deepCopy(this.QUEBEC_TAX), deepCopy(this.GST_TAX) ]
            default:
                return [ deepCopy(this.GST_TAX) ]
        }
    }


    private static NEW_BRUNSWICK_TAX =  {
        type: TaxType.HST_NB,
        rate: TaxCalculator.HST_NB_RATE,
    }

    private static NEW_FOUNDLAND_TAX =  {
        type: TaxType.HST_NL,
        rate: TaxCalculator.HST_NL_RATE,
    }

    private static NOVA_SCOTIA_TAX =  {
        type: TaxType.HST_NS,
        rate: TaxCalculator.HST_NS_RATE,
    }

    private static ONTARIO_TAX =  {
        type: TaxType.HST_ON,
        rate: TaxCalculator.HST_ON_RATE,
    }
    private static PRINCE_EDWARD_ISLAND_TAX =  {
        type: TaxType.HST_PE,
        rate: TaxCalculator.HST_PE_RATE,
    }

    private static GST_TAX =  {
        type: TaxType.GST,
        rate: TaxCalculator.GST_RATE,
    }

    private static QUEBEC_TAX =  {
        type: TaxType.QST_GST,
        rate: TaxCalculator.QST_QC_RATE,
    }
    private static isCanadianCountry (countryCode: string): boolean {
        // Check if the country code is a valid value of the Country enum and is Canada
        return countryCode === Country.CA && Object.values(Country).includes(countryCode as Country)
    }

    private static isCanadianProvince (stateOrProvince: string): boolean {
        // Check if the province code is a valid value of the Province enum
        return Object.values(TaxCalculator.CA_PROVINCES).includes(stateOrProvince as CAProvinces)
    }

    private static isTaxApplicable (countryCode: string, stateOrProvince: string | null): boolean {
        return !stateOrProvince ? false : this.isCanadianCountry(countryCode) && this.isCanadianProvince(stateOrProvince)
    }

    private static applyQuebecInternalRouteRule (originState: string | null): Tax[] {
        return originState === 'QC' ? [ deepCopy(this.QUEBEC_TAX), deepCopy(this.GST_TAX) ] : [ deepCopy(this.GST_TAX) ]
    }
}
