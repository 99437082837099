import React, { useEffect, useState } from 'react'

const Timer: React.FunctionComponent<Props> = ({ initialSeconds }) => {
    const [ seconds, setSeconds ] =  useState<number>(initialSeconds)
    useEffect(() => {
        const interval = window.setInterval(() => {
            if (seconds <= 0) {
                window.clearInterval(interval)

                return
            }

            setSeconds((oldSeconds) => oldSeconds - 1)
        }, 1000)

        return () => {
            window.clearInterval(interval)
        }
    }, [])

    return seconds > 0 ? <span>{seconds}</span> : null
}

interface Props {
    initialSeconds: number
}

export default Timer
