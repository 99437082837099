import React from 'react'
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core'
import styled from 'styled-components'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { transparentize } from 'polished'
import { ToggleButtonProps } from '@material-ui/lab/ToggleButton/ToggleButton'
import { InnerBoxProps } from '@/app/ui-new/pages/website/components/CariersCarousel/Mobile/CarriersCarouselMobile'

export const StyledContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    background-color: ${(props): string => props.theme.palette.white.main};
`

export const StyledTypographyLabel: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
    color: ${(props): number => props.theme.palette.neutral700.main};
`

export const StyledTypographyH1: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
`

export const StyledToggleButton: React.FC<ToggleButtonProps> = styled(ToggleButton)<ToggleButtonProps>`
    border-color ${(props): number => props.theme.palette.neutral300.main};
    color: ${(props): number => props.theme.palette.neutral700.main};
    text-transform: none;
    width: 115px;
    border-radius: 8px;

    &:hover,
    &.Mui-selected:hover{
        background-color: ${(props): string => transparentize(0.7, props.theme.palette.accentPrimary300.main)};
    }

    &.Mui-selected{
        background-color: ${(props): string => transparentize(0.88, props.theme.palette.accentPrimary300.main)};
        color: ${(props): number => props.theme.palette.primary.main};

        & [class*="MuiToggleButton-label"] svg{
            font-size: 16px;
            margin-right: 0.5rem;
        }
    }
`

export const StyledTypographySwitcher: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 500;
`

export const StyledOuterBox = styled.div`
    position: relative;
    padding: 0 0 0 ${(props): number => props.theme.spacing(4)}px;
    max-width: 100%;
    min-width: 330px;
`

export const StyledInnerBox: React.FC<InnerBoxProps> = styled(Box)<InnerBoxProps>`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: auto;
    min-height: 120px;
    &: hover {
        cursor: ${(props): string => props.isOverflowed ? 'pointer' : 'default'};
    }

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        /* WebKit */ width: 0;
        height: 0;
    }
`

export const StyledListItem = styled.div`
    border-color: ${(props): string => transparentize(0.85, props.theme.palette.black.main)};
    margin: 0  ${(props): number => props.theme.spacing(1)}px;
    user-select: none;
    & img {
    -webkit-user-drag: none;
    }
`
