import React from 'react'
import { StyledContainer } from './BaseCarrierLogo.styled'

const formNewClassName = (className: string[], otherClassName?: string): string => {
    const newClassName = className

    if (otherClassName) {
        newClassName.push(otherClassName)
    }

    return newClassName.join(' ')
}

const BaseCarrierLogo: React.FunctionComponent<BaseCarrierLogoProps> = ({
    logo,
    size = 'medium',
    className,
    name,
    ...rest
}) => {
    let logoComponent = logo
    if (typeof logo === 'string') {
        logoComponent = <img src={logo}
            alt={name ?? 'Carrier Logo'}/>
    }

    return <StyledContainer {...rest} className={formNewClassName([ size ], className)}>
        <div>
            {logoComponent}
        </div>
    </StyledContainer>
}

export interface BaseCarrierLogoProps extends React.ComponentProps<typeof StyledContainer> {
    logo?: string | React.ReactNode
    size?: 'small' | 'medium' | 'large' | 'wide-large'
    className?: string
    name?: string
}

export default BaseCarrierLogo
