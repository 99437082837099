import { useEffect, useState } from 'react'
import { CountryApiService } from '@/app/service/ApiService'
import type { Country } from '@/app/model'

import { ApiServiceAction } from '@lazr/enums'
import { useDebouncedCallback } from './useDebouncedCallback'

export interface CountriesProps {
    wait?: number
}

interface ReturnCountries {
    list?: Country[]
}
const useCountries = ({ wait }: CountriesProps, action: ApiServiceAction): ReturnCountries => {
    const initialResults: Country[] = []

    // State for storing result
    const [result, setResult] = useState<Country[]>(initialResults)

    // Calculate milliseconds
    const milliseconds = wait ? wait * 1000 : 0

    // Function to fetchCarrierData
    const listCountries = async (): Promise<void> => {
        try {
            const country = await CountryApiService.list()
            setResult(country)
        } catch (error) {
            console.error('Error fetching carrier data:', error)
            setResult([]) // Handle error by setting result to null
        }
    }

    const debouncedCountries = useDebouncedCallback(async (action) => {
        try {
            switch (action) {
                case ApiServiceAction.LIST:
                    await listCountries()
            }
        } catch (error) {
            console.error('Error in fetchData:', error)
        }
    }, milliseconds)

    // Effect to fetch data on limit and page change
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        debouncedCountries(action)
    }, [setResult])

    return { list: result}
}

export default useCountries
