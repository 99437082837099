/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAddressRequest } from '../models/AddAddressRequest';
import type { EditAddressRequest } from '../models/EditAddressRequest';
import type { SuccessResponse_AddAddressResponse_ } from '../models/SuccessResponse_AddAddressResponse_';
import type { SuccessResponse_DeleteCollectAccountResponse_ } from '../models/SuccessResponse_DeleteCollectAccountResponse_';
import type { SuccessResponse_EditAddressResponse_ } from '../models/SuccessResponse_EditAddressResponse_';
import type { SuccessResponse_GetAddressByIdResponse_ } from '../models/SuccessResponse_GetAddressByIdResponse_';
import type { SuccessResponse_GetAddressesResponse_ } from '../models/SuccessResponse_GetAddressesResponse_';
import type { SuccessResponse_RemoveAddressResponse_ } from '../models/SuccessResponse_RemoveAddressResponse_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class AddressBookService {

    /**
     * Add an address to your organization's address book.
     * @param requestBody The data
     * @returns SuccessResponse_AddAddressResponse_ OK
     * @throws ApiError
     */
    public static async addAddress(
        requestBody: AddAddressRequest,
    ): Promise<SuccessResponse_AddAddressResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/add-address`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete a user collect account by id
     * @param id The collect account id to delete
     * @returns SuccessResponse_DeleteCollectAccountResponse_ OK
     * @throws ApiError
     */
    public static async deleteCollectAccount(
        id: UuidV4,
    ): Promise<SuccessResponse_DeleteCollectAccountResponse_> {
        const result = await __request({
            method: 'DELETE',
            path: `/delete-collect-account/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit an address in your organization's address book.
     * @param requestBody The data
     * @returns SuccessResponse_EditAddressResponse_ OK
     * @throws ApiError
     */
    public static async editAddress(
        requestBody: EditAddressRequest,
    ): Promise<SuccessResponse_EditAddressResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-address`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Remove an address from the organization
     * @param id The ID of the address to delete
     * @returns SuccessResponse_RemoveAddressResponse_ OK
     * @throws ApiError
     */
    public static async removeAddress(
        id: UuidV4,
    ): Promise<SuccessResponse_RemoveAddressResponse_> {
        const result = await __request({
            method: 'DELETE',
            path: `/remove-address/${id}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an address by its ID
     * @param id The ID of the address to get
     * @param organizationId
     * @returns SuccessResponse_GetAddressByIdResponse_ OK
     * @throws ApiError
     */
    public static async getAddressById(
        id: string,
        organizationId?: UuidV4,
    ): Promise<SuccessResponse_GetAddressByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/address-by-id/${id}`,
            query: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of addresses saved in your organization
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param searchField Text field to search
     * @param name
     * @param address
     * @param description
     * @param city
     * @param state
     * @param postalCode
     * @param country
     * @param companyName
     * @param isShipping
     * @param isBilling
     * @param organizationId
     * @param isBillingDefault
     * @param isShippingDefault
     * @param excludeAddressId
     * @param orderBy Field to order by
     * @param order Order direction of orderBy
     * @returns SuccessResponse_GetAddressesResponse_ OK
     * @throws ApiError
     */
    public static async getAddresses(
        page?: number,
        resultsPerPage?: number,
        searchField?: string,
        name?: string,
        address?: string,
        description?: string,
        city?: string,
        state?: string,
        postalCode?: string,
        country?: string,
        companyName?: string,
        isShipping?: boolean,
        isBilling?: boolean,
        organizationId?: UuidV4,
        isBillingDefault?: boolean,
        isShippingDefault?: boolean,
        excludeAddressId?: UuidV4,
        orderBy?: 'name' | 'companyName' | 'description' | 'contactName' | 'streetAddressLine1',
        order?: 'asc' | 'desc',
    ): Promise<SuccessResponse_GetAddressesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/addresses`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'searchField': searchField,
                'name': name,
                'address': address,
                'description': description,
                'city': city,
                'state': state,
                'postalCode': postalCode,
                'country': country,
                'companyName': companyName,
                'isShipping': isShipping,
                'isBilling': isBilling,
                'organizationId': organizationId,
                'isBillingDefault': isBillingDefault,
                'isShippingDefault': isShippingDefault,
                'excludeAddressId': excludeAddressId,
                'orderBy': orderBy,
                'order': order,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}