/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './SupportWidget.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Support']: `Soutien`,
    ['Should you require assistance, our user support team is at your service.']: `Si vous avez besoin d'assistance, notre équipe de support utilisateur est à votre service.`,
    ['Don\'t hesitate to contact us for prompt and professional assistance with any inquiries or concerns you may have.']: `N'hésitez pas à nous contacter pour une assistance rapide et professionnelle concernant toutes les questions ou préoccupations que vous pourriez avoir.`,
    ['mailto:support@lazr.io?subject=Support Request']: `mailto:soutien@lazr.io?subject=Demande de soutien`,
    ['support@lazr.io']: `soutien@lazr.io`,
    ['Help Center']: `Centre d'aide`,
    ['Privacy']: `Politique de confidentialité`,
    ['Terms of Service']: `Conditions d'utilisation`,
}))()
