/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './AppFooter.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
    ['Support']: `Soutien`,
    ['Help Center']: `Centre d'aide`,
    ['Privacy']: `Politique de confidentialité`,
    ['Terms of Service']: `Conditions d'utilisation`,
}))()
