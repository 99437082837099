import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { initialUserInfo } from '../store/InitialState'
import { BillToAddress, Lane, User, UserInfo } from '../../../model'
import { Currency } from '@lazr/enums'

const reducer: Reducer = (state: UserInfo = initialUserInfo, action: AnyAction): UserInfo => ({
    user: user(state.user, action),
    isSignedIn: isSignedIn(state.isSignedIn, action),
    defaultUserCurrency: defaultUserCurrency(state.defaultUserCurrency, action),
})

const user = (state: User | null, action: AnyAction): User | null => {
    if (state) {
        state.lanes = userLanes(state.lanes, action)
        state.billToAddresses = userBillToAddresses(state.billToAddresses, action)
    }
    switch (action.type) {
        case types.SET_USER:
            return action.payload
        default:
            return state
    }
}

const isSignedIn = (state: boolean, action: AnyAction): boolean => {
    switch (action.type) {
        case types.SET_USER_IS_SIGNED_IN:
            return action.payload
        default:
            return state
    }
}
const defaultUserCurrency = (state: Currency, action: AnyAction): Currency => {
    switch (action.type) {
        case types.SET_DEFAULT_USER_CURRENCY:
            return action.payload
        default:
            return state
    }
}
const userLanes = (state: Lane[], action: AnyAction): Lane[] => {
    switch (action.type) {
        case types.SET_LANES:
            return action.payload
        default:
            return state
    }
}
const userBillToAddresses = (state: BillToAddress[], action: AnyAction): BillToAddress[] => {
    switch (action.type) {
        case types.SET_USER_BILL_TO_ADDRESSES:
            return action.payload
        default:
            return state
    }
}

export default reducer
