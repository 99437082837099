import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { Currency } from '../../model'
import { handleUnauthorizedException } from './index'

export const CurrencyApiService = Object.freeze({
    list: async (): Promise<Currency[]> => {
        let getCurrenciesResponse
        try {
            getCurrenciesResponse = await EnumTypesService.getCurrencies()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve currency list')
            }
            throw new Error('Unable to retrieve currency list')
        }

        if (!getCurrenciesResponse.data) {
            logger.debug('list',  JSON.stringify(getCurrenciesResponse, null, 4))
            throw new Error('Unable to retrieve currency list')
        }

        return getCurrenciesResponse.data
    },
})
