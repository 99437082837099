import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import TextButton from '@/app/ui-new/components/TextButtons/TextButton'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'

export const Header = styled(styled.div(MuiSpacing))`
    background-color: ${(props): string => props.theme.palette.black.main};
    color: ${(props): string => props.theme.palette.white.main};
    display: flex;
    justify-content: end;
    align-items: center;
    padding: ${(props): string => props.theme.spacing(3)}px ${(props): string => props.theme.spacing(6)}px;
    width: 100%;
    // height: 56px;
    // min-height: 56px;
    // max-height: 56px;
    user-select: none;
    border-top: 1px solid white;
    position: sticky;
    // top: 72px;
    left: 0;
    z-index: 1200;
`

export const HeaderTitle = styled(styled.div(MuiSpacing))`
    display: flex;
    justify-items: center;
    align-content: center;
    flex-grow: 1;
    padding-top: ${(props): string => props.theme.spacing(1)}px;
`

export const ModuleName = styled(Typography)`
    font-family: Termina,sans-serif;
    font-size: 1.71rem;
    font-weight: 700;
    line-height: 2.1rem;
    white-space: nowrap;
    padding-right: ${(props): string => props.theme.spacing(4)}px;
    border-right: 1px solid white;
`

export const OrganizationName = styled(Typography)`
    font-family: Termina,sans-serif;
    font-size: 1.71rem;
    font-weight: 400;
    line-height: 2.1rem;
    padding-left: ${(props): string => props.theme.spacing(4)}px;
  
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 360px;
`

export const HeaderSteps = styled(styled.div(MuiSpacing))`
    display: flex;
    align-items: center;
    // height: 56px;
    padding-right: ${(props): string => props.theme.spacing(6)}px;
    
    & svg:not(.arrow){
        height: 26px;
        width: 26px;
        padding: ${(props): string => props.theme.spacing(1.5)}px;
        border-radius: 50%;
        margin-right: ${(props): string => props.theme.spacing(2)}px;
        background-color: ${(props): string => props.theme.palette.neutral800.main};
    }

    & svg.arrow {
        color: ${(props): string => props.theme.palette.neutral500.main};
        margin: 0 ${(props): string => props.theme.spacing(3)}px;
    }

    & > span {
        display: flex;
        align-items: center;
        color: ${(props): string => props.theme.palette.white.main};
        cursor: pointer;
    }

    & > span.active {
        cursor: default;
        pointer-events: none;

        & svg{
            background-color: ${(props): string => props.theme.palette.accentSecondary.main};
        }
    }

    & > span.disabled {
        cursor: default;
        pointer-events: none;
        color: ${(props): string => props.theme.palette.neutral500.main};
    }

    & > span.completed {
        color: ${(props): string => props.theme.palette.white.main};
    }
`

export const ResetButton = styled(TextButton)`
    &:focus {
        outline: 0;
    }
`

export const NewOrderButton = styled(PrimaryButton)`
    background-color: ${(props): number => props.theme.palette.accentPrimary600.main};
    color: ${(props): number => props.theme.palette.accentPrimary600.contrastText};
`
