import { env, Environment } from './config'

const now = () => new Date().toLocaleString()

export const logger = {
    /* eslint-disable @typescript-eslint/no-unsafe-argument */
    log: (...args: any[]): void => {
        window.console.log(now(), ...args)
    },
    info: (...args: any[]): void => {
        window.console.info(now(), ...args)
    },
    warn: (...args: any[]): void => {
        window.console.warn(now(), ...args)
    },
    error: (...args: any[]): void => {
        window.console.error(now(), ...args)
    },
    debug: (...args: any[]): void => {
        if (env !== Environment.PRODUCTION) {
            window.console.debug(now(), ...args)
        }
    },
    /* eslint-enable @typescript-eslint/no-unsafe-argument */
}
