import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { ReasonForExportType } from '../../model'
import { handleUnauthorizedException } from './index'

export const ReasonForExportTypeApiService = Object.freeze({
    list: async (): Promise<ReasonForExportType[]> => {
        let getReasonForExportTypesResponse
        try {
            getReasonForExportTypesResponse = await EnumTypesService.getReasonForExportTypes()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve reason for export type list')
            }
            throw new Error('Unable to retrieve reason for export type list')
        }

        if (!getReasonForExportTypesResponse.data) {
            logger.debug('list',  JSON.stringify(getReasonForExportTypesResponse, null, 4))
            throw new Error('Unable to retrieve reason for export type list')
        }

        return getReasonForExportTypesResponse.data
    },
})
