import React from 'react'
import { StyledMuiButton } from './PrimaryButton.styled'
import { ButtonProps } from '@material-ui/core'

const PrimaryButton: React.FunctionComponent<ButtonProps> = (props) =>
    <StyledMuiButton
        variant="contained"
        {...props}
    >{props.children}
    </StyledMuiButton>

export default PrimaryButton
