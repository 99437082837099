/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GenerateBolResponse = {
    content: string;
    contentType: GenerateBolResponse.contentType;
    name: string;
    isGenerated: boolean;
    hasBarcode: boolean;
}

export namespace GenerateBolResponse {

    export enum contentType {
        APPLICATION_PDF = 'application/pdf',
    }


}
