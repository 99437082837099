import {
    AccessorialLocationType,
    AccessorialType,
    AddressType,
    CarrierType,
    Currency as CurrencyCode,
    DensityUnit,
    DimensionUnit,
    EquipmentType,
    OrderStatus,
    PackageType,
    PaymentTypeOption,
    Provider,
    RfqStatus,
    ServiceType,
    TariffType,
    TransportType,
    USMCA,
    UuidV4,
    UuidV5,
    VolumeUnit,
    WeightUnit,
    ResultLaneType,
    DocumentType,
    ServiceLevelType,
    ShippingMode,
    HazmatAccessibiltyType,
    ShipmentDocumentType,
    BatteryMaterial,
    BatteryPacking,
    Country as CountryCode,
} from '@lazr/openapi-client'
import { CurrencyProps } from './Currency'
import {
    B13aFilingOption,
    BillDutiesToPartyType,
    Country,
    CoverageCommodityType,
    CoverageProps,
    Currency,
    ExportComplianceStatement,
    HazmatClassType,
    HazmatModeType,
    ImportExportType,
    IncotermsType,
    NonDeliveryHandlingType,
    ReasonForExportType,
} from './index'
import { LabelSizeType } from '@lazr/enums'

export interface SearchProps {
    billingCurrency: Currency
    lazrCadToUsdRate: number
    clientNumber: string
    clientOrganizationId: UuidV4
    clientOrganizationName: string
    createdAt: string
    createdByUserId: UuidV4
    id: UuidV4
    lastUpdatedByUserId: UuidV4
    number: string
    rfq: SearchRfqProps
    status: OrderStatus
    threePlNumber: string
    threePlOrganizationId: UuidV4
    updatedAt: string
    lazrUsdToCadRate: number
    ediOrderId: null | UuidV5
    documents: ResultDocument[] | null
    collectData: Collect | null
}

export interface SearchRfqProps {
    accessorials: SearchRfqAccessorial[]
    handlingUnits: Partial<SearchRfqHandlingUnit>[]
    customsDetails: SearchRfqCustomsDetail[]
    quotes: SearchRfqQuote[]
    reasons: SearchRfqReason[]
    createdAt: string
    updatedAt: string
    lastUpdatedByUserId: UuidV4 | null
    resultsCompleted: Record<string, boolean>
    resultsSearchInProgress: boolean | null
    resultsSearchStartedAt: string | null
    reverseMargins: boolean | null
    shipmentCoverageValue: number | null
    coverageCommodityType: CoverageCommodityType | null
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean | null
    skidSpots: number | null
    linearFeet: number | null
    customsBroker: string | null
    destinationPoNumber: string | null
    destinationDeliveryInstructions: string | null
    destinationDeliveryCloseTime: string | null
    destinationDeliveryOpenTime: string | null
    destinationNotifyReceiver: boolean | null
    destinationCompanyName: string | null
    destinationContactPhoneExtension: string | null
    destinationContactPhone: string | null
    destinationContactEmails: string[] | null
    destinationContactName: string | null
    destinationName: string | null
    destinationDeliveryDate: string | null
    destinationPostalCode: string | null
    destinationAddressType: AddressType | null
    destinationDetectedAddressType?: AddressType | null
    destinationCountry: Country | null
    destinationState: string | null
    destinationCity: string | null
    destinationStreetAddress3: string | null
    destinationStreetAddress2: string | null
    destinationStreetAddress: string | null
    originPoNumber: string | null
    originPickupInstructions: string | null
    originPickupCloseTime: string | null
    originPickupOpenTime: string | null
    originNotifyShipper: boolean | null
    originCompanyName: string | null
    originContactPhoneExtension: string | null
    originContactPhone: string | null
    originContactEmails: string[] | null
    originContactName: string | null
    originName: string | null
    originPickupDate?: string | null
    originPostalCode: string | null
    originAddressType: AddressType | null
    originDetectedAddressType?: AddressType | null
    originCountry: Country | null
    originState: string | null
    originCity: string | null
    originStreetAddress3: string | null
    originStreetAddress2: string | null
    originStreetAddress: string | null
    equipmentType: EquipmentType | null
    transportType: TransportType
    paymentType: PaymentTypeOption
    customQuoteRequested: boolean
    status: RfqStatus
    orderId: UuidV4
    id: UuidV4
    shipmentDocumentType?: ShipmentDocumentType | null
}

export interface SearchRfq {
    accessorials: SearchRfqAccessorial[]
    handlingUnits: Partial<SearchRfqHandlingUnit>[]
    quotes: SearchRfqQuote[]
    mappedReasons: Record<string, SearchRfqReason[]>
    createdAt: string
    updatedAt: string
    lastUpdatedByUserId: UuidV4 | null
    resultsCompleted: Record<string, boolean>
    resultsSearchInProgress: boolean | null
    resultsSearchStartedAt: string | null
    reverseMargins: boolean | null
    shipmentCoverageValue: number | null
    originalShipmentCoverageValue: number | null
    originalShipmentCoverageValueCurrency: CurrencyCode | null
    coverageCommodityType: CoverageCommodityType | null
    isBookedWithCoverage: boolean
    isCoverageExclusionsAccepted?: boolean | null
    skidSpots: number | null
    linearFeet: number | null
    customsBroker: string | null
    destinationPoNumber: string | null
    schedulePickup: boolean
    existingPickups: {
        pickupNumber: string
        organizationProviderAccountId: UuidV4
        carrierId: UuidV4
        provider: Provider
    }[]
    destinationDeliveryInstructions: string | null
    destinationDeliveryCloseTime: string | null
    destinationDeliveryOpenTime: string | null
    destinationNotifyReceiver: boolean | null
    destinationCompanyName: string | null
    destinationContactPhoneExtension: string | null
    destinationContactPhone: string | null
    destinationContactEmails: string[]
    destinationContactName: string | null
    destinationName: string | null
    destinationDeliveryDate: string | null
    destinationPostalCode: string | null
    destinationAddressType: AddressType | null
    destinationDetectedAddressType?: AddressType | null
    destinationCountry: Country | null
    destinationState: string | null
    destinationCity: string | null
    destinationStreetAddress3: string | null
    destinationStreetAddress2: string | null
    destinationStreetAddress: string | null
    originPoNumber: string | null
    originPickupInstructions: string | null
    originPickupCloseTime: string | null
    originPickupOpenTime: string | null
    originNotifyShipper: boolean | null
    originCompanyName: string | null
    originContactPhoneExtension: string | null
    originContactPhone: string | null
    originContactEmails: string[]
    originContactName: string | null
    originName: string | null
    originPickupDate: string | null
    originPostalCode: string | null
    originAddressType: AddressType | null
    originDetectedAddressType?: AddressType | null
    originCountry: Country | null
    originState: string | null
    originCity: string | null
    originStreetAddress2: string | null
    originStreetAddress3: string | null
    originStreetAddress: string | null
    pickupStreetAddress: string | null
    pickupStreetAddress2: string | null
    pickupStreetAddress3: string | null
    pickupCity: string | null,
    pickupState: string | null,
    pickupCountry: Country | null,
    pickupPostalCode: string | null
    pickupAddressType: string | null
    pickupContactName: string | null
    pickupContactEmails: string[] | null
    pickupContactPhone: string | null,
    pickupContactPhoneExtension: string | null
    pickupCompanyName: string | null
    pickupNotify: boolean
    pickupOpenTime: string | null
    pickupCloseTime: string | null
    pickupInstructions: string | null
    selectedQuote: SearchRfqQuote | null
    selectedQuoteId: UuidV4 | null
    equipmentType: EquipmentType | null
    transportType: TransportType
    paymentType: PaymentTypeOption
    customQuoteRequested: boolean
    status: RfqStatus
    billDutiesToParty: BillDutiesToPartyType | null
    documentsOnlyIndicator: boolean | null
    customsTotalValue: number | null
    dutyBillToCity: string | null
    dutyBillToContactEmail: string | null
    dutyBillToCompanyName: string | null
    dutyBillToContactName: string | null
    dutyBillToContactPhone: string | null
    dutyBillToContactPhoneExt: string | null
    dutyBillToCountry: Country | null
    dutyBillToName: string | null
    dutyBillToPostalCode: string | null
    dutyBillToState: string | null
    dutyBillToStreetAddress: string | null
    dutyBillToStreetAddress2: string | null
    dutyBillToStreetAddress3: string | null
    dutyBillToAccountNumber: string | null
    dutyBusinessRelated: boolean | null
    dutyCurrency: Currency | null
    hazmatClass: HazmatClassType | null
    hazmatDeclarationDocumentIndicator: boolean | null
    hazmatMode: HazmatModeType | null
    importExport: ImportExportType | null
    useThermalPrinter: boolean | null
    labelSizeType: LabelSizeType | null
    reasonForExport: ReasonForExportType | null
    nonDeliveryHandling: NonDeliveryHandlingType | null
    otherReasonForExport: string | null
    b13aFilingOption: B13aFilingOption | null
    exportComplianceStatement: ExportComplianceStatement | null
    customsBrokerCity: string | null
    customsBrokerEmail: string | null
    customsBrokerContactName: string | null
    customsBrokerContactPhone: string | null
    customsBrokerContactPhoneExt: string | null
    customsBrokerCountry: Country | null
    customsBrokerPostalCode: string | null
    customsBrokerState: string | null
    customsBrokerStreetAddress: string | null
    customsBrokerStreetAddress2: string | null
    customsBrokerStreetAddress3: string | null
    customsBrokerAccountNumber: string | null
    taxIdentificationNumber: string | null
    consigneeTaxIdentificationNumber: string | null
    thirdPartyTaxIdentificationNumber: string | null
    orderId: UuidV4
    id: UuidV4
    customsDetails: SearchRfqCustomsDetail[]
    addressId: UuidV4 | null
    billingPo: string | null
    incotermsType: IncotermsType | null
    etdEnabled: boolean | null
    commercialInvoiceDocumentIndicator: boolean | null
    commercialInvoiceUserProvided: boolean | null
    shipmentDocumentType?: ShipmentDocumentType | null
    holdForPickup?: {
        carrierId: UuidV4 | null
        address: HoldForPickupAddress | null
    }
}

export interface HoldForPickupAddress {
    companyName: string
    phone: string
    phoneExtension: string | null
    streetAddress: string
    streetAddress2: string | null
    streetAddress3: string | null
    city: string
    state: string
    country: CountryCode
    postalCode: string
}

export interface SearchRfqAccessorial {
    isRemovable: boolean
    accessorial: SearchAccessorial
    id: UuidV4
}

export interface SearchRfqHandlingUnitAccessorial {
    isRemovable: boolean
    accessorial: SearchAccessorial
    id: UuidV4
}

export interface SearchRfqHandlingUnitDryIce {
    unit: WeightUnit
    value: number
}

export interface SearchRfqHandlingUnitBattery {
    material: BatteryMaterial
    packing: BatteryPacking
}

export interface SearchRfqHandlingUnit {
    accessorials: SearchRfqHandlingUnitAccessorial[]
    description: string | null
    poNumber: string | null
    packageType: PackageType | null
    nmfcSub: string | null
    nmfc: string | null
    class: string | null
    densityUnit: DensityUnit | null
    density: number | null
    totalVolumeUnit: VolumeUnit | null
    totalVolume: number | null
    totalWeightUnit: WeightUnit | null
    totalWeight: number | null
    weightUnit: WeightUnit | null
    weight: number | null
    heightUnit: DimensionUnit | null
    height: number | null
    widthUnit: DimensionUnit | null
    width: number | null
    lengthUnit: DimensionUnit | null
    length: number | null
    pieces: number | null
    quantity: number | null
    envelopeType: string | null
    rfqId: UuidV4
    id: UuidV4
    dryIce: SearchRfqHandlingUnitDryIce | null
    hazmatAccessibiltyType: HazmatAccessibiltyType | null
    freightHazmatUn: string | null
    freightHazmatScientificName: string | null
    freightHazmatPackagingGroup: string | null
    freightHazmatEmergencyContactNumber: string | null
    freightHazmatClass: string | null
    battery: SearchRfqHandlingUnitBattery | null
}

export interface SearchRfqCustomsDetail {
    id: UuidV4
    description: string | null
    harmonizedCode: string | null
    squareMeters: number | null
    countryOfManufacture: Country | null
    productCode: string | null
    unitValue: number | null
    unitCurrency?: CurrencyCode | null
    quantity: number | null
    productIndicatorIsUsmca: boolean | null
    productIndicatorFda: boolean | null
    productIndicatorTextile: boolean | null
    documentIndicatorUsmca: boolean | null
    documentIndicatorFda: boolean | null
    documentIndicatorFcc: boolean | null
    senderIsProducerIndicator: boolean | null
    textileManufacturer: string | null
    unitWeight: number | null
    weightUnit: WeightUnit | null
    pieces: number | null
    productIndicatorUsmcaType: USMCA | null
    handlingUnitIds: UuidV4[]
}

export interface SearchRfqQuoteCarrierService {
    id: UuidV4
    imagingServiceType: ServiceType
    trackingServiceType: ServiceType
    dispatchServiceType: ServiceType
    ratingServiceType: ServiceType
    transportType: TransportType
    supportEtd: boolean
    supportEtdDeletion: boolean
    createdAt: string
    updatedAt: string
}

export interface SearchRfqQuoteCarrier {
    id: UuidV4
    name: string
    idValue: string
    type: CarrierType
    apiCarrierAccountCode: string
    createdAt: string
    quoteCarrierService: SearchRfqQuoteCarrierService
    updatedAt: string
    etdUploadMaxSize: number | null
    pickupInstructionsMaxCharacter: number | null
    deliveryInstructionsMaxCharacter: number | null
    handlingUnitDescriptionMaxCharacter: number | null
    contactNameMaxCharacter: number | null
    companyNameMaxCharacter: number | null
    customsDescriptionMaxCharacter: number | null
    addressMaxCharacter: number | null
    customsBrokerAddressMaxCharacter: number | null
}

export interface SearchRfqReasonCarrier {
    id: UuidV4
    name: string
    idValue: string
    type: CarrierType
    apiCarrierAccountCode: string
    createdAt: string
    updatedAt: string
    services?: {
        transportType: TransportType
    }[]
}

export interface SearchRfqQuoteProps {
    quoteCarrier: SearchRfqQuoteCarrier
    quoteCharges: SearchRfqQuoteCharge[]
    tariffType: TariffType
    createdByUserId: UuidV4
    createdAt: string
    updatedAt: string
    validUntil: string
    estimatedTimeOfArrival: string
    additionalInfo: string
    costTransactionFeeUsd: number
    costTransactionFeeCad: number
    costTotalUsd: number
    costTotalCad: number
    costMarginUsd?: number
    costMarginCad?: number
    costRawUsd?: number
    costRawCad?: number
    pickupDate?: string
    transitDaysMax: number
    transitDaysMin: number
    exchangedAt: string
    lazrUsdToCadRate: number
    lazrCadToUsdRate: number
    preferredCurrency: CurrencyProps
    currency: CurrencyProps
    serviceLevelCode: string
    serviceLevel: string
    carrierDisplayName: string
    carrierCode: string
    carrierId: UuidV4
    apiCarrierQuoteNumber: string
    provider: Provider
    requiresValidation: boolean
    requiresCustomQuote: boolean
    isCustomQuote: boolean
    rfqId: UuidV4
    id: UuidV4
    isHidden: boolean
    isPriceAdjusted?: boolean
}

interface ResultShippingMode {
    code: ShippingMode
    name: string
}
export interface SearchRfqQuote {
    carrierDiscountCad?: number | null
    carrierDiscountUsd?: number | null
    coverage?: CoverageProps
    quoteCarrier: SearchRfqQuoteCarrier
    quoteCharges: SearchRfqQuoteCharge[]
    tariffType: TariffType
    isPreApprovedQuote: boolean
    createdByUserId: UuidV4
    createdAt: string
    updatedAt: string
    validUntil: string
    estimatedTimeOfArrival: string
    additionalInfo: string
    costTransactionFeeUsd?: number
    costTransactionFeeCad?: number
    costTotalUsd: number
    costTotalCad: number
    costMarginUsd?: number
    costMarginCad?: number
    costRawUsd?: number
    costRawCad?: number
    pickupDate?: string
    transitDaysMax: number
    transitDaysMin: number
    exchangedAt: string
    lazrUsdToCadRate: number
    lazrCadToUsdRate: number
    preferredCurrency: Currency
    currency: Currency
    serviceLevelType: ServiceLevelType | null
    serviceLevelCode: string
    serviceLevel: string
    carrierDisplayName: string
    carrierCode: string
    carrierId: UuidV4
    organizationProviderAccountId: UuidV4 | null
    apiCarrierQuoteNumber: string
    provider: Provider
    requiresValidation: boolean
    requiresCustomQuote: boolean
    isCustomQuote: boolean
    rfqId: UuidV4
    id: UuidV4
    clientSpecific: boolean
    threePlOrganizationId: string
    threePlOrganizationName: string
    isHidden: boolean
    isPriceAdjusted?: boolean
    estimatedDeliveryDate: string | null
    basicCoveragePriceSelected?: boolean
    lazrCoveragePriceSelected?: boolean
    shippingMode: ResultShippingMode | null
    accountNumber: string | null
    laneType: ResultLaneType | null
}

export interface SearchRfqReason {
    carrier: SearchRfqReasonCarrier
    tariffType: TariffType
    createdByUserId: UuidV4
    createdAt: string
    updatedAt: string
    serviceLevelCode: string
    serviceLevel: string
    carrierCode: string
    error: string
    carrierId: UuidV4
    provider: Provider
    rfqId: UuidV4
    id: UuidV4
    clientSpecific: boolean
    threePlOrganizationId: string
}

export interface SearchRfqQuoteCharge {
    totalUsd: number
    totalCad: number
    carrierDiscountCad?: number | null
    carrierDiscountUsd?: number | null
    marginUsd?: number
    marginCad?: number
    rawUsd?: number
    rawCad?: number
    description: string
    code: string
    seqNumber: number
    quoteId: UuidV4
    id: UuidV4
}

export interface SearchAccessorial {
    id: UuidV4
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
}

export interface Collect {
    id: UuidV4
    carrierId: UuidV4
    orderId: UuidV4
    carrierName: string
    accountNumber: string
}

export interface ResultDocument {
    id: UuidV4
    name: string
    type: DocumentType
    orderId: UuidV4
    updatedAt?: string
    createdByUserId?: UuidV4
    hasBarcode: boolean
    isGenerated: boolean
    isUploading?: boolean
}

export class Search {
    billingCurrency: Currency | null
    lazrCadToUsdRate: number
    clientNumber: string
    clientOrganizationId: UuidV4
    clientOrganizationName: string
    createdAt: string
    createdByUserId: UuidV4
    id: UuidV4
    lastUpdatedByUserId: UuidV4
    number: string
    rfq: Partial<SearchRfq>
    status: OrderStatus
    threePlNumber: string
    threePlOrganizationId: UuidV4
    updatedAt: string
    lazrUsdToCadRate: number
    ediOrderId: null | UuidV5
    documents: ResultDocument[] | null
    collectData: Collect | null

    constructor (props: SearchProps) {
        this.lazrCadToUsdRate = props.lazrCadToUsdRate
        this.clientNumber = props.clientNumber
        this.clientOrganizationId = props.clientOrganizationId
        this.clientOrganizationName = props.clientOrganizationName
        this.createdAt = props.createdAt
        this.createdByUserId = props.createdByUserId
        this.id = props.id
        this.number = props.number
        this.rfq = {
            ...props.rfq,
            mappedReasons: this.mapReasonsToProvider(props.rfq.reasons),
            selectedQuote: null,
            originContactEmails: props.rfq.originContactEmails ? props.rfq.originContactEmails :  [],
            destinationContactEmails: props.rfq.destinationContactEmails ? props.rfq.destinationContactEmails :  [],
        }
        this.status = props.status
        this.threePlNumber = props.threePlNumber
        this.threePlOrganizationId = props.threePlOrganizationId
        this.updatedAt = props.updatedAt
        this.lazrUsdToCadRate = props.lazrUsdToCadRate
        this.ediOrderId = props.ediOrderId
        this.collectData = props.collectData

        // Rich objects
        this.billingCurrency =  new Currency(props.billingCurrency)
        if (this.rfq.originCountry) {
            this.rfq.originCountry = props.rfq.originCountry && new Country(props.rfq.originCountry)
        }
        if (this.rfq.destinationCountry) {
            this.rfq.destinationCountry = props.rfq.destinationCountry && new Country(props.rfq.destinationCountry)
        }
        this.rfq.quotes = props.rfq.quotes.map((quote) => ({
            ...quote,
            currency: new Currency(quote.currency),
            preferredCurrency: new Currency(quote.preferredCurrency),
        }))
        this.documents = props.documents
    }
    private mapReasonsToProvider (reasons: SearchRfqReason[]): Record<string, SearchRfqReason[]> {
        const mappedReasons: Record<string, SearchRfqReason[]> = {}
        reasons.forEach((reason) => {
            if (!mappedReasons[reason.provider]) {
                mappedReasons[reason.provider] = []
            }
            mappedReasons[reason.provider].push(reason)
        })

        return mappedReasons
    }
}
