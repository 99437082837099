import styled from 'styled-components'
import { spacing } from '@material-ui/system'
import { Box, Typography as MuiTypography } from '@material-ui/core'
import { Grid as MuiGrid } from '@material-ui/core'
import React from 'react'
import { StyledBoxProps } from '@/app/ui-new/pages/website/components/FlipCards/CardDesktop'

export const BackgroundGradientLayer0 = styled(styled.div(spacing))`
    position: relative;
    opacity: 0.9;
`

export const BackgroundGradientLayer1 = styled.div`
    background: linear-gradient(to right, ${(props): number => props.theme.palette.accentSecondary.main},
        ${(props): number => props.theme.palette.accentPrimary.main});
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    clip-path: polygon(0% 35%, 30% 50%, 70% 10%, 100% 10%, 100% 45%, 90% 55%, 40% 90%, 0 60%);
`

export const BackgroundGradientLayer2 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -3;
`

export const BackgroundGradientLayer3 = styled.div`
    background-color: ${(props): number => props.theme.palette.black.main};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.7;
`

export const BackgroundGradientLayer4 = styled.div`
    backdrop-filter: blur(30px);
    padding: ${(props): string => props.theme.spacing(25)}px
        ${(props): string => props.theme.spacing(24)}px
        ${(props): string => props.theme.spacing(36)}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1712px){
        padding: ${(props): string => props.theme.spacing(25)}px
            ${(props): string => props.theme.spacing(6)}px
            ${(props): string => props.theme.spacing(36)}px;
    }
`

export const FlipCardsTitle = styled(MuiTypography)`
    color: ${(props): number => props.theme.palette.white.main};
    text-align: center;
    max-width: 1110px;
    padding-bottom: ${(props): string => props.theme.spacing(19)}px;
    font-family: 'Termina';
    font-weight: 600;
    font-size: 3.4285rem;
`

export const CardsContainer = styled(styled.div(spacing))`
    & > div > div{
        display: flex;
        justify-content: center;
    }

    & > div > div > div{
        margin: ${(props): string => props.theme.spacing(3)}px;
    }
`

export const Grid = styled(styled(MuiGrid)(spacing))``

export const CardOuter: React.FC<CardOuterProps> = styled(styled.div(spacing))<CardOuterProps>`
    background-color: transparent;
    perspective: 1000px;
    width: fit-content;
    height: fit-content;

    &.enable-hover:hover > div{
        transform: rotateY(180deg);
    }

    &.rotated > div{
        transform: rotateY(180deg);
    }
`

interface CardOuterProps {
    className: string
    ref: React.RefObject<HTMLDivElement | undefined>
}

export const CardInner: React.FC<StyledBoxProps> = styled(Box)<StyledBoxProps>`
    position: relative;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    width: 288px;
    height: ${(props): number => props.language === 'EN' ? 440 : 520}px;
`

export const Side = styled(styled.div(spacing))`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${(props): string => props.theme.spacing(6)}px;
    backface-visibility: hidden;
    position: absolute;
    background-color: ${(props): number => props.theme.palette.white.main};
    margin: ${(props): string => props.theme.spacing(5)}px 0;
    border-radius: 16px;
    overflow: hidden;

    &.back{
        transform: rotateY(180deg);
    }
`

export const GradientBorder = styled.div`
    position: relative;
    width: 176px;
    height: 170px;
    border-radius: 50%;
    background: linear-gradient(to left,
        ${(props): string => props.theme.palette.accentPrimary50.main},
        ${(props): string => props.theme.palette.accentSecondary50.main}
    );
    box-shadow: 0 8px 40px ${(props): string => props.theme.palette.accentPrimary50.main};
    transform: translateY(60px);
`

export const ImageContainer = styled(styled.div(spacing))`
    position: absolute;
    top: 4px;
    left: 4px;
    width: 168px;
    height: 162px;
    border-radius: 50%;
    overflow: hidden;

    & img{
        width: 168px;
        height: 162px;
    }
`

export const Typography1 = styled(styled(MuiTypography)(spacing))`
    font-weight: 500;
`

export const Typography2 = styled(styled(MuiTypography)(spacing))`
    font-weight: 700;
    font-size: 2rem;
    line-height: 125%;
    text-align: center;
    transform: translateY(80px);
`

export const Typography3 = styled(styled(MuiTypography)(spacing))`
    font-weight: 500;
    text-align:left;
    font-size: 1.43rem;
`

export const Typography4 = styled(styled(MuiTypography)(spacing))`
    text-align: left;
    line-height: 1.4rem;
    letter-spacing: -0.01rem;
`

export const KeyPointsContainer = styled(styled.div(spacing))`
    flex-grow: 1;
`

export const IconContainer = styled(styled.div(spacing))`
    width: 50px;
    min-width: 50px;
    text-align: left;
`

export const KeyPointItem = styled(styled.div(spacing))`
    display: flex;
`
