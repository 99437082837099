import { logger } from '../../logger'
import {
    AccessorialsService,
    ErrorResponse,
    ApiError,
} from '@lazr/openapi-client'
import { AccessorialLocationType, AccessorialType, TransportType } from '@lazr/openapi-client'
import { Accessorial } from '../../model'
import { handleUnauthorizedException } from './index'
export interface AccessorialParams {
    id?: string
    locationType?: AccessorialLocationType
    type?: AccessorialType
    code?: string
    transportTypes?: TransportType[]
    ids?: string[]
}

export const AccessorialApiService = Object.freeze({
    list: async (params: AccessorialParams = {}): Promise<Accessorial[]> => {
        let getAccessorialsResponse
        try {
            getAccessorialsResponse =
                await AccessorialsService.getAccessorials(params.id, params.locationType, params.type, params.code,
                    params.transportTypes, params.ids)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve accessorial list')
            }
            throw new Error('Unable to retrieve accessorial list')
        }

        if (!getAccessorialsResponse.data) {
            logger.debug('list', JSON.stringify(getAccessorialsResponse, null, 4))
            throw new Error('Unable to retrieve accessorial list')
        }
        return getAccessorialsResponse.data.map((accessorial) => new Accessorial(accessorial))

    },
})
