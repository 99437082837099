import React, { useState, useEffect } from 'react'
import {  useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { Wrapper, Welcome, TabsToolbar, Tabs, Tab, TabPanel } from './AuthMain.styled'
import SignIn from './SignIn'
import RegisterOrganization from './RegisterOrganization'
import { RegistrationState } from '@lazr/openapi-client'

/* I18N */
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './AuthMain.i18n'

const AuthMain: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)

    const user = useSelector(reduxGetUser)
    const localStorage = window.localStorage
    const currentLocation = window.location.pathname
    const [ tabIndex, setTabIndex ] = useState(Number(currentLocation !== '/signin'))

    let userFirstName
    if (user) {
        userFirstName = user.firstName
    }
    if (!user && localStorage && localStorage.getItem('userFirstName')) {
        userFirstName = localStorage.getItem('userFirstName')
    }
    const welcomeMessageSignin = userFirstName ? <>{t('Welcome back,')} <span>{userFirstName}</span></> : <>{t('Welcome to Lazr')}</>

    const history = useHistory()
    const isFromHistory = history.location.state && history.location.state.from
    const clearHistoryFrom = () => history.replace({ ...history.location, state: undefined })

    const navigateToCreateAcount = () => {
        history.push('/create-your-organization');
      }

    useEffect(() => {
        if (isFromHistory) {
            window.setTimeout(() => clearHistoryFrom(), 1000)
        }
    }, [ isFromHistory ])

    const handleTabChange = () => {
        const tabs = [ 'signin', 'register' ]
        const to = tabs[ Number(!tabIndex)]
        const from = tabs[ tabIndex ]

        if(tabIndex===1){
            return navigateToCreateAcount()
        }

        setTabIndex((previousState) => Number(!previousState))


        window.setTimeout(() => {
            history.push(to, { from: from })
        }, 300)
    }
    return <Wrapper>
        <div>
            <Welcome>
                {welcomeMessageSignin}
            </Welcome>
        </div>
        <TabsToolbar className='bottom-border-fit'>
            <Tabs value={tabIndex}>
                <Tab
                    label={`${t('Sign In')}`}
                    tabCount={2}
                    disabled={false}
                />
                <Tab
                    label={`${t('Create your Organization')}`}
                    tabCount={2}
                    disabled={false}
                    onClick={navigateToCreateAcount}
                />
            </Tabs>
        </TabsToolbar>
        {tabIndex === 0 &&
            <TabPanel>
                <SignIn/>
            </TabPanel>
        }
    </Wrapper>
}

interface Props{
}

export default AuthMain

