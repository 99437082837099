import React from 'react'
import {
    StyledToggleButtonGroup,
    ToggleButtonGroupLabel,
    ToggleButtonGroupContainer,
} from '@/app/ui-new/components/ToggleButton/ToggleButtonGroup.styled'
import { ToggleButtonGroupProps as StyledToggleButtonGroupProps } from '@material-ui/lab'

const ToggleButtonGroup: React.FunctionComponent<ToggleButtonGroupProps> = (props: ToggleButtonGroupProps) => (
    <ToggleButtonGroupContainer>
        {props.label && <ToggleButtonGroupLabel>{props.label}</ToggleButtonGroupLabel>}
        <StyledToggleButtonGroup {...props} />
    </ToggleButtonGroupContainer>
)

export interface ToggleButtonGroupProps extends StyledToggleButtonGroupProps{
    label?: string
}

export default ToggleButtonGroup
