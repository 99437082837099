import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { Notistack } from '../../definitions/Notistack'

const reducer: Reducer = (state: Notistack | null = null, action: AnyAction) => (
    action.type === types.SET_NOTISTACK
        ? (action.payload ? action.payload : null)
        : state
)

export default reducer
