import React, { MouseEvent } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatZone } from '../../lib/helpers'
import {
    Typography,
    TitleTypography,
    Grid,
} from './AddressValidation.styled'
import { IconContainer } from './PlacesAutocomplete.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useDispatch, useSelector } from 'react-redux'

import {
    setOriginAddressValidated as reduxSetOriginAddressValidated,
    setDestinationAddressValidated as reduxSetDestinationAddressValidated,
} from '../../redux/actions/MarketPlaceActions'
import {
    getSelectedValidatedOriginAddress as reduxGetSelectedValidatedOriginAddress,
    getSelectedValidatedDestinationAddress as reduxGetSelectedValidatedDestinationAddress,
} from '../../redux/selectors/MarketPlaceSelectors'

import { ValidatedAddress } from '../../definitions/ValidatedAddress'

const AddressValidation: React.FunctionComponent<Props> = React.memo(({ title, userAddress, carrierAddresses, className }) => {
    /* REDUX STATES */
    const dispatch = useDispatch()

    // Selected Items
    const selectedValidatedOriginAddress = useSelector(reduxGetSelectedValidatedOriginAddress)
    const selectedValidatedDestinationAddress = useSelector(reduxGetSelectedValidatedDestinationAddress)

    // Generate Address List component
    const addressList: any = []
    let idx = 0
    carrierAddresses.forEach((addressItem: ValidatedAddress) => {
        addressList.push(
            <Grid
                key={`car${idx}`}
                container
                alignItems="center"
                className={'addressRow' +
                    ((title === 'Origin' && selectedValidatedOriginAddress && addressItem.Id === selectedValidatedOriginAddress.Id) ||
                        (title === 'Destination' && selectedValidatedDestinationAddress && addressItem.Id === selectedValidatedDestinationAddress.Id) ?
                        ' selected'
                        :
                        ''
                    )
                }
                onClick={ (e: MouseEvent) => {
                    if (title === 'Origin') {
                        dispatch(reduxSetOriginAddressValidated(addressItem))
                    } else {
                        dispatch(reduxSetDestinationAddressValidated(addressItem))
                    }
                } }
            >
                <Grid item>
                    <IconContainer>
                        <FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} size="xs"/>
                    </IconContainer>
                </Grid>
                <Grid item xs>
                    <Typography variant='body2' color="textSecondary">
                        {addressItem.streetAddress2 && `${addressItem.streetAddress2}-`}{addressItem.streetAddress}
                    </Typography>
                    <Typography variant='body2' color="textSecondary">
                        {addressItem.city}, {addressItem.state}, {addressItem.country}, {addressItem.postalCode}
                    </Typography>
                </Grid>
                <Grid item>
                    {(addressItem.Id === selectedValidatedOriginAddress?.Id ||
                        addressItem.Id === selectedValidatedDestinationAddress?.Id) &&
                        <IconContainer>
                            <FontAwesomeIcon icon={[ 'far', 'check' ]} className='large gray close'/>
                        </IconContainer>
                    }
                </Grid>
            </Grid>,
        )
        idx++
    })

    return (
        <Grid container className={className}>
            <Grid item xs={12}>
                <TitleTypography my={2} variant='h6'>
                    {title}
                </TitleTypography>
                <Grid className={className} container justifyContent='space-between' direction='row'>
                    <Grid id='listContainer' item xs={12} sm={6}>
                        <TitleTypography pt={1.25} variant='body1'>
                            Specified Address
                        </TitleTypography>
                        <Typography variant='body1'>
                            {userAddress.streetAddress2 ? userAddress.streetAddress2.concat(', ') : ''}
                            {userAddress.streetAddress}
                        </Typography>
                        <Typography variant='body1'>
                            {formatZone(userAddress.city, userAddress.state, userAddress.country)}
                        </Typography>
                        <Typography variant='body1' pb={1.25}>
                            {userAddress.postalCode}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TitleTypography pt={1.25} variant='body1'>
                            Carrier&apos;s Address
                        </TitleTypography>
                        <PerfectScrollbar id='addressScrollbar'>
                            <Grid container>
                                {addressList}
                            </Grid>
                        </PerfectScrollbar>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

AddressValidation.displayName = 'AddressValidation'

export interface Props {
    title: string
    userAddress: ValidatedAddress
    carrierAddresses: ValidatedAddress[]
    className?: string
}

export default AddressValidation
