import styled from 'styled-components'
import { spacing as MuiSpacing } from '@material-ui/system'
import {
    Typography as MuiTypography,
    Grid as MuiGrid,
} from '@material-ui/core'

export const Container = styled.div`
    width: 100%;
    padding-top: ${(props): number => props.theme.spacing(27)}px;
    padding-bottom: ${(props): number => props.theme.spacing(27)}px;

    & img{
        max-width: 100%;
    }

    & button, a{
        width: calc(50% - ${(props): number => props.theme.spacing(3)}px);

        & span:first-child{
            font-weight: 700;
        }
    }

    & button + button, a + a, a + button, button + a{
        margin-left: ${(props): number => props.theme.spacing(6)}px;
        color: ${(props): string => props.theme.palette.neutral600.main};
    }

    & span.accentPrimary600{
        color: ${(props): string => props.theme.palette.accentPrimary600.main};
    }

    & span.accentSecondary400{
        color: ${(props): string => props.theme.palette.accentSecondary400.main};
    }
`

export const Grid = styled(MuiGrid)``

export const Title = styled(styled(MuiTypography)(MuiSpacing))`
    font-family: 'Termina';
    font-size: 3.4285rem;
    font-weight: 700;
    line-height: 4.1142rem;
`

export const SubTitle = styled(styled(MuiTypography)(MuiSpacing))`
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.4rem;
    max-width: 820px;
`

export const AdvantagesContainer = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${(props): number => props.theme.spacing(25)}px;

    & div{
        border-top: 1px solid ${(props): string => props.theme.palette.neutral300.main};
        border-bottom: 1px solid ${(props): string => props.theme.palette.neutral300.main};
        border-left: 1px solid ${(props): string => props.theme.palette.neutral300.main};
    }

    & div:last-child{
        border-right: 1px solid ${(props): string => props.theme.palette.neutral300.main};
    }
`

export const Advantages = styled.div`
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${(props): number => props.theme.spacing(6)}px;

`

export const AdvantageTitle = styled(styled(MuiTypography)(MuiSpacing))`
    font-family: 'Termina';
    font-size: 1.7142rem;
    font-weight: 700;
    line-height: 2.2285rem;
`

export const AdvantageSubTitle = styled(styled(MuiTypography)(MuiSpacing))`
    font-size: 1.2857rem;
    font-weight: 400;
    line-height: 1.7357rem;
`
