import styled from 'styled-components'
import MuiSnackbar from '@material-ui/core/Snackbar'

export const Snackbar = styled(MuiSnackbar)`
    &.fullwidth{
        right: 24px;

        & > div{
            width: 100%;

            & > div{
                & > div{
                    width: 100%;
                }
            }
        }
    }
`
