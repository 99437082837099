import { logger } from '../../logger'
import { ApiError, ErrorResponse, EnumTypesService } from '@lazr/openapi-client'
import { B13aFilingOption } from '../../model'
import { handleUnauthorizedException } from './index'

export const B13aFilingOptionApiService = Object.freeze({
    list: async (): Promise<B13aFilingOption[]> => {
        let getB13aFilingOptionsResponse
        try {
            getB13aFilingOptionsResponse = await EnumTypesService.getB13AFilingOptions()
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('list', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve b13a filing option list')
            }
            throw new Error('unable to retrieve b13a filing option list')
        }

        if (!getB13aFilingOptionsResponse.data) {
            logger.debug('list',  JSON.stringify(getB13aFilingOptionsResponse, null, 4))
            throw new Error('unable to retrieve b13a filing option list')
        }

        return getB13aFilingOptionsResponse.data
    },
})
