import React from 'react'
import { Box } from '@material-ui/core'
import ScrollableList from '@/app/ui-new/pages/landing/components/CarriersCarousel/ScrollableList'

const CarriersCarousel: React.FunctionComponent<Props> = (props: Props) => (
    <Box display='flex' alignItems='center' width={'100%'} height={150} bgcolor={'transparent'}>
        <ScrollableList
            freightCarriers={props.freightCarriers}
            parcelCarriers={props.parcelCarriers}
            carousel={props.carousel}
            bgColor={props.bgColor ?? '#FFFFFF'}
        />
    </Box>
)

export interface Props {
    freightCarriers: string[]
    parcelCarriers: string[]
    carousel: string
    bgColor?: string
}

export default CarriersCarousel
