import React from 'react'
import { Root } from './Loader.styled'

import { CircularProgress } from '@material-ui/core'

const Loader: React.FunctionComponent<Props> = () => (
    <Root>
        <CircularProgress color="secondary"/>
    </Root>
)

export interface Props {}

export default Loader
