/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['Load']: 'Load',
    ['Origin & Destination']: 'Origin & Destination',
    ['Coverage']: 'Coverage',
    ['PLT']: 'Pallet',
    ['BAG']: 'Bag',
    ['BALE']: 'Bale',
    ['BOX']: 'Box',
    ['BUCKET']: 'Bucket',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Bundle',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Case',
    ['COIL']: 'Coil',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylinder',
    ['DRUM']: 'Drum',
    ['PIECES']: 'Pieces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Roll',
    ['SKID']: 'Skid',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Tote',
    ['OTHER']: 'Other',
    ['FORKLIFT']: 'Forklift',
    ['This component is a map.']: `This component is a map.`,
    ['View Map']: `View Map`,
    ['Truck type']: `Truck type`,
    ['LTL']: `LTL`,
    ['Reefer']: `Reefer`,
    ['Fresh']: `Fresh`,
    [`Handling units`]: `Handling units`,
    ['Dashboard']: `Dashboard`,
    ['Marketplace']: `Marketplace`,
    ['Invoices']: `Invoices`,
    ['Address Book']: `Address Book`,
    ['Users']: `Users`,
    ['Shipping Details']: `Shipping Details`,
    ['Welcome to the marketplace! All you have to do is let us know what you need to ship']: `Welcome to the marketplace! All you have to do is let us know what you need to ship`,
    [' and we\'ll provide you with great options.']: ` and we'll provide you with great options.`,
    ['Input that does affect the Results']: `Input that does affect the Results`,
    ['Input that does NOT affect the Results']: `Input that does NOT affect the Results`,
    ['Something to fill in:']: `Something to fill in:`,
    ['Reset all shipping informations']: `Reset all shipping informations`,
    ['Go to']: `Go to`,
    ['Submit shipping information']: `Submit shipping information`,
    ['Complete!']: `Complete`,
    ['Continue']: `Continue`,
    ['Set shipping steps bar at fixed position']: `Set shipping steps bar at fixed position`,
    ['Results for']: `Results for`,
    ['Read more']: `Read more`,
    ['Conversations']: `Conversations`,
    ['No conversation']: `No conversation`,
    ['Notifications']: `Notifications`,
    ['No notification']: `No notification`,
    ['User menu']: `User menu`,
    ['Role:']: `Role:`,
    ['Admin']: `Admin`,
    ['Organization:']: `Organization:`,
    ['Lazr Freight']: `Lazr Freight`,
    ['My Account']: `My Account`,
    ['Orders']: `Orders`,
    ['Profile']: `Profile`,
    ['Settings']: `Settings`,
    ['Sign out']: `Sign out`,
    ['My Organization']: `My Organization`,
    ['Manage Team']: `Manage Team`,
    ['Invite members']: `Invite members`,

}
