import styled from 'styled-components'
import {
    Box,
    BoxProps,
    FormControlLabel,
    FormControlLabelProps,
    Typography,
    TypographyProps,
} from '@material-ui/core'
import React from 'react'
import {
    Dialog, DialogProps,
} from '@/app/ui-new/components/Dialog/Dialog'
import SelectionCard, { SelectionCardProps } from '@/app/ui-new/components/SelectionCard/SelectionCard'
import { ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab'

export const StyledTitleTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    color: ${(props): string => props.theme.palette.black.main};
`

export const RequiredTypography: React.FC<TypographyProps> = styled(Typography)<TypographyProps>`
    margin-top: ${(props): string => props.theme.spacing(2)}px;
    color: ${(props): string => props.theme.palette.error900.main};
`

export const StyledDialog: React.FC<DialogProps> = styled(Dialog)<DialogProps>`
    & [role='dialog']{
        padding: 0;
        background-color: black;
    }
    & [role='dialog']>svg:first-of-type {
        color: ${(props): string => props.theme.palette.white.main};
    }
`

export const StyledSelectionCard = styled(SelectionCard)<SelectionCardProps>`
     & [class*="MuiTypography-h6"]{
        font-weight: 500;
     }
`

export const StyledFormContainer: React.FC<BoxProps> = styled(Box)<BoxProps>`
    padding: ${(props): string => props.theme.spacing(8)}px ${(props): string => props.theme.spacing(4)}px;
    margin: ${(props): string => props.theme.spacing(4)}px;
    border-radius: 12px;
    background-color: ${(props): string => props.theme.palette.white.main};
`

export const StyledFormControlLabel: React.FC<FormControlLabelProps> = styled(FormControlLabel)<FormControlLabelProps>`
    margin-right: 0;
    &>div{
        min-width: ${(props): string => props.theme.spacing(12)}px;
    }
`

export const StyledToggleButtonGroup: React.FC<ToggleButtonGroupProps> = styled(ToggleButtonGroup)<ToggleButtonGroupProps>`
    width: 100%;
    overflow-x: auto;
    & [class*="MuiToggleButton-label"]{
         text-transform: none;
    }
`
