import { Currency, Provider } from '@lazr/enums'
import { OrderInfo } from '@/app/model'
import { CanadaPostSpecifics } from '@/app/model/ProviderSpecifics'

export const createCanadaPostSpecificObject = (provider: Provider | undefined, order: Partial<OrderInfo>): CanadaPostSpecifics => {
    if (!order.rfq?.reasonForExport || !order.rfq?.nonDeliveryHandling?.code ||
        (!!order.rfq?.commercialInvoiceDocumentIndicator && !order.rfq?.billDutiesToParty)
    ) {
        throw new Error(('Required fields missing.'))
    }

    return {
        canadaPostSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                reasonForExportType: order.rfq.reasonForExport.code,
                nonDeliveryHandlingType: order.rfq.nonDeliveryHandling.code,
                otherReasonForExport: order.rfq?.otherReasonForExport ?? null,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                dutyCurrency: order.rfq?.dutyCurrency ?? Currency.CAD,
            },
        },
    }
}
