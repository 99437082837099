import * as types from '../constants'
import { AnyAction, Reducer } from 'redux'
import { Language } from '@lazr/openapi-client'

const reducer: Reducer = (state: Language = Language.EN, action: AnyAction) => (
    action.type === types.SET_LANGUAGE
        ? action.payload
        : state
)

export default reducer
