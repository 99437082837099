import { logger } from '../../logger'
import {
    ApiError,
    ErrorResponse,
    PageNavigationService,
    UuidV4,
    PageName,
} from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'

interface PageNavigationParams {
    entityId?: UuidV4
    pageName: PageName
}

export const PageNavigationApiService = Object.freeze({
    record: async (pageNavigationParams: PageNavigationParams): Promise<void> => {
        try {
            await PageNavigationService.recordPageNavigation({
                entityId: pageNavigationParams.entityId || undefined,
                pageName: pageNavigationParams.pageName,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('record', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to record page navigation')
            }
            throw new Error('Unable to record page navigation')
        }
    },
})
