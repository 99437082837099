import { AnyAction, Reducer } from 'redux'
import { initialEdiListFiltering } from '../store/InitialState'
import * as types from '../constants'
import { EdiFilter, EdiListFiltering } from '../../definitions/EdiList'

const reducer: Reducer = (state: EdiListFiltering = initialEdiListFiltering, action: AnyAction): EdiListFiltering => ({
    filters: filters(state.filters, action),
})

const filters = (state: EdiFilter, action: AnyAction): EdiFilter =>
    action.type === types.SET_EDI_FILTER
        ? action.payload
        : state

export default reducer
